import { User } from 'src/types/auth.type';
import {
  Organization,
  OrganizationMemberRole,
} from 'src/types/organization.type';
import {
  OrganizationInvitation,
  OrganizationInvitationStatus,
} from 'src/types/organizationInvitation.type';

export const hasOrganizationAvailableLicences = (
  organization: Organization,
  invitations: OrganizationInvitation[],
) => {
  return (
    (organization.subscription.maxNumberOfUsers || 0) >
    (organization.members.length || 0) +
      (invitations.filter(
        (invitation) =>
          invitation.status === OrganizationInvitationStatus.PENDING,
      ).length || 0) -
      1
  );
};

export const isUserOrganizationAdmin = (
  organization: Organization,
  user: User,
) => {
  const role = organization.members.find(
    (member) => member._id === user._id,
  )?.role;
  return (
    role === OrganizationMemberRole.admin ||
    role === OrganizationMemberRole.owner
  );
};

export const isUserOrganizationOwner = (
  organization: Organization,
  user: User,
) => {
  const role = organization.members.find(
    (member) => member._id === user._id,
  )?.role;
  return role === OrganizationMemberRole.owner;
};

export const getOrganizationOwnerEmailDomain = (organization: Organization) =>
  organization.members
    .find((member) => member.role === OrganizationMemberRole.owner)
    ?.email.split('@')[1];

export const hideSensitiveFields = (organization: Organization) =>
  organization.hideSensitiveFields;
