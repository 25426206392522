import { Edit } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fDateLong } from 'src/helpers/formatTime';
import { OldPrejudiceFormPerteGainProfessionnelsActuel } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';

interface Props {
  control: Control<OldPrejudiceFormPerteGainProfessionnelsActuel, any>;
  canGoToPeriodeStep?: boolean;
  onClickGoToPeriodeStep?: () => void;
  isPGPF: boolean;
  procedure: Procedure;
}
export const DatesBanner: React.FC<Props> = ({
  control,
  canGoToPeriodeStep,
  onClickGoToPeriodeStep,
  isPGPF,
  procedure,
}) => {
  const { t } = useTranslation();
  const [dateDebut, dateFin] = useWatch({
    control,
    name: ['dateDebut', 'dateFin'],
  }).map((dateString) => dateString && new Date(dateString));
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.light,
        borderRadius: `${theme.shape.borderRadius}px`,
        boxShadow: '0 0 3px #ccc',
        padding: theme.spacing(2),
        width: canGoToPeriodeStep ? '400px' : '300px',
        alignSelf: 'center',
      })}
    >
      <Typography variant="caption">
        {!isPGPF && (
          <Typography variant="caption" textAlign="center">
            {t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.dateDebut.periodeTitle',
            )}
          </Typography>
        )}
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack direction="column" spacing={1} alignItems="center">
            <strong>
              {isPGPF
                ? t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.dateDebut.label',
                  )
                : t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.dateDebut.label',
                  )}
            </strong>
            {dateDebut && fDateLong(dateDebut)}
          </Stack>
          <Stack direction="column" spacing={1} alignItems="center">
            <strong>
              {isPGPF
                ? capitalize(
                    t(
                      `procedure.fields.dateLiquidation.label.byProcedureType.${procedure.procedureType}`,
                    ) || '',
                  )
                : t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.dateFin.label',
                  )}
            </strong>
            {dateFin && fDateLong(dateFin)}
          </Stack>
          {canGoToPeriodeStep && (
            <IconButton onClick={onClickGoToPeriodeStep}>
              <Edit />
            </IconButton>
          )}
        </Stack>
      </Typography>
    </Box>
  );
};
