import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import {
  MonetaryErosion,
  MonetaryErosionsCoefficientsType,
} from 'src/types/monetaryErosion.type';
import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  PerteDeChanceDeGainProfessionnel,
  PerteDeChanceDeGainProfessionnelRow,
  RevenuActiviteAnnuelDeReferenceRow,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { getMontantRevaloriseWrittenPrint } from '../../../../revalorisation.written.print';
import { displayNumerosPiecesWrittenPrint } from '../../../../numerosPieces.written.print';
import {
  displayMontantAnnualiseFormula,
  displayRowsAverageFormula,
  displayRowsSumFormula,
} from 'src/helpers/print/prejudicesWrittenPrints/formula';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { displayVictimeNameWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/victime.written.print';
import { uniqBy, upperFirst } from 'lodash';

export const revenuActiviteDeReferenceWrittenPrint = ({
  formData,
  rows,
  revenuAnnuelDeReference,
  perteDeChanceDeGainProfessionnel,
  dateLiquidation,
  monetaryErosions,
  revalorisationCoefficientsType,
  victime,
  isPGPF,
}: {
  formData: NewPrejudiceFormPerteGainProfessionnelsActuel;
  rows: RevenuActiviteAnnuelDeReferenceRow[];
  revenuAnnuelDeReference: number;
  perteDeChanceDeGainProfessionnel: PerteDeChanceDeGainProfessionnel;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'mensuel'
  >;
  victime: Victime;
  isPGPF: boolean;
}): (Table | TableOfContents | Paragraph)[] => {
  const title = getParagraph({
    numbering: {
      reference: 'numbers-dot-simple',
      level: isPGPF ? 1 : 0,
    },
    text: i18next.t(
      'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.title',
    ),
  });
  const firstPartText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.firstPart',
    {
      victimeName: displayVictimeNameWrittenPrint(victime),
      context: isPGPF ? 'PGPF' : undefined,
    },
  );

  const noRowsText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.noRows',
  );
  const getRowText = (row: RevenuActiviteAnnuelDeReferenceRow, index: number) =>
    `${i18next.t(
      'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.row',
      {
        annee: row.annee,
        revenuActiviteAnnuelNet: fCurrency(row.revenuActiviteAnnuelNet),
        numerosPieces: displayNumerosPiecesWrittenPrint(
          row.numerosPieces,
          'Parentheses',
        ),
        formula:
          (row.revenuNet || row.revenuNet === 0) && row.unite
            ? displayMontantAnnualiseFormula({
                montant: row.revenuNet,
                unite: row.unite,
                options: {
                  withParentheses: true,
                },
                editedFieldsParameters: {
                  formData,
                  fieldName: `revenuActiviteAnnuelDeReference.rows.${index}.revenuNet`,
                },
              })
            : '',
      },
    )}${
      !row.montantsDejaRevalorises
        ? getMontantRevaloriseWrittenPrint({
            montant: row.revenuActiviteAnnuelNet,
            anneeOrDateLiquidation: dateLiquidation,
            anneeOrDateMontant: row.annee ?? undefined,
            coefficientsType: revalorisationCoefficientsType,
            monetaryErosions,
          })
        : ''
    }`;

  const revenuDeReferenceAnnuelMoyen =
    CalculsFormNewPerteGainsProfessionnelsActuels.getTotalRevenuDeReferenceAnnuel(
      {
        rows,
        dateLiquidation,
        monetaryErosions,
        revalorisationCoefficientsType,
      },
    );

  const multipleRowsLastPartText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.multipleRowsLastPart',
    {
      revenuDeReferenceAnnuelMoyen: fCurrency(revenuDeReferenceAnnuelMoyen),
    },
  );

  const perteDeChanceDeGainProfessionnelFirstPartText = upperFirst(
    i18next.t(
      'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.perteDeChanceDeGainProfessionnel.firstPart',
      {
        victimeName: displayVictimeNameWrittenPrint(victime),
      },
    ),
  );
  const getPerteDeChanceDeGainProfessionnelsRowText = (
    row: PerteDeChanceDeGainProfessionnelRow,
  ) =>
    i18next.t(
      'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.perteDeChanceDeGainProfessionnel.row',
      {
        annee: row.annee,
        commentaires: row.commentaires,
        numerosPieces: displayNumerosPiecesWrittenPrint(
          row.numerosPieces,
          'Parentheses',
        ),
        indemniteParUnite:
          row.unite !== 'annee'
            ? i18next.t('prejudice.fields.unite.print.value', {
                unite: row.unite,
                value: fCurrency(row.indemnite),
              })
            : fCurrency(row.indemnite),
      },
    );

  const totalIndemniteFormula = displayRowsAverageFormula({
    rowValues: perteDeChanceDeGainProfessionnel.rows.map(
      CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitePerteDeChanceAnnuel,
    ),
    options: {
      withParentheses: true,
      countZeroValuesInAverage: true,
      length: uniqBy(perteDeChanceDeGainProfessionnel.rows, (row) => row.annee)
        .length,
    },
    editedFieldsParameters: {
      formData,
      fieldName: 'perteDeChanceDeGainProfessionnel.totalIndemnite',
    },
  });

  const coefficientPerteDeChanceOneRowNonFullPerteDeChanceText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.perteDeChanceDeGainProfessionnel.oneRow.nonFullPerteDeChance',
    {
      coefficientPerteDeChance: fPercent(
        perteDeChanceDeGainProfessionnel.coefficientPerteDeChance,
      ),
      perteDeChance: fCurrency(perteDeChanceDeGainProfessionnel.perteDeChance),
    },
  );
  const coefficientPerteDeChanceOneRowFullPerteDeChanceText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.perteDeChanceDeGainProfessionnel.oneRow.fullPerteDeChance',
    {
      totalIndemnite: fCurrency(
        perteDeChanceDeGainProfessionnel.totalIndemnite,
      ),
      totalIndemniteFormula,
    },
  );

  const coefficientPerteDeChanceMultipleRowsNonFullPerteDeChanceText =
    i18next.t(
      'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.perteDeChanceDeGainProfessionnel.multipleRows.nonFullPerteDeChance',
      {
        totalIndemnite: fCurrency(
          perteDeChanceDeGainProfessionnel.totalIndemnite,
        ),
        totalIndemniteFormula,
        coefficientPerteDeChance: fPercent(
          perteDeChanceDeGainProfessionnel.coefficientPerteDeChance,
        ),
        perteDeChance: fCurrency(
          perteDeChanceDeGainProfessionnel.perteDeChance,
        ),
      },
    );

  const coefficientPerteDeChanceMultipleRowsFullPerteDeChanceText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.perteDeChanceDeGainProfessionnel.multipleRows.fullPerteDeChance',
    {
      totalIndemnite: fCurrency(
        perteDeChanceDeGainProfessionnel.totalIndemnite,
      ),
      totalIndemniteFormula,
    },
  );

  const totalText = i18next.t(
    rows.length === 0 && perteDeChanceDeGainProfessionnel.rows.length === 0
      ? 'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.totalNoRows'
      : 'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.total',
    {
      total: fCurrency(revenuAnnuelDeReference),
      formula: displayRowsSumFormula({
        rowValues: [
          revenuDeReferenceAnnuelMoyen,
          perteDeChanceDeGainProfessionnel.perteDeChance,
        ],
        options: {
          withParentheses: true,
        },
        editedFieldsParameters: {
          formData,
          fieldName: 'revenuActiviteAnnuelDeReference.total',
        },
      }),
    },
  );

  const coefficientPerteDeChanceText =
    perteDeChanceDeGainProfessionnel.rows.length <= 1
      ? perteDeChanceDeGainProfessionnel.coefficientPerteDeChance === 100
        ? coefficientPerteDeChanceOneRowFullPerteDeChanceText
        : coefficientPerteDeChanceOneRowNonFullPerteDeChanceText
      : perteDeChanceDeGainProfessionnel.coefficientPerteDeChance !== 100
        ? coefficientPerteDeChanceMultipleRowsNonFullPerteDeChanceText
        : coefficientPerteDeChanceMultipleRowsFullPerteDeChanceText;

  const perteDeChanceDeGainProfessionnelPargaraphs: Paragraph[] =
    perteDeChanceDeGainProfessionnel.rows.length === 0 &&
    perteDeChanceDeGainProfessionnel.perteDeChance === 0
      ? []
      : perteDeChanceDeGainProfessionnel.rows.length === 0 &&
          perteDeChanceDeGainProfessionnel.perteDeChance !== 0
        ? [
            getParagraph(perteDeChanceDeGainProfessionnelFirstPartText),
            getParagraph(coefficientPerteDeChanceText),
          ]
        : perteDeChanceDeGainProfessionnel.rows.length === 1 &&
            perteDeChanceDeGainProfessionnel.rows[0]
          ? [
              getParagraph({
                children: [
                  ...getTextRun(perteDeChanceDeGainProfessionnelFirstPartText),
                  ...getTextRun(
                    i18next.t(
                      'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.perteDeChanceDeGainProfessionnel.oneRowFirstPart',
                    ),
                  ),
                  ...getTextRun(
                    getPerteDeChanceDeGainProfessionnelsRowText(
                      perteDeChanceDeGainProfessionnel.rows[0],
                    ),
                  ),
                ],
              }),
              getParagraph(coefficientPerteDeChanceText),
            ]
          : [
              getParagraph(perteDeChanceDeGainProfessionnelFirstPartText),
              ...perteDeChanceDeGainProfessionnel.rows.map((row) =>
                getParagraph({
                  numbering: {
                    reference: 'simple-list',
                    level: 0,
                  },
                  children: getTextRun(
                    getPerteDeChanceDeGainProfessionnelsRowText(row),
                  ),
                }),
              ),
              getParagraph(coefficientPerteDeChanceText),
            ];

  if (rows.length === 0 && perteDeChanceDeGainProfessionnel.rows.length === 0) {
    return [
      title,
      getParagraph({
        children: [
          ...getTextRun(firstPartText),
          ...getTextRun(noRowsText),
          ...getTextRun(totalText),
        ],
      }),
    ];
  } else if (rows.length === 1 && rows[0]) {
    return [
      title,
      getParagraph({
        children: [
          ...getTextRun(firstPartText),
          ...getTextRun(
            i18next.t(
              'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.oneRowSecondPart',
            ),
          ),
          ...getTextRun(getRowText(rows[0], 0)),
        ],
      }),
      getEmptyLine(),
      ...perteDeChanceDeGainProfessionnelPargaraphs,
      getEmptyLine(),
      getParagraph(upperFirst(totalText)),
    ];
  } else {
    return [
      title,
      getParagraph({
        children: [
          ...getTextRun(firstPartText),
          ...getTextRun(
            i18next.t(
              'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.revenuActiviteAnnuelDeReference.multipleRowsSecondPart',
            ),
          ),
        ],
      }),
      ...rows.map((row, index) =>
        getParagraph({
          numbering: {
            reference: 'simple-list',
            level: 0,
          },
          children: getTextRun(getRowText(row, index)),
        }),
      ),
      getParagraph(multipleRowsLastPartText),
      getEmptyLine(),
      ...perteDeChanceDeGainProfessionnelPargaraphs,
      getEmptyLine(),
      getParagraph(upperFirst(totalText)),
    ];
  }
};
