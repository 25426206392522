import React, { useEffect, useMemo } from 'react';
import { OrganizationQuote } from 'src/pages/OrganizationQuote';
import {
  useDisplayedOrganization,
  useOrganizations,
} from 'src/hooks/store/organization.hooks';
import { Navigate, Route } from 'react-router-dom';
import { CreateOrganization } from 'src/pages/CreateOrganization';
import { SentryRoutes } from './sentry';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchAllOrganizations } from 'src/slices/organization';
import { Invitations } from 'src/pages/Invitations';
import { organizationInvitationSelector } from 'src/store/selectors';
import { FullscreenSpin } from 'src/components/basic/FullscreenSpin';
import { LoggedLayout } from 'src/containers/LoggedLayout';
import Settings from 'src/pages/Settings';
import { fetchAllInvitationsbyReceiver } from 'src/slices/organizationInvitation';
import { useAuthenticatedUser } from 'src/hooks/store/auth.hooks';
import Admin from 'src/pages/Admin';
import Dashboard from 'src/pages/Dashboard';
import Client from 'src/pages/Client';
import { PATH_VICTIME } from './paths';
import { BaremePage } from '../pages/BaremePage';
import SubscriptionOptions from 'src/pages/SubscriptionOptions';
import SubscriptionSuccessful from 'src/pages/SubscriptionSuccessful';
import Help from 'src/pages/Help';
import { OrganizationConfiguration } from 'src/pages/OrganizationConfiguration';
import {
  isAutomaticSubscriptionAvailableForOrganization,
  isOrganizationSubscriptionValid,
  isSubscriptionQuoteAvailableForOrganization,
} from 'src/helpers/subscription';

export const OrganizationSwitch: React.FC = () => {
  const dispatch = useAppDispatch();
  const authenticatedUser = useAuthenticatedUser();
  const { isInitialLoading: isOrganizationsInitialLoading, organizations } =
    useOrganizations();
  const { organizationId, organization } = useDisplayedOrganization();
  const { invitations, isInitialLoading: isInvitationInitialLoading } =
    useAppSelector((state) => ({
      invitations: organizationInvitationSelector.selectAll(state),
      isLoading: state.organizationInvitation.isLoading,
      isInitialLoading: state.organizationInvitation.isInitialLoading,
      error: state.organizationInvitation.error,
    }));

  const validOrganizations = useMemo(
    () => organizations.filter(isOrganizationSubscriptionValid),
    [organizations],
  );

  useEffect(() => {
    dispatch(fetchAllOrganizations());
    dispatch(fetchAllInvitationsbyReceiver());
  }, []);

  if (
    (organizations.length === 0 || !organizations[0] || !organization) &&
    (isInvitationInitialLoading || isOrganizationsInitialLoading)
  ) {
    return (
      <LoggedLayout isOrganizationLayout noResources>
        <FullscreenSpin />
      </LoggedLayout>
    );
  }

  const userHasNoOrganization = organizations.length === 0;
  const userHasNoInvitations = invitations.length === 0;
  const organizationSubscriptionNotValid =
    !organization ||
    !isOrganizationSubscriptionValid(organization.subscription);
  const validOrganizationSelected =
    !!organization && !organizationSubscriptionNotValid;
  const automaticSubscriptionAvailable =
    !!organization &&
    isAutomaticSubscriptionAvailableForOrganization(organization);
  const isQuoteAvailable =
    !!organization && isSubscriptionQuoteAvailableForOrganization(organization);
  const getRedirectedRoutes = () =>
    validOrganizationSelected ? (
      <Navigate to={`/organization/${organizationId}/`} replace />
    ) : validOrganizations[0] ? (
      <Navigate to={`/organization/${validOrganizations[0]._id}/`} replace />
    ) : organizations[0] ? (
      <Navigate to={`/organization/${organizations[0]._id}/`} replace />
    ) : userHasNoOrganization && userHasNoInvitations ? (
      <Invitations isWelcomePage />
    ) : userHasNoOrganization ? (
      <CreateOrganization />
    ) : null;
  return (
    <LoggedLayout isOrganizationLayout noResources={!validOrganizationSelected}>
      <SentryRoutes>
        <Route
          path="/organization/:organizationId/*"
          element={
            !!organization ? (
              <SentryRoutes>
                {isQuoteAvailable && (
                  <Route
                    path="/quote"
                    element={
                      <OrganizationQuote
                        isOrganizationActivation={
                          organizationSubscriptionNotValid
                        }
                      />
                    }
                  />
                )}
                {automaticSubscriptionAvailable && (
                  <>
                    <Route
                      path="/subscription"
                      element={<SubscriptionOptions />}
                    />
                    <Route
                      path="/subscription/successful"
                      element={<SubscriptionSuccessful />}
                    />
                  </>
                )}
                {organizationSubscriptionNotValid ? (
                  <Route
                    path="*"
                    element={<OrganizationQuote isOrganizationActivation />}
                  />
                ) : (
                  <>
                    <Route path="/help" element={<Help />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route
                      path="/settings"
                      element={<OrganizationConfiguration />}
                    />
                    <Route path={`${PATH_VICTIME}/*`} element={<Client />} />
                  </>
                )}
                <Route path="*" element={<Navigate to="/" replace />} />
              </SentryRoutes>
            ) : (
              getRedirectedRoutes()
            )
          }
        />
        <Route path="/bareme/:chosenBaremeType" element={<BaremePage />} />
        <Route path="/invitations" element={<Invitations />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/create-organization" element={<CreateOrganization />} />
        {authenticatedUser?.isAdmin ? (
          <Route path="/admin" element={<Admin />} />
        ) : null}
        <Route path="*" element={getRedirectedRoutes()} />
      </SentryRoutes>
    </LoggedLayout>
  );
};
