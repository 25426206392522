import { Paragraph, Table, TableOfContents } from 'docx';
import {
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  RevenuProche,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
  NumberingType,
} from '../../../docxFunctions';
import i18next from 'i18next';
import {
  getMontantRevaloriseWrittenPrint,
  getRevalorisationIntroductionWrittenPrintIfRevalorise,
} from '../../revalorisation.written.print';
import { displayVictimeNameWrittenPrint } from '../../victime.written.print';
import { fDate } from 'src/helpers/formatTime';
import { displayNumerosPiecesWrittenPrint } from '../../numerosPieces.written.print';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';

export const prpVictimeBlesseWrittenPrint = ({
  procedure,
  formData,
  victimesIndirectes,
  monetaryErosions,
}: {
  procedure: Procedure;
  formData: PrejudiceFormPerteRevenusProcheVictimeNonDecedee;
  monetaryErosions: MonetaryErosion[];
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] => {
  const victimeIndirecte =
    victimesIndirectes.find(
      (victime) => victime.lienVictime === 'conjointConcubin',
    ) ?? victimesIndirectes[0];

  const firstPart = i18next.t(
    'prejudice.prejudicesTypes.PERTES_REVENUS_DES_PROCHES.writtenPrint.firstPart',
    {
      victimeName: victimeIndirecte
        ? displayVictimeNameWrittenPrint(victimeIndirecte)
        : '',
      dateNaissance: fDate(victimeIndirecte?.dateNaissance ?? ''),
      lienVictime: i18next.t(
        `victimeIndirecte.form.fields.lienVictime.options.${victimeIndirecte?.lienVictime}`,
      ),
    },
  );

  const perteRevenuPersonnel = i18next.t(
    'prejudice.prejudicesTypes.PERTES_REVENUS_DES_PROCHES.writtenPrint.perteRevenuPersonnel',
    {
      numerosPieces: displayNumerosPiecesWrittenPrint(
        formData.perteRevenuPersonnel.numerosPieces,
        'Parentheses',
      ),
      anneeRevenu: formData.perteRevenuPersonnel.annee,
      revalorisation:
        procedure.dateLiquidation &&
        formData.perteRevenuPersonnel.annee &&
        !formData.perteRevenuPersonnel.dejaRevalorise
          ? getMontantRevaloriseWrittenPrint({
              montant: formData.perteRevenuPersonnel.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation,
              anneeOrDateMontant: formData.perteRevenuPersonnel.annee,
              coefficientsType: formData.coefficientsType,
              monetaryErosions,
            })
          : '',
      revenue: fCurrency(formData.perteRevenuPersonnel.montant),
    },
  );

  const montantATPADeduire = i18next.t(
    'prejudice.prejudicesTypes.PERTES_REVENUS_DES_PROCHES.writtenPrint.montantATPADeduire',
    {
      numerosPieces: displayNumerosPiecesWrittenPrint(
        formData.montantATPADeduire.numerosPieces,
        'Parentheses',
      ),
      anneeRevenu: formData.montantATPADeduire.annee,
      revalorisation:
        procedure.dateLiquidation &&
        formData.montantATPADeduire.annee &&
        !formData.montantATPADeduire.dejaRevalorise
          ? getMontantRevaloriseWrittenPrint({
              montant: formData.montantATPADeduire.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation,
              anneeOrDateMontant: formData.montantATPADeduire.annee,
              coefficientsType: formData.coefficientsType,
              monetaryErosions,
            })
          : '',
      montantATP: fCurrency(formData.montantATPADeduire.montant),
    },
  );

  const perteDroitRetraite = i18next.t(
    'prejudice.prejudicesTypes.PERTES_REVENUS_DES_PROCHES.writtenPrint.perteDroitRetraite',
    {
      numerosPieces: displayNumerosPiecesWrittenPrint(
        formData.perteDroitRetraite.numerosPieces,
        'Parentheses',
      ),
      anneeRevenu: formData.perteDroitRetraite.annee,
      revalorisation:
        procedure.dateLiquidation &&
        formData.perteDroitRetraite.annee &&
        !formData.perteDroitRetraite.dejaRevalorise
          ? getMontantRevaloriseWrittenPrint({
              montant: formData.perteDroitRetraite.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation,
              anneeOrDateMontant: formData.perteDroitRetraite.annee,
              coefficientsType: formData.coefficientsType,
              monetaryErosions,
            })
          : '',
      perteDroitRetraite: fCurrency(formData.perteDroitRetraite.montant),
    },
  );

  const surcroitActivite = i18next.t(
    'prejudice.prejudicesTypes.PERTES_REVENUS_DES_PROCHES.writtenPrint.surcroitActivite',
    {
      anneeRevenu: formData.surcroitActivite.annee,
      revalorisation:
        procedure.dateLiquidation &&
        formData.surcroitActivite.annee &&
        !formData.surcroitActivite.dejaRevalorise
          ? getMontantRevaloriseWrittenPrint({
              montant: formData.surcroitActivite.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation,
              anneeOrDateMontant: formData.surcroitActivite.annee,
              coefficientsType: formData.coefficientsType,
              monetaryErosions,
            })
          : '',
      surcroitActivite: fCurrency(formData.surcroitActivite.montant),
    },
  );

  const perteChanceAssistanceConjoint = i18next.t(
    'prejudice.prejudicesTypes.PERTES_REVENUS_DES_PROCHES.writtenPrint.perteChanceAssistanceConjoint',
    {
      anneeRevenu: formData.perteChanceAssistanceConjoint.annee,
      perteChanceAssistanceConjoint: fCurrency(
        formData.perteChanceAssistanceConjoint.montant,
      ),
      revalorisation:
        procedure.dateLiquidation &&
        formData.perteChanceAssistanceConjoint.annee &&
        !formData.perteChanceAssistanceConjoint.dejaRevalorise
          ? getMontantRevaloriseWrittenPrint({
              montant: formData.perteChanceAssistanceConjoint.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation,
              anneeOrDateMontant: formData.perteChanceAssistanceConjoint.annee,
              coefficientsType: formData.coefficientsType,
              monetaryErosions,
            })
          : '',
    },
  );

  const indemnite = i18next.t(
    'prejudice.prejudicesTypes.PERTES_REVENUS_DES_PROCHES.writtenPrint.indemnite',
    {
      formule: fCurrency(formData.partResponsableTotal),
      total: fCurrency(formData.total),
      fractionIndemnisable: fPercent(formData.partResponsabilite * 100),
    },
  );

  const getNumberedParagraph = (text: string) => {
    return getParagraph({
      numbering: {
        reference: NumberingType.SIMPLE_LIST,
        level: 0,
      },
      children: [...getTextRun(text)],
    });
  };

  const getRevenuProcheParagraph = (
    typeRevenu: RevenuProche,
    typeRevenuText: string,
  ): Paragraph[] => {
    return !!(typeRevenu.montant && typeRevenu.annee)
      ? [getNumberedParagraph(typeRevenuText)]
      : [];
  };

  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      revalorisationCoefficientsType: formData.coefficientsType ?? 'annuel',
      formData: formData,
      formType: 'PERTES_REVENUS_PROCHE',
      isVictimeIndirecte: true,
    }),
    getEmptyLine(),
    getParagraph(firstPart),
    ...getRevenuProcheParagraph(
      formData.perteRevenuPersonnel,
      perteRevenuPersonnel,
    ),
    ...getRevenuProcheParagraph(
      formData.montantATPADeduire,
      montantATPADeduire,
    ),
    ...getRevenuProcheParagraph(
      formData.perteDroitRetraite,
      perteDroitRetraite,
    ),
    ...getRevenuProcheParagraph(formData.surcroitActivite, surcroitActivite),
    ...getRevenuProcheParagraph(
      formData.perteChanceAssistanceConjoint,
      perteChanceAssistanceConjoint,
    ),
    getEmptyLine(),
    getParagraph(indemnite),
  ];
};
