import { styled, Table } from '@mui/material';

export const LayoutTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-root': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root	': {
    height: 40,
    backgroundColor: theme.palette.background.default,
  },
}));
