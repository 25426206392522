import { Helmet } from 'react-helmet-async';
import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { createUseStyles } from 'react-jss';

// ----------------------------------------------------------------------

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    width: '100%',
  },
});

interface Props {
  children: React.ReactNode;
  title: string;
}

// eslint-disable-next-line react/display-name
const Page = forwardRef<any, Props>(
  ({ children, title = '', ...other }, ref) => {
    const classes = useStyles();

    return (
      <Box className={classes.pageContainer} ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </Box>
    );
  },
);

export default Page;
