import { Paragraph, Table, TableOfContents } from 'docx';
import { CalculsFormNewPerteGainsProfessionnelsFuturs } from 'src/constants/calculs';
import { Bareme } from 'src/types/bareme.type';
import { NewPrejudiceFormPerteGainProfessionnelsFuturs } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine } from '../../../docxFunctions';
import { prejudiceNotesPrint } from '../../prejudiceNotesPrint';
import { Victime } from 'src/types/victime.type';
import { totalCapitalisationPrint } from '../../totalCapitalisationPrint';
import { tableRecapitulatifPrint } from './tableRecapitulatifPrint';
import { stepsPrint } from './stepsPrint';
import { debitSoldeAEchoirPrint } from '../../debitSoldeAEchoirPrint';
import i18next from 'i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { rentesPrint } from '../../rentesPrint';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';

export const newPerteGainsProfessionnelsFutursPrint = (
  victime: Victime,
  procedure: Procedure,
  formData: NewPrejudiceFormPerteGainProfessionnelsFuturs,
  baremes: Bareme[],
  monetaryErosions: MonetaryErosion[],
): (Paragraph | Table | TableOfContents)[] => {
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const indemniteGlobaleARepartir =
    CalculsFormNewPerteGainsProfessionnelsFuturs.getIndemnitesRepartie({
      ...formData,
      tiersPayeursTotalCapitalise: formData.tiersPayeursTotalCapitalise,
      perteGainProfessionnelsTotal: formData.perteDeGainsProfessionnels.total,
      indemnitesJournalieresPercuesNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .indemnitesJournalieresPercuesNet,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
  const tableRecapitulatif = tableRecapitulatifPrint(
    formData,
    procedure.partResponsabilite,
    true,
  );
  const steps = stepsPrint({
    formData,
    monetaryErosions,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : null,
    isPGPF: true,
  });
  const debitSoldeTable = debitSoldeAEchoirPrint({
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
    displayTropPercu: true,
    PGPFReliquat: indemniteGlobaleARepartir.PGPFReliquat,
  });
  const bareme = baremes.find(
    (bareme) => bareme._id === formData.baremeCapitalisation,
  );
  const totalCapitalisation = totalCapitalisationPrint({
    title: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.print.title',
    ),
    victime,
    procedure,
    titleVictime: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.title',
    ),
    titleTiersPayeurs: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.tiersPayeurs.title',
    ),
    victimeSommeACapitaliser: {
      value: formData.victimeSommeACapitaliser,
      label: i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.sommeACapitaliser.title',
      ),
    },
    victimeCoefficientCapitalisation: {
      value: formData.prixEuroRente,
      label: i18next.t(
        'prejudice.capitalisation.form.fields.coefficient.title',
      ),
    },
    bareme: bareme && {
      value: bareme,
    },
    tiersPayeursTotalCapitalise: {
      value: formData.tiersPayeursTotalCapitalise,
      label: i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.tiersPayeurs.montantCapitalise.title',
      ),
    },
    victimeTotalCapitalise: {
      value: formData.renteCapitalisee,
      label: i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.montantCapitalise.title',
      ),
    },
    ageDernierArrerage: {
      value: formData.ageDernierArrerage,
    },
    isLastArrerageViager: {
      value: formData.isLastArrerageViager,
    },
    tiersPayeursSommeACapitaliserLabel: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.tiersPayeurs.sommeACapitaliser.title',
    ),
    tiersPayeursCoefficientCapitalisationLabel: i18next.t(
      'prejudice.capitalisation.form.fields.coefficient.title',
    ),
    tiersPayeurs: formData.capitalisationTiersPayeurs.parTiersPayeur,
    totalCapitalise: {
      value: formData.capitalConstitutifRente,
      label: i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.total.montantCapitalise.title',
      ),
    },
    titleTotal: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.total.title',
    ),
    allTiersPayeurs: procedure.tiersPayeurs,
  });
  const rentes =
    formData.rentes?.montant || formData.rentes?.montant === 0
      ? rentesPrint({ montant: formData.rentes.montant })
      : [];
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
  });
  return [
    getEmptyLine(),
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...steps,
    getEmptyLine(),
    ...tableRecapitulatif,
    getEmptyLine(),
    ...(!formData.isRentesOption && !shouldNotDisplayCapitalisation
      ? [...totalCapitalisation, getEmptyLine()]
      : []),
    ...debitSoldeTable,
    ...(formData.isRentesOption === true && !shouldNotDisplayCapitalisation
      ? [getEmptyLine(), ...rentes]
      : []),
  ];
};
