import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import * as yup from 'yup';
import { TextFieldForm } from '../forms/TextFieldForm';
import { Button, Stack, Typography } from '@mui/material';
import { handleNotification } from 'src/helpers/notifications';
import { SendHelpRequestDto } from 'src/types/auth.type';
import { sendHelpRequest } from 'src/slices/auth';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
export const SendHelpRequest: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { organization } = useDisplayedOrganization();
  const { control, handleSubmit, reset } = useForm<SendHelpRequestDto>({
    mode: 'onSubmit',
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        message: yup.string().required(),
      }),
    ),
  });

  const onSubmit = async (data: SendHelpRequestDto) => {
    await handleNotification(
      dispatch(
        sendHelpRequest({
          dto: data,
          organizationId: organization?._id,
        }),
      ),
      t('pages.Help.sendHelpRequest.success'),
      t('pages.Help.sendHelpRequest.error'),
      enqueueSnackbar,
      closeSnackbar,
    );
    reset();
  };
  return (
    <Stack
      spacing={2}
      direction="column"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      maxWidth={560}
    >
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        {t('pages.Help.sendHelpRequest.description')}
      </Typography>
      <TextFieldForm
        control={control}
        name="message"
        label={`${t('pages.Help.sendHelpRequest.message.label')}*`}
        fullWidth
        multiline
        rows={4}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ alignSelf: 'end' }}
      >
        {t('common.send')}
      </Button>
    </Stack>
  );
};
