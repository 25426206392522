import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { Bareme } from 'src/types/bareme.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { getAgeDateAttribution } from 'src/helpers/prejudices/capitalisation';
import { AgeOrZero } from 'src/constants/calculs/type';
import { getParagraph, getTextRun } from '../docxFunctions';
import { simpleVerticalTablePrint } from '../simpleVerticalTablePrint';
import { fCoefficientCapitalisation } from 'src/helpers/formatValues';

type CapitalisationField<T> = {
  value: T;
  label?: string;
};

type CapitalisationFieldDefinedLabel<T> = Omit<
  CapitalisationField<T>,
  'label'
> & {
  label: string;
};

export const totalCapitalisationPrint = ({
  victime,
  procedure,
  title,
  titleVictime,
  titleTiersPayeurs,
  titleTotal,
  tiersPayeursSommeACapitaliserLabel,
  tiersPayeursCoefficientCapitalisationLabel,
  tiersPayeursMontantCapitaliseLabel,
  tiersPayeurs,
  allTiersPayeurs,
  ...props
}: {
  victime: Victime;
  procedure: Procedure;
  title?: string;
  titleVictime?: string;
  titleTiersPayeurs?: string;
  titleTotal?: string;
  ageLabel?: string;
  tiersPayeursSommeACapitaliserLabel?: string;
  tiersPayeursCoefficientCapitalisationLabel?: string;
  tiersPayeursMontantCapitaliseLabel?: string;
  victimeSommeACapitaliser: CapitalisationField<number>;
  bareme?: CapitalisationField<Bareme>;
  isLastArrerageViager: CapitalisationField<boolean | undefined>;
  ageDernierArrerage: CapitalisationField<number | null | undefined>;
  victimeCoefficientCapitalisation: CapitalisationField<number | null>;
  victimeTotalCapitalise: CapitalisationField<number>;
  tiersPayeurs?: {
    tiersPayeur: string;
    sommeACapitaliser?: number;
    coefficient: number | null;
    montantCapitalise?: number;
  }[];
  tiersPayeursTotalCapitalise?: CapitalisationField<number>;
  totalCapitalise?: CapitalisationField<number>;
  ageDateAttribution?: CapitalisationField<AgeOrZero | null | undefined>;
  allTiersPayeurs: string[];
}): (Table | TableOfContents | Paragraph)[] => {
  const {
    victimeSommeACapitaliser,
    bareme,
    isLastArrerageViager,
    ageDernierArrerage,
    victimeCoefficientCapitalisation,
    victimeTotalCapitalise,
    tiersPayeursTotalCapitalise,
    totalCapitalise,
    ageDateAttribution,
  }: {
    ageDateAttribution: CapitalisationFieldDefinedLabel<AgeOrZero | null>;
    victimeSommeACapitaliser: CapitalisationFieldDefinedLabel<number>;
    bareme?: CapitalisationFieldDefinedLabel<Bareme>;
    isLastArrerageViager: CapitalisationFieldDefinedLabel<boolean | undefined>;
    ageDernierArrerage: CapitalisationFieldDefinedLabel<
      number | null | undefined
    >;
    victimeCoefficientCapitalisation: CapitalisationFieldDefinedLabel<
      number | null
    >;
    victimeTotalCapitalise: CapitalisationFieldDefinedLabel<number>;
    tiersPayeursTotalCapitalise?: CapitalisationFieldDefinedLabel<number>;
    totalCapitalise?: CapitalisationFieldDefinedLabel<number>;
    partResponsableTotalCapitalise?: CapitalisationFieldDefinedLabel<number>;
  } = {
    victimeSommeACapitaliser: {
      label: i18next.t(
        'prejudice.capitalisation.form.fields.sommeACapitaliser.title',
      ),
      ...props.victimeSommeACapitaliser,
    },
    bareme: props.bareme && {
      label: i18next.t(
        'prejudice.capitalisation.form.fields.bareme.print.title',
      ),
      ...props.bareme,
    },
    isLastArrerageViager: {
      label: i18next.t(
        'prejudice.capitalisation.form.fields.isLastArrerageViager.print.value',
      ),
      ...props.isLastArrerageViager,
    },
    ageDernierArrerage: {
      label:
        i18next.t(
          'prejudice.capitalisation.form.fields.ageDernierArrerage.title',
        ) || '',
      ...props.ageDernierArrerage,
    },
    victimeCoefficientCapitalisation: {
      label: i18next.t(
        'prejudice.capitalisation.form.fields.coefficient.otherTitle',
      ),
      ...props.victimeCoefficientCapitalisation,
    },
    victimeTotalCapitalise: {
      label: i18next.t(
        'prejudice.capitalisation.form.fields.montantCapitalise.title',
      ),
      ...props.victimeTotalCapitalise,
    },
    ...(!!props.tiersPayeursTotalCapitalise && {
      tiersPayeursTotalCapitalise: {
        label: i18next.t(
          'prejudice.capitalisation.form.fields.montantCapitalise.tiersPayeurs.title',
        ),
        ...props.tiersPayeursTotalCapitalise,
      },
    }),
    totalCapitalise: props.totalCapitalise && {
      label: i18next.t(
        'prejudice.capitalisation.form.fields.montantCapitalise.title',
      ),
      ...props.totalCapitalise,
    },
    ageDateAttribution: {
      label:
        props.ageDateAttribution?.label ??
        i18next.t(
          'prejudice.capitalisation.form.fields.ageDateAttribution.title',
        ),
      value:
        props.ageDateAttribution?.value !== undefined
          ? props.ageDateAttribution.value
          : victime.dateNaissance && procedure.dateLiquidation
            ? getAgeDateAttribution({
                dateNaissance: victime.dateNaissance
                  ? new Date(victime.dateNaissance)
                  : undefined,
                dateLiquidation: procedure.dateLiquidation
                  ? new Date(procedure.dateLiquidation)
                  : undefined,
                dateDeces: victime.dateDeces
                  ? new Date(victime.dateDeces)
                  : undefined,
              })
            : null,
    },
  };

  return [
    ...(title
      ? [
          getParagraph({
            children: getTextRun({
              text: title,
              bold: true,
            }),
          }),
        ]
      : []),
    simpleVerticalTablePrint([
      ...(titleVictime
        ? [
            {
              title: titleVictime,
            },
          ]
        : []),
      {
        label: ageDateAttribution.label,
        value: ageDateAttribution.value
          ? i18next.t('forms.fields.age.value', {
              count: ageDateAttribution.value.age,
              context: ageDateAttribution.value.isExactlyZero
                ? 'exactly_zero'
                : undefined,
            })
          : '',
      },
      {
        label: victimeSommeACapitaliser.label,
        value: fCurrency(victimeSommeACapitaliser.value),
      },
      ...(bareme
        ? [
            {
              label: bareme.label,
              value: getCapitalisationBaremeSourceLabel(bareme.value),
            },
          ]
        : []),
      {
        label: ageDernierArrerage.label,
        value:
          isLastArrerageViager.value === true
            ? isLastArrerageViager.label
            : ageDernierArrerage.value || ageDernierArrerage.value === 0
              ? i18next.t('forms.fields.age.value', {
                  count: ageDernierArrerage.value || 0,
                })
              : '',
      },
      {
        label: victimeCoefficientCapitalisation.label,
        value: victimeCoefficientCapitalisation.value
          ? fCoefficientCapitalisation(victimeCoefficientCapitalisation.value)
          : '',
      },
      {
        label: victimeTotalCapitalise.label,
        value: fCurrency(victimeTotalCapitalise.value),
      },
      ...(Boolean(!!tiersPayeurs || !!tiersPayeursTotalCapitalise)
        ? [
            {
              title:
                titleTiersPayeurs ||
                i18next.t('prejudice.capitalisation.form.tiersPayeurs.title'),
            },
          ]
        : []),
      ...(allTiersPayeurs?.reduce(
        (
          accumulator: Parameters<typeof simpleVerticalTablePrint>[0],
          tiersPayeurName,
        ) => {
          const tiersPayeur = tiersPayeurs?.find(
            (tiersPayeur) => tiersPayeur.tiersPayeur === tiersPayeurName,
          );
          if (!tiersPayeur) {
            return accumulator;
          }
          return [
            ...accumulator,
            {
              title: tiersPayeur.tiersPayeur,
            },
            {
              label:
                tiersPayeursSommeACapitaliserLabel ||
                i18next.t(
                  'prejudice.capitalisation.form.fields.sommeACapitaliser.title',
                ),
              value: fCurrency(tiersPayeur.sommeACapitaliser || 0),
            },
            {
              label:
                tiersPayeursCoefficientCapitalisationLabel ||
                i18next.t(
                  'prejudice.capitalisation.form.fields.coefficient.title',
                ),
              value: fDecimalNumber(tiersPayeur.coefficient || 0, 3),
            },
            {
              label:
                tiersPayeursMontantCapitaliseLabel ||
                i18next.t(
                  'prejudice.capitalisation.form.fields.montantCapitalise.title',
                ),
              value: fCurrency(tiersPayeur.montantCapitalise || 0),
            },
          ];
        },
        [],
      ) || []),
      ...(!!tiersPayeursTotalCapitalise
        ? [
            {
              label: tiersPayeursTotalCapitalise.label,
              value: fCurrency(tiersPayeursTotalCapitalise.value),
            },
          ]
        : []),
      ...(titleTotal
        ? [
            {
              title:
                titleTotal ||
                i18next.t('prejudice.capitalisation.form.total.title'),
            },
          ]
        : []),
      ...(totalCapitalise
        ? [
            {
              title: `${totalCapitalise.label} : ${fCurrency(
                totalCapitalise.value,
              )}`,
            },
          ]
        : []),
    ]),
  ];
};
