import { isDate } from 'lodash';

export const parseDate = (date: Date | string | undefined) => {
  let parsedDate: Date | undefined;
  if (typeof date === 'string') {
    parsedDate = new Date(date);
  } else {
    parsedDate = date;
  }
  if (!isDate(parsedDate)) {
    return undefined;
  }
  return parsedDate;
};

export const parseYear = (date: Date | string | number | undefined) => {
  if (typeof date === 'number') {
    return date;
  } else {
    const parsedDate = parseDate(date);
    if (!parsedDate) {
      return undefined;
    }
    return parsedDate.getFullYear();
  }
};
