import { CellObject, ExcelDataType, WorkBook, WorkSheet, utils } from 'xlsx';

export type WorkSheetWithName = { sheet: WorkSheet; name: string };
export const createWorkbook = (
  sheetsWithName: Array<WorkSheetWithName>,
): WorkBook => {
  const newBook = utils.book_new();
  for (const sheetWithName of sheetsWithName) {
    utils.book_append_sheet(newBook, sheetWithName.sheet, sheetWithName.name);
  }
  return newBook;
};

export const createWorkSheet = (
  data: CellObject[][],
  name: string,
): WorkSheetWithName => {
  const newSheet = utils.aoa_to_sheet(data ?? []);
  const colWidths = getMaxWidths(newSheet);
  newSheet['!cols'] = colWidths;
  return { sheet: newSheet, name };
};

const getMaxWidths = (worksheet: WorkSheet): { wch: number }[] => {
  const colWidths: { wch: number }[] = [];
  const range = utils.decode_range(worksheet['!ref'] as string);
  for (let columnRange = range.s.c; columnRange <= range.e.c; ++columnRange) {
    let maxWidth = 10;
    for (let rowRange = range.s.r; rowRange <= range.e.r; ++rowRange) {
      const cellAddress = { c: columnRange, r: rowRange };
      const cellRef = utils.encode_cell(cellAddress);
      const cell: CellObject | undefined = worksheet[cellRef];
      if (cell && cell.v) {
        const cellValue = cell.v.toString();
        maxWidth = Math.max(maxWidth, cellValue.length);
      }
    }
    colWidths[columnRange] = { wch: maxWidth };
  }
  return colWidths;
};

type CellType = string | number | undefined;

export const toCellObject = (
  value: CellType,
  type: ExcelDataType = 's',
): CellObject => ({
  v: value,
  t: type,
});

export const toArrayStringCell = (values: CellType[]): CellObject[] =>
  values.map((x) => toCellObject(x));
