import React, { useEffect } from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fr } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';
import { setLocale } from 'yup';
import { useAppDispatch, useAppSelector } from './hooks/store';
import { fetchCurrentUser } from './slices/auth';
import { GuestSwitch } from './routes/GuestSwitch';
import { LoggedSwitch } from './routes/LoggedSwitch';
import { FullscreenSpin } from './components/basic/FullscreenSpin';
import './locales/i18n';
import ThemeConfig from './constants/theme';
import GlobalStyles from './constants/theme/globalStyles';
import yupFr from './locales/yup';
import 'src/helpers/yup';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MobileAccess } from './containers/MobileAccess';
import {
  BrowserRouter as Router,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://6904304b616c6553a598e084a09b1671@o4506909259923456.ingest.us.sentry.io/4506910320885760',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

/* Yup locale */
setLocale(yupFr);

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isLogged, isInitialLoading, isLoading } = useAppSelector(
    ({ auth: { isLogged, isInitialLoading, isLoading } }) => ({
      isLogged,
      isInitialLoading,
      isLoading,
    }),
  );

  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    if (!isLogged && !isLoading && isInitialLoading) {
      dispatch(fetchCurrentUser());
    }
  }, [isLogged, isLoading, isInitialLoading]);

  return (
    <>
      <ThemeConfig>
        <GlobalStyles />
        <I18nextProvider i18n={i18next}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <SnackbarProvider
              ref={notistackRef}
              action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                  <CloseIcon />
                </IconButton>
              )}
              maxSnack={4}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              TransitionComponent={Collapse}
            >
              <GoogleOAuthProvider
                clientId={process.env.VITE_APP_GOOGLE_CLIENT_ID || ''}
                onScriptLoadSuccess={() => {
                  console.log('success');
                }}
                onScriptLoadError={() => {
                  console.log('error');
                }}
              >
                <MobileAccess>
                  {isInitialLoading ? (
                    <FullscreenSpin />
                  ) : (
                    <Router>
                      {isLogged ? <LoggedSwitch /> : <GuestSwitch />}
                    </Router>
                  )}
                </MobileAccess>
              </GoogleOAuthProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </I18nextProvider>
      </ThemeConfig>
    </>
  );
};

export default App;
