import {
  MenuItem,
  Select,
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import { getRIIBaremeSourceNameLabel } from 'src/helpers/bareme/bareme';
import { sortBaremesRII } from 'src/helpers/bareme/sortAndOrder';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import {
  Bareme,
  BaremeRIIValue,
  BaremeSource,
  Gender,
  genders,
} from 'src/types/bareme.type';
interface Props {
  baremes: Bareme[];
}
export const BaremeRII: React.FC<Props> = ({ baremes }) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedGender, setSelectedGender] =
    useState<Exclude<Gender, 'undetermined'>>('man');
  useEffect(() => {
    setSelectedGender(
      ((
        (baremes?.[tabIndex]?.values as BaremeRIIValue[] | undefined)?.filter(
          (value: BaremeRIIValue) => value?.gender !== 'undetermined',
        )?.[0] as BaremeRIIValue | undefined
      )?.gender as 'man' | 'woman' | 'all') || 'man',
    );
  }, [baremes, tabIndex]);

  const sortedBaremes = useMemo(() => sortBaremesRII(baremes), [baremes]);

  const onChangeTabIndex = (
    _: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setTabIndex(value);
  };
  const getAgeString = (value: BaremeRIIValue['rows'][number]) => {
    if (value.age) {
      return `${value.age} ans`;
    } else if (value.fromAge && value.toAge) {
      return `${value.fromAge} à ${value.toAge} ans`;
    } else if (value.fromAge) {
      return `${value.fromAge} ans et plus`;
    } else if (value.toAge) {
      return `moins de ${value.toAge} ans`;
    }
  };

  const getPercentageString = (
    value: BaremeRIIValue['rows'][number]['columns'][number],
  ) => {
    if (value.percentage) {
      return `${value.percentage}%`;
    } else if (value.fromPercentage && value.toPercentage) {
      return `${value.fromPercentage} à ${value.toPercentage}%`;
    } else if (value.fromPercentage) {
      return `${value.fromPercentage}% et plus`;
    } else if (value.toPercentage) {
      return `moins de ${value.toPercentage}%`;
    }
  };
  const displayMornetTable = (rows: BaremeRIIValue['rows']) => (
    <NormalTable stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell
            variant="head"
            sx={{
              minWidth: '100px',
              position: 'sticky',
              left: 0,
              zIndex: 3,
            }}
          />
          {rows?.map((row, index) => (
            <TableCell key={index} sx={{ minWidth: '100px' }} align="center">
              {getAgeString(row)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: rows[0]?.columns.length || 0 })?.map(
          (_, index) => {
            const percentage = rows[0]?.columns[index];
            return (
              <TableRow key={index}>
                <TableCell
                  variant="head"
                  sx={{
                    minWidth: '100px',
                    position: 'sticky',
                    left: 0,
                  }}
                >
                  {percentage && getPercentageString(percentage)}
                </TableCell>
                {rows?.map((row, cellIndex) => (
                  <TableCell key={cellIndex} align="center">
                    {row.columns[index] &&
                      fDecimalNumber(row.columns[index]?.value || 0)}
                  </TableCell>
                ))}
              </TableRow>
            );
          },
        )}
      </TableBody>
    </NormalTable>
  );

  const displayOniamTable = (rows: BaremeRIIValue['rows']) => (
    <NormalTable stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell
            variant="head"
            sx={{
              minWidth: '100px',
              position: 'sticky',
              left: 0,
              zIndex: 3,
            }}
          />
          {rows?.[0]?.columns?.map((percentageValues, index) => (
            <TableCell key={index} sx={{ minWidth: '100px' }} align="center">
              {getPercentageString(percentageValues)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row, index) => (
          <TableRow key={index}>
            <TableCell
              variant="head"
              sx={{
                minWidth: '100px',
                position: 'sticky',
                left: 0,
              }}
            >
              {getAgeString(row)}
            </TableCell>
            {row?.columns?.map((percentageValues, index) => (
              <TableCell key={index} align="center">
                {fCurrency(percentageValues.value || 0)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </NormalTable>
  );
  const displayAdditionalSourceLabel = (source?: BaremeSource) => {
    const label = t(`bareme.type.RII.additionalLabels.source.${source}`, {
      defaultValue: '',
    });
    if (label) {
      return (
        <Typography paddingX={2} paddingY={3} fontWeight="bold">
          {label}
        </Typography>
      );
    }
  };
  return (
    <Stack sx={{ maxWidth: '100%', height: '100%' }}>
      <Tabs value={tabIndex} onChange={onChangeTabIndex} variant="scrollable">
        {sortedBaremes.map((bareme, index) => (
          <Tab
            key={index}
            label={getRIIBaremeSourceNameLabel(bareme)}
            sx={{ maxWidth: 'none', textTransform: 'none' }}
          />
        ))}
      </Tabs>
      {displayAdditionalSourceLabel(sortedBaremes?.[tabIndex]?.source)}
      {sortedBaremes.map((bareme, index) => (
        <Stack
          key={index}
          sx={{
            display: tabIndex === index ? '' : 'none',
            maxWidth: '100%',
            height: '100%',
          }}
        >
          {Boolean(
            bareme.values.some(
              (value) =>
                (value as BaremeRIIValue).gender !== undefined &&
                (value as BaremeRIIValue).gender !== 'all',
            ),
          ) && (
            <Select<'man' | 'woman' | 'all'>
              value={selectedGender}
              onChange={(event) =>
                setSelectedGender(event.target.value as 'man' | 'woman' | 'all')
              }
              sx={{
                marginTop: 1,
                marginLeft: 1,
                width: '150px',
              }}
            >
              {genders
                .filter(
                  (gender) => gender !== 'all' && gender !== 'undetermined',
                )
                .map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {t(`bareme.genders.${value}`)}
                  </MenuItem>
                ))}
            </Select>
          )}
          {(bareme.values as BaremeRIIValue[]).map((value, index) =>
            value.type === 'percentage' && value.rows ? (
              <TableContainer
                key={index}
                sx={{
                  display: value.gender !== selectedGender ? 'none' : undefined,
                  height: '100%',
                }}
              >
                {bareme.source === 'ONIAM'
                  ? displayOniamTable(value.rows)
                  : displayMornetTable(value.rows)}
              </TableContainer>
            ) : value.pointPrices ? (
              <TableContainer key={index}>
                <NormalTable stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        variant="head"
                        sx={{
                          minWidth: '100px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 3,
                        }}
                      >
                        {t('bareme.type.RIIValues.degree.label')}
                      </TableCell>
                      <TableCell
                        variant="head"
                        sx={{
                          minWidth: '100px',
                          position: 'sticky',
                          left: '100px',
                          zIndex: 3,
                        }}
                      >
                        {t('bareme.type.RIIValues.pointPrice.label')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {value.pointPrices.map((pointPrice, index) => (
                      <TableRow key={index}>
                        <TableCell>{pointPrice.degree}</TableCell>
                        <TableCell>
                          {fCurrency(pointPrice.pointPrice)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </NormalTable>
              </TableContainer>
            ) : null,
          )}
        </Stack>
      ))}
    </Stack>
  );
};
