import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import {
  deleteVictimeById,
  fetchAllVictimes,
  updateVictime,
} from 'src/slices/victime';

import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import Sidebar from 'src/components/basic/Sidebar';
import { useNavigate } from 'react-router-dom';
import { victimeSelectors } from '../store/selectors';
import { ConfirmationDialog } from '../components/basic/ConfirmationDialog';
import { VictimMenu } from '../components/dashboard/VictimMenu';
import { Victime } from 'src/types/victime.type';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';

const useStyles = createUseStyles({
  dossierEditionHeader: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  dossierEditionStackInputs: {
    margin: theme.spacing(2, 0),
  },
  dossierEditionActions: {},
  dossierEditionActionsCancel: {},
  dossierEditionActionsSave: {},
  tableContainer: {
    padding: '24px',
    position: 'relative',
    top: '64px',
  },
  firstColumnTable: {
    paddingLeft: '0px',
  },
  buttonAddFolder: {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    position: 'fixed',
    bottom: 24,
    right: 24,
  },
  tableHead: {
    '& .MuiTableCell-root': {
      color: '#19A3FF',
      fontWeight: 'heavy',
    },
  },
  rowTable: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  nameRowTable: {
    fontWeight: 600,
  },
});

interface Props {
  isSidebar?: boolean;
  isOpen: boolean | undefined;
  closeSideBar: () => void;
}

const VictimTrashCanDialog: React.FC<Props> = ({
  isSidebar,
  isOpen,
  closeSideBar,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organization } = useDisplayedOrganization();

  const [deleteVictimConfirmDialog, setDeleteVictimConfirmDialog] =
    useState(false);

  const [cleanTrashConfirmDialog, setCleanTrashConfirmDialog] = useState(false);

  const [recycleVictimConfirmDialog, setRecycleVictimConfirmDialog] =
    useState(false);
  const [selectedVictim, setSelectedVictim] = useState<string | null>(null);

  const redirect = (victimeId: string) => {
    navigate(`/client/${victimeId}`);
  };

  const victimes = useAppSelector(victimeSelectors.selectAll);

  const [victimeRows, setDossierRows] = useState<
    {
      id: string;
      name: string;
      creationDate: string;
      editDate: string;
      status: string;
      victime: Victime;
    }[]
  >([]);

  const fetchVictimes = () => dispatch(fetchAllVictimes(organization?._id));

  useEffect(() => {
    fetchVictimes();
  }, [organization?._id]);

  useEffect(() => {
    setDossierRows(
      victimes
        .filter((victime) => victime.inTrash)
        .map((victime) => ({
          id: victime._id,
          name: victime.referenceDossierVictime,
          creationDate: new Date(victime.createdAt).toLocaleDateString(),
          editDate: new Date(victime.updatedAt).toLocaleDateString(),
          status: victime.statut,
          victime,
        })),
    );
  }, [victimes]);

  const deleteVictim = async () => {
    if (selectedVictim) {
      await dispatch(deleteVictimeById(selectedVictim));
      setDeleteVictimConfirmDialog(false);
      fetchVictimes();
    }
  };

  const recycleVictimInTrash = async () => {
    if (selectedVictim) {
      await dispatch(
        updateVictime({ id: selectedVictim, data: { inTrash: false } }),
      );
      setRecycleVictimConfirmDialog(false);
      fetchVictimes();
    }
  };

  const cleanTheTrash = () => {
    victimeRows.forEach((victime) => {
      dispatch(deleteVictimeById(victime.id));
    });
    setCleanTrashConfirmDialog(false);
    fetchVictimes();
  };

  const header = (
    <Typography variant="h4" className={classes.dossierEditionHeader}>
      Restaurer ou Supprimer définitivement des dossiers de victime
    </Typography>
  );

  const body = (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell align="left" className={classes.firstColumnTable}>
                #
              </TableCell>
              <TableCell align="left">
                {t('dashboard.table.referenceDossierVictime')}
              </TableCell>
              <TableCell align="left">
                {t('dashboard.table.creation')}
              </TableCell>
              <TableCell align="left">{t('dashboard.table.edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {victimeRows.map((row, index) => (
              <TableRow
                key={index}
                className={classes.rowTable}
                onClick={() => redirect(row.id)}
              >
                <TableCell align="left" className={classes.firstColumnTable}>
                  {index + 1}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.nameRowTable}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.creationDate}</TableCell>
                <TableCell align="left">{row.editDate}</TableCell>
                <TableCell align="left">
                  <VictimMenu
                    victime={row.victime}
                    setVictimToDelete={setSelectedVictim}
                    setDeleteVictimConfirmDialog={setDeleteVictimConfirmDialog}
                    setRecycleVictimConfirmDialog={
                      setRecycleVictimConfirmDialog
                    }
                    isTrash
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={deleteVictimConfirmDialog}
        onCancel={() => setDeleteVictimConfirmDialog(false)}
        onConfirm={deleteVictim}
        title="Supprimer ce dossier"
        description="Voulez-vous vraiment supprimer ce dossier ?"
        confirmText="Supprimer"
        cancelText="Annuler"
      />
      <ConfirmationDialog
        open={cleanTrashConfirmDialog}
        onCancel={() => setCleanTrashConfirmDialog(false)}
        onConfirm={cleanTheTrash}
        title="Vider la corbeille"
        description="Voulez-vous vraiment vider la corbeille ?"
        confirmText="Vider"
        cancelText="Annuler"
      />
      <ConfirmationDialog
        open={recycleVictimConfirmDialog}
        onCancel={() => setRecycleVictimConfirmDialog(false)}
        onConfirm={recycleVictimInTrash}
        title="Restaurer ce dossier"
        description="Voulez-vous vraiment restaurer ce dossier ?"
        confirmText="Restaurer"
        cancelText="Annuler"
      />
    </>
  );

  const footer = (
    <Stack
      className={classes.dossierEditionActions}
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      style={{ width: '100%', marginTop: theme.spacing(isSidebar ? 0 : 5) }}
    >
      <Button
        className={classes.dossierEditionActionsCancel}
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => {
          if (isSidebar) {
            closeSideBar();
          } else {
            navigate(-1);
          }
        }}
      >
        Retour
      </Button>
      <Button
        className={classes.dossierEditionActionsSave}
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setCleanTrashConfirmDialog(true)}
      >
        Vider la corbeille
      </Button>
    </Stack>
  );

  return !isSidebar ? (
    <Container maxWidth="md" style={{ padding: theme.spacing(2) }}>
      {header}
      {body}
      {footer}
    </Container>
  ) : (
    <Sidebar
      justifyStart
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      closeSideBar={() => {
        closeSideBar();
      }}
    />
  );
};

export default VictimTrashCanDialog;
