import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';
import {
  NumberingType,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { displayRowsAverageFormula } from 'src/helpers/print/prejudicesWrittenPrints/formula';
import { displayNumerosPiecesWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/numerosPieces.written.print';
import { getMontantRevaloriseWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/revalorisation.written.print';
import { displayVictimeNameWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/victime.written.print';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
  PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';

export const revenusTheoriqueWrittenPrint = ({
  anneesRevenusTheoriques,
  dateLiquidation,
  monetaryErosions,
  victime,
  revenuDeReference,
  revenuTheorique,
  situationIndex,
  formData: { revalorisationCoefficientsType, ...formData },
}: Pick<
  PerteGainProfessionnelsActuelSituation,
  'revenuDeReference' | 'revenuTheorique'
> & {
  anneesRevenusTheoriques: PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee[];
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  victime: Victime;
  formData: OldPrejudiceFormPerteGainProfessionnelsActuel;
  situationIndex: number;
}): (Table | TableOfContents | Paragraph)[] => {
  const getYearText = (
    anneeRevenusTheoriques: PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee,
  ): string => {
    const revalorisation = getMontantRevaloriseWrittenPrint({
      anneeOrDateLiquidation: dateLiquidation,
      anneeOrDateMontant: anneeRevenusTheoriques.annee,
      coefficientsType: revalorisationCoefficientsType,
      montant: anneeRevenusTheoriques.netPaye || 0,
      monetaryErosions,
    });
    return anneeRevenusTheoriques.netFiscal
      ? i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.year.netFiscal',
          {
            numerosPieces: displayNumerosPiecesWrittenPrint(
              anneeRevenusTheoriques.numerosPieces,
              'Parentheses',
            ),
            netFiscal: fCurrency(anneeRevenusTheoriques.netFiscal || 0),
            netPaye: fCurrency(anneeRevenusTheoriques.netPaye || 0),
            revalorisation,
          },
        )
      : i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.year.netPaye',
          {
            victimeName: displayVictimeNameWrittenPrint(victime),
            netPaye: fCurrency(anneeRevenusTheoriques.netPaye || 0),
            numerosPieces: displayNumerosPiecesWrittenPrint(
              anneeRevenusTheoriques.numerosPieces,
              'Parentheses',
            ),
            revalorisation,
          },
        );
  };
  const annees = anneesRevenusTheoriques.filter(
    (annee) => !!annee.netPaye || !!annee.netFiscal,
  );

  const revenuTheoriqueText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.revenuTheorique',
    {
      revenuTheorique: fCurrency(revenuTheorique || 0),
    },
  );
  switch (annees.length) {
    case 0:
      return [
        getParagraph(
          i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.noYears',
            {
              revenuDeReference: fCurrency(revenuDeReference || 0),
            },
          ),
        ),
        getParagraph(revenuTheoriqueText),
      ];
    case 1:
      return annees[0]
        ? [
            getParagraph({
              children: [
                ...getTextRun(
                  i18next.t(
                    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.oneYearFirstPart',
                    {
                      year: annees[0].annee,
                    },
                  ),
                ),
                ...getTextRun(getYearText(annees[0])),
              ],
            }),
            getParagraph(
              i18next.t(
                'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.oneYearLastPart',
                {
                  revenuDeReference: fCurrency(revenuDeReference || 0),
                },
              ),
            ),
            getParagraph(revenuTheoriqueText),
          ]
        : [];
    default:
      return [
        getParagraph(
          i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.multipleYearsFirstPart',
          ),
        ),
        ...annees
          .map((annee) => [
            getParagraph({
              numbering: {
                reference: NumberingType.SIMPLE_LIST,
                level: 0,
              },
              children: [
                ...getTextRun(
                  i18next.t(
                    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.year.firstPart',
                    {
                      year: annee.annee,
                    },
                  ),
                ),
                ...getTextRun(getYearText(annee)),
              ],
            }),
          ])
          .flat(),
        getParagraph(
          i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.multipleYearsLastPart',
            {
              revenuDeReference: fCurrency(revenuDeReference || 0),
              formula: displayRowsAverageFormula({
                rowValues: annees.map((annee) =>
                  !annee.montantsDejaRevalorises
                    ? getMontantRevalorise({
                        anneeOrDateLiquidation: dateLiquidation,
                        anneeOrDateMontant: annee.annee,
                        coefficientsType: revalorisationCoefficientsType,
                        montant: annee.netPaye || 0,
                        monetaryErosions,
                      }) || 0
                    : annee.netPaye || 0,
                ),
                editedFieldsParameters: {
                  fieldName: `situations.${situationIndex}.revenuTheorique`,
                  formData,
                },
                options: { withParentheses: true },
              }),
            },
          ),
        ),
        getParagraph(revenuTheoriqueText),
      ];
  }
};
