import React from 'react';
import { Control, Controller, FieldPath, useWatch } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  NumeroPieceValuesRow,
} from '../../../../types/prejudice.type';
import { LayoutTable, NormalTable } from '../../../styled';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import {
  CalculsFormPerteGainsProfessionnelsActuel,
  CalculsGlobal,
} from '../../../../constants/calculs';
import { ReadFieldForm } from '../../../forms/ReadFieldForm';
import { CalculsBox } from '../../../basic/CalculsBox';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import {
  getMontantRevalorise,
  getRevalorisationTooltip,
} from 'src/helpers/prejudices/revalorisation';
import { isRegimeChiffreAffaire } from 'src/helpers/prejudices/prejudice';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { useTranslation } from 'react-i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';

interface Props {
  control: Control<
    | OldPrejudiceFormPerteGainProfessionnelsActuel
    | OldPrejudiceFormPerteGainProfessionnelsFuturs
  >;
  situationIndex: number;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation?: Date | undefined;
  isPGPF: boolean;
  dateConsolidation?: Date | undefined;
  allNumerosPieces: NumeroPieceValuesRow[];
  validate: (
    fieldsToValidate: FieldPath<
      | OldPrejudiceFormPerteGainProfessionnelsActuel
      | OldPrejudiceFormPerteGainProfessionnelsFuturs
    >[],
  ) => void;
  previous: (
    fieldsToValidate: FieldPath<
      | OldPrejudiceFormPerteGainProfessionnelsActuel
      | OldPrejudiceFormPerteGainProfessionnelsFuturs
    >[],
  ) => void;
}

export const RevenusReelsStep: React.FC<Props> = ({
  control,
  situationIndex,
  monetaryErosions,
  dateLiquidation,
  isPGPF,
  allNumerosPieces,
  validate,
  previous,
}) => {
  const { t } = useTranslation();
  const watchValues = useWatch({
    control,
    name: [`situations.${situationIndex}.regime`, 'dateDebut', 'dateFin'],
  });
  if (!watchValues[1] || !watchValues[2]) {
    return null;
  }
  const dateDebut = new Date(watchValues[1]);
  const dateFin = new Date(watchValues[2]);
  if (!dateDebut || !dateFin) {
    return null;
  }
  const regime = watchValues[0];
  const isChiffreAffaire = isRegimeChiffreAffaire(regime);
  return (
    <>
      <Stack alignItems="start" width="100%">
        <CalculsBox padding={2}>
          <FormControl>
            <FormLabel
              sx={{
                textAlign: 'center',
              }}
            >
              {t('prejudice.revalorisation.coefficientsType.choice.label')}
            </FormLabel>
            <RadioFormField
              control={control}
              name="revalorisationCoefficientsType"
              options={[
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                  ),
                  value: 'annuel',
                },
                {
                  label: t(
                    'prejudice.revalorisation.coefficientsType.choice.options.smic',
                  ),
                  value: 'smic',
                },
              ]}
              row={true}
            />
          </FormControl>
        </CalculsBox>
      </Stack>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.annee.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {regime === 'CHOMAGE'
                  ? t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.netFiscal.chomage.columnHeader',
                    )
                  : isChiffreAffaire
                    ? t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.netFiscal.chiffreAffaire.columnHeader',
                      )
                    : t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.netFiscal.columnHeader',
                      )}
              </TableCell>
              {regime === 'CHOMAGE' && (
                <>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.smicBrut.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.victimeRedevable.columnHeader',
                    )}
                  </TableCell>
                </>
              )}
              <TableCell align="right">
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.netPaye.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.revalorisation.columnHeaders.montantsDejaRevalorises',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              ...Array(dateFin.getFullYear() - dateDebut.getFullYear() + 1),
            ].map((_, index) => (
              <TableRow key={dateDebut.getFullYear() + index}>
                <TableCell>
                  <FormNumeroPieceDialog
                    control={control}
                    name={`situations.${situationIndex}.anneesRevenusReels.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell align="center">
                  <ReadFieldForm
                    control={control}
                    name={`situations.${situationIndex}.anneesRevenusReels.${index}.annee`}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextFieldForm
                    control={control}
                    name={`situations.${situationIndex}.anneesRevenusReels.${index}.netFiscal`}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, signed: isChiffreAffaire },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
                {regime === 'CHOMAGE' && (
                  <>
                    <TableCell align="center">
                      <TextFieldForm
                        control={control}
                        name={`situations.${situationIndex}.anneesRevenusReels.${index}.smicBrut`}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Controller
                        control={control}
                        name={`situations.${situationIndex}.anneesRevenusReels.${index}.victimeRedevable`}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    </TableCell>
                  </>
                )}
                <TableCell align="right">
                  <ComputedTextFieldForm
                    control={control}
                    name={`situations.${situationIndex}.anneesRevenusReels.${index}.netPaye`}
                    watchFields={[
                      `situations.${situationIndex}.anneesRevenusReels.${index}.annee`,
                      `situations.${situationIndex}.anneesRevenusReels.${index}.netFiscal`,
                      `situations.${situationIndex}.anneesRevenusReels.${index}.smicBrut`,
                      `situations.${situationIndex}.anneesRevenusReels.${index}.victimeRedevable`,
                      `situations.${situationIndex}.anneesRevenusReels.${index}.montantsDejaRevalorises`,
                      'revalorisationCoefficientsType',
                    ]}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, signed: true },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    compute={([_, netFiscal, smicBrut, victimeRedevable]) => {
                      const netPaye =
                        CalculsFormPerteGainsProfessionnelsActuel.getSalaireNetPaye(
                          regime,
                          { netFiscal, smicBrut, victimeRedevable },
                        );
                      return netPaye;
                    }}
                    computeTooltip={(
                      [
                        annee,
                        _,
                        __,
                        ___,
                        montantsDejaRevalorises,
                        revalorisationCoefficientsType,
                      ],
                      fieldValue,
                    ) =>
                      getRevalorisationTooltip({
                        anneeOrDateMontant: annee,
                        montant: fieldValue || 0,
                        montantsDejaRevalorises,
                        monetaryErosions,
                        anneeOrDateLiquidation: dateLiquidation,
                        coefficientsType: revalorisationCoefficientsType,
                      })
                    }
                    editedFieldsName="editedFields"
                  />
                </TableCell>
                <TableCell align="center">
                  <Controller
                    control={
                      control as Control<OldPrejudiceFormPerteGainProfessionnelsActuel>
                    }
                    name={`situations.${situationIndex}.anneesRevenusReels.${index}.montantsDejaRevalorises`}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <Stack direction="row-reverse">
        <CalculsBox>
          <LayoutTable size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">
                    {isPGPF
                      ? t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.situations.revenusReelsNet.rowHeader',
                        )
                      : t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenusReelsNet.rowHeader',
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <ComputedTextFieldForm
                    control={control}
                    name={`situations.${situationIndex}.revenusReelsNet`}
                    watchFields={[
                      `situations.${situationIndex}.anneesRevenusReels`,
                      'revalorisationCoefficientsType',
                    ]}
                    compute={([rows, revalorisationCoefficientsType]) => {
                      return CalculsGlobal.sum(
                        rows.map((row) =>
                          row.montantsDejaRevalorises
                            ? row.netPaye || 0
                            : row.netPaye
                              ? getMontantRevalorise({
                                  montant: row.netPaye || 0,
                                  anneeOrDateMontant: row.annee,
                                  anneeOrDateLiquidation: dateLiquidation,
                                  monetaryErosions,
                                  coefficientsType:
                                    revalorisationCoefficientsType,
                                }) || row.netPaye
                              : 0,
                        ),
                      );
                    }}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, signed: true },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </LayoutTable>
        </CalculsBox>
      </Stack>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ alignSelf: 'center' }}
          onClick={() => previous([`situations.${situationIndex}`])}
        >
          {t('common.previous')}
        </Button>
        <Button
          variant="contained"
          sx={{ alignSelf: 'center' }}
          onClick={() => validate([`situations.${situationIndex}`])}
        >
          {t('common.next')}
        </Button>
      </Stack>
    </>
  );
};
