import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { CalculsFormListeProjection } from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { prejudiceFormTable } from 'src/helpers/print/prejudicesPrints/prejudiceFormTablePrint';
import {
  ListeProjectionIncidenceProfessionelle,
  PrejudiceFormListeProjection,
  PGPFReliquatAndPGPFReliquatActivationStatus,
} from 'src/types/prejudice.type';
import { debitSoldeAEchoirPrint } from '../../debitSoldeAEchoirPrint';
import { perteDeRetraitePrint } from './perteDeRetraitePrint';
import { Victime } from 'src/types/victime.type';
import { Procedure } from 'src/types/procedure.type';
import { Bareme } from 'src/types/bareme.type';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const incidenceProfessionnellePrint = ({
  formData,
  partResponsabilite,
  PGPFReliquat,
  victime,
  procedure,
  baremes,
}: {
  formData: PrejudiceFormListeProjection;
  partResponsabilite: number;
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  victime: Victime;
  procedure: Procedure;
  baremes: Bareme[];
}): (TableOfContents | Paragraph | Table)[] => {
  const prejudiceValues =
    formData.prejudiceValues as ListeProjectionIncidenceProfessionelle;
  const { beforePGPFReliquatTotal } =
    CalculsFormListeProjection.getIncidenceProfessionnelleIndemnite({
      partResponsabilite,
      PGPFReliquat,
      rows: prejudiceValues.rows,
      perteDeRetraite: prejudiceValues.perteDeRetraite,
    });
  return [
    ...(formData.prejudiceValues?.rows
      ? prejudiceFormTable(prejudiceValues.rows, [
          {
            name: 'numerosPieces',
            headerLabel: i18next.t('numeroPiece.form.columnHeader'),
            render: (row) =>
              row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
          },
          {
            name: 'libelle',
            headerLabel: i18next.t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.libelle.print.columnHeader',
            ),
          },
          {
            name: 'montant',
            headerLabel: i18next.t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.montant.print.columnHeader',
            ),
            render: (row) => fCurrency(row.montant),
          },
        ])
      : []),
    ...perteDeRetraitePrint({
      victime,
      procedure,
      capitalisationBaremes: baremes,
      perteDeRetraite: prejudiceValues.perteDeRetraite,
    }),
    getEmptyLine(),
    ...(PGPFReliquat.PGPFReliquat &&
    PGPFReliquat.activatePGPFReliquat &&
    (beforePGPFReliquatTotal || beforePGPFReliquatTotal === 0)
      ? [
          getEmptyLine(),
          getParagraph({
            children: getTextRun({
              text: i18next.t(
                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.PGPFReliquat.basculeIP.print.label',
                {
                  tropPercu: fCurrency(-PGPFReliquat.PGPFReliquat),
                },
              ),
            }),
          }),
          getEmptyLine(),
          simpleHorizontalTablePrint(
            [
              '',
              i18next.t(
                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.PGPFReliquat.totalTable.fields.incidenceProfessionnelle.columnHeader',
              ),
              i18next.t(
                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.PGPFReliquat.totalTable.fields.tiersPayeurs.columnHeader',
              ),
            ],
            [
              {
                rowLabel: i18next.t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.PGPFReliquat.totalTable.totalRowHeader',
                ),
                columns: [
                  fCurrency(beforePGPFReliquatTotal),
                  fCurrency(-PGPFReliquat.PGPFReliquat),
                ],
              },
              {
                rowLabel: i18next.t(`partResponsabilite.tableRowHeader`, {
                  partResponsabilite: fPartResponsabilite(
                    partResponsabilite * 100,
                  ),
                }),
                columns: [
                  fCurrency(beforePGPFReliquatTotal * partResponsabilite),
                  '',
                ],
              },
            ],
          ),
        ]
      : []),
    getEmptyLine(),
    ...debitSoldeAEchoirPrint({
      indemniteGlobaleARepartir:
        CalculsFormListeProjection.getIncidenceProfessionnelleIndemniteRepartie(
          {
            PGPFReliquat,
            incidenceProfessionnelle: prejudiceValues,
            partResponsabilite,
          },
        ),
      partResponsabilite,
      hide: {
        indemniteTiersPayeurs: {
          arreragesEchus: true,
          arreragesAEchoir: true,
        },
      },
    }),
  ];
};
