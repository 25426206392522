import { FormControl, FormLabel } from '@mui/material';
import React from 'react';
import { Control, FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { chiffragesOptions, PrejudiceFormBase } from 'src/types/prejudice.type';

interface Props<
  TFieldValues extends PrejudiceFormBase,
  TName extends FieldPath<TFieldValues> & 'chiffrage',
> {
  control: Control<TFieldValues>;
  name?: TName;
}

export const Chiffrage = <
  TFieldValues extends PrejudiceFormBase,
  TName extends FieldPath<TFieldValues> & 'chiffrage',
>({
  control,
  name = 'chiffrage' as TName,
}: Props<TFieldValues, TName>) => {
  const { t } = useTranslation();

  return (
    <FormControl
      sx={{
        backgroundColor: 'action.hover',
        padding: 1,
        borderRadius: 1,
      }}
    >
      <FormLabel
        sx={{
          textAlign: 'center',
        }}
      >
        {t('prejudice.fields.chiffrage.label')}
      </FormLabel>
      <RadioFormField
        control={control}
        name={name}
        options={[
          {
            label: t(
              `prejudice.fields.chiffrage.options.${chiffragesOptions[0]}.label`,
            ),
            value: chiffragesOptions[0],
          },
          {
            label: t(
              `prejudice.fields.chiffrage.options.${chiffragesOptions[1]}.label`,
            ),
            value: chiffragesOptions[1],
          },
        ]}
        row
      />
    </FormControl>
  );
};
