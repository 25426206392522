import React from 'react';
import { Control } from 'react-hook-form';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import { CalculsFormCalendrierAssistance } from '../../../constants/calculs';
import { PrejudiceFormCalendrierAssistanceCapitalisation } from 'src/types/prejudice.type';
import { TotalIndemniteGlobaleARepartirAEchoir } from './TotalIndemniteGlobaleARepartirAEchoir';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Grid } from '@mui/material';
import { CalculsRentes } from 'src/constants/calculs/calculsRentes';
import { RentesOption } from './Rentes/RenteOption';

interface Props {
  control: Control<PrejudiceFormCalendrierAssistanceCapitalisation>;
  tiersPayeurs: string[];
  partResponsabilite: number;
  monetaryErosions: MonetaryErosion[];
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  dateDeces: Date | undefined;
}

export const TotalAssistanceDebitSolde: React.FC<Props> = ({
  control,
  tiersPayeurs,
  partResponsabilite,
  monetaryErosions,
  dateConsolidation,
  dateLiquidation,
  dateDeces,
}) => {
  return (
    <ComputedPropsForm
      watchFields={[
        'rows',
        'victimeSommeACapitaliser',
        'victimeCoefficientCapitalisation',
        'capitalisationTiersPayeurs',
        'tiersPayeursTotalCapitalise',
        'revalorisationCoefficientsType',
      ]}
      compute={([
        rows,
        victimeSommeACapitaliser,
        victimeCoefficientCapitalisation,
        capitalisationTiersPayeurs,
        tiersPayeursTotalCapitalise,
        revalorisationCoefficientsType,
      ]) => {
        return {
          props: {
            ...CalculsFormCalendrierAssistance.getAssistanceIndemnitesRepartie({
              rows,
              victimeSommeACapitaliser,
              victimeCoefficientCapitalisation,
              capitalisationTiersPayeurs,
              tiersPayeursTotalCapitalise,
              partResponsabilite,
              tiersPayeurs,
              dateLiquidation,
              monetaryErosions,
              revalorisationCoefficientsType,
              dateConsolidation,
              dateDeces,
            }),
          },
        };
      }}
      control={control}
      render={({
        indemniteGlobaleARepartir,
        indemniteTiersPayeurs,
        indemniteVictime,
      }) => (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TotalIndemniteGlobaleARepartirAEchoir
              indemniteGlobaleARepartir={{
                indemniteGlobaleARepartir,
                indemniteVictime,
                indemniteTiersPayeurs,
              }}
              partResponsabilite={partResponsabilite}
            />
          </Grid>
          <Grid item xs={6}>
            <ComputedPropsForm
              control={control}
              watchFields={['victimeCoefficientCapitalisation']}
              customValues={[indemniteVictime.arreragesAEchoir.debit]}
              compute={([victimeCoefficientCapitalisation], customValues) => {
                const indemniteVictimeAEchoir = customValues?.[0] || 0;
                return {
                  props: {
                    rentesMontant: CalculsRentes.getAnnuel(
                      indemniteVictimeAEchoir,
                      victimeCoefficientCapitalisation || 0,
                    ),
                  },
                };
              }}
              render={({ rentesMontant }) => (
                <RentesOption
                  control={control}
                  name={{
                    renteMontant: 'rentes.montant',
                    isRentesOption: 'isRentesOption',
                    editedFields: 'editedFields',
                  }}
                  renteDefaultValue={rentesMontant}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};
