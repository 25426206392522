import { Paragraph, Table, TableOfContents } from 'docx';
import { Bareme } from 'src/types/bareme.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getEmptyLine, getParagraph } from '../../../docxFunctions';
import { getRevalorisationIntroductionWrittenPrintIfRevalorise } from '../../revalorisation.written.print';
import { capitalisationSelectedBaremeIntroductionWrittenPrint } from '../../capitalisation.written.print';
import { perteAnnuelleFoyerWrittenPrint } from './perteAnnuelleFoyer.written.print';
import { prejudiceViagerFoyerWrittenPrint } from './prejudiceViagerFoyer.written.print';
import { enfantsWrittenPrint } from './enfants.written.print';
import { conjointWrittenPrint } from './conjoint.written.print';
import { prpRecapitulatifWrittenPrint } from './recapitulatif.written.print';

export const prpVictimeDecedeeWrittenPrint = ({
  victime,
  procedure,
  formData,
  victimesIndirectes,
  baremes,
  monetaryErosions,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormPerteRevenusProche;
  monetaryErosions: MonetaryErosion[];
  victimesIndirectes: VictimeIndirecte[];
  baremes: Bareme[];
}): (Table | TableOfContents | Paragraph)[] => {
  const selectedBareme = baremes.find(
    (bareme) => bareme._id === formData.baremeCapitalisation,
  );
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...capitalisationSelectedBaremeIntroductionWrittenPrint(selectedBareme),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      formData,
      formType: 'PERTES_REVENUS_PROCHE',
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    }),
    ...perteAnnuelleFoyerWrittenPrint({
      formData,
      victime,
      procedure,
      monetaryErosions,
    }),
    getEmptyLine(),
    ...prejudiceViagerFoyerWrittenPrint({
      formData,
      victime,
      procedure,
      victimesIndirectes,
    }),
    getEmptyLine(),
    ...(formData.enfants.rows.length > 0
      ? enfantsWrittenPrint({
          formData,
          victime,
          procedure,
          victimesIndirectes,
        })
      : []),
    getEmptyLine(),
    ...conjointWrittenPrint({ formData, victime }),
    getEmptyLine(),
    ...prpRecapitulatifWrittenPrint({
      formData,
      procedure,
      victimesIndirectes,
    }),
  ];
};
