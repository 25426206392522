import React, { useMemo, useState } from 'react';
import {
  Stack,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { signInUpGoogle } from 'src/slices/auth';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';

const useStyles = createUseStyles({
  authSocialContainer: {
    width: '100%',
  },
  googleButton: {
    color: '#DF3E30',
    height: '24px',
  },
  facebookButton: {
    color: '#1877F2',
    height: '24px',
  },
  twitterButton: {
    color: '#1C9CEA',
    height: '24px',
  },
  authSocialDivider: {
    margin: theme.spacing(3, 0),
  },
  authSocialOrDivider: {
    color: theme.palette.text.secondary,
  },
});
interface Props {
  invitationToken?: string;
}

const AuthSocial: React.FC<Props> = ({ invitationToken }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>(undefined);
  const loadingState = useState<boolean>(false);
  const onSuccess = async (response: CredentialResponse) => {
    loadingState[1](true);
    if (error) {
      setError(undefined);
    }
    if (response.credential) {
      const resultAction = await dispatch(
        signInUpGoogle({
          googleAccessToken: response.credential,
          invitationToken,
        }),
      );
      if (signInUpGoogle.rejected.match(resultAction)) {
        setError(resultAction.error.message);
      }
    }
    loadingState[1](false);
  };
  const onError = () => {
    setError("Couldn't sign in with Google");
  };
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const width = useMemo(
    () =>
      `${400 - parseInt(theme.spacing(isSm ? 2 : 3).replace('px', '')) * 2}px`,
    [theme, isSm],
  );
  return (
    <div className={classes.authSocialContainer}>
      <Stack direction="row" spacing={2} justifyContent="center">
        {/* Find out how to just disable the button */}
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onError}
          size="large"
          width={width}
          containerProps={{
            style: {
              width: '100%',
            },
          }}
          locale="fr"
        />
      </Stack>
      <Divider className={classes.authSocialDivider}>
        <Typography
          variant="body2"
          className={classes.authSocialOrDivider}
          sx={{ textTransform: 'uppercase' }}
        >
          {t('common.or')}
        </Typography>
      </Divider>
    </div>
  );
};

export default AuthSocial;
