import {
  CalculsFormCalendrierDepense,
  CalculsFormCapitalisation,
  CalculsGlobal,
} from 'src/constants/calculs';
import {
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierDepenseCapitalisationLegacy,
} from 'src/types/prejudice.type';

const LEGACY_FORM_VERSION = 8;

export const shouldMigrateDSF = (
  formData:
    | PrejudiceFormCalendrierDepenseCapitalisation
    | PrejudiceFormCalendrierDepenseCapitalisationLegacy,
) => !formData.formVersion || formData.formVersion < LEGACY_FORM_VERSION;

const migrateRows = (
  formData: PrejudiceFormCalendrierDepenseCapitalisationLegacy,
): PrejudiceFormCalendrierDepenseCapitalisation['rows'] => {
  const migratedRows: PrejudiceFormCalendrierDepenseCapitalisation['rows'] =
    formData.rows.map((row, index) => {
      const montantUnitaireAnnualise =
        CalculsFormCapitalisation.getMontantUnitaireAnnualise({
          type: row.type,
          frequenceMontant: row.frequenceMontant,
          montantUnitaire: row.montantUnitaire,
          quantite: row.quantite,
          renouvellementMaintenance: row.renouvellementMaintenance,
        });
      return {
        ...row,
        capitalisation: {
          ageDateAttribution: formData.ageDateAttribution,
          ageDernierArrerage: formData.ageDernierArrerage,
          isLastArrerageViager: formData.isLastArrerageViager,
          coefficient: formData.victimeCoefficientCapitalisation,
          montantCapitalise:
            montantUnitaireAnnualise *
            (formData.victimeCoefficientCapitalisation || 0),
        },
        capitalisationTiersPayeurs:
          index === 0
            ? {
                parTiersPayeur:
                  formData.capitalisationTiersPayeurs.parTiersPayeur,
              }
            : {
                parTiersPayeur:
                  formData.capitalisationTiersPayeurs.parTiersPayeur.map(
                    ({ tiersPayeur }) => ({
                      tiersPayeur,
                      montantCapitalise: 0,
                      coefficient: 0,
                      sommeACapitaliser: 0,
                    }),
                  ),
              },
        montantUnitaireAnnualise: montantUnitaireAnnualise,
      };
    });
  const migratedApresDecisionRows: PrejudiceFormCalendrierDepenseCapitalisation['rows'] =
    formData.apresDecision.rows;
  return [...migratedRows, ...migratedApresDecisionRows];
};

export const migrateDSF = ({
  formData,
  dateLiquidation,
}: {
  formData: PrejudiceFormCalendrierDepenseCapitalisationLegacy;
  dateLiquidation: Date | undefined;
}): PrejudiceFormCalendrierDepenseCapitalisation => {
  const rows = migrateRows(formData);
  const totauxDepensesCapitalisation =
    CalculsFormCalendrierDepense.totauxDepensesCapitalisation({
      rows,
      dateLiquidation,
    });
  const totauxDepensesTiersPayeursCapitalisation =
    CalculsFormCalendrierDepense.totauxDepensesTiersPayeursCapitalisation({
      rows,
      dateLiquidation,
    });
  return {
    notes: formData.notes,
    chiffrage: formData.chiffrage,
    capitalisation: {
      montantCapitalise: totauxDepensesCapitalisation.montantCapitalise,
      bareme: formData.baremeCapitalisation,
    },
    capitalisationTiersPayeurs: {
      montantCapitalise: CalculsGlobal.sum(
        Object.values(totauxDepensesTiersPayeursCapitalisation.parTiersPayeur),
      ),
      parTiersPayeur: Object.entries(
        totauxDepensesTiersPayeursCapitalisation.parTiersPayeur,
      ).map(([tiersPayeur, montantCapitalise]) => ({
        tiersPayeur,
        montantCapitalise,
      })),
    },
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    rows,
    editedFields: formData.editedFields,
    isRentesOption: false,
  };
};
