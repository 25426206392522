import React from 'react';
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers';
import { TextFieldProps, Tooltip } from '@mui/material';
import {
  UseControllerProps,
  useController,
  useWatch,
  FieldValues,
  FieldPath,
} from 'react-hook-form';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  TextFieldProps?: Partial<TextFieldProps>;
  minDateField?: string;
  maxDateField?: string;
  required?: boolean;
}

export const ComputedDatePickerForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  TextFieldProps,
  minDate,
  maxDate,
  minDateField,
  maxDateField,
  required,
  ...props
}: Props<TFieldValues, TName> &
  Omit<
    DatePickerProps<Date>,
    'onChange' | 'value' | 'renderInput' | 'date' | 'openPicker' | 'rawValue'
  >) => {
  const {
    field: { value, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, shouldUnregister, defaultValue });

  const watchValues = useWatch({
    control,
    name: [minDateField, maxDateField] as any,
  });

  return (
    <Tooltip title={error?.message || ''}>
      <div>
        <DesktopDatePicker
          {...props}
          {...{
            minDate: minDateField ? new Date(watchValues[0]) : minDate,
            maxDate: maxDateField ? new Date(watchValues[1]) : maxDate,
          }}
          value={value ? new Date(value) : null}
          label={required ? props.label + ' *' : props.label}
          {...field}
          onChange={(value: any) => {
            field.onChange(
              (value?.toString() !== 'Invalid Date' && value?.toISOString()) ||
                null,
            );
          }}
          onClose={field.onBlur}
          slotProps={{
            textField: {
              ...(TextFieldProps || {}),
              error: !!error,
              onBlur: field.onBlur,
            },
          }}
        />
      </div>
    </Tooltip>
  );
};
