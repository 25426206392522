import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { LayoutTable } from '../../styled';
import { fCurrency } from '../../../helpers/formatNumber';
import { CalculsBox } from '../../basic/CalculsBox';
import {
  IndemniteRepartieAEchoir,
  PGPFReliquat,
} from 'src/constants/calculs/type';
import { getTropPercuString } from 'src/helpers/prejudices/pgpfReliquat';
import { useTranslation } from 'react-i18next';
import { fPartResponsabilite } from 'src/helpers/formatValues';
interface Props {
  indemniteGlobaleARepartir: Omit<
    IndemniteRepartieAEchoir,
    'indemniteTiersPayeurs'
  > &
    Partial<Pick<IndemniteRepartieAEchoir, 'indemniteTiersPayeurs'>>;
  caption?: string;
  PGPFReliquat?: PGPFReliquat;
  titles?: {
    indemniteGlobaleARepartirBeforePartResponsabilite?: string;
    indemniteGlobaleARepartir?: string;
    indemniteVictime?: {
      arreragesEchus?: string;
      arreragesAEchoir?: string;
      total?: string;
    };
    indemniteTiersPayeurs?: {
      arreragesEchus?: string;
      arreragesAEchoir?: string;
      total?: string;
    };
  };
  hide?: {
    indemniteGlobaleARepartirBeforePartResponsabilite?: boolean;
    indemniteGlobaleARepartir?: boolean;
    indemniteVictime?: {
      arreragesEchus?: boolean;
      arreragesAEchoir?: boolean;
      total?: boolean;
    };
    indemniteTiersPayeurs?: {
      arreragesEchus?: boolean;
      arreragesAEchoir?: boolean;
      total?: boolean;
    };
  };
  partResponsabilite: number;
}
export const TotalIndemniteGlobaleARepartirAEchoir: React.FC<Props> = ({
  indemniteGlobaleARepartir,
  caption,
  PGPFReliquat,
  hide,
  partResponsabilite,
  ...props
}) => {
  const { t } = useTranslation();
  const titles: typeof props.titles = {
    indemniteGlobaleARepartirBeforePartResponsabilite:
      props.titles?.indemniteGlobaleARepartirBeforePartResponsabilite ||
      t(
        'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteGlobaleBeforePartResponsabilite.tableRowHeader',
      ),
    indemniteGlobaleARepartir:
      props.titles?.indemniteGlobaleARepartir ||
      t(
        'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteGlobale.tableRowHeaderPartResponsabilite',
        {
          partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
        },
      ),
    indemniteVictime: {
      arreragesEchus:
        props.titles?.indemniteVictime?.arreragesEchus ||
        t(
          'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteVictime.echus.tableRowHeader',
        ),
      arreragesAEchoir:
        props.titles?.indemniteVictime?.arreragesAEchoir ||
        t(
          'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteVictime.aEchoir.tableRowHeader',
        ),
      total:
        props.titles?.indemniteVictime?.total ||
        t(
          'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteVictime.tableRowHeader',
        ),
    },
    indemniteTiersPayeurs: {
      arreragesEchus:
        props.titles?.indemniteTiersPayeurs?.arreragesEchus ||
        t(
          'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteTiersPayeurs.echus.tableRowHeader',
        ),
      arreragesAEchoir:
        props.titles?.indemniteTiersPayeurs?.arreragesAEchoir ||
        t(
          'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteTiersPayeurs.aEchoir.tableRowHeader',
        ),
      total:
        props.titles?.indemniteTiersPayeurs?.total ||
        t(
          'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteTiersPayeurs.tableRowHeader',
        ),
    },
  };

  return (
    <CalculsBox>
      <LayoutTable size="small">
        <TableBody>
          {hide?.indemniteGlobaleARepartirBeforePartResponsabilite ? null : (
            <TableRow>
              <TableCell colSpan={2}>
                {titles.indemniteGlobaleARepartirBeforePartResponsabilite}
              </TableCell>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                {fCurrency(
                  indemniteGlobaleARepartir.indemniteGlobaleARepartir
                    .beforePartResponsabilite,
                )}
              </TableCell>
            </TableRow>
          )}
          {hide?.indemniteGlobaleARepartir ? null : (
            <>
              <TableRow>
                <TableCell colSpan={2}>
                  {titles.indemniteGlobaleARepartir}
                </TableCell>
                <TableCell
                  colSpan={2}
                  align="right"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {fCurrency(
                    indemniteGlobaleARepartir.indemniteGlobaleARepartir.solde,
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
              </TableRow>
            </>
          )}
          {hide?.indemniteVictime?.total ? null : (
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                {titles.indemniteVictime?.total}
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
              >
                {getTropPercuString(
                  indemniteGlobaleARepartir.indemniteVictime.total,
                  PGPFReliquat?.total ?? null,
                )}
              </TableCell>
            </TableRow>
          )}
          {hide?.indemniteVictime?.arreragesEchus ? null : (
            <TableRow>
              <TableCell colSpan={2}>
                {titles.indemniteVictime?.arreragesEchus}
              </TableCell>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                {getTropPercuString(
                  indemniteGlobaleARepartir.indemniteVictime.arreragesEchus
                    .debit,
                  PGPFReliquat?.echus ?? null,
                  PGPFReliquat?.deduitsDuTropPercu.echus,
                )}
              </TableCell>
            </TableRow>
          )}
          {hide?.indemniteVictime?.arreragesAEchoir ? null : (
            <TableRow>
              <TableCell colSpan={2}>
                {titles.indemniteVictime?.arreragesAEchoir}
              </TableCell>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                {getTropPercuString(
                  indemniteGlobaleARepartir.indemniteVictime.arreragesAEchoir
                    .debit,
                  PGPFReliquat?.aEchoir ?? null,
                  PGPFReliquat?.deduitsDuTropPercu.aEchoir,
                )}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell />
          </TableRow>
          {indemniteGlobaleARepartir.indemniteTiersPayeurs ? (
            <>
              {hide?.indemniteTiersPayeurs?.total ? null : (
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                    {titles.indemniteTiersPayeurs?.total}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="right"
                    sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                  >
                    {fCurrency(
                      indemniteGlobaleARepartir.indemniteTiersPayeurs.total,
                    )}
                  </TableCell>
                </TableRow>
              )}
              {hide?.indemniteTiersPayeurs?.arreragesEchus ? null : (
                <>
                  <TableRow>
                    <TableCell colSpan={2}>
                      {titles.indemniteTiersPayeurs?.arreragesEchus}
                    </TableCell>
                    <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                      {fCurrency(
                        indemniteGlobaleARepartir.indemniteTiersPayeurs
                          .arreragesEchus.debit,
                      )}
                    </TableCell>
                  </TableRow>
                  {indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesEchus.parTiersPayeur?.map(
                    ({ tiersPayeur, montant }, index) => (
                      <TableRow key={index}>
                        <TableCell />
                        <TableCell>{tiersPayeur}</TableCell>
                        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                          {fCurrency(montant)}
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </>
              )}
              {hide?.indemniteTiersPayeurs?.arreragesAEchoir ? null : (
                <>
                  {indemniteGlobaleARepartir.indemniteTiersPayeurs
                    .arreragesAEchoir ? (
                    <TableRow>
                      <TableCell colSpan={2}>
                        {titles.indemniteTiersPayeurs?.arreragesAEchoir}
                      </TableCell>
                      <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                        {fCurrency(
                          indemniteGlobaleARepartir.indemniteTiersPayeurs
                            .arreragesAEchoir.debit,
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesAEchoir?.parTiersPayeur?.map(
                    ({ tiersPayeur, montant }, index) => (
                      <TableRow key={index}>
                        <TableCell />
                        <TableCell>{tiersPayeur}</TableCell>
                        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                          {fCurrency(montant)}
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </>
              )}
            </>
          ) : null}
          {caption && (
            <TableRow>
              <TableCell
                colSpan={4}
                sx={{ paddingTop: '40px', maxWidth: '400px' }}
              >
                {caption}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </LayoutTable>
    </CalculsBox>
  );
};
