import { Document } from 'docx';
import { Bareme } from 'src/types/bareme.type';
import { Dommage } from 'src/types/dommage.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Prejudice, PrejudiceType } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getPGPFReliquatAndPGPFReliquatActivationStatus } from '../prejudices/pgpfReliquat';
import { getPrejudiceTotal, PrejudiceTotalValue } from '../prejudices/total';
import { defaultNumberingConfigs, getEmptyLine } from './docxFunctions';
import { makePrejudicesMinimalTotalPrint } from './makePrejudicesMinimalTotalPrint';
import { makePrejudicesPrint } from './makePrejudicesPrint';
import { makePrejudicesTotalPrint } from './makePrejudicesTotalPrint';
import { makeVictimePrint } from './makeVictimePrint';
import { numeroPiecesPrint } from './numeroPiecesPrint';
import { getPrejudicesPerCategoryPerDirectEntries } from '../prejudices/sortAndOrder';
import { getCGAULinkPrint, getCGAUText, getCGAUTitle } from './CGAUPrint';

export const makePrint = ({
  victime,
  dommages,
  procedure,
  monetaryErosions,
  prejudices,
  baremes,
  victimesIndirectes,
  isWrittenPrint,
}: {
  victime: Victime;
  dommages: Dommage[];
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
  prejudices: Prejudice[];
  baremes: Bareme[];
  victimesIndirectes: VictimeIndirecte[];
  isWrittenPrint: boolean;
}): Document => {
  const PGPFReliquat =
    getPGPFReliquatAndPGPFReliquatActivationStatus(prejudices);
  const prejudicesTotalValues: Partial<
    Record<PrejudiceType, PrejudiceTotalValue[]>
  > = prejudices.reduce(
    (totalPerPrejudice, prejudice) => ({
      ...totalPerPrejudice,
      [prejudice.type]: getPrejudiceTotal({
        victime,
        victimesIndirectes,
        procedure,
        prejudice,
        dateLiquidation: procedure.dateLiquidation
          ? new Date(procedure.dateLiquidation)
          : undefined,
        monetaryErosions,
        PGPFReliquat,
        dateConsolidation: procedure.dateConsolidation
          ? new Date(procedure.dateConsolidation)
          : undefined,
        dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
      }),
    }),
    {},
  );
  const prejudicesTotalPrint = makePrejudicesTotalPrint({
    procedure,
    victime,
    prejudices,
    victimesIndirectes,
    prejudicesTotalValues,
    monetaryErosions,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
  });
  const prejudicesVictimeIndirecteTotalPrint = makePrejudicesTotalPrint({
    procedure,
    victime,
    prejudices,
    victimesIndirectes,
    isVictimeIndirecteTable: true,
    prejudicesTotalValues,
    monetaryErosions,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
  });
  const minimalPrejudicesTotalPrint = makePrejudicesMinimalTotalPrint({
    procedure,
    victime,
    prejudices,
    victimesIndirectes,
    prejudicesTotalValues,
    monetaryErosions,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
  });

  const printPrejudicesIndirectes = getPrejudicesPerCategoryPerDirectEntries({
    procedure,
    prejudices,
    victime,
    victimesIndirectes,
  })
    ['INDIRECTE'].map((index) => {
      return index?.[1].length === 0;
    })
    .every((result) => result === true)
    ? undefined
    : makePrejudicesPrint({
        victime,
        victimesIndirectes,
        procedure,
        prejudices,
        monetaryErosions,
        baremes,
        PGPFReliquat,
        prejudicesTotalValues,
        isVictimeIndirecte: true,
        isWrittenPrint,
      });
  const printPrejudicesDirectes = makePrejudicesPrint({
    victime,
    victimesIndirectes,
    procedure,
    prejudices,
    monetaryErosions,
    baremes,
    PGPFReliquat,
    prejudicesTotalValues,
    isVictimeIndirecte: false,
    isWrittenPrint,
  });

  return new Document({
    numbering: {
      config: defaultNumberingConfigs,
    },
    sections: [
      {
        children: [
          ...makeVictimePrint({
            victime,
            dommage: dommages[0],
            procedure,
          }),
          ...(minimalPrejudicesTotalPrint
            ? [getEmptyLine(), ...minimalPrejudicesTotalPrint]
            : []),
          getEmptyLine(),
          ...(prejudicesVictimeIndirecteTotalPrint || []),
          getEmptyLine(),
        ],
      },
      ...(printPrejudicesDirectes
        ? [
            {
              children: printPrejudicesDirectes,
            },
          ]
        : []),
      ...(printPrejudicesIndirectes
        ? [
            {
              children: printPrejudicesIndirectes,
            },
          ]
        : []),
      ...(isWrittenPrint
        ? []
        : prejudicesTotalPrint
          ? [
              {
                children: [...(prejudicesTotalPrint || [])],
              },
            ]
          : []),
      numeroPiecesPrint({
        procedure,
        victime,
        prejudices,
        victimesIndirectes,
      }),
      {
        children: [
          getCGAUTitle(),
          getEmptyLine(),
          getCGAUText(),
          getEmptyLine(),
          getCGAULinkPrint(),
        ],
      },
    ],
  });
};
