import React from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TooltipTableHeader } from '../../TooltipTableHeader';

interface Props {
  tiersPayeurs: string[];
  isCalculCapitalisation: boolean | undefined;
  shouldNotDisplayCapitalisation: boolean;
}

export const TableAssistanceHeader: React.FC<Props> = ({
  tiersPayeurs,
  isCalculCapitalisation,
  shouldNotDisplayCapitalisation,
}) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center">
          {t('numeroPiece.form.columnHeader')}
        </TableCell>
        <TableCell
          align="center"
          sx={{ position: 'sticky', left: 0, zIndex: 3 }}
          variant="head"
          colSpan={2}
        >
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.dateDebut.periodeColumnHeader',
          )}
        </TableCell>

        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursPeriode.columnHeader',
          )}
        </TableCell>
        {!isCalculCapitalisation && (
          <>
            <TableCell align="center">
              <TooltipTableHeader
                headerLabel={t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursHospitalisationADeduire.columnHeader',
                )}
                tooltipTitle={
                  <Typography whiteSpace="pre-line">
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursHospitalisationADeduire.headerTooltip',
                    )}
                  </Typography>
                }
              />
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '102px' }}>
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.deduireJoursHospitalisation.columnHeader',
              )}
            </TableCell>
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursRetenus.columnHeader',
              )}
            </TableCell>
          </>
        )}
        <TableCell align="center">
          <TooltipTableHeader
            headerLabel={t(
              'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursConges.columnHeader',
            )}
            tooltipTitle={
              <>
                <Typography variant="subtitle1">
                  {t(
                    'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursConges.headerTooltip.title',
                  )}
                </Typography>
                <br />
                <Typography whiteSpace="pre-line">
                  {t(
                    'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursConges.headerTooltip.content',
                  )}
                </Typography>
              </>
            }
          />
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.totalJours.columnHeader',
          )}
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.typeAssistance.columnHeader',
          )}
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.forfaitHoraire.columnHeader',
          )}
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.nombreHeureParUniteChoisie.columnHeader',
          )}
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.quantite.columnHeader',
          )}
        </TableCell>
        {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
          <>
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.annualiseMontantTotal.columnHeader',
              )}
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '150px' }}>
              {t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.annualiseMontantTotal.revalorise.columnHeader',
              )}
            </TableCell>
          </>
        ) : null}
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.dateJustificatif.columnHeader',
          )}
        </TableCell>
        <TableCell align="center">
          {t('prejudice.revalorisation.columnHeaders.montantsDejaRevalorises')}
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.montantTotal.columnHeader',
          )}
        </TableCell>
        {tiersPayeurs.map((tiersPayeur) => (
          <TableCell key={tiersPayeur} align="center">
            {t(
              'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.priseEnChargeTiersPayeurs.columnHeader',
              { tiersPayeur },
            )}
          </TableCell>
        ))}
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.deductionFiscale.columnHeader',
          )}
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.autresDeductions.columnHeader',
          )}
        </TableCell>
        <TableCell align="center" sx={{ minWidth: '150px' }}>
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.resteACharge.columnHeader',
          )}
        </TableCell>
        <TableCell align="center" sx={{ minWidth: '150px' }}>
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.resteACharge.revalorise.columnHeader',
          )}
        </TableCell>
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.commentaires.columnHeader',
          )}
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};
