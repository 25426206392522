import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

interface Props {
  width: string;
  children: React.ReactElement;
}

const MHidden: React.FC<Props> = ({ width, children }) => {
  const breakWidth = width.substring(0, 2);
  const breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' =
    breakWidth === 'xs'
      ? 'xs'
      : breakWidth === 'sm'
        ? 'sm'
        : breakWidth === 'md'
          ? 'md'
          : breakWidth === 'lg'
            ? 'lg'
            : 'xl';

  const hiddenUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(breakpoint),
  );
  const hiddenDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakpoint),
  );

  if (width.includes('Down')) {
    return hiddenDown ? null : children;
  }

  if (width.includes('Up')) {
    return hiddenUp ? null : children;
  }

  return null;
};

export default MHidden;
