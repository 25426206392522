import { Checkbox, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  checked: boolean;
  onClickCheck: () => void;
}

export const AcceptNewsletter: React.FC<Props> = ({
  checked,
  onClickCheck,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2} alignItems="center" marginBottom={4}>
      <Checkbox checked={checked} onChange={onClickCheck} />
      <Typography sx={{ color: 'text.secondary' }}>
        {t('pages.SignUp.acceptNewsletters')}
      </Typography>
    </Stack>
  );
};
