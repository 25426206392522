import { RootState } from 'src/store';
import { useAppSelector } from '../store';
import { OrganizationInvitation } from 'src/types/organizationInvitation.type';
import { organizationInvitationSelector } from 'src/store/selectors';

export const useInvitationsToOrganization = (): OrganizationInvitation[] => {
  const invitations = useAppSelector(
    (state: RootState) => state.organizationInvitation.organizationInvitations,
  );
  return invitations;
};

export const useInvitationsByReceiver = (): OrganizationInvitation[] => {
  const invitations = useAppSelector(organizationInvitationSelector.selectAll);
  return invitations;
};
