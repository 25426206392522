import { Upload } from '@mui/icons-material';
import { Box, Divider, Fab, Stack, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { EssentialInformations } from 'src/components/client/EssentialInformations';
import { getPGPFReliquatAndPGPFReliquatActivationStatus } from 'src/helpers/prejudices/pgpfReliquat';
import {
  getPrejudicesTypesPerCategoryPerDirectEntries,
  getSortedPrejudices,
} from 'src/helpers/prejudices/sortAndOrder';
import {
  getPrejudicesTotal,
  PrejudicesTotalAmount,
} from 'src/helpers/prejudices/total';
import { useCanUpdateVictime } from 'src/hooks/store/victime.hook';
import { ButtonTypography } from '../../../components/basic/ButtonTypography';
import { PrejudiceCard } from '../../../components/client/PrejudiceCard';
import { prejudicesData } from '../../../constants/prejudices';
import { theme } from '../../../constants/theme';
import { fCurrency } from '../../../helpers/formatNumber';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { fetchAllDommages } from '../../../slices/dommage';
import { getAllMonetaryErosions } from '../../../slices/monetaryErosion';
import { fetchAllPrejudices } from '../../../slices/prejudice';
import { fetchAllVictimesIndirectes } from '../../../slices/victimeIndirecte';
import {
  baremeSelectors,
  dommageSelectors,
  monetaryErosionSelectors,
  prejudiceSelectors,
  victimeIndirecteSelectors,
} from '../../../store/selectors';
import {
  Prejudice,
  PrejudiceCategorie,
  PrejudiceCategorieType,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { Victime } from '../../../types/victime.type';
import { PrejudiceInputDialog } from './PrejudiceInputDialog';
import { PrintProcedureDialog } from './PrintProcedureDialog';

const useStyles = createUseStyles({
  newRequestButton: {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    position: 'fixed',
    bottom: 24,
    right: 24,
  },
});

/* Component */
interface Props {
  victime: Victime;
}

export const Prejudices: React.FC<Props> = ({ victime }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { procedureId } = useParams<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [openPrejudiceFormDialog, setOpenPrejudiceFormDialog] = useState<{
    prejudice?: Prejudice;
    prejudiceType: PrejudiceType;
  } | null>(null);
  const [isExportOpened, setIsExportOpened] = useState(false);
  useEffect(() => {
    dispatch(getAllMonetaryErosions());
  }, []);

  const {
    dommages,
    prejudices,
    monetaryErosion,
    victimesIndirectes,
    victimesIndirectesIsFetched,
    baremes,
  } = useAppSelector((state) => ({
    dommages: dommageSelectors.selectAll(state),
    prejudices: prejudiceSelectors.selectAll(state),
    monetaryErosion: monetaryErosionSelectors.selectAll(state),
    victimesIndirectes: victimeIndirecteSelectors.selectAll(state),
    victimesIndirectesIsFetched: state.victimeIndirecte.isFetched,
    baremes: baremeSelectors.selectAll(state),
  }));
  const canUpdateVictime = useCanUpdateVictime(victime);
  useEffect(() => {
    if (!procedureId) {
      return;
    }
    dispatch(fetchAllPrejudices(procedureId));

    if (!victimesIndirectesIsFetched) {
      dispatch(fetchAllVictimesIndirectes(procedureId));
    }
  }, []);

  const procedure = useMemo(
    () => victime.procedures.find((procedure) => procedure._id === procedureId),
    [procedureId, victime.procedures],
  );

  const prejudicesCategorisesNotFiltred:
    | Record<PrejudiceCategorieType, [PrejudiceCategorie, PrejudiceType[]][]>
    | undefined = useMemo(
    () =>
      procedure
        ? getPrejudicesTypesPerCategoryPerDirectEntries({
            victime,
            procedure,
            victimesIndirectes,
            prejudices,
          })
        : undefined,
    [procedure, dommages, prejudices, victimesIndirectes],
  );

  const prejudicesCategorises:
    | Record<'DIRECTE' | 'INDIRECTE', [PrejudiceCategorie, PrejudiceType[]][]>
    | undefined = prejudicesCategorisesNotFiltred
    ? {
        DIRECTE: prejudicesCategorisesNotFiltred.DIRECTE.filter(
          ([category, _]) => {
            if (
              !procedure?.dateConsolidation ||
              (victime.dateDeces &&
                procedure.dateConsolidation >= victime.dateDeces)
            ) {
              return (
                category !== 'PREJUDICES_PATRIMONIAUX_PERMANENTS' &&
                category !== 'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS'
              );
            }
            return true;
          },
        ),
        INDIRECTE: prejudicesCategorisesNotFiltred.INDIRECTE,
      }
    : undefined;

  const sortedPrejudices = useMemo(
    () =>
      procedure &&
      getSortedPrejudices({
        victime,
        procedure,
        victimesIndirectes,
        prejudices,
      }),
    [procedure, dommages, prejudices, victimesIndirectes],
  );

  useEffect(() => {
    if (procedure?._id) {
      dispatch(fetchAllDommages(procedure._id));
    }
  }, [procedure?._id]);

  const montantTotal: PrejudicesTotalAmount = useMemo(
    () =>
      procedure
        ? getPrejudicesTotal({
            victime,
            victimesIndirectes,
            procedure,
            prejudices,
            dateLiquidation: procedure.dateLiquidation
              ? new Date(procedure.dateLiquidation)
              : undefined,
            monetaryErosions: monetaryErosion,
            dateConsolidation: procedure.dateConsolidation
              ? new Date(procedure.dateConsolidation)
              : undefined,
            dateDeces: victime.dateDeces
              ? new Date(victime.dateDeces)
              : undefined,
          })
        : {
            victime: 0,
            tiersPayeurs: 0,
            proche: null,
            rentes: null,
            total: 0,
          },
    [prejudices, victime, dommages, procedure, monetaryErosion],
  );

  const PGPFReliquat = useMemo(
    () => getPGPFReliquatAndPGPFReliquatActivationStatus(prejudices),
    [prejudices],
  );

  const canOpenPrejudice = (prejudiceType: PrejudiceType): boolean => {
    if (prejudicesData[prejudiceType].requirements) {
      const requirements = prejudicesData[prejudiceType].requirements || [];

      if (requirements.includes('dateAccident') && !victime.dateAccident) {
        enqueueSnackbar("vous devez saisir une date d'accident valide", {
          variant: 'error',
        });
        return false;
      } else if (requirements.includes('dateDeces') && !victime.dateDeces) {
        enqueueSnackbar('Vous devez saisir une date de décès valide', {
          variant: 'error',
        });
        return false;
      }
    }
    return true;
  };

  const displayNonSelectedPrejudices = (
    nonSelectedPrejudicesCategorises: [PrejudiceCategorie, PrejudiceType[]][],
  ) => (
    <Stack direction="row" spacing={2}>
      {[
        nonSelectedPrejudicesCategorises.slice(
          0,
          Math.ceil(nonSelectedPrejudicesCategorises.length / 2),
        ) || [],
        nonSelectedPrejudicesCategorises.slice(
          Math.ceil(nonSelectedPrejudicesCategorises.length / 2),
        ) || [],
      ].map((prejduiceCategorises, index) => (
        <Stack spacing={2} sx={{ overflowY: 'auto', flex: 1 }} key={index}>
          {prejduiceCategorises.map(([category, prejudices]) => (
            <Stack key={category} spacing={0.5}>
              <Typography variant="h6">
                {t(`prejudice.categories.${category}`)}
              </Typography>
              {prejudices.map((prejudice) => (
                <ButtonTypography
                  key={prejudice}
                  variant="subtitle2"
                  fontWeight="600"
                  onClick={() => {
                    if (canOpenPrejudice(prejudice)) {
                      setOpenPrejudiceFormDialog({
                        prejudiceType: prejudice,
                      });
                    }
                  }}
                >
                  +{' '}
                  {capitalize(
                    t(`prejudice.prejudicesTypes.${prejudice}.title`) || '',
                  )}
                </ButtonTypography>
              ))}
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );

  if (!procedure?._id) {
    return <></>;
  }
  return (
    <>
      <EssentialInformations victime={victime} procedure={procedure} />
      <Box
        sx={{
          overflowY: 'auto',
          margin: theme.spacing(0, 2),
          flexGrow: 1,
          height: '0px',
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          spacing={6.25}
          sx={{ mt: 2, height: 'calc(100% - 16px)' }}
        >
          <Box sx={{ maxWidth: '700px', width: '700px', overflowY: 'auto' }}>
            <Box paddingBottom={2}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Calcul de l&apos;indemnisation
              </Typography>
              {prejudices.length === 0 ? (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ paddingBottom: 2 }}
                >
                  Veuillez sélectionner les préjudices à chiffrer dans la liste
                  de droite
                </Typography>
              ) : (
                <Stack spacing={2}>
                  {(sortedPrejudices ?? []).map((prejudice) => (
                    <PrejudiceCard
                      key={prejudice._id}
                      victime={victime}
                      victimesIndirectes={victimesIndirectes}
                      procedure={procedure}
                      prejudice={prejudice}
                      onClickEdit={() => {
                        if (canOpenPrejudice(prejudice.type)) {
                          setOpenPrejudiceFormDialog({
                            prejudice,
                            prejudiceType: prejudice.type,
                          });
                        }
                      }}
                      dateLiquidation={procedure.dateLiquidation}
                      monetaryErosions={monetaryErosion}
                      PGPFReliquat={PGPFReliquat}
                    />
                  ))}
                </Stack>
              )}
            </Box>
            <Stack
              spacing={1}
              sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'background.paper',
              }}
            >
              <Box sx={{ borderTop: 'solid 3px black' }} />
              <Typography variant="h6">Montant total indemnisation</Typography>
              <Stack
                direction="row"
                paddingLeft={2}
                justifyContent="space-between"
              >
                <Typography variant="h6">Victime directe</Typography>
                <Typography variant="h6" color="green">
                  {fCurrency(montantTotal.victime)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                paddingLeft={2}
                justifyContent="space-between"
              >
                <Typography variant="h6">Victime Indirecte</Typography>
                <Typography variant="h6" color="green">
                  {fCurrency(montantTotal.proche || 0)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                paddingLeft={2}
                justifyContent="space-between"
              >
                <Typography variant="h6">Tiers Payeurs</Typography>
                <Typography variant="h6" color="green">
                  {fCurrency(montantTotal.tiersPayeurs || 0)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                paddingLeft={2}
                justifyContent="space-between"
              >
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6" color="green">
                  {fCurrency(montantTotal.total)}
                </Typography>
              </Stack>
              {montantTotal.rentes || montantTotal.rentes === 0 ? (
                <>
                  <Divider />
                  <Stack
                    direction="row"
                    paddingLeft={2}
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">
                      {t('pages.Prejudices.total.rentes')}
                    </Typography>
                    <Typography variant="h6" color="green">
                      {fCurrency(montantTotal.rentes || 0)}
                    </Typography>
                  </Stack>
                </>
              ) : null}
            </Stack>
          </Box>
          <Stack direction="column" spacing={4} overflow="scroll">
            {prejudicesCategorises?.DIRECTE?.length &&
            prejudicesCategorises?.DIRECTE?.length > 0 ? (
              <Stack direction="column" spacing={2}>
                <Typography variant="h4">
                  {t('prejudice.directe.DIRECTE')}
                </Typography>
                {displayNonSelectedPrejudices(
                  prejudicesCategorises?.DIRECTE || [],
                )}
              </Stack>
            ) : null}
            {prejudicesCategorises?.INDIRECTE?.length &&
            prejudicesCategorises?.INDIRECTE?.length > 0 ? (
              <Stack direction="column" spacing={2}>
                <Typography variant="h4">
                  {t('prejudice.directe.INDIRECTE')}
                </Typography>
                {displayNonSelectedPrejudices(
                  prejudicesCategorises?.INDIRECTE || [],
                )}
              </Stack>
            ) : null}
            {canUpdateVictime ? (
              <Fab
                variant="extended"
                size="medium"
                className={classes.newRequestButton}
                onClick={() => setIsExportOpened(true)}
              >
                Exporter
                <Upload />
              </Fab>
            ) : null}
          </Stack>
          <PrejudiceInputDialog
            open={!!openPrejudiceFormDialog}
            onClose={() => setOpenPrejudiceFormDialog(null)}
            prejudice={openPrejudiceFormDialog?.prejudice}
            prejudiceType={openPrejudiceFormDialog?.prejudiceType}
            allPrejudices={prejudices}
            victime={victime}
            victimesIndirectes={victimesIndirectes}
            procedure={procedure}
            dommages={dommages}
            baremes={baremes}
            PGPFReliquat={PGPFReliquat}
          />
          <PrintProcedureDialog
            open={isExportOpened}
            onClose={() => setIsExportOpened(false)}
            procedure={procedure}
            victime={victime}
            victimesIndirectes={victimesIndirectes}
            dommages={dommages}
            prejudices={sortedPrejudices ?? []}
            monetaryErosions={monetaryErosion}
            baremes={baremes}
          />
        </Stack>
      </Box>
    </>
  );
};
