import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { apiRequest } from 'src/helpers/api';
import { Procedure } from 'src/types/procedure.type';
import { pendingReducer, rejectedReducer } from '../helpers/slice';
import {
  Victime,
  CreateVictimeDto,
  UpdateVictimeDto,
  UpdateVictimeRolesDto,
} from '../types/victime.type';

/* Thunks */

export const fetchAllVictimes = createAsyncThunk(
  'victimes/fetchAll',
  async (organizationId?: string) => {
    if (organizationId) {
      return await apiRequest<Victime[]>(
        'GET',
        `/organization/${organizationId}/victimes`,
      );
    } else {
      return await apiRequest<Victime[]>('GET', '/victimes');
    }
  },
);

export const fetchVictimeById = createAsyncThunk(
  'victime/fetchById',
  async (victimeId: string) => {
    return await apiRequest<Victime>('GET', `/victimes/${victimeId}`);
  },
);

export const deleteVictimeById = createAsyncThunk(
  'victime/deleteById',
  async (victimeId: string) => {
    return await apiRequest<void>('DELETE', `/victimes/${victimeId}`);
  },
);

export const createVictime = createAsyncThunk(
  'victime/create',
  async (createDto: CreateVictimeDto) => {
    const { organization, ...dto } = createDto;
    if (organization) {
      return await apiRequest<Victime>(
        'POST',
        `/organization/${organization}/victimes`,
        undefined,
        dto,
      );
    } else {
      return await apiRequest<Victime>(
        'POST',
        '/victimes',
        undefined,
        createDto,
      );
    }
  },
);

export const updateVictime = createAsyncThunk(
  'victime/update',
  async ({ id, data }: { id: string; data: UpdateVictimeDto }) => {
    return await apiRequest<Victime>(
      'PATCH',
      `/victimes/${id}`,
      undefined,
      data,
    );
  },
);

export const updateVictimeRoles = createAsyncThunk(
  'victime/updateRoles',
  async ({
    organizationId,
    id,
    dto,
  }: {
    organizationId: string;
    id: string;
    dto: UpdateVictimeRolesDto;
  }) => {
    return await apiRequest<Victime>(
      'PATCH',
      `/organization/${organizationId}/victimes/${id}/roles`,
      undefined,
      dto,
    );
  },
);

/* Slice */

export const victimeAdapter = createEntityAdapter<Victime>({
  selectId: (victime: Victime) => victime._id,
});

const victimeSlice = createSlice({
  name: 'victime',
  initialState: victimeAdapter.getInitialState({
    isLoading: false,
  }),
  reducers: {
    addLocalProcedure(
      state,
      { payload }: { payload: { victimeId: string; procedure: Procedure } },
    ) {
      const { victimeId, procedure } = payload;

      if (state.entities[victimeId]) {
        const procedures = (state.entities[victimeId] as Victime).procedures;
        if (!procedures.find((value) => value._id === procedure._id)) {
          procedures.push(procedure);
        }
      }
    },
    updateLocalProcedure(
      state,
      { payload }: { payload: { victimeId: string; procedure: Procedure } },
    ) {
      const { victimeId, procedure } = payload;

      if (state.entities[victimeId]) {
        const procedures = (state.entities[victimeId] as Victime).procedures;
        const procedureIndex = procedures.findIndex(
          (value) => value._id === procedure._id,
        );

        if (procedureIndex !== -1) {
          procedures[procedureIndex] = procedure;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVictimes.pending, pendingReducer)
      .addCase(fetchAllVictimes.rejected, rejectedReducer)
      .addCase(fetchAllVictimes.fulfilled, (state, { payload }) => {
        victimeAdapter.setAll(state, payload);
        state.isLoading = false;
      })
      .addCase(fetchVictimeById.pending, pendingReducer)
      .addCase(fetchVictimeById.rejected, rejectedReducer)
      .addCase(fetchVictimeById.fulfilled, (state, { payload }) => {
        victimeAdapter.setOne(state, payload);
        state.isLoading = false;
      })
      .addCase(createVictime.pending, pendingReducer)
      .addCase(createVictime.rejected, rejectedReducer)
      .addCase(createVictime.fulfilled, (state, { payload }) => {
        victimeAdapter.addOne(state, payload);
        state.isLoading = false;
      })
      .addCase(updateVictime.pending, pendingReducer)
      .addCase(updateVictime.rejected, rejectedReducer)
      .addCase(updateVictime.fulfilled, (state, { payload }) => {
        victimeAdapter.setOne(state, payload);
        state.isLoading = false;
      })
      .addCase(deleteVictimeById.pending, pendingReducer)
      .addCase(deleteVictimeById.rejected, rejectedReducer)
      .addCase(deleteVictimeById.fulfilled, (state, action) => {
        victimeAdapter.removeOne(state, action.meta.arg);
        state.isLoading = false;
      })
      .addCase(updateVictimeRoles.pending, pendingReducer)
      .addCase(updateVictimeRoles.rejected, rejectedReducer)
      .addCase(updateVictimeRoles.fulfilled, (state, { payload }) => {
        victimeAdapter.setOne(state, payload);
        state.isLoading = false;
      });
  },
});
export const { updateLocalProcedure, addLocalProcedure } = victimeSlice.actions;

export default victimeSlice.reducer;
