import { prejudicesData } from 'src/constants/prejudices';
import {
  Prejudice,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormMateriel,
  PrejudiceFormValeur,
  PrejudiceFormValeurVictimesIndirectes,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';

type PrejudiceFormWithCustomPartResponsabilite =
  | PrejudiceFormValeur
  | PrejudiceFormValeurVictimesIndirectes
  | PrejudiceFormCalendrierDepense
  | PrejudiceFormMateriel;
export const getPrejudicesToUpdateAfterProcedureUpdate = ({
  oldProcedure,
  newProcedure,
  prejudices,
}: {
  oldProcedure: Procedure;
  newProcedure: Procedure;
  prejudices: Prejudice[];
}): Prejudice[] => {
  const isPartResponsabiliteUpdated =
    newProcedure.partResponsabilite !== oldProcedure.partResponsabilite;
  const prejudicesToUpdate: Prejudice[] = prejudices.filter((prejudice) => {
    if (
      !(
        isPartResponsabiliteUpdated &&
        prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
          .isCustomPartResponsabilite
      )
    ) {
      return false;
    }
    const formPartResponsabilite = (
      prejudice.formData as PrejudiceFormWithCustomPartResponsabilite
    ).partResponsabilite;
    if (
      formPartResponsabilite !== undefined &&
      formPartResponsabilite === oldProcedure.partResponsabilite
    ) {
      return true;
    }
    return false;
  });

  return prejudicesToUpdate;
};
