import { Prejudice } from 'src/types/prejudice.type';
import i18next from 'i18next';
import { Paragraph, Table, TableOfContents } from 'docx';
import { getParagraph, getTextRun } from '../docxFunctions';
import { isPrejudiceRevalorise } from 'src/helpers/prejudices/revalorisation';

export const prejudiceCalculsPrint = (
  prejudice: Pick<Prejudice, 'formData' | 'formType' | 'type'>,
  displayRevalorisation = true,
): (Paragraph | Table | TableOfContents)[] => {
  displayRevalorisation = Boolean(
    displayRevalorisation && isPrejudiceRevalorise(prejudice),
  );
  const prejudiceCalculs = i18next.t(
    `prejudice.calculs.prejudicesFormTypes.${prejudice.formType}`,
    '',
  );
  return [
    getParagraph({
      children: [
        ...(displayRevalorisation
          ? getTextRun({
              text: `${i18next.t('prejudice.calculs.revalorisation')}`,
              break: 1,
            })
          : []),

        ...(prejudiceCalculs
          ? [
              ...getTextRun({
                text: i18next.t('prejudice.calculs.title'),
                bold: true,
                break: displayRevalorisation ? 2 : 1,
              }),
              ...getTextRun({
                text: prejudiceCalculs,
                break: 1,
              }),
            ]
          : []),
      ],
    }),
  ];
};
