import { Paragraph, Table, TableOfContents } from 'docx';
import { Prejudice, PrejudiceFormValeur } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';

export const valeurWrittenPrint = ({
  victime,
  procedure,
  formData,
  prejudice,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormValeur;
  prejudice: Pick<Prejudice, 'formType' | 'formData' | 'type'>;
}): (Table | TableOfContents | Paragraph)[] => {
  const partResponsabilite =
    formData.partResponsabilite || formData.partResponsabilite === 0
      ? formData.partResponsabilite
      : procedure.partResponsabilite;
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...(prejudice.type === 'CALCUL_DES_INTERETS_ET_CAPITALISATION_DES_INTERETS'
      ? [
          getParagraph(
            i18next.t(
              'prejudice.prejudicesTypes.CALCUL_DES_INTERETS_ET_CAPITALISATION_DES_INTERETS.writtenPrint.total',
              {
                victimeName: displayVictimeNameWrittenPrint(victime),
                numerosPieces: displayNumerosPiecesWrittenPrint(
                  formData.numerosPieces,
                  'Parentheses',
                ),
                total: fCurrency(formData.montant),
              },
            ),
          ),
        ]
      : [
          getParagraph({
            children: [
              ...getTextRun({
                text: i18next.t(
                  `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.firstPart`,
                ),
              }),
              ...getTextRun(
                i18next.t(
                  partResponsabilite === 1
                    ? 'prejudice.prejudicesFormTypes.VALEUR.writtenPrint.text.fullPartResponabilite'
                    : 'prejudice.prejudicesFormTypes.VALEUR.writtenPrint.text.nonFullPartResponabilite',
                  {
                    victimeName: displayVictimeNameWrittenPrint(victime),
                    numerosPieces: displayNumerosPiecesWrittenPrint(
                      formData.numerosPieces,
                      'Parentheses',
                    ),
                    total: fCurrency(formData.montant),
                    ...(partResponsabilite !== 1
                      ? {
                          partResponsabilite: fPartResponsabilite(
                            partResponsabilite * 100,
                          ),
                          totalPartResponsabilite: fCurrency(
                            formData.montant * partResponsabilite,
                          ),
                        }
                      : {}),
                  },
                ),
              ),
            ],
          }),
        ]),
  ];
};
