import React from 'react';
import { Control } from 'react-hook-form';
import {
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
} from '../../../../types/prejudice.type';
import { Procedure } from '../../../../types/procedure.type';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { CalculsFormPerteGainsProfessionnelsFuturs } from '../../../../constants/calculs';
import { TotalPerteGainsFutursContent } from './TotalPerteGainsFutursContent';

interface Props {
  control: Control<OldPrejudiceFormPerteGainProfessionnelsFuturs>;
  procedure: Procedure;
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  dateDeces: Date | undefined;
}

export const TotalPerteGainsFuturs: React.FC<Props> = ({
  control,
  procedure,
  dateConsolidation,
  dateLiquidation,
  dateDeces,
}) => {
  return (
    <ComputedPropsForm
      control={control}
      watchFields={[
        'situations',
        'victimeTotalCapitalise',
        'tiersPayeursTotalCapitalise',
        'capitalisationTiersPayeurs',
      ]}
      compute={([
        situations,
        victimeTotalCapitalise,
        tiersPayeursTotalCapitalise,
        capitalisationTiersPayeurs,
      ]) => {
        return {
          props: {
            ...CalculsFormPerteGainsProfessionnelsFuturs.getIndemnitesRepartie({
              situations,
              victimeTotalCapitalise,
              tiersPayeursTotalCapitalise,
              partResponsabilite: procedure.partResponsabilite,
              tiersPayeurs: procedure.tiersPayeurs,
              capitalisationTiersPayeurs,
              dateConsolidation,
              dateLiquidation,
              dateDeces,
            }),
            tiersPayeurTotalCapitalisePartResponsabilite:
              tiersPayeursTotalCapitalise * procedure.partResponsabilite,
          },
        };
      }}
      render={({
        PGPFReliquat,
        indemniteGlobaleARepartir,
        indemniteVictime,
        indemniteTiersPayeurs,
      }) => (
        <TotalPerteGainsFutursContent
          control={
            control as Control<
              | OldPrejudiceFormPerteGainProfessionnelsFuturs
              | NewPrejudiceFormPerteGainProfessionnelsFuturs
              | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
            >
          }
          indemniteRepartie={{
            indemniteGlobaleARepartir,
            indemniteVictime,
            indemniteTiersPayeurs,
          }}
          PGPFReliquat={PGPFReliquat}
          partResponsabilite={procedure.partResponsabilite}
        />
      )}
    />
  );
};
