import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { apiRequest } from '../helpers/api';

/* Thunks */
export const getAllMonetaryErosions = createAsyncThunk(
  'monetaryErosion/getAll',
  async () => {
    return await apiRequest<MonetaryErosion[]>('GET', `/monetaryErosion`);
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
};

/* Adapter */
export const monetaryErosionAdapter = createEntityAdapter<MonetaryErosion>({
  selectId: (monetaryerosion) => monetaryerosion._id,
});

/* Slice */
const monetaryErosionSlice = createSlice({
  name: 'monetaryErosion',
  initialState: monetaryErosionAdapter.getInitialState({
    isLoading: false,
    error: undefined,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMonetaryErosions.pending, sharedReducers.pending)
      .addCase(getAllMonetaryErosions.rejected, sharedReducers.rejected)
      .addCase(getAllMonetaryErosions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        monetaryErosionAdapter.setAll(state, payload);
      });
  },
});

export default monetaryErosionSlice.reducer;
