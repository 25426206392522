import { Paragraph, Table, TableOfContents } from 'docx';
import { CalculsFormPerteGainsProfessionnelsActuel } from 'src/constants/calculs';
import { fDateLong } from 'src/helpers/formatTime';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { OldPrejudiceFormPerteGainProfessionnelsActuel } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../../docxFunctions';
import { debitSoldeEchusPrint } from '../../debitSoldeEchusPrint';
import { prejudiceNotesPrint } from '../../prejudiceNotesPrint';
import { situationsPrint } from './situationsPrint';
import { tableSituationPrint } from './tableSituationPrint';

export const perteGainsProfessionnelsActuelsPrint = (
  procedure: Procedure,
  formData: OldPrejudiceFormPerteGainProfessionnelsActuel,
  monetaryErosions: MonetaryErosion[],
): (Paragraph | Table | TableOfContents)[] => {
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const datesPrint = new Paragraph({
    children: [
      ...(formData.dateDebut
        ? getTextRun({
            text: `Date début : ${fDateLong(formData.dateDebut)}`,
          })
        : []),
      ...(formData.dateFin
        ? getTextRun({
            break: formData.dateDebut ? 1 : 0,
            text: `Date fin : ${fDateLong(formData.dateFin)}`,
          })
        : []),
    ],
  });
  const totaux = CalculsFormPerteGainsProfessionnelsActuel.getTotauxSituations(
    formData.situations,
    procedure.partResponsabilite,
  );

  const indemniteGlobaleARepartir =
    CalculsFormPerteGainsProfessionnelsActuel.getIndemnitesRepartie(
      formData.situations,
      procedure.tiersPayeurs,
      procedure.partResponsabilite,
    );
  const tableSituation = tableSituationPrint(
    formData.situations,
    totaux,
    procedure.partResponsabilite,
    false,
  );
  const tableTotal = debitSoldeEchusPrint({
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
  });
  const situations = situationsPrint({
    situations: formData.situations,
    monetaryErosions,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : null,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    datesPrint,
    getEmptyLine(),
    ...situations,
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: 'Au total',
        bold: true,
      }),
    }),
    getEmptyLine(),
    tableSituation,
    getEmptyLine(),
    ...tableTotal,
  ];
};
