import { prejudiceCategoriesData } from 'src/constants/prejudices';
import {
  Prejudice,
  PrejudiceCategorie,
  PrejudiceCategorieType,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import {
  mapAnswerToPrejudices,
  prejudicesData,
  prejudicesVictimesIndirectes,
} from 'src/constants/prejudices';

export const getPrejudicesTypesPerCategory = ({
  procedure,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
}): Partial<Record<PrejudiceCategorie, PrejudiceType[]>> => {
  const lastProcedureAnswer = procedure.answers[procedure.answers.length - 1];
  const prejudices = lastProcedureAnswer
    ? mapAnswerToPrejudices[lastProcedureAnswer]
    : undefined;
  return (prejudices?.consolideOuNonConsolide || [])
    .concat(prejudices?.consolide || [])
    .concat(prejudices?.exceptionnal || [])
    .concat(
      victimesIndirectes.length > 0
        ? [
            ...prejudicesVictimesIndirectes.blesseeOuDecedee,
            ...(!!victime.dateDeces
              ? prejudicesVictimesIndirectes.decedee
              : []),
          ]
        : [],
    )
    .reduce<Partial<Record<PrejudiceCategorie, PrejudiceType[]>>>(
      (accumulator, value) => {
        const categorie =
          prejudicesData[value || 'DEPENSE_SANTE_FUTURES'].categorie;
        if (accumulator[categorie]) {
          accumulator[categorie]?.push(value);
        } else {
          accumulator[categorie] = [value];
        }
        return accumulator;
      },
      {},
    );
};

export const getNotSelectedPrejudicesTypesPerCategory = ({
  procedure,
  prejudices,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
  prejudices: Prejudice[];
}): Partial<Record<PrejudiceCategorie, PrejudiceType[]>> =>
  Object.entries(
    getPrejudicesTypesPerCategory({
      procedure,
      victime,
      victimesIndirectes,
    }),
  ).reduce(
    (
      accumulator: Partial<Record<PrejudiceCategorie, PrejudiceType[]>>,
      [category, prejudicesTypes],
    ) => {
      const notSelectedPrejudicesTypes = prejudicesTypes.filter(
        (prejudiceType) =>
          !prejudices.some((prejudice) => prejudice.type === prejudiceType),
      );
      if (notSelectedPrejudicesTypes.length > 0) {
        accumulator[category as PrejudiceCategorie] =
          notSelectedPrejudicesTypes;
      }
      return accumulator;
    },
    {},
  );

export const getSelectedPrejudicesTypesPerCategory = ({
  procedure,
  prejudices,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
  prejudices: Prejudice[];
}): Partial<Record<PrejudiceCategorie, PrejudiceType[]>> =>
  Object.entries(
    getPrejudicesTypesPerCategory({
      procedure,
      victime,
      victimesIndirectes,
    }),
  ).reduce(
    (
      accumulator: Partial<Record<PrejudiceCategorie, PrejudiceType[]>>,
      [category, prejudicesTypes],
    ) => {
      const selectedPrejudicesTypes = prejudicesTypes.filter((prejudiceType) =>
        prejudices.some((prejudice) => prejudice.type === prejudiceType),
      );
      if (selectedPrejudicesTypes.length > 0) {
        accumulator[category as PrejudiceCategorie] = selectedPrejudicesTypes;
      }
      return accumulator;
    },
    {},
  );

export const getPrejudicesPerCategory = ({
  procedure,
  prejudices,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
  prejudices: Prejudice[];
}): Partial<Record<PrejudiceCategorie, Prejudice[]>> => {
  const prejudicesTypesPerCategory = getPrejudicesTypesPerCategory({
    victime,
    procedure,
    victimesIndirectes,
  });
  const definedPrejudices = prejudices.filter(
    (prejudice): prejudice is Prejudice => Boolean(prejudice),
  );
  return Object.entries(prejudicesTypesPerCategory).reduce(
    (
      accumulator: Partial<Record<PrejudiceCategorie, Prejudice[]>>,
      [categorie, types],
    ) => ({
      ...accumulator,
      [categorie]: types
        .map((prejudiceType) =>
          definedPrejudices.find(
            (prejudice) => prejudice.type === prejudiceType,
          ),
        )
        .filter((prejudice): prejudice is Prejudice => Boolean(prejudice)),
    }),
    {},
  );
};

export const getPrejudicesPerCategoryPerDirectEntries = ({
  procedure,
  prejudices,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
  prejudices: Prejudice[];
}): Record<PrejudiceCategorieType, [PrejudiceCategorie, Prejudice[]][]> => {
  const prejudicesPerCategory = getPrejudicesPerCategory({
    procedure,
    prejudices,
    victime,
    victimesIndirectes,
  });
  const prejudicesEntries = Object.entries(prejudicesPerCategory) as [
    PrejudiceCategorie,
    Prejudice[],
  ][];
  return {
    DIRECTE: prejudicesEntries.filter(
      ([categorie]) => prejudiceCategoriesData[categorie].directe,
    ),
    INDIRECTE: prejudicesEntries.filter(
      ([categorie]) => !prejudiceCategoriesData[categorie].directe,
    ),
  };
};

export const sortCategories = (categories: PrejudiceCategorie[]) =>
  categories.sort((categorieA, categorieB) => {
    const categorieAOrder = prejudiceCategoriesData[categorieA].rank;
    const categorieBOrder = prejudiceCategoriesData[categorieB].rank;
    return categorieAOrder - categorieBOrder;
  });

export const getSortedPrejudices = ({
  procedure,
  victime,
  victimesIndirectes,
  prejudices,
}: {
  procedure: Procedure;
  prejudices: Prejudice[];
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
}): Prejudice[] => {
  const prejudicesPerCategory = getPrejudicesPerCategory({
    procedure,
    prejudices,
    victime,
    victimesIndirectes,
  });
  const sortedCategories = sortCategories(
    Object.keys(prejudicesPerCategory) as PrejudiceCategorie[],
  );
  return sortedCategories.reduce((accumulator: Prejudice[], category) => {
    const categoryPrejudices = prejudicesPerCategory[category];
    if (categoryPrejudices) {
      return accumulator.concat(categoryPrejudices);
    }
    return accumulator;
  }, []);
};

export const getPrejudicesTypesPerCategoryPerDirectEntries = ({
  procedure,
  prejudices,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
  prejudices: Prejudice[];
}): Record<PrejudiceCategorieType, [PrejudiceCategorie, PrejudiceType[]][]> => {
  const notSelectedPrejudicesTypesPerCategoryEntries = Object.entries(
    getNotSelectedPrejudicesTypesPerCategory({
      procedure,
      prejudices,
      victime,
      victimesIndirectes,
    }),
  ) as [PrejudiceCategorie, PrejudiceType[]][];
  return {
    DIRECTE: notSelectedPrejudicesTypesPerCategoryEntries.filter(
      ([categorie]) => prejudiceCategoriesData[categorie].directe,
    ),
    INDIRECTE: notSelectedPrejudicesTypesPerCategoryEntries.filter(
      ([categorie]) => !prejudiceCategoriesData[categorie].directe,
    ),
  };
};
