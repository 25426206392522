import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Control } from 'react-hook-form';

import {
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
} from '../../../../types/prejudice.type';
import { fCurrency } from '../../../../helpers/formatNumber';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { useTranslation } from 'react-i18next';
import { TotalIndemniteGlobaleARepartirAEchoir } from '../TotalIndemniteGlobaleARepartirAEchoir';
import {
  IndemniteRepartieAEchoir,
  PGPFReliquat,
} from 'src/constants/calculs/type';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { RentesOption } from '../Rentes/RenteOption';
import { CalculsRentes } from 'src/constants/calculs/calculsRentes';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';

interface Props {
  control: Control<
    | OldPrejudiceFormPerteGainProfessionnelsFuturs
    | NewPrejudiceFormPerteGainProfessionnelsFuturs
    | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
  >;
  indemniteRepartie: Omit<IndemniteRepartieAEchoir, 'indemniteTiersPayeurs'> &
    Partial<Pick<IndemniteRepartieAEchoir, 'indemniteTiersPayeurs'>>;
  PGPFReliquat?: PGPFReliquat;
  partResponsabilite: number;
}

export const TotalPerteGainsFutursContent: React.FC<Props> = ({
  indemniteRepartie,
  PGPFReliquat,
  control,
  partResponsabilite,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TotalIndemniteGlobaleARepartirAEchoir
          indemniteGlobaleARepartir={indemniteRepartie}
          PGPFReliquat={PGPFReliquat}
          partResponsabilite={partResponsabilite}
        />
      </Grid>
      {PGPFReliquat ? (
        <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
          <CalculsBox>
            <Typography textAlign="center" variant="subtitle1">
              {t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.PGPFReliquat.basculeIP.title',
              )}
            </Typography>
            <Stack
              direction="row"
              alignSelf={PGPFReliquat.total ? 'flex-end' : 'center'}
              alignItems="center"
              justifyContent={PGPFReliquat.total ? 'space-between' : 'center'}
            >
              <Typography variant="body2">
                {PGPFReliquat.total
                  ? t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.PGPFReliquat.basculeIP.label',
                      {
                        tropPercu: fCurrency(-PGPFReliquat.total),
                      },
                    )
                  : t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.PGPFReliquat.basculeIP.noTropPercuLabel',
                    )}
              </Typography>
              {PGPFReliquat.total ? (
                <CheckboxFormField
                  control={control as any}
                  name="activatePGPFReliquat"
                />
              ) : null}
            </Stack>
          </CalculsBox>
        </Grid>
      ) : (
        <Grid item xs={6} />
      )}
      <Grid item xs={6}>
        <ComputedPropsForm
          control={control}
          customValues={[
            indemniteRepartie.indemniteVictime.arreragesAEchoir.debit,
          ]}
          watchFields={[
            'capitalisation.coefficient',
            'prixEuroRente',
            'victimeCoefficientCapitalisation',
          ]}
          compute={(coefficientsValues, customValues) => {
            const coefficient =
              coefficientsValues[0] ||
              coefficientsValues[1] ||
              coefficientsValues[2] ||
              0;
            const indemniteVictimeAEchoir = customValues?.[0] || 0;
            return {
              props: {
                renteMontant: CalculsRentes.getAnnuel(
                  indemniteVictimeAEchoir,
                  coefficient,
                ),
              },
            };
          }}
          render={({ renteMontant }) => (
            <RentesOption
              control={control as any}
              name={{
                renteMontant: 'rentes.montant',
                isRentesOption: 'isRentesOption',
                editedFields: 'editedFields',
              }}
              renteDefaultValue={renteMontant}
            />
          )}
        />
      </Grid>
      {PGPFReliquat?.total ? null : (
        <ComputedReadFieldForm
          control={control as any}
          name="activatePGPFReliquat"
          watchFields={[]}
          compute={([]) => false}
        />
      )}
      <ComputedReadFieldForm
        control={control as any}
        name="PGPFReliquat"
        watchFields={[]}
        customValues={[PGPFReliquat?.total]}
        compute={([], customValues) => customValues?.[0]}
      />
    </Grid>
  );
};
