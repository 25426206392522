import React from 'react';
import { Control, FieldPath } from 'react-hook-form';
import { Button, Stack } from '@mui/material';

import { OldPrejudiceFormPerteGainProfessionnelsActuel } from '../../../../types/prejudice.type';
import { DatePickerForm } from '../../../forms/DatePickerForm';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';

interface Props {
  control: Control<OldPrejudiceFormPerteGainProfessionnelsActuel>;
  dateConsolidation: Date | undefined;
  dateAccident: Date | undefined;
  dateLiquidation: Date | undefined;
  isPGPF?: boolean;
  validate: (
    fieldsToValidate: FieldPath<OldPrejudiceFormPerteGainProfessionnelsActuel>[],
  ) => void;
  onPrevious?: () => void;
}

export const PeriodeStep: React.FC<Props> = ({
  control,
  dateConsolidation,
  dateAccident,
  dateLiquidation,
  isPGPF,
  validate,
  onPrevious,
}) => (
  <>
    <Stack direction="row" justifyContent="space-evenly">
      <DatePickerForm
        control={control}
        name="dateDebut"
        label="Date de début"
        TextFieldProps={{
          InputLabelProps: { shrink: true },
          sx: {
            minWidth: 155,
            width: 155,
          },
        }}
        minDate={isPGPF ? dateConsolidation : dateAccident}
        disabled={isPGPF && !!dateConsolidation}
      />
      <DatePickerForm
        control={control}
        name="dateFin"
        label="Date de fin"
        TextFieldProps={{
          InputLabelProps: { shrink: true },
          sx: {
            minWidth: 155,
            width: 155,
          },
        }}
        minDate={dateAccident}
        maxDate={
          isPGPF ? dateLiquidation : dateConsolidation || dateLiquidation
        }
      />
    </Stack>
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {!isPGPF && onPrevious && (
        <Button
          variant="outlined"
          color="primary"
          sx={{ alignSelf: 'center' }}
          onClick={onPrevious}
        >
          Précédent
        </Button>
      )}
      <ComputedPropsForm
        control={control}
        watchFields={['dateDebut', 'dateFin']}
        compute={([dateDebut, dateFin]) => ({
          props: {
            dateDebut,
            dateFin,
          },
        })}
        render={({ dateDebut, dateFin }) => (
          <Button
            variant="contained"
            sx={{ alignSelf: 'center' }}
            onClick={() => validate(['dateDebut', 'dateFin'])}
            disabled={!dateDebut || !dateFin}
          >
            Valider
          </Button>
        )}
      />
    </Stack>
  </>
);
