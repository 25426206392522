import React from 'react';

import { Stack, Container, Typography } from '@mui/material';

import {
  AuthenticationContentStyle,
  AuthenticationSectionStyle,
  AuthenticationStyle,
} from 'src/components/authentication/AuthenticationStyles';
import MHidden from 'src/components/extended/MHidden';
import LostPasswordForm from 'src/containers/authentication/LostPasswordForm';
import { APP_NAME, APP_NAME_UPPER } from '../constants/global';
import illustrationRegister from 'src/assets/images/illustration_register.png';

export const LostPassword: React.FC = () => {
  return (
    <AuthenticationStyle title={`LostPassword | ${APP_NAME}`}>
      <MHidden width="mdDown">
        <AuthenticationSectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {APP_NAME_UPPER}
          </Typography>
          <img src={illustrationRegister} alt="login" />
        </AuthenticationSectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <AuthenticationContentStyle>
          <Stack sx={{ mb: 5, display: 'none' }}>
            <Typography variant="h4" gutterBottom>
              Title
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Subtitle</Typography>
          </Stack>

          <LostPasswordForm />
        </AuthenticationContentStyle>
      </Container>
    </AuthenticationStyle>
  );
};
