import { styled, Table } from '@mui/material';

export const NormalTable = styled(Table)(({ theme }) => ({
  '& .MuiTableBody-root': {
    '.MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: '#F7F7F8',
      '& .MuiTableCell-stickyHeader': {
        backgroundColor: '#F7F7F8',
      },
    },
  },
  // '& .MuiTableCell-stickyHeader': {
  //   '.MuiTableRow-root:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
  '& .MuiTableFooter-root': {
    '.MuiTableRow-root:first-of-type .MuiTableCell-footer': {
      borderTop: `1px solid rgb(241, 243, 244)`,
    },
  },
  '& .MuiTableCell-body': {
    borderBottom: 'none',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  '& .MuiTableCell-footer': {
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderBottom: 'none',
    fontSize: theme.typography.body2.fontSize,
  },
  '& .MuiOutlinedInput-root	': {
    height: 40,
    backgroundColor: theme.palette.background.default,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 13,
    minWidth: 70,
  },
}));
