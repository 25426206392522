import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { LayoutTable } from 'src/components/styled';
import { useTranslation } from 'react-i18next';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { fCurrency } from 'src/helpers/formatNumber';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { CalculsRentes } from 'src/constants/calculs/calculsRentes';
import { ReadFieldForm } from 'src/components/forms/ReadFieldForm';

interface Props<
  TFieldValues extends FieldValues,
  TRenteMontantName extends FieldPath<TFieldValues> & `${string}.montant`,
  TEditedFieldsName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  name: {
    renteMontant: TRenteMontantName;
    editedFields: TEditedFieldsName;
  };
  renteDefaultValue?: number;
  titles?: {
    renteMontant?: string;
    renteMontantTrimestriel?: string;
    renteMontantMensuel?: string;
    renteDefaultValue?: string;
  };
  isReadonly?: boolean;
}
export const Rentes = <
  TFieldValues extends FieldValues,
  TRenteMontantName extends FieldPath<TFieldValues> & `${string}.montant`,
  TEditionFieldsName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  titles,
  isReadonly,
  renteDefaultValue,
}: Props<TFieldValues, TRenteMontantName, TEditionFieldsName>) => {
  const { t } = useTranslation();
  return (
    <LayoutTable>
      <TableBody>
        <TableRow>
          <TableCell>
            {titles?.renteMontantMensuel ||
              t('prejudice.rentes.form.fields.montantMensuel.rowHeader')}
          </TableCell>
          <TableCell align="right">
            <ComputedPropsForm
              control={control}
              watchFields={[name.renteMontant]}
              compute={([renteMontant]) => ({
                props: {
                  renteMontantMensuel:
                    CalculsRentes.getMensuel(renteMontant) || 0,
                },
              })}
              render={({ renteMontantMensuel }) => (
                <>{fCurrency(renteMontantMensuel)}</>
              )}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {titles?.renteMontantTrimestriel ||
              t('prejudice.rentes.form.fields.montantTrimestriel.rowHeader')}
          </TableCell>
          <TableCell align="right">
            <ComputedPropsForm
              control={control}
              watchFields={[name.renteMontant]}
              compute={([renteMontant]) => ({
                props: {
                  renteMontantTrimestriel:
                    CalculsRentes.getTrimestriel(renteMontant) || 0,
                },
              })}
              render={({ renteMontantTrimestriel }) => (
                <>{fCurrency(renteMontantTrimestriel)}</>
              )}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {titles?.renteMontant ||
              t('prejudice.rentes.form.fields.montant.rowHeader')}
          </TableCell>
          <TableCell align="right">
            {isReadonly ? (
              renteDefaultValue ? (
                fCurrency(renteDefaultValue)
              ) : (
                <ReadFieldForm
                  control={control}
                  name={name.renteMontant}
                  render={(value) => <>{fCurrency(value)}</>}
                />
              )
            ) : renteDefaultValue || renteDefaultValue === 0 ? (
              <ComputedTextFieldForm<
                TFieldValues,
                any[],
                TRenteMontantName,
                any[]
              >
                control={control}
                name={name.renteMontant}
                customValues={[renteDefaultValue]}
                watchFields={[]}
                compute={(_, customValues) => customValues?.[0] || 0}
                InputProps={{
                  inputComponent: MaskNumber as any,
                  inputProps: {
                    suffix: '€',
                    numberMask: { scale: 2, signed: true },
                  } as IMaskNumberProps,
                }}
                editedFieldsName={name.editedFields}
              />
            ) : (
              <TextFieldForm
                control={control}
                name={name.renteMontant}
                InputProps={{
                  inputComponent: MaskNumber as any,
                  inputProps: {
                    suffix: '€',
                    numberMask: { scale: 2, signed: true },
                  } as IMaskNumberProps,
                }}
              />
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </LayoutTable>
  );
};
