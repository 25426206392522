import { Table } from 'docx';
import { fCurrency } from 'src/helpers/formatNumber';
import { simpleVerticalTablePrint } from '../simpleVerticalTablePrint';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import i18next from 'i18next';

export const partResponsabiliteTotalPrint = ({
  total,
  partResponsabilite,
}: {
  total: number;
  partResponsabilite: number;
}): Table => {
  const totalPartResponsabilite = total * partResponsabilite;
  return simpleVerticalTablePrint([
    {
      label: i18next.t('partResponsabilite.tableRowHeader', {
        partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
      }),
      value: fCurrency(totalPartResponsabilite),
      bold: true,
    },
  ]);
};
