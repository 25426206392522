import React from 'react';
import { Step, StepButton, Stepper } from '@mui/material';

interface Props {
  activeStep: number;
  steps: {
    label: string;
    title: string;
    disabled?: boolean;
    onClick?: (label: string) => void;
  }[];
}

export const StepManagerHeader: React.FC<Props> = ({ activeStep, steps }) => {
  return (
    <Stepper nonLinear activeStep={activeStep}>
      {steps.map(({ label, title, disabled, onClick }) => (
        <Step key={label}>
          <StepButton
            color="inherit"
            disabled={disabled}
            onClick={onClick ? () => onClick(label) : undefined}
          >
            {title}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};
