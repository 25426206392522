import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { join } from 'lodash';
import { fCurrency } from 'src/helpers/formatNumber';
import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';
import {
  NumberingType,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import {
  displayFormula,
  displayRowsSumFormula,
} from 'src/helpers/print/prejudicesWrittenPrints/formula';
import { displayNumerosPiecesWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/numerosPieces.written.print';
import { getMontantRevaloriseWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/revalorisation.written.print';
import { displayVictimeNameWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/victime.written.print';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
  PerteGainProfessionnelsActuelSituationRevenusReelsAnnee,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';

export const revenuReelWrittenPrint = ({
  anneesRevenusReels,
  dateLiquidation,
  monetaryErosions,
  victime,
  revenuTheorique,
  situationIndex,
  revenusReelsNet,
  totalPerteDeGain,
  formData: { revalorisationCoefficientsType, ...formData },
}: Pick<
  PerteGainProfessionnelsActuelSituation,
  | 'revenusReelsNet'
  | 'revenuDeReference'
  | 'revenuTheorique'
  | 'totalPerteDeGain'
> & {
  anneesRevenusReels: PerteGainProfessionnelsActuelSituationRevenusReelsAnnee[];
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  victime: Victime;
  situationIndex: number;
  formData: OldPrejudiceFormPerteGainProfessionnelsActuel;
}): (Table | TableOfContents | Paragraph)[] => {
  const annees = anneesRevenusReels.filter(
    (annee) => !!annee.netPaye || !!annee.netFiscal,
  );
  const firstPartText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.firstPart',
  );

  const getYearNetPayeText = (
    annee: PerteGainProfessionnelsActuelSituationRevenusReelsAnnee,
  ): string =>
    !annee.netPaye
      ? ''
      : annee.annee < 2019 && annee.netFiscal
        ? i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.year.netPaye.netFiscal',
            {
              netFiscal: fCurrency(annee.netFiscal),
              netPaye: fCurrency(annee.netPaye),
              numerosPieces: displayNumerosPiecesWrittenPrint(
                annee.numerosPieces,
                'Parentheses',
              ),
              revalorisation: getMontantRevaloriseWrittenPrint({
                anneeOrDateLiquidation: dateLiquidation,
                anneeOrDateMontant: annee.annee,
                coefficientsType: revalorisationCoefficientsType,
                montant: annee.netPaye || 0,
                monetaryErosions,
              }),
            },
          )
        : i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.year.netPaye.noNetFiscal',
            {
              netPaye: fCurrency(annee.netPaye),
              numerosPieces: displayNumerosPiecesWrittenPrint(
                annee.numerosPieces,
                'Parentheses',
              ),
              revalorisation: getMontantRevaloriseWrittenPrint({
                anneeOrDateLiquidation: dateLiquidation,
                anneeOrDateMontant: annee.annee,
                coefficientsType: revalorisationCoefficientsType,
                montant: annee.netPaye || 0,
                monetaryErosions,
              }),
            },
          );

  const totalPerteDeGainFormulaText = displayFormula({
    formula: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.totalPerteDeGain.formula',
      {
        revenuTheorique: fCurrency(revenuTheorique),
        revenusReelsNet: fCurrency(revenusReelsNet || 0),
        context: !revenusReelsNet
          ? 'noRevenusReelsNet'
          : !revenuTheorique
            ? 'noRevenuTheorique'
            : undefined,
      },
    ),
    options: { withParentheses: true },
    editedFieldsParameters: {
      fieldName: `situations.${situationIndex}.totalPerteDeGain`,
      formData,
    },
  });

  const getOneYearParagraphs = (
    annee: PerteGainProfessionnelsActuelSituationRevenusReelsAnnee,
  ): Paragraph[] => {
    if (!annee.netPaye) {
      return [
        getParagraph(
          i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.oneYear.noNetPaye',
            {
              year: annee.annee,
              victimeName: displayVictimeNameWrittenPrint(victime),
              numerosPieces: displayNumerosPiecesWrittenPrint(
                annee.numerosPieces,
                'Parentheses',
              ),
              totalPerteDeGain: fCurrency(totalPerteDeGain),
              formula: totalPerteDeGainFormulaText,
            },
          ),
        ),
      ];
    } else {
      return [
        getParagraph(
          `${firstPartText}${i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.oneYear.netPaye.firstPart',
            {
              year: annee.annee,
              victimeName: displayVictimeNameWrittenPrint(victime),
            },
          )}${getYearNetPayeText(annee)}`,
        ),
        getParagraph(
          i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.oneYear.netPaye.lastPart',
            {
              totalPerteDeGain: fCurrency(totalPerteDeGain),
              formula: totalPerteDeGainFormulaText,
            },
          ),
        ),
      ];
    }
  };
  const getMultipleYearsYearParagraph = (
    annee: PerteGainProfessionnelsActuelSituationRevenusReelsAnnee,
  ): Paragraph =>
    getParagraph({
      numbering: {
        reference: NumberingType.SIMPLE_LIST,
        level: 0,
      },
      children: [
        ...getTextRun(
          i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.multipleYears.yearFirstPart',
            {
              year: annee.annee,
            },
          ),
        ),
        ...(!annee.netPaye
          ? getTextRun(
              i18next.t(
                'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.multipleYears.noNetPaye',
                {
                  numerosPieces: displayNumerosPiecesWrittenPrint(
                    annee.numerosPieces,
                    'Parentheses',
                  ),
                },
              ),
            )
          : getTextRun(getYearNetPayeText(annee))),
      ],
    });
  switch (annees.length) {
    case 1:
      return annees[0]
        ? [
            getParagraph(
              `${firstPartText}${i18next.t(
                'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.oneYear.firstPart',
                {
                  revenuTheorique: fCurrency(revenuTheorique),
                  year: annees[0].annee,
                },
              )}`,
            ),
            ...getOneYearParagraphs(annees[0]),
          ]
        : [];
    default:
      return [
        getParagraph(
          `${firstPartText}${i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.multipleYears.firstPart',
            {
              revenuTheorique: fCurrency(revenuTheorique),
              victimeName: displayVictimeNameWrittenPrint(victime),
              years: join(
                annees.map((annee) => annee.annee),
                ', ',
              ),
            },
          )}`,
        ),
        ...annees.map((annee) => getMultipleYearsYearParagraph(annee)),
        getParagraph(
          i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.multipleYears.lastPart',
            {
              revenusReelsNet: fCurrency(revenusReelsNet || 0),
              victimeName: displayVictimeNameWrittenPrint(victime),
              revenusReelsNetFormula: displayRowsSumFormula({
                rowValues: annees.map((annee) =>
                  !annee.montantsDejaRevalorises
                    ? getMontantRevalorise({
                        anneeOrDateLiquidation: dateLiquidation,
                        anneeOrDateMontant: annee.annee,
                        coefficientsType: revalorisationCoefficientsType,
                        montant: annee.netPaye || 0,
                        monetaryErosions,
                      }) || 0
                    : annee.netPaye || 0,
                ),
                editedFieldsParameters: {
                  fieldName: `situations.${situationIndex}.revenusReelsNet`,
                  formData,
                },
                options: { withParentheses: true },
              }),
              totalPerteDeGain: fCurrency(totalPerteDeGain),
              formula: totalPerteDeGainFormulaText,
            },
          ),
        ),
      ];
  }
};
