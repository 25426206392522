import { get } from 'lodash';
import React, { memo, useMemo } from 'react';
import {
  useWatch,
  FieldValues,
  FieldPath,
  FieldPathValues,
  Control,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
//TODO : add a way to assign form values based on the computed result, and pass which values to assign to which fields
interface Props<
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>[],
  TCustomValues,
  TComputedResultProps extends Record<string, any>,
> {
  control: Control<TFieldValues>;
  watchFields: readonly [...TFieldNames];
  customValues?: TCustomValues;
  compute: (
    values: FieldPathValues<TFieldValues, TFieldNames>,
    customValues?: TCustomValues,
  ) => ComputedResult<TComputedResultProps>;
  render: (
    props: TComputedResultProps | Record<string, never>,
  ) => React.ReactElement;
  valuesToSetAfterCompute?: Partial<
    Record<FieldPath<TFieldValues>, FieldPath<TComputedResultProps>>
  >;
}

type ComputedResult<TProps extends Record<string, any>> = {
  hidden?: boolean;
  // TODO : if props is not passed, it should be passed as Record<string, never> in the render() function, instead of Record<string, any>
  props?: TProps;
};

const ComputedPropsFormComponent = <
  TFieldValues extends FieldValues,
  TFieldNames extends FieldPath<TFieldValues>[],
  TCustomValues,
  TComputedResultProps extends Record<string, any>,
>({
  control,
  customValues,
  watchFields,
  valuesToSetAfterCompute,
  compute,
  render,
}: Props<TFieldValues, TFieldNames, TCustomValues, TComputedResultProps>) => {
  const watchValues = useWatch({ control, name: watchFields });
  const memoizedCustomValues = useMemo(() => customValues, [customValues]);
  const {
    setValue,
  }: {
    setValue: UseFormReturn<TFieldValues>['setValue'] | undefined;
  } = useFormContext<TFieldValues>() ?? {
    setValue: undefined,
  };
  const setValuesAfterComputeCallback = useDebouncedCallback(
    (computedResultProps: TComputedResultProps | undefined) => {
      if (computedResultProps && valuesToSetAfterCompute) {
        (
          Object.keys(valuesToSetAfterCompute) as FieldPath<TFieldValues>[]
        ).forEach((key) => {
          const computedPropsResultKey = get(valuesToSetAfterCompute, key);
          if (!computedPropsResultKey) {
            return;
          }
          const valueToSet = get(computedResultProps, computedPropsResultKey);
          setValue?.(key, valueToSet);
        });
      }
    },
  );
  const computedResult = useMemo<ComputedResult<TComputedResultProps>>(() => {
    const computedResult = compute(watchValues, memoizedCustomValues);
    const computedResultProps = computedResult.props;
    setValuesAfterComputeCallback(computedResultProps);
    return computedResult;
  }, [watchValues, memoizedCustomValues]);

  if (computedResult.hidden) {
    return null;
  }
  return render(computedResult.props || {});
};

export const ComputedPropsForm = memo(
  ComputedPropsFormComponent,
) as typeof ComputedPropsFormComponent;
