import React from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { FormControlLabel, Stack } from '@mui/material';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { Bareme, BaremeCapitalisationValue } from 'src/types/bareme.type';
import { getAgesLastArrerage } from 'src/helpers/bareme/bareme';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { useTranslation } from 'react-i18next';

interface Props<
  TFieldValues extends FieldValues,
  TBaremeName extends FieldPath<TFieldValues>,
  TAgeDernierArreragename extends FieldPath<TFieldValues>,
  TIsLastArrerageViagerName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  baremeName: TBaremeName;
  ageDernierArrerageName: TAgeDernierArreragename;
  isLastArrerageViagerName: TIsLastArrerageViagerName;
  baremes: Bareme[];
  sexe: 'm' | 'f' | 'u';
  ageDateAttribution: number | null;
}

export const SelectDerniersArrerages = <
  TFieldValues extends FieldValues,
  TBaremeName extends FieldPath<TFieldValues>,
  TAgeDernierArreragename extends FieldPath<TFieldValues>,
  TIsLastArrerageViagerName extends FieldPath<TFieldValues>,
>({
  control,
  baremes,
  sexe,
  baremeName,
  ageDernierArrerageName,
  isLastArrerageViagerName,
  ageDateAttribution,
}: Props<
  TFieldValues,
  TBaremeName,
  TAgeDernierArreragename,
  TIsLastArrerageViagerName
>) => {
  const { t } = useTranslation();
  return (
    <ComputedPropsForm
      control={control}
      customValues={[ageDateAttribution]}
      watchFields={[baremeName]}
      compute={([baremeCapitalisation], customValues) => {
        const selectedBareme: Bareme | undefined = baremes.find(
          (bareme) => bareme._id === baremeCapitalisation,
        );
        const observedAgeDateAttribution = customValues?.[0];
        const agesLastArrerage:
          | { ages: number[]; viager: boolean }
          | undefined =
          observedAgeDateAttribution || observedAgeDateAttribution === 0
            ? getAgesLastArrerage(
                (selectedBareme?.values || []) as BaremeCapitalisationValue[],
                sexe,
                observedAgeDateAttribution,
              )
            : undefined;
        return {
          props: {
            agesLastArrerage: agesLastArrerage?.ages,
            displayViager: agesLastArrerage?.viager,
          },
        };
      }}
      render={({ agesLastArrerage, displayViager }) => (
        <Stack
          direction="column"
          justifyContent="end"
          alignItems="end"
          spacing={2}
        >
          <ComputedPropsForm
            control={control}
            customValues={[agesLastArrerage]}
            watchFields={[isLastArrerageViagerName]}
            compute={([isLastArrerageViager], customValues) => ({
              props: {
                disabled: isLastArrerageViager === true,
                ageDernierArrerage: customValues?.[0],
              },
            })}
            render={({ disabled, ageDernierArrerage }) => (
              <SelectFieldForm
                control={control}
                name={ageDernierArrerageName}
                disabled={disabled}
                sx={{ minWidth: 268 }}
                allowNoSelection
                noSelectionText={
                  t(
                    'prejudice.capitalisation.form.fields.ageDernierArrerage.noSelectionText',
                  ) || ''
                }
                options={(ageDernierArrerage as number[] | undefined)?.map(
                  (age) => ({
                    label: t(
                      'prejudice.capitalisation.form.fields.ageDernierArrerage.age',
                      { age },
                    ),
                    value: age,
                  }),
                )}
              />
            )}
          />
          {displayViager && (
            <FormControlLabel
              label={
                t(
                  'prejudice.capitalisation.form.fields.isLastArrerageViager.label',
                )?.toString() || ''
              }
              control={
                <CheckboxFormField
                  control={control}
                  name={isLastArrerageViagerName}
                />
              }
            />
          )}
        </Stack>
      )}
    />
  );
};
