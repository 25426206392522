import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { ConfirmationDialog } from 'src/components/basic/ConfirmationDialog';
import { OrganizationInvitationMenu } from 'src/components/organization/OrganizationInvitationMenu';
import { DataGrid, GridColDef, enUS, frFR } from '@mui/x-data-grid';
import { OrganizationInvitation } from 'src/types/organizationInvitation.type';
import { fDate } from 'src/helpers/formatTime';
import { isBefore } from 'date-fns';
import { useInvitationsToOrganization } from 'src/hooks/store/organizationInvitation.hooks';
import { UpdateOrganizationInvitationDialog } from './UpdateOrganizationInvitationDialog';
import {
  deleteOrganizationInvitation,
  fetchAllOrganizationInvitations,
  resendOrganizationInvitation,
} from 'src/slices/organizationInvitation';
import { useSnackbar } from 'notistack';
type GridRowType = OrganizationInvitation;
export const OrganizationInvitations: React.FC = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [updateInvitationDialog, setUpdateInvitationDialog] =
    useState<OrganizationInvitation | null>(null);
  const [
    deleteInvitationConfirmationDialog,
    setDeleteInvitationConfirmationDialog,
  ] = useState<{
    open: boolean;
    invitationToDelete: OrganizationInvitation | null;
  }>({ open: false, invitationToDelete: null });

  const { organization } = useDisplayedOrganization();
  useEffect(() => {
    if (organization?._id) {
      dispatch(fetchAllOrganizationInvitations(organization?._id));
    }
  }, [organization?._id]);
  const invitations = useInvitationsToOrganization();

  const onClickUpdateInvitation = (invitation: OrganizationInvitation) => {
    setUpdateInvitationDialog(invitation);
  };

  const onClickDeleteInvitation = (invitation: OrganizationInvitation) => {
    setDeleteInvitationConfirmationDialog({
      open: true,
      invitationToDelete: invitation,
    });
  };

  const onCloseDeleteInvitationDialog = () => {
    setDeleteInvitationConfirmationDialog({
      open: false,
      invitationToDelete: null,
    });
  };

  const onConfirmDeleteInvitation = () => {
    if (
      deleteInvitationConfirmationDialog.invitationToDelete?._id &&
      organization?._id
    ) {
      dispatch(
        deleteOrganizationInvitation({
          organizationId: organization._id,
          organizationInvitationId:
            deleteInvitationConfirmationDialog.invitationToDelete._id,
        }),
      );
      setDeleteInvitationConfirmationDialog({
        open: false,
        invitationToDelete: null,
      });
    }
  };

  const onClickResendInvitation = (invitationId: string) => {
    if (organization?._id) {
      dispatch(
        resendOrganizationInvitation({
          organizationId: organization?._id,
          organizationInvitationId: invitationId,
        }),
      );
      enqueueSnackbar(
        t('pages.OrganizationConfiguration.invitations.resendSuccess'),
        {
          variant: 'success',
          autoHideDuration: 1000,
        },
      );
    }
  };
  const columns: GridColDef<GridRowType>[] = [
    {
      field: 'email',
      headerName: t(
        'pages.OrganizationConfiguration.invitations.table.email.columnHeader',
      ),
      flex: 1,
    },
    {
      field: 'role',
      headerName: t(
        'pages.OrganizationConfiguration.invitations.table.role.columnHeader',
      ),
      flex: 1,
      renderCell: (params) =>
        t(`organization.fields.role.options.${params.row.role}.label`),
    },
    {
      field: 'status',
      headerName: t(
        'pages.OrganizationConfiguration.invitations.table.status.columnHeader',
      ),
      renderCell: (params) =>
        t(`organizationInvitation.fields.status.options.${params.row.status}`),
      flex: 1,
    },
    {
      field: 'expirationDate',
      headerName: t(
        'pages.OrganizationConfiguration.invitations.table.expirationDate.columnHeader',
      ),
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={
            isBefore(new Date(params.row.expirationDate), new Date())
              ? 'error'
              : 'initial'
          }
        >
          {fDate(params.row.expirationDate)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      renderCell: (params) => (
        <OrganizationInvitationMenu
          invitation={params.row as OrganizationInvitation}
          onClickUpdateInvitation={onClickUpdateInvitation}
          onClickDeleteInvitation={onClickDeleteInvitation}
          onClickResendInvitation={onClickResendInvitation}
        />
      ),
    },
  ];
  const rows: GridRowType[] = invitations.map((invitation) => ({
    id: invitation._id,
    ...invitation,
  }));
  return (
    <>
      <DataGrid
        sx={{
          cursor: 'default',
          boxShadow:
            'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, \
           &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
        }}
        localeText={
          i18n.language === 'en'
            ? enUS.components.MuiDataGrid.defaultProps.localeText
            : frFR.components.MuiDataGrid.defaultProps.localeText
        }
        loading={false}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        pageSizeOptions={[10, 20, 50]}
      />
      {organization ? (
        <UpdateOrganizationInvitationDialog
          open={updateInvitationDialog !== null}
          onClose={() => setUpdateInvitationDialog(null)}
          invitation={updateInvitationDialog}
          organizationId={organization._id}
        />
      ) : null}
      <ConfirmationDialog
        open={deleteInvitationConfirmationDialog.open}
        onCancel={onCloseDeleteInvitationDialog}
        onConfirm={onConfirmDeleteInvitation}
        title={t(
          'pages.OrganizationConfiguration.deleteInvitationConfirmation.title',
          {
            memberEmail:
              deleteInvitationConfirmationDialog.invitationToDelete?.email,
            organizationName: organization?.name,
          },
        )}
      />
    </>
  );
};
