import { configureStore, createAction, PreloadedState } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slices/auth';
import victimeReduce from '../slices/victime';
import procedureReducer from '../slices/procedure';
import dommageReducer from '../slices/dommage';
import prejudiceReducer from '../slices/prejudice';
import monetaryErosionReducer from '../slices/monetaryErosion';
import victimeIndirecteReducer from '../slices/victimeIndirecte';
import baremeReducer from '../slices/bareme';
import invitationReducer from '../slices/invitation';
import constantReducer from '../slices/constant';
import subscriptionReducer from '../slices/subscription';
import organizationReducer from '../slices/organization';
import organizationInvitationReducer from '../slices/organizationInvitation';

const RESET_STATE_ACTION = 'RESET_STATE';
export const resetStore = createAction(RESET_STATE_ACTION);

/* Reducers */
export const combinedReducer = combineReducers({
  auth: authReducer,
  victime: victimeReduce,
  procedure: procedureReducer,
  dommage: dommageReducer,
  prejudice: prejudiceReducer,
  monetaryErosion: monetaryErosionReducer,
  victimeIndirecte: victimeIndirecteReducer,
  bareme: baremeReducer,
  invitation: invitationReducer,
  constant: constantReducer,
  subscription: subscriptionReducer,
  organization: organizationReducer,
  organizationInvitation: organizationInvitationReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STATE_ACTION) {
    state = {};
  }
  return combinedReducer(state, action);
};

export const store = configureStore({ reducer: rootReducer });
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    },
  });
}

/* Infer the `RootState` and `AppDispatch` types from the store itself */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
