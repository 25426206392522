import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { prejudicesData } from 'src/constants/prejudices';
import { fCurrency } from 'src/helpers/formatNumber';
import { fDate } from 'src/helpers/formatTime';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Prejudice, PrejudiceFormMateriel } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import {
  getMontantRevalorisePrint,
  getQuotientRevalorisationPrint,
} from '../revalorisationPrint';
import { prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { sortBy } from 'lodash';

export const calendrierMaterielPrint = ({
  formData,
  monetaryErosions,
  prejudice,
  procedure,
}: {
  formData: PrejudiceFormMateriel;
  monetaryErosions: MonetaryErosion[];
  prejudice: Pick<Prejudice, 'formData' | 'formType' | 'type'>;
  procedure: Procedure;
}): (Paragraph | Table | TableOfContents)[] => {
  const partResponsabilite =
    formData.partResponsabilite || formData.partResponsabilite === 0
      ? formData.partResponsabilite
      : procedure.partResponsabilite;
  const table = prejudiceFormTable(
    sortBy(formData.rows, (row) => row.date),
    [
      {
        name: 'numerosPieces',
        headerLabel: i18next.t('numeroPiece.form.columnHeader'),
        render: (row) =>
          row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
      },
      {
        name: 'intitule',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.MATERIEL.fields.rows.intitule.columnHeader',
        ),
      },
      {
        name: 'date',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.MATERIEL.fields.rows.date.columnHeader',
        ),
        render: (row) => (row.date ? fDate(row.date) : ''),
      },
      {
        name: 'montant',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.MATERIEL.fields.rows.montant.columnHeader',
        ),
        render: (row) => fCurrency(row.montant),
      },
      {
        name: 'montant',
        headerLabel: i18next.t(
          'prejudice.revalorisation.quotient.columnHeader',
        ),
        render: (row) =>
          row.montantsDejaRevalorises
            ? ''
            : getQuotientRevalorisationPrint({
                anneeOrDateLiquidation: procedure.dateLiquidation || undefined,
                monetaryErosions,
                anneeOrDateMontant: row.date || undefined,
                coefficientsType: formData.revalorisationCoefficientsType,
              }),
      },
      {
        name: 'montant',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.MATERIEL.fields.rows.montant.print.revaloriseColumnHeader',
        ),
        render: (row) =>
          row.montantsDejaRevalorises
            ? ''
            : getMontantRevalorisePrint({
                montant: row.montant,
                anneeOrDateLiquidation: procedure.dateLiquidation || undefined,
                monetaryErosions,
                anneeOrDateMontant: row.date || undefined,
                montantsDejaRevalorises: row.montantsDejaRevalorises ?? true,
                coefficientsType: formData.revalorisationCoefficientsType,
              }),
      },
    ],
  );
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const displayTotalPartResponsabilite =
    prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
      .displayTotalPartResponsabilite;
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.MATERIEL.fields.partResponsabilite.print.value',
        {
          value: fPartResponsabilite(partResponsabilite * 100),
        },
      ),
    ),
    getEmptyLine(),
    ...(table ? table : []),
    getParagraph(i18next.t('prejudice.revalorisation.print.footnote') || ''),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: displayTotalPartResponsabilite
          ? i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.total.print.partResponsabiliteValue',
              {
                partResponsabilite: fPartResponsabilite(
                  partResponsabilite * 100,
                ),
                value: fCurrency(formData.total * partResponsabilite),
              },
            )
          : i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.total.print.value',
              {
                value: fCurrency(formData.total),
              },
            ),
      }),
    }),
  ];
};
