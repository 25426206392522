import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityId,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import {
  CreateOrganizationDto,
  UpdateOrganizationDto,
  Organization,
  UpdateMemberRoleDto,
} from 'src/types/organization.type';
import { apiRequest } from '../helpers/api';
import {
  Subscription,
  UpdateSubscriptionDto,
} from 'src/types/subscription.type';

/* Thunks */
export const fetchAllOrganizations = createAsyncThunk(
  'organizations/fetchAll',
  async () => {
    return await apiRequest<Organization[]>('GET', '/organization');
  },
);

export const fetchOrganization = createAsyncThunk(
  'organizations/fetch',
  async (organizationId: string) => {
    return await apiRequest<Organization>(
      'GET',
      `/organization/${organizationId}`,
    );
  },
);

export const createOrganization = createAsyncThunk(
  'organizations/create',
  async (payload: { data: CreateOrganizationDto }) => {
    const { data } = payload;

    return await apiRequest<Organization>(
      'POST',
      '/organization',
      undefined,
      data,
    );
  },
);

export const updateOrganization = createAsyncThunk(
  'organizations/update',
  async (payload: { organizationId: string; data: UpdateOrganizationDto }) => {
    const { organizationId, data } = payload;

    return await apiRequest<Organization>(
      'PATCH',
      `/organization/${organizationId}`,
      undefined,
      data,
    );
  },
);

export const deleteOrganization = createAsyncThunk(
  'organizations/delete',
  async (payload: { organizationId: string }) => {
    const { organizationId } = payload;

    return await apiRequest<{ id: string }>(
      'DELETE',
      `/organization/${organizationId}`,
    );
  },
);

export const fetchOrganizationsForNormaAdmin = createAsyncThunk(
  'organizations/fetchForNormaAdmin',
  async () => {
    return await apiRequest<Organization[]>('GET', '/organization/all');
  },
);

export const updateOrganizationSubscription = createAsyncThunk(
  'organizations/updateSubscription',
  async ({
    organizationId,
    dto,
  }: {
    organizationId: string;
    dto: UpdateSubscriptionDto;
  }) => {
    return await apiRequest<Organization>(
      'PATCH',
      `/organization/${organizationId}/subscription`,
      undefined,
      dto,
    );
  },
);

export const cancelOrganizationSubscriptionAsAdmin = createAsyncThunk(
  'organizations/cancelSubscriptionAsAdmin',
  async (organizationId: string) => {
    return await apiRequest<Organization>(
      'DELETE',
      `/organization/${organizationId}/subscription`,
    );
  },
);

export const updateOrganizationMemberRole = createAsyncThunk(
  'organizations/updateMemberRole',
  async ({
    organizationId,
    memberId,
    dto,
  }: {
    organizationId: string;
    memberId: string;
    dto: UpdateMemberRoleDto;
  }) => {
    return await apiRequest<Organization>(
      'PATCH',
      `/organization/${organizationId}/members/${memberId}/role`,
      undefined,
      dto,
    );
  },
);

export const removeOrganizationMember = createAsyncThunk(
  'organizations/removeMember',
  async (payload: { organizationId: string; memberId: string }) => {
    const { organizationId, memberId } = payload;

    return await apiRequest<Organization>(
      'DELETE',
      `/organization/${organizationId}/members/${memberId}`,
    );
  },
);

export const fetchSubscriptionByOrganizationId = createAsyncThunk(
  'subscriptions/fetchByOrganizationId',
  async (organizationId: string) => {
    return await apiRequest<Subscription>(
      'GET',
      `/organization/${organizationId}/subscription`,
    );
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
  upsertOne: (state: any, action: PayloadAction<Organization>) => {
    state.isLoading = false;
    organizationsAdapter.upsertOne(state, action.payload);
  },
};

/* Adapter */
export const organizationsAdapter = createEntityAdapter({
  selectId: (organization: Organization) => organization._id,
});

/* Slice */
const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: organizationsAdapter.getInitialState<{
    isLoading: boolean;
    isInitialLoading: boolean;
    error: string | null | undefined;
    organizationsForNormaAdmin: {
      data: Organization[];
      isLoading: boolean;
      error: string | null | undefined;
    };
  }>({
    isLoading: false,
    isInitialLoading: true,
    error: undefined,
    organizationsForNormaAdmin: {
      data: [],
      isLoading: false,
      error: undefined,
    },
  }),
  reducers: {
    setOrganization: (state, action: PayloadAction<Organization>) => {
      organizationsAdapter.upsertOne(state, action.payload);
    },
    removeOne: (state, action: PayloadAction<EntityId>) => {
      organizationsAdapter.removeOne(state, action.payload);
    },
    updateSubscriptionInOrganizationSlice: (
      state,
      action: PayloadAction<{
        organizationId: string;
        subscription: Subscription;
      }>,
    ) => {
      organizationsAdapter.updateOne(state, {
        id: action.payload.organizationId,
        changes: {
          subscription: action.payload.subscription,
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrganizations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllOrganizations.rejected, (state, { error }) => {
        state.isLoading = false;
        state.isInitialLoading = false;
        state.error = error.message || 'error';
      })
      .addCase(fetchAllOrganizations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isInitialLoading = false;
        organizationsAdapter.setAll(state, payload);
      })
      .addCase(createOrganization.pending, sharedReducers.pending)
      .addCase(createOrganization.rejected, sharedReducers.rejected)
      .addCase(createOrganization.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        organizationsAdapter.upsertOne(state, payload);
      })
      .addCase(updateOrganization.pending, sharedReducers.pending)
      .addCase(updateOrganization.rejected, sharedReducers.rejected)
      .addCase(updateOrganization.fulfilled, sharedReducers.upsertOne)
      .addCase(deleteOrganization.pending, sharedReducers.pending)
      .addCase(deleteOrganization.rejected, sharedReducers.rejected)
      .addCase(deleteOrganization.fulfilled, (state, { meta }) => {
        state.isLoading = false;
        organizationsAdapter.removeOne(state, meta.arg.organizationId);
      })
      .addCase(fetchOrganization.pending, sharedReducers.pending)
      .addCase(fetchOrganization.rejected, sharedReducers.rejected)
      .addCase(fetchOrganization.fulfilled, sharedReducers.upsertOne)
      .addCase(fetchOrganizationsForNormaAdmin.pending, (state) => {
        state.organizationsForNormaAdmin.isLoading = true;
        state.organizationsForNormaAdmin.error = null;
      })
      .addCase(fetchOrganizationsForNormaAdmin.rejected, (state, { error }) => {
        state.organizationsForNormaAdmin.isLoading = false;
        state.organizationsForNormaAdmin.error = error.message || 'error';
      })
      .addCase(
        fetchOrganizationsForNormaAdmin.fulfilled,
        (state, { payload }) => {
          state.organizationsForNormaAdmin.isLoading = false;
          state.organizationsForNormaAdmin.data = payload;
        },
      )
      .addCase(updateOrganizationSubscription.pending, sharedReducers.pending)
      .addCase(updateOrganizationSubscription.rejected, sharedReducers.rejected)
      .addCase(
        updateOrganizationSubscription.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          state.organizationsForNormaAdmin.data =
            state.organizationsForNormaAdmin.data.map((organization) =>
              organization._id === payload._id ? payload : organization,
            );
        },
      )
      .addCase(
        cancelOrganizationSubscriptionAsAdmin.pending,
        sharedReducers.pending,
      )
      .addCase(
        cancelOrganizationSubscriptionAsAdmin.rejected,
        sharedReducers.rejected,
      )
      .addCase(
        cancelOrganizationSubscriptionAsAdmin.fulfilled,
        sharedReducers.upsertOne,
      )
      .addCase(updateOrganizationMemberRole.pending, sharedReducers.pending)
      .addCase(updateOrganizationMemberRole.rejected, sharedReducers.rejected)
      .addCase(updateOrganizationMemberRole.fulfilled, sharedReducers.upsertOne)
      .addCase(removeOrganizationMember.pending, sharedReducers.pending)
      .addCase(removeOrganizationMember.rejected, sharedReducers.rejected)
      .addCase(removeOrganizationMember.fulfilled, sharedReducers.upsertOne)
      .addCase(
        fetchSubscriptionByOrganizationId.pending,
        sharedReducers.pending,
      )
      .addCase(
        fetchSubscriptionByOrganizationId.rejected,
        sharedReducers.rejected,
      )
      .addCase(
        fetchSubscriptionByOrganizationId.fulfilled,
        (state, { payload, meta }) => {
          state.isLoading = false;
          organizationsAdapter.updateOne(state, {
            id: meta.arg,
            changes: {
              subscription: payload,
            },
          });
        },
      );
  },
});

export const {
  setOrganization,
  removeOne,
  updateSubscriptionInOrganizationSlice,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
