import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency, fDecimalNumber, fPercent } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { prejudiceFormTable } from 'src/helpers/print/prejudicesPrints/prejudiceFormTablePrint';
import {
  ListeProjectionFonctionnelPermanentRow,
  PrejudiceFormListeProjection,
} from 'src/types/prejudice.type';

export const deficitFonctionnelPermanentPrint = ({
  formData,
}: {
  formData: PrejudiceFormListeProjection;
}): (Table | TableOfContents | Paragraph)[] => {
  return [
    ...prejudiceFormTable(
      (formData.prejudiceValues?.rows ||
        []) as ListeProjectionFonctionnelPermanentRow[],
      [
        {
          name: 'numerosPieces',
          headerLabel: i18next.t('numeroPiece.form.columnHeader'),
          render: (row) =>
            row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
        },
        {
          name: 'commentaires',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.commentaires.print.columnHeader',
          ),
        },
        {
          name: 'pourcentage',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.pourcentage.columnHeader',
          ),
          render: (row) => fPercent(row.pourcentage),
        },
        {
          name: 'valeurPoint',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.valeurPoint.print.columnHeader',
          ),
          render: (row) =>
            row.valeurPoint !== undefined
              ? fDecimalNumber(row.valeurPoint, 2, true)
              : '',
        },
        {
          name: 'montant',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.montant.print.columnHeader',
          ),
          render: (row) => fCurrency(row.montant),
        },
      ],
    ),
  ];
};
