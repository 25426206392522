import { canCreateVictime, canUpdateVictime } from 'src/helpers/victime';
import { useAuthenticatedUser } from './auth.hooks';
import { useDisplayedOrganization } from './organization.hooks';
import { Victime } from 'src/types/victime.type';

export const useCanUpdateVictime = (victime: Victime): boolean => {
  const user = useAuthenticatedUser();
  const { organization } = useDisplayedOrganization();
  if (!user) {
    return false;
  }
  return canUpdateVictime(user, victime, organization ?? undefined);
};
export const useCanCreateVictime = (): boolean => {
  const user = useAuthenticatedUser();
  const { organization } = useDisplayedOrganization();
  if (!user) {
    return false;
  }
  return canCreateVictime(user, organization ?? undefined);
};
