import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  NumberingType,
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
  PerteGainProfessionnelsFutursSituation,
} from 'src/types/prejudice.type';
import { revenusTheoriqueWrittenPrint } from './steps/revenuTheorique.written.print';
import { revenuReelWrittenPrint } from './steps/revenuReel.written.print';
import { revenusIndemnitesWrittenPrint } from './steps/revenuIndemnites.written.print';
import { Victime } from 'src/types/victime.type';

export const situationWrittenPrint = ({
  situation,
  monetaryErosions,
  dateLiquidation,
  isPGPF,
  victime,
  index,
  ...formData
}: OldPrejudiceFormPerteGainProfessionnelsActuel & {
  situation:
    | PerteGainProfessionnelsActuelSituation
    | PerteGainProfessionnelsFutursSituation;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation: Date | undefined;
  isPGPF?: boolean;
  victime: Victime;
  index: number;
}): (Table | Paragraph | TableOfContents)[] => {
  return [
    ...(situation.revenuDeReference ||
    situation.anneesRevenusTheoriques.filter(
      (annee) => annee.netFiscal || annee.netPaye,
    ).length
      ? [
          getParagraph({
            numbering: {
              reference: NumberingType.ROMAN_NUMERALS,
              level: 1,
            },
            children: getTextRun({
              text: i18next.t(
                'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenuTheorique.title',
              ),
            }),
          }),
          getEmptyLine(),
          ...revenusTheoriqueWrittenPrint({
            revenuDeReference: situation.revenuDeReference,
            anneesRevenusTheoriques: situation.anneesRevenusTheoriques,
            monetaryErosions,
            dateLiquidation,
            victime,
            formData,
            situationIndex: index,
            revenuTheorique: situation.revenuTheorique,
          }),
        ]
      : []),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: NumberingType.ROMAN_NUMERALS,
        level: 1,
      },
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.revenusReelsNet.title',
        ),
      }),
    }),
    getEmptyLine(),
    ...revenuReelWrittenPrint({
      monetaryErosions,
      dateLiquidation: dateLiquidation,
      formData,
      ...situation,
      victime,
      situationIndex: index,
    }),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: NumberingType.ROMAN_NUMERALS,
        level: 1,
      },
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.tiersPayeursIndemnites.title',
        ),
      }),
    }),
    getEmptyLine(),
    ...revenusIndemnitesWrittenPrint({
      revenusIndemnitesTiersPayeurs: situation.tiersPayeursIndemnites,
      totalIndemnitesCsgCrds: situation.totalIndemnitesCsgCrds,
      totalIndemnitesNet: situation.totalIndemnitesNet,
      isPGPF,
      formData,
      situationIndex: index,
    }),
  ];
};
