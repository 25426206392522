import i18next from 'i18next';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';

export const displayVictimeNameWrittenPrint = (
  victime: Victime | VictimeIndirecte,
) => {
  switch (true) {
    case !!victime.nom && !!victime.prenom && !!victime.sexe:
      return i18next.t('print.victime.titleAndNameWithoutParentheses', {
        firstName: victime.prenom,
        lastName: victime.nom,
        context: victime.sexe,
      });
    case !!victime.nom && !!victime.prenom:
      return i18next.t('print.victime.name', {
        firstName: victime.prenom,
        lastName: victime.nom,
      });
    case !!victime.nom && !!victime.sexe:
      return i18next.t('print.victime.titleAndLastName', {
        lastName: victime.nom,
        context: victime.sexe,
      });
    default:
      return i18next.t('print.victime.noNameAndNoReferenceDossierVictime');
  }
};
