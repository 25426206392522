import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import * as yup from 'yup';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Flex } from './Flex';
import { Edit, ManageAccounts } from '@mui/icons-material';
import { TextFieldForm } from '../forms/TextFieldForm';
import { User, UserJobType } from 'src/types/auth.type';
import { SelectFieldForm } from '../forms/SelectFieldForm';

const useStyles = createUseStyles({
  profileContainer: {
    color: 'rgb(97, 97, 97)',
    padding: theme.spacing(3),
    backgroundColor: 'rgb(250, 250, 250)',
  },
  profileHeader: {
    '& .MuiCardHeader-action': {
      alignSelf: 'center',
    },
  },
});

interface UserCompanyRoleForm {
  company: string;
  role: string;
  jobType?: UserJobType;
}
interface Props {
  user: User;
  updateRole: (userId: string, isAdmin: boolean) => void;
  adminDeleteAccount: (userId: string) => void;
  updateCompanyRole: (userId: string, data: UserCompanyRoleForm) => void;
  activateAccount: (userId: string) => void;
  deactivateAccount: (userId: string) => void;
}

const ProfileCard: React.FC<Props> = ({
  user,
  updateRole,
  adminDeleteAccount,
  updateCompanyRole,
  activateAccount,
  deactivateAccount,
}) => {
  const validationSchema = yup.object({
    company: yup.string(),
    role: yup.string(),
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const [editCompanyRole, setEditCompanyRole] = useState<boolean>(false);
  const { control, handleSubmit } = useForm<UserCompanyRoleForm>({
    defaultValues: {
      company: user.company,
      jobType: user.jobType,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    return () => {
      resetEdit();
    };
  }, []);

  const resetEdit = async () => {
    setEditCompanyRole(false);
  };

  const onSubmitCompanyRole = async (data: UserCompanyRoleForm) => {
    updateCompanyRole(user._id, data);
    resetEdit();
  };

  const userRegisterDate = new Date(user.createdAt ?? Date.now());
  const userLastConnectionDate = user.stats.lastConnection
    ? new Date(user.stats.lastConnection)
    : userRegisterDate;
  const displayEditCompanyRole = () => (
    <form
      style={{ marginTop: theme.spacing(3) }}
      onSubmit={handleSubmit(onSubmitCompanyRole, console.log)}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ margin: theme.spacing(1, 0, 0.5, 0) }}
        spacing={2}
      >
        <TextFieldForm
          label={t('common.company')}
          name="company"
          control={control}
        />
        <SelectFieldForm
          label={t('user.jobType.label')}
          name="jobType"
          control={control}
          options={Object.values(UserJobType).map((jobType) => ({
            label: t(`user.jobType.options.${jobType}`),
            value: jobType,
          }))}
          sx={{ minWidth: 300 }}
        />
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ margin: theme.spacing(2, 0, 0.5, 0) }}
        spacing={2}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => resetEdit()}
          fullWidth
        >
          {t('common.cancel')}
        </Button>
        <Button variant="contained" type="submit" color="primary" fullWidth>
          {t('common.save')}
        </Button>
      </Stack>
    </form>
  );

  return (
    <Card className={classes.profileContainer}>
      <CardHeader
        className={classes.profileHeader}
        title={<div>{user.firstName + ' ' + user.lastName}</div>}
        subheader={<div>{user.email}</div>}
        action={
          user.isAdmin ? (
            <>
              <AdminPanelSettingsIcon color="primary" />
            </>
          ) : (
            <></>
          )
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('common.phone') + ' : '}
              {user.phoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{t('pages.Admin.company')}</Typography>
            {editCompanyRole ? (
              displayEditCompanyRole()
            ) : (
              <Flex style={{ alignItems: 'center' }}>
                <div>
                  <Typography variant="body1">{user.company ?? ''}</Typography>
                  <Typography variant="body2" color="#637381" component="div">
                    {user.jobType
                      ? t(`user.jobType.options.${user.jobType}`)
                      : ''}
                  </Typography>
                </div>
                <IconButton
                  sx={{ marginLeft: user.company || user.jobType ? 3 : 0 }}
                  onClick={() => setEditCompanyRole(!editCompanyRole)}
                >
                  <Edit />
                </IconButton>
              </Flex>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography component="div" variant="h5">
              {t('pages.Admin.userInfo')}
            </Typography>
            <div>
              {"Date d'inscription : " +
                userRegisterDate.toLocaleDateString() +
                '-' +
                userRegisterDate.toLocaleTimeString()}
            </div>
            <div>
              {'Dernière connexion : ' +
                userLastConnectionDate.toLocaleDateString() +
                '-' +
                userLastConnectionDate.toLocaleTimeString()}
            </div>
            <div>
              {'Nombre de connexion : ' +
                (user.stats?.numberOfConnections ?? 0)}
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{ justifyContent: 'space-between', alignItems: 'stretch' }}
      >
        {user.disabled ? (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ManageAccounts />}
            onClick={() => activateAccount(user._id)}
          >
            {t('pages.Admin.activateAccount')}
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="error"
            startIcon={<ManageAccounts />}
            onClick={() => deactivateAccount(user._id)}
          >
            {t('pages.Admin.deactivateAccount')}
          </Button>
        )}
        {!user.isAdmin ? (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AdminPanelSettingsIcon />}
            onClick={() => updateRole(user._id, true)}
          >
            {t('pages.Admin.addAdmin')}
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="error"
            startIcon={<AdminPanelSettingsIcon />}
            onClick={() => updateRole(user._id, false)}
          >
            {t('pages.Admin.removeAdmin')}
          </Button>
        )}
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteForeverIcon />}
          onClick={() => adminDeleteAccount(user._id)}
        >
          {t('common.delete')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProfileCard;
