import { format, formatDistanceToNow } from 'date-fns';
import { fr as frLocale } from 'date-fns/locale';
import i18next from 'i18next';
import { AgeOrZero } from 'src/constants/calculs/type';
import { fDecimalNumber } from './formatNumber';

// ----------------------------------------------------------------------

export function fDate(date: Date | string) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateLong(date: Date | string) {
  return format(new Date(date), 'dd MMMM yyyy', {
    locale: frLocale,
  });
}

export function fDateTime(date: Date | string) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fDateTimeSuffix(date: Date | string) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function nowWithoutTime(): Date {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);

  return date;
}

export function dateWithoutTime(date: Date | string) {
  return new Date(new Date(date).setUTCHours(0, 0, 0, 0));
}

export function fMonth(month: number) {
  return format(new Date(2023, month), 'LLLL', { locale: frLocale });
}

export function fYear(year: number) {
  return format(new Date(year, 0), 'yyyy');
}

export function fMonthAndYear(
  date: Date | string | { month: number; year: number },
) {
  const monthAndYearDate =
    typeof date === 'string'
      ? new Date(date)
      : 'month' in date
        ? new Date(date.year, date.month)
        : new Date(date);
  return format(monthAndYearDate, 'MM/yyyy', { locale: frLocale });
}

export function fAge(age: AgeOrZero | number) {
  if (typeof age !== 'number' && !age) {
    return '';
  }
  return i18next.t('forms.fields.age.formattedValue.value', {
    count: typeof age === 'number' ? age : age.age,
    formattedCount: fDecimalNumber(typeof age === 'number' ? age : age.age, 2),
    context:
      typeof age === 'number'
        ? age === 0
        : age.isExactlyZero
          ? 'exactly_zero'
          : undefined,
  });
}

export function fYears(years: number) {
  // TODO : handle less than 1 and exactly 0
  return i18next.t('forms.fields.year.formattedValue.value', {
    count: years,
    formattedCount: fDecimalNumber(years, 2),
  });
}

export const fDays = (days: number) =>
  i18next.t('forms.fields.day.value', {
    count: days,
    formattedCount: fDecimalNumber(days, 2),
  });

export const fWeeks = (weeks: number) =>
  i18next.t('forms.fields.week.value', {
    count: weeks,
    formattedCount: fDecimalNumber(weeks, 2),
  });

export const fHours = (hours: number) =>
  i18next.t('forms.fields.hour.value', {
    count: hours,
    formattedCount: fDecimalNumber(hours, 2),
  });

export const MAX_TIMESTAMP = 4070905200000;
export const MIN_TIMESTAMP = -2208989361000;
