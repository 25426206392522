import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { SignIn } from '../pages/SignIn';
import { SignUp } from '../pages/SignUp';
import { LostPassword } from '../pages/LostPassword';
import { SentryRoutes } from './sentry';

export const GuestSwitch: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<SignIn />} />
      <Route path="/signIn" element={<SignIn />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/lostPassword" element={<LostPassword />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );
};
