import { Checkbox, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { conditionsLink } from 'src/constants/global';

interface Props {
  checked: boolean;
  onClickCheck: () => void;
}

export const AcceptConditions: React.FC<Props> = ({
  checked,
  onClickCheck,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2} alignItems="center" marginBottom={4}>
      <Checkbox checked={checked} onChange={onClickCheck} />
      <Typography sx={{ color: 'text.secondary' }}>
        {t('pages.SignUp.acceptTerms')}
        {/* TODO put in variable */}
        <a href={conditionsLink} target="_blank" rel="noreferrer">
          {t('pages.SignUp.acceptTermsLink')}
        </a>
      </Typography>
    </Stack>
  );
};
