import { RootState } from './index';
import { dommageAdapter } from '../slices/dommage';
import { victimeAdapter } from '../slices/victime';
import { procedureAdapter } from '../slices/procedure';
import { prejudiceAdapter } from '../slices/prejudice';
import { monetaryErosionAdapter } from '../slices/monetaryErosion';
import { victimeIndirecteAdapter } from '../slices/victimeIndirecte';
import { baremesAdapter } from 'src/slices/bareme';
import { invitationsAdapter } from 'src/slices/invitation';
import { constantsAdapter } from 'src/slices/constant';
import { organizationsAdapter } from 'src/slices/organization';
import { organizationInvitationsAdapter } from 'src/slices/organizationInvitation';

export const dommageSelectors = dommageAdapter.getSelectors<RootState>(
  (state) => state.dommage,
);

export const victimeSelectors = victimeAdapter.getSelectors<RootState>(
  (state) => state.victime,
);

export const procedureSelectors = procedureAdapter.getSelectors<RootState>(
  (state) => state.procedure,
);

export const prejudiceSelectors = prejudiceAdapter.getSelectors<RootState>(
  (state) => state.prejudice,
);

export const monetaryErosionSelectors =
  monetaryErosionAdapter.getSelectors<RootState>(
    (state) => state.monetaryErosion,
  );

export const baremeSelectors = baremesAdapter.getSelectors<RootState>(
  (state) => state.bareme,
);

export const victimeIndirecteSelectors =
  victimeIndirecteAdapter.getSelectors<RootState>(
    (state) => state.victimeIndirecte,
  );

export const invitationSelectors = invitationsAdapter.getSelectors<RootState>(
  (state) => state.invitation,
);

export const appConstantsSelector = constantsAdapter.getSelectors<RootState>(
  (state) => state.constant,
);

export const subscriptionSelector = (state: RootState) => state.subscription;

export const organizationSelector =
  organizationsAdapter.getSelectors<RootState>((state) => state.organization);

export const organizationInvitationSelector =
  organizationInvitationsAdapter.getSelectors<RootState>(
    (state) => state.organizationInvitation,
  );
