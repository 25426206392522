import { PrejudiceFormScolairePerteDeChanceRow } from 'src/types/prejudice.type';
import { CalculsGlobal } from './calculsGlobal';

export abstract class CalculsFormScolaire {
  static getPerteDeChance({
    montant,
    coefficientPerteDeChance,
  }: Pick<
    PrejudiceFormScolairePerteDeChanceRow,
    'montant' | 'coefficientPerteDeChance'
  >) {
    return montant * coefficientPerteDeChance;
  }
  static getTotalPerteDeChance(
    rows: Pick<
      PrejudiceFormScolairePerteDeChanceRow,
      'montant' | 'coefficientPerteDeChance'
    >[],
  ): number {
    return CalculsGlobal.sum(rows.map(this.getPerteDeChance));
  }
  static getTotal(montant: number, totalPerteDeChance: number): number {
    return montant + totalPerteDeChance;
  }
}
