export class CalculsRentes {
  static getTrimestriel(montant: number): number {
    return montant / 4;
  }
  static getMensuel(montant: number): number {
    return montant / 12;
  }
  static getAnnuel(
    indemniteVictimeAEchoir: number,
    coefficientCapitalisation: number,
  ): number | undefined {
    return coefficientCapitalisation
      ? indemniteVictimeAEchoir / coefficientCapitalisation
      : 0;
  }
}
