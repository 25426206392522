import { PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte } from 'src/types/prejudice.type';
import { fCurrency } from 'src/helpers/formatNumber';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import i18next from 'i18next';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { Paragraph, Table, TableOfContents } from 'docx';
import { CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte } from 'src/constants/calculs';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const tableRecapitulatifSaisieDirectePrint = ({
  perteDeGainsProfessionnels,
  revenuEspere,
  indemnitesJournalieresPercuesPendantLaPeriodeDArret,
  isPGPF,
  partResponsabilite,
}: Pick<
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  | 'revenuEspere'
  | 'perteDeGainsProfessionnels'
  | 'indemnitesJournalieresPercuesPendantLaPeriodeDArret'
> & {
  partResponsabilite: number;
  isPGPF: boolean;
}): (Paragraph | Table | TableOfContents)[] => {
  const difference =
    CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte.getDifference(
      perteDeGainsProfessionnels.total,
      indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet,
    );
  const displayCsgRds = !isPGPF;
  return [
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.print.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    simpleHorizontalTablePrint(
      [
        '',
        i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.revenuEspere.columnHeader',
        ),
        i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.perteDeGainsProfessionnels.columnHeader',
        ),
        i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.indemnitesJournalieresPercuesPendantLaPeriodeDArret.columnHeader',
        ),
        ...(displayCsgRds
          ? [
              i18next.t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.csgCrds.columnHeader',
              ),
            ]
          : []),
        i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.difference.columnHeader',
        ),
      ],
      [
        {
          rowLabel: i18next.t(
            'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.totalRow.rowHeader',
          ),
          columns: [
            fCurrency(perteDeGainsProfessionnels.total),
            fCurrency(revenuEspere.total),
            indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet ||
            indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet ===
              0
              ? fCurrency(
                  indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet,
                )
              : '',
            ...(displayCsgRds
              ? [
                  indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal ||
                  indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal ===
                    0
                    ? fCurrency(
                        indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal,
                      )
                    : '',
                ]
              : []),
            fCurrency(difference),
          ],
        },
        {
          rowLabel: i18next.t(
            'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.print.partResponsabiliteRow.rowHeader',
            {
              partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
            },
          ),
          columns: [
            perteDeGainsProfessionnels.total,
            revenuEspere.total,
            indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet,
            ...(displayCsgRds
              ? [
                  indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal,
                ]
              : []),
            difference,
          ].map((value) =>
            value || value === 0 ? fCurrency(value * partResponsabilite) : '',
          ),
        },
      ],
    ),
  ];
};
