import React from 'react';
import { Control } from 'react-hook-form';
import { NewPrejudiceFormPerteGainProfessionnelsActuel } from '../../../../types/prejudice.type';
import { Procedure } from '../../../../types/procedure.type';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { TotalIndemniteGlobaleARepartirEchus } from '../TotalIndemniteGlobaleARepartirEchus';
import { pick } from 'lodash';

interface Props {
  control: Control<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  procedure: Procedure;
}

export const TotalNewPerteGainsActuels: React.FC<Props> = ({
  control,
  procedure,
}) => {
  return (
    <ComputedPropsForm
      control={control}
      watchFields={[
        'perteDeGainsProfessionnels.total',
        'indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal',
        'indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet',
        'indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows',
      ]}
      compute={([
        perteGainProfessionnelsTotal,
        indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal,
        indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet,
        indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
      ]) => {
        return {
          props: {
            ...CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie(
              {
                perteGainProfessionnelsTotal,
                indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal,
                indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet,
                indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
                partResponsabilite: procedure.partResponsabilite,
              },
            ),
          },
        };
      }}
      render={(untypedIndemniteGlobaleARepartir) => {
        const indemniteGlobaleARepartir =
          untypedIndemniteGlobaleARepartir as ReturnType<
            typeof CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie
          >;
        return (
          <TotalIndemniteGlobaleARepartirEchus
            indemniteGlobaleARepartir={indemniteGlobaleARepartir}
            tropPercu={pick(indemniteGlobaleARepartir, 'tropPercu')}
            partResponsabilite={procedure.partResponsabilite}
          />
        );
      }}
    />
  );
};
