import React from 'react';
import { Stack, Tab, TabProps, Tooltip, Typography } from '@mui/material';
import { Help } from '@mui/icons-material';

interface Props {
  label: string;
  errorTooltipText?: string;
  helpTooltipText?: string;
  isError?: boolean;
  value: number;
}
export const PrejudiceTab: React.FC<Props & TabProps> = ({
  label,
  errorTooltipText = "Vous n'avez pas rempli tous les champs obligatoires",
  helpTooltipText,
  isError,
  value,
  ...props
}) => (
  <Tooltip title={isError ? errorTooltipText : ''}>
    <Tab
      {...props}
      label={
        <Stack direction="row" alignItems="center">
          {label}
          {helpTooltipText ? (
            <Tooltip
              title={
                typeof helpTooltipText === 'string' ? (
                  <Typography
                    whiteSpace="pre-line"
                    maxHeight="400px"
                    overflow="auto"
                  >
                    {helpTooltipText}
                  </Typography>
                ) : (
                  helpTooltipText
                )
              }
              placement="bottom-end"
            >
              <Stack
                padding={1}
                sx={{
                  cursor: 'pointer',
                }}
                color="text.secondary"
              >
                <Help />
              </Stack>
            </Tooltip>
          ) : null}
        </Stack>
      }
      value={value}
      sx={{
        maxWidth: '500px',
        color: isError ? 'error.main' : undefined,
      }}
    />
  </Tooltip>
);
