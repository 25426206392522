import * as React from 'react';
import Slider, { SliderProps } from '@mui/material/Slider';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { round } from 'lodash';

const getRange = (start: number, stop: number, step: number) => {
  const arr = [];
  let i = start;
  while (i <= stop) {
    arr.push(i);
    i = Math.round((i + step + Number.EPSILON) * 100) / 100;
  }
  return arr;
};

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  rangeStart?: number;
  min?: number;
  max?: number;
  displayAsPercentage?: boolean;
}

export const SliderForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  rangeStart,
  min = 0,
  max = 100,
  step = 0.05,
  displayAsPercentage,
  ...props
}: Props<TFieldValues, TName> & SliderProps) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
  });
  const getMarks = () => {
    const range = getRange(min, max, step || 0.05);
    return range.map((v) => ({
      value: v,
      label: [min, max].includes(v)
        ? displayAsPercentage
          ? `${round(v * 100)}%`
          : v.toString()
        : '',
    }));
  };

  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const marks = props.marks || getMarks();
  const valueLabelFormat = (value: number) =>
    displayAsPercentage ? <>{`${round(value * 100)}%`}</> : <>{value}</>;
  return (
    <Slider
      {...field}
      {...props}
      valueLabelDisplay={'auto'}
      valueLabelFormat={props.valueLabelFormat || valueLabelFormat}
      value={value as number | number[] | undefined}
      marks={marks}
      step={step}
      min={min}
      max={max}
      onChange={(_, value) => {
        onChange(
          (rangeStart && typeof value === 'number' && value < rangeStart) ||
            (Array.isArray(value) &&
              value?.[0] !== undefined &&
              rangeStart !== undefined &&
              value[0] < rangeStart)
            ? rangeStart
            : value,
        );
      }}
    />
  );
};
