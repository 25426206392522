import React, { useState } from 'react';
import { Control } from 'react-hook-form';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
} from '../../../../types/prejudice.type';
import { NormalTable } from '../../../styled';
import { Procedure } from '../../../../types/procedure.type';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { fCurrency } from '../../../../helpers/formatNumber';
import { TotalNewPerteGainsActuels } from './TotalNewPerteGainsActuels';
import { RenteAccidentDuTravailDialog } from './RenteAccidentDuTravailDialog';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { Edit } from '@mui/icons-material';
import { getTropPercuString } from 'src/helpers/prejudices/pgpfReliquat';
import { useTranslation } from 'react-i18next';
import { CalculsFormNewPerteGainsProfessionnelsFuturs } from 'src/constants/calculs';
import { fPartResponsabilite } from 'src/helpers/formatValues';

interface Props {
  procedure: Procedure;
  control: Control<
    | NewPrejudiceFormPerteGainProfessionnelsActuel
    | NewPrejudiceFormPerteGainProfessionnelsFuturs
  >;
  isPGPF: boolean;
  tauxIPP: number | null;
  onClickModify: () => void;
}

export const TableRecapitulatif: React.FC<Props> = ({
  control,
  procedure,
  isPGPF = false,
  tauxIPP,
  onClickModify,
}) => {
  const { t } = useTranslation();
  const [
    isRenteAccidentDuTravailModalOpen,
    setIsRenteAccidentDuTravailModalOpen,
  ] = useState(false);
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <ComputedPropsForm
            control={control}
            watchFields={[
              'revenuActiviteAnnuelDeReference.total',
              'perteDeGainsProfessionnels.total',
              'indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet',
              'indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal',
            ]}
            compute={([
              totalRevenuActiviteAnnuelDeReference,
              totalPerteDeGainsProfessionnels,
              indemnitesJournalieresPercuesNet,
              csgRdsTotal,
            ]) => {
              const total =
                CalculsFormNewPerteGainsProfessionnelsFuturs.getTotal(
                  totalPerteDeGainsProfessionnels,
                  indemnitesJournalieresPercuesNet,
                );
              return {
                props: {
                  totalRevenuActiviteAnnuelDeReference,
                  totalPerteDeGainsProfessionnels,
                  indemnitesJournalieresPercuesNet,
                  csgRdsTotal,
                  total,
                  partResponsableTotalRevenuActiviteAnnuelDeReference:
                    totalRevenuActiviteAnnuelDeReference *
                    procedure.partResponsabilite,
                  partResponsableTotalPerteDeGainsProfessionnels:
                    totalPerteDeGainsProfessionnels *
                    procedure.partResponsabilite,
                  partResponsableIndemnitesJournalieresPercuesNet:
                    (indemnitesJournalieresPercuesNet || 0) *
                    procedure.partResponsabilite,
                  partResponsableCsgRdsTotal:
                    (csgRdsTotal || 0) * procedure.partResponsabilite,
                  partResponsableTotal: total * procedure.partResponsabilite,
                },
              };
            }}
            render={({
              totalPerteDeGainsProfessionnels,
              indemnitesJournalieresPercuesNet,
              csgRdsTotal,
              total,
              partResponsableTotalPerteDeGainsProfessionnels,
              partResponsableCsgRdsTotal,
            }) => (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" />
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.perteDeGainsProfessionnelsTotal.columnHeader',
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.indemnitesJournalieresPercuesNet.columnHeader',
                      )}
                    </TableCell>
                    {!isPGPF && (
                      <TableCell align="center">
                        {t(
                          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.csgCrds.columnHeader',
                        )}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.total.columnHeader',
                      )}
                    </TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.totalRow.rowHeader',
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {fCurrency(totalPerteDeGainsProfessionnels)}
                    </TableCell>
                    <TableCell align="center">
                      {fCurrency(indemnitesJournalieresPercuesNet)}
                    </TableCell>
                    {!isPGPF && (
                      <TableCell align="center">
                        {fCurrency(csgRdsTotal)}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <ComputedReadFieldForm
                        name="total"
                        control={
                          control as Control<NewPrejudiceFormPerteGainProfessionnelsActuel>
                        }
                        customValues={[total]}
                        watchFields={[]}
                        compute={(_, customValues) => customValues?.[0]}
                        render={(value) => <>{getTropPercuString(value)}</>}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={onClickModify}
                        sx={{
                          color: '#19A3FF',
                          fontWeight: 'heavy',
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell align="center">
                      {t('partResponsabilite.tableRowHeader', {
                        partResponsabilite: fPartResponsabilite(
                          procedure.partResponsabilite * 100,
                        ),
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {fCurrency(
                        partResponsableTotalPerteDeGainsProfessionnels,
                      )}
                    </TableCell>
                    <TableCell />
                    {!isPGPF && (
                      <TableCell align="center">
                        {fCurrency(partResponsableCsgRdsTotal)}
                      </TableCell>
                    )}
                    <TableCell />
                  </TableRow>
                </TableFooter>
              </>
            )}
          />
        </NormalTable>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent={isPGPF ? 'start' : 'end'}
        alignItems="start"
      >
        {isPGPF ? (
          <Stack
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.light,
              borderRadius: theme.shape.borderRadius / 8,
              padding: theme.spacing(1),
            })}
          >
            <Typography textAlign="center" variant="subtitle1">
              {t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.modalButtonTitle',
              )}
            </Typography>
            {isPGPF && (
              <Button
                onClick={() => {
                  setIsRenteAccidentDuTravailModalOpen(true);
                }}
                sx={{ textTransform: 'none' }}
              >
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.modalButtonText',
                )}
              </Button>
            )}
          </Stack>
        ) : (
          <TotalNewPerteGainsActuels
            control={control as any}
            procedure={procedure}
          />
        )}
      </Stack>
      {isPGPF && (
        <ComputedPropsForm
          control={control}
          watchFields={['revenuActiviteAnnuelDeReference.total']}
          compute={([totalRevenuDeReference]) => ({
            props: {
              totalRevenuDeReference,
            },
          })}
          render={({ totalRevenuDeReference }) => (
            <RenteAccidentDuTravailDialog
              open={isRenteAccidentDuTravailModalOpen}
              salaireAnnuelDeReference={totalRevenuDeReference}
              tauxIPP={tauxIPP}
              onClose={() => setIsRenteAccidentDuTravailModalOpen(false)}
              formType="REVENUS"
            />
          )}
        />
      )}
    </>
  );
};
