import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';
import {
  MonetaryErosion,
  MonetaryErosionsCoefficientsType,
} from 'src/types/monetaryErosion.type';
import {
  DepenseFrequence,
  PrejudiceFormCalendrierDepenseRow,
  PrejudiceFormCalendrierValeurRow,
} from 'src/types/prejudice.type';
import { CalculsFormCapitalisation } from './calculsFormCapitalisation';
import { TotauxDepenses } from './type';
import { CalculsGlobal } from './calculsGlobal';

export abstract class CalculsFormCalendrier {
  static getMontantTiersPayeurs(
    priseEnChargeTiersPayeur: number,
    dureeDansUniteChoisie: number,
    quantite: number,
    renouvellementMaintenance: number,
    frequenceMontant: DepenseFrequence,
  ): number {
    let montant = priseEnChargeTiersPayeur * dureeDansUniteChoisie * quantite;
    if (
      frequenceMontant === 'viagere' &&
      renouvellementMaintenance &&
      renouvellementMaintenance !== 0
    ) {
      montant = montant / renouvellementMaintenance;
    }
    return montant;
  }
  static totalTiersPayeursDepense(depense: {
    priseEnChargeTiersPayeurs: PrejudiceFormCalendrierDepenseRow['priseEnChargeTiersPayeurs'];
  }): number {
    return depense.priseEnChargeTiersPayeurs?.reduce(
      (total, { montant }) => total + montant,
      0,
    );
  }

  static resteAChargeProratise({
    type,
    frequenceMontant,
    montantTotal,
    dureeDansUniteChoisie,
    quantite,
    priseEnChargeTiersPayeurs,
    renouvellementMaintenance,
  }: Pick<
    PrejudiceFormCalendrierDepenseRow,
    | 'type'
    | 'frequenceMontant'
    | 'montantTotal'
    | 'montantUnitaire'
    | 'dureeDansUniteChoisie'
    | 'quantite'
    | 'renouvellementMaintenance'
  > & {
    priseEnChargeTiersPayeurs: number[];
  }): number {
    if (type === 'recurrente' && frequenceMontant !== 'non_renseigne') {
      return priseEnChargeTiersPayeurs?.reduce((a, b) => {
        let substractedAmount: number = b * quantite * dureeDansUniteChoisie;
        if (
          frequenceMontant === 'viagere' &&
          renouvellementMaintenance &&
          renouvellementMaintenance !== 0
        ) {
          substractedAmount = substractedAmount / renouvellementMaintenance;
        }
        return a - substractedAmount;
      }, montantTotal);
    } else if (type === 'ponctuelle') {
      return priseEnChargeTiersPayeurs?.reduce((a, b) => a - b, montantTotal);
    } else {
      return priseEnChargeTiersPayeurs?.reduce(
        (a, b) => a - b * quantite * dureeDansUniteChoisie,
        montantTotal,
      );
    }
  }

  static resteACharge({
    type,
    montantUnitaire,
    quantite,
    priseEnChargeTiersPayeurs,
  }: Pick<
    PrejudiceFormCalendrierDepenseRow,
    'type' | 'montantUnitaire' | 'quantite'
  > & {
    priseEnChargeTiersPayeurs: number[];
  }): number {
    if (type === 'recurrente') {
      return (
        priseEnChargeTiersPayeurs.reduce((a, b) => a - b, montantUnitaire) *
        quantite
      );
    } else {
      return priseEnChargeTiersPayeurs.reduce((a, b) => a - b, montantUnitaire);
    }
  }

  static totauxDepenses({
    rows: unfilteredRows,
    tiersPayeurs,
    dateLiquidation,
    monetaryErosions,
    isCalculCapitalisation,
    revalorisationCoefficientsType,
  }: {
    rows: Pick<
      PrejudiceFormCalendrierDepenseRow,
      | 'type'
      | 'dateDebut'
      | 'dateFin'
      | 'frequenceMontant'
      | 'montantUnitaire'
      | 'resteACharge'
      | 'victimeIndirecte'
      | 'montantTotal'
      | 'quantite'
      | 'priseEnChargeTiersPayeurs'
      | 'priseEnChargeTiersPayeursProratises'
      | 'montantsDejaRevalorises'
      | 'dateJustificatif'
      | 'renouvellementMaintenance'
    >[];
    tiersPayeurs: string[];
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
    isVictimesIndirectes?: boolean;
    isCalculCapitalisation: boolean;
    revalorisationCoefficientsType: Exclude<
      MonetaryErosionsCoefficientsType,
      'smic'
    >;
  }): TotauxDepenses {
    const rowsEchues = unfilteredRows.filter((row) =>
      CalculsFormCapitalisation.isDepenseEchue({
        depense: row,
        isCalculCapitalisation,
        dateLiquidation,
      }),
    );

    const totaux = rowsEchues.reduce(
      (totaux: TotauxDepenses, depense) => {
        const resteAChargeRevalorise =
          !depense.montantsDejaRevalorises &&
          depense.dateJustificatif &&
          depense.resteACharge &&
          dateLiquidation
            ? getMontantRevalorise({
                montant: depense.resteACharge,
                monetaryErosions,
                anneeOrDateMontant: depense.dateJustificatif,
                anneeOrDateLiquidation: dateLiquidation,
                coefficientsType: revalorisationCoefficientsType,
              }) || depense.resteACharge
            : depense.resteACharge;
        const montantTotal = totaux.montantTotal + (depense.montantTotal || 0);
        const resteACharge = CalculsGlobal.max([
          0,
          totaux.resteACharge + (resteAChargeRevalorise || 0),
        ]);
        const victimeIndirecte = depense.victimeIndirecte
          ? (totaux.totalParVictimeIndirecte?.[depense.victimeIndirecte] || 0) +
            (resteAChargeRevalorise || 0)
          : null;
        return {
          montantTotal: montantTotal,
          resteACharge: resteACharge,
          priseEnChargeTiersPayeurs: tiersPayeurs.reduce(
            (
              accumulator: TotauxDepenses['priseEnChargeTiersPayeurs'],
              tierPayeur,
            ) => {
              const priseEnChargeTiersPayeur =
                (totaux.priseEnChargeTiersPayeurs[tierPayeur] || 0) +
                (Number(
                  depense.priseEnChargeTiersPayeursProratises?.find(
                    ({ tiersPayeur: tiersPayeurProratise }) =>
                      tiersPayeurProratise === tierPayeur,
                  )?.montant,
                ) || 0);
              accumulator[tierPayeur] = priseEnChargeTiersPayeur;
              return accumulator;
            },
            {} as TotauxDepenses['priseEnChargeTiersPayeurs'],
          ),
          totalParVictimeIndirecte: {
            ...(totaux.totalParVictimeIndirecte || {}),
            ...(depense.victimeIndirecte && victimeIndirecte
              ? {
                  [depense.victimeIndirecte]: victimeIndirecte,
                }
              : {}),
          },
        };
      },
      {
        priseEnChargeTiersPayeurs: {},
        montantTotal: 0,
        resteACharge: 0,
        totalParVictimeIndirecte: {},
      },
    );
    return totaux;
  }

  static totauxDepensesAEchoir({
    rows: unfilteredRows,
    tiersPayeurs,
    dateLiquidation,
    monetaryErosions,
    isCalculCapitalisation,
    revalorisationCoefficientsType,
  }: {
    rows: Pick<
      PrejudiceFormCalendrierDepenseRow,
      | 'type'
      | 'dateDebut'
      | 'dateFin'
      | 'frequenceMontant'
      | 'montantUnitaire'
      | 'resteACharge'
      | 'victimeIndirecte'
      | 'montantTotal'
      | 'quantite'
      | 'priseEnChargeTiersPayeurs'
      | 'priseEnChargeTiersPayeursProratises'
      | 'montantsDejaRevalorises'
      | 'dateJustificatif'
      | 'renouvellementMaintenance'
    >[];
    tiersPayeurs: string[];
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
    isVictimesIndirectes?: boolean;
    isCalculCapitalisation: boolean;
    revalorisationCoefficientsType: Exclude<
      MonetaryErosionsCoefficientsType,
      'smic'
    >;
  }): TotauxDepenses {
    const rowsEchues = unfilteredRows.filter(
      (row) =>
        !CalculsFormCapitalisation.isDepenseEchue({
          depense: row,
          isCalculCapitalisation,
          dateLiquidation,
        }) && row.type === 'ponctuelle',
    );

    const totaux = rowsEchues.reduce(
      (totaux: TotauxDepenses, depense) => {
        const resteAChargeRevalorise =
          !depense.montantsDejaRevalorises &&
          depense.dateJustificatif &&
          depense.resteACharge &&
          dateLiquidation
            ? getMontantRevalorise({
                montant: depense.resteACharge,
                monetaryErosions,
                anneeOrDateMontant: depense.dateJustificatif,
                anneeOrDateLiquidation: dateLiquidation,
                coefficientsType: revalorisationCoefficientsType,
              }) || depense.resteACharge
            : depense.resteACharge;
        const montantTotal = totaux.montantTotal + (depense.montantTotal || 0);
        const resteACharge = CalculsGlobal.max([
          0,
          totaux.resteACharge + (resteAChargeRevalorise || 0),
        ]);
        const victimeIndirecte = depense.victimeIndirecte
          ? (totaux.totalParVictimeIndirecte?.[depense.victimeIndirecte] || 0) +
            (resteAChargeRevalorise || 0)
          : null;
        return {
          montantTotal: montantTotal,
          resteACharge: resteACharge,
          priseEnChargeTiersPayeurs: tiersPayeurs.reduce(
            (
              accumulator: TotauxDepenses['priseEnChargeTiersPayeurs'],
              tierPayeur,
            ) => {
              const priseEnChargeTiersPayeur =
                (totaux.priseEnChargeTiersPayeurs[tierPayeur] || 0) +
                (Number(
                  depense.priseEnChargeTiersPayeursProratises?.find(
                    ({ tiersPayeur: tiersPayeurProratise }) =>
                      tiersPayeurProratise === tierPayeur,
                  )?.montant,
                ) || 0);
              accumulator[tierPayeur] = priseEnChargeTiersPayeur;
              return accumulator;
            },
            {} as TotauxDepenses['priseEnChargeTiersPayeurs'],
          ),
          totalParVictimeIndirecte: {
            ...(totaux.totalParVictimeIndirecte || {}),
            ...(depense.victimeIndirecte && victimeIndirecte
              ? {
                  [depense.victimeIndirecte]: victimeIndirecte,
                }
              : {}),
          },
        };
      },
      {
        priseEnChargeTiersPayeurs: {},
        montantTotal: 0,
        resteACharge: 0,
        totalParVictimeIndirecte: {},
      },
    );
    return totaux;
  }

  static getTotalCalendrierValeur({
    rows,
    revalorisationCoefficientsType,
    noRevalorisation,
    monetaryErosions,
    dateLiquidation,
  }: {
    rows: PrejudiceFormCalendrierValeurRow[];
    revalorisationCoefficientsType?: Exclude<
      MonetaryErosionsCoefficientsType,
      'smic'
    >;
    noRevalorisation?: boolean;
    monetaryErosions: MonetaryErosion[];
    dateLiquidation: string | null | undefined;
  }): number {
    return rows.reduce((montantTotal, row) => {
      if (!row.montantsDejaRevalorises && !noRevalorisation) {
        return (
          montantTotal +
          (getMontantRevalorise({
            montant: Number(row.montant),
            monetaryErosions,
            anneeOrDateMontant: row.date ?? undefined,
            anneeOrDateLiquidation: dateLiquidation ?? undefined,
            coefficientsType: revalorisationCoefficientsType,
          }) || 0)
        );
      }
      return montantTotal + Number(row.montant);
    }, 0);
  }
}
