import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { getEmptyLine } from 'src/helpers/print/docxFunctions';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { PerteDeChanceDeGainProfessionnel } from 'src/types/prejudice.type';

export const perteDeChanceDeGainProfessionnelPrint = ({
  rows,
  totalIndemnite,
  perteDeChance,
  coefficientPerteDeChance,
}: PerteDeChanceDeGainProfessionnel): (
  | Table
  | TableOfContents
  | Paragraph
)[] => {
  return [
    ...(rows.length > 0
      ? [
          simpleHorizontalTablePrint(
            [
              i18next.t('numeroPiece.form.columnHeader'),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.rows.annee.columnHeader',
              ),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.rows.indemnite.columnHeader',
              ),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.rows.commentaires.columnHeader',
              ),
            ],
            rows.map((row) => ({
              rowLabel: row.numerosPieces
                ? displayNumeroPiece(row.numerosPieces)
                : '',
              columns: [
                row.annee?.toString() || '',
                row.unite
                  ? i18next.t('prejudice.fields.unite.print.value', {
                      unite: row.unite,
                      value: fCurrency(row.indemnite),
                    })
                  : '',
                row.commentaires || '',
              ],
            })),
          ),
          getEmptyLine(),
        ]
      : []),
    simpleHorizontalTablePrint(
      [
        i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.totalIndemnite.columnHeader',
        ),
        i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.coefficientPerteDeChance.columnHeader',
        ),
        i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.perteDeChance.columnHeader',
        ),
      ],
      [
        {
          rowLabel: fCurrency(totalIndemnite),
          columns: [
            fPercent(coefficientPerteDeChance),
            fCurrency(perteDeChance),
          ],
        },
      ],
    ),
  ];
};
