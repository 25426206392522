import React from 'react';
import { Control } from 'react-hook-form';
import {
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
} from '../../../../types/prejudice.type';
import { Procedure } from '../../../../types/procedure.type';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { TotalPerteGainsFutursContent } from '../perteGainsProfessionnelsFuturs/TotalPerteGainsFutursContent';
import { CalculsFormNewPerteGainsProfessionnelsFuturs } from 'src/constants/calculs';

interface Props {
  control: Control<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte>;
  procedure: Procedure;
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  dateDeces: Date | undefined;
}

export const TotalPerteGainsFutursSaisieDirecte: React.FC<Props> = ({
  control,
  procedure,
  dateConsolidation,
  dateLiquidation,
  dateDeces,
}) => {
  return (
    <ComputedPropsForm
      control={control}
      watchFields={[
        'perteDeGainsProfessionnels.total',
        'capitalisation.montantCapitalise',
        'indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet',
        'indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows',
        'capitalisationTiersPayeurs',
      ]}
      compute={([
        perteDeGainsProfessionnelsTotal,
        montantCapitalise,
        indemnitesJournalieresPercuesNet,
        indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
        capitalisationTiersPayeurs,
      ]) => {
        return {
          props: {
            ...CalculsFormNewPerteGainsProfessionnelsFuturs.getIndemnitesRepartie(
              {
                perteGainProfessionnelsTotal: perteDeGainsProfessionnelsTotal,
                indemnitesJournalieresPercuesNet,
                indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
                tiersPayeursTotalCapitalise:
                  capitalisationTiersPayeurs.montantCapitalise,
                renteCapitalisee: montantCapitalise,
                partResponsabilite: procedure.partResponsabilite,
                capitalisationTiersPayeurs,
                tiersPayeurs: procedure.tiersPayeurs,
                dateConsolidation,
                dateLiquidation,
                dateDeces,
              },
            ),
          },
        };
      }}
      render={({
        PGPFReliquat,
        indemniteGlobaleARepartir,
        indemniteVictime,
        indemniteTiersPayeurs,
      }) => (
        <TotalPerteGainsFutursContent
          control={
            control as Control<
              | OldPrejudiceFormPerteGainProfessionnelsFuturs
              | NewPrejudiceFormPerteGainProfessionnelsFuturs
              | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
            >
          }
          indemniteRepartie={{
            indemniteGlobaleARepartir,
            indemniteVictime,
            indemniteTiersPayeurs,
          }}
          PGPFReliquat={PGPFReliquat}
          partResponsabilite={procedure.partResponsabilite}
        />
      )}
    />
  );
};
