import {
  ListeProjectionFonctionnelPermanentRow,
  ListeProjectionIncidenceProfessionelle,
  ListeProjectionIncidenceProfessionelleRow,
  ListeProjectionMaladieRow,
  PerteDeRetraiteValues,
  PGPFReliquatAndPGPFReliquatActivationStatus,
} from 'src/types/prejudice.type';
import { CalculsGlobal } from './calculsGlobal';
import { IndemniteRepartieAEchoir } from './type';

export abstract class CalculsFormListeProjection {
  static getAnneesConsolideDeces(
    dateConsolidation: Date | string,
    dateDeces: Date | string,
  ): number {
    const years = CalculsGlobal.getYears(dateConsolidation, dateDeces);
    return CalculsGlobal.max([years, 0]);
  }

  static getMontantProratise(
    montantTotal: number,
    coefficientCapitalisationAgeConsolidation: number,
    dateConsolidation: Date | string,
    dateDeces: Date | string,
  ): number {
    return (
      montantTotal *
      (this.getAnneesConsolideDeces(dateConsolidation, dateDeces) /
        coefficientCapitalisationAgeConsolidation)
    );
  }

  static getMontantDeficitFonctionnelPermanent({
    pourcentage,
    valeurPoint,
  }: {
    pourcentage: number | string;
    valeurPoint: number | string;
  }): number {
    return Number(valeurPoint) * Number(pourcentage);
  }

  static getDegreFinalPrejudiceEsthetiquePermanent(
    rows: Pick<ListeProjectionMaladieRow, 'jours' | 'degre'>[],
  ): number {
    if (!rows.length) return 1;

    const { sumJours, degreEchelle } = rows.reduce(
      (acc, row) => ({
        sumJours: acc.sumJours + Number(row.jours),
        degreEchelle: acc.degreEchelle + Number(row.jours) * Number(row.degre),
      }),
      {
        sumJours: 0,
        degreEchelle: 0,
      },
    );

    return Math.round((degreEchelle / sumJours) * 10) / 10;
  }

  static getCoefficientIPP(tauxIPP: number): number {
    if (tauxIPP < 50) {
      return tauxIPP / 2;
    } else {
      return 25 + (tauxIPP - 50) * 1.5;
    }
  }

  static getFractionSalairePriseEnCompte(
    salaireAnnuelDeReference: number,
  ): number {
    if (salaireAnnuelDeReference < 37299.82) {
      return salaireAnnuelDeReference;
    } else if (salaireAnnuelDeReference < 149199.28) {
      return 37299.82 + (salaireAnnuelDeReference - 37299.82) / 3;
    } else if (salaireAnnuelDeReference < 149199.28) {
      return salaireAnnuelDeReference;
    } else {
      return 0;
    }
  }

  static getRenteAnnuelle(
    tauxIPP: number,
    salaireAnnuelDeReference: number,
  ): number {
    if (tauxIPP < 10) {
      return 0;
    } else {
      return (
        (this.getFractionSalairePriseEnCompte(salaireAnnuelDeReference) *
          this.getCoefficientIPP(tauxIPP)) /
        100
      );
    }
  }

  static getCapital(tauxIPP: number): number {
    switch (tauxIPP) {
      case 1:
        return 419.37;
      case 2:
        return 681.64;
      case 3:
        return 996.07;
      case 4:
        return 1572.14;
      case 5:
        return 1991.62;
      case 6:
        return 2463.31;
      case 7:
        return 2987.19;
      case 8:
        return 3563.92;
      case 9:
        return 4192.8;
      default:
        return 0;
    }
  }

  static getIncidenceProfessionnelleIndemnite({
    PGPFReliquat,
    partResponsabilite,
    rows,
    perteDeRetraite,
  }: {
    PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
    partResponsabilite: number;
    rows: ListeProjectionIncidenceProfessionelleRow[];
    perteDeRetraite: PerteDeRetraiteValues;
  }): {
    montantTotal: number;
    beforePGPFReliquatTotal: number | null;
    indemniteGlobaleARepartir: {
      beforePartResponsabiliteTotal: number;
      total: number;
      victime: {
        echus: number;
        aEchoir: number;
      };
      tiersPayeurs: number;
    };
    total: {
      total: number;
      victime: {
        echus: number;
        aEchoir: number;
      };
    };
    IPPGPFReliquat: number | null;
  } {
    const totalEchus =
      CalculsGlobal.sum(rows.map((row) => Number(row.montant))) +
      (perteDeRetraite?.echus.montantAPayerADateCalcul || 0);
    const totalAEchoir = perteDeRetraite?.aEchoir.montantCapitalise || 0;
    const total = totalEchus + totalAEchoir;
    const partResponsableTotal = partResponsabilite * total;

    if (PGPFReliquat.PGPFReliquat && PGPFReliquat.activatePGPFReliquat) {
      const beforePGPFReliquatTotal = total;
      const afterPGPFReliquatMontantTotal =
        partResponsableTotal + PGPFReliquat.PGPFReliquat;
      const montantTotal = CalculsGlobal.max([
        0,
        afterPGPFReliquatMontantTotal,
      ]);
      const partVictime =
        afterPGPFReliquatMontantTotal < 0
          ? partResponsableTotal
          : afterPGPFReliquatMontantTotal;
      const partTiersPayeurs = partResponsableTotal - partVictime;
      return {
        montantTotal,
        beforePGPFReliquatTotal,
        indemniteGlobaleARepartir: {
          beforePartResponsabiliteTotal: total,
          total: partResponsableTotal,
          victime: {
            echus: (totalEchus / total) * partVictime,
            aEchoir: (totalAEchoir / total) * partVictime,
          },
          tiersPayeurs: partTiersPayeurs,
        },
        IPPGPFReliquat:
          afterPGPFReliquatMontantTotal < 0
            ? afterPGPFReliquatMontantTotal
            : null,
        total: {
          total,
          victime: {
            echus: totalEchus,
            aEchoir: totalAEchoir,
          },
        },
      };
    } else {
      return {
        montantTotal: total,
        beforePGPFReliquatTotal: null,
        indemniteGlobaleARepartir: {
          beforePartResponsabiliteTotal: total,
          total: partResponsableTotal,
          victime: {
            echus: totalEchus * partResponsabilite,
            aEchoir: totalAEchoir * partResponsabilite,
          },
          tiersPayeurs: 0,
        },
        IPPGPFReliquat: null,
        total: {
          total,
          victime: {
            echus: totalEchus,
            aEchoir: totalAEchoir,
          },
        },
      };
    }
  }

  static getDeficitFonctionnelPermanentIndemnite({
    rows,
  }: {
    rows: Pick<ListeProjectionFonctionnelPermanentRow, 'montant'>[];
  }): number {
    const total = CalculsGlobal.sum(rows.map((row) => Number(row.montant)));
    return total;
  }
  static getIncidenceProfessionnelleIndemniteRepartie({
    partResponsabilite,
    incidenceProfessionnelle,
    PGPFReliquat,
  }: {
    partResponsabilite: number;
    incidenceProfessionnelle: ListeProjectionIncidenceProfessionelle;
    PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  }): IndemniteRepartieAEchoir {
    const indemniteIncidenceProfessionnelle =
      this.getIncidenceProfessionnelleIndemnite({
        PGPFReliquat,
        partResponsabilite,
        rows: incidenceProfessionnelle.rows,
        perteDeRetraite: incidenceProfessionnelle.perteDeRetraite,
      });
    const totalTiersPayeurs =
      PGPFReliquat.PGPFReliquat &&
      PGPFReliquat.PGPFReliquat < 0 &&
      PGPFReliquat.activatePGPFReliquat
        ? -PGPFReliquat.PGPFReliquat
        : 0;
    const indemniteGlobaleARepartir =
      indemniteIncidenceProfessionnelle.total.total * partResponsabilite;
    const indemniteVictime = CalculsGlobal.max([
      0,
      CalculsGlobal.min([
        indemniteIncidenceProfessionnelle.total.total - totalTiersPayeurs,
        indemniteGlobaleARepartir,
      ]),
    ]);
    const indemniteVictimeEchue = CalculsGlobal.min([
      indemniteIncidenceProfessionnelle.total.victime.echus,
      indemniteVictime,
    ]);
    const indemniteVictimeAEchoir = CalculsGlobal.min([
      indemniteIncidenceProfessionnelle.total.victime.aEchoir,
      indemniteVictime - indemniteVictimeEchue,
    ]);
    const indemniteTiersPayeurs = CalculsGlobal.min([
      totalTiersPayeurs,
      indemniteGlobaleARepartir - indemniteVictime,
    ]);
    return {
      indemniteGlobaleARepartir: {
        solde: indemniteGlobaleARepartir,
        beforePartResponsabilite:
          indemniteIncidenceProfessionnelle.indemniteGlobaleARepartir
            .beforePartResponsabiliteTotal,
      },
      indemniteVictime: {
        arreragesEchus: {
          debit: indemniteVictimeEchue,
          solde: indemniteGlobaleARepartir - indemniteVictimeEchue,
        },
        arreragesAEchoir: {
          debit: indemniteVictimeAEchoir,
          solde:
            indemniteGlobaleARepartir -
            indemniteVictimeEchue -
            indemniteVictimeAEchoir,
        },
        total: indemniteVictime,
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          debit: indemniteTiersPayeurs,
          solde:
            indemniteGlobaleARepartir -
            indemniteVictimeEchue -
            indemniteVictimeAEchoir -
            indemniteTiersPayeurs,
        },
        arreragesAEchoir: {
          debit: 0,
          solde: 0,
        },
        total: indemniteTiersPayeurs,
      },
    };
  }
}
