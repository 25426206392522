import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'src/components/basic/Accordion';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { DatePickerForm } from 'src/components/forms/DatePickerForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { LayoutTable, SpanNoWrap } from 'src/components/styled';
import { CalculsGlobal } from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { Bareme } from 'src/types/bareme.type';
import { PrejudiceFormListeProjection } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { CapitalisationRows } from '../Capitalisation/CapitalisationRows';

interface Props {
  control: Control<PrejudiceFormListeProjection>;
  procedure: Procedure;
  dateLiquidation: Date | null | undefined;
  baremes: Bareme[];
  dateNaissance: Date | null | undefined;
  dateDeces: Date | null | undefined;
  sexe: 'm' | 'f' | 'u';
}

export const PerteDeRetraite: React.FC<Props> = ({
  control,
  procedure,
  dateLiquidation,
  baremes,
  dateNaissance,
  dateDeces,
  sexe,
}) => {
  const { t } = useTranslation();
  const dateDecesOrLiquidation = dateDeces || dateLiquidation;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 'bold',
            opacity: 0.45,
          }}
          whiteSpace="pre-line"
        >
          {t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.subtitle',
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Accordion
          title={
            t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.accordionsTitles.retraite',
            ) || ''
          }
        >
          <LayoutTable>
            <TableBody>
              <TableRow>
                <TableCell>
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.anneeRetraite.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <TextFieldForm
                    control={control}
                    name="prejudiceValues.perteDeRetraite.retraite.anneeRetraite"
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 0, thousandsSeparator: '' },
                        nullable: true,
                      } as IMaskNumberProps,
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.ageRetraite.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <ComputedPropsForm
                    control={control}
                    watchFields={[
                      'prejudiceValues.perteDeRetraite.retraite.anneeRetraite',
                    ]}
                    compute={([anneeRetraite]) => ({
                      props: {
                        age:
                          anneeRetraite && dateNaissance
                            ? anneeRetraite - dateNaissance.getFullYear()
                            : null,
                      },
                    })}
                    render={(props) => (
                      <SpanNoWrap>
                        {props.age && props.age > 0
                          ? t('forms.fields.age.value', {
                              count: props.age,
                              context:
                                props.age === 0 ? 'exactly_zero' : undefined,
                            })
                          : ''}
                      </SpanNoWrap>
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.retraiteTheoriqueTotal.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <TextFieldForm
                    control={control}
                    name="prejudiceValues.perteDeRetraite.retraite.retraiteTheoriqueTotal"
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.retraitePercue.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <TextFieldForm
                    control={control}
                    name="prejudiceValues.perteDeRetraite.retraite.retraitePercue"
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.perteAnnuelle.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <ComputedTextFieldForm
                    control={control}
                    name="prejudiceValues.perteDeRetraite.retraite.perteAnnuelle"
                    watchFields={[
                      'prejudiceValues.perteDeRetraite.retraite.retraiteTheoriqueTotal',
                      'prejudiceValues.perteDeRetraite.retraite.retraitePercue',
                    ]}
                    compute={([retraiteTheoriqueTotal, retraitePercue]) =>
                      retraiteTheoriqueTotal - retraitePercue
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </LayoutTable>
        </Accordion>
      </Grid>
      <Grid item xs={12} md={6}>
        <Accordion
          title={
            t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.accordionsTitles.echus',
            ) || ''
          }
        >
          <LayoutTable>
            <TableBody>
              <TableRow>
                <TableCell>
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.echus.nombreAnneesEntreAnneeRetraiteEtAnneeCalcul.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <ComputedReadFieldForm
                    control={control}
                    name="prejudiceValues.perteDeRetraite.echus.nombreAnneesEntreAnneeRetraiteEtAnneeCalcul"
                    watchFields={[
                      'prejudiceValues.perteDeRetraite.retraite.anneeRetraite',
                    ]}
                    compute={([anneeRetraite]) => {
                      if (!dateDecesOrLiquidation || anneeRetraite === null) {
                        return 0;
                      }
                      const nombreAnneesEntreAnneeRetraiteEtAnneeCalcul =
                        dateDecesOrLiquidation.getFullYear() - anneeRetraite;
                      return CalculsGlobal.max([
                        0,
                        nombreAnneesEntreAnneeRetraiteEtAnneeCalcul || 0,
                      ]);
                    }}
                    render={(value) => (
                      <SpanNoWrap>
                        {t('forms.fields.year.value', {
                          count: value,
                        })}
                      </SpanNoWrap>
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.echus.montantAPayerADateCalcul.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <ComputedReadFieldForm
                    control={control}
                    name="prejudiceValues.perteDeRetraite.echus.montantAPayerADateCalcul"
                    watchFields={[
                      'prejudiceValues.perteDeRetraite.retraite.perteAnnuelle',
                      'prejudiceValues.perteDeRetraite.echus.nombreAnneesEntreAnneeRetraiteEtAnneeCalcul',
                    ]}
                    compute={([
                      perteAnnuelle,
                      nombreAnneesEntreAnneeRetraiteEtAnneeCalcul,
                    ]) =>
                      perteAnnuelle *
                      nombreAnneesEntreAnneeRetraiteEtAnneeCalcul
                    }
                    render={(value) => (
                      <SpanNoWrap>{fCurrency(value)}</SpanNoWrap>
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </LayoutTable>
        </Accordion>
      </Grid>
      <Grid item xs={12} md={9} lg={6}>
        <Accordion
          title={
            t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.accordionsTitles.aEchoir',
            ) || ''
          }
          summaryProps={{
            sx: (theme) => ({
              backgroundColor: theme.palette.action.hover,
            }),
          }}
        >
          <CalculsBox>
            <TableContainer>
              <LayoutTable>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {t(
                        'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.aEchoir.dateCapitalisation.rowHeader',
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <DatePickerForm
                        control={control}
                        name="prejudiceValues.perteDeRetraite.aEchoir.dateCapitalisation"
                        minDate={dateLiquidation ?? undefined}
                        TextFieldProps={{
                          sx: { minWidth: 268 },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <ComputedPropsForm
                    control={control}
                    watchFields={[
                      'prejudiceValues.perteDeRetraite.aEchoir.dateCapitalisation',
                    ]}
                    compute={([dateCapitalisation]) => {
                      if (!dateCapitalisation || !dateNaissance) {
                        return {
                          props: {
                            dateAttribution: null as string | null,
                            ageDateAttribution: null as number | null,
                          },
                        };
                      }
                      const ageDateAttribution = CalculsGlobal.getAge(
                        dateNaissance,
                        dateCapitalisation,
                      );
                      return {
                        props: {
                          dateAttribution: dateCapitalisation,
                          ageDateAttribution,
                        },
                      };
                    }}
                    render={(props) => (
                      <CapitalisationRows
                        control={control}
                        component={{
                          sommeACapitaliser: (
                            <ComputedTextFieldForm
                              control={control}
                              name="prejudiceValues.perteDeRetraite.aEchoir.sommeACapitaliser"
                              watchFields={[
                                'prejudiceValues.perteDeRetraite.retraite.perteAnnuelle',
                              ]}
                              compute={([perteAnnuelle]) => perteAnnuelle}
                              InputProps={{
                                inputComponent: MaskNumber as any,
                                inputProps: {
                                  numberMask: { scale: 2, signed: true },
                                  suffix: '€',
                                } as IMaskNumberProps,
                              }}
                              sx={{ minWidth: 268 }}
                              editedFieldsName="editedFields"
                            />
                          ),
                        }}
                        name={{
                          sommeACapitaliser:
                            'prejudiceValues.perteDeRetraite.aEchoir.sommeACapitaliser',
                          bareme:
                            'prejudiceValues.perteDeRetraite.aEchoir.bareme',
                          ageDernierArrerage:
                            'prejudiceValues.perteDeRetraite.aEchoir.ageDernierArrerage',
                          coefficient:
                            'prejudiceValues.perteDeRetraite.aEchoir.coefficient',
                          isLastArrerageViager:
                            'prejudiceValues.perteDeRetraite.aEchoir.isLastArrerageViager',
                          montantCapitalise:
                            'prejudiceValues.perteDeRetraite.aEchoir.montantCapitalise',
                        }}
                        procedure={procedure}
                        dateLiquidation={dateLiquidation}
                        baremes={baremes}
                        dateNaissance={dateNaissance}
                        dateDeces={dateDeces}
                        sexe={sexe}
                        titles={{
                          montantCapitalise:
                            t(
                              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.aEchoir.montantCapitalise.rowHeader',
                            ) || '',
                          sommeACapitaliser:
                            t(
                              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.aEchoir.sommeACapitaliser.rowHeader',
                            ) || '',
                        }}
                        ageDateAttribution={
                          props.ageDateAttribution ?? undefined
                        }
                        dateAttribution={props.dateAttribution ?? undefined}
                        hide={{ sexe: true }}
                        editedFieldsName="editedFields"
                      />
                    )}
                  />
                </TableBody>
              </LayoutTable>
            </TableContainer>
          </CalculsBox>
        </Accordion>
      </Grid>
    </Grid>
  );
};
