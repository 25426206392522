import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityId,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import {
  CreateInvitationDto,
  Invitation,
  UpdateInvitationDto,
} from 'src/types/invitation.type';
import { apiRequest } from '../helpers/api';

/* Thunks */
export const fetchAllInvitations = createAsyncThunk(
  'invitations/fetchAll',
  async () => {
    return await apiRequest<Invitation[]>('GET', '/invitation');
  },
);

export const createInvitation = createAsyncThunk(
  'invitations/create',
  async (payload: { data: CreateInvitationDto }) => {
    const { data } = payload;

    return await apiRequest<Invitation>('POST', '/invitation', undefined, data);
  },
);

export const updateInvitation = createAsyncThunk(
  'invitations/update',
  async (payload: { invitationId: string; data: UpdateInvitationDto }) => {
    const { invitationId, data } = payload;

    return await apiRequest<Invitation>(
      'PATCH',
      `/invitation/${invitationId}`,
      undefined,
      data,
    );
  },
);

export const resendInvitation = createAsyncThunk(
  'invitations/resend',
  async (payload: { invitationId: string }) => {
    const { invitationId } = payload;
    return await apiRequest<Invitation>(
      'PUT',
      `/invitation/${invitationId}/resend`,
    );
  },
);

export const deleteInvitation = createAsyncThunk(
  'invitations/delete',
  async (payload: { invitationId: string }) => {
    const { invitationId } = payload;

    return await apiRequest<{ id: string }>(
      'DELETE',
      `/invitation/${invitationId}`,
    );
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
};

/* Adapter */
export const invitationsAdapter = createEntityAdapter<Invitation>({
  selectId: (invitation) => invitation._id,
});

/* Slice */
const invitationsSlice = createSlice({
  name: 'invitations',
  initialState: invitationsAdapter.getInitialState({
    isLoading: false,
    error: undefined,
  }),
  reducers: {
    setInvitation: (state, action: PayloadAction<Invitation>) => {
      invitationsAdapter.upsertOne(state, action.payload);
    },
    removeOne: (state, action: PayloadAction<EntityId>) => {
      invitationsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInvitations.pending, sharedReducers.pending)
      .addCase(fetchAllInvitations.rejected, sharedReducers.rejected)
      .addCase(fetchAllInvitations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        invitationsAdapter.setAll(state, payload);
      })
      .addCase(createInvitation.pending, sharedReducers.pending)
      .addCase(createInvitation.rejected, sharedReducers.rejected)
      .addCase(createInvitation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        invitationsAdapter.upsertOne(state, payload);
      })
      .addCase(updateInvitation.pending, sharedReducers.pending)
      .addCase(updateInvitation.rejected, sharedReducers.rejected)
      .addCase(updateInvitation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        invitationsAdapter.upsertOne(state, payload);
      })
      .addCase(deleteInvitation.pending, sharedReducers.pending)
      .addCase(deleteInvitation.rejected, sharedReducers.rejected)
      .addCase(
        deleteInvitation.fulfilled,
        (
          state,
          {
            meta: {
              arg: { invitationId },
            },
          },
        ) => {
          state.isLoading = false;
          invitationsAdapter.removeOne(state, invitationId);
        },
      )
      .addCase(resendInvitation.pending, sharedReducers.pending)
      .addCase(resendInvitation.rejected, sharedReducers.rejected)
      .addCase(resendInvitation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        invitationsAdapter.upsertOne(state, payload);
      });
  },
});

export const { setInvitation, removeOne } = invitationsSlice.actions;

export default invitationsSlice.reducer;
