import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { simpleVerticalTablePrint } from '../simpleVerticalTablePrint';
import { CalculsRentes } from 'src/constants/calculs/calculsRentes';
import { getEmptyLine, getParagraph, getTextRun } from '../docxFunctions';

export const rentesPrint = ({
  montant,
  title,
}: {
  montant: number;
  title?: string;
}): (Table | TableOfContents | Paragraph)[] => {
  const mensuel = CalculsRentes.getMensuel(montant);
  const trimestriel = CalculsRentes.getTrimestriel(montant);

  return [
    getParagraph({
      children: getTextRun({
        text: title || i18next.t('prejudice.rentes.print.title'),
        bold: true,
      }),
    }),
    getEmptyLine(),
    simpleVerticalTablePrint([
      {
        label: i18next.t('prejudice.rentes.form.fields.montant.rowHeader'),
        value: fCurrency(montant),
      },
      {
        label: i18next.t(
          'prejudice.rentes.form.fields.montantTrimestriel.rowHeader',
        ),
        value: fCurrency(trimestriel),
      },
      {
        label: i18next.t(
          'prejudice.rentes.form.fields.montantMensuel.rowHeader',
        ),
        value: fCurrency(mensuel),
      },
    ]),
  ];
};
