import i18next from 'i18next';
import { fDate, fDays } from 'src/helpers/formatTime';

export const getPeriodeWrittenPrint = ({
  dateDebut,
  dateFin,
  duree,
  ...parameters
}: {
  dateDebut: Date | string | undefined | null;
  dateFin: Date | string | undefined | null;
  duree?: number | undefined | null;
  options?: {
    spaceBefore?: boolean;
    endCharacter?: string;
  };
}): string => {
  const options = {
    spaceBefore: true,
    endCharacter: '.',
    ...parameters.options,
  };
  const text = i18next.t('print.writtenPrint.periode', {
    dateDebut: dateDebut ? fDate(dateDebut) : '',
    dateFin: dateFin ? fDate(dateFin) : '',
    jours:
      !dateDebut && !dateFin && duree
        ? fDays(duree)
        : duree
          ? i18next.t('print.writtenPrint.periodeDays', {
              jours: fDays(duree),
            })
          : '',
    endCharacter: options.endCharacter,
    context:
      !dateDebut && !dateFin && !duree
        ? 'noDateAndNoDays'
        : !dateDebut && !dateFin
          ? 'noDate'
          : !dateDebut
            ? 'noStartDate'
            : !dateFin
              ? 'noEndDate'
              : undefined,
  });

  if (text.length === 0) {
    return '';
  }

  return `${options?.spaceBefore ? ' ' : ''}${text}`;
};
