import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { pendingReducer, rejectedReducer } from '../helpers/slice';
import {
  VictimeIndirecte,
  CreateVictimeIndirecteDto,
  UpdateVictimeIndirecteDto,
} from '../types/victimeIndirecte.type';

/* Thunks */

export const fetchAllVictimesIndirectes = createAsyncThunk(
  'victimesIndirectes/fetchAll',
  async (procedureId: string) => {
    return await apiRequest<VictimeIndirecte[]>(
      'GET',
      `/procedures/${procedureId}/victimesIndirectes`,
    );
  },
);

export const createVictimeIndirecte = createAsyncThunk(
  'victimesIndirectes/create',
  async (payload: { procedureId: string; data: CreateVictimeIndirecteDto }) => {
    const { procedureId, data } = payload;

    return await apiRequest<VictimeIndirecte>(
      'POST',
      `/procedures/${procedureId}/victimesIndirectes`,
      undefined,
      data,
    );
  },
);

export const updateVictimeIndirecte = createAsyncThunk(
  'victimesIndirectes/update',
  async (payload: {
    procedureId: string;
    victimeIndirecteId: string;
    data: UpdateVictimeIndirecteDto;
  }) => {
    const { procedureId, victimeIndirecteId, data } = payload;

    return await apiRequest<VictimeIndirecte>(
      'PATCH',
      `/procedures/${procedureId}/victimesIndirectes/${victimeIndirecteId}`,
      undefined,
      data,
    );
  },
);

export const deleteVictimeIndirecte = createAsyncThunk(
  'victimesIndirectes/delete',
  async (payload: { procedureId: string; victimeIndirecteId: string }) => {
    const { procedureId, victimeIndirecteId } = payload;

    return await apiRequest<{ id: string }>(
      'DELETE',
      `/procedures/${procedureId}/victimesIndirectes/${victimeIndirecteId}`,
    );
  },
);

/* Slice */

export const victimeIndirecteAdapter = createEntityAdapter<VictimeIndirecte>({
  selectId: (victimeIndirecte) => victimeIndirecte._id,
});

export const victimeIndirecteSlice = createSlice({
  name: 'victimeIndirecte',
  initialState: victimeIndirecteAdapter.getInitialState({
    isLoading: false,
    isFetched: false,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVictimesIndirectes.fulfilled, (state, action) => {
        victimeIndirecteAdapter.setAll(state, action.payload);
        state.isLoading = false;
        state.isFetched = true;
      })
      .addCase(fetchAllVictimesIndirectes.pending, pendingReducer)
      .addCase(fetchAllVictimesIndirectes.rejected, (state) => {
        state.isLoading = false;
        state.isFetched = false;
      });

    builder
      .addCase(createVictimeIndirecte.fulfilled, (state, action) => {
        victimeIndirecteAdapter.addOne(state, action.payload);
        state.isLoading = false;
      })
      .addCase(createVictimeIndirecte.pending, pendingReducer)
      .addCase(createVictimeIndirecte.rejected, rejectedReducer);

    builder
      .addCase(updateVictimeIndirecte.fulfilled, (state, action) => {
        victimeIndirecteAdapter.setOne(state, action.payload);
        state.isLoading = false;
      })
      .addCase(updateVictimeIndirecte.pending, pendingReducer)
      .addCase(updateVictimeIndirecte.rejected, rejectedReducer);

    builder
      .addCase(deleteVictimeIndirecte.fulfilled, (state, action) => {
        victimeIndirecteAdapter.removeOne(state, action.payload.id);
        state.isLoading = false;
      })
      .addCase(deleteVictimeIndirecte.pending, pendingReducer)
      .addCase(deleteVictimeIndirecte.rejected, rejectedReducer);
  },
});

export default victimeIndirecteSlice.reducer;
