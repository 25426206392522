import {
  RevenuAnnuelEspere,
  RevenuAnnuelEspereRow,
} from 'src/types/prejudice.type';
import { Time } from '../time';
import { CalculsGlobal } from './calculsGlobal';

export abstract class CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte {
  static getPerteDeChance({
    coefficientPerteDeChance,
    unite,
    revenuEspere,
  }: Pick<
    RevenuAnnuelEspereRow,
    'coefficientPerteDeChance' | 'unite' | 'revenuEspere'
  >): number {
    const amount = (revenuEspere * coefficientPerteDeChance) / 100;
    switch (unite) {
      case 'jour':
        return amount * Time.daysInYear;
      case 'mois':
        return amount * Time.monthsInYear;
      case 'annee':
        return amount;
      case 'semaine':
        return amount * Time.weeksInYear;
      default:
        return 0;
    }
  }
  static getTotalPerteDeChance(
    rows: Pick<RevenuAnnuelEspereRow, 'perteDeChance'>[],
  ): number {
    return CalculsGlobal.sum(rows.map((row) => row.perteDeChance));
  }

  static getTotalPerteDeChanceByUnits(
    total: RevenuAnnuelEspere['total'],
  ): Record<Exclude<RevenuAnnuelEspereRow['unite'], 'annee' | ''>, number> {
    return {
      jour: total / Time.daysInYear,
      mois: total / Time.monthsInYear,
      semaine: total / Time.weeksInYear,
    };
  }

  static getDifference(
    perteGainProfessionnelsTotal: number,
    indemnitesJournalieresPercuesNet: number | null,
  ): number {
    return (
      CalculsGlobal.max([perteGainProfessionnelsTotal, 0]) -
      (indemnitesJournalieresPercuesNet || 0)
    );
  }
}
