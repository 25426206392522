import { isValid } from 'date-fns';
import * as yup from 'yup';
import { Message } from 'yup';

const parseDateWithoutTime = (
  value: string | number | Date,
): Date | undefined => {
  const date = new Date(value);
  if (isValid(date)) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
  return undefined;
};

function getDateWithExtraDay(date: Date, minus: boolean) {
  const dateWithExtraDay = new Date(
    new Date(date).valueOf() + (minus ? -86400 : 86400),
  );
  if (isValid(dateWithExtraDay)) {
    return dateWithExtraDay;
  }
  return date;
}

const parseDateAndMinOrMaxDate = (
  value: any,
  parent: any,
  minOrMaxName?: string,
  defaultMinOrMax?: Date,
): { minOrMaxDate: Date | undefined; valueDate: Date | undefined } => {
  const minOrMax: Date | string | undefined =
    (minOrMaxName && parent[minOrMaxName]) || defaultMinOrMax;
  const minOrMaxDate =
    typeof minOrMax === 'string' ||
    typeof minOrMax === 'number' ||
    minOrMax instanceof Date
      ? parseDateWithoutTime(minOrMax)
      : minOrMax;
  const valueDate =
    typeof value === 'string' ||
    typeof value === 'number' ||
    value instanceof Date
      ? parseDateWithoutTime(value)
      : value;
  return { minOrMaxDate, valueDate };
};
yup.addMethod(
  yup.StringSchema,
  'minDate',
  function minDate(minName?: string, message?: Message, defaultMin?: Date) {
    return this.test({
      name: 'min',
      message,
      test(value: any) {
        const { minOrMaxDate: minDate, valueDate } = parseDateAndMinOrMaxDate(
          value,
          this.parent,
          minName,
          defaultMin,
        );
        if (!minDate || !valueDate) {
          return true;
        }
        const extraDayMinDate = getDateWithExtraDay(minDate, true);
        return valueDate >= extraDayMinDate;
      },
    });
  },
);
yup.addMethod(
  yup.StringSchema,
  'maxDate',
  function maxDate(maxName?: string, message?: Message, defaultMax?: Date) {
    return this.test({
      name: 'max',
      message,
      test(value: any) {
        const { minOrMaxDate: maxDate, valueDate } = parseDateAndMinOrMaxDate(
          value,
          this.parent,
          maxName,
          defaultMax,
        );
        if (!maxDate || !valueDate) {
          return true;
        }
        const maxDateWithExtraDay = getDateWithExtraDay(maxDate, false);
        return valueDate <= maxDateWithExtraDay;
      },
    });
  },
);
yup.addMethod(
  yup.StringSchema,
  'isDateString',
  function isDateString(message?: Message) {
    return this.test({
      name: 'isDateString',
      message,
      test(value: any) {
        if (!value) {
          return true;
        } else if (typeof value === 'string') {
          return isValid(new Date(value));
        }
        return false;
      },
    });
  },
);
const parseDateAndMinOrMaxYear = (
  value: any,
  parent: any,
  minOrMaxName?: string,
  defaultMinOrMax?: number,
): { minOrMax: number | undefined; valueDate: Date | undefined } => {
  const minOrMax: number | undefined =
    Number(minOrMaxName && parent[minOrMaxName]) || defaultMinOrMax;
  const valueDate =
    typeof value === 'string' ||
    typeof value === 'number' ||
    value instanceof Date
      ? new Date(value)
      : value;
  return { minOrMax, valueDate };
};

yup.addMethod(
  yup.StringSchema,
  'minYear',
  function minYear(
    minName?: string,
    message?: Message,
    defaultMin?: number,
    options?: { strict: boolean },
  ) {
    return this.test({
      name: 'minYear',
      message,
      test(value: any) {
        const { minOrMax: min, valueDate } = parseDateAndMinOrMaxYear(
          value,
          this.parent,
          minName,
          defaultMin,
        );
        if (!min || !valueDate) {
          return true;
        }
        if (options?.strict) {
          return valueDate.getFullYear() > min;
        } else {
          return valueDate.getFullYear() >= min;
        }
      },
    });
  },
);

yup.addMethod(
  yup.StringSchema,
  'maxYear',
  function maxYear(
    maxName?: string,
    message?: Message,
    defaultMax?: number,
    options?: { strict: boolean },
  ) {
    return this.test({
      name: 'maxYear',
      message,
      test(value: any) {
        const { minOrMax: max, valueDate } = parseDateAndMinOrMaxYear(
          value,
          this.parent,
          maxName,
          defaultMax,
        );
        if (!max || !valueDate) {
          return true;
        }
        if (options?.strict) {
          return valueDate.getFullYear() < max;
        } else {
          return valueDate.getFullYear() <= max;
        }
      },
    });
  },
);

declare module 'yup' {
  interface StringSchema {
    isDateString(
      schemaIfDate?: yup.DateSchema,
      message?: Message<{ date: string }>,
    ): this;
    minDate(
      minName?: string,
      message?: Message<{ min: Date }>,
      defaultMin?: Date,
      options?: { strict: boolean },
    ): this;
    maxDate(
      maxName?: string,
      message?: Message<{ max: Date }>,
      defaultMax?: Date,
      options?: { strict: boolean },
    ): this;
    minYear(
      minName?: string,
      message?: Message<{ min: number }>,
      defaultMin?: number,
      options?: { strict: boolean },
    ): this;
    maxYear(
      maxName?: string,
      message?: Message<{ max: number }>,
      defaultMax?: number,
      options?: { strict: boolean },
    ): this;
  }
}

export const dateString = () => yup.string().isDateString();
