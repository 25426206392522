import React, { useState } from 'react';
import {
  Control,
  FieldPath,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormTrigger,
} from 'react-hook-form';
import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  NumeroPieceValuesRow,
  PrejudiceType,
} from '../../../../types/prejudice.type';
import { Procedure } from '../../../../types/procedure.type';
import { Dommage } from 'src/types/dommage.type';
import { Victime } from 'src/types/victime.type';
import { TableRevenuActiviteAnnuelDeReference } from './TableRevenuActiviteAnnuelDeReference';
import { Box, Grid, Stack } from '@mui/material';
import TablePerteDeGainsProfessionnels from './TablePerteDeGainsProfessionnels';
import { TableIndemnitesJournalieresPercuesPendantLaPeriodeDArret } from './TableIndemnitesJournalieresPercuesPendantLaPeriodeDArret';
import { TableRecapitulatif } from './TableRecapitulatif';
import { useTranslation } from 'react-i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Chiffrage } from '../Chiffrage';
import { StepContainer } from './StepContainer';
import { StepManagerHeader } from './StepManagerHeader';
import { SavePrejudiceButton } from '../../SavePrejudiceButton';
import { User } from 'src/types/auth.type';
import { ReadFieldForm } from 'src/components/forms/ReadFieldForm';
//TODO Put share props in one interface
interface PGPAProps {
  setAdditionalTitle: (title: string | undefined) => void;
  procedure: Procedure;
  dommage: Dommage;
  victime: Victime;
  monetaryErosions: MonetaryErosion[];
  dateConsolidation?: Date | undefined;
  dateLiquidation?: Date | undefined;
  prejudiceType: PrejudiceType;
  control: Control<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  handleSubmit: UseFormHandleSubmit<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  trigger: UseFormTrigger<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  values?: NewPrejudiceFormPerteGainProfessionnelsActuel;
  getValues: UseFormGetValues<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  onPrevious?: () => void;
  onClose: () => void;
  isPGPF?: boolean;
  tauxIPP: number | null;
  allNumerosPieces: NumeroPieceValuesRow[];
  user: User | null;
}

interface PGPFProps {
  setAdditionalTitle: (title: string | undefined) => void;
  procedure: Procedure;
  victime: Victime;
  dommage: Dommage | undefined;
  monetaryErosions: MonetaryErosion[];
  dateConsolidation?: Date | undefined;
  dateLiquidation?: Date | undefined;
  prejudiceType: PrejudiceType;
  control:
    | Control<NewPrejudiceFormPerteGainProfessionnelsFuturs>
    | Control<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  handleSubmit:
    | UseFormHandleSubmit<NewPrejudiceFormPerteGainProfessionnelsFuturs>
    | UseFormHandleSubmit<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  trigger:
    | UseFormTrigger<NewPrejudiceFormPerteGainProfessionnelsFuturs>
    | UseFormTrigger<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  values?:
    | NewPrejudiceFormPerteGainProfessionnelsFuturs
    | NewPrejudiceFormPerteGainProfessionnelsActuel;
  getValues:
    | UseFormGetValues<NewPrejudiceFormPerteGainProfessionnelsFuturs>
    | UseFormGetValues<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  onPrevious?: () => void;
  onClose: () => void;
  isPGPF?: boolean;
  tauxIPP: number | null;
  allNumerosPieces: NumeroPieceValuesRow[];
  user: User | null;
}

type StepType =
  | 'REVENU_ACTIVITE_ANNUEL_DE_REFERENCE'
  | 'PERTE_DE_GAIN_PROFESSIONNEL'
  | 'INDEMNITES_JOURNALIERES_PERCUES'
  | 'RECAPITULATIF';

const steps: Partial<
  Record<StepType, FieldPath<NewPrejudiceFormPerteGainProfessionnelsActuel>[]>
> = {
  REVENU_ACTIVITE_ANNUEL_DE_REFERENCE: [
    'revenuActiviteAnnuelDeReference',
    'perteDeChanceDeGainProfessionnel',
  ],
  PERTE_DE_GAIN_PROFESSIONNEL: ['perteDeGainsProfessionnels'],
  INDEMNITES_JOURNALIERES_PERCUES: [
    'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
  ],
};

export const NewStepManager: React.FC<PGPAProps | PGPFProps> = ({
  victime,
  control,
  getValues,
  values,
  onPrevious,
  trigger,
  procedure,
  isPGPF = false,
  dateConsolidation,
  dateLiquidation,
  prejudiceType,
  monetaryErosions,
  tauxIPP,
  allNumerosPieces,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<StepType>(
    values ? 'RECAPITULATIF' : 'REVENU_ACTIVITE_ANNUEL_DE_REFERENCE',
  );
  const [isError, setIsError] = useState(false);

  const changeStepAndValidate = async (
    step: StepType,
    fieldsToValidate: FieldPath<NewPrejudiceFormPerteGainProfessionnelsActuel>[],
  ) => {
    if (await trigger(fieldsToValidate as any)) {
      setStep(step);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const handleStep = async (newStep: StepType) => {
    const fieldsToValidate =
      Object.values(steps)[Object.keys(steps).indexOf(step)] ?? [];
    const moveIndex: number =
      Object.keys(steps).indexOf(step) - Object.keys(steps).indexOf(newStep);
    if (moveIndex < 0) {
      await changeStepAndValidate(newStep, fieldsToValidate);
    } else {
      setStep(newStep);
      setIsError(false);
    }
  };

  const getStep = () => {
    switch (step) {
      case 'REVENU_ACTIVITE_ANNUEL_DE_REFERENCE':
        return (
          <StepContainer
            onClickPreviousStep={
              !values
                ? onPrevious
                : () =>
                    changeStepAndValidate('RECAPITULATIF', [
                      'revenuActiviteAnnuelDeReference',
                      'perteDeChanceDeGainProfessionnel',
                    ])
            }
            onClickNextStep={() => {
              const revenuActiviteAnnuelDeReference =
                getValues()?.revenuActiviteAnnuelDeReference.total;
              if (
                (revenuActiviteAnnuelDeReference &&
                  revenuActiviteAnnuelDeReference !== 0) ||
                isPGPF
              ) {
                changeStepAndValidate('PERTE_DE_GAIN_PROFESSIONNEL', [
                  'revenuActiviteAnnuelDeReference',
                  'perteDeChanceDeGainProfessionnel',
                ]);
              } else {
                changeStepAndValidate('INDEMNITES_JOURNALIERES_PERCUES', [
                  'revenuActiviteAnnuelDeReference',
                  'perteDeChanceDeGainProfessionnel',
                ]);
              }
            }}
          >
            <TableRevenuActiviteAnnuelDeReference
              control={control as any}
              isError={isError}
              dateLiquidation={dateLiquidation}
              monetaryErosions={monetaryErosions}
              allNumerosPieces={allNumerosPieces}
            />
          </StepContainer>
        );
      case 'PERTE_DE_GAIN_PROFESSIONNEL':
        return (
          <StepContainer
            onClickPreviousStep={() =>
              setStep('REVENU_ACTIVITE_ANNUEL_DE_REFERENCE')
            }
            onClickNextStep={() =>
              changeStepAndValidate('INDEMNITES_JOURNALIERES_PERCUES', [
                'perteDeGainsProfessionnels',
              ])
            }
          >
            <TablePerteDeGainsProfessionnels
              dateConsolidation={dateConsolidation}
              dateLiquidation={dateLiquidation}
              dateAccident={
                victime?.dateAccident
                  ? new Date(victime.dateAccident)
                  : undefined
              }
              control={control as any}
              isError={isError}
              isPGPF={isPGPF}
              monetaryErosions={monetaryErosions}
              prejudiceType={prejudiceType}
              procedure={procedure}
              victime={victime}
              allNumerosPieces={allNumerosPieces}
              formType="REVENUS"
              getValues={getValues}
            />
          </StepContainer>
        );
      case 'INDEMNITES_JOURNALIERES_PERCUES':
        return (
          <StepContainer
            onClickPreviousStep={() => {
              const revenuActiviteAnnuelDeReference =
                getValues()?.revenuActiviteAnnuelDeReference.total;
              if (
                (revenuActiviteAnnuelDeReference &&
                  revenuActiviteAnnuelDeReference !== 0) ||
                isPGPF
              ) {
                changeStepAndValidate('PERTE_DE_GAIN_PROFESSIONNEL', [
                  'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
                ]);
              } else {
                changeStepAndValidate('REVENU_ACTIVITE_ANNUEL_DE_REFERENCE', [
                  'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
                ]);
              }
            }}
            onClickNextStep={() =>
              changeStepAndValidate('RECAPITULATIF', [
                'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
              ])
            }
          >
            <TableIndemnitesJournalieresPercuesPendantLaPeriodeDArret
              control={control as any}
              procedure={procedure}
              isPGPF={isPGPF}
              tauxIPP={tauxIPP}
              prejudiceType={prejudiceType}
              victime={victime}
              allNumerosPieces={allNumerosPieces}
              dateConsolidation={dateConsolidation}
              dateLiquidation={dateLiquidation}
              revenuName="revenuActiviteAnnuelDeReference.total"
              formType="REVENUS"
            />
          </StepContainer>
        );
      default:
        return null;
    }
  };
  return (
    <Stack direction="column" spacing={4} flex={1}>
      {step !== 'RECAPITULATIF' && (
        <StepManagerHeader
          activeStep={Object.keys(steps).indexOf(step)}
          steps={Object.keys(steps).map((label) => ({
            label: label,
            title: t(
              `prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.stepper.${label}${
                label === 'INDEMNITES_JOURNALIERES_PERCUES'
                  ? isPGPF
                    ? '_PGPF'
                    : '_PGPA'
                  : ''
              }`,
            ),
            disabled:
              !(
                (getValues()?.revenuActiviteAnnuelDeReference.total &&
                  getValues()?.revenuActiviteAnnuelDeReference.total !== 0) ||
                isPGPF
              ) && label === 'PERTE_DE_GAIN_PROFESSIONNEL',
            onClick: () => handleStep(label as StepType),
          }))}
        />
      )}
      {getStep()}
      <Box sx={{ display: step === 'RECAPITULATIF' ? undefined : 'none' }}>
        <StepContainer>
          <ReadFieldForm
            control={control as any}
            name="difference"
            render={(total) => <>{total}</>}
          />
          <TableRecapitulatif
            onClickModify={() =>
              changeStepAndValidate('REVENU_ACTIVITE_ANNUEL_DE_REFERENCE', [])
            }
            isPGPF={isPGPF}
            procedure={procedure}
            control={control as any}
            tauxIPP={tauxIPP}
          />
        </StepContainer>
      </Box>
      {step === 'RECAPITULATIF' && (
        <Grid container>
          <Grid item xs={4}>
            <Chiffrage
              control={
                control as Control<NewPrejudiceFormPerteGainProfessionnelsActuel>
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SavePrejudiceButton victime={victime} />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      )}
    </Stack>
  );
};
