import { Alert } from '@mui/material';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import React from 'react';

export const displayNotification = (
  closeNotification: () => void,
  message: string,
  variant: boolean,
) => {
  return (
    <Alert
      onClose={() => closeNotification()}
      severity={variant ? 'success' : 'error'}
    >
      {message}
    </Alert>
  );
};

export const handleNotification = async (
  action: Promise<any>,
  successMessage: string,
  errorMessage: string,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey,
  closeSnackbar: (key?: SnackbarKey | undefined) => void,
) => {
  return await action
    .then((res) => {
      const message = res.error === undefined ? successMessage : errorMessage;

      const key = enqueueSnackbar(message, {
        content: displayNotification(
          () => closeSnackbar(key),
          message,
          res.error == undefined,
        ),
      });
    })
    .catch(() => {
      const message = errorMessage;
      const key = enqueueSnackbar(message, {
        content: displayNotification(() => closeSnackbar(key), message, false),
      });
    });
};
