import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { LayoutTable } from '../../styled';
import { fCurrency } from '../../../helpers/formatNumber';
import { CalculsBox } from '../../basic/CalculsBox';
import {
  IndemniteRepartieEchus,
  TropPercuEchus,
} from 'src/constants/calculs/type';
import { getTropPercuString } from 'src/helpers/prejudices/pgpfReliquat';
import { useTranslation } from 'react-i18next';
import { fPartResponsabilite } from 'src/helpers/formatValues';

interface Props {
  indemniteGlobaleARepartir: Omit<
    IndemniteRepartieEchus,
    'indemniteTiersPayeurs'
  > &
    Partial<Pick<IndemniteRepartieEchus, 'indemniteTiersPayeurs'>>;
  caption?: string;
  tropPercu?: TropPercuEchus;
  partResponsabilite: number;
}

export const TotalIndemniteGlobaleARepartirEchus: React.FC<Props> = ({
  indemniteGlobaleARepartir,
  caption,
  tropPercu,
  partResponsabilite,
}) => {
  const { t } = useTranslation();
  return (
    <CalculsBox>
      <LayoutTable size="small">
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              {t(
                'prejudice.indemniteGlobaleARepartir.echus.table.indemniteGlobaleBeforePartResponsabilite.tableRowHeader',
              )}
            </TableCell>
            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
              {fCurrency(
                indemniteGlobaleARepartir.indemniteGlobaleARepartir
                  .beforePartResponsabilite,
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              {t(
                'prejudice.indemniteGlobaleARepartir.echus.table.indemniteGlobale.tableRowHeaderPartResponsabilite',
                {
                  partResponsabilite: fPartResponsabilite(
                    partResponsabilite * 100,
                  ),
                },
              )}
            </TableCell>
            <TableCell colSpan={2} align="right" sx={{ whiteSpace: 'nowrap' }}>
              {fCurrency(
                indemniteGlobaleARepartir.indemniteGlobaleARepartir.solde,
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
              {t(
                'prejudice.indemniteGlobaleARepartir.echus.table.indemniteVictime.tableRowHeader',
              )}
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
            >
              {tropPercu?.tropPercu
                ? getTropPercuString(
                    indemniteGlobaleARepartir.indemniteVictime.arreragesEchus
                      .debit,
                    tropPercu.tropPercu,
                  )
                : fCurrency(
                    indemniteGlobaleARepartir.indemniteVictime.arreragesEchus
                      .debit,
                  )}
            </TableCell>
          </TableRow>
          {indemniteGlobaleARepartir.indemniteTiersPayeurs ? (
            <>
              <TableRow>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                  {t(
                    'prejudice.indemniteGlobaleARepartir.echus.table.indemniteTiersPayeurs.tableRowHeader',
                  )}
                </TableCell>
                <TableCell
                  colSpan={2}
                  align="right"
                  sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                >
                  {fCurrency(
                    indemniteGlobaleARepartir.indemniteTiersPayeurs
                      .arreragesEchus.debit,
                  )}
                </TableCell>
              </TableRow>
              {indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesEchus.parTiersPayeur?.map(
                ({ tiersPayeur, montant }, index) => (
                  <TableRow key={index}>
                    <TableCell />
                    <TableCell>{tiersPayeur}</TableCell>
                    <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                      {fCurrency(montant)}
                    </TableCell>
                  </TableRow>
                ),
              )}
            </>
          ) : null}
          {caption && (
            <TableRow>
              <TableCell
                colSpan={4}
                sx={{ paddingTop: '40px', maxWidth: '400px' }}
              >
                {caption}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </LayoutTable>
    </CalculsBox>
  );
};
