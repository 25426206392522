import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { LayoutTable, NormalTable } from 'src/components/styled';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NumeroPieceValuesRow,
  PerteDeChanceDeGainProfessionnelRow,
  unites,
} from 'src/types/prejudice.type';
import { defaultPerteDeChanceDeGainProfessionnelRow } from '../../prejudiceFormTypes/NewFormPerteGainsProfessionnelsActuel';
import { TooltipTableHeader } from '../../TooltipTableHeader';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';

interface Props {
  control: Control<NewPrejudiceFormPerteGainProfessionnelsActuel>;
  allNumerosPieces: NumeroPieceValuesRow[];
}

export const TotalPerteGainsProfessionnelsActuel: React.FC<Props> = ({
  control,
  allNumerosPieces,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'perteDeChanceDeGainProfessionnel.rows',
  });

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as PerteDeChanceDeGainProfessionnelRow);
    handleMenuClose();
  };
  const onAppendRow = () => {
    append(defaultPerteDeChanceDeGainProfessionnelRow);
  };

  return (
    <>
      <TableContainer>
        <NormalTable>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: '50px' }}>
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell align="center" sx={{ width: '50px' }}>
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.rows.annee.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.rows.indemnite.columnHeader',
                )}
              </TableCell>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.rows.commentaires.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell align="center">
                  <FormNumeroPieceDialog
                    control={control}
                    name={`perteDeChanceDeGainProfessionnel.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextFieldForm
                    control={control}
                    name={`perteDeChanceDeGainProfessionnel.rows.${index}.annee`}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: {
                          scale: 0,
                          min: 1900,
                          max: 2100,
                          thousandsSeparator: '',
                        },
                      } as IMaskNumberProps,
                    }}
                    fullWidth
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: '350px' }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <TextFieldForm
                      control={control}
                      name={`perteDeChanceDeGainProfessionnel.rows.${index}.indemnite`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: { scale: 2 },
                          suffix: '€',
                        } as IMaskNumberProps,
                      }}
                    />
                    <SelectFieldForm
                      control={control}
                      name={`perteDeChanceDeGainProfessionnel.rows.${index}.unite`}
                      options={unites.map((unite) => ({
                        label: t(`prejudice.fields.unite.optionsPar.${unite}`),
                        value: unite,
                      }))}
                      sx={{ width: '130px' }}
                    />
                  </Stack>
                </TableCell>
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`perteDeChanceDeGainProfessionnel.rows.${index}.commentaires`}
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={handleMenuOpen(index)}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuIndex === index}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleDelete(index)}>
                      {t('forms.table.deleteRow', { index: index + 1 })}
                    </MenuItem>
                    <MenuItem onClick={() => duplicateRow(index)}>
                      {t('forms.table.duplicateRow', { index: index + 1 })}
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        paddingTop={2}
      >
        <Button variant="contained" onClick={onAppendRow}>
          {t('forms.table.addRow')}
        </Button>
      </Stack>
      <Stack alignItems="end" padding={2}>
        <CalculsBox>
          <LayoutTable>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.totalIndemnite.columnHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <ComputedTextFieldForm
                    control={control}
                    name="perteDeChanceDeGainProfessionnel.totalIndemnite"
                    watchFields={['perteDeChanceDeGainProfessionnel.rows']}
                    compute={([rows]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getTotalIndemnitePerteDeChanceDeGainProfessionnels(
                        rows,
                      )
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <TooltipTableHeader
                    tooltipTitle={t(
                      'prejudice.perteDeChance.fields.coefficientPerteDeChance.headerTooltip',
                    )}
                    headerLabel={t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.coefficientPerteDeChance.columnHeader',
                    )}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextFieldForm
                    control={control}
                    name="perteDeChanceDeGainProfessionnel.coefficientPerteDeChance"
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2, min: 0, max: 100 },
                        suffix: '%',
                      } as IMaskNumberProps,
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  {t(
                    'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.perteDeChance.columnHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <ComputedTextFieldForm
                    control={control}
                    name="perteDeChanceDeGainProfessionnel.perteDeChance"
                    watchFields={[
                      'perteDeChanceDeGainProfessionnel.totalIndemnite',
                      'perteDeChanceDeGainProfessionnel.coefficientPerteDeChance',
                    ]}
                    compute={([totalIndemnite, coefficientPerteDeChance]) =>
                      CalculsFormNewPerteGainsProfessionnelsActuels.getPerteDeChanceDeGainProfessionnelsPerteDeChance(
                        {
                          totalIndemnite,
                          coefficientPerteDeChance,
                        },
                      )
                    }
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                    editedFieldsName="editedFields"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </LayoutTable>
        </CalculsBox>
      </Stack>
    </>
  );
};
