import { Paragraph, Table, TableOfContents } from 'docx';
import {
  CalculsFormPerteGainsProfessionnelsActuel,
  CalculsFormPerteGainsProfessionnelsFuturs,
} from 'src/constants/calculs';
import { fDateLong } from 'src/helpers/formatTime';
import { Bareme } from 'src/types/bareme.type';
import { OldPrejudiceFormPerteGainProfessionnelsFuturs } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../../docxFunctions';
import { prejudiceNotesPrint } from '../../prejudiceNotesPrint';
import { tableSituationPrint } from './tableSituationPrint';
import { Victime } from 'src/types/victime.type';
import { totalCapitalisationPrint } from '../../totalCapitalisationPrint';
import { situationsPrint } from './situationsPrint';
import { debitSoldeAEchoirPrint } from '../../debitSoldeAEchoirPrint';
import i18next from 'i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { rentesPrint } from '../../rentesPrint';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';

export const perteGainsProfessionnelsFutursPrint = (
  victime: Victime,
  procedure: Procedure,
  formData: OldPrejudiceFormPerteGainProfessionnelsFuturs,
  baremes: Bareme[],
  monetaryErosions: MonetaryErosion[],
): (Paragraph | Table | TableOfContents)[] => {
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const datesPrint = new Paragraph({
    children: [
      ...(formData.dateDebut
        ? getTextRun({
            text: i18next.t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.dateDebut.print.value',
              {
                date: fDateLong(formData.dateDebut),
              },
            ),
          })
        : []),
      ...(formData.dateFin
        ? getTextRun({
            break: formData.dateDebut ? 1 : 0,
            text: i18next.t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.dateFin.print.value',
              {
                date: fDateLong(formData.dateFin),
              },
            ),
          })
        : []),
    ],
  });
  const totaux = CalculsFormPerteGainsProfessionnelsActuel.getTotauxSituations(
    formData.situations,
    procedure.partResponsabilite,
  );
  const indemniteGlobaleARepartir =
    CalculsFormPerteGainsProfessionnelsFuturs.getIndemnitesRepartie({
      situations: formData.situations,
      victimeTotalCapitalise: formData.victimeTotalCapitalise,
      tiersPayeursTotalCapitalise: formData.tiersPayeursTotalCapitalise,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      capitalisationTiersPayeurs: formData.capitalisationTiersPayeurs,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });

  const tableSituation = tableSituationPrint(
    formData.situations,
    totaux,
    procedure.partResponsabilite,
    true,
  );

  const debitSoldeTable = debitSoldeAEchoirPrint({
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
    displayTropPercu: true,
    PGPFReliquat: indemniteGlobaleARepartir.PGPFReliquat,
  });
  const bareme = baremes.find(
    (bareme) => bareme._id === formData.baremeCapitalisation,
  );
  const tableCapitalisation = totalCapitalisationPrint({
    title: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.print.title',
    ),
    victime,
    procedure,
    titleVictime: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.title',
    ),
    titleTiersPayeurs: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.tiersPayeurs.title',
    ),
    titleTotal: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.total.title',
    ),
    victimeSommeACapitaliser: {
      label: i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.sommeACapitaliser.title',
      ),
      value: formData.victimeSommeACapitaliser,
    },
    victimeCoefficientCapitalisation: {
      value: formData.victimeCoefficientCapitalisation,
    },
    bareme: bareme && {
      value: bareme,
    },
    tiersPayeursTotalCapitalise: {
      value: formData.tiersPayeursTotalCapitalise,
    },
    victimeTotalCapitalise: {
      value: formData.victimeTotalCapitalise,
    },
    ageDernierArrerage: {
      value: formData.ageDernierArrerage,
    },
    isLastArrerageViager: {
      value: formData.isLastArrerageViager,
    },

    tiersPayeurs: formData.capitalisationTiersPayeurs.parTiersPayeur,
    totalCapitalise: {
      value:
        formData.victimeTotalCapitalise - formData.tiersPayeursTotalCapitalise,
      label: i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.total.montantCapitalise.title',
      ),
    },
    allTiersPayeurs: procedure.tiersPayeurs,
  });
  const rentes =
    formData.rentes?.montant || formData.rentes?.montant === 0
      ? rentesPrint({ montant: formData.rentes.montant })
      : [];

  const situations = situationsPrint({
    situations: formData.situations,
    monetaryErosions,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : null,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });

  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
  });
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.dateDebut.print.periodeTitle',
        ),
        bold: true,
      }),
    }),
    datesPrint,
    getEmptyLine(),
    ...situations,
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.print.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    tableSituation,
    getEmptyLine(),
    ...(!formData.isRentesOption && !shouldNotDisplayCapitalisation
      ? [...tableCapitalisation, getEmptyLine()]
      : []),
    ...debitSoldeTable,
    ...(formData.isRentesOption === true && !shouldNotDisplayCapitalisation
      ? [getEmptyLine(), ...rentes]
      : []),
  ];
};
