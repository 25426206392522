import React from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { Typography, Stack, TypographyProps, StackProps } from '@mui/material';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { Bareme } from 'src/types/bareme.type';

interface Props<
  TFieldValues extends FieldValues,
  TBaremeName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  baremeName: TBaremeName;
  baremes: Bareme[];
  getSourceCommentFunction?: (bareme: Bareme) => string;
  typographyProps?: TypographyProps;
  displayShortenedSourceCommentUrl?: boolean;
  stackProps?: StackProps;
}

export const TypeBareme = <
  TFieldValues extends FieldValues,
  TBaremeName extends FieldPath<TFieldValues>,
>({
  control,
  baremes,
  baremeName,
  getSourceCommentFunction,
  typographyProps,
  displayShortenedSourceCommentUrl,
  stackProps,
}: Props<TFieldValues, TBaremeName>) => {
  return (
    <ComputedPropsForm
      control={control}
      compute={([baremeCapitalisation]) => {
        const bareme = baremes.find(
          (bareme) => bareme._id === baremeCapitalisation,
        );
        if (!bareme) {
          return {
            props: {
              sourceComment: '',
            },
          };
        }
        return {
          props: {
            sourceComment: getSourceCommentFunction
              ? getSourceCommentFunction(bareme)
              : bareme?.sourceComment,
          },
        };
      }}
      watchFields={[baremeName]}
      render={({ sourceComment }) => (
        <Stack
          {...stackProps}
          sx={{
            backgroundColor: 'white',
            padding: 2,
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            ...stackProps?.sx,
          }}
        >
          {sourceComment?.includes('https://') ? (
            <Typography
              {...typographyProps}
              align="left"
              sx={{
                fontWeight: 'bold',
                cursor: 'pointer',
                wordWrap: 'break-word',
                ...typographyProps?.sx,
              }}
              onClick={() =>
                window.open(sourceComment, '_blank', 'width=1000,height=700')
              }
            >
              {displayShortenedSourceCommentUrl
                ? new URL(sourceComment).hostname
                : sourceComment}
            </Typography>
          ) : (
            <Typography
              {...typographyProps}
              align="left"
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                ...typographyProps?.sx,
              }}
            >
              {sourceComment}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};
