import React from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { Typography } from '@mui/material';
import { CalculsGlobal } from 'src/constants/calculs';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { fCurrency } from 'src/helpers/formatNumber';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';

interface Props<
  TFieldValues extends FieldValues,
  TSommeACapitaliserName extends FieldPath<TFieldValues>,
  TCoefficientName extends FieldPath<TFieldValues>,
  TMontantCapitaliseName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  sommeACapitaliserName: TSommeACapitaliserName;
  montantCapitaliseName: TMontantCapitaliseName;
  coefficientName: TCoefficientName;
  editedFieldsName: TEditedFieldsName;
  isEditable?: boolean;
}

export const MontantCapitalise = <
  TFieldValues extends FieldValues,
  TSommeACapitaliserName extends FieldPath<TFieldValues>,
  TCoefficientName extends FieldPath<TFieldValues>,
  TMontantCapitaliseName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
>({
  control,
  sommeACapitaliserName,
  montantCapitaliseName,
  coefficientName,
  editedFieldsName,
  isEditable = false,
}: Props<
  TFieldValues,
  TSommeACapitaliserName,
  TCoefficientName,
  TMontantCapitaliseName,
  TEditedFieldsName
>) => {
  return isEditable ? (
    <ComputedTextFieldForm
      control={control}
      name={montantCapitaliseName}
      watchFields={[sommeACapitaliserName, coefficientName]}
      compute={([sommeACapitaliser, coefficient]) =>
        CalculsGlobal.getMontantCapitalise(sommeACapitaliser, coefficient)
      }
      InputProps={{
        inputComponent: MaskNumber as any,
        inputProps: {
          numberMask: { scale: 2 },
          suffix: '€',
        } as IMaskNumberProps,
      }}
      editedFieldsName={editedFieldsName}
    />
  ) : (
    <ComputedReadFieldForm
      control={control}
      name={montantCapitaliseName}
      watchFields={[sommeACapitaliserName, coefficientName]}
      compute={([sommeACapitaliser, coefficient]) =>
        CalculsGlobal.getMontantCapitalise(
          sommeACapitaliser,
          coefficient,
        ) as any
      }
      render={(totalCapitalise) => (
        <Typography fontWeight="bold">{fCurrency(totalCapitalise)}</Typography>
      )}
    />
  );
};
