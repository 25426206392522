import { Paragraph, Table, TableOfContents } from 'docx';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { getEmptyLine, getParagraph } from '../../../docxFunctions';
import i18next from 'i18next';
import { Victime } from 'src/types/victime.type';
import { fCurrency } from 'src/helpers/formatNumber';
import { fAge, fDate, fYears } from 'src/helpers/formatTime';
import { Procedure } from 'src/types/procedure.type';
import { CalculsGlobal } from 'src/constants/calculs';
import { partialRecapitulatifPrint } from '../../../prejudicesPrints/prejudices/perteRevenusProcheVictimeDecedeePrint';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { fCoefficientCapitalisation } from 'src/helpers/formatValues';
import { displayFormula } from '../../formula';

export const prejudiceViagerFoyerWrittenPrint = ({
  formData,
  victime,
  procedure,
  victimesIndirectes,
}: {
  formData: PrejudiceFormPerteRevenusProche;
  victime: Victime;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] => {
  const anneesEntreDecesEtLiquidation =
    victime.dateDeces && procedure.dateLiquidation
      ? CalculsGlobal.getYears(
          new Date(victime.dateDeces),
          new Date(procedure.dateLiquidation),
        )
      : 0;
  const victimeAgeLiquidation =
    victime.dateNaissance && procedure.dateLiquidation
      ? CalculsGlobal.getAgeOrZero(
          new Date(victime.dateNaissance),
          new Date(procedure.dateLiquidation),
        )
      : { age: 0, isExactlyZero: true };
  const conjoint = victimesIndirectes.find(
    (victimeIndirecte) => victimeIndirecte.lienVictime === 'conjointConcubin',
  );

  const conjointAgeLiquidation =
    conjoint?.dateNaissance && procedure.dateLiquidation
      ? CalculsGlobal.getAgeOrZero(
          new Date(conjoint.dateNaissance),
          new Date(procedure.dateLiquidation),
        )
      : { age: 0, isExactlyZero: true };
  const foyerCoefficientCapitalisation: number | null | undefined =
    (formData.victime.coefficientCapitalisation ||
      formData.victime.coefficientCapitalisation === 0) &&
    (formData.conjoint.coefficientCapitalisation ||
      formData.conjoint.coefficientCapitalisation === 0)
      ? CalculsGlobal.min([
          formData.victime.coefficientCapitalisation || 0,
          formData.conjoint.coefficientCapitalisation || 0,
        ])
      : formData.victime.coefficientCapitalisation ||
          formData.victime.coefficientCapitalisation === 0
        ? formData.victime.coefficientCapitalisation
        : formData.conjoint.coefficientCapitalisation;
  return [
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 0,
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.prejudiceViagerFoyer.title',
      ),
    }),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 1,
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.prejudiceViagerFoyer.entreDecesEtLiquidation.text',
        {
          dateDeces: victime.dateDeces
            ? fDate(new Date(victime.dateDeces))
            : '',
          dateLiquidation: procedure.dateLiquidation
            ? fDate(new Date(procedure.dateLiquidation))
            : '',
          anneesEntreDecesEtLiquidation: fYears(anneesEntreDecesEtLiquidation),
          prejudiceViagerFoyer: fCurrency(
            formData.foyer.prejudiceViagerEntreDecesEtLiquidation,
          ),
          formula: displayFormula({
            formula: i18next.t(
              'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViagerEntreDecesEtLiquidation.formula',
              {
                anneesEntreDecesEtLiquidation: fYears(
                  anneesEntreDecesEtLiquidation,
                ),
                perteAnnuelle: fCurrency(formData.foyer.perteAnnuelle, 2),
              },
            ),
            options: {
              withParentheses: true,
            },
            editedFieldsParameters: {
              formData,
              fieldName: 'foyer.prejudiceViagerEntreDecesEtLiquidation',
            },
          }),
        },
      ),
    }),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 1,
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.prejudiceViagerFoyer.apresLiquidation.firstPart',
        {
          dateLiquidation: procedure.dateLiquidation
            ? fDate(new Date(procedure.dateLiquidation))
            : '',
          prejudiceViagerFoyer: fCurrency(
            formData.foyer.prejudiceViagerApresLiquidation,
          ),
          formula: displayFormula({
            formula: i18next.t(
              'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViagerApresLiquidation.formula',
              {
                perteAnnuelle: fCurrency(formData.foyer.perteAnnuelle, 2),
                coefficientCapitalisation: fCoefficientCapitalisation(
                  CalculsGlobal.min([
                    formData.victime.coefficientCapitalisation || 0,
                    formData.conjoint.coefficientCapitalisation || 0,
                  ]),
                ),
              },
            ),
            options: {
              withParentheses: true,
            },
            editedFieldsParameters: {
              formData,
              fieldName: 'foyer.prejudiceViagerApresLiquidation',
            },
          }),
        },
      ),
    }),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.prejudiceViagerFoyer.apresLiquidation.secondPart',
        {
          victimeSexe: i18next.t(
            `victime.fields.sexe.options.${victime.sexe}.adjectiveLabel`,
          ),
          victimeAgeLiquidation: fAge(victimeAgeLiquidation),
          victimeCoefficientCapitalisation:
            formData.victime.coefficientCapitalisation ||
            formData.victime.coefficientCapitalisation === 0
              ? fCoefficientCapitalisation(
                  formData.victime.coefficientCapitalisation,
                )
              : '',
          conjointSexe: i18next.t(
            `victime.fields.sexe.options.${conjoint?.sexe}.adjectiveLabel`,
          ),
          conjointAgeLiquidation: fAge(conjointAgeLiquidation),
          conjointCoefficientCapitalisation:
            formData.conjoint.coefficientCapitalisation ||
            formData.conjoint.coefficientCapitalisation === 0
              ? fCoefficientCapitalisation(
                  formData.conjoint.coefficientCapitalisation,
                )
              : '',
          coefficientCapitalisation:
            foyerCoefficientCapitalisation ||
            foyerCoefficientCapitalisation === 0
              ? fCoefficientCapitalisation(foyerCoefficientCapitalisation)
              : '',
          context: conjoint ? undefined : 'noConjoint',
        },
      ),
    ),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.prejudiceViagerFoyer.total.text',
        {
          prejudiceViagerFoyer: fCurrency(formData.foyer.prejudiceViager),
          formula:
            formData.foyer.prejudiceViagerEntreDecesEtLiquidation ||
            formData.foyer.prejudiceViagerApresLiquidation
              ? displayFormula({
                  formula: i18next.t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViager.formula',
                    {
                      prejudiceViagerEntreDecesEtLiquidation: fCurrency(
                        formData.foyer.prejudiceViagerEntreDecesEtLiquidation,
                      ),
                      prejudiceViagerApresLiquidation: fCurrency(
                        formData.foyer.prejudiceViagerApresLiquidation,
                      ),
                    },
                  ),
                  options: {
                    withParentheses: true,
                  },
                  editedFieldsParameters: {
                    formData,
                    fieldName: 'foyer.prejudiceViager',
                  },
                })
              : '',
        },
      ),
    ),
    getEmptyLine(),
    partialRecapitulatifPrint({
      title: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.foyer.title',
      ),
      echus: formData.foyer.prejudiceViagerEntreDecesEtLiquidation,
      aEchoir: formData.foyer.prejudiceViagerApresLiquidation,
      total:
        (formData.foyer.prejudiceViagerEntreDecesEtLiquidation ?? 0) +
        (formData.foyer.prejudiceViagerApresLiquidation ?? 0),
    }),
  ];
};
