import { Close } from '@mui/icons-material';
import {
  Box,
  ListItemText,
  List,
  ListItem,
  IconButton,
  Drawer,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { conditionsLink } from 'src/constants/global';
import { isUserOrganizationAdmin } from 'src/helpers/organization';
import { isOrganizationSubscriptionValid } from 'src/helpers/subscription';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { User } from 'src/types/auth.type';

interface Step {
  name: string;
  route: string | ((organizationId: string) => string);
  labelTranslationKey: string;
}

const steps: Step[] = [
  {
    name: 'settings',
    route: '/settings',
    labelTranslationKey: 'common.settings',
  },
  {
    name: 'organizationSettings',
    route: (organizationId) => `/organization/${organizationId}/settings`,
    labelTranslationKey: 'common.organizationParameters',
  },
  {
    name: 'invitations',
    route: '/invitations',
    labelTranslationKey: 'common.invitations',
  },
];

interface Props {
  open: boolean;
  user: User | null;
  noSettingsLink?: boolean;
  isOrganizationSettings?: true;
  onClose: () => void;
  onClickLogout: () => void;
}

export const SettingsDrawer: React.FC<Props> = ({
  open,
  user,
  noSettingsLink,
  isOrganizationSettings,
  onClose,
  onClickLogout,
}) => {
  const { t } = useTranslation();
  const { organization } = useDisplayedOrganization();
  const organizationHasValidSubscription =
    organization?.subscription &&
    isOrganizationSubscriptionValid(organization.subscription);
  const isOrganizationAdmin = Boolean(
    organization && user && isUserOrganizationAdmin(organization, user),
  );
  const displayedSteps = steps.filter((step) => {
    if (step.name === 'organizationSettings') {
      return isOrganizationAdmin && organizationHasValidSubscription;
    }
    if (step.name === 'invitations') {
      return isOrganizationSettings;
    }
    return true;
  });

  return (
    <Drawer
      variant="temporary"
      open={open}
      PaperProps={{
        sx: {
          width: 1,
          maxWidth: 240,
          display: { xs: 'block' },
          bgcolor: 'background.default',
        },
      }}
      anchor="right"
      onClose={onClose}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ display: { md: 'none' }, alignSelf: 'start' }}
        >
          <Close />
        </IconButton>
        <List
          sx={{
            alignItems: 'center',
            margin: '16px 24px 16px 24px',
          }}
        >
          {noSettingsLink
            ? null
            : displayedSteps.map((step) => {
                return (
                  <Link
                    key={step.name}
                    to={
                      typeof step.route === 'string'
                        ? step.route
                        : step.route(organization?._id || '')
                    }
                    style={{ textDecoration: 'none' }}
                    onClick={onClose}
                  >
                    <ListItem
                      sx={(theme) => ({
                        ...theme.typography.body2,
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: `${theme.shape.borderRadius}px`,
                        marginBottom: '16px',
                        transform: 'scale(1)',
                        transition: '0.2s linear',
                        '&:hover': {
                          backgroundColor: theme.palette.secondary.main,
                          transform: 'scale(1.1)',
                          transition: '0.2s linear',
                        },
                        '&:link': {
                          textDecoration: 'none',
                        },
                      })}
                    >
                      <ListItemText
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                          textAlign: 'center',
                          '&:link': {
                            textDecoration: 'none',
                          },
                        })}
                        primary={t(step.labelTranslationKey)}
                      />
                    </ListItem>
                  </Link>
                );
              })}
          {user?.isAdmin && (
            <Link to="/admin" style={{ textDecoration: 'none' }}>
              <ListItem
                sx={(theme) => ({
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: `${theme.shape.borderRadius}px`,
                  marginBottom: '16px',
                  transform: 'scale(1)',
                  transition: '0.2s linear',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    transform: 'scale(1.1)',
                    transition: '0.2s linear',
                  },
                  '&:link': {
                    textDecoration: 'none',
                  },
                  ...theme.typography.body2,
                })}
              >
                <ListItemText
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                    textAlign: 'center',
                    '&:link': {
                      textDecoration: 'none',
                    },
                  })}
                  primary={'Admin'}
                />
              </ListItem>
            </Link>
          )}
          <Box
            component="a"
            href={conditionsLink}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <ListItem
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                borderRadius: `${theme.shape.borderRadius}px`,
                marginBottom: '16px',
                transform: 'scale(1)',
                transition: '0.2s linear',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.main,
                  transform: 'scale(1.1)',
                  transition: '0.2s linear',
                },
                '&:link': {
                  textDecoration: 'none',
                },
                ...theme.typography.body2,
              })}
            >
              <ListItemText
                sx={(theme) => ({
                  color: theme.palette.common.white,
                  textAlign: 'center',
                  '&:link': {
                    textDecoration: 'none',
                  },
                })}
                primary={t('common.conditions')}
              />
            </ListItem>
          </Box>
          <ListItem
            sx={(theme) => ({
              backgroundColor: theme.palette.primary.main,
              borderRadius: `${theme.shape.borderRadius}px`,
              marginBottom: '16px',
              transform: 'scale(1)',
              transition: '0.2s linear',
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                transform: 'scale(1.1)',
                transition: '0.2s linear',
              },
              '&:link': {
                textDecoration: 'none',
              },
              ...theme.typography.body2,
            })}
            onClick={onClickLogout}
          >
            <ListItemText
              sx={(theme) => ({
                color: theme.palette.common.white,
                textAlign: 'center',
                '&:link': {
                  textDecoration: 'none',
                },
              })}
              primary={t('common.logout')}
            />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};
