import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import {
  Subscription,
  SubscriptionQuote,
  UpdateSubscriptionDto,
} from 'src/types/subscription.type';
import { updateSubscriptionInOrganizationSlice } from './organization';

/* Thunks */
export const cancelSubscription = createAsyncThunk(
  'subscriptions/cancel',
  async (organizationId: string, { dispatch }) => {
    const result = await apiRequest<Subscription>(
      'DELETE',
      `/organization/${organizationId}/subscription`,
    );
    dispatch(
      updateSubscriptionInOrganizationSlice({
        organizationId,
        subscription: result,
      }),
    );
  },
);

export const reactivateSubscription = createAsyncThunk(
  'subscriptions/reactivate',
  async (organizationId: string, { dispatch }) => {
    const result = await apiRequest<Subscription>(
      'PUT',
      `/organization/${organizationId}/subscription/reactivate`,
    );
    dispatch(
      updateSubscriptionInOrganizationSlice({
        organizationId,
        subscription: result,
      }),
    );
  },
);

export const askForQuote = createAsyncThunk(
  'subscriptions/askForQuote',
  async ({
    organizationId,
    dto,
  }: {
    organizationId: string;
    dto: SubscriptionQuote;
  }) => {
    await apiRequest<Subscription>(
      'POST',
      `/organization/${organizationId}/subscription/quote`,
      undefined,
      dto,
    );
  },
);

export const updateSubscription = createAsyncThunk(
  'subscriptions/update',
  async (
    { id, dto }: { id: string; dto: UpdateSubscriptionDto },
    { dispatch },
  ) => {
    const result = await apiRequest<Subscription>(
      'PATCH',
      `/subscription/${id}`,
      undefined,
      dto,
    );
    dispatch(
      updateSubscriptionInOrganizationSlice({
        organizationId: id,
        subscription: result,
      }),
    );
  },
);

export const updateOrganizationPaymentMethod = createAsyncThunk(
  'subscriptions/updatePaymentMethod',
  async (organizationId: string) => {
    return await apiRequest(
      'PUT',
      `/organization/${organizationId}/subscription/update-payment-method`,
    );
  },
);

export const previewAddSubscriptionMemberAmount = createAsyncThunk(
  'subscriptions/previewAddSubscriptionMemberAmount',
  async (organizationId: string) => {
    return await apiRequest<number>(
      'GET',
      `/organization/${organizationId}/subscription/preview-add-member-amount`,
    );
  },
);

export const getCurrentBilledAmount = createAsyncThunk(
  'subscriptions/getCurrentBilledAmount',
  async (organizationId: string) => {
    return await apiRequest<number>(
      'GET',
      `/organization/${organizationId}/subscription/current-billed-amount`,
    );
  },
);

/* Slice */
const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    isCancelled: false,
    isSubscriptionFlowLoading: false,
    isLoading: false,
    askedForQuote: {
      isLoading: false,
      error: null,
    },
    error: undefined,
    addMemberAmount: 0,
    currentBilledAmount: 0,
  },
  reducers: {
    setIsSubscriptionFlowLoading: (
      state,
      {
        payload,
      }: {
        payload: boolean;
      },
    ) => {
      state.isSubscriptionFlowLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(cancelSubscription.pending, (state: any) => {
        state.isCancelled = false;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(cancelSubscription.rejected, (state: any, { error }) => {
        state.isLoading = false;
        state.error = error.message || 'error';
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.isCancelled = true;
        state.isLoading = false;
      })
      .addCase(askForQuote.pending, (state: any) => {
        state.askedForQuote.isLoading = true;
        state.askedForQuote.error = null;
      })
      .addCase(askForQuote.rejected, (state: any, { error }) => {
        state.askedForQuote.isLoading = false;
        state.askedForQuote.error = error.message || 'error';
      })
      .addCase(askForQuote.fulfilled, (state) => {
        state.askedForQuote.isLoading = false;
      })
      .addCase(updateSubscription.pending, (state: any) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateSubscription.rejected, (state: any, { error }) => {
        state.isLoading = false;
        state.error = error.message || 'error';
      })
      .addCase(updateSubscription.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(previewAddSubscriptionMemberAmount.pending, (state: any) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        previewAddSubscriptionMemberAmount.rejected,
        (state: any, { error }) => {
          state.isLoading = false;
          state.error = error.message || 'error';
        },
      )
      .addCase(
        previewAddSubscriptionMemberAmount.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          state.addMemberAmount = payload;
        },
      )
      .addCase(getCurrentBilledAmount.fulfilled, (state, { payload }) => {
        state.currentBilledAmount = payload;
      });
  },
});

export const { setIsSubscriptionFlowLoading } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
