import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { getEmptyLine, getParagraph } from 'src/helpers/print/docxFunctions';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs } from 'src/types/prejudice.type';

export const revenusIndemnitesPrint = ({
  revenusIndemnitesTiersPayeurs,
  totalIndemnitesNet,
  totalIndemnitesCsgCrds,
  isPGPF,
}: {
  revenusIndemnitesTiersPayeurs: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[];
  totalIndemnitesNet: number;
  totalIndemnitesCsgCrds: number;
  isPGPF?: boolean;
}): (Table | TableOfContents | Paragraph)[] => [
  simpleHorizontalTablePrint(
    [
      i18next.t('numeroPiece.form.columnHeader'),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneeTiersPayeursIndemnites.tiersPayeur.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneeTiersPayeursIndemnites.netPaye.columnHeader',
      ),
      ...(isPGPF
        ? [
            i18next.t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.situations.revenusIndemnitesTiersPayeurs.csgRds.columnHeader',
            ),
          ]
        : []),
    ],
    [
      ...revenusIndemnitesTiersPayeurs.map(
        ({ tiersPayeur, netPaye, csgRds, numerosPieces }) => ({
          rowLabel: displayNumeroPiece(numerosPieces),
          columns: [
            tiersPayeur || '',
            netPaye || netPaye === 0 ? fCurrency(netPaye) : '',
            ...(isPGPF
              ? [csgRds || csgRds === 0 ? fCurrency(csgRds) : '']
              : []),
          ],
        }),
      ),
    ],
  ),
  getEmptyLine(),
  getParagraph(
    i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.totalIndemnitesNet.print.value',
      { value: fCurrency(totalIndemnitesNet) },
    ),
  ),
  ...(isPGPF
    ? [
        getParagraph(
          i18next.t(
            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.situations.totalIndemnitesCsgRds.value',
            { value: fCurrency(totalIndemnitesCsgCrds) },
          ),
        ),
      ]
    : []),
];
