import React from 'react';
import { Box, styled, Typography, Stack, IconButton } from '@mui/material';
import { Edit, RemoveRedEye } from '@mui/icons-material';

import {
  Prejudice,
  PrejudiceFormBase,
  PrejudiceFormListeProjection,
  PGPFReliquatAndPGPFReliquatActivationStatus,
} from '../../types/prejudice.type';
import { theme } from '../../constants/theme';
import { Procedure } from '../../types/procedure.type';
import { Victime } from '../../types/victime.type';
import {
  FormattedPrejudiceTotal,
  formatPrejudiceTotal,
  getPrejudiceTotal,
} from 'src/helpers/prejudices/total';
import { useTranslation } from 'react-i18next';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { useCanUpdateVictime } from 'src/hooks/store/victime.hook';

/* Styled components */
const GreyRoundedBox = styled(Box)({
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontStyle: 'italic',
});

/* Component */
interface Props {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudice: Prejudice;
  onClickEdit: () => void;
  dateLiquidation: string | null | undefined;
  monetaryErosions: MonetaryErosion[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
}

export const PrejudiceCard: React.FC<Props> = ({
  victime,
  victimesIndirectes,
  procedure,
  prejudice,
  onClickEdit,
  dateLiquidation,
  monetaryErosions,
  PGPFReliquat,
}) => {
  const { t } = useTranslation();
  const displayFormValues = (formValues: FormattedPrejudiceTotal) => {
    return formValues.map(({ fieldName, fieldValue }, index) => (
      <Stack key={index} direction="row" justifyContent="space-between">
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          whiteSpace="break-spaces"
        >
          {fieldName}
        </Typography>
        <Typography variant="subtitle2">{fieldValue}</Typography>
      </Stack>
    ));
  };
  const displayForm = () => {
    switch (prejudice.formType) {
      case 'VALEUR':
      case 'CALENDRIER_ASSISTANCE':
      case 'CALENDRIER_DEPENSE':
      case 'CALENDRIER_ASSISTANCE_CAPITALISATION':
      case 'CALENDRIER_DEPENSE_CAPITALISATION':
      case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL':
      case 'PERTE_GAINS_PROFESSIONNELS_FUTURS':
      case 'VALEUR_VICTIMES_INDIRECTES':
      case 'CALENDRIER_VALEURS':
      case 'MATERIEL':
      case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL':
      case 'PERTES_REVENUS_PROCHE':
      case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL':
      case 'SCOLAIRE': {
        return displayFormValues(
          formatPrejudiceTotal(
            getPrejudiceTotal({
              victime,
              victimesIndirectes,
              procedure,
              prejudice,
              dateLiquidation: dateLiquidation
                ? new Date(dateLiquidation)
                : undefined,
              monetaryErosions,
              PGPFReliquat,
              dateConsolidation: procedure.dateConsolidation
                ? new Date(procedure.dateConsolidation)
                : undefined,
              dateDeces: victime.dateDeces
                ? new Date(victime.dateDeces)
                : undefined,
            }),
          ),
        );
      }
      case 'LISTE_PROJECTION': {
        const formData = prejudice.formData as PrejudiceFormListeProjection;

        if (
          formData.enableProrataTemporisDeces &&
          (!procedure?.dateConsolidation || !victime.dateDeces)
        ) {
          return (
            <span>
              Veuillez saisir une date de consolidation et une date de décès
            </span>
          );
        }

        return displayFormValues(
          formatPrejudiceTotal(
            getPrejudiceTotal({
              victime,
              victimesIndirectes,
              procedure,
              prejudice,
              dateLiquidation: dateLiquidation
                ? new Date(dateLiquidation)
                : undefined,
              monetaryErosions,
              PGPFReliquat,
              dateConsolidation: procedure.dateConsolidation
                ? new Date(procedure.dateConsolidation)
                : undefined,
              dateDeces: victime.dateDeces
                ? new Date(victime.dateDeces)
                : undefined,
            }),
          ),
        );
      }
    }
  };
  const chiffrage = (prejudice.formData as PrejudiceFormBase).chiffrage;
  const canUpdateVictime = useCanUpdateVictime(victime);
  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography
          variant="h6"
          sx={{
            alignSelf: 'center',
            flexGrow: 2,
            color:
              prejudice.type === 'INDEMNITE_PROVISIONNELLE'
                ? 'info.light'
                : undefined,
          }}
        >
          {t(`prejudice.prejudicesTypes.${prejudice.type}.title`)}
        </Typography>
        {chiffrage ? (
          <Box height="100%">
            <Typography variant="caption">
              {t(`prejudice.fields.chiffrage.options.${chiffrage}.longLabel`)}
            </Typography>
          </Box>
        ) : null}
        <IconButton onClick={onClickEdit}>
          {canUpdateVictime ? <Edit /> : <RemoveRedEye />}
        </IconButton>
      </Stack>
      <GreyRoundedBox
        sx={{
          mt: 1,
          backgroundColor:
            prejudice.type === 'INDEMNITE_PROVISIONNELLE'
              ? 'secondary.lighter'
              : undefined,
        }}
      >
        <Stack direction="column">{displayForm()}</Stack>
      </GreyRoundedBox>
    </Box>
  );
};
