import {
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierDepenseCapitalisationRow,
  PrejudiceFormCalendrierDepenseRow,
} from 'src/types/prejudice.type';
import { CalculsFormCalendrier } from './calculsFormCalendrier';
import { CalculsGlobal } from './calculsGlobal';
import {
  IndemniteRepartieEchus,
  IndemniteRepartieEchusIndirecte,
} from './type';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInYears,
  eachDayOfInterval,
  eachYearOfInterval,
  getDaysInYear,
  isAfter,
  min,
} from 'date-fns';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { Time } from '../time';
import { MAX_TIMESTAMP, MIN_TIMESTAMP } from 'src/helpers/formatTime';
import i18next from 'i18next';
import { getCapitalisationCoefficient } from 'src/helpers/prejudices/capitalisation';
import { Bareme, BaremeCapitalisationValue } from 'src/types/bareme.type';
import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';

export abstract class CalculsFormCalendrierDepense {
  static montantTotal({
    type,
    frequenceMontant,
    renouvellementMaintenance,
    quantite,
    montantUnitaire,
    dureeDansUniteChoisie,
    isCalculCapitalisation,
  }: Pick<
    PrejudiceFormCalendrierDepenseRow,
    | 'type'
    | 'frequenceMontant'
    | 'renouvellementMaintenance'
    | 'quantite'
    | 'montantUnitaire'
    | 'dureeDansUniteChoisie'
  > & {
    isCalculCapitalisation: boolean;
  }) {
    if (type === 'ponctuelle') {
      return montantUnitaire;
    }
    if (frequenceMontant === 'viagere') {
      let multiplier = dureeDansUniteChoisie / (renouvellementMaintenance || 1);
      if (!isCalculCapitalisation) {
        multiplier = Math.ceil(multiplier);
      }
      return montantUnitaire * multiplier || 0;
    } else {
      return montantUnitaire * quantite * dureeDansUniteChoisie;
    }
  }
  static getDuree({
    dateDebut,
    dateFin,
    frequenceMontant,
    dateLiquidation,
    dateDeces,
  }: Pick<
    PrejudiceFormCalendrierDepenseRow,
    'dateDebut' | 'dateFin' | 'frequenceMontant'
  > & {
    dateLiquidation: Date | undefined;
    dateDeces: Date | undefined;
  }): number {
    if (
      !dateDebut ||
      (!!dateDeces && isAfter(new Date(dateDebut), new Date(dateDeces))) ||
      !dateLiquidation ||
      isAfter(new Date(dateDebut), dateLiquidation)
    ) {
      return 0;
    }
    const dateFinPeriodeEchue = dateFin
      ? min([new Date(dateFin), dateDeces || dateLiquidation])
      : dateDeces || dateLiquidation;
    if (dateDebut) {
      switch (frequenceMontant) {
        case 'annuel':
        case 'viagere':
          return CalculsGlobal.getYears(dateDebut, dateFinPeriodeEchue);
        case 'mensuel':
          return CalculsGlobal.getMonths(dateDebut, dateFinPeriodeEchue);
        case 'hebdomadaire':
          return CalculsGlobal.getWeeks(dateDebut, dateFinPeriodeEchue);
        case 'quotidien':
          return CalculsGlobal.getDays(dateDebut, dateFinPeriodeEchue);
        default:
          return 1;
      }
    }
    return 1;
  }
  static getIndemniteRepartieEchus(
    parameters: Omit<
      Parameters<typeof CalculsFormCalendrier.totauxDepenses>[0],
      'isCalculCapitalisation'
    > & { partResponsabilite: number },
  ): IndemniteRepartieEchus {
    const totaux = CalculsFormCalendrier.totauxDepenses({
      ...parameters,
      isCalculCapitalisation: false,
    });
    const indemniteRepartie = CalculsGlobal.getIndemnitesRepartie(
      totaux.resteACharge,
      totaux.priseEnChargeTiersPayeurs,
      parameters.partResponsabilite,
    );
    return indemniteRepartie;
  }
  static getIndemniteRepartieEchusIndirecte(
    parameters: Omit<
      Parameters<typeof CalculsFormCalendrier.totauxDepenses>[0],
      'isCalculCapitalisation'
    > & { partResponsabilite: number },
  ): IndemniteRepartieEchusIndirecte {
    const totaux = CalculsFormCalendrier.totauxDepenses({
      ...parameters,
      isCalculCapitalisation: false,
    });
    const indemniteRepartie = CalculsGlobal.getIndemnitesRepartieIndirecte({
      totalResteACharge: totaux.resteACharge,
      priseEnChargeTiersPayeurs: totaux.priseEnChargeTiersPayeurs,
      parVictimeIndirecte: totaux.totalParVictimeIndirecte || {},
      partResponsabilite: parameters.partResponsabilite,
    });
    return indemniteRepartie;
  }
  static isDepenseCapitalisee({
    dateFin: unParsedDateFin,
    dateLiquidation,
    type,
  }: Pick<PrejudiceFormCalendrierDepenseRow, 'dateFin' | 'type'> & {
    dateLiquidation: Date | undefined;
  }): boolean {
    if (type === 'ponctuelle') {
      return false;
    }
    const dateFin = unParsedDateFin
      ? CalculsGlobal.getDate(unParsedDateFin)
      : null;

    if (!dateLiquidation) {
      return false;
    }
    if (!dateFin) {
      return true;
    }
    if (isAfter(dateFin, dateLiquidation)) {
      return true;
    }
    return false;
  }

  static getAgeDernierArrerage({
    dateNaissance,
    dateFin,
  }: Pick<PrejudiceFormCalendrierDepenseRow, 'dateFin'> &
    Pick<Victime, 'dateNaissance'>): { age: number | null; isViager: boolean } {
    if (!dateNaissance) {
      return { age: null, isViager: false };
    }
    if (!dateFin) {
      return { age: null, isViager: true };
    }
    const dateNaissanceVictime = CalculsGlobal.getDate(dateNaissance);
    const dateFinDepense = CalculsGlobal.getDate(dateFin);
    const age = CalculsGlobal.getAge(dateNaissanceVictime, dateFinDepense);
    return { age, isViager: false };
  }

  static getDateAttribution({
    dateDebut: unParsedDateDebut,
    frequenceMontant,
    dateLiquidation: unParsedDateLiquidation,
    quantite,
    renouvellementMaintenance,
  }: Pick<
    PrejudiceFormCalendrierDepenseRow,
    'dateDebut' | 'frequenceMontant' | 'quantite' | 'renouvellementMaintenance'
  > &
    Pick<Procedure, 'dateLiquidation'>): Date | null {
    if (frequenceMontant === 'non_renseigne') {
      return null;
    }
    const dateDebut = unParsedDateDebut ? new Date(unParsedDateDebut) : null;
    const dateLiquidation = unParsedDateLiquidation
      ? new Date(unParsedDateLiquidation)
      : null;
    if (!dateDebut) {
      return null;
    }
    if (!dateLiquidation || isAfter(dateDebut, dateLiquidation)) {
      return dateDebut;
    }
    if (
      frequenceMontant === 'quotidien' ||
      (frequenceMontant === 'hebdomadaire' && quantite >= 7) ||
      (frequenceMontant === 'mensuel' && quantite >= 31) ||
      (frequenceMontant === 'annuel' && quantite >= 366)
    ) {
      return dateLiquidation;
    }
    const daysInFrequence = (daysInYear: number) => {
      switch (frequenceMontant) {
        case 'annuel':
          return daysInYear / quantite;
        case 'mensuel':
          return daysInYear / (12 * quantite);
        case 'hebdomadaire':
          return daysInYear / (52 * quantite);
        default:
          return 1;
      }
    };
    let allDays: Date[];
    // If the date of liquidation is the same as the date of debut, we return the date of liquidation because the date will eventually go to the liquidation date after multiple intervals
    if (
      dateDebut.getMonth() === dateLiquidation.getMonth() &&
      dateDebut.getDate() === dateLiquidation.getDate()
    ) {
      if (
        frequenceMontant !== 'viagere' ||
        renouvellementMaintenance === 1 ||
        !renouvellementMaintenance
      ) {
        return dateLiquidation;
      } else {
        const years = Math.abs(differenceInYears(dateDebut, dateLiquidation));
        return addYears(
          dateLiquidation,
          years < renouvellementMaintenance
            ? renouvellementMaintenance - years
            : years % renouvellementMaintenance,
        );
      }
    }
    const getDaysInFrequenceBetweenDates = (dateDebut: Date, dateFin: Date) => {
      const years = eachYearOfInterval({
        start: dateDebut,
        end: dateFin,
      });
      const averageDaysInYear = CalculsGlobal.average(years.map(getDaysInYear));
      const days = daysInFrequence(averageDaysInYear);
      return days;
    };
    if (frequenceMontant === 'annuel') {
      const dateFin = addYears(dateDebut, 1);
      const days = eachDayOfInterval(
        {
          start: dateDebut,
          end: dateFin,
        },
        {
          step: getDaysInFrequenceBetweenDates(dateDebut, dateFin),
        },
      );
      if (
        days.some(
          (date) =>
            date.getDate() === dateLiquidation.getDate() &&
            date.getMonth() === dateLiquidation.getMonth(),
        )
      ) {
        return dateLiquidation;
      }
    }
    if (frequenceMontant === 'mensuel') {
      const dateFin = addMonths(dateDebut, 1);
      const days = eachDayOfInterval(
        {
          start: dateDebut,
          end: dateFin,
        },
        {
          step: getDaysInFrequenceBetweenDates(dateDebut, dateFin),
        },
      );
      if (days.some((date) => date.getDate() === dateLiquidation.getDate())) {
        return dateLiquidation;
      }
    }
    if (frequenceMontant === 'hebdomadaire') {
      const dateFin = addWeeks(dateDebut, 1);
      const days = eachDayOfInterval(
        {
          start: dateDebut,
          end: dateFin,
        },
        {
          step: getDaysInFrequenceBetweenDates(dateDebut, dateFin),
        },
      );
      if (days.some((date) => date.getDay() === dateLiquidation.getDay())) {
        return dateLiquidation;
      }
    }
    if (frequenceMontant !== 'viagere') {
      const days = getDaysInFrequenceBetweenDates(dateDebut, dateLiquidation);
      const getEnd = () => {
        switch (frequenceMontant) {
          case 'annuel':
            return addYears(dateLiquidation, 1);
          case 'mensuel':
            return addMonths(dateLiquidation, 1);
          case 'hebdomadaire':
            return addWeeks(dateLiquidation, 1);
          default:
            return dateLiquidation;
        }
      };
      allDays = eachDayOfInterval(
        {
          start: dateDebut,
          end: getEnd(),
        },
        {
          step: days,
        },
      );
    } else {
      allDays = eachDayOfInterval(
        {
          start: dateDebut,
          end: addDays(
            dateLiquidation,
            Time.daysInYear * (renouvellementMaintenance || 1),
          ),
        },
        { step: Time.daysInYear * (renouvellementMaintenance || 1) },
      );
    }

    // We find the cases where the one of the interval day falls exactly on the same day as the liquidation date
    if (
      allDays.some(
        (date) => Math.abs(differenceInDays(date, dateLiquidation)) <= 1,
      )
    ) {
      return dateLiquidation;
    }
    const date = allDays.find((date) => isAfter(date, dateLiquidation));
    return date || null;
  }

  static totauxDepensesCapitalisation({
    rows,
    dateLiquidation,
  }: {
    rows: Pick<
      PrejudiceFormCalendrierDepenseCapitalisationRow,
      'dateFin' | 'type' | 'capitalisation' | 'capitalisationTiersPayeurs'
    >[];
    dateLiquidation: Date | undefined;
  }) {
    const rowsCapitalisees = rows.filter((row) =>
      this.isDepenseCapitalisee({
        dateFin: row.dateFin,
        dateLiquidation,
        type: row.type,
      }),
    );
    const montantCapitalise = CalculsGlobal.sum(
      rowsCapitalisees.map((row) => row.capitalisation.montantCapitalise),
    );
    return {
      montantCapitalise,
    };
  }

  static totauxDepensesTiersPayeursCapitalisation({
    rows,
    dateLiquidation,
  }: {
    rows: Pick<
      PrejudiceFormCalendrierDepenseCapitalisationRow,
      'dateFin' | 'type' | 'capitalisationTiersPayeurs'
    >[];
    dateLiquidation: Date | undefined;
  }) {
    const rowsCapitalisees = rows.filter((row) =>
      this.isDepenseCapitalisee({
        dateFin: row.dateFin,
        dateLiquidation,
        type: row.type,
      }),
    );
    const parTiersPayeur = rowsCapitalisees.reduce(
      (accumulator, row) => ({
        ...accumulator,
        ...row.capitalisationTiersPayeurs.parTiersPayeur.reduce(
          (parTiersPayeur, { tiersPayeur, montantCapitalise }) => ({
            ...parTiersPayeur,
            [tiersPayeur]:
              (parTiersPayeur[tiersPayeur] || 0) + montantCapitalise,
          }),
          accumulator,
        ),
      }),
      {} as Record<string, number>,
    );
    return {
      parTiersPayeur,
    };
  }

  static totalTiersPayeursCapitalisation(
    parTiersPayeur: PrejudiceFormCalendrierDepenseCapitalisation['capitalisationTiersPayeurs']['parTiersPayeur'],
  ) {
    return CalculsGlobal.sum(
      parTiersPayeur.map(({ montantCapitalise }) => montantCapitalise),
    );
  }

  static getMinAndMaxDateJustificatif({
    procedure,
    isCalculCapitalisation,
  }: {
    procedure: Procedure;
    isCalculCapitalisation: boolean | undefined;
  }): {
    minDate: {
      date: Date;
      message: string;
    };
    maxDate: {
      date: Date;
      message: string;
    };
  } {
    const { dateConsolidation, dateLiquidation } = {
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
    };
    const normaMinDate = new Date(MIN_TIMESTAMP);
    const normaMaxDate = new Date(MAX_TIMESTAMP);
    const minDate = {
      date: normaMinDate,
      message: i18next.t('validation.dates.dateTooLow'),
    };
    if (isCalculCapitalisation) {
      return {
        minDate,
        maxDate: {
          date: dateLiquidation || dateConsolidation || normaMaxDate,
          message:
            dateConsolidation || dateLiquidation
              ? i18next.t(
                  `validation.prejudices.dates.${
                    dateLiquidation
                      ? 'maxDateLiquidation'
                      : dateConsolidation
                        ? 'maxDateConsolidation'
                        : ''
                  }`,
                )
              : i18next.t('validation.dates.dateTooHigh'),
        },
      };
    } else {
      return {
        minDate,
        maxDate: {
          date: dateConsolidation || dateLiquidation || new Date(),
          message:
            dateConsolidation || dateLiquidation
              ? i18next.t(
                  `validation.prejudices.dates.${
                    dateConsolidation
                      ? 'maxDateConsolidation'
                      : dateLiquidation
                        ? 'maxDateLiquidation'
                        : ''
                  }`,
                )
              : i18next.t('validation.dates.dateTooHigh'),
        },
      };
    }
  }
  static getCapitalisationCoefficient({
    dateAttribution,
    ageDernierArrerage,
    isLastArrerageViager,
    baremeCapitalisation,
    dateLiquidation,
    baremesCapitalisation,
    victime,
    enableCapitalisationDifferee,
  }: Pick<
    PrejudiceFormCalendrierDepenseCapitalisationRow['capitalisation'],
    'dateAttribution' | 'ageDernierArrerage' | 'isLastArrerageViager'
  > & {
    baremeCapitalisation: string | undefined;
    dateLiquidation: Date | undefined;
    baremesCapitalisation: Bareme[];
    victime: Pick<Victime, 'sexe' | 'dateNaissance'>;
    enableCapitalisationDifferee?: boolean;
  }): number | null {
    const bareme = baremesCapitalisation?.find(
      (bareme) => bareme._id === baremeCapitalisation,
    );
    if (!bareme || !victime.dateNaissance || !dateAttribution) {
      return null;
    }
    const ageDateAttribution = CalculsGlobal.getAge(
      victime.dateNaissance,
      dateAttribution,
    );
    return getCapitalisationCoefficient({
      ...victime,
      baremeValues: bareme.values as BaremeCapitalisationValue[],
      ageDateAttribution,
      dateLiquidation,
      dateNaissance: victime.dateNaissance,
      ageDernierArrerage: ageDernierArrerage ?? null,
      isDernierArrerageViager: isLastArrerageViager,
      enableCapitalisationDifferee:
        enableCapitalisationDifferee && bareme.source !== 'CJ',
    });
  }

  static getMontantCapitalise({
    montantUnitaireAnnualise,
    coefficient,
    montantsDejaRevalorises,
    dateJustificatif,
    revalorisationCoefficientsType,
    dateLiquidation,
    monetaryErosions,
  }: Pick<
    PrejudiceFormCalendrierDepenseCapitalisationRow['capitalisation'],
    'coefficient'
  > &
    Pick<
      PrejudiceFormCalendrierDepenseCapitalisationRow,
      | 'montantUnitaireAnnualise'
      | 'montantsDejaRevalorises'
      | 'dateJustificatif'
    > &
    Pick<
      PrejudiceFormCalendrierDepenseCapitalisation,
      'revalorisationCoefficientsType'
    > & {
      monetaryErosions: MonetaryErosion[];
      dateLiquidation: Date | undefined;
    }): number {
    const montantRevalorise =
      !montantsDejaRevalorises && dateJustificatif && montantUnitaireAnnualise
        ? getMontantRevalorise({
            anneeOrDateMontant: dateJustificatif,
            montant: montantUnitaireAnnualise,
            monetaryErosions,
            anneeOrDateLiquidation: dateLiquidation,
            coefficientsType: revalorisationCoefficientsType,
          }) || 0
        : montantUnitaireAnnualise || 0;
    return montantRevalorise * (coefficient || 0);
  }
}
