import { Paragraph, Table, TableOfContents } from 'docx';
import { Prejudice, PrejudiceFormMateriel } from 'src/types/prejudice.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import i18next from 'i18next';
import {
  getRevalorisationIntroductionWrittenPrintIfRevalorise,
  getMontantRevaloriseWrittenPrint,
} from '../revalorisation.written.print';
import { fDate } from 'src/helpers/formatTime';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { fCurrency } from 'src/helpers/formatNumber';
import { Procedure } from 'src/types/procedure.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { displayRowsSumFormula } from '../formula';
import { Victime } from 'src/types/victime.type';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';
import { upperFirst } from 'lodash';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';

export const materielWrittenPrint = ({
  formData,
  prejudice,
  procedure,
  monetaryErosions,
  victime,
}: {
  formData: PrejudiceFormMateriel;
  prejudice: Pick<Prejudice, 'formType' | 'formData' | 'type'>;
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
  victime: Victime;
}): (Table | TableOfContents | Paragraph)[] => {
  const partResponsabilite =
    formData.partResponsabilite || procedure.partResponsabilite;
  const rows = formData.rows.filter((row) => !!row.montant);
  const rowsParagraphsByNumberOfRows: Record<
    'noRow' | 'oneRow' | 'multipleRows',
    Paragraph[]
  > = {
    noRow: [],
    oneRow: rows[0]
      ? [
          getParagraph({
            children: [
              ...getTextRun(
                upperFirst(
                  i18next.t(
                    `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.oneRow.row`,
                    {
                      date: rows[0].date ? fDate(rows[0].date) : '',
                      intitule: rows[0].intitule,
                      numerosPieces: displayNumerosPiecesWrittenPrint(
                        rows[0].numerosPieces,
                        'Parentheses',
                      ),
                      montant: fCurrency(rows[0].montant),
                      context: !rows[0].date ? 'noDate' : undefined,
                    },
                  ),
                ),
              ),
              ...(!rows[0].montantsDejaRevalorises && rows[0].date
                ? getTextRun(
                    getMontantRevaloriseWrittenPrint({
                      montant: rows[0].montant,
                      anneeOrDateLiquidation:
                        procedure.dateLiquidation ?? undefined,
                      anneeOrDateMontant: rows[0].date ?? undefined,
                      coefficientsType: formData.revalorisationCoefficientsType,
                      monetaryErosions,
                    }),
                  )
                : []),
            ],
          }),
        ]
      : [],
    multipleRows: [
      getParagraph(
        i18next.t(
          `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.multipleRows.firstPart`,
        ),
      ),
      ...rows.map((row) =>
        getParagraph({
          numbering: {
            reference: 'simple-list',
            level: 0,
          },
          children: [
            ...getTextRun(
              i18next.t(
                `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.multipleRows.row`,
                {
                  date: row.date ? fDate(row.date) : '',
                  intitule: row.intitule,
                  numerosPieces: displayNumerosPiecesWrittenPrint(
                    row.numerosPieces,
                    'Parentheses',
                  ),
                  montant: fCurrency(row.montant),
                  context: !row.date ? 'noDate' : undefined,
                },
              ),
            ),
            ...(!row.montantsDejaRevalorises && row.date
              ? getTextRun(
                  getMontantRevaloriseWrittenPrint({
                    montant: row.montant,
                    anneeOrDateLiquidation:
                      procedure.dateLiquidation ?? undefined,
                    anneeOrDateMontant: row.date ?? undefined,
                    coefficientsType: formData.revalorisationCoefficientsType,
                    monetaryErosions,
                  }),
                )
              : []),
          ],
        }),
      ),
      getParagraph(
        i18next.t(
          `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.multipleRows.total`,
          {
            total: fCurrency(formData.total),
            formula: displayRowsSumFormula({
              rowValues: rows.map((row) =>
                row.montantsDejaRevalorises
                  ? row.montant
                  : getMontantRevalorise({
                      montant: row.montant,
                      anneeOrDateLiquidation:
                        procedure.dateLiquidation ?? undefined,
                      anneeOrDateMontant: row.date ?? undefined,
                      coefficientsType: formData.revalorisationCoefficientsType,
                      monetaryErosions,
                    }) ?? row.montant,
              ),
              options: {
                withParentheses: true,
              },
              editedFieldsParameters: {
                formData,
                fieldName: 'total',
              },
            }),
          },
        ),
      ),
    ],
  };

  const totalTextByPartResponsabilite: Record<'full' | 'nonFull', string> = {
    full: i18next.t(
      `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.total.fullPartResponsabilite`,
      {
        victimeName: displayVictimeNameWrittenPrint(victime),
        total: fCurrency(formData.total),
      },
    ),
    nonFull: i18next.t(
      `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.total.nonFullPartResponsabilite`,
      {
        victimeName: displayVictimeNameWrittenPrint(victime),
        total: fCurrency(formData.total),
        partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
        totalPartResponsabilite: fCurrency(formData.total * partResponsabilite),
      },
    ),
  };
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      formData,
      formType: 'MATERIEL',
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    }),
    ...rowsParagraphsByNumberOfRows[
      rows.length === 0
        ? 'noRow'
        : rows.length === 1
          ? 'oneRow'
          : 'multipleRows'
    ],
    getEmptyLine(),
    getParagraph(
      totalTextByPartResponsabilite[
        partResponsabilite === 1 ? 'full' : 'nonFull'
      ],
    ),
  ];
};
