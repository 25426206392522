import { Paragraph, Table, TableOfContents } from 'docx';
import {
  Prejudice,
  PrejudiceFormCalendrierValeur,
} from 'src/types/prejudice.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { fDate } from 'src/helpers/formatTime';
import { Victime } from 'src/types/victime.type';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';

export const calendrierValeursWrittenPrint = ({
  formData,
  prejudice,
  victime,
}: {
  formData: PrejudiceFormCalendrierValeur;
  prejudice: Pick<Prejudice, 'formType' | 'formData' | 'type'>;
  victime: Victime;
}): (Table | TableOfContents | Paragraph)[] => {
  const nonZeroRows = formData.rows.filter((row) => !!row.montant);
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...(nonZeroRows.length > 0 && nonZeroRows.length === 1 && nonZeroRows[0]
      ? [
          getParagraph(
            i18next.t(
              `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.oneRow`,
              {
                date: nonZeroRows[0].date ? fDate(nonZeroRows[0].date) : '',
                victimeName: displayVictimeNameWrittenPrint(victime),
                intitule: nonZeroRows[0].intitule,
                numerosPieces: displayNumerosPiecesWrittenPrint(
                  nonZeroRows[0].numerosPieces,
                  'Parentheses',
                ),
                montant: fCurrency(nonZeroRows[0].montant),
              },
            ),
          ),
        ]
      : nonZeroRows.length > 0
        ? [
            getParagraph(
              i18next.t(
                `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.multipleRows.firstPart`,
                {
                  victimeName: displayVictimeNameWrittenPrint(victime),
                },
              ),
            ),
            ...nonZeroRows.map((row) =>
              getParagraph({
                numbering: {
                  reference: 'simple-list',
                  level: 0,
                },
                children: getTextRun(
                  i18next.t(
                    `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.multipleRows.row`,
                    {
                      date: row.date ? fDate(row.date) : '',
                      montant: fCurrency(row.montant),
                      intitule: row.intitule,
                      numerosPieces: displayNumerosPiecesWrittenPrint(
                        row.numerosPieces,
                        'Parentheses',
                      ),
                    },
                  ),
                ),
              }),
            ),
            getParagraph(
              i18next.t(
                `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.text.multipleRows.total`,
                {
                  total: fCurrency(formData.total),
                },
              ),
            ),
          ]
        : []),
  ];
};
