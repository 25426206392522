import { isAfter } from 'date-fns';
import { CalculsGlobal } from 'src/constants/calculs';
import { AgeOrZero } from 'src/constants/calculs/type';
import { sortBaremesCapitalisation } from 'src/helpers/bareme/sortAndOrder';
import {
  Bareme,
  BaremeCapitalisationValue,
  BaremeEsperanceDeVieValue,
} from 'src/types/bareme.type';
import { Victime } from 'src/types/victime.type';

// TODO : Convert this helper and all helpers to a class or a cleaner way to export all in one element
// const getExtrapolatedCapitalisationCoefficientForMissingUpperOrLowerColumn = ({
//   sortedColumns,
//   ageDernierArrerage,
//   ageDernierArrerageIndex,
//   isUpperColumnMissing,
// }: {
//   sortedColumns: BaremeCapitalisationValue['rows'][number]['columns'];
//   ageDernierArrerage: number;
//   ageDernierArrerageIndex: number;
//   isUpperColumnMissing: boolean;
// }): number | null => {
//   if (isUpperColumnMissing) {
//     const firstLowerColumn =
//       ageDernierArrerageIndex - 1 >= 0
//         ? sortedColumns[ageDernierArrerageIndex - 1]
//         : null;
//     const secondLowerColumn =
//       ageDernierArrerageIndex - 2 >= 0
//         ? sortedColumns[ageDernierArrerageIndex - 2]
//         : null;
//     if (
//       !firstLowerColumn ||
//       !secondLowerColumn ||
//       firstLowerColumn.ageLastArrerage === null ||
//       secondLowerColumn.ageLastArrerage === null
//     ) {
//       return null;
//     }
//     return (
//       firstLowerColumn.percentage +
//       ((firstLowerColumn.percentage - secondLowerColumn.percentage) *
//         (ageDernierArrerage - firstLowerColumn.ageLastArrerage)) /
//         (firstLowerColumn.ageLastArrerage - secondLowerColumn.ageLastArrerage)
//     );
//   } else {
//     const firstUpperColumn =
//       ageDernierArrerageIndex + 1 < sortedColumns.length
//         ? sortedColumns[ageDernierArrerageIndex + 1]
//         : null;
//     const secondUpperColumn =
//       ageDernierArrerageIndex + 2 < sortedColumns.length
//         ? sortedColumns[ageDernierArrerageIndex + 2]
//         : null;
//     if (
//       !firstUpperColumn ||
//       !secondUpperColumn ||
//       firstUpperColumn.ageLastArrerage === null ||
//       secondUpperColumn.ageLastArrerage === null
//     ) {
//       return null;
//     }
//     return (
//       firstUpperColumn.percentage -
//       ((secondUpperColumn.percentage - firstUpperColumn.percentage) *
//         (firstUpperColumn.ageLastArrerage - ageDernierArrerage)) /
//         (secondUpperColumn.ageLastArrerage - firstUpperColumn.ageLastArrerage)
//     );
//   }
// };

// const getExtrapolatedCapitalisationCoefficient = ({
//   columns,
//   ageDernierArrerage,
// }: {
//   columns: BaremeCapitalisationValue['rows'][number]['columns'];
//   ageDernierArrerage: number;
// }): number | null => {
//   const sortedColumns = sortBy(columns, 'ageLastArrerage');
//   const index = sortedIndexBy(
//     sortedColumns,
//     {
//       ageLastArrerage: ageDernierArrerage,
//       percentage: 0,
//       isLastArrerageViagere: false,
//     },
//     'ageLastArrerage',
//   );
//   const upperColumn =
//     index < sortedColumns.length ? sortedColumns[index] : null;
//   const lowerColumn = index > 0 ? sortedColumns[index - 1] : null;
//   if (
//     (!upperColumn || upperColumn.ageLastArrerage === null) &&
//     !!lowerColumn &&
//     lowerColumn.ageLastArrerage !== null
//   ) {
//     return getExtrapolatedCapitalisationCoefficientForMissingUpperOrLowerColumn(
//       {
//         sortedColumns,
//         ageDernierArrerage,
//         ageDernierArrerageIndex: index,
//         isUpperColumnMissing: true,
//       },
//     );
//   } else if (
//     (!lowerColumn || lowerColumn.ageLastArrerage === null) &&
//     !!upperColumn &&
//     upperColumn.ageLastArrerage !== null
//   ) {
//     return getExtrapolatedCapitalisationCoefficientForMissingUpperOrLowerColumn(
//       {
//         sortedColumns,
//         ageDernierArrerage,
//         ageDernierArrerageIndex: index,
//         isUpperColumnMissing: false,
//       },
//     );
//   }
//   if (
//     !upperColumn ||
//     !lowerColumn ||
//     upperColumn.ageLastArrerage === null ||
//     lowerColumn.ageLastArrerage === null
//   ) {
//     return null;
//   }
//   const percentage =
//     lowerColumn.percentage +
//     ((upperColumn.percentage - lowerColumn.percentage) *
//       (ageDernierArrerage - lowerColumn.ageLastArrerage)) /
//       (upperColumn.ageLastArrerage - lowerColumn.ageLastArrerage);
//   return percentage;
// };

const findCapitalisationBaremeColumn = ({
  victimeSexe,
  baremeValues,
  ageDateAttribution,
  ageDernierArrerage,
  isDernierArrerageViager,
}: {
  victimeSexe: 'm' | 'f' | 'u';
  baremeValues: BaremeCapitalisationValue[];
  ageDateAttribution: number;
  ageDernierArrerage?: number | undefined;
  isDernierArrerageViager?: boolean;
}):
  | BaremeCapitalisationValue['rows'][number]['columns'][number]
  | undefined => {
  const row = baremeValues
    .find(
      (value) =>
        (value.gender === 'man' && victimeSexe === 'm') ||
        (value.gender === 'woman' && victimeSexe === 'f') ||
        (value.gender === 'undetermined' && victimeSexe === 'u') ||
        value.gender === 'all',
    )
    ?.rows?.find((row) => row.ageDateAttribution === ageDateAttribution);
  const column = row?.columns?.find(
    (column) =>
      (!isDernierArrerageViager &&
        column.ageLastArrerage === ageDernierArrerage) ||
      (isDernierArrerageViager === true &&
        column.isLastArrerageViagere === true),
  );
  return column;
};

export const getCapitalisationNotDiffereeCoefficient = ({
  victimeSexe,
  baremeValues,
  ageDateAttribution,
  ageDernierArrerage,
  isDernierArrerageViager,
}: {
  victimeSexe: 'm' | 'f' | 'u';
  baremeValues: BaremeCapitalisationValue[];
  ageDateAttribution: number;
  ageDernierArrerage?: number | undefined;
  isDernierArrerageViager?: boolean;
}): number | null => {
  const column = findCapitalisationBaremeColumn({
    victimeSexe,
    baremeValues,
    ageDateAttribution,
    ageDernierArrerage,
    isDernierArrerageViager,
  });
  if (column) {
    return column.percentage;
  }
  // Extrapolated coefficients are deactivated for now
  // const columns = row?.columns;
  // if (
  //   !isDernierArrerageViager &&
  //   ageDernierArrerage !== undefined &&
  //   !!columns
  // ) {
  //   const extrapolatedCoefficient = getExtrapolatedCapitalisationCoefficient({
  //     columns,
  //     ageDernierArrerage,
  //   });
  //   if (extrapolatedCoefficient !== null) {
  //     return extrapolatedCoefficient;
  //   }
  // }
  return null;
};
export const getAllCapitalisationCoefficient = ({
  sexe,
  dateNaissance,
  dateLiquidation,
  baremeValues,
  ageDateAttribution,
  ageDernierArrerage,
  isDernierArrerageViager,
}: {
  sexe: Victime['sexe'];
  dateNaissance: Date | string;
  dateLiquidation: Date;
  baremeValues: BaremeCapitalisationValue[];
  ageDateAttribution: number;
  ageDernierArrerage: number | undefined;
  isDernierArrerageViager: boolean | undefined;
}): (number | null)[] => {
  const ageDateLiquidation = CalculsGlobal.getAge(
    dateNaissance,
    dateLiquidation,
  );
  const coefficientLiquidation = getCapitalisationNotDiffereeCoefficient({
    victimeSexe: sexe,
    baremeValues,
    ageDateAttribution: ageDateLiquidation,
    ageDernierArrerage,
    isDernierArrerageViager,
  });
  const coefficientDateAttribution = getCapitalisationNotDiffereeCoefficient({
    victimeSexe: sexe,
    baremeValues,
    ageDateAttribution: ageDateLiquidation,
    ageDernierArrerage: ageDateAttribution,
  });
  return [coefficientLiquidation, coefficientDateAttribution];
};

export const getCapitalisationDiffereeCoefficient = ({
  sexe,
  dateNaissance,
  dateLiquidation,
  baremeValues,
  ageDateAttribution,
  ageDernierArrerage,
  isDernierArrerageViager,
}: {
  sexe: Victime['sexe'];
  dateNaissance: Date | string;
  dateLiquidation: Date;
  baremeValues: BaremeCapitalisationValue[];
  ageDateAttribution: number;
  ageDernierArrerage: number | undefined;
  isDernierArrerageViager: boolean | undefined;
}): number | null => {
  const [coefficientLiquidation, coefficientDateAttribution] =
    getAllCapitalisationCoefficient({
      sexe,
      dateNaissance,
      dateLiquidation,
      baremeValues,
      ageDateAttribution,
      ageDernierArrerage,
      isDernierArrerageViager,
    });
  if (coefficientLiquidation === null || coefficientDateAttribution === null) {
    return null;
  }
  return (coefficientLiquidation || 0) - (coefficientDateAttribution || 0);
};
export const getCapitalisationCoefficient = ({
  sexe,
  dateNaissance,
  dateLiquidation,
  baremeValues,
  ageDateAttribution,
  ageDernierArrerage,
  isDernierArrerageViager,
  enableCapitalisationDifferee,
}: {
  sexe: Victime['sexe'];
  dateNaissance: Date | string;
  dateLiquidation: Date | undefined;
  baremeValues: BaremeCapitalisationValue[];
  ageDateAttribution: number;
  ageDernierArrerage: number | null;
  isDernierArrerageViager: boolean | undefined;
  enableCapitalisationDifferee?: boolean;
}) => {
  const ageLiquidation = dateLiquidation
    ? CalculsGlobal.getAge(dateNaissance, dateLiquidation)
    : null;
  const difference =
    ageLiquidation !== null ? ageDateAttribution - ageLiquidation : null;
  if (
    dateLiquidation &&
    difference !== null &&
    difference >= 1 &&
    enableCapitalisationDifferee
  ) {
    return getCapitalisationDiffereeCoefficient({
      sexe,
      dateNaissance,
      baremeValues,
      ageDateAttribution,
      dateLiquidation,
      ageDernierArrerage: ageDernierArrerage ?? undefined,
      isDernierArrerageViager,
    });
  } else {
    return getCapitalisationNotDiffereeCoefficient({
      victimeSexe: sexe,
      baremeValues,
      ageDateAttribution,
      ageDernierArrerage: ageDernierArrerage ?? undefined,
      isDernierArrerageViager,
    });
  }
};

export const getProrataTemporisCapitalisationCoefficient = ({
  victimeSexe,
  selectedBareme,
  ageDateAttribution,
  anneeCapitalisation,
}: {
  victimeSexe: 'm' | 'f' | 'u';
  selectedBareme: Bareme;
  ageDateAttribution: number;
  anneeCapitalisation: number;
}): number | null => {
  if (
    selectedBareme.type === 'EsperanceDeVie' &&
    selectedBareme.source !== 'CJ'
  ) {
    const baremeValues = selectedBareme.values as BaremeEsperanceDeVieValue[];
    const coefficient = baremeValues.find(
      (value) =>
        (value.gender === 'man' && victimeSexe === 'm') ||
        (value.gender === 'woman' && victimeSexe === 'f') ||
        (value.gender === 'undetermined' && victimeSexe === 'u') ||
        value.gender === 'all',
    )?.values[anneeCapitalisation]?.[ageDateAttribution];
    return coefficient ? coefficient : null;
  }
  return null;
};

export const getCapitalisationBaremes = (
  baremes: Bareme[],
  sexe: 'm' | 'f' | 'u',
): Bareme[] =>
  sortBaremesCapitalisation(
    baremes.filter(
      (bareme) =>
        bareme.type === 'Capitalisation' &&
        (bareme.values as BaremeCapitalisationValue[]).some(
          (value) =>
            (value.gender === 'man' && sexe === 'm') ||
            (value.gender === 'woman' && sexe === 'f') ||
            (value.gender === 'undetermined' && sexe === 'u') ||
            value.gender === 'all',
        ),
    ),
  );

export const getProrataTemporisBaremes = (
  baremes: Bareme[],
  sexe: 'm' | 'f' | 'u',
): Bareme[] =>
  baremes.filter((bareme) => {
    if (bareme.type !== 'EsperanceDeVie') {
      return false;
    }
    if (bareme.source === 'CJ') {
      return true;
    } else if (
      bareme.source === 'INSEE' &&
      (bareme.values as BaremeEsperanceDeVieValue[]).some(
        (value) =>
          (value.gender === 'man' && sexe === 'm') ||
          (value.gender === 'woman' && sexe === 'f') ||
          (value.gender === 'undetermined' && sexe === 'u') ||
          value.gender === 'all',
      )
    ) {
      return true;
    }
    return false;
  });

export const getFirstNonNullAgeDernierArrerage = (bareme?: Bareme) => {
  if (!bareme) {
    return 0;
  }
  const baremeValues = bareme.values as BaremeCapitalisationValue[];
  if (!baremeValues[0]?.rows) {
    return 0;
  }
  for (let i = 0; i < baremeValues[0].rows.length; i++) {
    const row = baremeValues[0].rows[i];
    if (row?.ageDateAttribution !== null && row?.columns?.length) {
      for (let j = 0; j < row?.columns?.length; j++) {
        const column = row?.columns[j];
        if (column?.ageLastArrerage !== null) {
          return column?.ageLastArrerage;
        }
      }
    }
  }
};
export const getAgeDateAttribution = ({
  dateNaissance,
  dateLiquidation,
  dateDeces,
  dateAttribution,
}: {
  dateNaissance?: Date | null;
  dateLiquidation?: Date | null;
  dateDeces?: Date | null;
  dateAttribution?: Date | string | null;
}): AgeOrZero | null => {
  if (dateNaissance && dateAttribution) {
    return CalculsGlobal.getAgeOrZero(dateNaissance, dateAttribution);
  } else if (dateDeces && dateNaissance) {
    return CalculsGlobal.getAgeOrZero(dateNaissance, dateDeces);
  } else if (dateLiquidation && dateNaissance)
    return CalculsGlobal.getAgeOrZero(dateNaissance, dateLiquidation);
  else if (dateNaissance) {
    return CalculsGlobal.getAgeOrZero(dateNaissance, new Date());
  }
  return null;
};
export const getShouldNotDisplayCapitalisation = ({
  dateConsolidation,
  dateLiquidation,
  dateDeces,
}: {
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  dateDeces: Date | undefined;
}): boolean => {
  if (
    dateDeces &&
    dateConsolidation &&
    dateLiquidation &&
    isAfter(dateDeces, dateConsolidation) &&
    isAfter(dateLiquidation, dateDeces)
  ) {
    return true;
  }
  return false;
};
