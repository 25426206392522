import { RegimeType } from '../types/regime.type';

export const prettyString: Record<RegimeType, string> = {
  SALARIE_RG: 'Salarié Régime général (RG)',
  SALARIE_FP: 'Salarié Fonction publique (FP)',
  INDEPENDANT_ARTISTES_AUTEURS: 'Indépendant Artistes Auteurs',
  INDEPENDANT_HORS_MICRO: 'Indépendant hors Micro',
  MICRO_ENTREPRENEUR_BNC: 'Micro Entrepreneur - BNC',
  MICRO_ENTREPRENEUR_BIC_ARTISAN: 'Micro Entrepreneur - BIC (artisan)',
  MICRO_ENTREPRENEUR_BIC_ACHAT_VENTE:
    'Micro Entrepreneur - BIC (achat / vente)',
  CHOMAGE: 'Chômage',
};

export const regimeSubtitles: Record<RegimeType, string[]> = {
  SALARIE_RG: [],
  SALARIE_FP: [],
  INDEPENDANT_ARTISTES_AUTEURS: [],
  INDEPENDANT_HORS_MICRO: [
    'Régime réel BNC (régime de la déclaration contrôlée)',
    'Régime réel BIC (artisan et commerçant)',
    'Gérant majoritaire',
  ],
  MICRO_ENTREPRENEUR_BNC: [],
  MICRO_ENTREPRENEUR_BIC_ARTISAN: [],
  MICRO_ENTREPRENEUR_BIC_ACHAT_VENTE: [],
  CHOMAGE: [],
};
