import React from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import {
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { CalculsBox } from '../../../basic/CalculsBox';
import { LayoutTable } from '../../../styled';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { Procedure } from 'src/types/procedure.type';
import { Bareme } from 'src/types/bareme.type';
import { getTropPercuString } from 'src/helpers/prejudices/pgpfReliquat';
import { ErrorOutline } from '@mui/icons-material';
import { CapitalisationRows } from './CapitalisationRows';
import { useTranslation } from 'react-i18next';
import { TiersPayeursCapitalisationRows } from './TiersPayeursCapitalisationRows';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';

interface Props<
  TFieldValues extends FieldValues,
  TSommeACapitaliserName extends FieldPath<TFieldValues>,
  TBaremeName extends FieldPath<TFieldValues>,
  TAgeDernierArreragename extends FieldPath<TFieldValues>,
  TCoefficientname extends FieldPath<TFieldValues>,
  TIsLastArrerageViagerName extends FieldPath<TFieldValues>,
  TMontantCapitaliseName extends FieldPath<TFieldValues>,
  TAgeDateAttributionName extends FieldPath<TFieldValues>,
  TEnableCapitalisationDiffereeName extends FieldPath<TFieldValues>,
  TTIersPayeursSommeACapitaliserSuffix extends string,
  TTIersPayeursBaremeSuffix extends string,
  TTIersPayeursAgeDernierArrerageSuffix extends string,
  TTIersPayeursCoefficientSuffix extends string,
  TTIersPayeursIsLastArrerageViagerSuffix extends string,
  TTiersPayeursMontantCapitaliseSuffix extends string,
  TTiersPayeursRootName extends FieldPath<TFieldValues>,
  TTiersPayeursTotalCapitaliseName extends FieldPath<TFieldValues>,
  TDefaultValueSommeACapitaliserName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursSommeACapitaliserName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursSommeACapitaliserSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursBaremeName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursBaremeSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursAgeDernierArrerageName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursAgeDernierArrerageSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursCoefficientName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursCoefficientSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursIsLastArrerageViagerName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursIsLastArrerageViagerSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursMontantCapitaliseName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTiersPayeursMontantCapitaliseSuffix}`,
  TTotalName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  victimeName: {
    sommeACapitaliser?: TSommeACapitaliserName;
    bareme?: TBaremeName;
    ageDernierArrerage?: TAgeDernierArreragename;
    coefficient?: TCoefficientname;
    isLastArrerageViager?: TIsLastArrerageViagerName;
    montantCapitalise?: TMontantCapitaliseName;
    ageDateAttribution?: TAgeDateAttributionName;
    enableCapitalisationDifferee?: TEnableCapitalisationDiffereeName;
  };
  procedure: Procedure;
  dateLiquidation: Date | null | undefined;
  baremes: Bareme[];
  dateNaissance: Date | null | undefined;
  dateDeces: Date | null | undefined;
  sexe: 'm' | 'f' | 'u';
  tiersPayeurSuffix?: {
    sommeACapitaliser?: TTIersPayeursSommeACapitaliserSuffix;
    bareme?: TTIersPayeursBaremeSuffix;
    ageDernierArrerage?: TTIersPayeursAgeDernierArrerageSuffix;
    coefficient?: TTIersPayeursCoefficientSuffix;
    isLastArrerageViager?: TTIersPayeursIsLastArrerageViagerSuffix;
    montantCapitalise?: TTiersPayeursMontantCapitaliseSuffix;
  };
  tiersPayeursName?: {
    root: TTiersPayeursRootName;
    montantCapitalise?: TTiersPayeursTotalCapitaliseName;
  };
  editedFieldsName: TEditedFieldsName;
  titles?: {
    victime?: {
      title?: string;
      sommeACapitaliser?: string;
      bareme?: string;
      ageDernierArrerage?: string;
      coefficient?: string;
      isLastArrerageViager?: string;
      montantCapitalise?: string;
      enableCapitalisationDifferee?: string;
    };
    tiersPayeur?: {
      title?: string;
      sommeACapitaliser?: string;
      bareme?: string;
      ageDernierArrerage?: string;
      coefficient?: string;
      isLastArrerageViager?: string;
      montantCapitalise?: string;
    };
    total?: {
      title?: string;
    };
  };
  defaultValuesNames?: {
    victimeSommeACapitaliser?: TDefaultValueSommeACapitaliserName;
  };
  displayTotal?: boolean;
  totalName?: TTotalName;
  displayCalculsBox?: boolean;
  editable?: {
    victime?: {
      montantCapitalise?: boolean;
      ageDateAttribution?: boolean;
    };
  };
}

const ConditionalCalculsBox: React.FC<{
  displayCalculsBox: boolean;
  children: React.ReactNode;
}> = ({ displayCalculsBox, children }) => (
  <>{displayCalculsBox ? <CalculsBox>{children}</CalculsBox> : children}</>
);

export const TotalCapitalisation = <
  TFieldValues extends FieldValues,
  TSommeACapitaliserName extends FieldPath<TFieldValues>,
  TBaremeName extends FieldPath<TFieldValues>,
  TAgeDernierArreragename extends FieldPath<TFieldValues>,
  TCoefficientname extends FieldPath<TFieldValues>,
  TIsLastArrerageViagerName extends FieldPath<TFieldValues>,
  TMontantCapitaliseName extends FieldPath<TFieldValues>,
  TAgeDateAttributionName extends FieldPath<TFieldValues>,
  TEnableCapitalisationDiffereeName extends FieldPath<TFieldValues>,
  TTIersPayeursSommeACapitaliserSuffix extends string,
  TTIersPayeursBaremeSuffix extends string,
  TTIersPayeursAgeDernierArrerageSuffix extends string,
  TTIersPayeursCoefficientSuffix extends string,
  TTIersPayeursIsLastArrerageViagerSuffix extends string,
  TTiersPayeursMontantCapitaliseSuffix extends string,
  TTiersPayeursRootName extends FieldPath<TFieldValues>,
  TTiersPayeursTotalCapitaliseName extends FieldPath<TFieldValues>,
  TDefaultValueSommeACapitaliserName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
  _TTiersPayeursSommeACapitaliserName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursSommeACapitaliserSuffix}`,
  _TTiersPayeursBaremeName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursBaremeSuffix}`,
  _TTiersPayeursAgeDernierArrerageName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursAgeDernierArrerageSuffix}`,
  _TTiersPayeursCoefficientName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursCoefficientSuffix}`,
  _TTiersPayeursIsLastArrerageViagerName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursIsLastArrerageViagerSuffix}`,
  _TTiersPayeursMontantCapitaliseName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTiersPayeursMontantCapitaliseSuffix}`,
  TTotalName extends FieldPath<TFieldValues>,
>({
  control,
  victimeName,
  dateLiquidation,
  baremes,
  dateNaissance,
  dateDeces,
  sexe,
  displayCalculsBox = true,
  tiersPayeurSuffix,
  tiersPayeursName,
  procedure,
  titles,
  displayTotal = true,
  defaultValuesNames,
  totalName,
  editable,
  editedFieldsName,
}: Props<
  TFieldValues,
  TSommeACapitaliserName,
  TBaremeName,
  TAgeDernierArreragename,
  TCoefficientname,
  TIsLastArrerageViagerName,
  TMontantCapitaliseName,
  TAgeDateAttributionName,
  TEnableCapitalisationDiffereeName,
  TTIersPayeursSommeACapitaliserSuffix,
  TTIersPayeursBaremeSuffix,
  TTIersPayeursAgeDernierArrerageSuffix,
  TTIersPayeursCoefficientSuffix,
  TTIersPayeursIsLastArrerageViagerSuffix,
  TTiersPayeursMontantCapitaliseSuffix,
  TTiersPayeursRootName,
  TTiersPayeursTotalCapitaliseName,
  TDefaultValueSommeACapitaliserName,
  TEditedFieldsName,
  _TTiersPayeursSommeACapitaliserName,
  _TTiersPayeursBaremeName,
  _TTiersPayeursAgeDernierArrerageName,
  _TTiersPayeursCoefficientName,
  _TTiersPayeursIsLastArrerageViagerName,
  _TTiersPayeursMontantCapitaliseName,
  TTotalName
>) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={4}>
      <ConditionalCalculsBox displayCalculsBox={displayCalculsBox}>
        {dateDeces && (
          <Stack direction="row" alignItems="center" margin={1}>
            <ErrorOutline
              sx={{ fontWeight: 'bold', opacity: 0.45, fontSize: '20px' }}
            />
            <Typography
              sx={{
                fontWeight: 'bold',
                opacity: 0.45,
              }}
              marginLeft={1}
              variant="body2"
            >
              {t('prejudice.capitalisation.form.victimeDecedee')}
            </Typography>
          </Stack>
        )}
        <TableContainer>
          <LayoutTable size="small">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                  {titles?.victime?.title}
                </TableCell>
              </TableRow>
              <CapitalisationRows
                control={control}
                name={victimeName}
                procedure={procedure}
                dateLiquidation={dateLiquidation}
                baremes={baremes}
                dateNaissance={dateNaissance}
                dateDeces={dateDeces}
                sexe={sexe}
                titles={titles?.victime}
                defaultValuesNames={defaultValuesNames}
                editable={editable}
                editedFieldsName={editedFieldsName}
              />
              {tiersPayeurSuffix && tiersPayeursName ? (
                <TiersPayeursCapitalisationRows
                  control={control}
                  name={tiersPayeursName}
                  procedure={procedure}
                  titles={titles?.tiersPayeur}
                  suffix={tiersPayeurSuffix}
                  editedFieldsName={editedFieldsName}
                />
              ) : null}
              {displayTotal &&
              tiersPayeursName?.montantCapitalise &&
              victimeName.montantCapitalise ? (
                <ComputedPropsForm
                  control={control}
                  watchFields={[
                    victimeName.montantCapitalise,
                    tiersPayeursName?.montantCapitalise,
                  ]}
                  compute={([
                    victimeTotalCapitalise,
                    tiersPayeursTotalCapitalise,
                  ]) => ({
                    props: {
                      totalVictime:
                        Number(victimeTotalCapitalise) -
                          Number(tiersPayeursTotalCapitalise) || 0,
                    },
                  })}
                  render={({ totalVictime }) => (
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        {t('prejudice.capitalisation.form.total.title') ??
                          titles?.total?.title}
                      </TableCell>
                      <TableCell align="right">
                        {totalName ? (
                          <ComputedReadFieldForm
                            control={control}
                            name={totalName}
                            watchFields={[]}
                            customValues={[totalVictime]}
                            compute={(_, values) => values?.[0] || 0}
                            render={(value) => (
                              <Typography fontWeight="bold">
                                {getTropPercuString(value)}
                              </Typography>
                            )}
                          />
                        ) : (
                          <Typography fontWeight="bold">
                            {getTropPercuString(totalVictime)}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                />
              ) : null}
            </TableBody>
          </LayoutTable>
        </TableContainer>
      </ConditionalCalculsBox>
    </Stack>
  );
};
