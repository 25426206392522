import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import {
  MonetaryErosion,
  MonetaryErosionsCoefficientsType,
} from 'src/types/monetaryErosion.type';
import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  PerteDeGainsProfessionnelsRow,
  PertesDeGainsProfessionnels,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { getMontantRevaloriseWrittenPrint } from '../../../../revalorisation.written.print';
import { displayNumerosPiecesWrittenPrint } from '../../../../numerosPieces.written.print';
import { fDate, fDays } from 'src/helpers/formatTime';
import {
  displayFormula,
  displayMontantParPeriodeFormula,
  displayRowsSumFormula,
} from 'src/helpers/print/prejudicesWrittenPrints/formula';
import { displayVictimeNameWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/victime.written.print';
import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';

export const perteDeGainsProfessionnelsWrittenPrint = ({
  formData,
  perteDeGainsProfessionnels,
  dateLiquidation,
  monetaryErosions,
  revalorisationCoefficientsType,
  victime,
  isPGPF,
}: {
  formData:
    | NewPrejudiceFormPerteGainProfessionnelsActuel
    | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
  perteDeGainsProfessionnels: PertesDeGainsProfessionnels;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'mensuel'
  >;
  victime: Victime;
  isPGPF: boolean;
}): (Table | TableOfContents | Paragraph)[] => {
  const getRowText = (row: PerteDeGainsProfessionnelsRow, index: number) =>
    i18next.t(
      'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.perteDeGainsProfessionnels.row.firstPart',
      {
        victimeName: displayVictimeNameWrittenPrint(victime),
        dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        dateFin: row.dateFin ? fDate(row.dateFin) : '',
        duree: fDays(row.duree || 0),
        revenuDeReferencePeriode: fCurrency(row.revenuDeReferencePeriode),
        formula:
          (row.revenuDeReferenceAnnuel || row.revenuDeReferenceAnnuel === 0) &&
          (row.duree || row.duree === 0)
            ? displayMontantParPeriodeFormula({
                montant: row.revenuDeReferenceAnnuel,
                duree: row.duree || 0,
                unite: 'annee',
                options: {
                  withParentheses: true,
                },
                editedFieldsParameters: {
                  formData,
                  fieldName: `perteDeGainsProfessionnels.rows.${index}.revenuDeReferenceAnnuel`,
                },
              })
            : '',
      },
    );

  const getRevenuPercuParPeriodeText = (
    row: PerteDeGainsProfessionnelsRow,
    index: number,
  ) =>
    !!row.revenuPercuParPeriode
      ? `${i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.perteDeGainsProfessionnels.row.revenuPercuParPeriode',
          {
            numerosPieces: displayNumerosPiecesWrittenPrint(
              row.numerosPieces,
              'Parentheses',
            ),
            revenuPercuParPeriode: fCurrency(row.revenuPercuParPeriode),
            formula: row.unite
              ? displayMontantParPeriodeFormula({
                  montant: row.revenuPercuNet,
                  duree: row.duree || 0,
                  unite: row.unite,
                  options: {
                    withParentheses: true,
                  },
                  editedFieldsParameters: {
                    formData,
                    fieldName: `perteDeGainsProfessionnels.rows.${index}.revenuPercuParPeriode`,
                  },
                })
              : '',
          },
        )}${
          !row.montantsDejaRevalorises
            ? getMontantRevaloriseWrittenPrint({
                montant: row.revenuPercuParPeriode,
                anneeOrDateLiquidation: dateLiquidation,
                anneeOrDateMontant: row.dateFin ?? undefined,
                coefficientsType: revalorisationCoefficientsType,
                monetaryErosions,
              })
            : ''
        }`
      : i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.perteDeGainsProfessionnels.row.noRevenuPercuParPeriode',
        );
  const getPerteDeGainParPeriodeText = (
    row: PerteDeGainsProfessionnelsRow,
    index: number,
  ) =>
    !!row.perteDeGainsProfessionnels
      ? i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.perteDeGainsProfessionnels.row.perteDeGainsProfessionnels',
          {
            perteDeGainsProfessionnels: fCurrency(
              row.perteDeGainsProfessionnels,
            ),
            formula: displayFormula({
              formula: i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeGainsProfessionnels.rows.perteDeGainsProfessionnels.formula',
                {
                  revenuPercuParPeriode: fCurrency(
                    !!row.revenuPercuParPeriode &&
                      dateLiquidation &&
                      row.dateFin &&
                      !row.montantsDejaRevalorises
                      ? getMontantRevalorise({
                          montant: row.revenuPercuParPeriode,
                          anneeOrDateLiquidation: dateLiquidation,
                          anneeOrDateMontant: row.dateFin,
                          coefficientsType: revalorisationCoefficientsType,
                          monetaryErosions,
                        }) || row.revenuPercuParPeriode
                      : row.revenuPercuParPeriode,
                  ),
                  revenuDeReferencePeriode: fCurrency(
                    row.revenuDeReferencePeriode,
                  ),
                },
              ),
              editedFieldsParameters: {
                formData,
                fieldName: `perteDeGainsProfessionnels.rows.${index}.perteDeGainsProfessionnels`,
              },
              options: {
                withParentheses: true,
              },
            }),
          },
        )
      : '';
  const totalText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.perteDeGainsProfessionnels.total',
    {
      victimeName: displayVictimeNameWrittenPrint(victime),
      total: fCurrency(perteDeGainsProfessionnels.total),
      formula: displayRowsSumFormula({
        rowValues: perteDeGainsProfessionnels.rows.map(
          (row) => row.perteDeGainsProfessionnels || 0,
        ),
        options: {
          withParentheses: true,
        },
        editedFieldsParameters: {
          formData,
          fieldName: 'perteDeGainsProfessionnels.total',
        },
      }),
    },
  );
  const nonZeroRows = perteDeGainsProfessionnels.rows.filter(
    (row) =>
      !!row.revenuPercuParPeriode ||
      !!row.revenuDeReferenceAnnuel ||
      !!row.revenuDeReferencePeriode ||
      !!row.perteDeGainsProfessionnels,
  );
  return [
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: isPGPF ? 1 : 0,
      },
      text: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.perteDeGainsProfessionnels.title',
      ),
    }),
    ...(nonZeroRows.length === 0
      ? []
      : [
          ...(nonZeroRows.length === 1 && nonZeroRows[0]
            ? [
                getParagraph({
                  children: [
                    ...getTextRun(getRowText(nonZeroRows[0], 0)),
                    ...getTextRun({
                      text: getRevenuPercuParPeriodeText(nonZeroRows[0], 0),
                    }),
                    ...getTextRun({
                      text: getPerteDeGainParPeriodeText(nonZeroRows[0], 0),
                    }),
                  ],
                }),
                getEmptyLine(),
              ]
            : [
                ...nonZeroRows.map((row, index) =>
                  getParagraph({
                    numbering: {
                      reference: 'simple-list',
                      level: 0,
                    },
                    children: [
                      ...getTextRun(getRowText(row, index)),
                      ...getTextRun({
                        text: getRevenuPercuParPeriodeText(row, index),
                      }),
                      ...getTextRun({
                        text: getPerteDeGainParPeriodeText(row, index),
                      }),
                    ],
                  }),
                ),
                getEmptyLine(),
              ]),
        ]),
    getParagraph(totalText),
    ...(!formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
      .indemnitesJournalieresPercuesNet &&
    !formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal
      ? [
          getEmptyLine(),
          getParagraph(
            i18next.t(
              'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.perteDeGainsProfessionnels.noIndemnitesJournalieresPercuesPendantLaPeriodeDArret',
            ),
          ),
        ]
      : []),
  ];
};
