import { Paragraph, Table, TableOfContents } from 'docx';
import {
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
  NumberingType,
} from '../../docxFunctions';
import i18next from 'i18next';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';
import { fDate, fDays } from 'src/helpers/formatTime';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { Procedure } from 'src/types/procedure.type';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { CalculsGlobal } from 'src/constants/calculs';
import { displayRowsSumFormula } from '../formula';

export const dftpWrittenPrint = ({
  victime,
  formData,
  procedure,
}: {
  victime: Victime;
  formData: PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel;
  procedure: Procedure;
}): (Table | TableOfContents | Paragraph)[] => {
  const nonZeroRows = formData.rows.filter(
    (row) => !!row.montantTotal || !!row.commentaires,
  );
  const montantsTotal = CalculsGlobal.sommeMontantRows(
    formData.rows.map((row) => ({ montant: row.montantTotal })),
  );
  const intro = i18next.t(
    'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.writtenPrint.intro',
    { baseJournaliere: fCurrency(formData.forfaitJour ?? 0) },
  );
  const firstPart = i18next.t(
    'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.writtenPrint.firstPart',
    {
      name: displayVictimeNameWrittenPrint(victime),
      baseJournaliere: fCurrency(formData.forfaitJour ?? 0),
    },
  );
  const total = i18next.t(
    'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.writtenPrint.total',
    {
      fractionIndemnisable: fPartResponsabilite(
        procedure.partResponsabilite * 100,
      ),
      total: fCurrency(montantsTotal),
      montant: fCurrency(montantsTotal * procedure.partResponsabilite),
      formule: displayRowsSumFormula({
        rowValues: formData.rows.map((row) => row.montantTotal),
        options: {
          withParentheses: true,
        },
        editedFieldsParameters: {
          formData,
          fieldName: 'rows',
        },
      }),
    },
  );

  const getPeriodes = (
    row: PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow,
  ) =>
    i18next.t(
      'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.writtenPrint.periode',
      {
        percentage: fPercent(row.percentage ?? 0),
        dateDeDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        dateDeFin: row.dateFin ? fDate(row.dateFin) : '',
        totalJours: fDays(row.totalJours),
        piece: displayNumerosPiecesWrittenPrint(
          row.numerosPieces,
          'Parentheses',
        ),
      },
    ) +
    (row.joursHospitalisationADeduire === 0
      ? ''
      : i18next.t(
          'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.writtenPrint.hospitalisation.intro',
          {
            hospitalisation: fDays(row.joursHospitalisationADeduire ?? 0),
          },
        ) +
        (!!row.deduireJoursHospitalisation
          ? '\n' +
            i18next.t(
              'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.writtenPrint.hospitalisation.deduire',
              {
                joursRetenus: fDays(row.totalJours),
                periode: fDays(row.joursPeriode),
                hospitalisation: fDays(row.joursHospitalisationADeduire ?? 0),
              },
            )
          : ''));

  const getNomenclature = (
    row: PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow,
  ) =>
    i18next.t(
      'prejudice.prejudicesTypes.DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.writtenPrint.nomenclature',
      {
        nomenclature: row.commentaires,
        piece: displayNumerosPiecesWrittenPrint(
          row.numerosPieces,
          'Parentheses',
        ),
        dateDeDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        dateDeFin: row.dateFin ? fDate(row.dateFin) : '',
        jours: fDays(row.totalJours),
        montant: fCurrency(row.montantTotal),
      },
    );

  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...(!!nonZeroRows.length && nonZeroRows[0]
      ? [
          getParagraph(intro),
          ...(nonZeroRows.length
            ? [
                getParagraph(`${firstPart} :`),
                getEmptyLine(),
                ...nonZeroRows.flatMap((row) =>
                  getParagraph({
                    numbering: {
                      reference: NumberingType.SIMPLE_LIST,
                      level: 0,
                    },
                    children: row.commentaires
                      ? getTextRun(getNomenclature(row))
                      : getTextRun(getPeriodes(row)),
                  }),
                ),
              ]
            : [
                getParagraph(
                  `${firstPart} ${nonZeroRows[0].commentaires ? getNomenclature(nonZeroRows[0]) : getPeriodes(nonZeroRows[0])}`,
                ),
              ]),
          getEmptyLine(),
          getParagraph(total),
        ]
      : []),
  ];
};
