import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useAppDispatch } from 'src/hooks/store';
import { sendLostPassword, useLostPasswordToken } from 'src/slices/auth';
import {
  Alert,
  AlertColor,
  Stack,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = createUseStyles({
  box: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
  },
  boxWide: {
    minWidth: '550px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    '&:first-child': {
      marginTop: '0px',
    },
    '&:last-child': {
      marginBottom: '0px',
    },
    marginTop: '12px',
    marginBottom: '12px',
  },
  error: {
    color: '#ff4d4f',
  },
  lostPasswordFormContainer: {},
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const tailFormItemLayout = {
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 14,
    offset: 10,
  },
};

const LostPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation();
  const [hidePassword, setHidePassword] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertType, setAlertType] = useState<AlertColor | undefined>(undefined);
  const { token }: any = parse(search);

  const validationSchema = yup.object(
    token
      ? {
          password: yup
            .string()
            .min(10)
            .required(t('forms.rules.required') || ''),
          confirmPassword: yup
            .string()
            .nullable()
            .oneOf(
              [yup.ref('password')],
              t('forms.errorConfirmPasswordNotMatch') || '',
            )
            .required(t('forms.rules.required') || ''),
        }
      : {
          email: yup
            .string()
            .email(t('forms.rules.email') || '')
            .required(t('forms.rules.required') || ''),
        },
  );
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (error) {
        setError(false);
      }

      const resultAction = await (token
        ? dispatch(useLostPasswordToken({ token, password: values.password }))
        : dispatch(sendLostPassword({ email: values.email })));

      if (
        (token ? useLostPasswordToken : sendLostPassword).rejected.match(
          resultAction,
        )
      ) {
        setError(true);
      } else {
        if (token) {
          setAlert(true);
          setAlertContent(t('common.passwordChanged') || '');
          setAlertType('success');
        } else {
          setAlert(true);
          setAlertContent(t('pages.LostPassword.emailSent') || '');
          setAlertType('info');
        }
        navigate('/');
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  const [error, setError] = useState(false);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const renderSendLostPasswordFormItems = () => (
    <TextField
      fullWidth
      type="email"
      label={t('common.email')}
      {...getFieldProps('email')}
      error={Boolean(touched.email && errors.email)}
      helperText={touched.email && errors.email}
    />
  );

  const hidePasswordIcon = () => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setHidePassword(!hidePassword)}
        edge="end"
      >
        {hidePassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  const renderUseLostPasswordTokenFormItems = () => (
    <>
      <TextField
        fullWidth
        type={hidePassword ? 'text' : 'password'}
        label={t('common.password')}
        {...getFieldProps('password')}
        InputProps={{
          endAdornment: hidePasswordIcon(),
        }}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
      />
      <TextField
        fullWidth
        type={hidePassword ? 'text' : 'password'}
        label={t('forms.confirmPassword')}
        {...getFieldProps('confirmPassword')}
        InputProps={{
          endAdornment: hidePasswordIcon(),
        }}
        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
        helperText={touched.confirmPassword && errors.confirmPassword}
      />
    </>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert}
        autoHideDuration={6000}
        onClose={() => setAlert(false)}
      >
        <Alert
          onClose={() => setAlert(false)}
          severity={alertType}
          sx={{ width: '100%' }}
          elevation={3}
        >
          {alertContent}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form
          className={classes.form}
          {...(!!token ? formItemLayout : {})}
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack className={classes.lostPasswordFormContainer} spacing={3}>
            {token
              ? renderUseLostPasswordTokenFormItems()
              : renderSendLostPasswordFormItems()}
            <Stack {...(!!token ? tailFormItemLayout : {})}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {t('common.submit')}
              </LoadingButton>
              {error && (
                <div className={classes.error}>
                  {t(token ? 'forms.errorBadToken' : 'common.errorGeneric')}
                  &nbsp;
                </div>
              )}
              <Typography
                variant="body2"
                align="center"
                sx={{ color: 'text.secondary', mt: 3 }}
              >
                <Link underline="always" to="/" component={RouterLink}>
                  {t('pages.SignUp.backToSignIn')}
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};

export default LostPassword;
