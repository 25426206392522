import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Container, Stack, Typography } from '@mui/material';
import { capitalize, omit } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { ConfirmationDialog } from 'src/components/basic/ConfirmationDialog';
import Sidebar from 'src/components/basic/Sidebar';
import { AutocompleteForm } from 'src/components/forms/AutocompleteForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { theme } from 'src/constants/theme';
import { hideSensitiveFields } from 'src/helpers/organization';
import { dateString } from 'src/helpers/yup';
import { useAppDispatch } from 'src/hooks/store';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { createVictime, updateVictime } from 'src/slices/victime';
import * as yup from 'yup';
import { ComputedDatePickerForm } from '../../components/forms/ComputedDatePickerForm';
import { DatePickerForm } from '../../components/forms/DatePickerForm';
import { TextFieldForm } from '../../components/forms/TextFieldForm';
import {
  TypeDeFaitGenerateur,
  Victime,
  victimeGenders,
  victimeStatuts,
} from '../../types/victime.type';

const useStyles = createUseStyles({
  dossierEditionHeader: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  dossierEditionStackInputs: {
    margin: theme.spacing(2, 0),
  },
  dossierEditionActions: {},
  dossierEditionActionsCancel: {},
  dossierEditionActionsSave: {},
});

type FormData = Pick<
  Victime,
  | 'statut'
  | 'responsableDossier'
  | 'referenceDossierVictime'
  | 'dateAccident'
  | 'nom'
  | 'prenom'
  | 'sexe'
  | 'dateNaissance'
  | 'dateDeces'
  | 'typeDeFaitGenerateur'
>;

interface Props {
  victime?: Victime;
  isSidebar?: boolean;
  isOpen: boolean | undefined;
  closeSideBar: () => void;
}

const VictimeEdition: React.FC<Props> = ({
  victime,
  isSidebar,
  isOpen,
  closeSideBar,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { organization } = useDisplayedOrganization();
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  const validationSchema: yup.Schema<FormData> = yup.object().shape({
    statut: yup.string().required().oneOf(victimeStatuts),
    responsableDossier: yup.string(),
    referenceDossierVictime: yup.string().required(),
    dateAccident: dateString()
      .nullable()
      .required()
      .when('dateNaissance', ([dateNaissance], schema) => {
        return !dateNaissance
          ? schema
          : schema.minDate(
              undefined,
              'Date accident antérieure à la date de naissance',
              dateNaissance,
            );
      }),
    nom: yup.string().defined(),
    prenom: yup.string().defined(),
    sexe: yup.string().oneOf(victimeGenders).defined(),
    dateNaissance: dateString().nullable().required(),
    dateDeces: dateString()
      .nullable()
      .when(
        ['dateAccident', 'dateNaissance'],
        ([dateAccident, dateNaissance], schema) => {
          const errorMessage = `Date de décès antérieure à la date ${
            dateAccident ? "d'accident" : 'de naissance'
          }`;

          return dateAccident || dateNaissance
            ? schema.minDate(
                undefined,
                errorMessage,
                dateAccident || dateNaissance,
              )
            : schema;
        },
      )
      .maxDate(
        undefined,
        t('victime.form.fields.dateDeces.validation.maxToday'),
        new Date(),
      ),
  });

  const redirect = (victimeId: string, index: number) => {
    navigate(`/client/${victimeId}`, { state: { index } });
  };

  const onSubmit = async (values: FormData) => {
    if (!victime) {
      try {
        const newVictime = await dispatch(
          createVictime({
            ...values,
            inTrash: false,
            organization: organization?._id ?? undefined,
          }),
        ).unwrap();
        redirect(newVictime._id, 0);
      } catch (e) {
        console.error(e);
      }
    } else {
      await dispatch(
        updateVictime({
          id: victime._id,
          data: omit(values, ['dateNaissance']),
        }),
      );
    }
    if (isSidebar) {
      closeSideBar();
    }
  };
  const shouldHideSensitiveFields =
    organization && hideSensitiveFields(organization);
  const initialValues: FormData = {
    statut: victime?.statut || 'OUVERT',
    responsableDossier: victime?.responsableDossier || '',
    referenceDossierVictime: victime?.referenceDossierVictime || '',
    dateAccident: victime?.dateAccident || null,
    nom: victime?.nom || '',
    prenom: victime?.prenom || '',
    sexe: victime?.sexe || 'm',
    dateNaissance: victime?.dateNaissance || null,
    dateDeces: victime?.dateDeces || null,
    typeDeFaitGenerateur: victime?.typeDeFaitGenerateur || '',
  };

  const { control, handleSubmit, reset, trigger } = useForm<FormData>({
    defaultValues: initialValues,
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isOpen) {
      reset(initialValues);
    }
  }, [isOpen]);

  const header = (
    <Typography variant="h4" className={classes.dossierEditionHeader}>
      {victime ? t('victime.form.title.edit') : t('victime.form.title.create')}
    </Typography>
  );

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      {victime && (
        <SelectFieldForm
          name="statut"
          control={control}
          variant="outlined"
          label={t('victime.fields.statut.label')}
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="normal"
          options={victimeStatuts.map((statut) => ({
            value: statut,
            label: t(`victime.fields.statut.options.${statut}`),
          }))}
        />
      )}
      <Typography
        variant="h4"
        component="h2"
        style={{ marginTop: theme.spacing(isSidebar ? 2 : 4) }}
      >
        {t('victime.form.titleDossier')}
      </Typography>
      <Stack
        className={classes.dossierEditionStackInputs}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        style={{ marginTop: theme.spacing(4) }}
      >
        {organization ? null : (
          <TextFieldForm
            name="responsableDossier"
            control={control}
            variant="outlined"
            label={t('victime.fields.responsableDossier.label')}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        )}
        <TextFieldForm
          name="referenceDossierVictime"
          control={control}
          variant="outlined"
          label={t('victime.fields.referenceDossierVictime.label')}
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
        />
      </Stack>

      <Typography
        variant="h4"
        component="h2"
        style={{ marginTop: theme.spacing(6) }}
      >
        {t('victime.form.titleVictime')}
      </Typography>
      <Stack
        className={classes.dossierEditionStackInputs}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
      >
        {shouldHideSensitiveFields ? null : (
          <TextFieldForm
            name="prenom"
            control={control}
            variant="outlined"
            label={t('victime.fields.prenom.label')}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        )}
        {shouldHideSensitiveFields ? null : (
          <TextFieldForm
            name="nom"
            control={control}
            variant="outlined"
            label={t('victime.fields.nom.label')}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        )}
        <SelectFieldForm
          name="sexe"
          control={control}
          variant="outlined"
          label={`${t('victime.fields.sexe.label')} *`}
          fullWidth
          InputLabelProps={{ shrink: true }}
          options={victimeGenders
            .filter((value) => value !== 'u')
            .map((value) => ({
              value,
              label: capitalize(
                t(`victime.fields.sexe.options.${value}.label`),
              ),
            }))}
        />
      </Stack>
      <Typography
        variant="body1"
        component="p"
        style={{ marginTop: theme.spacing(5) }}
      >
        {t('victime.form.titleDates')}
      </Typography>
      <Stack
        className={classes.dossierEditionStackInputs}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
      >
        <DatePickerForm
          name="dateNaissance"
          control={control}
          label={t('victime.fields.dateNaissance.label')}
          TextFieldProps={{
            InputLabelProps: { shrink: true },
            sx: { width: 270 },
          }}
          required
          disabled={!!victime}
        />
        <ComputedDatePickerForm
          name="dateDeces"
          control={control}
          label={t('victime.fields.dateDeces.label')}
          TextFieldProps={{
            InputLabelProps: { shrink: true },
            sx: { width: 270 },
          }}
          minDateField="dateAccident"
        />
      </Stack>
      <Typography
        variant="h4"
        component="h2"
        style={{ marginTop: theme.spacing(6) }}
      >
        {t('victime.form.titleFaitDommageable')}
      </Typography>
      <Stack className={classes.dossierEditionStackInputs} spacing={2}>
        <AutocompleteForm
          control={control}
          name="typeDeFaitGenerateur"
          disablePortal
          getOptionLabel={(option) =>
            Object.values(TypeDeFaitGenerateur).includes(
              option as TypeDeFaitGenerateur,
            )
              ? t(`victime.fields.typeDeFaitGenerateur.options.${option}`) ??
                option
              : option
          }
          options={Object.values(TypeDeFaitGenerateur)}
          blurOnSelect={true}
          freeSolo
          sx={{
            maxWidth: 405,
            height: 60,
          }}
          TextFieldProps={{
            label: t('victime.fields.typeDeFaitGenerateur.label'),
          }}
        />
        <ComputedDatePickerForm
          name="dateAccident"
          control={control}
          label={t('victime.fields.dateAccident.label')}
          TextFieldProps={{
            InputLabelProps: { shrink: true },
            sx: { width: 270 },
          }}
          required
          minDateField={'dateNaissance'}
        />
      </Stack>
    </form>
  );

  const footer = (
    <Stack
      className={classes.dossierEditionActions}
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      style={{ width: '100%', marginTop: theme.spacing(isSidebar ? 0 : 5) }}
    >
      <Button
        className={classes.dossierEditionActionsCancel}
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => {
          if (isSidebar) {
            closeSideBar();
          } else {
            navigate(-1);
          }
        }}
      >
        {t('common.cancel')}
      </Button>
      <Button
        className={classes.dossierEditionActionsSave}
        variant="contained"
        color="primary"
        fullWidth
        onClick={async () => {
          if (victime) {
            handleSubmit(onSubmit)();
          } else {
            if (await trigger()) {
              setConfirmationDialogOpen(true);
            }
          }
        }}
      >
        {t('common.save')}
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title={t('victime.form.confirmationDialog.title')}
        description={t('victime.form.confirmationDialog.description') || ''}
        confirmText={t('victime.form.confirmationDialog.confirm') || ''}
        cancelText={t('victime.form.confirmationDialog.cancel') || ''}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          handleSubmit(onSubmit)();
        }}
        onCancel={() => {
          setConfirmationDialogOpen(false);
        }}
      />
    </Stack>
  );

  return !isSidebar ? (
    <Container maxWidth="md" style={{ padding: theme.spacing(2) }}>
      {header}
      {body}
      {footer}
    </Container>
  ) : (
    <Sidebar
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      closeSideBar={() => {
        closeSideBar();
      }}
    />
  );
};

export default VictimeEdition;
