import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { Bareme } from 'src/types/bareme.type';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';

export const BaremeChoice: React.FC<{
  control: Control<PrejudiceFormPerteRevenusProche>;
  capitalisationBaremes: Bareme[];
  procedure: Procedure;
}> = ({ control, capitalisationBaremes, procedure }) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<PrejudiceFormPerteRevenusProche>();

  const defaultBareme = capitalisationBaremes.find(
    (bareme) => bareme._id === procedure.baremes?.baremeCapitalisationId,
  );

  useEffect(() => {
    if (defaultBareme) {
      setValue('baremeCapitalisation', defaultBareme._id);
    }
  }, [defaultBareme, setValue]);

  return (
    <>
      <Typography variant="h6" marginTop={2} marginBottom={2}>
        {t('prejudice.capitalisation.form.fields.bareme.title')}
      </Typography>
      <SelectFieldForm
        control={control}
        name="baremeCapitalisation"
        sx={{ minWidth: 268 }}
        allowNoSelection
        noSelectionText={t(
          'prejudice.capitalisation.form.fields.bareme.noSelectionText',
        )}
        options={capitalisationBaremes.map((bareme) => ({
          label: getCapitalisationBaremeSourceLabel(bareme),
          value: bareme._id,
        }))}
        afterOnChange={(value) => {
          const bareme = capitalisationBaremes.find(
            (bareme) => bareme._id === value,
          );
          if (bareme?.source === 'CJ') {
            setValue('foyer.prejudiceViagerApresLiquidation', 0);
          }
        }}
      />
    </>
  );
};
