import React from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { OrganizationMember } from 'src/types/organization.type';
import { useTranslation } from 'react-i18next';

interface OrganizationMemberMenuProps {
  member: OrganizationMember;
  onClickUpdateMember: (member: OrganizationMember) => void;
  onClickDeleteMember: (member: OrganizationMember) => void;
}

export const OrganizationMemberMenu: React.FC<OrganizationMemberMenuProps> = ({
  onClickUpdateMember,
  member,
  onClickDeleteMember,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);
  return (
    <div>
      <IconButton onClick={handleOpenMenu}>
        <MoreHoriz />
      </IconButton>
      <Menu
        key={member._id}
        anchorEl={anchorEl}
        open={open}
        onClose={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          handleCloseMenu();
        }}
      >
        <MenuItem
          onClick={() => {
            onClickUpdateMember(member);
            handleCloseMenu();
          }}
        >
          {t('pages.OrganizationConfiguration.table.menu.edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickDeleteMember(member);
            handleCloseMenu();
          }}
        >
          {t('pages.OrganizationConfiguration.table.menu.delete')}
        </MenuItem>
      </Menu>
    </div>
  );
};
