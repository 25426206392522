import { ArrowBack } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { contactEmail } from 'src/constants/global';
import { useAppDispatch } from 'src/hooks/store';
import { logout } from 'src/helpers/auth';

export const AccountNotActivated: React.FC = () => {
  const dispatch = useAppDispatch();
  const onClickBack = () => logout(dispatch);
  return (
    <Stack
      height="100%"
      paddingX={9}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h3" paddingBottom={6}>
        🎉 Merci ! 🎉
      </Typography>
      <Typography
        variant="h4"
        color="GrayText"
        textAlign="center"
        paddingBottom={4}
        maxWidth="lg"
      >
        {
          "Votre demande de création de compte a bien été transmise à l'équipe.Nous revenons vers vous d'ici 48h ouvrées pour l'activer. D'ici là, n'hésitez pas à nous contacter si vous avez des questions :"
        }
      </Typography>
      <Typography variant="h4" fontWeight="800">
        {contactEmail}
      </Typography>
      <Button
        startIcon={<ArrowBack />}
        onClick={onClickBack}
        sx={{ textTransform: 'none', marginTop: 6 }}
      >
        <Typography variant="subtitle1" fontWeight="800">
          {"retour à l'écran de connexion"}
        </Typography>
      </Button>
    </Stack>
  );
};
