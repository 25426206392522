import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { NormalTable } from 'src/components/styled';
import { CalculsFormListeProjection } from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  PrejudiceFormPerteGainProfessionelsFormType,
  RenteAccidentDuTravail,
} from 'src/types/prejudice.type';
import * as yup from 'yup';

interface Props {
  open: boolean;
  salaireAnnuelDeReference: number;
  tauxIPP: number | null;
  formType: Exclude<
    PrejudiceFormPerteGainProfessionelsFormType,
    'DECLARATION_FISCALES'
  >;
  onClose: () => void;
}

export const RenteAccidentDuTravailDialog: React.FC<Props> = ({
  open,
  salaireAnnuelDeReference,
  tauxIPP,
  formType,
  onClose,
}) => {
  const { t } = useTranslation();
  const defaultValues = {
    tauxIPP: tauxIPP || 0,
    salaireAnnuelDeReference,
  };

  const validationSchema = yup.object({
    tauxIPP: yup.number().required(),
    salaireAnnuelDeReference: yup.number().required(),
  });
  const { control, reset } = useForm<RenteAccidentDuTravail>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Grid container>
          <Grid item xs={3} />
          <Grid item xs={6}>
            {t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.title',
            )}
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="end">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <NormalTable>
          <TableBody>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.table.tauxIPP.rowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedTextFieldForm
                  name="tauxIPP"
                  watchFields={[]}
                  customValues={[tauxIPP]}
                  compute={(_, customValues) => customValues?.[0] || 0}
                  control={control}
                  InputProps={{
                    inputComponent: MaskNumber as any,
                    inputProps: {
                      numberMask: { scale: 2 },
                      nullable: true,
                    } as IMaskNumberProps,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {formType === 'REVENUS'
                  ? t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.table.salaireAnnuelDeReference.rowHeader',
                    )
                  : t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.table.revenuEspere.rowHeader',
                    )}
              </TableCell>
              <TableCell align="right">
                <ComputedTextFieldForm
                  watchFields={[]}
                  customValues={[salaireAnnuelDeReference]}
                  compute={(_, customValues) => customValues?.[0] || 0}
                  name="salaireAnnuelDeReference"
                  control={control}
                  InputProps={{
                    inputComponent: MaskNumber as any,
                    inputProps: {
                      numberMask: { scale: 2 },
                      suffix: '€',
                      nullable: true,
                    } as IMaskNumberProps,
                  }}
                />
              </TableCell>
            </TableRow>
            <ComputedPropsForm
              control={control}
              watchFields={['tauxIPP', 'salaireAnnuelDeReference']}
              compute={([tauxIPP, salaireAnnuelDeReference]) => ({
                props: {
                  renteAnnuelle: CalculsFormListeProjection.getRenteAnnuelle(
                    Number(tauxIPP),
                    Number(salaireAnnuelDeReference),
                  ),
                  capital: CalculsFormListeProjection.getCapital(
                    Number(tauxIPP),
                  ),
                },
              })}
              render={({ renteAnnuelle, capital }) => (
                <>
                  <TableRow>
                    <TableCell>
                      {t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.table.renteAnnuelle.rowHeader',
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {fCurrency(renteAnnuelle)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.table.capital.rowHeader',
                      )}
                    </TableCell>
                    <TableCell align="right">{fCurrency(capital)}</TableCell>
                  </TableRow>
                </>
              )}
            />
          </TableBody>
        </NormalTable>
        <Typography variant="h5">
          {t(
            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.informationsUtiles.title',
          )}
        </Typography>
        <Typography>
          {t(
            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.informationsUtiles.content',
          )}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
