import React, { useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchAllBaremes } from 'src/slices/bareme';
import { baremeSelectors } from 'src/store/selectors';
import { Bareme, BaremeType } from 'src/types/bareme.type';
import { useTranslation } from 'react-i18next';
import { getAllMonetaryErosions } from 'src/slices/monetaryErosion';

type AllBaremesType = BaremeType | 'monetaryErosions';

export const Baremes: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { baremes } = useAppSelector((state) => ({
    baremes: baremeSelectors.selectAll(state),
  }));
  const baseUrl = window.location.origin;
  useEffect(() => {
    dispatch(fetchAllBaremes());
    dispatch(getAllMonetaryErosions());
  }, []);
  const baremesByType = baremes.reduce(
    (accumulator: Partial<Record<BaremeType, Bareme[]>>, bareme: Bareme) => ({
      ...accumulator,
      [bareme.type]: [...(accumulator[bareme.type] || []), bareme],
    }),
    {},
  );

  const baremeButton = (type: AllBaremesType, title: string) => (
    <div
      style={{ textDecoration: 'none' }}
      onClick={() => {
        window.open(
          `${baseUrl}/bareme/${type}`,
          '',
          `width=${window.innerWidth / 2},height=${window.innerHeight - 100}`,
        );
      }}
    >
      <Button sx={{ width: '400px', maxWidth: '100%' }} variant="contained">
        {title}
      </Button>
    </div>
  );

  return (
    <Box width="100%" height="100%">
      <>
        <Typography variant="h5" textAlign="center">
          Référentiels et barèmes
        </Typography>
        <Stack
          spacing={2}
          alignItems="center"
          paddingTop={4}
          paddingX={{ xs: 1, md: 0 }}
        >
          {(Object.keys(baremesByType) as (keyof typeof baremesByType)[]).map(
            (type) => (
              <React.Fragment key={type}>
                {baremeButton(type, t(`bareme.type.${type}.label.short`))}
              </React.Fragment>
            ),
          )}
          {baremeButton(
            'monetaryErosions',
            t('monetaryErosion.resources.label.short'),
          )}
        </Stack>
      </>
    </Box>
  );
};
