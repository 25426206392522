import { Paragraph, Table, TableOfContents } from 'docx';
import {
  IndemniteRepartieEchus,
  IndemniteRepartieTiersPayeurs,
} from 'src/constants/calculs/type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { Victime } from 'src/types/victime.type';
import { debitSoldeEchusPrint } from '../../prejudicesPrints/debitSoldeEchusPrint';
import { displayFormula } from '../formula';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';

export const indemniteGlobaleARepartirEchusWrittenPrint = ({
  indemniteRepartie,
  partResponsabilite,
  tiersPayeurs,
  victime,
  indemniteGlobaleFormula,
  firstPart,
}: {
  indemniteRepartie: IndemniteRepartieEchus;
  partResponsabilite: number;
  tiersPayeurs: string[];
  victime: Victime;
  indemniteGlobaleFormula: string;
  firstPart: string;
}): (Table | TableOfContents | Paragraph)[] => {
  const getTiersPayeursFormulaText = (
    indemniteTiersPayeur: IndemniteRepartieTiersPayeurs[number],
  ) =>
    indemniteTiersPayeur.montantNonReparti !== indemniteTiersPayeur.montant
      ? i18next.t(
          'prejudice.indemniteGlobaleARepartir.echus.fields.indemniteTiersPayeurs.parTiersPayeur.formula',
          {
            total: fCurrency(
              indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
            ),
            montantNonReparti: fCurrency(
              indemniteTiersPayeur.montantNonReparti,
            ),
            totalNonReparti: fCurrency(
              indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                .totalNonReparti || 0,
            ),
          },
        )
      : '';
  const indemniteTiersPayeursFormulaText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.fields.indemniteTiersPayeurs.debit.formula',
    {
      indemniteGlobale: fCurrency(
        indemniteRepartie.indemniteGlobaleARepartir.solde,
      ),
      indemniteVictime: fCurrency(
        indemniteRepartie.indemniteVictime.arreragesEchus.debit,
      ),
      context: !indemniteRepartie.indemniteVictime.arreragesEchus.debit
        ? 'noVictime'
        : undefined,
    },
  );
  const indemniteGlobaleFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.fullPartResponabilite.indemniteGlobale',
    {
      indemniteGlobale: fCurrency(
        indemniteRepartie.indemniteGlobaleARepartir.solde,
      ),
      indemniteVictime: fCurrency(
        indemniteRepartie.indemniteVictime.arreragesEchus.debit,
      ),
      victimeName: displayVictimeNameWrittenPrint(victime),
      formula: displayFormula({
        formula: indemniteGlobaleFormula,
        options: {
          withParentheses: true,
        },
      }),
    },
  );
  const multipleTiersPayeursFirstPartFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.fullPartResponabilite.multipleTiersPayeursFirstPart',
    {
      indemniteTiersPayeurs: fCurrency(
        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
      ),
      formula: displayFormula({
        formula: indemniteTiersPayeursFormulaText,
        options: {
          withParentheses: true,
        },
      }),
    },
  );

  const getTiersPayeurRowFullPartResponsabiliteText = (
    tiersPayeur: IndemniteRepartieTiersPayeurs[number],
  ) =>
    i18next.t(
      'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.fullPartResponabilite.tiersPayeurRow',
      {
        montant: fCurrency(tiersPayeur.montant),
        indemniteTiersPayeur: fDecimalNumber(
          (tiersPayeur.montant /
            indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit) *
            indemniteRepartie.indemniteGlobaleARepartir.solde,
          2,
        ),
        indemniteGlobale: fDecimalNumber(
          indemniteRepartie.indemniteGlobaleARepartir.solde,
          2,
        ),
        totalIndemniteTiersPayeurs: fDecimalNumber(
          indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
          2,
        ),
        tiersPayeur: tiersPayeur.tiersPayeur,
        formula: displayFormula({
          formula: getTiersPayeursFormulaText(tiersPayeur),
          options: {
            withParentheses: true,
          },
        }),
      },
    );

  const nonZeroArreragesEchusTiersPayeursParTiersPayeur =
    indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.parTiersPayeur?.filter(
      (tiersPayeur) => !!tiersPayeur.montant,
    );

  const oneTiersPayeurFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.fullPartResponabilite.oneTiersPayeur',
    {
      indemniteTiersPayeurs: fCurrency(
        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
      ),
      tiersPayeur:
        nonZeroArreragesEchusTiersPayeursParTiersPayeur?.[0]?.tiersPayeur,
      formula: displayFormula({
        formula: indemniteTiersPayeursFormulaText,
        options: {
          withParentheses: true,
        },
      }),
    },
  );

  const indemniteGlobaleNonFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.nonFullPartResponsabilite.indemniteGlobale',
    {
      montantPartResponsabilite: fCurrency(
        indemniteRepartie.indemniteGlobaleARepartir.solde,
      ),
      montant: fCurrency(
        indemniteRepartie.indemniteGlobaleARepartir.solde / partResponsabilite,
      ),
      partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
      formula: displayFormula({
        formula: indemniteGlobaleFormula,
        options: {
          withParentheses: true,
        },
      }),
    },
  );

  const noTiersPayeurNonFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.nonFullPartResponsabilite.noTiersPayeur',
    {
      indemniteVictime: fCurrency(
        indemniteRepartie.indemniteVictime.arreragesEchus.debit,
      ),
      victimeName: displayVictimeNameWrittenPrint(victime),
    },
  );

  const victimeNonFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.nonFullPartResponsabilite.indemniteVictime',
    {
      victimeName: displayVictimeNameWrittenPrint(victime),
      indemniteVictime: fCurrency(
        indemniteRepartie.indemniteVictime.arreragesEchus.debit,
      ),
    },
  );

  const noVictimeText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.noVictime',
  );

  const noVictimeMultipleTiersPayeursText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.noVictimeMultipleTiersPayeurs',
  );

  const multipleTiersPayeursFirstPartNonFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.nonFullPartResponsabilite.multipleTiersPayeursFirstPart',
    {
      indemniteTiersPayeurs: fCurrency(
        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
      ),
      formula: displayFormula({
        formula: indemniteTiersPayeursFormulaText,
        options: {
          withParentheses: true,
        },
      }),
    },
  );

  const getTiersPayeurRowNonFullPartResponsabiliteText = (
    tiersPayeur: IndemniteRepartieTiersPayeurs[number],
  ) =>
    i18next.t(
      'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.nonFullPartResponsabilite.tiersPayeurRow',
      {
        montant: fCurrency(tiersPayeur.montant),
        indemniteTiersPayeur: fDecimalNumber(
          (tiersPayeur.montant /
            indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit) *
            indemniteRepartie.indemniteGlobaleARepartir.solde,
          2,
        ),
        indemniteGlobale: fDecimalNumber(
          indemniteRepartie.indemniteGlobaleARepartir.solde,
          2,
        ),
        totalIndemniteTiersPayeurs: fDecimalNumber(
          indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
          2,
        ),
        tiersPayeur: tiersPayeur.tiersPayeur,
        formula: displayFormula({
          formula: getTiersPayeursFormulaText(tiersPayeur),
          options: {
            withParentheses: true,
          },
        }),
      },
    );

  const oneTiersPayeurNonFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.nonFullPartResponsabilite.oneTiersPayeur',
    {
      indemniteTiersPayeurs: fCurrency(
        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
      ),
      tiersPayeur: tiersPayeurs[0],
      formula: displayFormula({
        formula: indemniteTiersPayeursFormulaText,
        options: {
          withParentheses: true,
        },
      }),
    },
  );

  if (partResponsabilite === 1) {
    return [
      getParagraph({
        children: [
          ...getTextRun(firstPart),
          ...getTextRun(indemniteGlobaleFullPartResponsabiliteText),
        ],
      }),
      ...(indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit === 0
        ? []
        : (nonZeroArreragesEchusTiersPayeursParTiersPayeur ?? []).length > 1
          ? [
              getParagraph(
                multipleTiersPayeursFirstPartFullPartResponsabiliteText,
              ),
              ...(nonZeroArreragesEchusTiersPayeursParTiersPayeur ?? [])
                .filter((tiersPayeur) => !!tiersPayeur.montant)
                .map((tiersPayeur) =>
                  getParagraph(
                    getTiersPayeurRowFullPartResponsabiliteText(tiersPayeur),
                  ),
                ),
            ]
          : (nonZeroArreragesEchusTiersPayeursParTiersPayeur ?? []).length === 1
            ? [getParagraph(oneTiersPayeurFullPartResponsabiliteText)]
            : []),
      getEmptyLine(),
      ...debitSoldeEchusPrint({
        indemniteGlobaleARepartir: indemniteRepartie,
        partResponsabilite,
        displayTitle: false,
      }),
    ];
  } else {
    return [
      getParagraph({
        children: [
          ...getTextRun(firstPart),
          ...getTextRun(indemniteGlobaleNonFullPartResponsabiliteText),
        ],
      }),
      ...(indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit === 0
        ? [getParagraph(noTiersPayeurNonFullPartResponsabiliteText)]
        : (nonZeroArreragesEchusTiersPayeursParTiersPayeur ?? []).length > 1
          ? [
              ...(indemniteRepartie.indemniteVictime.arreragesEchus.debit !== 0
                ? [getParagraph(victimeNonFullPartResponsabiliteText)]
                : []),
              getParagraph(
                multipleTiersPayeursFirstPartNonFullPartResponsabiliteText,
              ),
              getEmptyLine(),
              ...(nonZeroArreragesEchusTiersPayeursParTiersPayeur ?? []).map(
                (tiersPayeur) =>
                  getParagraph(
                    getTiersPayeurRowNonFullPartResponsabiliteText(tiersPayeur),
                  ),
              ),
              ...(indemniteRepartie.indemniteVictime.arreragesEchus.debit === 0
                ? [getParagraph(noVictimeMultipleTiersPayeursText)]
                : []),
            ]
          : (nonZeroArreragesEchusTiersPayeursParTiersPayeur ?? []).length === 1
            ? [
                ...(indemniteRepartie.indemniteVictime.arreragesEchus.debit !==
                0
                  ? [getParagraph(victimeNonFullPartResponsabiliteText)]
                  : []),
                getParagraph(oneTiersPayeurNonFullPartResponsabiliteText),
                ...(indemniteRepartie.indemniteVictime.arreragesEchus.debit ===
                0
                  ? [getParagraph(noVictimeText)]
                  : []),
              ]
            : []),
      getEmptyLine(),
      ...debitSoldeEchusPrint({
        indemniteGlobaleARepartir: indemniteRepartie,
        partResponsabilite,
        displayTitle: false,
      }),
    ];
  }
};
