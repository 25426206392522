import { Procedure } from './procedure.type';
import { Event } from './event.type';
export const victimeGenders = ['m', 'f', 'u'] as const;
export type VictimeGender = (typeof victimeGenders)[number];
export interface Victime {
  _id: string;
  inTrash: boolean;
  statut: VictimeStatut;
  responsableDossier?: string;
  referenceDossierVictime: string | '';
  dateAccident: string | null;
  nom: string;
  prenom: string;
  sexe: VictimeGender;
  dateNaissance: string | null;
  dateDeces?: string | null;
  procedures: Procedure[];
  events: Event[];
  typeDeFaitGenerateur?: TypeDeFaitGenerateur | string | null;
  roles?: Record<string, VictimeUserRole>;
  organization?: string;
  createdAt: string;
  updatedAt: string;
}

export enum TypeDeFaitGenerateur {
  accidentDeLaCirculation = 'ACCIDENT_DE_LA_CIRCULATION',
  accidentMedical = 'ACCIDENT_MEDICAL',
  violences = 'VIOLENCES',
  accidentDuTravail = 'ACCIDENT_DU_TRAVAIL',
}
export const victimeStatuts = ['OUVERT', 'FERME'] as const;
export type VictimeStatut = (typeof victimeStatuts)[number];
/* DTO */

export type CreateVictimeDto = Omit<
  Victime,
  | '_id'
  | 'createdAt'
  | 'updatedAt'
  | 'procedures'
  | 'events'
  | 'statut'
  | 'victimesIndirectes'
>;

export type UpdateVictimeDto = Partial<
  Omit<
    Victime,
    | '_id'
    | 'createdAt'
    | 'updatedAt'
    | 'procedures'
    | 'events'
    | 'victimesIndirectes'
    | 'sexe'
    | 'dateNaissance'
  >
>;

export enum VictimeUserRole {
  admin = 'victime_admin',
  editor = 'victime_editor',
  reader = 'victime_reader',
}
export type UpdateVictimeRolesDto = {
  roles: {
    userId: string;
    role: VictimeUserRole;
  }[];
};
