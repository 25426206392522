import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { LayoutTable, NormalTable } from 'src/components/styled';
import { fCurrency } from 'src/helpers/formatNumber';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { CalculsGlobal } from 'src/constants/calculs';
import { HiddenFieldForm } from 'src/components/forms/HiddenFieldForm';

interface Props {
  control: Control<PrejudiceFormPerteRevenusProche>;
  procedure: Procedure;
}

export const PerteConjoint: React.FC<Props> = ({ control, procedure }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableContainer sx={{ maxWidth: '700px' }}>
        <LayoutTable>
          <TableBody>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.total.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedReadFieldForm
                  control={control}
                  name="conjoint.total"
                  watchFields={['foyer.prejudiceViager', 'enfants.total']}
                  compute={(values) => {
                    const [prejudiceViagerFoyer, totalEnfants] =
                      values.map(Number);

                    return (prejudiceViagerFoyer || 0) - (totalEnfants || 0);
                  }}
                  render={(value) => <>{fCurrency(value)}</>}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </LayoutTable>
      </TableContainer>
      <NormalTable>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="left">
              {t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.conjoint.capitauxDeces.title',
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            {procedure.tiersPayeurs.map((tiersPayeur, index) => (
              <TableCell key={index} align="center">
                {tiersPayeur}
              </TableCell>
            ))}
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.conjoint.capitauxDeces.total.columnHeader',
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {procedure.tiersPayeurs.map((tiersPayeur, tiersPayeursIndex) => (
              <TableCell key={tiersPayeursIndex} align="center">
                <HiddenFieldForm
                  control={control}
                  name={`conjoint.capitauxDeces.tiersPayeurs.${tiersPayeursIndex}.tiersPayeur`}
                  value={tiersPayeur}
                />
                <TextFieldForm
                  control={control}
                  name={`conjoint.capitauxDeces.tiersPayeurs.${tiersPayeursIndex}.montant`}
                  InputProps={{
                    inputComponent: MaskNumber as any,
                    inputProps: {
                      numberMask: { scale: 2 },
                      suffix: '€',
                    } as IMaskNumberProps,
                  }}
                />
              </TableCell>
            ))}
            <TableCell align="center">
              <ComputedReadFieldForm
                control={control}
                name="conjoint.capitauxDeces.total"
                watchFields={['conjoint.capitauxDeces.tiersPayeurs']}
                compute={([tiersPayeurs]) => {
                  return (
                    CalculsGlobal.sum(
                      tiersPayeurs.map(({ montant }) => montant || 0),
                    ) || 0
                  );
                }}
                render={(value) => <>{fCurrency(value)}</>}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </NormalTable>
      <TableContainer sx={{ maxWidth: '700px' }}>
        <LayoutTable>
          <TableBody>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.totalApresCapitauxDeces.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedReadFieldForm
                  control={control}
                  name="conjoint.totalApresCapitauxDeces"
                  watchFields={[
                    'conjoint.total',
                    'conjoint.capitauxDeces.total',
                  ]}
                  compute={([totalConjoint, conjointCapitauxDecesTotal]) =>
                    (totalConjoint || 0) - (conjointCapitauxDecesTotal || 0)
                  }
                  render={(value) => <>{fCurrency(value)}</>}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </LayoutTable>
      </TableContainer>
    </>
  );
};
