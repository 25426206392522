import React from 'react';
import { Help } from '@mui/icons-material';
import {
  IconButton,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  headerLabel: string;
  tooltipTitle: string | NonNullable<ReactNode>;
  justifyContent?: StackProps['justifyContent'];
}
export const TooltipTableHeader: React.FC<Props> = ({
  headerLabel,
  tooltipTitle,
  justifyContent,
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent={justifyContent}>
      <span>{headerLabel}</span>
      <Tooltip
        title={
          typeof tooltipTitle === 'string' ? (
            <Typography whiteSpace="pre-line" maxHeight="400px" overflow="auto">
              {tooltipTitle}
            </Typography>
          ) : (
            tooltipTitle
          )
        }
        placement="bottom-end"
      >
        <IconButton>
          <Help />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
