import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { askForQuote } from 'src/slices/subscription';
import { SubscriptionQuote } from 'src/types/subscription.type';
import * as yup from 'yup';
import { TextFieldForm } from '../forms/TextFieldForm';
import { Button, Stack } from '@mui/material';
import { MaskPhoneNumber } from '../masks/MaskPhoneNumber';
import { handleNotification } from 'src/helpers/notifications';
import { MaskNumber } from '../masks/MaskNumber';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';

type FormData = Omit<
  SubscriptionQuote,
  'numberOfUsersLicensesNeeded' | 'organizationId'
> & {
  numberOfUsersLicensesNeeded: number | null;
};
interface Props {
  isOrganizationActivation?: true;
}
export const AskForQuote: React.FC<Props> = ({ isOrganizationActivation }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { organization } = useDisplayedOrganization();
  const { control, handleSubmit, reset } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      phoneNumber: '',
      numberOfUsersLicensesNeeded: null,
      message: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        phoneNumber: yup.string().required(),
        numberOfUsersLicensesNeeded: yup.number().required(),
        message: yup.string().optional(),
      }),
    ),
  });

  const onSubmit = async (data: FormData) => {
    if (!organization?._id) {
      return;
    }
    await handleNotification(
      dispatch(
        askForQuote({
          organizationId: organization._id,
          dto: {
            ...data,
            numberOfUsersLicensesNeeded:
              data.numberOfUsersLicensesNeeded ?? undefined,
          },
        }),
      ),
      t('pages.SubscriptionOptions.manual.askForQuote.success'),
      t('pages.SubscriptionOptions.manual.askForQuote.error'),
      enqueueSnackbar,
      closeSnackbar,
    );
    reset();
  };
  return (
    <Stack
      spacing={2}
      direction="column"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      width="100%"
    >
      <TextFieldForm
        control={control}
        name="phoneNumber"
        label={`${t(
          'pages.SubscriptionOptions.manual.askForQuote.phone.label',
        )}*`}
        fullWidth
        InputProps={{
          inputComponent: MaskPhoneNumber as any,
        }}
      />
      <TextFieldForm
        control={control}
        name="numberOfUsersLicensesNeeded"
        label={`${
          isOrganizationActivation
            ? t(
                'pages.SubscriptionOptions.manual.askForQuote.numberOfUsersLicensesNeeded.labelMaxNumberOfUsers',
              )
            : t(
                'pages.SubscriptionOptions.manual.askForQuote.numberOfUsersLicensesNeeded.label',
              )
        }*`}
        fullWidth
        InputProps={{
          inputComponent: MaskNumber as any,
          inputProps: {
            min: 1,
            nullable: true,
          },
        }}
      />
      <TextFieldForm
        control={control}
        name="message"
        label={t('pages.SubscriptionOptions.manual.askForQuote.message.label')}
        multiline
        minRows={4}
        fullWidth
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ alignSelf: 'end' }}
      >
        {t('common.send')}
      </Button>
    </Stack>
  );
};
