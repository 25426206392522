import { Paragraph, Table, TableOfContents, TextRun } from 'docx';
import {
  ListeProjectionAgrement,
  ListeProjectionAgrementRow,
  Prejudice,
  PrejudiceFormListeProjection,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { getParagraph, getTextRun } from 'src/helpers/print/docxFunctions';
import i18next from 'i18next';
import { displayNumerosPiecesWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/numerosPieces.written.print';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayVictimeNameWrittenPrint } from '../../victime.written.print';
import { prejudicesData } from 'src/constants/prejudices';

export const agrementWrittenPrint = ({
  victime,
  formData,
  prejudice,
}: {
  victime: Victime;
  formData: Omit<PrejudiceFormListeProjection, 'prejudiceValues'> & {
    prejudiceValues: ListeProjectionAgrement;
  };
  prejudice: Pick<Prejudice, 'type'>;
}): (Table | TableOfContents | Paragraph)[] => {
  const getRowTextRuns = (row: ListeProjectionAgrementRow): TextRun[] => [
    ...getTextRun(
      i18next.t(
        'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_AGREMENT.writtenPrint.row',
        {
          typePrejudice: row.typePrejudice,
          montant: fCurrency(row.montant),
          numerosPieces: displayNumerosPiecesWrittenPrint(
            row.numerosPieces,
            'Parentheses',
          ),
        },
      ),
    ),
  ];

  const rows = [
    'PREJUDICES_EXTRA_PATRIMONIAUX_TEMPORAIRES',
    'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS',
  ].includes(prejudicesData[prejudice.type].categorie)
    ? formData.prejudiceValues.rows
    : formData.prejudiceValues.rows.filter((row) => !!row.montant);

  const rowsParagraphsByNumberOfRows: Record<
    'noRow' | 'oneRow' | 'multipleRows',
    Paragraph[]
  > = {
    noRow: [],
    oneRow: rows[0]
      ? [
          getParagraph({
            children: [
              ...getTextRun(
                i18next.t(
                  `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.rows.firstPart`,
                  {},
                ),
              ),
              ...getTextRun(
                i18next.t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_AGREMENT.writtenPrint.secondPart',
                  {
                    victimeName: displayVictimeNameWrittenPrint(victime),
                  },
                ),
              ),
              ...getRowTextRuns(rows[0]),
            ],
          }),
        ]
      : [],
    multipleRows: [
      getParagraph({
        children: [
          ...getTextRun(
            i18next.t(
              `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.rows.firstPart`,
              {},
            ),
          ),
          ...getTextRun(
            i18next.t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_AGREMENT.writtenPrint.secondPart',
              {
                victimeName: displayVictimeNameWrittenPrint(victime),
              },
            ),
          ),
        ],
      }),
      ...rows.map((row) =>
        getParagraph({
          numbering: {
            reference: 'simple-list',
            level: 0,
          },
          children: [...getRowTextRuns(row)],
        }),
      ),
    ],
  };
  return [
    ...rowsParagraphsByNumberOfRows[
      rows.length === 0
        ? 'noRow'
        : rows.length === 1
          ? 'oneRow'
          : 'multipleRows'
    ],
  ];
};
