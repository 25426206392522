import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  CalculsFormListeProjection,
  CalculsGlobal,
} from 'src/constants/calculs';
import { fAge, fDate, fYears } from 'src/helpers/formatTime';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import {
  ListeProjectionIncidenceProfessionelle,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  PrejudiceFormListeProjection,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { displayNumerosPiecesWrittenPrint } from '../../../numerosPieces.written.print';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  fCoefficientCapitalisation,
  fPartResponsabilite,
} from 'src/helpers/formatValues';
import { Procedure } from 'src/types/procedure.type';
import { displayFormula, displayRowsSumFormula } from '../../../formula';
import { displayVictimeNameWrittenPrint } from '../../../victime.written.print';

export const incidenceProfessionnelleWrittenPrint = ({
  formData,
  victime,
  PGPFReliquat,
  procedure,
}: {
  formData: PrejudiceFormListeProjection & {
    prejudiceValues: ListeProjectionIncidenceProfessionelle;
  };
  victime: Victime;
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  procedure: Procedure;
}): (TableOfContents | Paragraph | Table)[] => {
  const prejudiceValues = formData.prejudiceValues;
  const indemniteRepartie =
    CalculsFormListeProjection.getIncidenceProfessionnelleIndemniteRepartie({
      PGPFReliquat,
      incidenceProfessionnelle: prejudiceValues,
      partResponsabilite: procedure.partResponsabilite,
    });
  const { beforePGPFReliquatTotal, IPPGPFReliquat, montantTotal } =
    CalculsFormListeProjection.getIncidenceProfessionnelleIndemnite({
      partResponsabilite: procedure.partResponsabilite,
      PGPFReliquat,
      rows: prejudiceValues.rows,
      perteDeRetraite: prejudiceValues.perteDeRetraite,
    });
  const rows = prejudiceValues.rows.filter((row) => !!row.montant);
  return [
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 0,
      },
      children: getTextRun(
        i18next.t(
          'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.firstPart',
          {
            victimeName: displayVictimeNameWrittenPrint(victime),
            sexe: i18next.t(
              `victime.fields.sexe.options.${victime.sexe}.adjectiveLabel`,
            ),
            ageAccident:
              victime.dateNaissance && victime.dateAccident
                ? fAge(
                    CalculsGlobal.getAgeOrZero(
                      victime.dateNaissance,
                      victime.dateAccident,
                    ),
                  )
                : '',
          },
        ),
      ),
    }),
    ...(rows.length > 0
      ? [
          ...(rows.length > 1 ? [getEmptyLine()] : []),
          ...rows.map((row) =>
            getParagraph({
              ...(rows.length > 1
                ? {
                    numbering: {
                      reference: 'simple-list',
                      level: 0,
                    },
                  }
                : {}),
              children: getTextRun(
                i18next.t(
                  'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.incidenceProfessionelle.row',
                  {
                    numerosPieces: displayNumerosPiecesWrittenPrint(
                      row.numerosPieces,
                      'WithComma',
                    ),
                    libelle: row.libelle,
                    montant: fCurrency(row.montant),
                  },
                ),
              ),
            }),
          ),
        ]
      : []),
    ...(!!prejudiceValues.perteDeRetraite.retraite.perteAnnuelle ||
    !!prejudiceValues.perteDeRetraite.aEchoir.sommeACapitaliser
      ? [
          getEmptyLine(),
          getParagraph({
            numbering: {
              reference: 'numbers-dot-simple',
              level: 0,
            },
            children: getTextRun(
              i18next.t(
                'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.perteDeRetraite.title',
                {
                  dateNaissance: victime.dateNaissance
                    ? fDate(victime.dateNaissance)
                    : '',
                  ageRetraite:
                    victime.dateNaissance &&
                    prejudiceValues.perteDeRetraite.retraite.anneeRetraite
                      ? fAge(
                          prejudiceValues.perteDeRetraite.retraite
                            .anneeRetraite -
                            new Date(victime.dateNaissance).getFullYear(),
                        )
                      : '',
                  perteAnnuelle: fCurrency(
                    prejudiceValues.perteDeRetraite.retraite.perteAnnuelle,
                  ),
                },
              ),
            ),
          }),
          getParagraph(
            i18next.t(
              'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.perteDeRetraite.firstPart',
              {
                dateNaissance: victime.dateNaissance
                  ? fDate(victime.dateNaissance)
                  : '',
                ageRetraite:
                  victime.dateNaissance &&
                  prejudiceValues.perteDeRetraite.retraite.anneeRetraite
                    ? fAge(
                        prejudiceValues.perteDeRetraite.retraite.anneeRetraite -
                          new Date(victime.dateNaissance).getFullYear(),
                      )
                    : '',
                perteAnnuelle: fCurrency(
                  prejudiceValues.perteDeRetraite.retraite.perteAnnuelle,
                ),
              },
            ),
          ),
          ...(prejudiceValues.perteDeRetraite.echus
            .nombreAnneesEntreAnneeRetraiteEtAnneeCalcul > 0
            ? [
                getParagraph(
                  i18next.t(
                    'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.perteDeRetraite.nombreAnneesGreaterThanZero.firstPart',
                    {
                      nombreAnneesEntreAnneeRetraiteEtAnneeCalcul: fYears(
                        prejudiceValues.perteDeRetraite.echus
                          .nombreAnneesEntreAnneeRetraiteEtAnneeCalcul,
                      ),
                      montantAPayerADateCalcul: fCurrency(
                        prejudiceValues.perteDeRetraite.echus
                          .montantAPayerADateCalcul,
                      ),
                    },
                  ),
                ),
              ]
            : []),
          ...(!!prejudiceValues.perteDeRetraite.aEchoir.coefficient
            ? [
                getParagraph({
                  children: [
                    ...getTextRun(
                      i18next.t(
                        'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.perteDeRetraite.aEchoir.firstPart',
                        {
                          montantCapitalise: fCurrency(
                            prejudiceValues.perteDeRetraite.aEchoir
                              .montantCapitalise,
                          ),
                          sommeACapitaliser: fCurrency(
                            prejudiceValues.perteDeRetraite.aEchoir
                              .sommeACapitaliser,
                          ),
                          coefficientCapitalisation: fCoefficientCapitalisation(
                            prejudiceValues.perteDeRetraite.aEchoir.coefficient,
                          ),
                          formula:
                            prejudiceValues.perteDeRetraite.aEchoir
                              .sommeACapitaliser &&
                            prejudiceValues.perteDeRetraite.aEchoir.coefficient
                              ? displayFormula({
                                  formula: i18next.t(
                                    'prejudice.capitalisation.form.fields.montantCapitalise.formula',
                                    {
                                      sommeACapitaliser: fCurrency(
                                        prejudiceValues.perteDeRetraite.aEchoir
                                          .sommeACapitaliser,
                                      ),
                                      coefficientCapitalisation:
                                        fCoefficientCapitalisation(
                                          prejudiceValues.perteDeRetraite
                                            .aEchoir.coefficient,
                                        ),
                                    },
                                  ),
                                  options: {
                                    withParentheses: true,
                                  },
                                  editedFieldsParameters: {
                                    formData,
                                    fieldName: 'montantProratise',
                                  },
                                })
                              : '',
                        },
                      ),
                    ),
                    ...(prejudiceValues.perteDeRetraite.aEchoir
                      .isLastArrerageViager ||
                    prejudiceValues.perteDeRetraite.aEchoir.ageDernierArrerage
                      ? getTextRun(
                          prejudiceValues.perteDeRetraite.aEchoir
                            .isLastArrerageViager
                            ? i18next.t(
                                'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.perteDeRetraite.aEchoir.isLastArrerageViager',
                              )
                            : i18next.t(
                                'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.perteDeRetraite.aEchoir.ageDernierArrerage',
                                {
                                  ageDernierArrerage: prejudiceValues
                                    .perteDeRetraite.aEchoir.ageDernierArrerage
                                    ? fAge(
                                        prejudiceValues.perteDeRetraite.aEchoir
                                          .ageDernierArrerage,
                                      )
                                    : '',
                                },
                              ),
                        )
                      : getTextRun(
                          i18next.t(
                            'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.perteDeRetraite.aEchoir.noAgeDernierArrerage',
                          ),
                        )),
                  ],
                }),
              ]
            : []),
        ]
      : []),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 0,
      },
      children: getTextRun(
        i18next.t(
          'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.firstPart',
          {
            total: fCurrency(beforePGPFReliquatTotal ?? montantTotal),
            formula: displayRowsSumFormula({
              rowValues: [
                ...prejudiceValues.rows.map((row) => row.montant || 0),
                prejudiceValues.perteDeRetraite.echus
                  .montantAPayerADateCalcul || 0,
                prejudiceValues.perteDeRetraite.aEchoir.montantCapitalise || 0,
              ],
              options: {
                withParentheses: true,
              },
              editedFieldsParameters: {
                formData,
                fieldName: 'montantTotal',
              },
            }),
          },
        ),
      ),
    }),
    ...(PGPFReliquat.PGPFReliquat &&
    PGPFReliquat.activatePGPFReliquat &&
    (beforePGPFReliquatTotal || beforePGPFReliquatTotal === 0)
      ? [
          getEmptyLine(),
          getParagraph(
            i18next.t(
              'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.PGPFReliquat.firstPart',
              {
                totalTiersPayeurs: fCurrency(-PGPFReliquat.PGPFReliquat),
              },
            ),
          ),
          getEmptyLine(),
          getParagraph({
            numbering: {
              reference: 'numbers-dot-simple',
              level: 0,
            },
            children: getTextRun(
              i18next.t(
                'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.indemniteGlobaleARepartir.title',
              ),
            ),
          }),
          ...(procedure.partResponsabilite === 1
            ? [
                getParagraph(
                  i18next.t(
                    'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.PGPFReliquat.fullPartResponsabilite',
                    {
                      victimeName: displayVictimeNameWrittenPrint(victime),
                      indemniteVictime: fCurrency(
                        indemniteRepartie.indemniteVictime.total,
                      ),
                      indemniteTiersPayeurs: fCurrency(
                        indemniteRepartie.indemniteTiersPayeurs.total,
                      ),
                    },
                  ),
                ),
              ]
            : [
                getParagraph({
                  children: [
                    ...getTextRun(
                      i18next.t(
                        'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.PGPFReliquat.nonFullPartResponsabilite.indemniteGlobale',
                        {
                          partResponsabilite: fPartResponsabilite(
                            procedure.partResponsabilite * 100,
                          ),
                          indemniteGlobale: fCurrency(
                            indemniteRepartie.indemniteGlobaleARepartir.solde,
                          ),
                          total: fCurrency(beforePGPFReliquatTotal),
                          totalPartResponsabilite: fCurrency(
                            beforePGPFReliquatTotal *
                              procedure.partResponsabilite,
                          ),
                        },
                      ),
                    ),
                    ...getTextRun(
                      i18next.t(
                        'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.PGPFReliquat.nonFullPartResponsabilite.indemniteVictime',
                        {
                          victimeName: displayVictimeNameWrittenPrint(victime),
                          indemniteVictime: fCurrency(
                            indemniteRepartie.indemniteVictime.total,
                          ),
                          count: indemniteRepartie.indemniteVictime.total,
                        },
                      ),
                    ),
                    ...getTextRun(
                      i18next.t(
                        'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.PGPFReliquat.nonFullPartResponsabilite.indemniteTiersPayeurs',
                        {
                          indemniteTiersPayeurs: fCurrency(
                            indemniteRepartie.indemniteTiersPayeurs.total,
                          ),
                        },
                      ),
                    ),
                  ],
                }),
              ]),
          ...(IPPGPFReliquat != null
            ? [
                getParagraph(
                  i18next.t(
                    'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.PGPFReliquat.IPPGPFReliquat',
                  ),
                ),
              ]
            : []),
        ]
      : [
          getParagraph({
            numbering: {
              reference: 'numbers-dot-simple',
              level: 0,
            },
            children: getTextRun(
              i18next.t(
                'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.indemniteGlobaleARepartir.title',
              ),
            ),
          }),
          ...(procedure.partResponsabilite === 1
            ? [
                getParagraph(
                  i18next.t(
                    'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.noPGPFReliquat.fullPartResponsabilite',
                    {
                      victimeName: displayVictimeNameWrittenPrint(victime),
                      total: fCurrency(montantTotal),
                    },
                  ),
                ),
              ]
            : [
                getParagraph(
                  i18next.t(
                    'prejudice.prejudicesTypes.INCIDENCE_PROFESSIONNELLE.writtenPrint.total.noPGPFReliquat.nonFullPartResponsabilite',
                    {
                      victimeName: displayVictimeNameWrittenPrint(victime),
                      partResponsabilite: fPartResponsabilite(
                        procedure.partResponsabilite * 100,
                      ),
                      total: fCurrency(montantTotal),
                      totalPartResponsabilite: fCurrency(
                        montantTotal * procedure.partResponsabilite,
                      ),
                    },
                  ),
                ),
              ]),
        ]),
  ];
};
