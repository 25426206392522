import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  NumberingType,
  getEmptyLine,
  getParagraph,
} from 'src/helpers/print/docxFunctions';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { tableRecapitulatifPrint } from 'src/helpers/print/prejudicesPrints/prejudices/perteDeGain/tableRecapitulatifPrint';
import { CalculsFormNewPerteGainsProfessionnelsFuturs } from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { getRevalorisationIntroductionWrittenPrintIfRevalorise } from '../../../revalorisation.written.print';
import { totalCapitalisationWrittenPrint } from '../../../totalCapitalisation.written.print';
import { Bareme } from 'src/types/bareme.type';
import { indemniteGlobaleARepartirAEchoirWrittenPrint } from '../../../indemniteGlobaleARepartir/indemniteGlobaleARepartirAEchoir.written.print';
import { saisieDirecteStepsWrittenPrint } from './steps/saisieDirecteSteps.written.print';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';
import { rentesPrint } from 'src/helpers/print/prejudicesPrints/rentesPrint';

export const pgpfSaisieDirecteWrittenPrint = ({
  victime,
  procedure,
  formData,
  monetaryErosions,
  baremes,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
}): (Table | TableOfContents | Paragraph)[] => {
  const indemniteRepartie =
    CalculsFormNewPerteGainsProfessionnelsFuturs.getIndemnitesRepartie({
      perteGainProfessionnelsTotal: formData.perteDeGainsProfessionnels.total,
      partResponsabilite: procedure.partResponsabilite,
      renteCapitalisee: formData.capitalisation.montantCapitalise,
      capitalisationTiersPayeurs: formData.capitalisationTiersPayeurs,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
      indemnitesJournalieresPercuesNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .indemnitesJournalieresPercuesNet,
      tiersPayeursTotalCapitalise:
        formData.capitalisationTiersPayeurs.montantCapitalise,
      tiersPayeurs: procedure.tiersPayeurs,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
  });
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      formData,
      formType: 'PERTE_GAINS_PROFESSIONNELS_FUTURS',
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    }),
    getParagraph({
      numbering: {
        reference: NumberingType.NUMBERS_DOT_SIMPLE,
        level: 0,
      },
      text: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_FUTURS.writtenPrint.entreConsolidationEtLiquidationTitle',
      ),
    }),
    ...saisieDirecteStepsWrittenPrint({
      formData,
      victime,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      monetaryErosions,
      isPGPF: false,
    }),
    getParagraph({
      numbering: {
        reference: NumberingType.NUMBERS_DOT_SIMPLE,
        level: 1,
      },
      text: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.tableRecapitulatif.title',
      ),
    }),
    ...tableRecapitulatifPrint(
      formData,
      procedure.partResponsabilite,
      true,
      false,
    ),
    getEmptyLine(),
    ...(!shouldNotDisplayCapitalisation && !formData.isRentesOption
      ? [
          getParagraph({
            numbering: {
              reference: NumberingType.NUMBERS_DOT_SIMPLE,
              level: 0,
            },
            text: i18next.t(
              'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_FUTURS.writtenPrint.capitalisationTitle',
            ),
          }),
          ...totalCapitalisationWrittenPrint({
            capitalisation: formData.capitalisation,
            tiersPayeursCapitalisation: formData.capitalisationTiersPayeurs,
            sommeACapitaliser: formData.capitalisation.sommeACapitaliser,
            baremes,
            formData,
            montantCapitaliseName: 'capitalisation.montantCapitalise',
            procedure,
            victime,
            total: formData.totalCapitalisation,
          }),
        ]
      : []),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: NumberingType.NUMBERS_DOT_SIMPLE,
        level: 0,
      },
      text: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.indemniteGlobaleARepartir.title',
      ),
    }),
    ...indemniteGlobaleARepartirAEchoirWrittenPrint({
      indemniteRepartie,
      firstPart: i18next.t(
        `prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_FUTURS.writtenPrint.indemniteGlobaleARepartir.firstPart`,
      ),
      partResponsabilite: procedure.partResponsabilite,
      victime,
      tiersPayeurs: procedure.tiersPayeurs,
      indemniteGlobaleFormula: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_FUTURS.writtenPrint.indemniteGlobaleARepartir.indemniteGlobale.formula',
        {
          perteGainProfessionnelsTotal: fCurrency(
            formData.perteDeGainsProfessionnels.total,
          ),
          montantCapitalise: fCurrency(
            formData.capitalisation.montantCapitalise,
          ),
          partResponsabilite: fPartResponsabilite(
            procedure.partResponsabilite * 100,
          ),
          context:
            !formData.capitalisation.montantCapitalise ||
            !formData.perteDeGainsProfessionnels.total
              ? 'oneValue'
              : undefined,
        },
      ),
      PGPFReliquat: indemniteRepartie.PGPFReliquat,
    }),
    ...(indemniteRepartie.PGPFReliquat.total &&
    indemniteRepartie.PGPFReliquat.total < 0 &&
    indemniteRepartie.PGPFReliquat.deduitsDuTropPercu
      ? [
          getEmptyLine(),
          getParagraph({
            text: i18next.t(
              'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_FUTURS.writtenPrint.PGPFReliquat',
              {
                tropPercu: fCurrency(-indemniteRepartie.PGPFReliquat.total),
              },
            ),
          }),
        ]
      : []),
    ...(formData.isRentesOption
      ? [
          getEmptyLine(),
          ...rentesPrint({
            montant: formData.rentes?.montant || 0,
          }),
        ]
      : []),
  ];
};
