import React from 'react';
import { Control } from 'react-hook-form';
import {
  PerteGainProfessionnelsActuelSituation,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
} from '../../../../types/prejudice.type';
import { Procedure } from '../../../../types/procedure.type';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { CalculsFormPerteGainsProfessionnelsActuel } from '../../../../constants/calculs';
import { TotalIndemniteGlobaleARepartirEchus } from '../TotalIndemniteGlobaleARepartirEchus';
import { pick } from 'lodash';

interface Props {
  control: Control<OldPrejudiceFormPerteGainProfessionnelsActuel>;
  procedure: Procedure;
}

export const TotalPerteGains: React.FC<Props> = ({ control, procedure }) => {
  return (
    <ComputedPropsForm
      control={control}
      watchFields={['situations']}
      compute={(values) => {
        const situations: PerteGainProfessionnelsActuelSituation[] = values[0];
        return {
          props:
            CalculsFormPerteGainsProfessionnelsActuel.getIndemnitesRepartie(
              situations,
              procedure.tiersPayeurs,
              procedure.partResponsabilite,
            ),
        };
      }}
      render={(untypedIndemniteGlobaleARepartir) => {
        const indemniteGlobaleARepartir =
          untypedIndemniteGlobaleARepartir as ReturnType<
            typeof CalculsFormPerteGainsProfessionnelsActuel.getIndemnitesRepartie
          >;
        return (
          <TotalIndemniteGlobaleARepartirEchus
            indemniteGlobaleARepartir={indemniteGlobaleARepartir}
            tropPercu={pick(indemniteGlobaleARepartir, 'tropPercu')}
            partResponsabilite={procedure.partResponsabilite}
          />
        );
      }}
    />
  );
};
