import {
  Interval,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  eachYearOfInterval,
  getDaysInYear,
  intervalToDuration,
  isEqual,
  isValid,
  startOfYear,
  subDays,
} from 'date-fns';
import { ProcedureHospitalisation } from 'src/types/procedure.type';
import { Time } from '../time';
import {
  AgeOrZero,
  IndemniteRepartieEchus,
  IndemniteRepartieEchusIndirecte,
  IndemniteRepartieTiersPayeurs,
  SmicAnnee,
} from './type';
import { areIntervalsOverlappingWithOptions } from 'date-fns/fp';
export abstract class CalculsGlobal {
  static getDate(date: Date | string): Date {
    const dateObject = new Date(date);
    return dateObject;
  }
  static annualiseMontantTotal(
    dateDebut: Date | string,
    dateFin: Date | string,
    montantTotal: number,
  ) {
    return (
      (Time.daysInYear /
        (differenceInDays(new Date(dateFin), new Date(dateDebut)) || 1)) *
      montantTotal
    );
  }

  static sommeMontantRows(rows: { montant: number | string }[]): number {
    return rows.reduce((total, row) => total + Number(row.montant), 0);
  }

  static average(numbers: number[]): number {
    return this.sum(numbers) / numbers.length;
  }

  static sum(numbers: (number | string)[]) {
    return numbers.reduce<number>((total, number) => total + Number(number), 0);
  }

  static max(numbers: number[]): number {
    return Math.max(...numbers);
  }

  static min(numbers: number[]): number {
    return Math.min(...numbers);
  }

  static getDays(dateDebut: Date | string, dateFin: Date | string): number {
    return differenceInDays(new Date(dateFin), new Date(dateDebut)) + 1;
  }
  static getDaysInWeeks(days: number): number {
    return days / Time.daysInWeek;
  }

  static getWeeks(dateDebut: Date | string, dateFin: Date | string): number {
    return this.getDaysInWeeks(this.getDays(dateDebut, dateFin));
  }

  static getMonths(dateDebut: Date | string, dateFin: Date | string): number {
    if (!isValid(new Date(dateDebut)) || !isValid(new Date(dateFin))) {
      return 0;
    }
    if (new Date(dateDebut).getDate() === new Date(dateFin).getDate()) {
      return differenceInMonths(new Date(dateFin), new Date(dateDebut));
    }
    const interval: Interval = {
      start: new Date(dateDebut),
      end: new Date(dateFin),
    };
    const intervals = this.splitIntervalByYear(interval);

    return Object.keys(intervals).reduce((total, year) => {
      const interval = intervals[Number(year)];
      if (!interval) {
        return total;
      }
      const days = this.getDays(
        new Date(interval.start),
        new Date(interval.end),
      );
      const daysInYear = getDaysInYear(Number(year));
      return total + (days / daysInYear) * Time.monthsInYear;
    }, 0);
  }

  static getYears(dateDebut: Date | string, dateFin: Date | string): number {
    const { parsedDateDebut, parsedDateFin } = {
      parsedDateDebut: new Date(dateDebut),
      parsedDateFin: new Date(dateFin),
    };
    if (!isValid(parsedDateDebut) || !isValid(parsedDateFin)) {
      return 0;
    }
    if (
      parsedDateDebut.getMonth() === parsedDateFin.getMonth() &&
      parsedDateDebut.getDate() === parsedDateFin.getDate()
    ) {
      return differenceInYears(parsedDateFin, parsedDateDebut);
    }
    const intervals = this.splitIntervalByYear({
      start: parsedDateDebut,
      end: parsedDateFin,
    });

    const years = Object.keys(intervals).reduce((total, year) => {
      const interval = intervals[Number(year)];
      if (!interval) {
        return total;
      }
      const days = this.getDays(
        new Date(interval.start),
        new Date(interval.end),
      );
      const daysInYear = getDaysInYear(Number(year));
      return total + days / daysInYear;
    }, 0);
    return years;
  }

  static getAge(birthDate: Date | string, to: Date | string): number {
    const start = this.getDate(birthDate);
    const end = this.getDate(to);
    const years = intervalToDuration({
      start,
      end,
    }).years;

    // TODO : handle this
    return years || 0;
  }

  static getAgeOrZero(birthDate: Date | string, to?: Date | string): AgeOrZero {
    const toDate = to ? new Date(to) : new Date();
    // TODO : handle this differently : don't allow `to` to be undefined and instead change all the calls to this function
    if (isEqual(new Date(birthDate), new Date(toDate))) {
      return {
        age: 0,
        isExactlyZero: true,
      };
    } else {
      return {
        age: this.getAge(birthDate, toDate),
        isExactlyZero: false,
      };
    }
  }

  static getIndemnitesRepartie(
    totalResteACharge: number,
    priseEnChargeTiersPayeurs: Record<string, number>,
    partResponsabilite: number,
  ): IndemniteRepartieEchus {
    const indemniteGlobaleBeforePartResponsabilite =
      totalResteACharge + this.sum(Object.values(priseEnChargeTiersPayeurs));
    const indemniteGlobale =
      totalResteACharge * partResponsabilite +
      this.sum(Object.values(priseEnChargeTiersPayeurs)) * partResponsabilite;
    /* Préférence victime */
    const preferenceVictimeDebit = this.min([
      indemniteGlobale,
      totalResteACharge,
    ]);
    const preferenceVictimeSolde = indemniteGlobale - preferenceVictimeDebit;

    /* Tiers payeurs */
    const tiersPayeurs = Object.keys(priseEnChargeTiersPayeurs ?? {});
    const sommeCreanceTiersPayeurs = this.sum(
      Object.values(priseEnChargeTiersPayeurs),
    );
    const creanceTiersPayeurs: IndemniteRepartieTiersPayeurs = tiersPayeurs.map(
      (tiersPayeur) => ({
        tiersPayeur,
        montant:
          ((priseEnChargeTiersPayeurs[tiersPayeur] || 0) *
            preferenceVictimeSolde) /
          (sommeCreanceTiersPayeurs || 1),
        montantNonReparti: priseEnChargeTiersPayeurs[tiersPayeur] || 0,
      }),
    );
    const totalTiersPayeurs = this.sum(
      creanceTiersPayeurs.map(({ montant }) => montant),
    );
    return {
      indemniteGlobaleARepartir: {
        solde: indemniteGlobale,
        beforePartResponsabilite: indemniteGlobaleBeforePartResponsabilite,
      },
      indemniteVictime: {
        arreragesEchus: {
          debit: preferenceVictimeDebit,
          solde: preferenceVictimeSolde,
        },
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          debit: totalTiersPayeurs,
          parTiersPayeur: creanceTiersPayeurs,
          totalNonReparti: sommeCreanceTiersPayeurs,
        },
      },
    };
  }

  static getIndemnitesRepartieIndirecte({
    totalResteACharge,
    parVictimeIndirecte,
    priseEnChargeTiersPayeurs,
    partResponsabilite,
  }: {
    totalResteACharge: number;
    parVictimeIndirecte: Record<string, number>;
    priseEnChargeTiersPayeurs: Record<string, number>;
    partResponsabilite: number;
  }): IndemniteRepartieEchusIndirecte {
    const {
      indemniteGlobaleARepartir,
      indemniteVictime,
      indemniteTiersPayeurs,
    } = this.getIndemnitesRepartie(
      totalResteACharge,
      priseEnChargeTiersPayeurs,
      partResponsabilite,
    );
    const totalVictimesIndirectes = this.sum(
      Object.values(parVictimeIndirecte),
    );
    const parVictimeIndirectePartResponsabilite = Object.entries(
      parVictimeIndirecte,
    ).reduce(
      (accumulator: Record<string, number>, [id, value]) => ({
        ...accumulator,
        [id]:
          (value * indemniteVictime.arreragesEchus.debit) /
          (totalVictimesIndirectes || 1),
      }),
      {},
    );
    return {
      indemniteGlobaleARepartir,
      indemniteProche: {
        arreragesEchus: {
          debit: indemniteVictime.arreragesEchus.debit,
          solde: indemniteVictime.arreragesEchus.solde,
          parVictimeIndirecte: parVictimeIndirectePartResponsabilite,
        },
      },
      indemniteTiersPayeurs,
    };
  }
  static getOverlappingDaysInIntervals(
    intervalLeft: Interval,
    intervalRight: Interval,
  ): number {
    const { intervalLeftStart, intervalLeftEnd } = {
      intervalLeftStart: new Date(intervalLeft.start),
      intervalLeftEnd: new Date(intervalLeft.end),
    };
    const { intervalRightStart, intervalRightEnd } = {
      intervalRightStart: new Date(intervalRight.start),
      intervalRightEnd: new Date(intervalRight.end),
    };
    if (
      intervalLeft.start > intervalLeft.end ||
      intervalRight.start > intervalRight.end ||
      intervalLeft.end < intervalRight.start ||
      intervalLeft.start > intervalRight.end
    ) {
      return 0;
    }
    const internalInterval = {
      start: this.max([
        intervalLeftStart.valueOf(),
        intervalRightStart.valueOf(),
      ]),
      end: this.min([intervalLeftEnd.valueOf(), intervalRightEnd.valueOf()]),
    };

    return this.getDays(
      new Date(internalInterval.start),
      new Date(internalInterval.end),
    );
  }

  static getJoursHospitalisationsADeduire(
    dateDebut: string | null,
    dateFin: string | null,
    hospitalisations: ProcedureHospitalisation[],
  ): number {
    if (dateDebut && dateFin) {
      const intervalLeft = {
        start: new Date(dateDebut),
        end: new Date(dateFin),
      };
      if (intervalLeft.start > intervalLeft.end) {
        return 0;
      }
      return hospitalisations
        .filter(
          (hospitalisation) =>
            hospitalisation.dateFin &&
            hospitalisation.dateDebut &&
            new Date(hospitalisation.dateDebut).getTime() <=
              new Date(hospitalisation.dateFin).getTime(),
        )
        .reduce((total, hospitalisation) => {
          if (!hospitalisation.dateDebut || !hospitalisation.dateFin) {
            return total;
          }
          const hospitalisationInterval = {
            start: new Date(hospitalisation.dateDebut),
            end: new Date(hospitalisation.dateFin),
          };
          return (
            total +
            this.getOverlappingDaysInIntervals(
              intervalLeft,
              hospitalisationInterval,
            )
          );
        }, 0);
    }
    return 0;
  }

  static getIndicesRevalorisationsAnnuel(
    coefficientsErosionMonetaire: Record<number, number>,
    anneeMontant: number,
    anneeLiquidation: number,
  ): {
    indiceLiquidation: number | undefined;
    indiceMontant: number | undefined;
    anneeLiquidation: number | null;
    anneeMontant: number | null;
  } {
    const coefficientsErosionMonetaireAnnees = Object.keys(
      coefficientsErosionMonetaire,
    );
    const findAnneeIndices = (annee: number): number | null => {
      if (annee in coefficientsErosionMonetaire) {
        return annee;
      } else {
        const derniereAnnee = coefficientsErosionMonetaireAnnees.length
          ? Number(
              coefficientsErosionMonetaireAnnees[
                coefficientsErosionMonetaireAnnees.length - 1
              ],
            )
          : null;
        return (derniereAnnee || derniereAnnee === 0) &&
          derniereAnnee in coefficientsErosionMonetaire
          ? derniereAnnee
          : null;
      }
    };
    const anneeIndiceLiquidation = findAnneeIndices(anneeLiquidation);
    const anneeIndiceMontant = findAnneeIndices(anneeMontant);
    return {
      indiceLiquidation: anneeIndiceLiquidation
        ? coefficientsErosionMonetaire[anneeIndiceLiquidation]
        : undefined,
      indiceMontant: anneeIndiceMontant
        ? coefficientsErosionMonetaire[anneeIndiceMontant]
        : undefined,
      anneeLiquidation: anneeIndiceLiquidation,
      anneeMontant: anneeIndiceMontant,
    };
  }

  static getMontantRevaloriseAnnuel(
    montant: number,
    coefficientsErosionMonetaire: Record<number, number>,
    anneeMontant: number,
    anneeLiquidation: number,
  ): number {
    const indicesRevalorisation = this.getIndicesRevalorisationsAnnuel(
      coefficientsErosionMonetaire,
      anneeMontant,
      anneeLiquidation,
    );
    const indiceAnneeLiquidation = indicesRevalorisation.indiceLiquidation;
    const indiceAnneeMontant = indicesRevalorisation.indiceMontant;
    return (
      (montant * (indiceAnneeLiquidation || 0)) / (indiceAnneeMontant || 1)
    );
  }

  static getIndicesRevalorisationsMensuel(
    coefficientsErosionMonetaire: Record<number, Record<number, number>>,
    dateMontant: Date,
    dateLiquidation: Date,
  ): {
    indiceLiquidation: number | undefined;
    indiceMontant: number | undefined;
    anneeLiquidation: number | null;
    moisLiquidation: number | null;
    anneeMontant: number | null;
    moisMontant: number | null;
  } {
    const coefficientsErosionMonetaireAnnees = Object.keys(
      coefficientsErosionMonetaire,
    );
    const findDateIndices = (
      annee: number,
      mois: number,
    ): {
      annee: number | null;
      mois: number | null;
    } => {
      const coefficientsErosionMonetaireAnnee =
        coefficientsErosionMonetaire[annee];
      if (coefficientsErosionMonetaireAnnee) {
        if (mois in coefficientsErosionMonetaireAnnee) {
          return {
            annee,
            mois,
          };
        } else if (Object.keys(coefficientsErosionMonetaireAnnee).length) {
          return {
            annee,
            mois: Number(
              Object.keys(coefficientsErosionMonetaireAnnee)[
                Object.keys(coefficientsErosionMonetaireAnnee).length - 1
              ],
            ),
          };
        }
      }
      const derniereAnnee = Number(
        coefficientsErosionMonetaireAnnees[
          coefficientsErosionMonetaireAnnees.length - 1
        ],
      );
      const coefficientsErosionMonetaireDerniereAnnee =
        derniereAnnee || derniereAnnee === 0
          ? coefficientsErosionMonetaire[derniereAnnee]
          : null;
      if (
        coefficientsErosionMonetaireDerniereAnnee &&
        Object.keys(coefficientsErosionMonetaireDerniereAnnee).length
      ) {
        const derniereAnneeDernierMois = Number(
          Object.keys(coefficientsErosionMonetaireDerniereAnnee)[
            Object.keys(coefficientsErosionMonetaireDerniereAnnee).length - 1
          ],
        );
        return {
          annee: derniereAnnee,
          mois: derniereAnneeDernierMois,
        };
      } else {
        return {
          annee: null,
          mois: null,
        };
      }
    };
    const { mois: moisLiquidation, annee: anneeLiquidation } = findDateIndices(
      dateLiquidation.getFullYear(),
      dateLiquidation.getMonth(),
    );
    const indiceLiquidation =
      (anneeLiquidation || anneeLiquidation === 0) &&
      (moisLiquidation || moisLiquidation === 0)
        ? coefficientsErosionMonetaire[anneeLiquidation]?.[moisLiquidation] || 1
        : 1;
    const { mois: moisMontant, annee: anneeMontant } = findDateIndices(
      dateMontant.getFullYear(),
      dateMontant.getMonth(),
    );
    const indiceMontant =
      (anneeMontant || anneeMontant === 0) && (moisMontant || moisMontant === 0)
        ? coefficientsErosionMonetaire[anneeMontant]?.[moisMontant] || 1
        : 1;
    return {
      indiceLiquidation,
      indiceMontant,
      anneeLiquidation,
      moisLiquidation,
      anneeMontant,
      moisMontant,
    };
  }

  static getMontantRevaloriseMensuel(
    montant: number,
    coefficientsErosionMonetaire: Record<number, Record<number, number>>,
    dateMontant: Date,
    dateLiquidation: Date,
  ): number {
    const indicesRevalorisation = this.getIndicesRevalorisationsMensuel(
      coefficientsErosionMonetaire,
      dateMontant,
      dateLiquidation,
    );
    const indiceAnneeLiquidation = indicesRevalorisation.indiceLiquidation;
    const indiceAnneeMontant = indicesRevalorisation.indiceMontant;

    return (
      (montant * (indiceAnneeLiquidation || 0)) / (indiceAnneeMontant || 1)
    );
  }

  static getRevalorisationsSmics(
    coefficients: Record<number, number>,
    anneeMontant: number,
    anneeLiquidation: number,
  ): {
    smicAnneeLiquidation: SmicAnnee;
    smicAnneeMontant: SmicAnnee;
  } {
    const maxAnnee = this.max(Object.keys(coefficients).map(Number));
    const minAnnee = this.min(Object.keys(coefficients).map(Number));
    const availableAnneeMontant = this.min([
      this.max([anneeMontant, minAnnee]),
      maxAnnee,
    ]);
    const availableAnneeLiquidation = this.min([
      this.max([anneeLiquidation, minAnnee]),
      maxAnnee,
    ]);
    const smicCoefficientMontant = coefficients[availableAnneeMontant];
    const smicCoefficientLiquidation = coefficients[availableAnneeLiquidation];
    if (!smicCoefficientMontant && !smicCoefficientLiquidation) {
      return {
        smicAnneeLiquidation: null,
        smicAnneeMontant: null,
      };
    }
    let smicAnneeMontant: SmicAnnee = null;
    let smicAnneeLiquidation: SmicAnnee = null;
    if (smicCoefficientMontant) {
      smicAnneeMontant = {
        annee: availableAnneeMontant,
        value: smicCoefficientMontant,
      };
    }
    if (smicCoefficientLiquidation) {
      smicAnneeLiquidation = {
        annee: availableAnneeLiquidation,
        value: smicCoefficientLiquidation,
      };
    }

    return {
      smicAnneeLiquidation,
      smicAnneeMontant,
    };
  }

  static getMontantRevaloriseSmic(
    montant: number,
    coefficients: Record<number, number>,
    anneeMontant: number,
    anneeLiquidation: number,
  ): number {
    const smics = this.getRevalorisationsSmics(
      coefficients,
      anneeMontant,
      anneeLiquidation,
    );
    const smicAnneeLiquidation = smics.smicAnneeLiquidation;
    const smicAnneeMontant = smics.smicAnneeMontant;
    return (
      (montant * (smicAnneeLiquidation?.value || 0)) /
      (smicAnneeMontant?.value || 1)
    );
  }

  static getMontantCapitalise(
    montant: number,
    coefficient: number | null,
  ): number {
    return montant * (coefficient || 0);
  }

  static areIntervalsOverlapping(
    intervals: Interval[],
    inclusive = false,
  ): boolean {
    try {
      const sortedIntervals = intervals.sort(
        (firstDate, secondDate) =>
          new Date(firstDate.start).valueOf() -
          new Date(secondDate.start).valueOf(),
      );
      for (let i = 0; i < sortedIntervals.length - 1; i++) {
        const intervalLeft = sortedIntervals[i];
        const intervalRight = sortedIntervals[i + 1];
        if (
          intervalLeft &&
          intervalRight &&
          areIntervalsOverlappingWithOptions(
            { inclusive },
            intervalLeft,
            intervalRight,
          )
        ) {
          return true;
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  static splitIntervalByYear(interval: Interval): Record<number, Interval> {
    try {
      const { start: startDate, end: endDate } = interval;
      const years = eachYearOfInterval(interval);
      const intervals = years.reduce(
        (accumulator, yearStart, index) => {
          const start = index === 0 ? startDate : startOfYear(yearStart);
          const end =
            index === years.length - 1
              ? endDate
              : subDays(
                  startOfYear(new Date(yearStart.getFullYear() + 1, 0, 1)),
                  1,
                );
          accumulator[yearStart.getFullYear()] = { start, end };
          return accumulator;
        },
        {} as Record<number, Interval>,
      );
      return intervals;
    } catch (e) {
      return {};
    }
  }
}
