import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useSnackbar } from 'notistack';

export interface DintilhacTextProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  content: string;
}

export const DintilhacText: React.FC<DintilhacTextProps> = (
  props: DintilhacTextProps,
): React.JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435,
          minWidth: { xs: 0, md: 700 },
        },
      }}
      maxWidth="xs"
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <DialogTitle>{props.label}</DialogTitle>
      <DialogContent
        dividers
        sx={{
          whiteSpace: 'pre-line',
        }}
      >
        {props.content}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} variant="text">
          Fermer
        </Button>
        <Button
          onClick={async () => {
            await navigator.clipboard.writeText(props.content);
            enqueueSnackbar('Texte copié dans le presse-papier', {
              variant: 'info',
              autoHideDuration: 1000,
            });
            props.setOpen(false);
          }}
          variant="contained"
        >
          Copier
        </Button>
      </DialogActions>
    </Dialog>
  );
};
