import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import {
  CalculsFormCapitalisation,
  CalculsGlobal,
} from '../../../constants/calculs';
import { PrejudiceFormCalendrierDepenseCapitalisation } from '../../../types/prejudice.type';
import { TotalIndemniteGlobaleARepartirAEchoir } from './TotalIndemniteGlobaleARepartirAEchoir';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { useTranslation } from 'react-i18next';
import { CalculsRentes } from 'src/constants/calculs/calculsRentes';
import { getCapitalisationCoefficient } from 'src/helpers/prejudices/capitalisation';
import { Victime } from 'src/types/victime.type';
import { Bareme, BaremeCapitalisationValue } from 'src/types/bareme.type';
import { RentesOption } from './Rentes/RenteOption';
interface Props {
  control: Control<PrejudiceFormCalendrierDepenseCapitalisation>;
  tiersPayeurs: string[];
  partResponsabilite: number;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  victime: Victime;
  baremesCapitalisation: Bareme[];
  dateConsolidation: Date | undefined;
  dateDeces: Date | undefined;
}

export const TotalDepensesCapitalisation: React.FC<Props> = ({
  control,
  tiersPayeurs,
  partResponsabilite,
  dateLiquidation,
  monetaryErosions,
  victime,
  baremesCapitalisation,
  dateConsolidation,
  dateDeces,
}) => {
  const { t } = useTranslation();
  return (
    <ComputedPropsForm
      watchFields={[
        'rows',
        'capitalisation',
        'capitalisationTiersPayeurs',
        'revalorisationCoefficientsType',
      ]}
      compute={([
        rows,
        capitalisation,
        capitalisationTiersPayeurs,
        revalorisationCoefficientsType,
      ]) => {
        const indemnitesRepartie =
          CalculsFormCapitalisation.getIndemnitesRepartie({
            rows,
            capitalisation,
            partResponsabilite,
            tiersPayeurs,
            dateLiquidation,
            monetaryErosions,
            revalorisationCoefficientsType,
            capitalisationTiersPayeurs,
            dateConsolidation,
            dateDeces,
          });
        const baremeValues = baremesCapitalisation.find(
          ({ _id }) => _id === capitalisation.bareme,
        )?.values as BaremeCapitalisationValue[] | undefined;
        return {
          props: {
            indemniteGlobaleARepartir: indemnitesRepartie,
            rentesMontant:
              victime.dateNaissance && dateLiquidation && baremeValues
                ? CalculsRentes.getAnnuel(
                    indemnitesRepartie.indemniteVictime.arreragesAEchoir
                      .debit || 0,
                    getCapitalisationCoefficient({
                      sexe: victime.sexe,
                      dateNaissance: victime.dateNaissance,
                      dateLiquidation,
                      ageDernierArrerage: null,
                      ageDateAttribution: CalculsGlobal.getAge(
                        victime.dateNaissance,
                        dateLiquidation,
                      ),
                      isDernierArrerageViager: true,
                      baremeValues,
                    }) || 0,
                  )
                : 0,
          },
        };
      }}
      control={control}
      render={(props) => {
        if (!props) {
          return <></>;
        }
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" paddingBottom={1} height="80px">
                {t('prejudice.indemniteGlobaleARepartir.aEchoir.title')}
              </Typography>
              <TotalIndemniteGlobaleARepartirAEchoir
                indemniteGlobaleARepartir={props.indemniteGlobaleARepartir}
                titles={{
                  indemniteVictime: {
                    arreragesEchus: t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.indemniteRepartie.indemniteVictime.echus.tableRowHeader',
                    ),
                    arreragesAEchoir: t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.indemniteRepartie.indemniteVictime.aEchoir.tableRowHeader',
                    ),
                  },
                  indemniteTiersPayeurs: {
                    arreragesEchus: t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.indemniteRepartie.indemniteTiersPayeurs.echus.tableRowHeader',
                    ),
                    arreragesAEchoir: t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.indemniteRepartie.indemniteTiersPayeurs.aEchoir.tableRowHeader',
                    ),
                  },
                }}
                partResponsabilite={partResponsabilite}
              />
            </Grid>
            <Grid item xs={6}>
              <RentesOption
                control={control}
                name={{
                  renteMontant: 'rentes.montant',
                  isRentesOption: 'isRentesOption',
                  editedFields: 'editedFields',
                }}
                renteDefaultValue={props.rentesMontant}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
