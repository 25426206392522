import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  PerteGainProfessionnelsActuelSituation,
  PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { StepManager } from '../prejudiceFormComponents/perteGainsProfessionnelsActuel/StepManager';
import { RegimeType, REGIME_TYPE_LISTE } from '../../../types/regime.type';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Procedure } from 'src/types/procedure.type';
import { useTranslation } from 'react-i18next';
import { Victime } from 'src/types/victime.type';
import { dateString } from 'src/helpers/yup';
import {
  validationSchemaNumeroPieces,
  getDefaultMinAndMaxDate,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import i18next from 'i18next';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { InputAdornment } from '@mui/material';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';

export const validationSchemaOldFormPerteGainsProfessionnelsActuel = ({
  procedure,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<OldPrejudiceFormPerteGainProfessionnelsActuel> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  return validationSchemaPrejudiceBase.shape({
    dateDebut: dateString()
      .nullable()
      .required()
      .minDate(undefined, minDate.message, minDate.date)
      .maxDate(undefined, maxDate.message, maxDate.date),
    dateFin: dateString()
      .nullable()
      .required()
      .minDate(
        'dateDebut',
        i18next.t('validation.prejudices.dates.minDateDebut') || '',
      )
      .when([], (_, schema) =>
        schema.maxDate(undefined, maxDate.message, maxDate.date),
      ),
    situations: yup
      .array()
      .defined()
      .of(
        yup.object({
          regime: yup
            .string()
            .oneOf([...REGIME_TYPE_LISTE])
            .required(),
          anneesRevenusTheoriques: yup
            .array()
            .defined()
            .length(3)
            .of(
              yup.object({
                numerosPieces: validationSchemaNumeroPieces,
                annee: yup.number().required(),
                netFiscal: yup.number().nullable().optional().defined(),
                netPaye: yup.number().nullable().optional().defined(),
                smicBrut: yup.number().required().min(0).defined(),
                victimeRedevable: yup.boolean().required(),
                montantsDejaRevalorises: yup.boolean().required(),
              }),
            )
            .when('revenuDeReference', {
              is: null,
              then: (schema) =>
                schema.test(
                  'anneesRevenusTheoriques',
                  'Au moins un revenu net payé est requis*',
                  (anneesRevenusTheoriques) =>
                    anneesRevenusTheoriques?.some(
                      (annee) =>
                        annee.netPaye !== undefined && annee.netPaye !== null,
                    ) ?? true,
                ),
              otherwise: (schema) => schema,
            }),
          revenuDeReference: yup.number().nullable().optional().defined(),
          revenuTheorique: yup.number().required(),
          anneesRevenusReels: yup
            .array()
            .defined()
            .of(
              yup.object({
                numerosPieces: validationSchemaNumeroPieces,
                annee: yup.number().required(),
                netFiscal: yup.number().optional().defined(),
                netPaye: yup.number().optional().defined(),
                smicBrut: yup.number().required().min(0).defined(),
                victimeRedevable: yup.boolean().required(),
                montantsDejaRevalorises: yup.boolean().required(),
              }),
            ),
          revenusReelsNet: yup.number().required(),
          tiersPayeursIndemnites: yup
            .array()
            .defined()
            .length(procedure.tiersPayeurs.length)
            .of(
              yup.object({
                numerosPieces: validationSchemaNumeroPieces,
                tiersPayeur: yup.string().required(),
                netPaye: yup.number().required().min(0),
                csgRds: yup.number().nullable().optional().min(0).defined(),
                montantsDejaRevalorises: yup.boolean().optional(),
                anneeTiersPayeursIndemnites: yup
                  .array()
                  .defined()
                  .of(
                    yup.object({
                      annee: yup.number().required(),
                      csgRds: yup
                        .number()
                        .nullable()
                        .optional()
                        .min(0)
                        .defined(),
                    }),
                  ),
                //TODO Find a way to validate those fields
                // .test(
                //   'anneeTiersPayeursIndemnites',
                //   "Au moins une année d'indemnité est requise*",
                //   (anneeTiersPayeursIndemnites) =>
                //     anneeTiersPayeursIndemnites?.some(
                //       (annee) =>
                //       // annee.brut !== undefined &&
                //       // annee.csgRds !== undefined,
                //     ) ?? true,
                // ),
              }),
            ),
          totalIndemnitesNet: yup.number().required(),
          totalIndemnitesCsgCrds: yup.number().optional().min(0).defined(),
          arrerageVictime: yup.number().defined(),
          totalPerteDeGain: yup.number().defined(),
        }),
      ),
    revalorisationCoefficientsType: yup
      .string()
      .oneOf(['smic', 'annuel'] as const)
      .required(),
    formType: yup.string().oneOf(['DECLARATION_FISCALES']).required(),
  });
};
export const defaultPrejudiceFormPerteGainProfessionnelsActuelTiersPayeurIndemnite =
  (
    tiersPayeur: string,
    anneeDateDebut: number,
    anneeDateFin: number,
  ): PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs => ({
    tiersPayeur,
    netPaye: 0,
    csgRds: null,
    numerosPieces: { rows: [] },
    anneeTiersPayeursIndemnites: [
      ...Array(anneeDateFin - anneeDateDebut + 1),
    ].map((_, index) => ({
      annee: anneeDateDebut + index,
      montantsDejaRevalorises: false,
      netFiscal: null,
      brut: null,
      netPaye: null,
      csgRds: null,
    })),
  });

export const defaultPrejudiceFormPerteGainProfessionnelsActuelTiersPayeursIndemnite =
  (
    procedure: Procedure,
    anneeDateDebut: number,
    anneeDateFin: number,
  ): PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[] =>
    procedure.tiersPayeurs.map((tiersPayeur) =>
      defaultPrejudiceFormPerteGainProfessionnelsActuelTiersPayeurIndemnite(
        tiersPayeur,
        anneeDateDebut,
        anneeDateFin,
      ),
    );

export const defaultPrejudiceFormPerteGainProfessionnelsActuelAnneeRevenusReels =
  (annee: number) => ({
    annee,
    netFiscal: 0,
    netPaye: 0,
    smicBrut: 0,
    victimeRedevable: false,
    montantsDejaRevalorises: false,
    numerosPieces: { rows: [] },
  });

export const defaultPrejudiceFormPerteGainProfessionnelsActuelAnneesRevenusReels =
  (anneeDateDebut: number, anneeDateFin: number) =>
    [...Array(anneeDateFin - anneeDateDebut + 1)].map((_, index) =>
      defaultPrejudiceFormPerteGainProfessionnelsActuelAnneeRevenusReels(
        anneeDateDebut + index,
      ),
    );

export const defaultPrejudiceFormPerteGainProfessionnelsActuelAnneeRevenusTheoriques =
  (annee: number) => ({
    annee,
    netFiscal: null,
    netPaye: null,
    smicBrut: 0,
    victimeRedevable: false,
    montantsDejaRevalorises: false,
    numerosPieces: { rows: [] },
  });

export const defaultPrejudiceFormPerteGainProfessionnelsActuelAnneesRevenuTheoriqueValues =
  (
    anneeDateDebut: number,
  ): PerteGainProfessionnelsActuelSituationRevenusTheoriquesAnnee[] =>
    [anneeDateDebut - 3, anneeDateDebut - 2, anneeDateDebut - 1].map((annee) =>
      defaultPrejudiceFormPerteGainProfessionnelsActuelAnneeRevenusTheoriques(
        annee,
      ),
    );

export const defaultPrejudiceFormPerteGainProfessionnelsActuelValues = (
  anneeDateDebut: number,
  anneeDateFin: number,
  procedure: Procedure,
  regime: RegimeType,
): PerteGainProfessionnelsActuelSituation => ({
  regime,
  anneesRevenusTheoriques:
    defaultPrejudiceFormPerteGainProfessionnelsActuelAnneesRevenuTheoriqueValues(
      anneeDateDebut,
    ),
  revenuTheorique: 0,
  anneesRevenusReels:
    defaultPrejudiceFormPerteGainProfessionnelsActuelAnneesRevenusReels(
      anneeDateDebut,
      anneeDateFin,
    ),
  revenusReelsNet: 0,
  revenuDeReference: 0,
  totalPerteDeGain: 0,
  tiersPayeursIndemnites:
    defaultPrejudiceFormPerteGainProfessionnelsActuelTiersPayeursIndemnite(
      procedure,
      anneeDateDebut,
      anneeDateFin,
    ),
  totalIndemnitesNet: 0,
  totalIndemnitesCsgCrds: 0,
  arrerageVictime: 0,
});

export const getDefaultPrejudiceFormPerteGainProfessionnelsActuelSituations = ({
  dateDebut,
  dateFin,
  procedure,
  existingSituations,
}: {
  dateDebut: string | null | undefined;
  dateFin: string | null | undefined;
  procedure: Procedure;
  existingSituations: PerteGainProfessionnelsActuelSituation[];
}): PerteGainProfessionnelsActuelSituation[] =>
  existingSituations.map((situation) => {
    const anneeDateDebut = dateDebut
      ? new Date(dateDebut).getFullYear()
      : undefined;
    const anneeDateFin = dateFin ? new Date(dateFin).getFullYear() : undefined;
    return {
      ...(anneeDateDebut && anneeDateFin
        ? defaultPrejudiceFormPerteGainProfessionnelsActuelValues(
            anneeDateDebut,
            anneeDateFin,
            procedure,
            situation.regime,
          )
        : {}),
      ...situation,
      anneesRevenusTheoriques:
        situation.anneesRevenusTheoriques?.map((anneeRevenusTheoriques) => ({
          ...defaultPrejudiceFormPerteGainProfessionnelsActuelAnneeRevenusTheoriques(
            anneeRevenusTheoriques.annee,
          ),
          ...anneeRevenusTheoriques,
        })) || [],
      anneesRevenusReels:
        situation.anneesRevenusReels?.map((anneeRevenusReels) => ({
          ...defaultPrejudiceFormPerteGainProfessionnelsActuelAnneeRevenusReels(
            anneeRevenusReels.annee,
          ),
          ...anneeRevenusReels,
        })) || [],
      tiersPayeursIndemnites:
        situation.tiersPayeursIndemnites && anneeDateDebut && anneeDateFin
          ? procedure.tiersPayeurs.map((tiersPayeur) => {
              const tiersPayeurIndemnites =
                situation.tiersPayeursIndemnites.find(
                  (tiersPayeurIndemnites) =>
                    tiersPayeurIndemnites.tiersPayeur === tiersPayeur,
                );
              return tiersPayeurIndemnites
                ? {
                    ...defaultPrejudiceFormPerteGainProfessionnelsActuelTiersPayeurIndemnite(
                      tiersPayeur,
                      anneeDateDebut,
                      anneeDateFin,
                    ),
                    ...tiersPayeurIndemnites,
                  }
                : defaultPrejudiceFormPerteGainProfessionnelsActuelTiersPayeurIndemnite(
                    tiersPayeur,
                    anneeDateDebut,
                    anneeDateFin,
                  );
            })
          : [],
    };
  });

export const oldPGPADefaultValues = ({
  procedure,
  values,
  prejudiceType,
}: {
  procedure: Procedure;
  values?: OldPrejudiceFormPerteGainProfessionnelsActuel | undefined;
  prejudiceType: PrejudiceType;
}): OldPrejudiceFormPerteGainProfessionnelsActuel => ({
  ...prejudiceBaseDefaultValues({
    prejudiceType,
    values,
  }),
  notes: values?.notes || '',
  dateDebut: values?.dateDebut || (null as any),
  dateFin: values?.dateFin || (null as any),
  situations: getDefaultPrejudiceFormPerteGainProfessionnelsActuelSituations({
    dateDebut: values?.dateDebut,
    dateFin: values?.dateFin,
    procedure,
    existingSituations: values?.situations || [],
  }),
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'smic',
  formType: values?.formType || 'DECLARATION_FISCALES',
});

type Props = PrejudiceFormProps<OldPrejudiceFormPerteGainProfessionnelsActuel>;

export const OldFormPerteGainsProfessionnelsActuel: React.FC<Props> = ({
  victime,
  procedure,
  dommages,
  values,
  monetaryErosions,
  onPrevious,
  dateLiquidation,
  allNumerosPieces,
  ...props
}) => {
  const { t } = useTranslation();
  const useFormReturn = useForm<OldPrejudiceFormPerteGainProfessionnelsActuel>({
    defaultValues: oldPGPADefaultValues({
      procedure,
      values: values,
      prejudiceType: props.prejudiceType,
    }),
    resolver: yupResolver(
      validationSchemaOldFormPerteGainsProfessionnelsActuel({
        procedure,
        victime,
        prejudiceType: props.prejudiceType,
      }),
    ),
  });
  const { control, trigger, getValues } = useFormReturn;

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<OldPrejudiceFormPerteGainProfessionnelsActuel>
      {...props}
      victime={victime}
      procedure={procedure}
      monetaryErosions={monetaryErosions}
      {...useFormReturn}
      renderPrejudice={({ setAdditionalTitle }) => (
        <Stack direction="column" spacing={4}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
              {
                defaultValue: '',
                context: victime.dateDeces ? 'deces' : 'blessure',
              },
            )}
          />
          <StepManager
            setAdditionalTitle={setAdditionalTitle}
            procedure={procedure}
            dommage={dommages?.[0]}
            victime={victime}
            control={control}
            trigger={trigger}
            values={values}
            getValues={getValues}
            monetaryErosions={monetaryErosions}
            isPGPF={false}
            onPrevious={onPrevious ? onPrevious : undefined}
            dateLiquidation={dateLiquidation}
            tauxIPP={null}
            allNumerosPieces={allNumerosPieces}
            user={props.user}
          />
        </Stack>
      )}
    />
  );
};
