import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  IndemniteRepartieAEchoir,
  PGPFReliquat,
} from 'src/constants/calculs/type';
import { fCurrency } from 'src/helpers/formatNumber';
import { getTropPercuString } from '../../prejudices/pgpfReliquat';
import { getEmptyLine, getParagraph, getTextRun } from '../docxFunctions';
import { simpleVerticalTablePrint } from '../simpleVerticalTablePrint';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const debitSoldeAEchoirPrint = ({
  title,
  indemniteGlobaleARepartir,
  displayTropPercu,
  partResponsabilite,
  PGPFReliquat,
  hide,
  displayTitle = true,
}: {
  title?: string;
  indemniteGlobaleARepartir: Omit<
    IndemniteRepartieAEchoir,
    'indemniteTiersPayeurs'
  > &
    Partial<Pick<IndemniteRepartieAEchoir, 'indemniteTiersPayeurs'>>;
  displayTropPercu?: boolean;
  partResponsabilite: number;
  PGPFReliquat?: PGPFReliquat;
  isRentesOptionSelected?: boolean;
  hide?: {
    indemniteGlobaleARepartir?: boolean;
    indemniteVictime?: {
      arreragesEchus?: boolean;
      arreragesAEchoir?: boolean;
      total?: boolean;
    };
    indemniteTiersPayeurs?: {
      arreragesEchus?: boolean;
      arreragesAEchoir?: boolean;
      total?: boolean;
    };
  };
  displayTitle?: boolean;
}): (Table | TableOfContents | Paragraph)[] => [
  ...(displayTitle
    ? [
        getParagraph({
          children: getTextRun({
            text:
              title ||
              i18next.t(
                'prejudice.indemniteGlobaleARepartir.aEchoir.print.title',
              ),
            bold: true,
          }),
        }),
      ]
    : []),
  getEmptyLine(),
  simpleVerticalTablePrint([
    ...(hide?.indemniteGlobaleARepartir
      ? []
      : [
          {
            label: i18next.t(
              'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteGlobale.print.tableRowHeader',
              {
                partResponsabilite: fPartResponsabilite(
                  partResponsabilite * 100,
                ),
              },
            ),
            value: fCurrency(
              indemniteGlobaleARepartir.indemniteGlobaleARepartir.solde,
            ),
          },
        ]),
    ...(hide?.indemniteVictime?.total
      ? []
      : [
          {
            label: i18next.t(
              'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteVictime.tableRowHeader',
            ),
            value: displayTropPercu
              ? getTropPercuString(
                  indemniteGlobaleARepartir.indemniteVictime.total,
                  PGPFReliquat?.total ?? null,
                )
              : fCurrency(indemniteGlobaleARepartir.indemniteVictime.total),
            bold: true,
          },
        ]),
    ...(hide?.indemniteVictime?.arreragesEchus
      ? []
      : [
          {
            label: i18next.t(
              'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteVictime.echus.tableRowHeader',
            ),
            value: displayTropPercu
              ? getTropPercuString(
                  indemniteGlobaleARepartir.indemniteVictime.arreragesEchus
                    .debit,
                  PGPFReliquat?.echus ?? null,
                  PGPFReliquat?.deduitsDuTropPercu.echus,
                )
              : fCurrency(
                  indemniteGlobaleARepartir.indemniteVictime.arreragesEchus
                    .debit,
                ),
          },
        ]),
    ...(hide?.indemniteVictime?.arreragesAEchoir
      ? []
      : [
          {
            label: i18next.t(
              'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteVictime.aEchoir.tableRowHeader',
            ),
            value: displayTropPercu
              ? getTropPercuString(
                  indemniteGlobaleARepartir.indemniteVictime.arreragesAEchoir
                    .debit,
                  PGPFReliquat?.aEchoir ?? null,
                  PGPFReliquat?.deduitsDuTropPercu.aEchoir,
                )
              : fCurrency(
                  indemniteGlobaleARepartir.indemniteVictime.arreragesAEchoir
                    .debit,
                ),
          },
        ]),
    ...(indemniteGlobaleARepartir.indemniteTiersPayeurs
      ? [
          ...(hide?.indemniteTiersPayeurs?.total
            ? []
            : [
                {
                  label: i18next.t(
                    'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteTiersPayeurs.tableRowHeader',
                  ),
                  value: fCurrency(
                    indemniteGlobaleARepartir.indemniteTiersPayeurs.total,
                  ),
                  bold: true,
                },
              ]),
          ...(hide?.indemniteTiersPayeurs?.arreragesEchus
            ? []
            : [
                {
                  label: i18next.t(
                    'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteTiersPayeurs.echus.tableRowHeader',
                  ),
                  value: fCurrency(
                    indemniteGlobaleARepartir.indemniteTiersPayeurs
                      .arreragesEchus.debit,
                  ),
                },
                ...(
                  indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesEchus
                    .parTiersPayeur || []
                ).map(
                  ({ tiersPayeur, montant }) =>
                    ({
                      label: tiersPayeur,
                      value: fCurrency(montant),
                    }) as const,
                ),
              ]),
          ...(indemniteGlobaleARepartir.indemniteTiersPayeurs
            .arreragesAEchoir && !hide?.indemniteTiersPayeurs?.arreragesAEchoir
            ? [
                {
                  label: i18next.t(
                    'prejudice.indemniteGlobaleARepartir.aEchoir.table.indemniteTiersPayeurs.aEchoir.tableRowHeader',
                  ),
                  value: fCurrency(
                    indemniteGlobaleARepartir.indemniteTiersPayeurs
                      .arreragesAEchoir?.debit,
                  ),
                },
                ...(
                  indemniteGlobaleARepartir.indemniteTiersPayeurs
                    .arreragesAEchoir.parTiersPayeur || []
                ).map(({ tiersPayeur, montant }) => ({
                  label: tiersPayeur,
                  value: fCurrency(montant),
                })),
              ]
            : []),
        ]
      : []),
  ]),
];
