import { Table } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { prejudiceFormTable } from 'src/helpers/print/prejudicesPrints/prejudiceFormTablePrint';
import {
  ListeProjectionAgrementRow,
  PrejudiceFormListeProjection,
} from 'src/types/prejudice.type';

export const prejudiceAgrementPrint = ({
  formData,
}: {
  formData: PrejudiceFormListeProjection;
}): Table[] | undefined => {
  return (
    formData.prejudiceValues?.rows &&
    prejudiceFormTable(
      formData.prejudiceValues.rows as ListeProjectionAgrementRow[],
      [
        {
          name: 'numerosPieces',
          headerLabel: i18next.t('numeroPiece.form.columnHeader'),
          render: (row) =>
            row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
        },
        {
          name: 'typePrejudice',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.maladie.columnHeader',
          ),
        },
        {
          name: 'montant',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_AGREMENT.fields.rows.montant.columnHeader',
          ),
          render: (row) => fCurrency(row.montant),
        },
      ],
    )
  );
};
