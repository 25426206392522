import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { capitalize } from 'lodash';
import { CalculsGlobal } from 'src/constants/calculs';
import { Dommage } from 'src/types/dommage.type';
import { Procedure, ProcedureType } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { fDateLong } from '../formatTime';

import { getEmptyLine, getParagraph, getTextRun } from './docxFunctions';
import { fPartResponsabilite } from '../formatValues';

const getVictimeDates = (victime: Victime) => {
  const age = victime.dateNaissance
    ? CalculsGlobal.getAgeOrZero(victime.dateNaissance)
    : null;
  const ageDeces =
    victime.dateNaissance && victime.dateDeces
      ? CalculsGlobal.getAgeOrZero(victime.dateNaissance, victime.dateDeces)
      : null;
  return `${
    victime.dateNaissance
      ? `né${victime.sexe === 'm' ? '' : 'e'} le ${fDateLong(
          victime.dateNaissance,
        )}${
          victime.dateDeces || !age
            ? ''
            : ` (${i18next.t('forms.fields.age.value', {
                count: age.age,
                context: age.isExactlyZero ? 'exactly_zero' : undefined,
              })})`
        }`
      : ''
  }
${
  victime.dateDeces
    ? `${victime.dateNaissance ? ', ' : ''}décédé${
        victime.sexe === 'f' ? 'e' : ''
      } le ${fDateLong(victime.dateDeces)} ${
        ageDeces
          ? `(${i18next.t('forms.fields.age.value', {
              count: ageDeces.age,
              context: ageDeces.isExactlyZero ? 'exactly_zero' : undefined,
            })})`
          : ''
      }`
    : ''
}`;
};

const getAccident = (victime: Victime) => {
  const ageAccident =
    victime.dateNaissance && victime.dateAccident
      ? CalculsGlobal.getAgeOrZero(victime.dateNaissance, victime.dateAccident)
      : null;
  return victime.dateAccident
    ? i18next.t(
        `print.victime.accident${
          victime.dateNaissance ? '.withAge' : '.withoutAge'
        }`,
        {
          dateAccident: fDateLong(victime.dateAccident),
          ageAccident: ageAccident?.age,
          isAgeAccidentExactlyZero: ageAccident?.isExactlyZero,
        },
      )
    : '';
};
const getConsolidation = (procedure: Procedure) =>
  procedure.dateConsolidation
    ? i18next.t('print.victime.dateConsolidation', {
        dateConsolidation: fDateLong(procedure.dateConsolidation),
      })
    : '';

const getDateLiquidation = (
  dateLiquidation: string,
  procedureType: ProcedureType,
) =>
  capitalize(
    i18next.t('print.victime.dateLiquidation', {
      dateLiquidation: fDateLong(dateLiquidation),
      procedureType,
    }),
  );

const getDateEdition = () =>
  i18next.t('print.victime.dateEdition', {
    dateEdition: fDateLong(new Date()),
  });

const getPartResponsable = (partResponsabilite: number) =>
  i18next.t('print.victime.partResponsabilite', {
    partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
  });

const getVictimeName = (firstName: string, lastName: string) =>
  `${capitalize(firstName)} ${capitalize(lastName)}`;
export const makeVictimePrint = ({
  victime,
  procedure,
}: {
  victime: Victime;
  dommage?: Dommage;
  procedure: Procedure;
}): (Table | Paragraph | TableOfContents)[] => {
  const accidentText = getAccident(victime);
  const consolidationText = getConsolidation(procedure);
  return [
    getParagraph({
      children: getTextRun({
        text: getDateEdition(),
        break: 1,
        bold: true,
        size: 24,
      }),
    }),
    getEmptyLine(2),
    getParagraph({
      text: `${victime.sexe === 'm' ? 'Monsieur' : 'Madame'} ${getVictimeName(
        victime.prenom,
        victime.nom,
      )}, ${getVictimeDates(victime)}`,
    }),
    ...(accidentText
      ? [
          new Paragraph({
            children: getTextRun({ text: accidentText, break: 1 }),
          }),
        ]
      : []),
    ...(procedure.resumeAffaire
      ? [
          new Paragraph({
            children: getTextRun({ text: procedure.resumeAffaire, break: 1 }),
          }),
        ]
      : []),
    new Paragraph({
      children: getTextRun({
        text: getPartResponsable(procedure.partResponsabilite),
        break: 1,
      }),
    }),
    ...(consolidationText
      ? [
          new Paragraph({
            children: getTextRun({ text: consolidationText, break: 1 }),
          }),
        ]
      : []),
    ...(procedure.dateLiquidation && procedure.procedureType
      ? [
          new Paragraph({
            children: getTextRun({
              text: getDateLiquidation(
                procedure.dateLiquidation,
                procedure.procedureType,
              ),
              break: 1,
            }),
          }),
        ]
      : []),
  ];
};
