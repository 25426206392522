import { Close } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makePrint } from 'src/helpers/print/makePrint';
import { makeXlsx } from 'src/helpers/print/makeXlsx';
import {
  getPrintProcedureFileName,
  saveDocument,
} from 'src/helpers/print/saveDocument';
import { Bareme } from 'src/types/bareme.type';
import { Dommage } from 'src/types/dommage.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Prejudice } from 'src/types/prejudice.type';
import { PrintFormat, PrintType } from 'src/types/print.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';

interface Props {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  dommages: Dommage[];
  prejudices: Prejudice[];
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
  open: boolean;
  onClose: () => void;
}

export const PrintProcedureDialog: React.FC<Props> = ({
  victime,
  victimesIndirectes,
  procedure,
  dommages,
  prejudices,
  monetaryErosions,
  baremes,
  open,
  onClose,
}) => {
  // TODO commented until we add pdf support
  // const [savingFormat, setSavingFormat] = useState<PrintFormat>('docx');

  // TODO commented until we add pdf support
  // const onChangeSavingFormat = (
  //   e: React.MouseEvent<HTMLElement, MouseEvent>,
  //   value: PrintFormat,
  // ) => {
  //   setSavingFormat(value);
  // };
  const { t } = useTranslation();
  const [printType, setPrintType] = React.useState<PrintType>('written');
  const [printFormat, setPrintFormat] = React.useState<PrintFormat>('docx');
  const onClickPrint = () => {
    if (!procedure || !prejudices || !monetaryErosions) {
      return;
    }
    const document = () =>
      printFormat === 'docx'
        ? makePrint({
            victime,
            procedure,
            dommages,
            monetaryErosions,
            prejudices,
            baremes,
            victimesIndirectes,
            isWrittenPrint: printType === 'print' ? false : true,
          })
        : makeXlsx({
            victime,
            procedure,
            monetaryErosions,
            prejudices,
            victimesIndirectes,
          });

    const fileName = getPrintProcedureFileName(procedure, victime);
    saveDocument(document(), fileName, printFormat);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Card sx={{ minWidth: '500px' }}>
        <CardHeader
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">
                {t('print.dialog.title', {
                  procedureIntitule: procedure?.intitule,
                })}
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          }
        />
        <CardContent>
          {/*
            // TODO commented until we add pdf support
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" paddingRight={2}>
                Format:
              </Typography>
              <ToggleButtonGroup
                value={savingFormat}
                exclusive
                onChange={onChangeSavingFormat}
                aria-label="text alignment"
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {printFormats.map((format, index) => (
                  <ToggleButton
                    key={index}
                    value={format}
                    disabled={printFormats.length === 1}
                  >
                    {prettyString[format]}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
        */}
          <Stack direction="column" alignItems="flex-start">
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" paddingRight={2}>
                {t('print.dialog.printType.title')}
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="flex-start">
              <Stack direction="row" alignItems="center" paddingTop={1}>
                <Typography variant="h6" paddingRight={2}>
                  {t('print.dialog.printType.docx')}
                </Typography>
                <ToggleButtonGroup
                  value={printType}
                  exclusive
                  onChange={(_, value) => {
                    if (!value) {
                      return;
                    }
                    setPrintType(value);
                    setPrintFormat('docx');
                  }}
                  aria-label="text alignment"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  {['written', 'print'].map((type, index) => (
                    <ToggleButton key={index} value={type}>
                      {t(`print.dialog.printType.options.${type}`)}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
              <Stack direction="row" alignItems="center" paddingTop={1}>
                <Typography variant="h6" paddingRight={6}>
                  {t('print.dialog.printType.xlsx')}
                </Typography>
                <ToggleButtonGroup
                  value={printType}
                  exclusive
                  onChange={(_, value) => {
                    if (!value) {
                      return;
                    }
                    setPrintType(value);
                    setPrintFormat(value);
                  }}
                  aria-label="text alignment"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  {['xlsx', 'csv'].map((type, index) => (
                    <ToggleButton key={index} value={type}>
                      {t(`print.dialog.printType.options.${type}`)}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="contained"
            onClick={onClickPrint}
            disabled={!procedure || !prejudices || !monetaryErosions}
          >
            {t('print.dialog.confirm')}
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};
