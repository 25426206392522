import { Paragraph, Table, TableOfContents } from 'docx';
import { getEmptyLine } from 'src/helpers/print/docxFunctions';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
  PerteGainProfessionnelsFutursSituation,
} from 'src/types/prejudice.type';
import { situationPrint } from './situationPrint';

export const situationsPrint = ({
  situations,
  monetaryErosions,
  dateLiquidation,
  revalorisationCoefficientsType,
}: {
  situations: (
    | PerteGainProfessionnelsActuelSituation
    | PerteGainProfessionnelsFutursSituation
  )[];
  monetaryErosions: MonetaryErosion[];
  dateLiquidation: Date | null;
} & Pick<
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  'revalorisationCoefficientsType'
>): (Table | Paragraph | TableOfContents)[] =>
  situations.reduce(
    (situationsAccumulator, situation, index) => [
      ...situationsAccumulator,
      ...(index > 0 ? [getEmptyLine()] : []),
      ...situationPrint({
        situation,
        monetaryErosions,
        dateLiquidation: dateLiquidation
          ? new Date(dateLiquidation)
          : undefined,
        revalorisationCoefficientsType,
      }),
    ],
    [] as (Paragraph | Table | TableOfContents)[],
  );
