import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { LayoutTable } from '../../styled';
import { fCurrency } from '../../../helpers/formatNumber';
import { CalculsBox } from '../../basic/CalculsBox';
import { IndemniteRepartieEchusIndirecte } from 'src/constants/calculs/type';
import { useTranslation } from 'react-i18next';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { fPartResponsabilite } from 'src/helpers/formatValues';

interface Props {
  indemniteGlobaleARepartir: Omit<
    IndemniteRepartieEchusIndirecte,
    'indemniteTiersPayeurs'
  > &
    Partial<Pick<IndemniteRepartieEchusIndirecte, 'indemniteTiersPayeurs'>>;
  victimesIndirectes: VictimeIndirecte[];
  titles?: {
    indemniteGlobaleARepartir?: string;
    indemniteVictimeIndirecte?: string;
    indemniteTiersPayeurs?: string;
  };
  partResponsabilite: number;
}

export const TotalIndemniteGlobaleARepartirEchusIndirecte: React.FC<Props> = ({
  indemniteGlobaleARepartir,
  victimesIndirectes,
  titles,
  partResponsabilite,
}) => {
  const { t } = useTranslation();
  return (
    <CalculsBox>
      <LayoutTable size="small">
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              {titles?.indemniteGlobaleARepartir ||
                t(
                  'prejudice.indemniteGlobaleARepartir.indirecteEchus.table.indemniteGlobaleBeforePartResponsabilite.tableRowHeader',
                )}
            </TableCell>
            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
              {fCurrency(
                indemniteGlobaleARepartir.indemniteGlobaleARepartir
                  .beforePartResponsabilite,
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              {titles?.indemniteGlobaleARepartir ||
                t(
                  'prejudice.indemniteGlobaleARepartir.indirecteEchus.table.indemniteGlobale.tableRowHeaderPartResponsabilite',
                  {
                    partResponsabilite: fPartResponsabilite(
                      partResponsabilite * 100,
                    ),
                  },
                )}
            </TableCell>
            <TableCell colSpan={2} align="right" sx={{ whiteSpace: 'nowrap' }}>
              {fCurrency(
                indemniteGlobaleARepartir.indemniteGlobaleARepartir.solde,
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
              {titles?.indemniteVictimeIndirecte ||
                t(
                  'prejudice.indemniteGlobaleARepartir.indirecteEchus.table.indemniteVictime.tableRowHeader',
                )}
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
            >
              {fCurrency(
                indemniteGlobaleARepartir.indemniteProche.arreragesEchus.debit,
              )}
            </TableCell>
          </TableRow>
          {Object.entries(
            indemniteGlobaleARepartir.indemniteProche.arreragesEchus
              .parVictimeIndirecte,
          ).map(([victimeIndirecteId, value]) => {
            const victimeIndirecte = victimesIndirectes.find(
              (victime) => victime._id === victimeIndirecteId,
            );
            return (
              <TableRow key={victimeIndirecteId}>
                <TableCell />
                <TableCell>
                  {t('victimeIndirecte.displayNameLong', {
                    prenom: victimeIndirecte?.prenom,
                    nom: victimeIndirecte?.nom,
                    lienVictime: victimeIndirecte?.lienVictime,
                  })}
                </TableCell>
                <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                  {fCurrency(value)}
                </TableCell>
              </TableRow>
            );
          })}
          {indemniteGlobaleARepartir.indemniteTiersPayeurs ? (
            <>
              <TableRow>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                  {titles?.indemniteTiersPayeurs ||
                    t(
                      'prejudice.indemniteGlobaleARepartir.indirecteEchus.table.indemniteTiersPayeurs.tableRowHeader',
                    )}
                </TableCell>
                <TableCell
                  colSpan={2}
                  align="right"
                  sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                >
                  {fCurrency(
                    indemniteGlobaleARepartir.indemniteTiersPayeurs
                      .arreragesEchus.debit,
                  )}
                </TableCell>
              </TableRow>
              {indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesEchus.parTiersPayeur?.map(
                ({ tiersPayeur, montant }, index) => (
                  <TableRow key={index}>
                    <TableCell />
                    <TableCell>{tiersPayeur}</TableCell>
                    <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                      {fCurrency(montant)}
                    </TableCell>
                  </TableRow>
                ),
              )}
            </>
          ) : null}
        </TableBody>
      </LayoutTable>
    </CalculsBox>
  );
};
