import React, { useState } from 'react';
import { Control, FieldPath, useWatch } from 'react-hook-form';
import {
  Button,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs,
  PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeursAnnee,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  NumeroPieceValuesRow,
} from '../../../../types/prejudice.type';
import { Procedure } from '../../../../types/procedure.type';
import { NormalTable } from '../../../styled';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { fCurrency } from '../../../../helpers/formatNumber';
import { CalculsGlobal } from '../../../../constants/calculs';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { ReadFieldForm } from 'src/components/forms/ReadFieldForm';
import { RenteAccidentDuTravailDialog } from './RenteAccidentDuTravailDialog';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { t } from 'i18next';

interface Props {
  procedure: Procedure;
  control: Control<
    | OldPrejudiceFormPerteGainProfessionnelsActuel
    | OldPrejudiceFormPerteGainProfessionnelsFuturs
  >;
  situationIndex: number;
  isPGPF?: boolean;
  tauxIPP: number | null;
  validate: (
    fieldsToValidate: FieldPath<
      | OldPrejudiceFormPerteGainProfessionnelsActuel
      | OldPrejudiceFormPerteGainProfessionnelsFuturs
    >[],
  ) => void;
  previous: (
    fieldsToValidate: FieldPath<
      | OldPrejudiceFormPerteGainProfessionnelsActuel
      | OldPrejudiceFormPerteGainProfessionnelsFuturs
    >[],
  ) => void;
  allNumerosPieces: NumeroPieceValuesRow[];
}

export const RevenusIndemnitesStep: React.FC<Props> = ({
  procedure,
  control,
  situationIndex,
  isPGPF,
  tauxIPP,
  allNumerosPieces,
  validate,
  previous,
}) => {
  const [
    isRenteAccidentDuTravailModalOpen,
    setIsRenteAccidentDuTravailModalOpen,
  ] = useState(false);
  const watchValues = useWatch({
    control,
    name: ['dateDebut', 'dateFin'],
  });
  if (!watchValues[0] || !watchValues[1]) {
    return null;
  }
  const dateDebut = new Date(watchValues[0]);
  const dateFin = new Date(watchValues[1]);
  if (!dateDebut || !dateFin) {
    return null;
  }
  const anneeDateDebut = new Date(dateDebut).getFullYear();
  const anneeDateFin = new Date(dateFin).getFullYear();
  const tiersPayeurRow = (tiersPayeur: string, index: number) => (
    <React.Fragment key={index}>
      <TableRow key={tiersPayeur}>
        <TableCell>
          <FormNumeroPieceDialog
            control={control}
            name={`situations.${situationIndex}.tiersPayeursIndemnites.${index}.numerosPieces`}
            allOtherNumeroPieces={allNumerosPieces}
          />
        </TableCell>
        <TableCell align="center">{tiersPayeur}</TableCell>
        <TableCell colSpan={3 + (isPGPF ? 0 : 3)} />
      </TableRow>
      {[...Array(anneeDateFin - anneeDateDebut + 1)].map((_, anneeIndex) => (
        <TableRow key={anneeIndex}>
          <TableCell colSpan={2} />
          <TableCell align="center">
            <ReadFieldForm
              control={
                control as Control<OldPrejudiceFormPerteGainProfessionnelsActuel>
              }
              name={`situations.${situationIndex}.tiersPayeursIndemnites.${index}.anneeTiersPayeursIndemnites.${anneeIndex}.annee`}
              render={(value) => {
                return <>{value}</>;
              }}
            />
          </TableCell>
          <TableCell align="right">
            <TextFieldForm
              control={control}
              name={`situations.${situationIndex}.tiersPayeursIndemnites.${index}.anneeTiersPayeursIndemnites.${anneeIndex}.netPaye`}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 2 },
                  suffix: '€',
                  nullable: true,
                } as IMaskNumberProps,
              }}
              fullWidth
            />
          </TableCell>
          {!isPGPF && (
            <TableCell align="right">
              <TextFieldForm
                control={control}
                name={`situations.${situationIndex}.tiersPayeursIndemnites.${index}.anneeTiersPayeursIndemnites.${anneeIndex}.csgRds`}
                InputProps={{
                  inputComponent: MaskNumber as any,
                  inputProps: {
                    numberMask: { scale: 2 },
                    suffix: '€',
                    nullable: true,
                  } as IMaskNumberProps,
                }}
                fullWidth
              />
            </TableCell>
          )}
        </TableRow>
      ))}
      <TableRow>
        <TableCell colSpan={2} />
        <TableCell align="center">
          {t(
            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenusIndemnitesTiersPayeurs.rowHeader',
          )}
        </TableCell>
        <TableCell align="right">
          <ComputedTextFieldForm
            control={control}
            name={`situations.${situationIndex}.tiersPayeursIndemnites.${index}.netPaye`}
            watchFields={[
              ...[...Array(anneeDateFin - anneeDateDebut + 1)].map(
                (
                  _,
                  anneeIndex,
                ): `situations.${number}.tiersPayeursIndemnites.${number}.anneeTiersPayeursIndemnites.${number}` =>
                  `situations.${situationIndex}.tiersPayeursIndemnites.${index}.anneeTiersPayeursIndemnites.${anneeIndex}`,
              ),
            ]}
            compute={([...values]) => {
              const rows = values as
                | PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeursAnnee[]
                | undefined;
              if (rows?.some((row) => !!row.netPaye)) {
                return (
                  rows.reduce(
                    (
                      accumulator: number,
                      row: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeursAnnee,
                    ) => accumulator + Number(row.netPaye),
                    0,
                  ) || 0
                );
              } else {
                return 0;
              }
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: '€',
              } as IMaskNumberProps,
            }}
            fullWidth
            editedFieldsName="editedFields"
          />
        </TableCell>
        <TableCell align="right">
          <ComputedTextFieldForm
            control={control}
            name={`situations.${situationIndex}.tiersPayeursIndemnites.${index}.csgRds`}
            watchFields={[
              ...[...Array(anneeDateFin - anneeDateDebut + 1)].map(
                (
                  _,
                  anneeIndex,
                ): `situations.${number}.tiersPayeursIndemnites.${number}.anneeTiersPayeursIndemnites.${number}` =>
                  `situations.${situationIndex}.tiersPayeursIndemnites.${index}.anneeTiersPayeursIndemnites.${anneeIndex}`,
              ),
            ]}
            compute={([...values]) => {
              const rows = values as
                | PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeursAnnee[]
                | undefined;
              if (rows?.some((row) => !!row.csgRds)) {
                return (
                  rows.reduce(
                    (
                      accumulator: number,
                      row: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeursAnnee,
                    ) => accumulator + Number(row.csgRds),
                    0,
                  ) || null
                );
              } else {
                return null;
              }
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: '€',
                nullable: true,
              } as IMaskNumberProps,
            }}
            fullWidth
            editedFieldsName="editedFields"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
  const PGPFTiersPayeurRow = (tiersPayeur: string, index: number) => (
    <React.Fragment key={index}>
      <TableRow>
        <TableCell align="center">
          <FormNumeroPieceDialog
            control={control}
            name={`situations.${situationIndex}.tiersPayeursIndemnites.${index}.numerosPieces`}
            allOtherNumeroPieces={allNumerosPieces}
          />
        </TableCell>
        <TableCell align="center">{tiersPayeur}</TableCell>
        <TableCell align="right">
          <TextFieldForm
            control={control}
            name={`situations.${situationIndex}.tiersPayeursIndemnites.${index}.netPaye`}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2 },
                suffix: '€',
              } as IMaskNumberProps,
            }}
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneeTiersPayeursIndemnites.tiersPayeur.columnHeader',
                )}
              </TableCell>
              {!isPGPF && (
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneeTiersPayeursIndemnites.annee.columnHeader',
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneeTiersPayeursIndemnites.netPaye.columnHeader',
                )}
              </TableCell>
              {!isPGPF && (
                <TableCell align="center">
                  {t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneeTiersPayeursIndemnites.csgRds.columnHeader',
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {procedure.tiersPayeurs.map((tiersPayeur, index) =>
              isPGPF
                ? PGPFTiersPayeurRow(tiersPayeur, index)
                : tiersPayeurRow(tiersPayeur, index),
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              {!isPGPF && (
                <TableCell
                  align="right"
                  sx={{ fontWeight: 'bold' }}
                  colSpan={!isPGPF ? 3 : 2}
                >
                  {t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.totalIndemnitesNet.rowHeader',
                  )}
                </TableCell>
              )}
              <TableCell align="right" colSpan={isPGPF ? 3 : 1}>
                <ComputedReadFieldForm
                  control={control}
                  name={`situations.${situationIndex}.totalIndemnitesNet`}
                  watchFields={[
                    `situations.${situationIndex}.tiersPayeursIndemnites`,
                  ]}
                  compute={(values) => {
                    const rows: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[] =
                      values[0];
                    return CalculsGlobal.sum(
                      rows.map((row) => row.netPaye || 0),
                    );
                  }}
                  render={(value) => (
                    <>{`${
                      isPGPF
                        ? t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.situations.totalIndemnitesNet.rowHeader',
                          )
                        : ''
                    }${fCurrency(value)}`}</>
                  )}
                />
              </TableCell>
              {!isPGPF && (
                <TableCell align="right">
                  <ComputedPropsForm
                    control={control}
                    watchFields={[
                      `situations.${situationIndex}.tiersPayeursIndemnites`,
                    ]}
                    compute={(values) => {
                      const rows: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[] =
                        values[0];
                      return {
                        props: {
                          value: CalculsGlobal.sum(
                            rows
                              .map((row) => row.csgRds)
                              .filter(
                                (csgRds): csgRds is number => csgRds != null,
                              ),
                          ),
                        },
                      };
                    }}
                    render={({ value }) => <>{fCurrency(value)}</>}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableFooter>
        </NormalTable>
      </TableContainer>

      <ComputedReadFieldForm
        control={control}
        name={`situations.${situationIndex}.totalIndemnitesCsgCrds`}
        watchFields={[`situations.${situationIndex}.tiersPayeursIndemnites`]}
        compute={(values) => {
          const rows: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[] =
            values[0];
          return CalculsGlobal.sum(rows.map((row) => row.csgRds || 0));
        }}
      />
      {isPGPF && (
        <ComputedPropsForm
          control={control}
          watchFields={['totalRevenuDeReference']}
          compute={([totalRevenuDeReference]) => ({
            props: {
              totalRevenuDeReference,
            },
          })}
          render={({ totalRevenuDeReference }) => (
            <RenteAccidentDuTravailDialog
              open={isRenteAccidentDuTravailModalOpen}
              salaireAnnuelDeReference={totalRevenuDeReference || 0}
              tauxIPP={tauxIPP}
              onClose={() => setIsRenteAccidentDuTravailModalOpen(false)}
              formType="REVENUS"
            />
          )}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={3.5} display="flex" alignItems="start">
          {isPGPF && (
            <Button
              onClick={() => {
                setIsRenteAccidentDuTravailModalOpen(true);
              }}
              sx={{ textTransform: 'none' }}
            >
              {t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.modalButtonText',
              )}
            </Button>
          )}
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ alignSelf: 'center' }}
            onClick={() => previous([`situations.${situationIndex}`])}
          >
            {t('common.previous')}
          </Button>
          <Button
            variant="contained"
            sx={{ alignSelf: 'center' }}
            onClick={() => validate([`situations.${situationIndex}`])}
          >
            {t('common.next')}
          </Button>
        </Grid>
        <Grid item xs={3.5} />
      </Grid>
    </>
  );
};
