import { RootState } from 'src/store';
import { useAppSelector } from '../store';
import { organizationSelector } from 'src/store/selectors';
import { Organization } from 'src/types/organization.type';
import { useMatch, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

export const useDisplayedOrganization = (): {
  organization: Organization | null;
  organizationId: string | null;
  setDisplayedOrganization: (organizationId: string | null) => void;
} => {
  const navigate = useNavigate();
  const { organizations } = useOrganizations();
  const match = useMatch('/organization/:organizationId/*');
  const organizationId = match?.params.organizationId ?? null;
  const organization = useMemo(
    () =>
      organizations.find(
        (organization) => organization._id === organizationId,
      ) ?? null,
    [organizationId, organizations],
  );
  const setDisplayedOrganization = (id: string | null) => {
    if (id === null) {
      navigate('/');
    } else {
      navigate(`/organization/${id}`, { replace: true });
    }
  };
  return {
    organization,
    organizationId,
    setDisplayedOrganization,
  };
};

export const useOrganizations = (): {
  isInitialLoading: boolean;
  isLoading: boolean;
  organizations: Organization[];
} => {
  const { isInitialLoading, isLoading, organizations } = useAppSelector(
    (state: RootState) => ({
      organizations: organizationSelector.selectAll(state),
      isInitialLoading: state.organization.isInitialLoading,
      isLoading: state.organization.isLoading,
    }),
  );
  return { isInitialLoading, isLoading, organizations };
};

export const useOrganizationsForNormaAdmin = (): Organization[] => {
  const { organizations } = useAppSelector((state: RootState) => ({
    organizations: state.organization.organizationsForNormaAdmin.data,
  }));
  return organizations;
};
