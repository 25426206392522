import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchCurrentUser, updateUser } from 'src/slices/auth';
import { UpdateUserDto } from 'src/types/auth.type';

import * as yup from 'yup';
import UserConfiguration from 'src/containers/UserConfiguration';
import { Box } from '@mui/material';
import { theme } from 'src/constants/theme';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { handleNotification } from 'src/helpers/notifications';

const useStyles = createUseStyles({
  settingsPage: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: '95%',
    },
    maxWidth: '1000px',
    margin: 'auto',
  },
  settingsContainer: {
    overflowY: 'scroll',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar ': {
      display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    },
    height: '90%',
  },
  settingsActionsCancel: {
    margin: theme.spacing(3, 2, 2, 2),
  },
  settingsActionsSubmit: {
    margin: theme.spacing(3, 2, 2, 2),
  },
});

interface FormData {
  firstName: string;
  lastName: string;
  company: string;
  position: string;
  phoneNumber: string;
}

const Settings = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  // Move form to UserConfiguration.tsx
  const validationSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    phoneNumber: yup.string().length(10),
    company: yup.string(),
    profilePictureUrl: yup.string(),
  });

  const onSubmit = async (values: FormData) => {
    if (isDirty) {
      const updatedValues: UpdateUserDto = {
        firstName:
          user?.firstName !== values.firstName ? values.firstName : undefined,
        lastName:
          user?.lastName !== values.lastName ? values.lastName : undefined,
        phoneNumber:
          user?.phoneNumber !== values.phoneNumber
            ? values.phoneNumber
            : undefined,
        company: user?.company !== values.company ? values.company : undefined,
      };

      const isChangingValue = Object.keys(updatedValues).some(
        (k) => (updatedValues as any)[k] !== undefined,
      );

      isChangingValue &&
        (await handleNotification(
          dispatch(updateUser(updatedValues)),
          t('settings.successProfileUpdate'),
          t('settings.errorProfileUpdate'),
          enqueueSnackbar,
          closeSnackbar,
        ));

      dispatch(fetchCurrentUser());

      return isChangingValue;
    }
  };

  const initialValues = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    company: user?.company ?? '',
  };

  const formMethods = useForm<FormData>({
    defaultValues: initialValues,
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  if (!user) {
    return <></>;
  }

  return (
    <div className={classes.settingsPage}>
      <FormProvider {...formMethods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            overflowY: 'scroll',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar ': {
              display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
            },
          }}
        >
          <UserConfiguration onReset={reset} />
        </Box>
      </FormProvider>
    </div>
  );
};

export default Settings;
