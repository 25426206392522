import React, { useState } from 'react';
import {
  Control,
  FieldPath,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormTrigger,
} from 'react-hook-form';
import {
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  NumeroPieceValuesRow,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceType,
} from '../../../../types/prejudice.type';
import { Procedure } from '../../../../types/procedure.type';
import { Dommage } from 'src/types/dommage.type';
import { Victime } from 'src/types/victime.type';
import { Box, Grid, Stack } from '@mui/material';
import TablePerteDeGainsProfessionnels from './TablePerteDeGainsProfessionnels';
import { useTranslation } from 'react-i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Chiffrage } from '../Chiffrage';
import { StepContainer } from './StepContainer';
import { StepManagerHeader } from './StepManagerHeader';
import { TableRevenuEspere } from './TableRevenuEspere';
import { TableRecapitulatifSaisieDirecte } from './TableRecapitulatifSaisieDirecte';
import { TableIndemnitesJournalieresPercuesPendantLaPeriodeDArret } from './TableIndemnitesJournalieresPercuesPendantLaPeriodeDArret';
import { SavePrejudiceButton } from '../../SavePrejudiceButton';
import { User } from 'src/types/auth.type';
// TODO put share props in one interface
interface PGPAProps {
  setAdditionalTitle: (title: string | undefined) => void;
  procedure: Procedure;
  dommage: Dommage;
  victime: Victime;
  monetaryErosions: MonetaryErosion[];
  dateConsolidation?: Date | undefined;
  dateLiquidation?: Date | undefined;
  prejudiceType: PrejudiceType;
  control: Control<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  handleSubmit: UseFormHandleSubmit<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  trigger: UseFormTrigger<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  values?: PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
  getValues: UseFormGetValues<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  onPrevious?: () => void;
  onClose: () => void;
  isPGPF?: boolean;
  tauxIPP: number | null;
  allNumerosPieces: NumeroPieceValuesRow[];
  user: User | null;
}

interface PGPFProps {
  setAdditionalTitle: (title: string | undefined) => void;
  procedure: Procedure;
  victime: Victime;
  dommage: Dommage | undefined;
  monetaryErosions: MonetaryErosion[];
  dateConsolidation?: Date | undefined;
  dateLiquidation?: Date | undefined;
  prejudiceType: PrejudiceType;
  control:
    | Control<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte>
    | Control<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  handleSubmit:
    | UseFormHandleSubmit<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte>
    | UseFormHandleSubmit<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  trigger:
    | UseFormTrigger<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte>
    | UseFormTrigger<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  values?:
    | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
    | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
  getValues:
    | UseFormGetValues<PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte>
    | UseFormGetValues<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  onPrevious?: () => void;
  onClose: () => void;
  isPGPF?: boolean;
  tauxIPP: number | null;
  allNumerosPieces: NumeroPieceValuesRow[];
  user: User | null;
}

type StepType =
  | 'REVENU_ESPERE'
  | 'PERTE_DE_GAIN_PROFESSIONNEL'
  | 'INDEMNITES_JOURNALIERES_PERCUES'
  | 'RECAPITULATIF';

const steps: Partial<
  Record<
    StepType,
    FieldPath<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>
  >
> = {
  REVENU_ESPERE: 'revenuEspere',
  PERTE_DE_GAIN_PROFESSIONNEL: 'perteDeGainsProfessionnels',
  INDEMNITES_JOURNALIERES_PERCUES:
    'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
};

export const StepManagerSaisieDirecte: React.FC<PGPAProps | PGPFProps> = ({
  victime,
  control,
  getValues,
  values,
  onPrevious,
  trigger,
  procedure,
  isPGPF = false,
  dateConsolidation,
  dateLiquidation,
  prejudiceType,
  monetaryErosions,
  tauxIPP,
  allNumerosPieces,
  user,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<StepType>(
    values ? 'RECAPITULATIF' : 'REVENU_ESPERE',
  );
  const [isError, setIsError] = useState(false);

  const changeStepAndValidate = async (
    step: StepType,
    fieldsToValidate: FieldPath<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>[],
  ) => {
    if (await trigger(fieldsToValidate as any)) {
      setStep(step);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const handleStep = async (newStep: StepType) => {
    const fieldsToValidate =
      Object.values(steps)[Object.keys(steps).indexOf(step)];
    const moveIndex: number =
      Object.keys(steps).indexOf(step) - Object.keys(steps).indexOf(newStep);
    if (moveIndex < 0) {
      await changeStepAndValidate(
        newStep,
        fieldsToValidate ? [fieldsToValidate] : [],
      );
    } else {
      setStep(newStep);
      setIsError(false);
    }
  };

  const getStep = () => {
    switch (step) {
      case 'REVENU_ESPERE':
        return (
          <StepContainer
            onClickPreviousStep={
              !values
                ? onPrevious
                : () => changeStepAndValidate('RECAPITULATIF', ['revenuEspere'])
            }
            onClickNextStep={() => {
              const revenuActiviteAnnuelDeReference =
                getValues()?.revenuEspere.total;
              if (
                (revenuActiviteAnnuelDeReference &&
                  revenuActiviteAnnuelDeReference !== 0) ||
                isPGPF
              ) {
                changeStepAndValidate('PERTE_DE_GAIN_PROFESSIONNEL', [
                  'revenuEspere',
                ]);
              } else {
                changeStepAndValidate('INDEMNITES_JOURNALIERES_PERCUES', [
                  'revenuEspere',
                ]);
              }
            }}
          >
            <TableRevenuEspere
              control={control as any}
              isError={isError}
              allNumerosPieces={allNumerosPieces}
            />
          </StepContainer>
        );
      case 'PERTE_DE_GAIN_PROFESSIONNEL':
        return (
          <StepContainer
            onClickPreviousStep={() => setStep('REVENU_ESPERE')}
            onClickNextStep={() =>
              changeStepAndValidate('INDEMNITES_JOURNALIERES_PERCUES', [
                'perteDeGainsProfessionnels',
              ])
            }
          >
            <TablePerteDeGainsProfessionnels
              dateConsolidation={dateConsolidation}
              dateLiquidation={dateLiquidation}
              dateAccident={
                victime?.dateAccident
                  ? new Date(victime.dateAccident)
                  : undefined
              }
              control={control as any}
              isError={isError}
              isPGPF={isPGPF}
              monetaryErosions={monetaryErosions}
              prejudiceType={prejudiceType}
              procedure={procedure}
              victime={victime}
              allNumerosPieces={allNumerosPieces}
              formType="SAISIE_DIRECTE"
              getValues={getValues}
            />
          </StepContainer>
        );
      case 'INDEMNITES_JOURNALIERES_PERCUES':
        return (
          <StepContainer
            onClickPreviousStep={() => {
              const revenuEspere = getValues()?.revenuEspere.total;
              if ((revenuEspere && revenuEspere !== 0) || isPGPF) {
                changeStepAndValidate('PERTE_DE_GAIN_PROFESSIONNEL', [
                  'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
                ]);
              } else {
                changeStepAndValidate('REVENU_ESPERE', [
                  'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
                ]);
              }
            }}
            onClickNextStep={() =>
              changeStepAndValidate('RECAPITULATIF', [
                'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
              ])
            }
          >
            <TableIndemnitesJournalieresPercuesPendantLaPeriodeDArret
              control={control as any}
              procedure={procedure}
              isPGPF={isPGPF}
              tauxIPP={tauxIPP}
              prejudiceType={prejudiceType}
              victime={victime}
              allNumerosPieces={allNumerosPieces}
              dateConsolidation={dateConsolidation}
              dateLiquidation={dateLiquidation}
              revenuName="revenuEspere.total"
              formType="SAISIE_DIRECTE"
            />
          </StepContainer>
        );
      default:
        return null;
    }
  };
  return (
    <Stack direction="column" spacing={4} flex={1}>
      {step !== 'RECAPITULATIF' && (
        <StepManagerHeader
          activeStep={Object.keys(steps).indexOf(step)}
          steps={Object.keys(steps).map((label) => ({
            label: label,
            title: t(
              `prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.stepper.${label}${
                label === 'INDEMNITES_JOURNALIERES_PERCUES'
                  ? isPGPF
                    ? '_PGPF'
                    : '_PGPA'
                  : ''
              }`,
            ),
            disabled:
              !(
                (getValues()?.revenuEspere.total &&
                  getValues()?.revenuEspere.total !== 0) ||
                isPGPF
              ) && label === 'PERTE_DE_GAIN_PROFESSIONNEL',
            onClick: () => handleStep(label as StepType),
          }))}
        />
      )}
      {getStep()}
      <Box sx={{ display: step === 'RECAPITULATIF' ? undefined : 'none' }}>
        <StepContainer>
          <TableRecapitulatifSaisieDirecte
            onClickModify={() => changeStepAndValidate('REVENU_ESPERE', [])}
            isPGPF={isPGPF}
            procedure={procedure}
            control={control as any}
            tauxIPP={tauxIPP}
          />
        </StepContainer>
      </Box>
      {step === 'RECAPITULATIF' && (
        <Grid container>
          <Grid item xs={4}>
            <Chiffrage
              control={
                control as Control<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SavePrejudiceButton victime={victime} />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      )}
    </Stack>
  );
};
