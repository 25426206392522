import i18next from 'i18next';
import { join } from 'lodash';
import { FieldPath } from 'react-hook-form';
import { Time } from 'src/constants/time';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { PrejudiceFormBase, Unite } from 'src/types/prejudice.type';

interface DisplayFormulaOptions {
  withParentheses?: boolean;
}

interface EditedFieldsParameters<TFieldValues extends PrejudiceFormBase> {
  // TODO Type this better to avoid wrong field names
  fieldName: FieldPath<TFieldValues>;
  formulaString: string;
  formData: TFieldValues;
}

const isFieldEdited = <TFieldValues extends PrejudiceFormBase>({
  fieldName,
  formData: { editedFields },
}: Omit<EditedFieldsParameters<TFieldValues>, 'formulaString'>) =>
  editedFields.includes(fieldName);

const getRowsSumFormulaString = (rowValues: number[]): string =>
  join(
    rowValues.map((value) => fCurrency(value || 0)),
    ' + ',
  );

const getRowsAverageFormulaString = (
  rowValues: number[],
  length: number,
): string =>
  `(${join(
    rowValues.map((value) => fCurrency(value || 0)),
    ' + ',
  )}) / ${length}`;

const displayFormulaWithParentheses = (formula: string) =>
  !!formula
    ? i18next.t('prejudice.formula.withParentheses', {
        formula,
      })
    : '';

const displayFormulaWithOptions = ({
  formulaString,
  options,
}: {
  formulaString: string;
  options: DisplayFormulaOptions;
}) =>
  options.withParentheses && formulaString
    ? displayFormulaWithParentheses(formulaString)
    : formulaString;

export const displayFormula = <TFieldValues extends PrejudiceFormBase>({
  formula,
  options = {
    withParentheses: false,
  },
  editedFieldsParameters,
}: {
  formula: string;
  options?: DisplayFormulaOptions;
  editedFieldsParameters?: Omit<
    EditedFieldsParameters<TFieldValues>,
    'formulaString'
  >;
}) => {
  if (editedFieldsParameters && isFieldEdited(editedFieldsParameters)) {
    return '';
  }
  return displayFormulaWithOptions({
    formulaString: formula,
    options,
  });
};

export const displayRowsSumFormula = <TFieldValues extends PrejudiceFormBase>({
  rowValues,
  options = {
    withParentheses: false,
  },
  editedFieldsParameters,
}: {
  rowValues: number[];
  editedFieldsParameters?: Omit<
    EditedFieldsParameters<TFieldValues>,
    'formulaString'
  >;
  options?: DisplayFormulaOptions;
}): string => {
  if (editedFieldsParameters && isFieldEdited(editedFieldsParameters)) {
    return '';
  }
  const nonZeroValues = rowValues.filter((value) => !!value);
  const formulaString =
    nonZeroValues.length <= 1 ? '' : getRowsSumFormulaString(nonZeroValues);
  return displayFormulaWithOptions({
    formulaString,
    options,
  });
};

export const displayRowsAverageFormula = <
  TFieldValues extends PrejudiceFormBase,
>({
  rowValues,
  options = {
    withParentheses: false,
    countZeroValuesInAverage: false,
  },
  editedFieldsParameters,
}: {
  rowValues: number[];
  options?: DisplayFormulaOptions & {
    countZeroValuesInAverage?: boolean;
    length?: number;
  };
  editedFieldsParameters?: Omit<
    EditedFieldsParameters<TFieldValues>,
    'formulaString'
  >;
}) => {
  if (editedFieldsParameters && isFieldEdited(editedFieldsParameters)) {
    return '';
  }
  let values = [...rowValues];
  if (!options.countZeroValuesInAverage) {
    values = values.filter((value) => !!value);
  }
  const formulaString =
    values.length <= 1
      ? ''
      : getRowsAverageFormulaString(values, options.length || values.length);
  return displayFormulaWithOptions({
    formulaString,
    options,
  });
};

export const displayMontantParPeriodeFormula = <
  TFieldValues extends PrejudiceFormBase,
>({
  montant,
  duree,
  unite,
  options = {
    withParentheses: false,
  },
  editedFieldsParameters,
}: {
  montant: number;
  duree: number;
  unite: Unite;
  options?: DisplayFormulaOptions;
  editedFieldsParameters?: Omit<
    EditedFieldsParameters<TFieldValues>,
    'formulaString'
  >;
}) => {
  if (editedFieldsParameters && isFieldEdited(editedFieldsParameters)) {
    return '';
  }
  const daysInUnite = {
    annee: Time.daysInYear,
    mois: Time.daysInMonth,
    semaine: Time.daysInWeek,
    jour: 1,
  }[unite];

  const formulaString = i18next.t(
    'prejudice.formula.formulas.montantParPeriode',
    {
      context: unite === 'jour' ? 'day' : 'nonDay',
      montant: fCurrency(montant),
      duree: fDecimalNumber(duree),
      ...(unite !== 'jour' && daysInUnite
        ? {
            daysInUnite: fDecimalNumber(daysInUnite),
          }
        : {}),
    },
  );

  return displayFormulaWithOptions({
    formulaString,
    options,
  });
};

export const displayMontantAnnualiseFormula = <
  TFieldValues extends PrejudiceFormBase,
>({
  montant,
  unite,
  options = {
    withParentheses: false,
  },
  editedFieldsParameters,
}: {
  montant: number;
  unite: Unite;
  options?: DisplayFormulaOptions;
  editedFieldsParameters?: Omit<
    EditedFieldsParameters<TFieldValues>,
    'formulaString'
  >;
}) => {
  if (editedFieldsParameters && isFieldEdited(editedFieldsParameters)) {
    return '';
  }
  const unitInYear = {
    annee: 1,
    mois: Time.monthsInYear,
    semaine: Time.weeksInYear,
    jour: Time.daysInYear,
  }[unite];

  const formulaString =
    unite !== 'annee'
      ? i18next.t('prejudice.formula.formulas.montantAnnualise', {
          montant: fCurrency(montant),
          unitInYear: i18next.t('prejudice.fields.unite.value', {
            unite,
            count: unitInYear,
            value: fDecimalNumber(unitInYear),
          }),
        })
      : '';
  return displayFormulaWithOptions({
    formulaString,
    options,
  });
};
