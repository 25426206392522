import { PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow } from 'src/types/prejudice.type';

export abstract class CalculsFormCalendrierDeficitFonctionnelTemporairePartiel {
  static montantTotal({
    totalJours,
    forfaitJour,
    percentage,
  }: {
    totalJours: number;
    forfaitJour: number;
    percentage: number;
  }) {
    return forfaitJour * totalJours * (percentage / 100);
  }
  static total(
    rows: PrejudiceFormCalendrierDeficitFonctionnelTemporairePartielRow[],
  ) {
    return rows
      .map((row) => row.montantTotal)
      .reduce((acc, curr) => acc + curr, 0);
  }
}
