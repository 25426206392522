import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { Bareme } from 'src/types/bareme.type';
import { getParagraph } from '../docxFunctions';

export const capitalisationSelectedBaremeIntroductionWrittenPrint = (
  selectedBareme: Bareme | undefined,
  isCapitalisationDifferee: Boolean = false,
): (Table | TableOfContents | Paragraph)[] => [
  getParagraph(
    selectedBareme
      ? i18next.t(
          'prejudice.capitalisation.writtenPrint.selectedBaremeIntroduction',
          {
            bareme:
              getCapitalisationBaremeSourceLabel(selectedBareme) +
              (isCapitalisationDifferee
                ? i18next.t(
                    'prejudice.capitalisation.writtenPrint.capitalisationDifferee',
                  )
                : ''),
          },
        )
      : i18next.t(
          'prejudice.capitalisation.writtenPrint.noSelectedBaremeIntroduction',
        ),
  ),
];
