import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { isRegimeChiffreAffaire } from 'src/helpers/prejudices/prejudice';
import { getEmptyLine, getParagraph } from 'src/helpers/print/docxFunctions';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
} from 'src/types/prejudice.type';
import { getMontantRevalorisePrint } from '../../revalorisationPrint';

export const revenusTheoriquePrint = ({
  anneesRevenusTheoriques,
  revenuTheorique,
  revenuDeReference,
  dateLiquidation,
  monetaryErosions,
  regime,
  isPGPF,
  revalorisationCoefficientsType,
}: Pick<
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  'revalorisationCoefficientsType'
> &
  Pick<
    PerteGainProfessionnelsActuelSituation,
    'anneesRevenusTheoriques' | 'revenuTheorique' | 'revenuDeReference'
  > & {
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
    regime: string;
    isPGPF?: boolean;
  }): (Table | TableOfContents | Paragraph)[] => [
  ...(!isPGPF &&
  anneesRevenusTheoriques.some(
    (annee) => annee.netFiscal || annee.netPaye || annee.smicBrut,
  )
    ? [
        simpleHorizontalTablePrint(
          [
            i18next.t('numeroPiece.form.columnHeader'),
            i18next.t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.annee.columnHeader',
            ),
            regime === 'CHOMAGE'
              ? i18next.t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.netFiscal.chomage.columnHeader',
                )
              : isRegimeChiffreAffaire(regime)
                ? i18next.t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.netFiscal.chiffreAffaire.columnHeader',
                  )
                : i18next.t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.netFiscal.columnHeader',
                  ),
            ...(regime === 'CHOMAGE'
              ? [
                  i18next.t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.smicBrut.columnHeader',
                  ),
                  i18next.t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.victimeRedevable.columnHeader',
                  ),
                ]
              : []),
            i18next.t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.netPaye.columnHeader',
            ),
          ],
          anneesRevenusTheoriques.map(
            ({
              annee,
              netFiscal,
              netPaye,
              montantsDejaRevalorises,
              smicBrut,
              victimeRedevable,
              numerosPieces,
            }) => ({
              rowLabel: displayNumeroPiece(numerosPieces),
              columns: [
                annee.toString() || '',
                netFiscal ? fCurrency(netFiscal) : '',
                ...(regime === 'CHOMAGE'
                  ? [
                      smicBrut || smicBrut === 0 ? fCurrency(smicBrut) : '',
                      victimeRedevable
                        ? i18next.t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.victimeRedevable.print.value.true',
                          )
                        : i18next.t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.victimeRedevable.print.value.false',
                          ),
                    ]
                  : []),
                netPaye
                  ? getMontantRevalorisePrint({
                      montant: netPaye,
                      anneeOrDateLiquidation: dateLiquidation || undefined,
                      anneeOrDateMontant: annee || undefined,
                      montantsDejaRevalorises,
                      monetaryErosions,
                      coefficientsType: revalorisationCoefficientsType,
                    })
                  : '',
              ],
            }),
          ),
        ),
        getEmptyLine(),
      ]
    : []),
  getParagraph(
    i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenuDeReference.print.value',
      {
        value:
          revenuDeReference || revenuDeReference === 0
            ? fCurrency(revenuDeReference)
            : '',
      },
    ),
  ),
  ...(!isPGPF
    ? [
        getParagraph(
          i18next.t(
            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenuTheorique.print.value',
            {
              value:
                revenuTheorique || revenuTheorique === 0
                  ? fCurrency(revenuTheorique)
                  : '',
            },
          ),
        ),
      ]
    : []),
];
