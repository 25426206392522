import React from 'react';
import {
  Control,
  Controller,
  FieldPath,
  useController,
  useWatch,
} from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  NumeroPieceValuesRow,
} from '../../../../types/prejudice.type';
import { NormalTable, LayoutTable } from 'src/components/styled';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import {
  CalculsFormPerteGainsProfessionnelsActuel,
  CalculsGlobal,
} from '../../../../constants/calculs';
import { fCurrency } from '../../../../helpers/formatNumber';
import { CalculsBox } from '../../../basic/CalculsBox';
import { ReadFieldForm } from '../../../forms/ReadFieldForm';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import {
  getMontantRevalorise,
  getRevalorisationTooltip,
} from 'src/helpers/prejudices/revalorisation';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';
import { useTranslation } from 'react-i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';

interface Props {
  control: Control<
    | OldPrejudiceFormPerteGainProfessionnelsActuel
    | OldPrejudiceFormPerteGainProfessionnelsFuturs
  >;
  situationIndex: number;
  dateLiquidation: Date | undefined;
  dateConsolidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  isPGPF: boolean;
  allNumerosPieces: NumeroPieceValuesRow[];
  validate: (
    fieldsToValidate: FieldPath<
      | OldPrejudiceFormPerteGainProfessionnelsActuel
      | OldPrejudiceFormPerteGainProfessionnelsFuturs
    >[],
  ) => void;
  cancel: (
    fieldsToValidate: FieldPath<
      | OldPrejudiceFormPerteGainProfessionnelsActuel
      | OldPrejudiceFormPerteGainProfessionnelsFuturs
    >[],
  ) => void;
}

export const RevenusTheoriqueStep: React.FC<Props> = ({
  control,
  situationIndex,
  dateLiquidation,
  monetaryErosions,
  isPGPF,
  allNumerosPieces,
  validate,
  cancel,
}) => {
  const { t } = useTranslation();
  const watchValues = useWatch({
    control,
    name: [`situations.${situationIndex}.regime`, 'dateDebut', 'dateFin'],
  });
  const {
    fieldState: { error },
  } = useController({
    name: `situations.${situationIndex}.anneesRevenusTheoriques`,
    control,
  });
  if (!watchValues[0] || !watchValues[1] || !watchValues[2]) {
    return null;
  }
  const dateDebut = new Date(watchValues[1]);
  const dateFin = new Date(watchValues[2]);
  const regime = watchValues[0];
  const isChiffreAffaire = regime.substring(0, 18) === 'MICRO_ENTREPRENEUR';
  if (!dateDebut || !dateFin) {
    return null;
  }

  return (
    <>
      {!isPGPF && (
        <>
          <Stack alignItems="start" width="100%">
            <CalculsBox padding={2}>
              <FormControl>
                <FormLabel
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {t('prejudice.revalorisation.coefficientsType.choice.label')}
                </FormLabel>
                <RadioFormField
                  control={control}
                  name="revalorisationCoefficientsType"
                  options={[
                    {
                      label: t(
                        'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                      ),
                      value: 'annuel',
                    },
                    {
                      label: t(
                        'prejudice.revalorisation.coefficientsType.choice.options.smic',
                      ),
                      value: 'smic',
                    },
                  ]}
                  row={true}
                />
              </FormControl>
            </CalculsBox>
          </Stack>
          <TableContainer component={Paper}>
            <NormalTable size="medium">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {t('numeroPiece.form.columnHeader')}
                  </TableCell>
                  <TableCell align="center">
                    {t(
                      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.annee.columnHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {regime === 'CHOMAGE'
                      ? t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.netFiscal.chomage.columnHeader',
                        )
                      : isChiffreAffaire
                        ? t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.netFiscal.chiffreAffaire.columnHeader',
                          )
                        : t(
                            'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.netFiscal.columnHeader',
                          )}
                  </TableCell>
                  {regime === 'CHOMAGE' && (
                    <>
                      <TableCell align="center">
                        {t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.smicBrut.columnHeader',
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.victimeRedevable.columnHeader',
                        )}
                      </TableCell>
                    </>
                  )}
                  {!isPGPF && (
                    <TableCell align="right">
                      {t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusTheoriques.netPaye.columnHeader',
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {t(
                      'prejudice.revalorisation.columnHeaders.montantsDejaRevalorises',
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(3)].map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <FormNumeroPieceDialog
                        control={control}
                        name={`situations.${situationIndex}.anneesRevenusTheoriques.${index}.numerosPieces`}
                        allOtherNumeroPieces={allNumerosPieces}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ReadFieldForm
                        control={control}
                        name={`situations.${situationIndex}.anneesRevenusTheoriques.${index}.annee`}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextFieldForm
                        control={control}
                        name={`situations.${situationIndex}.anneesRevenusTheoriques.${index}.netFiscal`}
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '€',
                            nullable: true,
                          } as IMaskNumberProps,
                        }}
                      />
                    </TableCell>
                    {regime === 'CHOMAGE' && (
                      <>
                        <TableCell align="center">
                          <TextFieldForm
                            control={control}
                            name={`situations.${situationIndex}.anneesRevenusTheoriques.${index}.smicBrut`}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              inputProps: {
                                numberMask: { scale: 2 },
                                suffix: '€',
                              } as IMaskNumberProps,
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Controller
                            control={control}
                            name={`situations.${situationIndex}.anneesRevenusTheoriques.${index}.victimeRedevable`}
                            render={({ field }) => (
                              <Checkbox {...field} checked={field.value} />
                            )}
                          />
                        </TableCell>
                      </>
                    )}
                    {!isPGPF && (
                      <TableCell align="right">
                        <ComputedTextFieldForm
                          control={control}
                          name={`situations.${situationIndex}.anneesRevenusTheoriques.${index}.netPaye`}
                          watchFields={[
                            `situations.${situationIndex}.anneesRevenusTheoriques.${index}.annee`,
                            `situations.${situationIndex}.anneesRevenusTheoriques.${index}.netFiscal`,
                            `situations.${situationIndex}.anneesRevenusTheoriques.${index}.smicBrut`,
                            `situations.${situationIndex}.anneesRevenusTheoriques.${index}.victimeRedevable`,
                            `situations.${situationIndex}.anneesRevenusTheoriques.${index}.montantsDejaRevalorises`,
                            'revalorisationCoefficientsType',
                          ]}
                          compute={([
                            _,
                            netFiscal,
                            smicBrut,
                            victimeRedevable,
                          ]) => {
                            if (netFiscal != undefined && netFiscal != null) {
                              const netPaye =
                                CalculsFormPerteGainsProfessionnelsActuel.getSalaireNetPaye(
                                  regime,
                                  {
                                    netFiscal: Number(netFiscal),
                                    smicBrut: Number(smicBrut),
                                    victimeRedevable,
                                  },
                                );
                              return netPaye;
                            }
                            return null;
                          }}
                          computeTooltip={(
                            [
                              annee,
                              _,
                              __,
                              ___,
                              montantsDejaRevalorises,
                              revalorisationCoefficientsType,
                            ],
                            fieldValue,
                          ) =>
                            getRevalorisationTooltip({
                              anneeOrDateMontant: annee,
                              montant: fieldValue || 0,
                              montantsDejaRevalorises,
                              anneeOrDateLiquidation: dateLiquidation,
                              monetaryErosions,
                              coefficientsType: revalorisationCoefficientsType,
                            })
                          }
                          InputProps={{
                            inputComponent: MaskNumber as any,
                            inputProps: {
                              numberMask: {
                                scale: 2,
                                signed: isChiffreAffaire,
                              },
                              suffix: '€',
                              nullable: true,
                            } as IMaskNumberProps,
                          }}
                          editedFieldsName="editedFields"
                        />
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <Controller
                        control={
                          control as Control<OldPrejudiceFormPerteGainProfessionnelsActuel>
                        }
                        name={`situations.${situationIndex}.anneesRevenusTheoriques.${index}.montantsDejaRevalorises`}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </NormalTable>
          </TableContainer>
          <Stack alignItems="center">
            {error && (
              <Typography variant="subtitle2" color="error">
                {`${error.message}`}
              </Typography>
            )}
          </Stack>
        </>
      )}
      <Stack direction="row-reverse">
        <CalculsBox>
          <LayoutTable size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">
                    {isPGPF
                      ? t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.situations.revenuDeReference.rowHeader',
                        )
                      : t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenuDeReference.rowHeader',
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {isPGPF ? (
                    <TextFieldForm
                      control={control}
                      name={`situations.${situationIndex}.revenuDeReference`}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: {
                            scale: 2,
                            signed: isChiffreAffaire,
                            nullable: true,
                          },
                          suffix: '€',
                          nullable: true,
                        } as IMaskNumberProps,
                      }}
                    />
                  ) : (
                    <ComputedTextFieldForm
                      control={control}
                      name={`situations.${situationIndex}.revenuDeReference`}
                      watchFields={[
                        `situations.${situationIndex}.anneesRevenusTheoriques`,
                        'revalorisationCoefficientsType',
                      ]}
                      compute={([rows, revalorisationCoefficientsType]) => {
                        const nonZeroRows = rows.filter(
                          (row) =>
                            row.netPaye != undefined && row.netPaye != null,
                        );
                        const revenuDeReference = nonZeroRows
                          ? CalculsGlobal.average(
                              nonZeroRows.map((row) => {
                                if (
                                  row.netPaye &&
                                  row.montantsDejaRevalorises === false &&
                                  dateLiquidation &&
                                  monetaryErosions
                                ) {
                                  return getMontantRevalorise({
                                    montant: row.netPaye,
                                    anneeOrDateMontant: row.annee,
                                    anneeOrDateLiquidation: dateLiquidation,
                                    monetaryErosions,
                                    coefficientsType:
                                      revalorisationCoefficientsType,
                                  });
                                }
                                return row.netPaye;
                              }) as number[],
                            )
                          : null;
                        if (revenuDeReference || revenuDeReference === 0) {
                          return revenuDeReference;
                        }
                        return 0;
                      }}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          numberMask: {
                            scale: 2,
                            signed: isChiffreAffaire,
                            nullable: true,
                          },
                          suffix: '€',
                          nullable: true,
                        } as IMaskNumberProps,
                      }}
                      editedFieldsName="editedFields"
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">
                    {isPGPF
                      ? t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.fields.situations.revenuTheorique.rowHeader',
                        )
                      : t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenuTheorique.rowHeader',
                        )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <ComputedReadFieldForm
                    control={control}
                    name={`situations.${situationIndex}.revenuTheorique`}
                    watchFields={[
                      `situations.${situationIndex}.revenuDeReference`,
                    ]}
                    compute={([revenuDeReference]) => {
                      return (
                        CalculsFormPerteGainsProfessionnelsActuel.getRevenuTheorique(
                          dateDebut,
                          dateFin,
                          revenuDeReference || 0,
                        ) || 0
                      );
                    }}
                    render={(value) => <>{fCurrency(value)}</>}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </LayoutTable>
        </CalculsBox>
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ alignSelf: 'center' }}
          onClick={() => cancel([`situations.${situationIndex}`])}
        >
          {t('common.previous')}
        </Button>
        <Button
          variant="contained"
          sx={{ alignSelf: 'center' }}
          onClick={() => validate([`situations.${situationIndex}`])}
        >
          {t('common.next')}
        </Button>
      </Stack>
    </>
  );
};
