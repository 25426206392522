import { Table } from 'docx';
import i18next from 'i18next';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import {
  FormTableRow,
  prejudiceFormTable,
} from 'src/helpers/print/prejudicesPrints/prejudiceFormTablePrint';
import {
  ListeProjectionMaladieRow,
  PrejudiceFormListeProjection,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { getPeriodePrint } from '../../periodePrint';

export const prejudiceMaladiePrint = ({
  formData,
  prejudiceType,
}: {
  formData: PrejudiceFormListeProjection;
  prejudiceType: PrejudiceType;
}): Table[] | undefined => {
  const displayDegre = prejudiceType !== 'PREJUDICE_SEXUEL';

  return (
    formData.prejudiceValues?.rows &&
    prejudiceFormTable(
      formData.prejudiceValues.rows as ListeProjectionMaladieRow[],
      [
        {
          name: 'numerosPieces',
          headerLabel: i18next.t('numeroPiece.form.columnHeader'),
          render: (row) =>
            row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
        },
        {
          name: 'maladie',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.maladie.columnHeader',
          ),
        },
        {
          name: 'dateDebut',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.dateDebut.print.periodeColumnHeader',
          ),
          render: (row) =>
            getPeriodePrint({
              dateDebut: row.dateDebut,
              dateFin: row.dateFin,
            }),
        },
        {
          name: 'jours',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.jours.columnHeader',
          ),
        },
        ...(displayDegre
          ? ([
              {
                name: 'degre',
                headerLabel: i18next.t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.degre.columnHeader',
                ),
                render: (row) => fDecimalNumber(row.degre || 0, 1),
              },
            ] satisfies FormTableRow<
              ListeProjectionMaladieRow,
              keyof ListeProjectionMaladieRow,
              ListeProjectionMaladieRow[keyof ListeProjectionMaladieRow]
            >[])
          : []),
        {
          name: 'montant',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.montant.columnHeader',
          ),
          render: (row) => fCurrency(row.montant),
        },
      ],
    )
  );
};
