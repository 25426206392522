import { Paragraph, UnderlineType } from 'docx';
import { PrejudiceTotalValue } from 'src/helpers/prejudices/total';
import { Prejudice } from 'src/types/prejudice.type';
import { getTextRun } from '../docxFunctions';
import i18n from 'i18next';

export const prejudiceTitlePrint = (
  prejudice: Pick<Prejudice, 'type'>,
  _totalValues: PrejudiceTotalValue[],
): Paragraph => {
  // const isFutur = totalValues.some(
  //   (totalValue) =>
  //     totalValue.name === 'indemniteVictimeAEchoir' ||
  //     totalValue.name === 'indemniteTiersPayeurAEchoir',
  // );
  return new Paragraph({
    children: [
      ...getTextRun({
        text: `${i18n.t(`prejudice.prejudicesTypes.${prejudice.type}.title`)}${
          '' // totalValues.length > 1 ? ' - ' : ': '
        }`,
        bold: true,
        size: 24,
        underline: { color: '#000000', type: UnderlineType.SINGLE },
      }),
      // Hidden
      // ...totalValues
      //   .filter(
      //     (totalValue) =>
      //       totalValue.name === 'indemniteVictimeEchue' ||
      //       totalValue.name === 'indemniteVictimeAEchoir',
      //   )
      //   .reduce((accumulator: TextRun[], { name, amount }, index) => {
      //     const displayName = i18n.t(
      //       `prejudice.total.totalNames.shortPrint.${
      //         isFutur ? 'futur' : 'actuel'
      //       }.${name}`,
      //     );
      //     return [
      //       ...accumulator,
      //       ...getTextRun({
      //         text: `${index > 0 ? ' - ' : ''}${
      //           displayName ? `${displayName}: ` : ''
      //         }${fCurrency(amount)}`,
      //         bold: true,
      //         size: 24,
      //         underline: { color: '#000000', type: UnderlineType.SINGLE },
      //       }),
      //     ];
      //   }, []),
    ],
  });
};
