import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormValues,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { PrintType } from 'src/types/print.type';

const AVAILABLE_PRINTS_TYPES_BY_PREJUDICE_TYPE: Record<
  PrejudiceType,
  PrintType[]
> = {
  DEPENSE_SANTE_FUTURES: ['written', 'print'],
  FRAIS_DIVERS_FUTURS: ['written', 'print'],
  FRAIS_LOGEMENT_ADAPTE: ['written', 'print'],
  FRAIS_VEHICULE_ADAPTE: ['written', 'print'],
  PERTES_GAINS_PROFESSIONNELS_FUTURS: ['written', 'print'],
  INCIDENCE_PROFESSIONNELLE: ['written', 'print'],
  ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE: ['written', 'print'],
  DEFICIT_FONCTIONNEL_PERMANENT: ['written', 'print'],
  PREJUDICE_AGREMENT: ['written', 'print'],
  PREJUDICE_ESTHETIQUE_PERMANENT: ['written', 'print'],
  PREJUDICE_SEXUEL: ['written', 'print'],
  PREJUDICE_ETABLISSEMENT: ['written', 'print'],
  PREJUDICE_PERMANENT_EXCEPTIONNEL: ['written', 'print'],
  DEPENSE_SANTE_ACTUELLES: ['written', 'print'],
  FRAIS_DIVERS_ACTUELS: ['written', 'print'],
  PERTES_GAINS_PROFESSIONNELS_ACTUELS: ['written', 'print'],
  PREJUDICE_SCOLAIRE_UNIVERSITAIRE_FORMATION: ['written', 'print'],
  ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE: ['written', 'print'],
  DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL: ['written', 'print'],
  DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL: ['written', 'print'],
  SOUFFRANCES_ENDUREES: ['written', 'print'],
  PREJUDICE_ESTHETIQUE_TEMPORAIRE: ['written', 'print'],
  PREJUDICE_LIES_PATHOLOGIES_EVOLUTIVES: ['written', 'print'],
  PREJUDICE_IMPREPARATION_MEDICALE: ['written', 'print'],
  PREJUDICE_ANXIETE: ['written', 'print'],
  PREJUDICE_MATERIEL: ['written', 'print'],
  CALCUL_DES_INTERETS_ET_CAPITALISATION_DES_INTERETS: ['written', 'print'],
  AUTRES_POSTES_DE_PREJUDICES: ['written', 'print'],
  PREJUDICE_DANGOISSE_DE_MORT_IMMINENTE: ['written', 'print'],
  INDEMNITE_PROVISIONNELLE: ['written', 'print'],
  PERTES_REVENUS_DES_PROCHES: ['written', 'print'],
  FRAIS_OBSEQUES: ['written', 'print'],
  PREJUDICE_ACCOMPAGNEMENT_FIN_DE_VIE: ['written', 'print'],
  FRAIS_DIVERS_DES_PROCHES: ['written', 'print'],
  PREJUDICE_AFFECTION_OU_MORAL: ['written', 'print'],
  PREJUDICES_EXTRA_PATRIMONIAUX_EXCEPTIONNELS: ['print'],
  PREJUDICE_ANGOISSE_ET_INQUIETUDE: ['written', 'print'],
};

export const getAvailablePrintTypesForPrejudiceType = ({
  prejudiceType,
  formData,
}: {
  prejudiceType: PrejudiceType;
  formData: PrejudiceFormValues | undefined;
}): PrintType[] => {
  switch (prejudiceType) {
    case 'PERTES_GAINS_PROFESSIONNELS_ACTUELS': {
      const typedFormData = formData as
        | OldPrejudiceFormPerteGainProfessionnelsActuel
        | NewPrejudiceFormPerteGainProfessionnelsActuel
        | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
        | undefined;
      if (!typedFormData?.formType) {
        return ['print'];
      }
      break;
    }
    case 'PERTES_GAINS_PROFESSIONNELS_FUTURS': {
      const typedFormData = formData as
        | OldPrejudiceFormPerteGainProfessionnelsActuel
        | NewPrejudiceFormPerteGainProfessionnelsActuel
        | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
        | undefined;
      if (!typedFormData?.formType) {
        return ['print'];
      }
      break;
    }
    default:
      break;
  }
  return AVAILABLE_PRINTS_TYPES_BY_PREJUDICE_TYPE[prejudiceType];
};
