import { Document, Packer } from 'docx';
import { saveAs } from 'file-saver';
import i18next from 'i18next';
import { Prejudice } from 'src/types/prejudice.type';
import { PrintFormat } from 'src/types/print.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { BookType, WorkBook, writeFile } from 'xlsx';

export const getPrintProcedureFileName = (
  procedure: Procedure,
  victime: Victime,
) =>
  `norma-${
    i18next
      .t(`procedure.fields.procedureType.options.${procedure.procedureType}`)
      ?.toLowerCase() || ''
  }-${victime.referenceDossierVictime.toLowerCase()}`.replaceAll(' ', '');

export const getPrintDossierFileName = (victime: Victime) =>
  `norma-${victime.referenceDossierVictime.toLowerCase()}`.replaceAll(' ', '');

export const getPrintPrejudiceFileName = (
  procedure: Procedure,
  victime: Victime,
  prejudice: Pick<Prejudice, 'type' | 'formType'>,
) =>
  `norma-${
    i18next
      .t(`procedure.fields.procedureType.options.${procedure.procedureType}`)
      ?.toLowerCase() || ''
  }-${victime.referenceDossierVictime.toLowerCase()}-${prejudice.type
    .toLowerCase()
    .replaceAll(' ', '')}`;

const saveAsDocx = (document: Document, fileName: string) => {
  Packer.toBlob(document)
    .then((blob) => saveAs(blob, `${fileName}.docx`))
    .catch((e) => {
      console.log(e);
    });
};

const saveAsXlsx = (
  document: WorkBook,
  fileName: string,
  printFormat: BookType,
) => {
  writeFile(document, `${fileName}.${printFormat}`, { bookType: printFormat });
};

export const saveDocument = (
  document: Document | WorkBook,
  fileName: string,
  printFormat: PrintFormat,
) => {
  switch (printFormat) {
    case 'docx':
      return saveAsDocx(document as Document, fileName);
    case 'xlsx':
    case 'csv':
      return saveAsXlsx(document as WorkBook, fileName, printFormat);
  }
};
