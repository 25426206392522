import { Card } from '@mui/material';
import { styled } from '@mui/system';
import Page from '../basic/Page';

export const AuthenticationStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  height: '100%',
}));

export const AuthenticationSectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '30vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

export const AuthenticationContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  paddingTop: theme.spacing(5),
}));
