import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { getEmptyLine, getParagraph } from 'src/helpers/print/docxFunctions';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { tableRecapitulatifPrint } from 'src/helpers/print/prejudicesPrints/prejudices/perteDeGain/tableRecapitulatifPrint';
import { indemniteGlobaleARepartirEchusWrittenPrint } from '../../../indemniteGlobaleARepartir/indemniteGlobaleARepartirEchus.written.print';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { getRevalorisationIntroductionWrittenPrintIfRevalorise } from '../../../revalorisation.written.print';
import { saisieDirecteStepsWrittenPrint } from './steps/saisieDirecteSteps.written.print';

export const pgpaSaisieDirecteWrittenPrint = ({
  victime,
  procedure,
  formData,
  monetaryErosions,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
  monetaryErosions: MonetaryErosion[];
}): (Table | TableOfContents | Paragraph)[] => {
  const indemniteRepartie =
    CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie({
      ...formData,
      perteGainProfessionnelsTotal: formData.perteDeGainsProfessionnels.total,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .csgRdsTotal,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .indemnitesJournalieresPercuesNet,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
      partResponsabilite: procedure.partResponsabilite,
    });
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      formData,
      formType: 'PERTE_GAINS_PROFESSIONNELS_ACTUEL',
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    }),
    ...saisieDirecteStepsWrittenPrint({
      formData,
      victime,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      monetaryErosions,
      isPGPF: false,
    }),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 0,
      },
      text: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.tableRecapitulatif.title',
      ),
    }),
    ...tableRecapitulatifPrint(
      formData,
      procedure.partResponsabilite,
      undefined,
      false,
    ),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 0,
      },
      text: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.indemniteGlobaleARepartir.title',
      ),
    }),
    ...indemniteGlobaleARepartirEchusWrittenPrint({
      indemniteRepartie,
      firstPart: i18next.t(
        `prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.indemniteGlobaleARepartir.firstPart`,
      ),
      partResponsabilite: procedure.partResponsabilite,
      victime,
      tiersPayeurs: procedure.tiersPayeurs,
      indemniteGlobaleFormula: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.indemniteGlobaleARepartir.indemniteGlobale.formula',
        {
          perteGainProfessionnelsTotal: fCurrency(
            formData.perteDeGainsProfessionnels.total,
          ),
          csgCrdsTotal: fCurrency(
            formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
              .csgRdsTotal || 0,
          ),
          partResponsabilite: fPartResponsabilite(
            procedure.partResponsabilite * 100,
          ),
          context: formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
            .csgRdsTotal
            ? undefined
            : 'noCsgCrds',
        },
      ),
    }),
  ];
};
