import React from 'react';
import { InputProps } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { floor } from 'lodash';

type NumberMask = {
  scale?: number;
  signed?: boolean;
  thousandsSeparator?: string;
  padFractionalZeros?: boolean;
  normalizeZeros?: boolean;
  radix?: string;
  mapToRadix?: string[];
  min?: number;
  max?: number;
};

export interface IMaskNumberProps {
  numberMask?: NumberMask;
  suffix?: string;
  negative?: boolean;
  nullable?: boolean;
  defaultsToUndefined?: boolean;
}

export const MaskNumber = React.forwardRef<
  HTMLElement,
  IMaskNumberProps & InputProps
>(function MaskCurrency(props, ref) {
  const { onChange, value, numberMask, suffix, negative, nullable, ...rest } =
    props;

  return (
    <IMaskInput
      {...rest}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={value?.toString()}
      mask={suffix ? `num ${suffix}` : 'num'}
      lazy={false}
      blocks={{
        num: {
          mask: Number,
          thousandsSeparator: ' ',
          ...numberMask,
        },
      }}
      unmask
      inputRef={ref}
      style={{ textAlign: 'right', minWidth: 50 }}
      onClick={(e: any) => {
        const length =
          (value as number | string | undefined)?.toString().length || 0;
        const thousandsSeparatorLength =
          length > 0
            ? floor((length - 1) / 3) *
              (numberMask?.thousandsSeparator
                ? numberMask.thousandsSeparator.length
                : 1)
            : 0;
        return e.target.setSelectionRange(0, length + thousandsSeparatorLength);
      }}
      onAccept={(value: string) => {
        if (onChange) {
          const updatedValue =
            value && !isNaN(Number(value))
              ? negative === true && Number(value) > 0
                ? -Number(value)
                : Number(value)
              : nullable === true
                ? null
                : 0;
          if (updatedValue !== props.value) {
            onChange({
              target: {
                name: props.name,
                value: updatedValue,
              },
            } as any);
          }
        }
      }}
      overwrite
    />
  );
});
