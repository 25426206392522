import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaremeType } from 'src/types/bareme.type';

interface Props extends ButtonProps {
  isMultipleBaremes?: boolean;
  baremeType: BaremeType;
}

export const ResourcesButton: React.FC<Props> = ({
  isMultipleBaremes,
  baremeType,
  ...props
}) => {
  const { t } = useTranslation();
  const baseUrl = window.location.origin;

  return (
    <Button
      {...props}
      sx={{
        width: '400px',
        maxWidth: '100%',
        textDecoration: 'none',
        ...props.sx,
      }}
      variant="text"
      onClick={() =>
        window.open(
          `${baseUrl}/bareme/${baremeType}`,
          '',
          `width=${window.innerWidth / 2},height=${window.innerHeight - 100}`,
        )
      }
    >
      {t('bareme.prejudicesButton.text', { count: isMultipleBaremes ? 2 : 1 })}
    </Button>
  );
};
