import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/store';
import { AccountNotActivated } from 'src/pages/AccountNotActivated';
import { CompleteGoogleSignUp } from 'src/containers/authentication/CompleteGoogleSignUp';
import { OrganizationSwitch } from './OrganizationSwitch';
import { SentryRoutes } from './sentry';

export const LoggedSwitch: React.FC = () => {
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  if (user?.googleSignUpToBeCompleted) {
    return (
      <SentryRoutes>
        <Route
          path="/completeGoogleSignUp"
          element={<CompleteGoogleSignUp />}
        />
        <Route
          path="*"
          element={<Navigate to="/completeGoogleSignUp" replace />}
        />
      </SentryRoutes>
    );
  } else if (user?.disabled) {
    return (
      <SentryRoutes>
        <Route path="/accountNotActivated" element={<AccountNotActivated />} />
        <Route
          path="*"
          element={<Navigate to="/accountNotActivated" replace />}
        />
      </SentryRoutes>
    );
  }
  return <OrganizationSwitch />;
};
