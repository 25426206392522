import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  NumberingType,
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { displayRowsSumFormula } from 'src/helpers/print/prejudicesWrittenPrints/formula';
import { displayNumerosPiecesWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/numerosPieces.written.print';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
  PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs,
} from 'src/types/prejudice.type';

export const revenusIndemnitesWrittenPrint = ({
  revenusIndemnitesTiersPayeurs,
  totalIndemnitesNet,
  totalIndemnitesCsgCrds,
  situationIndex,
  formData,
  isPGPF,
}: {
  revenusIndemnitesTiersPayeurs: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs[];
  totalIndemnitesNet: number;
  totalIndemnitesCsgCrds: number;
  situationIndex: number;
  formData: OldPrejudiceFormPerteGainProfessionnelsActuel;
  isPGPF?: boolean;
} & Pick<
  PerteGainProfessionnelsActuelSituation,
  'totalIndemnitesNet' | 'totalIndemnitesCsgCrds'
>): (Table | TableOfContents | Paragraph)[] => {
  const noIndemnitesJournalieresPercuesPendantLaPeriodeDArretText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.tiersPayeursIndemnites.noIndemnitesJournalieresPercuesPendantLaPeriodeDArret',
  );
  const getIndemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeurText =
    (
      indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur: PerteGainProfessionnelsActuelSituationRevenusIndemnitesTiersPayeurs,
      index: number,
    ): string => {
      const indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeurFirstPartText =
        i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.tiersPayeursIndemnites.indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur.firstPart',
          {
            numerosPieces: displayNumerosPiecesWrittenPrint(
              indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur.numerosPieces,
              'Parentheses',
            ),
            tiersPayeur:
              indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur.tiersPayeur,
            indemnitesJournalieresPercuesPendantLaPeriodeDArret: fCurrency(
              indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur.netPaye ||
                0,
            ),
            formula: displayRowsSumFormula({
              rowValues:
                indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur.anneeTiersPayeursIndemnites.map(
                  (annee) => annee.netPaye || 0,
                ),
              options: {
                withParentheses: true,
              },
              editedFieldsParameters: {
                formData,
                fieldName: `situations.${situationIndex}.tiersPayeursIndemnites.${index}.netPaye`,
              },
            }),
          },
        );

      const indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeurCsgCrdsText =
        i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.tiersPayeursIndemnites.indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur.csgCrds',
          {
            csgCrds: fCurrency(
              indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur.csgRds ||
                0,
            ),
            formula: displayRowsSumFormula({
              rowValues:
                indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeur.anneeTiersPayeursIndemnites.map(
                  (annee) => annee.csgRds || 0,
                ),
              options: {
                withParentheses: true,
              },
              editedFieldsParameters: {
                formData,
                fieldName: `situations.${situationIndex}.tiersPayeursIndemnites.${index}.csgRds`,
              },
            }),
          },
        );
      return `${indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeurFirstPartText}${
        !isPGPF
          ? ` ${indemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeurCsgCrdsText}`
          : ''
      }`;
    };
  const totalText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.tiersPayeursIndemnites.total',
    {
      totalIndemnitesNet: fCurrency(totalIndemnitesNet),
      formula: displayRowsSumFormula({
        rowValues: revenusIndemnitesTiersPayeurs.map(
          (tiersPayeur) => tiersPayeur.netPaye || 0,
        ),
        options: {
          withParentheses: true,
        },
        editedFieldsParameters: {
          formData,
          fieldName: `situations.${situationIndex}.totalIndemnitesNet`,
        },
      }),
    },
  );

  const totalCsgCrdsText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.tiersPayeursIndemnites.totalCsgCrds',
    {
      totalIndemnitesCsgCrds: fCurrency(totalIndemnitesCsgCrds),
      formula: displayRowsSumFormula({
        rowValues: revenusIndemnitesTiersPayeurs.map(
          (tiersPayeur) => tiersPayeur.csgRds || 0,
        ),
        options: {
          withParentheses: true,
        },
        editedFieldsParameters: {
          formData,
          fieldName: `situations.${situationIndex}.totalIndemnitesCsgCrds`,
        },
      }),
    },
  );

  const firstPartText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.tiersPayeursIndemnites.firstPart',
  );

  const nonZeroRows = revenusIndemnitesTiersPayeurs.filter(
    (tiersPayeur) => !!tiersPayeur.netPaye || !!tiersPayeur.csgRds,
  );
  switch (true) {
    case nonZeroRows.length === 0 &&
      totalIndemnitesNet === 0 &&
      totalIndemnitesCsgCrds === 0:
      return [
        getParagraph(noIndemnitesJournalieresPercuesPendantLaPeriodeDArretText),
        getEmptyLine(),
        getParagraph(totalText),
        ...(totalIndemnitesCsgCrds && !isPGPF
          ? [getParagraph(totalCsgCrdsText)]
          : []),
      ];
    default:
      return [
        getParagraph(firstPartText),
        ...nonZeroRows.map((tiersPayeur, index) =>
          getParagraph({
            numbering: {
              reference: NumberingType.SIMPLE_LIST,
              level: 0,
            },
            children: getTextRun(
              getIndemnitesJournalieresPercuesPendantLaPeriodeDArretParTiersPayeurText(
                tiersPayeur,
                index,
              ),
            ),
          }),
        ),
        getEmptyLine(),
        getParagraph(totalText),
        ...(totalIndemnitesCsgCrds && !isPGPF
          ? [getParagraph(totalCsgCrdsText)]
          : []),
      ];
  }
};
