import { ProcedureQuestion } from '../types/procedure.type';

export const prettyString: Record<ProcedureQuestion, string> = {
  ACCIDENT_DU_TRAVAIL: 'AT/MP - Faute inexcusable employeur',
  ACCIDENT_DROIT_COMMUN: 'Accident de Droit commun',
  INDEMNISATION_JUDICIAIRE:
    'Indemnisation relevant des juridictions judiciaires',
  INDEMNISATION_ADMINISTRATIVE:
    'Indemnisation relevant des juridictions administratives',
};
