import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { getEmptyLine, getParagraph } from 'src/helpers/print/docxFunctions';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
} from 'src/types/prejudice.type';
import { getMontantRevalorisePrint } from '../../revalorisationPrint';

export const revenusReelsPrint = ({
  anneesRevenusReels,
  revenusReelsNet,
  dateLiquidation,
  monetaryErosions,
  revalorisationCoefficientsType,
}: Pick<
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  'revalorisationCoefficientsType'
> &
  Pick<
    PerteGainProfessionnelsActuelSituation,
    'anneesRevenusReels' | 'revenusReelsNet'
  > & {
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
    regime: string;
  }): (Table | TableOfContents | Paragraph)[] => [
  ...(anneesRevenusReels.some((annee) => annee.netPaye || annee.netPaye === 0)
    ? [
        simpleHorizontalTablePrint(
          [
            i18next.t('numeroPiece.form.columnHeader'),
            i18next.t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.annee.columnHeader',
            ),
            i18next.t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.anneesRevenusReels.netPaye.columnHeader',
            ),
          ],
          anneesRevenusReels.map(
            ({ numerosPieces, annee, netPaye, montantsDejaRevalorises }) => ({
              rowLabel: displayNumeroPiece(numerosPieces),
              columns: [
                annee?.toString() || '',
                netPaye || netPaye === 0
                  ? getMontantRevalorisePrint({
                      montant: netPaye,
                      anneeOrDateLiquidation: dateLiquidation || undefined,
                      anneeOrDateMontant: annee || undefined,
                      monetaryErosions,
                      montantsDejaRevalorises,
                      coefficientsType: revalorisationCoefficientsType,
                    })
                  : '',
              ],
            }),
          ),
        ),
        getEmptyLine(),
      ]
    : []),
  getEmptyLine(),
  getParagraph(
    i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenusReelsNet.print.value',
      {
        value:
          revenusReelsNet || revenusReelsNet === 0
            ? fCurrency(revenusReelsNet)
            : '',
      },
    ),
  ),
];
