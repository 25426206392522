export abstract class Time {
  static daysInWeek = 7;
  static daysInYear = 365.25;
  static monthsInYear = 12;
  static daysInMonth = this.daysInYear / this.monthsInYear;
  static nonPreciseDaysInMonth = 31;
  static weeksInMonth = this.daysInMonth / this.daysInWeek;
  static hoursInDay = 24;
  static hoursInWeek = this.hoursInDay * this.daysInWeek;
  static hoursInMonths = this.hoursInDay * this.daysInMonth;
  static hoursInYear = this.hoursInDay * this.daysInYear;
  static weeksInYear = 52;
}
