import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import {
  NumberingType,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import {
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  RevenuAnnuelEspereRow,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { displayNumerosPiecesWrittenPrint } from '../../../../numerosPieces.written.print';
import { displayRowsSumFormula } from 'src/helpers/print/prejudicesWrittenPrints/formula';
import { fCurrencyUnite } from 'src/helpers/formatValues';
import { displayVictimeNameWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/victime.written.print';

export const revenuEspereWrittenPrint = ({
  formData,
  isPGPF,
  victime,
}: {
  formData: PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
  victime: Victime;
  isPGPF: boolean;
}): (Table | TableOfContents | Paragraph)[] => {
  const { rows, total: totalRevenuEspere } = formData.revenuEspere;
  const title = getParagraph({
    numbering: {
      reference: 'numbers-dot-simple',
      level: isPGPF ? 1 : 0,
    },
    text: i18next.t(
      'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.title',
    ),
  });

  const noRowsText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.noRows',
    {
      victimeName: displayVictimeNameWrittenPrint(victime),
      total: fCurrency(formData.revenuEspere.total),
    },
  );
  const getFirstRowText = (row: RevenuAnnuelEspereRow) => {
    if (row.coefficientPerteDeChance === 100) {
      return i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.firstRow.fullCoefficientPerteDeChance',
        {
          numerosPieces: displayNumerosPiecesWrittenPrint(
            row.numerosPieces,
            'Parentheses',
          ),
          revenuEspere: fCurrencyUnite(row.revenuEspere, row.unite || 'annee'),
        },
      );
    } else {
      return i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.firstRow.nonFullCoefficientPerteDeChance',
        {
          numerosPieces: displayNumerosPiecesWrittenPrint(
            row.numerosPieces,
            'Parentheses',
          ),
          coefficientPerteDeChance: fPercent(row.coefficientPerteDeChance),
          revenuEspere: fCurrencyUnite(row.revenuEspere, row.unite || 'annee'),
        },
      );
    }
  };
  const getRowText = (row: RevenuAnnuelEspereRow, index: number) => {
    if (index === 0) {
      return getFirstRowText(row);
    }
    if (row.coefficientPerteDeChance === 100) {
      return i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.row.fullCoefficientPerteDeChance',
        {
          numerosPieces: displayNumerosPiecesWrittenPrint(
            row.numerosPieces,
            'Parentheses',
          ),
          revenuEspere: fCurrencyUnite(row.revenuEspere, row.unite || 'annee'),
        },
      );
    } else {
      return i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.row.nonFullCoefficientPerteDeChance',
        {
          numerosPieces: displayNumerosPiecesWrittenPrint(
            row.numerosPieces,
            'Parentheses',
          ),
          coefficientPerteDeChance: fPercent(row.coefficientPerteDeChance),
          revenuEspere: fCurrencyUnite(row.revenuEspere, row.unite || 'annee'),
        },
      );
    }
  };

  const oneRowFullCoefficientPerteDeChanceFirstPartText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.oneRowFullCoefficientPerteDeChanceFirstPart',
  );

  const oneRowNonFullCoefficientPerteDeChanceFirstPartText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.oneRowNonFullCoefficientPerteDeChanceFirstPart',
  );
  const totalText = i18next.t(
    'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.saisieDirecte.steps.revenuEspere.total',
    {
      total: fCurrency(totalRevenuEspere),
      formula: displayRowsSumFormula({
        rowValues: rows.map((row) => row.perteDeChance),
        options: {
          withParentheses: true,
        },
        editedFieldsParameters: {
          formData,
          fieldName: 'revenuEspere.total',
        },
      }),
    },
  );

  const nonZeroRows = rows.filter(
    (row) =>
      !!row.perteDeChance ||
      !!row.revenuEspere ||
      !!row.coefficientPerteDeChance,
  );

  switch (nonZeroRows.length) {
    case 0:
      return [title, getParagraph(noRowsText)];
    case 1:
      return !!nonZeroRows[0]
        ? [
            title,
            getParagraph({
              children: [
                ...getTextRun(
                  nonZeroRows[0].coefficientPerteDeChance === 100
                    ? oneRowFullCoefficientPerteDeChanceFirstPartText
                    : oneRowNonFullCoefficientPerteDeChanceFirstPartText,
                ),
                ...getTextRun(getFirstRowText(nonZeroRows[0])),
              ],
            }),
            getParagraph(totalText),
          ]
        : [];
    default:
      return [
        title,
        ...nonZeroRows.map((row, index) =>
          getParagraph({
            numbering: {
              reference: NumberingType.SIMPLE_LIST,
              level: 0,
            },
            children: getTextRun(getRowText(row, index)),
          }),
        ),
        getParagraph(totalText),
      ];
  }
};
