import React from 'react';
import { Control } from 'react-hook-form';
import { TableCell, TableFooter, TableRow } from '@mui/material';
import {
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceCapitalisation,
} from '../../../../types/prejudice.type';
import { SpanNoWrap } from '../../../styled';
import { CalculsFormCalendrierAssistance } from 'src/constants/calculs/calculsFormCalendrierAssistance';
import { fCurrency, fDecimalNumber } from '../../../../helpers/formatNumber';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { useTranslation } from 'react-i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';

interface Props {
  control:
    | Control<PrejudiceFormCalendrierAssistance>
    | Control<PrejudiceFormCalendrierAssistanceCapitalisation>;
  tiersPayeurs: string[];
  partResponsabilite: number;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation: Date | undefined;
  isCalculCapitalisation?: boolean;
  shouldNotDisplayCapitalisation: boolean;
}

export const TableAssistanceFooter: React.FC<Props> = ({
  control,
  tiersPayeurs,
  partResponsabilite,
  monetaryErosions,
  dateLiquidation,
  isCalculCapitalisation,
  shouldNotDisplayCapitalisation,
}) => {
  const { t } = useTranslation();
  return (
    <TableFooter>
      <ComputedPropsForm
        control={control as Control<PrejudiceFormCalendrierAssistance>}
        watchFields={['rows', 'revalorisationCoefficientsType']}
        compute={([rows, revalorisationCoefficientsType]) => {
          return {
            props: CalculsFormCalendrierAssistance.totaux({
              rows,
              partResponsabilite,
              tiersPayeurs,
              dateLiquidation,
              monetaryErosions,
              revalorisationCoefficientsType,
            }),
          };
        }}
        render={(props) => {
          const {
            montantTotal,
            priseEnChargeTiersPayeurs,
            resteACharge,
            montantTotalAnnualise,
            totalQuantite,
          } = props as ReturnType<
            typeof CalculsFormCalendrierAssistance.totaux
          >;
          return (
            <>
              <TableRow>
                <TableCell />
                <TableCell
                  colSpan={2}
                  sx={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 3,
                    backgroundColor: 'white',
                  }}
                />
                <TableCell
                  colSpan={
                    6 +
                    (!isCalculCapitalisation && !shouldNotDisplayCapitalisation
                      ? 3
                      : 0)
                  }
                />
                <TableCell align="right">
                  <SpanNoWrap>
                    {t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.total.totalRow.totalheures.value',
                      {
                        value: fDecimalNumber(totalQuantite),
                      },
                    )}
                  </SpanNoWrap>
                </TableCell>
                {isCalculCapitalisation && !shouldNotDisplayCapitalisation ? (
                  <>
                    <TableCell align="right" colSpan={2}>
                      <ComputedReadFieldForm
                        control={
                          control as Control<PrejudiceFormCalendrierAssistanceCapitalisation>
                        }
                        name="totalMontantTotalAnnualise"
                        watchFields={[]}
                        customValues={[montantTotalAnnualise]}
                        compute={([], customValues) =>
                          customValues?.[0]?.value || 0
                        }
                        render={(totalmontantTotalAnnualise) => (
                          <SpanNoWrap>
                            {fCurrency(totalmontantTotalAnnualise)}
                          </SpanNoWrap>
                        )}
                      />
                    </TableCell>
                  </>
                ) : null}
                <TableCell align="right" colSpan={2}>
                  {t(
                    'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.total.totalRow.rowHeader',
                  )}
                </TableCell>
                <TableCell align="right">
                  <SpanNoWrap>{fCurrency(montantTotal.total.value)}</SpanNoWrap>
                </TableCell>
                {tiersPayeurs.map((tierPayeur) => (
                  <TableCell key={tierPayeur} align="right">
                    <SpanNoWrap>
                      {fCurrency(
                        priseEnChargeTiersPayeurs[tierPayeur]?.total.value || 0,
                      )}
                    </SpanNoWrap>
                  </TableCell>
                ))}
                <TableCell colSpan={2} />
                <TableCell align="right" colSpan={2}>
                  <SpanNoWrap>{fCurrency(resteACharge.total.value)}</SpanNoWrap>
                </TableCell>
              </TableRow>
            </>
          );
        }}
      />
    </TableFooter>
  );
};
