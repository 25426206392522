import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';

const SubscriptionSuccessful: React.FC = () => {
  const { organization, organizationId } = useDisplayedOrganization();
  const subscription = organization?.subscription;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickBack = () => navigate(`/organization/${organizationId}`);
  const onClickOrganizationManagement = () =>
    navigate(`/organization/${organizationId}/settings`);

  if (!subscription || !subscription.isAutomaticSubscriptionPaymentActive) {
    return (
      <Stack
        height="100%"
        paddingX={9}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" paddingBottom={6}>
          {t('pages.SubscriptionSuccessful.error.title')}
        </Typography>
        <Typography
          variant="h5"
          color="GrayText"
          textAlign="center"
          paddingBottom={4}
          maxWidth="lg"
        >
          {t('pages.SubscriptionSuccessful.error.description')}
        </Typography>
        <Button
          startIcon={<ArrowBack />}
          onClick={onClickBack}
          sx={{ textTransform: 'none', marginTop: 6 }}
        >
          <Typography variant="subtitle1" fontWeight="800">
            {t('pages.SubscriptionSuccessful.backToHome')}
          </Typography>
        </Button>
      </Stack>
    );
  }
  return (
    <Stack
      height="100%"
      paddingX={9}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h4" paddingBottom={6}>
        {t('pages.SubscriptionSuccessful.title')}
      </Typography>
      <Typography
        variant="h5"
        color="GrayText"
        textAlign="center"
        paddingBottom={4}
        maxWidth="lg"
      >
        {t('pages.SubscriptionSuccessful.description')}
      </Typography>
      <Button
        variant="contained"
        onClick={onClickOrganizationManagement}
        sx={{ textTransform: 'none', marginTop: 3 }}
      >
        <Typography variant="subtitle1" fontWeight="800">
          {t('pages.SubscriptionSuccessful.organizationManagement')}
        </Typography>
      </Button>
      <Button
        startIcon={<ArrowBack />}
        onClick={onClickBack}
        sx={{ textTransform: 'none', marginTop: 3 }}
      >
        <Typography variant="subtitle1" fontWeight="800">
          {t('pages.SubscriptionSuccessful.backToHome')}
        </Typography>
      </Button>
    </Stack>
  );
};

export default SubscriptionSuccessful;
