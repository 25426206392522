import { Paragraph, Table, TableOfContents } from 'docx';
import {
  PrejudiceFormScolaire,
  PrejudiceFormScolairePerteDeChanceRow,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { CalculsFormScolaire, CalculsGlobal } from 'src/constants/calculs';
import { displayFormula, displayRowsSumFormula } from '../formula';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';

export const scolaireWrittenPrint = ({
  victime,
  procedure,
  formData,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormScolaire;
}): (Table | TableOfContents | Paragraph)[] => {
  const perteDeChanceRows = formData.perteDeChance.rows.filter(
    (row) => !!row.montant,
  );

  const getPerteDeChanceRowText = (
    row: PrejudiceFormScolairePerteDeChanceRow,
  ): string =>
    i18next.t(
      'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.perteDeChance.rows.row',
      {
        montant: fCurrency(row.montant),
        numerosPieces: displayNumerosPiecesWrittenPrint(
          row.numerosPieces,
          'Parentheses',
        ),
        libelle: row.libelle,
        victimeName: displayVictimeNameWrittenPrint(victime),
        coefficientPerteDeChance: fPercent(row.coefficientPerteDeChance * 100),
        perteDeChance: fCurrency(CalculsFormScolaire.getPerteDeChance(row)),
        formula:
          row.montant && row.coefficientPerteDeChance
            ? displayFormula({
                formula: i18next.t(
                  'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.perteDeChance.formula',
                  {
                    montant: fCurrency(row.montant),
                    coefficientPerteDeChance: fPercent(
                      row.coefficientPerteDeChance * 100,
                    ),
                  },
                ),
                options: {
                  withParentheses: true,
                },
                editedFieldsParameters: {
                  formData,
                  fieldName: `perteDeChance.rows.0.montant`,
                },
              })
            : '',
      },
    );
  const firstPartText = formData.montant
    ? i18next.t(
        'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.firstPart',
        {
          montant: fCurrency(formData.montant),
          numerosPieces: displayNumerosPiecesWrittenPrint(
            formData.numerosPieces,
            'Parentheses',
          ),
        },
      )
    : '';
  const perteDeChanceByNumberOfRows: Record<
    'noRow' | 'oneRow' | 'multipleRows',
    Paragraph[]
  > = {
    noRow: [],
    oneRow:
      formData.perteDeChance &&
      perteDeChanceRows.length > 0 &&
      perteDeChanceRows[0]
        ? [
            ...(firstPartText ? [getParagraph(firstPartText)] : []),
            getParagraph({
              children: [
                ...getTextRun(
                  i18next.t(
                    'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.perteDeChance.firstPart',
                    {
                      context: !formData.montant ? 'noMontant' : undefined,
                    },
                  ),
                ),
                ...getTextRun(getPerteDeChanceRowText(perteDeChanceRows[0])),
              ],
            }),
          ]
        : [],
    multipleRows: [
      ...(firstPartText ? [getParagraph(firstPartText)] : []),
      getParagraph(
        `${i18next.t(
          'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.perteDeChance.firstPart',
          { context: !formData.montant ? 'noMontant' : undefined },
        )} :`,
      ),
      ...perteDeChanceRows.map((row) =>
        getParagraph({
          numbering: {
            reference: 'simple-list',
            level: 0,
          },
          children: getTextRun(getPerteDeChanceRowText(row)),
        }),
      ),
      getParagraph({
        children: [
          ...getTextRun(
            i18next.t(
              'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.perteDeChance.total',
              {
                total: fCurrency(formData.perteDeChance.total),
                formula: displayRowsSumFormula({
                  rowValues: perteDeChanceRows.map(
                    CalculsFormScolaire.getPerteDeChance,
                  ),
                  options: {
                    withParentheses: true,
                  },
                  editedFieldsParameters: {
                    formData,
                    fieldName: `perteDeChance.total`,
                  },
                }),
              },
            ),
          ),
          ...getTextRun(
            i18next.t(
              'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.perteDeChance.totalSecondPart',
              {
                montant: fCurrency(formData.montant),
                context: !formData.montant ? 'noMontant' : undefined,
              },
            ),
          ),
        ],
      }),
    ],
  };
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...perteDeChanceByNumberOfRows[
      !formData.perteDeChance || perteDeChanceRows.length === 0
        ? 'noRow'
        : perteDeChanceRows.length === 1
          ? 'oneRow'
          : 'multipleRows'
    ],
    getEmptyLine(),
    getParagraph({
      children: [
        ...getTextRun({
          text: i18next.t(
            'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.total.firstPart',
          ),
        }),
        ...getTextRun(
          i18next.t(
            procedure.partResponsabilite === 1
              ? 'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.total.fullPartResponabilite'
              : 'prejudice.prejudicesFormTypes.SCOLAIRE.writtenPrint.total.nonFullPartResponabilite',
            {
              victimeName: displayVictimeNameWrittenPrint(victime),
              numerosPieces: displayNumerosPiecesWrittenPrint(
                formData.numerosPieces,
                'Parentheses',
              ),
              total: fCurrency(formData.total),
              ...(procedure.partResponsabilite !== 1
                ? {
                    partResponsabilite: fPartResponsabilite(
                      procedure.partResponsabilite * 100,
                    ),
                    totalPartResponsabilite: fCurrency(
                      formData.total * procedure.partResponsabilite,
                    ),
                  }
                : {}),
              formula: displayRowsSumFormula({
                rowValues: [
                  CalculsGlobal.sum(
                    perteDeChanceRows.map(CalculsFormScolaire.getPerteDeChance),
                  ),
                  formData.montant,
                ],
                options: {
                  withParentheses: true,
                },
                editedFieldsParameters: {
                  fieldName: 'total',
                  formData,
                },
              }),
            },
          ),
        ),
      ],
    }),
  ];
};
