import { Paragraph, Table, TableOfContents } from 'docx';
import { PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine } from '../../../docxFunctions';
import { prejudiceNotesPrint } from '../../prejudiceNotesPrint';
import { debitSoldeEchusPrint } from '../../debitSoldeEchusPrint';
import { tableRecapitulatifSaisieDirectePrint } from './tableRecapitulatiifSaisieDirectePrint';
import { stepsSaisieDirectePrint } from './stepsSaisieDirectePrint';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';

export const pgpaSaisieDirectePrint = (
  procedure: Procedure,
  formData: PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
): (Paragraph | Table | TableOfContents)[] => {
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const indemniteGlobaleARepartir =
    CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie({
      indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .csgRdsTotal,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .indemnitesJournalieresPercuesNet,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
      perteGainProfessionnelsTotal: formData.perteDeGainsProfessionnels.total,
      partResponsabilite: procedure.partResponsabilite,
    });
  const tableRecapitulatif = tableRecapitulatifSaisieDirectePrint({
    ...formData,
    partResponsabilite: procedure.partResponsabilite,
    isPGPF: false,
  });
  const steps = stepsSaisieDirectePrint({
    formData,
    isPGPF: false,
  });
  const debitSoldeTable = debitSoldeEchusPrint({
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
  });

  return [
    getEmptyLine(),
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...steps,
    getEmptyLine(),
    ...tableRecapitulatif,
    getEmptyLine(),
    ...debitSoldeTable,
  ];
};
