import React, { useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  IconButton,
  TableBody,
  Button as MuiButton,
  Grid,
  Button,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { NormalTable } from '../../../components/styled';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { createDommage, updateDommage } from '../../../slices/dommage';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldForm } from '../../../components/forms/TextFieldForm';
import { DatePickerForm } from '../../../components/forms/DatePickerForm';
import { Dommage, CreateUpdateDommageDTO } from '../../../types/dommage.type';
import { ProcedureHospitalisation } from '../../../types/procedure.type';
import {
  Prejudice,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormType,
} from '../../../types/prejudice.type';
import {
  createPrejudice,
  fetchAllPrejudices,
  updatePrejudice,
} from '../../../slices/prejudice';
import { prejudiceSelectors } from '../../../store/selectors';
import { prejudicesData } from '../../../constants/prejudices';
import { ComputedPropsForm } from '../../../components/forms/ComputedPropsForm';
import { CalculsGlobal } from '../../../constants/calculs';
import { CloseOutlined, DeleteRounded } from '@mui/icons-material';
import { ComputedTextFieldForm } from '../../../components/forms/ComputedTextFieldForm';
import { MaskNumber } from '../../../components/masks/MaskNumber';
import { updateProcedure } from '../../../slices/procedure';
import { Victime } from '../../../types/victime.type';
import { MAX_TIMESTAMP } from '../../../helpers/formatTime';
import { ComputedDatePickerForm } from '../../../components/forms/ComputedDatePickerForm';
import { isEqual } from 'date-fns';
import { dateString } from 'src/helpers/yup';

interface Props {
  procedureId: string;
  victime: Victime;
  dommage?: Dommage;
  open: boolean;
  onClose: () => void;
  onClickClose: () => void;
}
export const validationSchemaProcedureHospitalisation = (
  victime: Victime,
): yup.Schema<ProcedureHospitalisation> =>
  yup.object({
    dateDebut: dateString()
      .typeError('À renseigner')
      .nullable()
      .minDate(
        undefined,
        "Date antérieure à la date de d'accident",
        victime.dateAccident ? new Date(victime.dateAccident) : undefined,
      )
      .maxDate(
        undefined,
        'Date postérieure à la date de décès',
        victime.dateDeces
          ? new Date(victime.dateDeces)
          : new Date(MAX_TIMESTAMP),
      )
      .required(),
    dateFin: dateString()
      .typeError('À renseigner')
      .nullable()
      .minDate('dateDebut', 'Doit être supérieur à la date de début')
      .maxDate(
        undefined,
        'Date postérieure à la date de décès',
        victime.dateDeces
          ? new Date(victime.dateDeces)
          : new Date(MAX_TIMESTAMP),
      )
      .required(),
    jours: yup.number().required().min(0),
    motifHospitalisation: yup.string().defined(),
    precisions: yup.string().defined(),
  });

export const EditDommageDialog: React.FC<Props> = ({
  procedureId,
  victime,
  dommage,
  open,
  onClose,
  onClickClose,
}) => {
  const dispatch = useAppDispatch();
  const procedure = useMemo(
    () => victime.procedures.find((procedure) => procedure._id === procedureId),
    [procedureId, victime, victime.procedures],
  );

  const initialValues = {
    intitule: dommage?.intitule || '',
    hospitalisations: [...(procedure?.hospitalisations || [])].sort((a, b) => {
      if (!a.dateDebut) {
        return 1;
      }
      if (!b.dateDebut) {
        return -1;
      }
      if (a.dateDebut < b.dateDebut) {
        return -1;
      }
      if (a.dateDebut > b.dateDebut) {
        return 1;
      }
      return 0;
    }),
  };

  const { control, handleSubmit, reset } = useForm<
    CreateUpdateDommageDTO & { hospitalisations: ProcedureHospitalisation[] }
  >({
    defaultValues: initialValues,
    mode: 'onTouched',
    resolver: yupResolver(
      yup.object({
        intitule: yup.string().optional(),
        hospitalisations: yup.array().of(
          yup.object({
            dateDebut: yup
              .string()
              .isDateString()
              .typeError('À renseigner')
              .nullable()
              .required()
              .minDate(
                undefined,
                "Date antérieure à la date de d'accident",
                victime.dateAccident
                  ? new Date(victime.dateAccident)
                  : undefined,
              )
              .maxDate(
                undefined,
                'Date postérieure à la date de décès',
                victime.dateDeces
                  ? new Date(victime.dateDeces)
                  : new Date(MAX_TIMESTAMP),
              ),
            dateFin: yup
              .string()
              .isDateString()
              .typeError('À renseigner')
              .nullable()
              .required()
              .minDate('dateDebut', 'Doit être supérieur à la date de début')
              .maxDate(
                undefined,
                'Date postérieure à la date de décès',
                victime.dateDeces
                  ? new Date(victime.dateDeces)
                  : new Date(MAX_TIMESTAMP),
              ),
            jours: yup.number().required().min(0),
            motifHospitalisation: yup.string().nullable(),
            precisions: yup.string().nullable(),
          } as Record<keyof ProcedureHospitalisation, any>),
        ),
      }),
    ),
  });

  const { prejudices } = useAppSelector((state) => ({
    prejudices: prejudiceSelectors.selectAll(state),
  }));

  useEffect(() => {
    if (procedure) {
      dispatch(fetchAllPrejudices(procedure._id));
    }
  }, []);

  const prejudiceRowsFromHospitalisations = (
    values: ProcedureHospitalisation[],
    prejudiceValues?: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  ): PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal => ({
    rows: values.map((hospitalisation) => {
      const identicalRow = prejudiceValues?.rows.find((row) => {
        return (
          row.dateDebut &&
          hospitalisation.dateDebut &&
          isEqual(
            new Date(row.dateDebut),
            new Date(hospitalisation.dateDebut),
          ) &&
          row.dateFin &&
          hospitalisation.dateFin &&
          isEqual(new Date(row.dateFin), new Date(hospitalisation.dateFin)) &&
          row.totalJours === hospitalisation.jours
        );
      });
      const forfaitJour =
        identicalRow?.forfaitJour || prejudiceValues?.forfaitJour || 0;
      const montantTotal =
        identicalRow?.montantTotal || forfaitJour * hospitalisation.jours || 0;
      return {
        dateDebut: hospitalisation.dateDebut,
        dateFin: hospitalisation.dateFin,
        forfaitJour,
        totalJours: hospitalisation.jours,
        montantTotal,
        commentaires: hospitalisation.precisions,
        numerosPieces: { rows: [] },
        motifHospitalisation: hospitalisation.motifHospitalisation,
        notes: '',
      };
    }),
    forfaitJour: prejudiceValues?.forfaitJour || 0,
    notes: prejudiceValues?.notes || '',
    chiffrage: prejudiceValues?.chiffrage || 'en_cours',
    editedFields: prejudiceValues?.editedFields || [],
  });

  useEffect(() => {
    if (open) {
      reset(initialValues);
    }
  }, [open]);

  const onSubmit = (
    values: CreateUpdateDommageDTO & {
      hospitalisations: ProcedureHospitalisation[];
    },
  ) => {
    if (dommage && procedure) {
      dispatch(
        updateDommage({
          procedureId: procedure._id,
          dommageId: dommage._id,
          data: {
            intitule: values.intitule,
          },
        }),
      );
    } else if (procedure) {
      dispatch(
        createDommage({
          procedureId: procedure._id,
          data: {
            intitule: values.intitule,
          },
        }),
      );
    }
    if (procedure) {
      dispatch(
        updateProcedure({
          procedureId: procedure._id,
          victimeId: victime._id,
          data: {
            hospitalisations: values.hospitalisations,
          },
        }),
      ).unwrap();

      const deficitList = prejudices.filter(
        (prejudice: Prejudice) =>
          prejudice.type === 'DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL',
      );

      if (deficitList.length > 0) {
        const prejudice = prejudices.filter(
          (prejudice: Prejudice) =>
            prejudice.type === 'DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL',
        )[0];
        if (prejudice) {
          dispatch(
            updatePrejudice({
              procedureId: procedure._id,
              prejudiceId: prejudice._id,
              data: {
                formData: prejudiceRowsFromHospitalisations(
                  values.hospitalisations,
                  prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
                ),
              },
            }),
          );
        }
      } else if (values.hospitalisations.length > 0) {
        dispatch(
          createPrejudice({
            procedureId: procedure._id,
            data: {
              formData: prejudiceRowsFromHospitalisations(
                values.hospitalisations,
              ),
              type: 'DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL',
              formType: prejudicesData['DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL']
                .inputType as PrejudiceFormType,
            },
          }),
        );
      }
    }

    if (onClose) {
      onClose();
    }
  };

  const {
    fields: hospitalisationsFields,
    append: appendHospitalisation,
    remove: removeHospitalisation,
  } = useFieldArray({ name: 'hospitalisations', control });

  return (
    <Dialog open={open} onClose={onClickClose} fullWidth maxWidth={'md'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ mx: 4, minWidth: '500px' }}>
          <Stack spacing={'50px'} sx={{ padding: 1 }}>
            <Stack spacing={2}>
              <Stack direction="row" justifyContent={'space-between'}>
                <div />
                <IconButton onClick={onClickClose}>
                  <CloseOutlined />
                </IconButton>
              </Stack>
              <TextFieldForm
                name="intitule"
                control={control}
                label="Note"
                variant="outlined"
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h4" component="h2" align="center">
                Périodes de déficit fonctionnel temporaire total
              </Typography>
              <Typography
                align="center"
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                  opacity: 0.45,
                }}
              >
                Si vous renseignez ici toutes ces périodes de DFTT, et si il y a
                des recouvrements entre ces périodes et celles des ATPT ou DTTP,
                elles vous seront signalées au niveau de ces 2 postes de
                préjudices.
              </Typography>
              <TableContainer>
                <NormalTable size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Début</TableCell>
                      <TableCell align="center">Fin</TableCell>
                      <TableCell align="center">Jours</TableCell>
                      <TableCell align="center">Précisions (maladie)</TableCell>
                      <TableCell align="center">
                        Motif hospitalisation
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hospitalisationsFields.map((field, index) => (
                      <TableRow key={field.id}>
                        <TableCell>
                          <ComputedDatePickerForm
                            control={control}
                            name={`hospitalisations.${index}.dateDebut`}
                            TextFieldProps={{
                              sx: {
                                minWidth: 155,
                                width: 155,
                              },
                            }}
                            {...(index === 0 && victime.dateAccident
                              ? {
                                  minDate: new Date(victime.dateAccident),
                                }
                              : index !== 0
                                ? {
                                    minDateField: `hospitalisations.${
                                      index - 1
                                    }.dateFin`,
                                  }
                                : {})}
                            maxDate={
                              victime.dateDeces
                                ? new Date(victime.dateDeces)
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <DatePickerForm
                            control={control}
                            name={`hospitalisations.${index}.dateFin`}
                            TextFieldProps={{
                              sx: {
                                minWidth: 155,
                                width: 155,
                              },
                            }}
                            minDate={
                              victime.dateAccident
                                ? new Date(victime.dateAccident)
                                : undefined
                            }
                            maxDate={
                              victime.dateDeces
                                ? new Date(victime.dateDeces)
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <ComputedTextFieldForm
                            control={control}
                            name={`hospitalisations.${index}.jours`}
                            watchFields={[
                              `hospitalisations.${index}.dateDebut`,
                              `hospitalisations.${index}.dateFin`,
                            ]}
                            InputProps={{
                              inputComponent: MaskNumber as any,
                              sx: { maxWidth: 130 },
                            }}
                            compute={(values) => {
                              const [dateDebut, dateFin] = values;

                              return dateDebut && dateFin
                                ? CalculsGlobal.getDays(dateDebut, dateFin)
                                : 0;
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextFieldForm
                            control={control}
                            name={`hospitalisations.${index}.precisions`}
                          />
                        </TableCell>
                        <TableCell>
                          <TextFieldForm
                            control={control}
                            name={`hospitalisations.${index}.motifHospitalisation`}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => removeHospitalisation(index)}
                          >
                            <DeleteRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={2} align="right">
                        Total:
                      </TableCell>
                      <TableCell align="right">
                        <ComputedPropsForm
                          control={control}
                          watchFields={['hospitalisations']}
                          compute={(values) => {
                            const hospitalisations: ProcedureHospitalisation[] =
                              values[0];

                            return {
                              props: {
                                value: CalculsGlobal.sum(
                                  hospitalisations.map((hospitalisation) =>
                                    Number(hospitalisation.jours),
                                  ),
                                ),
                              },
                            };
                          }}
                          render={({ value }) => <>{value}</>}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </NormalTable>
              </TableContainer>
              <MuiButton
                sx={{ alignSelf: 'center' }}
                onClick={() =>
                  appendHospitalisation({
                    dateDebut: '',
                    dateFin: '',
                    jours: 0,
                    precisions: '',
                    motifHospitalisation: '',
                  })
                }
              >
                Ajouter une période
              </MuiButton>
            </Stack>
            <Grid container spacing={1}>
              <Grid item xs={4} />
              <Grid item xs={4} display="flex" justifyContent="center">
                <Button
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.primary.main,
                    transform: 'scale(1)',
                    transition: '0.12s linear',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                      transform: 'scale(1.1)',
                      transition: '0.12s linear',
                    },
                    height: 'fit-content',
                    padding: '15px 25px 15px 25px',
                    alignSelf: 'center',
                  })}
                  type="submit"
                  variant="contained"
                >
                  Enregistrer
                </Button>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ opacity: 0.45 }}
                  fontWeight="bold"
                >
                  Vous pouvez maintenant accéder aux calculs
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
};
