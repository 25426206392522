import {
  MenuItem,
  Select,
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { round } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { sortBaremesCapitalisation } from 'src/helpers/bareme/sortAndOrder';
import {
  Bareme,
  BaremeCapitalisationValue,
  Gender,
  genders,
} from 'src/types/bareme.type';
interface Props {
  baremes: Bareme[];
}
type ageDernierArrerage = { age: number; isViager: boolean };

type columnPercentagePerAgeDernierArrerage = Partial<
  Record<number | 'viagere', number>
>;
type BaremeFormattedValue = {
  gender: Gender;
  rows: {
    ageDateAttribution: number;
    columns: columnPercentagePerAgeDernierArrerage;
  }[];
};
interface FormattedBaremeCapitalisation extends Bareme {
  formattedValues: BaremeFormattedValue[];
}
export const BaremeCapitalisation: React.FC<Props> = ({
  baremes: unfilteredBaremes,
}) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const baremes = useMemo(
    () =>
      sortBaremesCapitalisation(
        unfilteredBaremes.filter((bareme) => !bareme.isExtrapolated),
      ),
    [unfilteredBaremes],
  );
  const [selectedGender, setSelectedGender] = useState<Gender>(
    (baremes[tabIndex]?.values?.[0] as BaremeCapitalisationValue | undefined)
      ?.gender || 'man',
  );
  useEffect(() => {
    setSelectedGender(
      (
        baremes?.[tabIndex]?.values?.[0] as
          | BaremeCapitalisationValue
          | undefined
      )?.gender || 'man',
    );
  }, [baremes, tabIndex]);

  const sortedColumnsBaremes: FormattedBaremeCapitalisation[] = useMemo(
    () =>
      baremes.map((bareme) => ({
        ...bareme,
        formattedValues: (bareme.values as BaremeCapitalisationValue[]).map(
          (value) => ({
            ...value,
            rows: value.rows.map((row) => ({
              ...row,
              columns: row.columns.reduce(
                (
                  accumulator: columnPercentagePerAgeDernierArrerage,
                  column,
                ) => ({
                  ...accumulator,
                  ...(column.isLastArrerageViagere
                    ? { viagere: column.percentage }
                    : column.ageLastArrerage
                      ? { [column.ageLastArrerage]: column.percentage }
                      : {}),
                }),
                {},
              ),
            })),
          }),
        ),
      })),
    [baremes],
  );

  const agesDernierArrerages: ageDernierArrerage[] = useMemo(
    () =>
      sortedColumnsBaremes[tabIndex]?.formattedValues
        ?.find((value) => value.gender === selectedGender)
        ?.rows.reduce((accumulator: ageDernierArrerage[], row) => {
          (Object.keys(row.columns) as ('viagere' | number)[]).forEach(
            (column) => {
              if (
                column !== 'viagere' &&
                !accumulator.some((a) => a.age === column)
              ) {
                accumulator.push({ age: column, isViager: false });
              } else if (
                column === 'viagere' &&
                !accumulator.some((a) => a.isViager)
              ) {
                accumulator.push({ age: 0, isViager: true });
              }
            },
          );
          return accumulator;
        }, [])
        ?.sort((a, b) => {
          if (a.isViager === false && b.isViager === true) {
            return 1;
          } else if (a.isViager == true && b.isViager == false) {
            return -1;
          }
          return b.age - a.age;
        }) ?? [],
    [sortedColumnsBaremes, tabIndex, selectedGender],
  );

  const onChangeTabIndex = (
    _: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setTabIndex(value);
  };

  const getAgeDernierArrerageString = (value: ageDernierArrerage) => {
    if (value.age) {
      return `${value.age} ans`;
    } else if (value.isViager) {
      return `Viagere`;
    }
  };

  const getAgeDateAttributionString = (value: number) => `${value} ans`;

  // TODO: Replace Table with DataGrid
  return (
    <>
      {sortedColumnsBaremes.length > 1 && (
        <Tabs value={tabIndex} onChange={onChangeTabIndex} variant="scrollable">
          {sortedColumnsBaremes.map((bareme, index) => (
            <Tab
              key={index}
              label={getCapitalisationBaremeSourceLabel(bareme)}
              value={index}
              sx={{ textTransform: 'none' }}
            />
          ))}
        </Tabs>
      )}
      {sortedColumnsBaremes.map((bareme, index) => (
        <Stack
          key={index}
          sx={{
            display: tabIndex === index ? '' : 'none',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          spacing={2}
        >
          {bareme.source === 'CJ' ? (
            <Typography
              component="a"
              href={bareme.sourceComment}
              target="_blank"
              margin={2}
            >
              {bareme.sourceComment}
            </Typography>
          ) : (
            <>
              {Boolean(
                bareme.formattedValues.some(
                  (value) =>
                    value.gender !== undefined && value.gender !== 'all',
                ),
              ) && (
                <Select
                  value={selectedGender}
                  onChange={(event) =>
                    setSelectedGender(event.target.value as 'man' | 'woman')
                  }
                  sx={{
                    marginTop: 1,
                    marginLeft: 1,
                    width: '150px',
                  }}
                >
                  {genders
                    .filter(
                      (gender) => gender !== 'all' && gender !== 'undetermined',
                    )
                    .map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {t(`bareme.genders.${value}`)}
                      </MenuItem>
                    ))}
                </Select>
              )}

              {bareme.formattedValues.map(({ gender, rows }, index) => (
                <TableContainer
                  key={index}
                  sx={{
                    display: gender !== selectedGender ? 'none' : undefined,
                  }}
                >
                  <NormalTable stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          variant="head"
                          sx={{
                            minWidth: '100px',
                            position: 'sticky',
                            left: 0,
                            zIndex: 3,
                          }}
                        />
                        {agesDernierArrerages?.map((age, index) => (
                          <TableCell key={index} sx={{ minWidth: '100px' }}>
                            {getAgeDernierArrerageString(age)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.map(({ ageDateAttribution, columns }, index) => (
                        <TableRow key={index}>
                          <TableCell
                            variant="head"
                            sx={{
                              minWidth: '100px',
                              position: 'sticky',
                              left: 0,
                            }}
                          >
                            {getAgeDateAttributionString(
                              Number(ageDateAttribution),
                            )}
                          </TableCell>
                          {agesDernierArrerages.map(
                            ({ age, isViager }, index) => {
                              const percentage = isViager
                                ? columns.viagere
                                : columns[age];
                              return (
                                <TableCell key={index}>
                                  {percentage && round(percentage, 3)}
                                </TableCell>
                              );
                            },
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </NormalTable>
                </TableContainer>
              ))}
            </>
          )}
        </Stack>
      ))}
    </>
  );
};
