import React from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  renderInput?: AutocompleteRenderInputParams;
  TextFieldProps?: TextFieldProps;
  InputProps?: TextFieldProps['InputProps'];
}

export const AutocompleteForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  TextFieldProps,
  InputProps,
  ...props
}: Props<TFieldValues, TName> &
  Omit<
    AutocompleteProps<string, boolean, boolean, boolean>,
    'renderInput'
  >) => {
  const {
    field: { onChange, ...fieldParameter },
    fieldState: { error },
  } = useController({ name, control, rules, shouldUnregister, defaultValue });

  return (
    <Autocomplete
      {...props}
      {...fieldParameter}
      onChange={(_, value) => {
        onChange(value);
      }}
      autoSelect
      disablePortal
      renderInput={(params) => {
        return (
          <Tooltip title={error?.message || ''}>
            <TextField
              {...TextFieldProps}
              {...(params as any)}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
              error={!!error}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  padding: '0px',
                  paddingLeft: '9px',
                  paddingY: '9px',
                },
                '& .MuiAutocomplete-input': {
                  padding: '0px',
                  paddingLeft: '9px',
                  paddingY: '9px',
                },
              }}
            />
          </Tooltip>
        );
      }}
    />
  );
};
