/* Utilities to format specific type of values, like coefficients de capitalisation, etc. */

import { Unite } from 'src/types/prejudice.type';
import { fCurrency, fDecimalNumber, fPercent } from './formatNumber';
import i18next from 'i18next';

export const fCoefficientCapitalisation = (value: number) => {
  return fDecimalNumber(value, 3);
};

export const fPartResponsabilite = (value: number) => {
  return fPercent(value, 2);
};

export const fCurrencyUnite = (value: number, unite: Unite) => {
  return i18next.t('prejudice.fields.unite.print.value', {
    unite,
    value: fCurrency(value),
  });
};
