import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import { UpdateSubscriptionDto } from 'src/types/subscription.type';

import {
  apiRequest,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '../helpers/api';
import {
  CompleteGoogleSignUpDto,
  SendHelpRequestDto,
  SignUpDto,
  UpdateUserDto,
  User,
} from '../types/auth.type';

interface Tokens {
  accessToken: string;
  refreshToken?: string;
}

interface AuthState {
  user: User | null;
  users: User[];
  isLogged: boolean;
  isLoading: boolean;
  isInitialLoading: boolean;
  error: string | null;
  askForHelp: {
    isLoading: boolean;
    error: string | null;
  };
}

const initialState: AuthState = {
  isLogged: false,
  isLoading: false,
  isInitialLoading: true,
  user: null,
  users: [],
  error: null,
  askForHelp: {
    isLoading: false,
    error: null,
  },
};

const setAccessRefreshTokenAndGetUser = (
  accessToken: string,
  refreshToken?: string,
) => {
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
  if (refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
  }

  return apiRequest<User>('GET', '/auth/user');
};

/* Thunks */

export const fetchCurrentUser = createAsyncThunk(
  'auth/fetchCurrentUser',
  async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return null;
    }

    try {
      return await apiRequest<User>('GET', '/auth/user');
    } catch (error) {
      return null;
    }
  },
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (updateUserDto: UpdateUserDto) => {
    return await apiRequest<User>('PUT', '/auth/user', undefined, {
      ...updateUserDto,
    });
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (payload: { email: string; password: string }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      '/auth/signIn',
      undefined,
      payload,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (payload: { dto: SignUpDto; invitationToken?: string }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/auth/signUp',
      payload.invitationToken
        ? {
            invitationToken: payload.invitationToken,
          }
        : undefined,
      payload.dto,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const signInUpGoogle = createAsyncThunk(
  'auth/signInUpGoogle',
  async (payload: { googleAccessToken: string; invitationToken?: string }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/auth/google',
      payload.invitationToken
        ? { invitationToken: payload.invitationToken }
        : undefined,
      // eslint-disable-next-line camelcase
      { access_token: payload.googleAccessToken },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const sendLostPassword = createAsyncThunk(
  'auth/sendLostPassword',
  async (payload: { email: string }) => {
    await apiRequest<{ status: boolean }>(
      'POST',
      '/auth/lostPassword',
      undefined,
      payload,
    );
  },
);

export const useLostPasswordToken = createAsyncThunk(
  'auth/useLostPasswordToken',
  async (payload: { token: string; password: string }) => {
    const { token, password } = payload;
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      `/auth/lostPassword/${encodeURIComponent(token)}`,
      undefined,
      { password },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const deleteOwnAccount = createAsyncThunk(
  'auth/deleteOwnAccount',
  async () => await apiRequest<User>('DELETE', '/auth/user'),
);

/** Admin thunks **/

export const adminGetAllUsers = createAsyncThunk(
  'auth/admin/getAllUsers',
  async () => {
    return await apiRequest<User[]>('GET', '/auth/admin/users');
  },
);

export const updateUserRole = createAsyncThunk(
  'auth/admin/updateUserRole',
  async ({ userId, isAdmin }: { userId: string; isAdmin: boolean }) => {
    return await apiRequest<User>(
      'PUT',
      `/auth/admin/${userId}/role`,
      undefined,
      {
        isAdmin,
      },
    );
  },
);

export const deleteAccount = createAsyncThunk(
  'auth/admin/deleteAccount',
  async (userId: string) => {
    return await apiRequest<User>('DELETE', `/auth/admin/${userId}`);
  },
);

export const activateAccount = createAsyncThunk(
  'auth/admin/activateAccount',
  async (userId: string) => {
    return await apiRequest<User>('PUT', `/auth/admin/${userId}`, undefined, {
      disabled: false,
    });
  },
);

export const deactivateAccount = createAsyncThunk(
  'auth/admin/deactivateAccount',
  async (userId: string) => {
    return await apiRequest<User>('PUT', `/auth/admin/${userId}`, undefined, {
      disabled: true,
    });
  },
);

export const updateUserCompanyRole = createAsyncThunk(
  'auth/admin/updateCompanyRole',
  async ({
    userId,
    company,
    role,
    jobType,
  }: {
    userId: string;
    company: string;
    role?: string;
    jobType?: string;
  }) => {
    return await apiRequest<User>('PUT', `/auth/admin/${userId}`, undefined, {
      company,
      role,
      jobType,
    });
  },
);

export const updateUserSubscription = createAsyncThunk(
  'auth/admin/updateSubscription',
  async ({
    userId,
    updateSubscriptionDto,
  }: {
    userId: string;
    updateSubscriptionDto: UpdateSubscriptionDto;
  }) => {
    return await apiRequest<User>(
      'PATCH',
      `/auth/admin/${userId}/subscription`,
      undefined,
      updateSubscriptionDto,
    );
  },
);

export const completeGoogleSignUp = createAsyncThunk(
  'auth/completeGoogleSignUp',
  async (payload: CompleteGoogleSignUpDto) => {
    return apiRequest<User>(
      'PUT',
      '/auth/complete-google-signup',
      undefined,
      payload,
    );
  },
);

export const sendHelpRequest = createAsyncThunk(
  'auth/sendHelpRequest',
  async ({
    dto,
    organizationId,
  }: {
    dto: SendHelpRequestDto;
    organizationId?: string;
  }) => {
    try {
      if (organizationId) {
        await apiRequest<void>(
          'POST',
          `/organization/${organizationId}/help`,
          undefined,
          dto,
        );
      } else {
        await apiRequest<void>('POST', '/auth/help', undefined, dto);
      }
    } catch (error) {
      console.log(error);
    }
  },
);

/* Shared reducers */
const signInUpPendingReducer = (state: AuthState) => {
  state.isLogged = false;
  state.isLoading = true;
  state.error = null;
  state.user = null;
};

const signInUpFulfilledReducer = (
  state: AuthState,
  { payload }: { payload: User },
) => {
  state.isLogged = true;
  state.isLoading = false;
  state.user = payload;
};

const pendingReducer = (state: AuthState) => {
  state.isLoading = true;
  state.error = null;
};

const fulfilledReducer = (state: AuthState) => {
  state.isLoading = false;
};

const rejectedReducer = (
  state: AuthState,
  { error }: { error: SerializedError },
) => {
  state.isLoading = false;
  state.error = error.message || 'error';
};

const userUpdatedReducer = (
  state: AuthState,
  { payload }: { payload: User },
) => {
  state.isLoading = false;
  state.users = state.users.map((user) =>
    user._id !== payload._id ? user : payload,
  );
  if (state.user?._id && state.user._id === payload._id) {
    state.user = payload;
  }
};

const updateAuthenticatedUserReducer = (
  state: AuthState,
  { payload }: { payload: User },
) => {
  state.isLoading = false;
  state.user = payload;
};

/* Slice */

const authSlice = createSlice({
  name: 'auth',
  reducers: {
    logout(state: AuthState) {
      state.user = null;
      state.isLogged = false;
      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    },
    resetError(state: AuthState) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state: AuthState) => {
        state.isInitialLoading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state: AuthState, { payload }) => {
        state.isInitialLoading = false;
        state.user = payload;
        state.isLogged = !!payload;
      })
      .addCase(fetchCurrentUser.rejected, (state: AuthState, { error }) => {
        state.isInitialLoading = false;
        state.error = error.message || 'error';
      })
      .addCase(signIn.pending, signInUpPendingReducer)
      .addCase(signIn.fulfilled, signInUpFulfilledReducer)
      .addCase(signIn.rejected, rejectedReducer)
      .addCase(signUp.pending, signInUpPendingReducer)
      .addCase(signUp.fulfilled, signInUpFulfilledReducer)
      .addCase(signUp.rejected, rejectedReducer)
      .addCase(signInUpGoogle.pending, signInUpPendingReducer)
      .addCase(signInUpGoogle.fulfilled, signInUpFulfilledReducer)
      .addCase(signInUpGoogle.rejected, rejectedReducer)
      .addCase(sendLostPassword.pending, pendingReducer)
      .addCase(sendLostPassword.fulfilled, fulfilledReducer)
      .addCase(sendLostPassword.rejected, rejectedReducer)
      .addCase(useLostPasswordToken.pending, pendingReducer)
      .addCase(useLostPasswordToken.fulfilled, signInUpFulfilledReducer)
      .addCase(useLostPasswordToken.rejected, rejectedReducer)
      .addCase(adminGetAllUsers.fulfilled, (state: AuthState, { payload }) => {
        state.users = payload;
      })
      .addCase(deleteAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.filter((user) => user._id !== payload._id);
      })
      .addCase(updateUserRole.pending, pendingReducer)
      .addCase(updateUserRole.rejected, rejectedReducer)
      .addCase(updateUserRole.fulfilled, userUpdatedReducer)
      .addCase(activateAccount.pending, pendingReducer)
      .addCase(activateAccount.rejected, rejectedReducer)
      .addCase(activateAccount.fulfilled, userUpdatedReducer)
      .addCase(deactivateAccount.pending, pendingReducer)
      .addCase(deactivateAccount.rejected, rejectedReducer)
      .addCase(deactivateAccount.fulfilled, userUpdatedReducer)
      .addCase(updateUserCompanyRole.pending, pendingReducer)
      .addCase(updateUserCompanyRole.rejected, rejectedReducer)
      .addCase(updateUserCompanyRole.fulfilled, userUpdatedReducer)
      .addCase(updateUserSubscription.pending, pendingReducer)
      .addCase(updateUserSubscription.rejected, rejectedReducer)
      .addCase(updateUserSubscription.fulfilled, userUpdatedReducer)
      .addCase(updateUser.pending, pendingReducer)
      .addCase(updateUser.rejected, rejectedReducer)
      .addCase(updateUser.fulfilled, updateAuthenticatedUserReducer)
      .addCase(completeGoogleSignUp.pending, pendingReducer)
      .addCase(completeGoogleSignUp.rejected, rejectedReducer)
      .addCase(completeGoogleSignUp.fulfilled, updateAuthenticatedUserReducer)
      .addCase(sendHelpRequest.pending, pendingReducer)
      .addCase(sendHelpRequest.rejected, rejectedReducer)
      .addCase(deleteOwnAccount.pending, pendingReducer)
      .addCase(deleteOwnAccount.rejected, rejectedReducer);
  },
  initialState,
});

export const { logout, resetError } = authSlice.actions;

export default authSlice.reducer;
