import { LocaleObject } from 'yup';

export const mixed: LocaleObject['mixed'] = {
  default: 'Champ est invalide.',
  required: 'Ce champ est obligatoire',
  defined: 'Ce champ ne doit pas être laissé vide',
  oneOf: "Ce champ doit être l'une des valeurs suivantes : ${values}",
  notOneOf: "Ce champ ne doit pas être l'une des valeurs suivantes : ${values}",
  notType: ({ type }) => {
    // const isCast = originalValue != null && originalValue !== value;
    // let msg =
    //   `${path} doit être un type \`${type}\`, ` +
    //   `mais la valeur finale était: \`${printValue(value, true)}\`` +
    //   (isCast
    //     ? ` (coulée de la valeur \`${printValue(originalValue, true)}\`).`
    //     : '.');
    //
    // if (value === null) {
    //   msg +=
    //     `\n Si « null » est conçue comme une valeur vide assurez-vous de marquer le schéma comme` +
    //     ' `.nullable()`';
    // }
    // return msg;
    if (type === 'number') {
      return 'Vous devez saisir un nombre valide';
    } else {
      return 'Erreur de type';
    }
  },
};
// TODO: Remove the &{oneOf...} when yup is fixes the type
export const string: LocaleObject['string'] & {
  oneOf: string;
} = {
  length: 'Ce champ doit être exactement ${length} caractères',
  min: 'Ce champ doit être au moins ${min} caractères',
  max: 'Ce champ doit être au plus ${max} caractères',
  matches: 'Ce champ doit correspondre à ce qui suit : "${regex}"',
  email: 'Ce champ doit être un email valide',
  url: 'Ce champ doit être une URL valide',
  trim: 'Ce champ doit être une chaîne garnie',
  lowercase: 'Ce champ doit être une chaîne en minuscule',
  uppercase: 'Ce champ doit être une chaîne de majuscules',
  oneOf: "Ce champ doit être l'une des valeurs suivantes : ${values}",
};

export const number: LocaleObject['number'] = {
  min: 'Ce champ doit être supérieure ou égale à ${min}',
  max: 'Ce champ doit être inférieur ou égal à ${max}',
  lessThan: 'Ce champ doit être inférieure à ${less}',
  moreThan: 'Ce champ doit être supérieure à ${more}',
  positive: 'Ce champ doit être un nombre positif',
  negative: 'Ce champ doit être un nombre négatif',
  integer: 'Ce champ doit être un entier',
};

export const date: LocaleObject['date'] = {
  min: 'Ce champ doit être au plus tard ${min}',
  max: 'Ce champ doit être au plus tôt ${max}',
};

export const boolean: LocaleObject['boolean'] = {};

export const object: LocaleObject['object'] = {
  noUnknown:
    "Ce champ ne peut pas avoir des clés non spécifiées dans la forme de l'objet",
};

export const array: LocaleObject['array'] = {
  min: 'Ce champ doit avoir au moins ${min} élément(s)',
  max: 'Ce champ ne doit pas contenir plus de ${max} élément(s)',
};

export default {
  mixed,
  string,
  number,
  date,
  boolean,
  object,
  array,
};
