import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
} from 'src/types/prejudice.type';
import { revenuAnnuelDeReferencePrint } from './revenuAnnuelDeReferencePrint';
import { tableIndemnitesJournalieresPercuesPendantLaPeriodeDArretPrint } from './tableIndemnitesJournalieresPercuesPendantLaPeriodeDArretPrint';
import { tablePerteDeGainProfessionnelPrint } from './tablePerteDeGainProfessionnelPrint';

export const stepsPrint = ({
  formData,
  monetaryErosions,
  dateLiquidation,
  isPGPF,
}: {
  formData:
    | NewPrejudiceFormPerteGainProfessionnelsActuel
    | NewPrejudiceFormPerteGainProfessionnelsFuturs;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation: Date | null;
  isPGPF: boolean;
}): (Table | Paragraph | TableOfContents)[] => {
  return [
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.stepper.print.REVENU_ACTIVITE_ANNUEL_DE_REFERENCE.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...revenuAnnuelDeReferencePrint({
      rows: formData.revenuActiviteAnnuelDeReference.rows,
      revenuAnnuelDeReference: formData.revenuActiviteAnnuelDeReference.total,
      monetaryErosions,
      dateLiquidation: dateLiquidation,
      perteDeChanceDeGainProfessionnel:
        formData.perteDeChanceDeGainProfessionnel,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    }),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.stepper.print.PERTE_DE_GAIN_PROFESSIONNEL.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...tablePerteDeGainProfessionnelPrint({
      rows: formData.perteDeGainsProfessionnels.rows,
      total: formData.perteDeGainsProfessionnels.total,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      monetaryErosions,
      dateLiquidation: dateLiquidation,
      formType: 'REVENUS',
    }),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.stepper.print.INDEMNITES_JOURNALIERES_PERCUES_PGPF.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...tableIndemnitesJournalieresPercuesPendantLaPeriodeDArretPrint({
      rows: formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
      totalIndemnitesPercuesNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .indemnitesJournalieresPercuesNet,
      totalIndemniteCsgCrdsNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .csgRdsTotal,
      isPGPF,
    }),
  ];
};
