import { NewPrejudiceFormPerteGainProfessionnelsActuel } from 'src/types/prejudice.type';
import { fCurrency } from 'src/helpers/formatNumber';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import i18next from 'i18next';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { Paragraph, Table, TableOfContents } from 'docx';
import { getTropPercuString } from '../../../../prejudices/pgpfReliquat';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const tableRecapitulatifPrint = (
  {
    perteDeGainsProfessionnels,
    indemnitesJournalieresPercuesPendantLaPeriodeDArret,
    total,
  }: Pick<
    NewPrejudiceFormPerteGainProfessionnelsActuel,
    | 'perteDeGainsProfessionnels'
    | 'indemnitesJournalieresPercuesPendantLaPeriodeDArret'
    | 'total'
  >,
  partResponsabilite: number,
  isPGPF?: boolean,
  displayTitle = true,
): (Paragraph | Table | TableOfContents)[] => [
  ...(displayTitle
    ? [
        getParagraph({
          children: getTextRun({
            text: i18next.t(
              'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.print.title',
            ),
            bold: true,
          }),
        }),
        getEmptyLine(),
      ]
    : []),
  simpleHorizontalTablePrint(
    [
      '',
      i18next.t(
        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.perteDeGainsProfessionnelsTotal.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.indemnitesJournalieresPercuesNet.columnHeader',
      ),
      ...(!isPGPF
        ? [
            i18next.t(
              'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.csgCrds.columnHeader',
            ),
          ]
        : []),
      i18next.t(
        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.total.columnHeader',
      ),
    ],
    [
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.totalRow.rowHeader',
        ),
        columns: [
          fCurrency(perteDeGainsProfessionnels.total),
          fCurrency(
            indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet ||
              0,
          ),
          ...(!isPGPF
            ? [
                indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal ||
                indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal ===
                  0
                  ? fCurrency(
                      indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal,
                    )
                  : '',
              ]
            : []),
          getTropPercuString(total),
        ],
      },
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.print.partResponsabiliteRow.rowHeader',
          {
            partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
          },
        ),
        columns: [
          perteDeGainsProfessionnels.total,
          null,
          ...(!isPGPF
            ? [indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal]
            : []),
          null,
        ].map((value) =>
          value || value === 0 ? fCurrency(value * partResponsabilite) : '',
        ),
      },
    ],
  ),
];
