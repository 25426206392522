import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { CalculsFormCalendrierDeficitFonctionnelTemporairePartiel } from 'src/constants/calculs';
import { prejudicesData } from 'src/constants/prejudices';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import {
  Prejudice,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { getPeriodePrint } from '../periodePrint';
import { prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { sortBy } from 'lodash';

export const deficitFonctionnelTemporairePartielPrint = ({
  procedure,
  prejudice,
  formData,
}: {
  procedure: Procedure;
  prejudice: Pick<Prejudice, 'formData' | 'formType' | 'type'>;
  formData: PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel;
}): (Paragraph | Table | TableOfContents)[] => {
  const table = prejudiceFormTable(
    sortBy(formData.rows, (row) => row.dateDebut),
    [
      {
        name: 'numerosPieces',
        headerLabel: i18next.t('numeroPiece.form.columnHeader'),
        render: (row) =>
          row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
      },
      {
        name: 'commentaires',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.commentaires.columnHeader',
        ),
      },
      {
        name: 'dateDebut',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.dateDebut.print.periodeColumnHeader',
        ),
        render: (row) =>
          getPeriodePrint({
            dateDebut: row.dateDebut,
            dateFin: row.dateFin,
          }),
      },
      {
        name: 'joursPeriode',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.joursPeriode.columnHeader',
        ),
      },
      {
        name: 'joursHospitalisationADeduire',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.joursHospitalisationADeduire.columnHeader',
        ),
        render: (row) =>
          row.deduireJoursHospitalisation &&
          !row.commentaires &&
          row.joursHospitalisationADeduire
            ? row.joursHospitalisationADeduire.toString()
            : '',
      },
      {
        name: 'totalJours',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.totalJours.columnHeader',
        ),
      },
      {
        name: 'totalJours',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.totalJours.print.columnHeader',
        ),
      },
      {
        name: 'percentage',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.percentage.print.columnHeader',
        ),
        render: (row) =>
          !row.commentaires && row.percentage ? row.percentage.toString() : '',
      },
      {
        name: 'forfaitJour',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.forfaitJour.print.columnHeader',
        ),
        render: (row) =>
          !row.commentaires && row.forfaitJour
            ? fCurrency(row.forfaitJour)
            : '',
      },
      {
        name: 'montantTotal',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.fields.rows.montantTotal.columnHeader',
        ),
        render: (row) => fCurrency(row.montantTotal),
      },
    ],
  );
  const total =
    formData.total || formData.total === 0
      ? formData.total
      : CalculsFormCalendrierDeficitFonctionnelTemporairePartiel.total(
          formData.rows,
        );
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const displayTotalPartResponsabilite =
    prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
      .displayTotalPartResponsabilite;
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...(table ? table : []),
    getParagraph({
      children: getTextRun({
        text: displayTotalPartResponsabilite
          ? i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.total.print.rowPartResponsabilite',
              {
                value: fCurrency(total * procedure.partResponsabilite),
                partResponsabilite: fPartResponsabilite(
                  procedure.partResponsabilite * 100,
                ),
              },
            )
          : i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL.total.print.row',
              {
                total: fCurrency(total),
              },
            ),
        bold: true,
        break: 1,
      }),
    }),
  ];
};
