import React from 'react';
import { Control } from 'react-hook-form';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import { CalculsFormCalendrier } from '../../../constants/calculs';
import {
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
} from '../../../types/prejudice.type';
import { TotalReparti } from './TotalReparti';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';

interface Props {
  control:
    | Control<PrejudiceFormCalendrierDepense>
    | Control<PrejudiceFormCalendrierDepenseCapitalisation>;
  tiersPayeurs: string[];
  monetaryErosions: MonetaryErosion[];
  partResponsabilite: number;
  dateLiquidation: Date | undefined;
}

export const TotalDepensesEchues: React.FC<Props> = ({
  control,
  monetaryErosions,
  partResponsabilite,
  dateLiquidation,
  tiersPayeurs,
}) => {
  return (
    <ComputedPropsForm
      control={control as Control<PrejudiceFormCalendrierDepense>}
      watchFields={['rows', 'revalorisationCoefficientsType']}
      compute={([rows, revalorisationCoefficientsType]): {
        props: {
          totalResteACharge: number;
          priseEnChargeTiersPayeurs: Record<string, number>;
        };
      } => {
        const totaux = CalculsFormCalendrier.totauxDepenses({
          rows,
          tiersPayeurs,
          dateLiquidation,
          monetaryErosions,
          isCalculCapitalisation: false,
          revalorisationCoefficientsType,
        });

        return {
          props: {
            totalResteACharge: totaux.resteACharge,
            priseEnChargeTiersPayeurs: totaux.priseEnChargeTiersPayeurs,
          },
        };
      }}
      render={({ totalResteACharge, priseEnChargeTiersPayeurs }) => (
        <TotalReparti
          totalResteACharge={totalResteACharge}
          priseEnChargeTiersPayeurs={priseEnChargeTiersPayeurs}
          partResponsabilite={partResponsabilite}
        />
      )}
    />
  );
};
