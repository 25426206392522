import React from 'react';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import {
  useDisplayedOrganization,
  useOrganizations,
} from 'src/hooks/store/organization.hooks';
import { useNavigate } from 'react-router-dom';

export const OrganizationsSelector: React.FC = () => {
  const navigate = useNavigate();
  const { organizations } = useOrganizations();
  const { organization, setDisplayedOrganization } = useDisplayedOrganization();
  const onChange = (organizationId: string) => {
    setDisplayedOrganization(organizationId);
  };

  const onClickCreateOrganization = () => {
    navigate('/create-organization');
  };

  if (organizations.length === 0 || !organization) {
    return <></>;
  }
  return (
    <Select
      value={organization?._id || ''}
      onChange={(e) => {
        if (!e.target.value) {
          return;
        }
        onChange(e.target.value as string);
      }}
      sx={{ backgroundColor: 'white', paddingY: 0 }}
      inputProps={{
        sx: {
          paddingY: 1,
          minWidth: 100,
        },
      }}
    >
      {organizations.map((organization) => (
        <MenuItem key={organization._id} value={organization._id}>
          {organization.name}
        </MenuItem>
      ))}
      <MenuItem
        key={organizations.length}
        onClick={onClickCreateOrganization}
        color="primary"
      >
        <Typography color="primary">Créer une organisation</Typography>
      </MenuItem>
    </Select>
  );
};
