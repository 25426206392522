import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './fr.json';

const userLang = (navigator.language || 'fr').substring(0, 2);
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  resources: {
    fr: {
      translation: fr,
    },
  },
  debug: process.env.NODE_ENV === 'development',
  lng: userLang,
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18n;
