import i18next from 'i18next';

export const displayDernierArrerage = (
  ageDerniersArrerages: number | null | undefined,
  isLastArrerageViager: boolean | undefined,
): string => {
  if (isLastArrerageViager) {
    return i18next.t(
      'prejudice.capitalisation.form.fields.isLastArrerageViager.print.value',
    );
  } else if (ageDerniersArrerages) {
    return `${ageDerniersArrerages} ${i18next.t(
      'prejudice.capitalisation.form.fields.ageDernierArrerage.age',
      {
        age: ageDerniersArrerages,
      },
    )}`;
  }
  return '';
};
