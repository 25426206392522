import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { Balance } from '@mui/icons-material';

interface Props {
  onClick: () => void;
}

export const DintilhacButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('prejudice.fields.dintilhac.buttonText')}>
      <IconButton
        onClick={onClick}
        color="primary"
        sx={{
          borderRadius: '50%',
          border: 'primary.main',
          borderStyle: 'solid',
          borderWidth: 2,
          padding: '4px',
        }}
      >
        <Balance />
      </IconButton>
    </Tooltip>
  );
};
