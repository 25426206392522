import { format } from 'date-fns';
import { monetaryErosionsTypeOrder } from 'src/constants/monetaryErosion';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { fr } from 'date-fns/locale/fr';
import { capitalize } from 'lodash';

const getMonetaryErosionRank = (monetaryErosion: MonetaryErosion) =>
  monetaryErosionsTypeOrder[monetaryErosion.coefficientsType];

export const sortMonetaryErosions = (monetaryErosions: MonetaryErosion[]) =>
  monetaryErosions.sort(
    (a, b) => getMonetaryErosionRank(a) - getMonetaryErosionRank(b),
  );

export const getCoefficientsMensuelsTableRowHeader = (
  year: number,
  month: number,
) => capitalize(format(new Date(year, month), 'MMMM yyyy', { locale: fr }));
