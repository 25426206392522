import { Paragraph, Table, TableOfContents } from 'docx';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { getEmptyLine, getParagraph } from '../../../docxFunctions';
import i18next from 'i18next';
import { Victime } from 'src/types/victime.type';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { fAge, fDate, fYears } from 'src/helpers/formatTime';
import { Procedure } from 'src/types/procedure.type';
import { CalculsGlobal } from 'src/constants/calculs';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { simpleHorizontalTablePrint } from '../../../simpleHorizontalTablePrint';
import { fCoefficientCapitalisation } from 'src/helpers/formatValues';
import { displayFormula } from '../../formula';

export const enfantsWrittenPrint = ({
  formData,
  victime,
  procedure,
  victimesIndirectes,
}: {
  formData: PrejudiceFormPerteRevenusProche;
  victime: Victime;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] => {
  const entreDecesEtLiquidationTable = simpleHorizontalTablePrint(
    [
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.ageDeces.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.partPerteAnnuelle.columnHeader',
      ),

      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.dureeIndemnisation.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.prejudiceEntreDecesEtLiquidation.columnHeader',
      ),
    ],

    [
      ...formData.enfants.rows.reduce(
        (accumulator, row) => {
          const victimeIndirecte = victimesIndirectes.find(
            (victimeIndirecte) =>
              victimeIndirecte._id === row.victimeIndirecteId,
          );
          if (victimeIndirecte && victime.dateDeces) {
            const ageDeces = CalculsGlobal.getAgeOrZero(
              new Date(victimeIndirecte.dateNaissance),
              new Date(victime.dateDeces),
            );
            accumulator.push({
              rowLabel: `${victimeIndirecte?.prenom} ${victimeIndirecte?.nom}`,
              columns: [
                fAge(ageDeces),
                fCurrency(row.partPerteAnnuelle),
                fYears(row.dureeIndemnisation),
                fCurrency(row.prejudiceEntreDecesEtLiquidation),
              ],
            });
          }
          return accumulator;
        },
        [] as Parameters<typeof simpleHorizontalTablePrint>[1],
      ),
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.totalPrejudiceEntreDecesEtLiquidation.writtenPrint.columnHeader',
        ),
        columns: [
          '',
          '',
          '',
          fCurrency(
            CalculsGlobal.sum(
              formData.enfants.rows.map(
                (row) => row.prejudiceEntreDecesEtLiquidation,
              ),
            ),
          ),
        ],
      },
    ],
  );
  const apresLiquidationTable = simpleHorizontalTablePrint(
    [
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.ageLiquidation.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.partPerteAnnuelle.columnHeader',
      ),

      i18next.t(
        'prejudice.capitalisation.form.fields.ageDernierArrerage.title',
      ),
      i18next.t('prejudice.capitalisation.form.fields.coefficient.otherTitle'),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.montantCapitalise.writtenPrint.columnHeader',
      ),
    ],
    [
      ...formData.enfants.rows.reduce(
        (accumulator, row) => {
          const victimeIndirecte = victimesIndirectes.find(
            (victimeIndirecte) =>
              victimeIndirecte._id === row.victimeIndirecteId,
          );
          if (victimeIndirecte && procedure.dateLiquidation) {
            const ageLiquidation = CalculsGlobal.getAgeOrZero(
              new Date(victimeIndirecte.dateNaissance),
              new Date(procedure.dateLiquidation),
            );
            accumulator.push({
              rowLabel: `${victimeIndirecte?.prenom} ${victimeIndirecte?.nom}`,
              columns: [
                fAge(ageLiquidation),
                fCurrency(row.partPerteAnnuelle),
                fAge(row.ageFinSoutienFinancier || 0),
                row.coefficientCapitalisation
                  ? fCoefficientCapitalisation(row.coefficientCapitalisation)
                  : '',
                row.montantCapitalise ? fCurrency(row.montantCapitalise) : '',
              ],
            });
          }
          return accumulator;
        },
        [] as Parameters<typeof simpleHorizontalTablePrint>[1],
      ),
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.totalMontantCapitalise.writtenPrint.tableRowHeader',
        ),
        columns: [
          '',
          '',
          '',
          '',
          fCurrency(
            CalculsGlobal.sum(
              formData.enfants.rows.map((row) => row.montantCapitalise || 0),
            ),
          ),
        ],
      },
    ],
  );
  const capitauxDecesTable = simpleHorizontalTablePrint(
    [
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.rows.total.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.capitauxDeces.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.totalApresCapitauxDeces.columnHeader',
      ),
    ],
    [
      ...formData.enfants.rows.reduce(
        (accumulator, row) => {
          const victimeIndirecte = victimesIndirectes.find(
            (victimeIndirecte) =>
              victimeIndirecte._id === row.victimeIndirecteId,
          );
          if (victimeIndirecte) {
            accumulator.push({
              rowLabel: `${victimeIndirecte?.prenom} ${victimeIndirecte?.nom}`,
              columns: [
                fCurrency(row.total),
                fCurrency(row.capitauxDeces.total),
                fCurrency(row.totalApresCapitauxDeces),
              ],
            });
          }
          return accumulator;
        },
        [] as Parameters<typeof simpleHorizontalTablePrint>[1],
      ),
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.totalApresCapitauxDeces.writtenPrint.tableRowHeader',
        ),
        columns: [
          fCurrency(formData.enfants.total),
          fCurrency(formData.enfants.totalCapitauxDeces),
          fCurrency(formData.enfants.totalApresCapitauxDeces),
        ],
      },
    ],
  );
  const totalEntreDecesEtLiquidation = CalculsGlobal.sum(
    formData.enfants.rows.map(
      (row) => row.prejudiceEntreDecesEtLiquidation || 0,
    ),
  );
  const totalMontantCapitalise = CalculsGlobal.sum(
    formData.enfants.rows.map((row) => row.montantCapitalise || 0),
  );
  return [
    getParagraph({
      numbering: {
        level: 0,
        reference: 'numbers-dot-simple',
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.title',
      ),
    }),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.partTotale.firstPart',
        {
          partTotale: fPercent(formData.enfants.partTotale),
        },
      ),
    ),
    ...formData.enfants.rows.reduce((accumulator, row) => {
      const victimeIndirecte = victimesIndirectes.find(
        (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
      );
      if (victimeIndirecte) {
        accumulator.push(
          getParagraph({
            numbering: {
              reference: 'simple-list',
              level: 0,
            },
            text: i18next.t(
              'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.partTotale.row',
              {
                nom: victimeIndirecte?.nom,
                prenom: victimeIndirecte?.prenom,
                ageFinSoutienFinancier: fAge(row.ageFinSoutienFinancier || 0),
                partIndividuelle: fPercent(row.partIndividuelle || 0),
              },
            ),
          }),
        );
      }
      return accumulator;
    }, [] as Paragraph[]),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 1,
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.entreDecesEtLiquidation.text',
      ),
    }),
    getParagraph({
      numbering: {
        level: 0,
        reference: 'lowercase-letters-parenthesis',
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.entreDecesEtLiquidation.textA',
      ),
    }),
    getParagraph({
      numbering: {
        level: 0,
        reference: 'lowercase-letters-parenthesis',
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.entreDecesEtLiquidation.textB',
      ),
    }),
    getParagraph({
      numbering: {
        level: 0,
        reference: 'lowercase-letters-parenthesis',
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.entreDecesEtLiquidation.textC',
      ),
    }),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.entreDecesEtLiquidation.rowsTitle',
      ),
    ),
    ...formData.enfants.rows.reduce((accumulator, row, index) => {
      const victimeIndirecte = victimesIndirectes.find(
        (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
      );
      if (victimeIndirecte) {
        accumulator.push(
          getParagraph({
            numbering: {
              reference: 'simple-list',
              level: 0,
            },
            text: i18next.t(
              'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.entreDecesEtLiquidation.rowText',
              {
                nom: victimeIndirecte?.nom,
                prenom: victimeIndirecte?.prenom,
                partPerteAnnuelle: fCurrency(row.partPerteAnnuelle),
                prejudiceEntreDecesEtLiquidation: fCurrency(
                  row.prejudiceEntreDecesEtLiquidation,
                ),
                formula:
                  row.partPerteAnnuelle || row.dureeIndemnisation
                    ? displayFormula({
                        formula: i18next.t(
                          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.prejudiceEntreDecesEtLiquidation.formula',
                          {
                            partPerteAnnuelle: fCurrency(row.partPerteAnnuelle),
                            dureeIndemnisation: fYears(row.dureeIndemnisation),
                          },
                        ),
                        options: {
                          withParentheses: true,
                        },
                        editedFieldsParameters: {
                          formData,
                          fieldName: `enfants.rows.${index}.prejudiceEntreDecesEtLiquidation`,
                        },
                      })
                    : '',
              },
            ),
          }),
        );
      }
      return accumulator;
    }, [] as Paragraph[]),
    getEmptyLine(),
    entreDecesEtLiquidationTable,
    getEmptyLine(),
    getParagraph({
      numbering: {
        level: 1,
        reference: 'numbers-dot-simple',
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.apresLiquidation.text',
        {
          dateLiquidation: procedure.dateLiquidation
            ? fDate(new Date(procedure.dateLiquidation))
            : '',
        },
      ),
    }),
    getEmptyLine(),
    apresLiquidationTable,
    getEmptyLine(),
    getParagraph({
      numbering: {
        level: 1,
        reference: 'lowercase-letters-parenthesis',
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.enfants.capitauxDeces.text',
        {
          total: fCurrency(formData.enfants.total),
          totalFormula:
            totalEntreDecesEtLiquidation && totalMontantCapitalise
              ? displayFormula({
                  formula: i18next.t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.total.formula',
                    {
                      totalPrejudiceEntreDecesEtLiquidation: fCurrency(
                        totalEntreDecesEtLiquidation,
                      ),
                      totalMontantCapitalise: fCurrency(totalMontantCapitalise),
                    },
                  ),
                  options: {
                    withParentheses: true,
                  },
                  editedFieldsParameters: {
                    formData,
                    fieldName: `enfants.total`,
                  },
                })
              : '',
          totalCapitauxDeces: fCurrency(formData.enfants.totalCapitauxDeces),
          totalApresCapitauxDeces: fCurrency(
            formData.enfants.totalApresCapitauxDeces,
          ),
          totalApresCapitauxDecesFormula:
            formData.enfants.total || formData.enfants.totalCapitauxDeces
              ? displayFormula({
                  formula: i18next.t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.totalApresCapitauxDeces.formula',
                    {
                      total: fCurrency(formData.enfants.total),
                      totalCapitauxDeces: fCurrency(
                        formData.enfants.totalCapitauxDeces,
                      ),
                    },
                  ),
                  options: {
                    withParentheses: true,
                  },
                  editedFieldsParameters: {
                    formData,
                    fieldName: `enfants.totalApresCapitauxDeces`,
                  },
                })
              : '',
        },
      ),
    }),
    getEmptyLine(),
    capitauxDecesTable,
  ];
};
