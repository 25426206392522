import React, { useMemo, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Box, Button, Grid, Stack, Tooltip } from '@mui/material';

import { OldPrejudiceFormPerteGainProfessionnelsActuel } from '../../../../types/prejudice.type';
import { REGIME_TYPE_LISTE, RegimeType } from '../../../../types/regime.type';
import { prettyString, regimeSubtitles } from '../../../../constants/regimes';

interface Props {
  control:
    | Control<OldPrejudiceFormPerteGainProfessionnelsActuel>
    | Control<OldPrejudiceFormPerteGainProfessionnelsActuel>;
  selectRegime: (regime: RegimeType) => void;
  cancel: () => void;
}

export const RegimeStep: React.FC<Props> = ({
  control,
  selectRegime,
  cancel,
}) => {
  const [selectedRegime, setSelectedRegime] = useState<RegimeType | undefined>(
    undefined,
  );
  const situations = useWatch({ control, name: 'situations' });

  const regimes = useMemo(
    () =>
      REGIME_TYPE_LISTE.filter(
        (regime) =>
          !situations.some((situation) => situation.regime === regime),
      ),
    [situations],
  );

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          {regimes.map((regime) => (
            <Grid key={regime} item xs={3}>
              <Tooltip
                title={
                  regimeSubtitles[regime].length > 0
                    ? regimeSubtitles[regime].map((subtitle, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && (
                            <>
                              <br />
                              <br />
                            </>
                          )}
                          {subtitle}
                        </React.Fragment>
                      ))
                    : ''
                }
              >
                <Button
                  variant={selectedRegime === regime ? 'contained' : 'outlined'}
                  sx={{ width: '100%' }}
                  onClick={() => setSelectedRegime(regime)}
                >
                  {prettyString[regime]}
                </Button>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          variant="contained"
          color="error"
          sx={{ alignSelf: 'center' }}
          onClick={cancel}
        >
          Annuler
        </Button>
        <Button
          disabled={!selectedRegime}
          variant="contained"
          sx={{ alignSelf: 'center' }}
          onClick={() => selectRegime(selectedRegime || 'SALARIE_RG')}
        >
          Valider
        </Button>
      </Stack>
    </>
  );
};
