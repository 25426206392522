import React from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

interface ProcedureMenuProps {
  procedureId: string;
  setProcedureToDelete: (victimId: string) => void;
  setDeleteProcedureConfirmDialog: (open: boolean) => void;
  isTrash?: boolean;
  setRecycleProcedureConfirmDialog?: (open: boolean) => void;
  onDuplicateProcedure?: (procedureId: string) => void;
}

export const ProcedureMenu: React.FC<ProcedureMenuProps> = ({
  procedureId,
  setProcedureToDelete,
  setDeleteProcedureConfirmDialog,
  isTrash = false,
  setRecycleProcedureConfirmDialog,
  onDuplicateProcedure,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleProcedureOptionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleProcedureOptionClose = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton onClick={handleProcedureOptionClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        key={procedureId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleProcedureOptionClose}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setProcedureToDelete(procedureId);
            setDeleteProcedureConfirmDialog(true);
            setAnchorEl(null);
          }}
        >
          {isTrash ? 'Supprimer' : 'Mettre dans la corbeille'}
        </MenuItem>
        {isTrash && setRecycleProcedureConfirmDialog && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setProcedureToDelete(procedureId);
              setRecycleProcedureConfirmDialog(true);
              setAnchorEl(null);
            }}
          >
            Restaurer
          </MenuItem>
        )}
        {!!onDuplicateProcedure && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onDuplicateProcedure(procedureId);
              setAnchorEl(null);
            }}
          >
            Dupliquer
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
