import React, { useReducer } from 'react';
import {
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  ModalProps,
  Button,
} from '@mui/material';

import { prettyString } from 'src/constants/procedureQuestions';
import { ProcedureQuestion } from '../../../types/procedure.type';

/* Tree */
type QuestionsTreeItem = null | Partial<{
  [key in ProcedureQuestion]: QuestionsTreeItem;
}>;
type QuestionsTree = Partial<Record<ProcedureQuestion, QuestionsTreeItem>>;

const questionsTree: QuestionsTree = {
  INDEMNISATION_JUDICIAIRE: {
    ACCIDENT_DU_TRAVAIL: null,
    ACCIDENT_DROIT_COMMUN: null,
  },
  INDEMNISATION_ADMINISTRATIVE: {
    ACCIDENT_DU_TRAVAIL: null,
    ACCIDENT_DROIT_COMMUN: null,
  },
};

/* Component */
interface State {
  answers: ProcedureQuestion[];
  tree: QuestionsTree;
}

const reducer = (
  state: State,
  action: { type: string; payload?: unknown },
): State => {
  switch (action.type) {
    case 'SELECT_ANSWER': {
      const { key, onFinish } = action.payload as {
        key: ProcedureQuestion;
        onFinish: (answers: ProcedureQuestion[]) => void;
      };

      if (state.tree[key] === null) {
        onFinish(state.answers.concat(key));
      } else if (state.tree[key] instanceof Object) {
        return {
          answers: state.answers.concat(key),
          tree: state.tree[key] as QuestionsTree,
        };
      }
      return state;
    }
    default:
      return { answers: [], tree: questionsTree };
  }
};

// Styled components

const StyledDialogContent = styled(DialogContent)({
  minWidth: '700px',
});

// Component
interface Props {
  open: boolean;
  onClose?: ModalProps['onClose'];
  onFinish: (answers: ProcedureQuestion[]) => void;
}

export const CreateProcedureDialog: React.FC<Props> = ({
  open,
  onClose,
  onFinish,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    tree: questionsTree,
    answers: [],
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onExited: () => {
          dispatch({ type: 'RESET' });
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="caption"
          fontWeight="bold"
          textAlign="center"
          component="div"
        >
          {!!state.answers.length &&
            state.answers.map((answer) => prettyString[answer]).join(' > ')}
        </Typography>
      </DialogTitle>
      <StyledDialogContent>
        <Stack spacing={6} margin={4} alignItems="center">
          {Object.keys(state.tree).map((key) => (
            <Button
              sx={(theme) => ({
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                transform: 'scale(1)',
                transition: '0.12s linear',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.main,
                  transform: 'scale(1.1)',
                  transition: '0.12s linear',
                },
                height: 'fit-content',
                padding: '15px 25px 15px 25px',
              })}
              key={key}
              onClick={() =>
                dispatch({ type: 'SELECT_ANSWER', payload: { key, onFinish } })
              }
            >
              {prettyString[key as ProcedureQuestion]}
            </Button>
          ))}
        </Stack>
      </StyledDialogContent>
    </Dialog>
  );
};
