import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

import { LayoutTable } from '../../styled';
import { CalculsBox } from '../../basic/CalculsBox';
import { fCurrency } from '../../../helpers/formatNumber';
import { useTranslation } from 'react-i18next';
import { fPartResponsabilite } from 'src/helpers/formatValues';

interface Props {
  totalMontantTotal: number;
  totalPartResponsabilite?: number;
  partResponsabilite?: number;
  displayPartResponsabilite?: boolean;
}

export const TotalDeficit: React.FC<Props> = ({
  totalMontantTotal,
  totalPartResponsabilite,
  partResponsabilite,
  displayPartResponsabilite,
}) => {
  const { t } = useTranslation();
  return (
    <CalculsBox>
      <LayoutTable size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right" colSpan={4}>
              {'Total'}
            </TableCell>
            <TableCell align="right" colSpan={4}>
              {fCurrency(totalMontantTotal)}
            </TableCell>
          </TableRow>
          {displayPartResponsabilite &&
            totalPartResponsabilite !== undefined &&
            (partResponsabilite || partResponsabilite === 0) && (
              <TableRow>
                <TableCell align="right" colSpan={4}>
                  {t('partResponsabilite.tableRowHeader', {
                    partResponsabilite: fPartResponsabilite(
                      partResponsabilite * 100,
                    ),
                  })}
                </TableCell>
                <TableCell align="right" colSpan={4}>
                  {fCurrency(totalPartResponsabilite)}
                </TableCell>
              </TableRow>
            )}
        </TableHead>
      </LayoutTable>
    </CalculsBox>
  );
};
