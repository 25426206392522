import React from 'react';
import { createUseStyles } from 'react-jss';
import { LinearProgress } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';

const useStyles = createUseStyles({
  contactGridOverlay: {},
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  loadingProgress: {},
});

export const CustomLoadingOverlay: React.FC = () => {
  const classes = useStyles();
  return (
    <GridOverlay className={classes.contactGridOverlay}>
      <div className={classes.loadingOverlay}>
        <LinearProgress className={classes.loadingProgress} />
      </div>
    </GridOverlay>
  );
};
