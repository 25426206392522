import React, { useState } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
} from 'react-hook-form';
import { Button, Grid, Stack, Typography } from '@mui/material';

import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
} from '../../../../types/prejudice.type';
import { TableSituations } from './TableSituations';
import { Procedure } from '../../../../types/procedure.type';
import { TotalPerteGains } from './TotalPerteGains';
import { RenteAccidentDuTravailDialog } from './RenteAccidentDuTravailDialog';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { Chiffrage } from '../Chiffrage';
import { SavePrejudiceButton } from '../../SavePrejudiceButton';
import { Victime } from 'src/types/victime.type';

interface Props {
  procedure: Procedure;
  control: Control<
    | OldPrejudiceFormPerteGainProfessionnelsActuel
    | OldPrejudiceFormPerteGainProfessionnelsFuturs
  >;
  fields: FieldArrayWithId<
    OldPrejudiceFormPerteGainProfessionnelsActuel,
    'situations'
  >[];
  remove: UseFieldArrayRemove;
  newSituation: () => void;
  editSituation: (index: number) => void;
  isPGPF: boolean;
  tauxIPP: number | null;
  victime: Victime;
}

export const RecapitulatifStep: React.FC<Props> = ({
  procedure,
  control,
  fields,
  remove,
  newSituation,
  editSituation,
  isPGPF,
  tauxIPP,
  victime,
}) => {
  const [
    isRenteAccidentDuTravailModalOpen,
    setIsRenteAccidentDuTravailModalOpen,
  ] = useState(false);
  return (
    <>
      <TableSituations
        procedure={procedure}
        control={control}
        fields={fields}
        isPGPF={isPGPF}
        remove={remove}
        onEdit={editSituation}
      />

      <Stack
        direction="row-reverse"
        justifyContent={isPGPF ? 'start' : 'end'}
        alignItems="start"
      >
        {!isPGPF && (
          <TotalPerteGains
            control={
              control as Control<OldPrejudiceFormPerteGainProfessionnelsActuel>
            }
            procedure={procedure}
          />
        )}
        {isPGPF && (
          <Stack
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.light,
              borderRadius: theme.shape.borderRadius / 8,
              padding: theme.spacing(1),
            })}
          >
            <Typography textAlign="center" variant="subtitle1">
              Spécial rentes ATMP ou assimilables
            </Typography>
            <Button
              onClick={() => {
                setIsRenteAccidentDuTravailModalOpen(true);
              }}
              sx={{ textTransform: 'none' }}
            >
              Simulateur de calcul de la rente ATMP ou de son capital
              représentatif
            </Button>
          </Stack>
        )}
      </Stack>

      <Button onClick={newSituation} sx={{ alignSelf: 'center' }}>
        Ajouter une situation
      </Button>
      {isPGPF && (
        <ComputedPropsForm
          control={control}
          watchFields={['totalRevenuDeReference']}
          compute={([totalRevenuDeReference]) => ({
            props: {
              totalRevenuDeReference,
            },
          })}
          render={({ totalRevenuDeReference }) => (
            <RenteAccidentDuTravailDialog
              open={isRenteAccidentDuTravailModalOpen}
              salaireAnnuelDeReference={totalRevenuDeReference}
              tauxIPP={tauxIPP}
              onClose={() => setIsRenteAccidentDuTravailModalOpen(false)}
              formType="REVENUS"
            />
          )}
        />
      )}
      <Grid container>
        <Grid item xs={4}>
          <Chiffrage control={control} />
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <SavePrejudiceButton victime={victime} />
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </>
  );
};
