import i18n from 'i18next';
import { FieldError, FieldErrors, SubmitErrorHandler } from 'react-hook-form';
import { PrejudiceFormValues } from 'src/types/prejudice.type';

const printPrejudiceFormError = (error: FieldErrors | FieldError | undefined) =>
  console.log(
    `${i18n.t('prejudice.errorSaving')}\n${JSON.stringify(error, null, 2)}`,
  );

export const printPrejudiceFormSubmitError: SubmitErrorHandler<
  PrejudiceFormValues
> = (errors: FieldErrors | undefined) => printPrejudiceFormError(errors);

export const printPrejudiceFormFieldError = (error: FieldError | undefined) =>
  printPrejudiceFormError(error);
