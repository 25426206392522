import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { apiRequest } from 'src/helpers/api';
import { pendingReducer, rejectedReducer } from '../helpers/slice';
import { CreateUpdateDommageDTO, Dommage } from '../types/dommage.type';

/* Thunks */

export const fetchAllDommages = createAsyncThunk(
  'dommages/fetchAll',
  async (procedureId: string) => {
    return await apiRequest<Dommage[]>(
      'GET',
      `/procedures/${procedureId}/dommages`,
    );
  },
);

export const createDommage = createAsyncThunk(
  'dommages/create',
  async (payload: { procedureId: string; data: CreateUpdateDommageDTO }) => {
    const { procedureId, data } = payload;

    return await apiRequest<Dommage>(
      'POST',
      `/procedures/${procedureId}/dommages`,
      undefined,
      data,
    );
  },
);

export const updateDommage = createAsyncThunk(
  'dommages/update',
  async (payload: {
    procedureId: string;
    dommageId: string;
    data: CreateUpdateDommageDTO;
  }) => {
    const { procedureId, dommageId, data } = payload;

    return await apiRequest<Dommage>(
      'PATCH',
      `/procedures/${procedureId}/dommages/${dommageId}`,
      undefined,
      data,
    );
  },
);

/* Slice */

export const dommageAdapter = createEntityAdapter<Dommage>({
  selectId: (dommage) => dommage._id,
});

const dommageSlice = createSlice({
  name: 'dommage',
  initialState: dommageAdapter.getInitialState({
    isLoading: false,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDommages.fulfilled, (state, action) => {
        dommageAdapter.setAll(state, action.payload);
        state.isLoading = false;
      })
      .addCase(fetchAllDommages.pending, pendingReducer)
      .addCase(fetchAllDommages.rejected, rejectedReducer);

    builder
      .addCase(createDommage.fulfilled, (state, action) => {
        dommageAdapter.addOne(state, action.payload);
        state.isLoading = false;
      })
      .addCase(createDommage.pending, pendingReducer)
      .addCase(createDommage.rejected, rejectedReducer);

    builder
      .addCase(updateDommage.fulfilled, (state, action) => {
        dommageAdapter.setOne(state, action.payload);
        state.isLoading = false;
      })
      .addCase(updateDommage.pending, pendingReducer)
      .addCase(updateDommage.rejected, rejectedReducer);
  },
});

export default dommageSlice.reducer;
