import { AlignmentType, Table } from 'docx';
import {
  getHeaderTableCell,
  getTableRow,
  getValueTableCell,
} from './docxFunctions';

export const simpleHorizontalTablePrint = (
  headerLabels: string[],
  rows: {
    rowLabel: string;
    columns: string[];
  }[],
  alignment?: (typeof AlignmentType)[keyof typeof AlignmentType],
): Table => {
  return new Table({
    alignment: alignment ?? AlignmentType.START,
    rows: [
      getTableRow({
        children: headerLabels.map((label, index) =>
          getHeaderTableCell(label, undefined, {
            alignment: index === 0 ? AlignmentType.START : AlignmentType.CENTER,
          }),
        ),
      }),
      ...rows.map((row) =>
        getTableRow({
          children: [
            getHeaderTableCell(row.rowLabel),
            ...row.columns.map((column) => getValueTableCell(column)),
          ],
        }),
      ),
    ],
  });
};

export const simpleTablePrint = (
  headerLabels: string[],
  rowLabels: string[],
  rows: string[][],
  alignment?: (typeof AlignmentType)[keyof typeof AlignmentType],
): Table => {
  return new Table({
    alignment: alignment ?? AlignmentType.START,
    rows: [
      getTableRow({
        children: [
          getHeaderTableCell('', undefined, { alignment: AlignmentType.START }),
          ...headerLabels.map((label) =>
            getHeaderTableCell(label, undefined, {
              alignment: AlignmentType.CENTER,
            }),
          ),
        ],
      }),
      ...rowLabels.map((rowLabel, rowIndex) =>
        getTableRow({
          children: [
            getHeaderTableCell(rowLabel, undefined, {
              alignment: AlignmentType.START,
            }),
            ...rows[rowIndex]!.map((column) => getValueTableCell(column)),
          ],
        }),
      ),
    ],
  });
};
