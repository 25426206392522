import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { simpleVerticalTablePrint } from 'src/helpers/print/simpleVerticalTablePrint';
import { RevenuAnnuelEspere } from 'src/types/prejudice.type';
import { CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte } from 'src/constants/calculs/calculsFormPerteGainsProfessionnelsActuelSaisieDirecte';
import { getEmptyLine } from 'src/helpers/print/docxFunctions';

export const revenuEsperePrint = ({
  rows,
  total,
}: Pick<RevenuAnnuelEspere, 'rows' | 'total'>): (
  | Table
  | TableOfContents
  | Paragraph
)[] => {
  const totalByUnits =
    CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte.getTotalPerteDeChanceByUnits(
      total,
    );
  return [
    ...(rows.length > 0
      ? [
          simpleHorizontalTablePrint(
            [
              i18next.t('numeroPiece.form.columnHeader'),
              i18next.t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.rows.revenuEspere.columnHeader',
              ),
              i18next.t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.rows.coefficientPerteDeChance.columnHeader',
              ),
              i18next.t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.rows.perteDeChance.columnHeader',
              ),
            ],
            rows.map((row) => ({
              rowLabel: row.numerosPieces
                ? displayNumeroPiece(row.numerosPieces)
                : '',
              columns: [
                row.revenuEspere && row.unite
                  ? i18next.t('prejudice.fields.unite.print.value', {
                      unite: row.unite,
                      value: fCurrency(row.revenuEspere),
                    })
                  : '',
                row.coefficientPerteDeChance
                  ? fPercent(row.coefficientPerteDeChance)
                  : '',
                row.perteDeChance ? fCurrency(row.perteDeChance) : '',
              ],
            })),
          ),
          getEmptyLine(),
        ]
      : []),
    simpleVerticalTablePrint([
      {
        title: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.title',
        ),
        bold: false,
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.jour.rowHeader',
        ),
        value: fCurrency(totalByUnits.jour),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.semaine.rowHeader',
        ),
        value: fCurrency(totalByUnits.semaine),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.mois.rowHeader',
        ),
        value: fCurrency(totalByUnits.mois),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.fields.revenuEspere.total.table.annee.rowHeader',
        ),
        value: fCurrency(total),
      },
    ]),
  ];
};
