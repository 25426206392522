import { InputProps } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const MaskPhoneNumber = React.forwardRef<
  HTMLElement,
  Props & InputProps
>(function MaskPhoneNumber(props, ref) {
  const { onChange, ...rest } = props;

  return (
    <IMaskInput
      {...rest}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mask="?# 00 00 00 00"
      definitions={{
        '?': /[0]/,
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) =>
        props.value !== value
          ? onChange({
              target: { name: props.name, value: value?.replace(/ /g, '') },
            })
          : undefined
      }
      overwrite
    />
  );
});
