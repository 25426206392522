import React from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { OrganizationInvitation } from 'src/types/organizationInvitation.type';

interface OrganizationInvitationMenuProps {
  invitation: OrganizationInvitation;
  onClickUpdateInvitation: (invitation: OrganizationInvitation) => void;
  onClickResendInvitation: (invitationId: string) => void;
  onClickDeleteInvitation: (invitation: OrganizationInvitation) => void;
}

export const OrganizationInvitationMenu: React.FC<
  OrganizationInvitationMenuProps
> = ({
  invitation,
  onClickUpdateInvitation,
  onClickResendInvitation,
  onClickDeleteInvitation,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);
  return (
    <div>
      <IconButton onClick={handleOpenMenu}>
        <MoreHoriz />
      </IconButton>
      <Menu
        key={invitation._id}
        anchorEl={anchorEl}
        open={open}
        onClose={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          handleCloseMenu();
        }}
      >
        <MenuItem
          onClick={() => {
            onClickResendInvitation(invitation._id);
            handleCloseMenu();
          }}
        >
          {t('pages.OrganizationConfiguration.invitations.table.menu.resend')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickUpdateInvitation(invitation);
            handleCloseMenu();
          }}
        >
          {t('pages.OrganizationConfiguration.invitations.table.menu.edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickDeleteInvitation(invitation);
            handleCloseMenu();
          }}
        >
          {t('pages.OrganizationConfiguration.invitations.table.menu.delete')}
        </MenuItem>
      </Menu>
    </div>
  );
};
