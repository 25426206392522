import React from 'react';
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'src/hooks/isMobile';
interface Props {
  children: React.ReactNode;
}
export const MobileAccess: React.FC<Props> = ({ children }) => {
  const mobile = useIsMobile();
  const { t } = useTranslation();
  if (!mobile) {
    return <>{children}</>;
  }
  return (
    <>
      <AppBar
        position="sticky"
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.dark,
        })}
      >
        {' '}
        <Toolbar>
          <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box
                component="img"
                src="/norma_logo_white.png"
                sx={{ height: '31.5px' }}
              />
              <Box
                component="img"
                src="/norma_text.png"
                sx={{ height: '17.5px' }}
              />
              {/* NORMA */}
            </Stack>
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack padding={4} spacing={2}>
        <Typography variant="h3">{t('pages.NoMobileAccess.title')}</Typography>
        <Typography variant="body1">
          {t('pages.NoMobileAccess.description')}
        </Typography>
      </Stack>
    </>
  );
};
