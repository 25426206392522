import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
  PerteGainProfessionnelsFutursSituation,
} from 'src/types/prejudice.type';
import { revenusReelsPrint } from './revenuReelPrint';
import { revenusIndemnitesPrint } from './revenusIndemnitesPrint';
import { revenusTheoriquePrint } from './revenuTheoriquePrint';

export const situationPrint = ({
  situation,
  monetaryErosions,
  dateLiquidation,
  revalorisationCoefficientsType,
}: {
  situation:
    | PerteGainProfessionnelsActuelSituation
    | PerteGainProfessionnelsFutursSituation;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation: Date | undefined;
} & Pick<
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  'revalorisationCoefficientsType'
>): (Table | Paragraph | TableOfContents)[] => {
  return [
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.REGIME.print.title',
          {
            regime: situation.regime,
          },
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenuTheorique.print.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...revenusTheoriquePrint({
      revenuTheorique: situation.revenuTheorique,
      revenuDeReference: situation.revenuDeReference,
      anneesRevenusTheoriques: situation.anneesRevenusTheoriques,
      monetaryErosions,
      dateLiquidation: dateLiquidation,
      regime: situation.regime,
      revalorisationCoefficientsType,
    }),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.revenusReelsNet.print.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...revenusReelsPrint({
      revenusReelsNet: situation.revenusReelsNet,
      anneesRevenusReels: situation.anneesRevenusReels,
      monetaryErosions,
      dateLiquidation: dateLiquidation,
      regime: situation.regime,
      revalorisationCoefficientsType,
    }),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.situations.tiersPayeursIndemnites.print.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...revenusIndemnitesPrint({
      revenusIndemnitesTiersPayeurs: situation.tiersPayeursIndemnites,
      totalIndemnitesCsgCrds: situation.totalIndemnitesCsgCrds,
      totalIndemnitesNet: situation.totalIndemnitesNet,
    }),
  ];
};
