import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/constants/theme';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Cancel, CheckCircle, Edit } from '@mui/icons-material';
import { TextFieldForm } from '../forms/TextFieldForm';
import { UpdateSubscriptionDto } from 'src/types/subscription.type';
import { CheckboxFormField } from '../forms/CheckboxFormField';
import { Organization } from 'src/types/organization.type';
import { fDate } from 'src/helpers/formatTime';
import {
  canCancelSubscriptionAsAdmin,
  isAutomaticSubscriptionAvailableForOrganization,
} from 'src/helpers/subscription';
import { DatePickerForm } from '../forms/DatePickerForm';
import { dateString } from 'src/helpers/yup';
import { IMaskNumberProps, MaskNumber } from '../masks/MaskNumber';

interface Props {
  organization: Organization;
  updateSubscription: (data: UpdateSubscriptionDto) => void;
  cancelSubscription: (subscriptionId: string) => void;
}

const OrganizationCard: React.FC<Props> = ({
  organization,
  updateSubscription,
  cancelSubscription,
}) => {
  const { t } = useTranslation();
  const [editSubscription, setEditSubscription] = useState<boolean>(false);

  const { control, handleSubmit, reset } = useForm<UpdateSubscriptionDto>({
    defaultValues: {
      isPaying: organization.subscription?.isPaying,
      isFreeSubscription: organization.subscription?.isFreeSubscription,
      isFreeTrial: organization.subscription?.isFreeTrial,
      freeTrialEndDate: organization.subscription?.freeTrialEndDate,
      maxNumberOfUsers: organization.subscription?.maxNumberOfUsers,
    },
    resolver: yupResolver(
      yup.object<UpdateSubscriptionDto>({
        isPaying: yup.boolean().optional(),
        isFreeSubscription: yup.boolean().optional(),
        isFreeTrial: yup.boolean().optional(),
        freeTrialEndDate: dateString().when(
          ['isFreeTrial'],
          ([isFreeTrial], schema) => {
            if (isFreeTrial) {
              return schema.required(t('forms.rules.required'));
            }
            return schema.optional();
          },
        ),
        maxNumberOfUsers: yup.number().optional().min(0).max(10000),
      }),
    ),
  });

  useEffect(() => {
    reset({
      isPaying: organization.subscription?.isPaying,
      isFreeSubscription: organization.subscription?.isFreeSubscription,
      isFreeTrial: organization.subscription?.isFreeTrial,
      freeTrialEndDate: organization.subscription?.freeTrialEndDate,
      maxNumberOfUsers: organization.subscription?.maxNumberOfUsers,
    });
  }, [organization, reset]);

  const subscriptionResetEdit = async () => {
    setEditSubscription(false);
  };

  const onSubmitSubscription = async (data: UpdateSubscriptionDto) => {
    updateSubscription({
      ...data,
      ...(!organization.subscription?.isFreeTrial && data.isFreeTrial
        ? {
            freeTrialStartDate: new Date(),
          }
        : {}),
    });
    subscriptionResetEdit();
  };

  const organizationRegisterDate = new Date(
    organization.createdAt ?? Date.now(),
  );

  const displayEditSubscription = () => {
    const automaticSubscriptionAvailable =
      isAutomaticSubscriptionAvailableForOrganization(organization);
    return (
      <form
        style={{ marginTop: theme.spacing(3) }}
        onSubmit={handleSubmit(onSubmitSubscription, console.log)}
      >
        {Boolean(
          !organization.subscription?.isFreeTrial &&
            !organization.subscription?.isAutomaticSubscriptionPaymentActive,
        ) && (
          <Stack
            marginTop={1}
            marginBottom={0.5}
            direction="row"
            alignItems="center"
          >
            <Typography variant="body1">
              {t('pages.Admin.subscription.free')}
            </Typography>
            <CheckboxFormField name="isFreeSubscription" control={control} />
          </Stack>
        )}
        {Boolean(
          !organization.subscription?.isAutomaticSubscriptionPaymentActive,
        ) && (
          <Stack
            marginTop={1}
            marginBottom={0.5}
            direction="row"
            alignItems="center"
          >
            <Typography variant="body1">
              {t('pages.Admin.subscription.manual')}
            </Typography>
            <CheckboxFormField name="isPaying" control={control} />
          </Stack>
        )}
        {!(
          automaticSubscriptionAvailable &&
          organization.subscription?.isAutomaticSubscriptionPaymentActive
        ) ? (
          <Stack
            marginTop={1}
            marginBottom={0.5}
            direction="row"
            alignItems="center"
          >
            <Typography variant="body1">
              {t('pages.Admin.subscription.freeTrial', {
                startDate: !!organization.subscription?.freeTrialStartDate
                  ? fDate(organization.subscription?.freeTrialStartDate)
                  : '',
                context: !!organization.subscription?.freeTrialStartDate
                  ? 'startDate'
                  : undefined,
              })}
            </Typography>
            <CheckboxFormField name="isFreeTrial" control={control} />
            <DatePickerForm
              name="freeTrialEndDate"
              control={control}
              label={t('pages.Admin.subscription.freeTrialEndDate')}
              sx={{ width: 300 }}
            />
          </Stack>
        ) : null}
        {automaticSubscriptionAvailable ? (
          <Stack marginTop={2} marginBottom={0.5} spacing={2}>
            <Typography variant="body1" fontWeight="bold">
              {t('pages.Admin.subscription.automatic.title')}
            </Typography>
            {canCancelSubscriptionAsAdmin(organization.subscription) ? (
              <Button
                variant="contained"
                onClick={() => {
                  if (organization.subscription?._id) {
                    cancelSubscription(organization.subscription?._id);
                  }
                }}
                sx={{ alignSelf: 'start' }}
              >
                {t('pages.Admin.subscription.automatic.cancel')}
              </Button>
            ) : null}
            {organization.subscription?.isFreeTrial &&
            organization.subscription?.isAutomaticSubscriptionPaymentActive ? (
              <>
                <Typography variant="body1">
                  {t('pages.Admin.subscription.automatic.freeTrial', {
                    startDate: !!organization.subscription?.freeTrialStartDate
                      ? fDate(organization.subscription?.freeTrialStartDate)
                      : '',
                    endDate: !!organization.subscription?.freeTrialEndDate
                      ? fDate(organization.subscription?.freeTrialEndDate)
                      : '',
                  })}
                </Typography>
              </>
            ) : (
              <>
                {!!organization.subscription?.subscriptionPeriodStartDate ? (
                  <Typography variant="body1">
                    {t('pages.Admin.subscription.automatic.lastPayment', {
                      lastPaymentDate: fDate(
                        organization.subscription?.subscriptionPeriodStartDate,
                      ),
                    })}
                  </Typography>
                ) : null}
                {!!organization.subscription?.subscriptionPeriodEndDate ? (
                  <Typography variant="body1">
                    {organization.subscription?.cancelAtPeriodEnd
                      ? t(
                          'pages.Admin.subscription.automatic.cancelAtPeriodEnd',
                          {
                            endDate: fDate(
                              organization.subscription
                                ?.subscriptionPeriodEndDate,
                            ),
                          },
                        )
                      : t('pages.Admin.subscription.automatic.nextPayment', {
                          nextPaymentDate: fDate(
                            organization.subscription
                              ?.subscriptionPeriodEndDate,
                          ),
                        })}
                  </Typography>
                ) : null}
              </>
            )}
          </Stack>
        ) : null}
        {!organization.subscription.isAutomaticSubscriptionPaymentActive ? (
          <TextFieldForm
            label={t('subscription.fields.maxNumberOfUsers')}
            name="maxNumberOfUsers"
            control={control}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 0 },
              } as IMaskNumberProps,
            }}
            sx={(theme) => ({
              marginTop: theme.spacing(1),
            })}
          />
        ) : null}
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ margin: theme.spacing(2, 0, 0.5, 0) }}
          spacing={2}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={subscriptionResetEdit}
            fullWidth
          >
            {t('common.cancel')}
          </Button>
          <Button variant="contained" type="submit" color="primary" fullWidth>
            {t('common.save')}
          </Button>
        </Stack>
      </form>
    );
  };

  return (
    <Card>
      <CardHeader title={<div>{organization.name}</div>} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{t('pages.Admin.company')}</Typography>
            <Typography variant="body2" color="#637381" component="div">
              {organization.type
                ? t(`organization.fields.type.options.${organization.type}`)
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              {t('pages.Admin.subscription.title')}
            </Typography>
            {editSubscription ? (
              displayEditSubscription()
            ) : (
              <Stack direction="row" alignItems="center">
                <Box>
                  <Typography
                    variant="body2"
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                  >
                    {organization.subscription?.isFreeSubscription
                      ? t('pages.Admin.subscription.free')
                      : organization.subscription?.isFreeTrial
                        ? t('pages.Admin.subscription.freeTrial', {
                            startDate:
                              !!organization.subscription?.freeTrialStartDate &&
                              fDate(
                                organization.subscription?.freeTrialStartDate,
                              ),
                            endDate:
                              !!organization.subscription?.freeTrialEndDate &&
                              fDate(
                                organization.subscription?.freeTrialEndDate,
                              ),
                          })
                        : organization.subscription?.isPaying
                          ? t('pages.Admin.subscription.manual')
                          : organization.subscription
                                ?.isAutomaticSubscriptionPaymentActive
                            ? t('pages.Admin.subscription.automatic.title')
                            : t('pages.Admin.subscription.none')}
                    {Boolean(
                      organization.subscription?.isFreeSubscription ||
                        organization.subscription?.isFreeTrial ||
                        organization.subscription?.isPaying ||
                        organization.subscription
                          ?.isAutomaticSubscriptionPaymentActive,
                    ) ? (
                      <CheckCircle color="success" />
                    ) : (
                      <Cancel color="error" />
                    )}
                  </Typography>
                </Box>
                <IconButton
                  style={{ marginLeft: theme.spacing(3) }}
                  onClick={() => setEditSubscription(!editSubscription)}
                >
                  <Edit />
                </IconButton>
              </Stack>
            )}
            <Typography variant="body2" component="div">
              {t('pages.Admin.subscription.members', {
                count: organization.members?.length,
              })}
            </Typography>
            <Typography variant="body2" component="div">
              {t('pages.Admin.subscription.maxNumberOfUsers', {
                count: (organization.subscription?.maxNumberOfUsers || 0) + 1,
              })}
            </Typography>
            {organization.subscription?.isFreeTrial &&
            organization.subscription?.isAutomaticSubscriptionPaymentActive ? (
              <>
                <Typography variant="body2">
                  {t('pages.Admin.subscription.automatic.freeTrial', {
                    startDate: !!organization.subscription?.freeTrialStartDate
                      ? fDate(organization.subscription?.freeTrialStartDate)
                      : '',
                    endDate: !!organization.subscription?.freeTrialEndDate
                      ? fDate(organization.subscription?.freeTrialEndDate)
                      : '',
                  })}
                </Typography>
              </>
            ) : (
              <>
                {!!organization.subscription?.subscriptionPeriodStartDate ? (
                  <Typography variant="body2">
                    {t('pages.Admin.subscription.automatic.lastPayment', {
                      lastPaymentDate: fDate(
                        organization.subscription?.subscriptionPeriodStartDate,
                      ),
                    })}
                  </Typography>
                ) : null}
                {!!organization.subscription?.subscriptionPeriodEndDate ? (
                  <Typography variant="body2">
                    {organization.subscription?.cancelAtPeriodEnd
                      ? t(
                          'pages.Admin.subscription.automatic.cancelAtPeriodEnd',
                          {
                            endDate: fDate(
                              organization.subscription
                                ?.subscriptionPeriodEndDate,
                            ),
                          },
                        )
                      : t('pages.Admin.subscription.automatic.nextPayment', {
                          nextPaymentDate: fDate(
                            organization.subscription
                              ?.subscriptionPeriodEndDate,
                          ),
                        })}
                  </Typography>
                ) : null}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography component="div" variant="h5">
              {t('pages.Admin.organizationInfo')}
            </Typography>
            <div>
              {"Date d'inscription : " +
                organizationRegisterDate.toLocaleDateString() +
                '-' +
                organizationRegisterDate.toLocaleTimeString()}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrganizationCard;
