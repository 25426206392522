import { Close } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeProceduresExport } from 'src/helpers/print/makeProceduresExport';
import {
  getPrintDossierFileName,
  saveDocument,
} from 'src/helpers/print/saveDocument';
import { useAppSelector } from 'src/hooks/store';
import procedure, {
  fetchAllPopulatedProcedureByVictimeId,
} from 'src/slices/procedure';
import { RootState } from 'src/store';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { PrintFormat } from 'src/types/print.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { GridRowType } from './Procedures';

interface Props {
  victime: Victime;
  open: boolean;
  onClose: () => void;
  selectedProcedures: GridRowType[];
  monetaryErosions: MonetaryErosion[];
}
export const PrintProceduresDialog: React.FC<Props> = ({
  victime,
  open,
  onClose,
  selectedProcedures,
  monetaryErosions,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPopulatedProcedureByVictimeId(victime._id));
  }, []);

  const allPopulatedProcedure = useAppSelector(
    (state: RootState) => state.procedure.allPopulatedProcedure,
  );

  const procedures: Procedure[] = selectedProcedures.map(
    (row) => row.procedure,
  );
  const { t } = useTranslation();
  const [printFormat, setPrintFormat] = React.useState<PrintFormat>('xlsx');

  const onClickPrint = () => {
    const document = makeProceduresExport({
      allPopulatedProcedure: allPopulatedProcedure.filter(
        (x) => !x.inTrash && procedures.some((y) => y._id == x._id),
      ),
      victime,
      monetaryErosions,
    });

    const fileName = getPrintDossierFileName(victime);
    saveDocument(document, fileName, printFormat);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Card sx={{ minWidth: '500px' }}>
        <CardHeader
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">{'Export'}</Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          }
        />
        <CardContent>
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" paddingRight={2}>
              {t('print.dialog.printType.title')}
            </Typography>
            <ToggleButtonGroup
              value={printFormat}
              exclusive
              onChange={(_, value) => {
                if (!value) {
                  return;
                }
                setPrintFormat(value);
              }}
              aria-label="text alignment"
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {['xlsx', 'csv'].map((type, index) => (
                <ToggleButton key={index} value={type}>
                  {t(`print.dialog.printType.options.${type}`)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="contained"
            onClick={onClickPrint}
            disabled={!procedure}
          >
            {t('print.dialog.confirm')}
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};
