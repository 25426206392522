import { fPercent } from 'src/helpers/formatNumber';

export const partResponsabiliteOptions: number[] = [
  0, 25, 33.33, 50, 66.66, 75, 100,
];

export type PartResponsabiliteOption =
  (typeof partResponsabiliteOptions)[number];

export const partResponsabiliteOptionsLabels: Record<
  PartResponsabiliteOption,
  {
    fraction: string;
    percentage: string;
  }
> = {
  0: {
    fraction: '0',
    percentage: fPercent(0),
  },
  25: {
    fraction: '1/4',
    percentage: fPercent(25),
  },
  33.33: {
    fraction: '1/3',
    percentage: fPercent(33.33, 2),
  },
  50: {
    fraction: '1/2',
    percentage: fPercent(50),
  },
  66.66: {
    fraction: '2/3',
    percentage: fPercent(66.66, 2),
  },
  75: {
    fraction: '3/4',
    percentage: fPercent(75),
  },
  100: {
    fraction: '1',
    percentage: fPercent(100),
  },
};
