import { Close } from '@mui/icons-material';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { Baremes } from 'src/containers/resources/Baremes';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ResourcesDrawer: React.FC<Props> = ({ open, onClose }) => {
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const onCloseDrawer = () => {
    onClose();
    setIsFullScreen(false);
  };
  return (
    <Drawer
      variant="temporary"
      open={open}
      PaperProps={{
        sx: {
          width: 1,
          maxWidth: isFullScreen ? '100%' : 500,
          display: { xs: 'block' },
          bgcolor: 'background.default',
        },
      }}
      anchor="right"
      onClose={onCloseDrawer}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          direction="row"
          justifyContent={{ sm: isFullScreen ? 'space-between' : 'end' }}
          sx={{
            ['@media (min-width:500px)']: {
              justifyContent: isFullScreen ? 'space-between' : 'end',
            },
          }}
          alignItems="center"
        >
          <IconButton
            onClick={onCloseDrawer}
            sx={{
              ['@media (min-width:500px)']: {
                display: isFullScreen ? undefined : 'none',
              },
            }}
          >
            <Close />
          </IconButton>
          {/*<IconButton*/}
          {/*  onClick={() => setIsFullScreen(!isFullScreen)}*/}
          {/*  sx={{*/}
          {/*    ['@media (max-width:500px)']: {*/}
          {/*      display: 'none',*/}
          {/*    },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {isFullScreen ? <FullscreenExit /> : <Fullscreen />}*/}
          {/*</IconButton>*/}
        </Stack>
        <Typography
          variant="h3"
          sx={{ textAlign: 'center', marginBottom: '16px' }}
        >
          Ressources
        </Typography>
        {/*
    TODO implement jurisprudence
    <div className={classes.drawerResourseButtonGroupAround}>
      <ButtonGroup
        disableElevation
        variant="contained"
        className={classes.drawerResourseButtonGroup}
      >
        <Button
          onClick={() => setIsOn(true)}
          className={
            isOn
              ? classes.drawerResourseButtonOn
              : classes.drawerResourseButtonOff
          }
        >
          Jurisprudence
        </Button>
        <Button
          onClick={() => setIsOn(false)}
          className={
            isOn
              ? classes.drawerResourseButtonOff
              : classes.drawerResourseButtonOn
          }
        >
          Barèmes
        </Button>
      </ButtonGroup>
    </div> */}
        {/* {isOn && <Jurisprudence />}
    {!isOn && <Baremes />} */}
        <Baremes />
      </Box>
    </Drawer>
  );
};
