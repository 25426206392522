import { Paragraph, Table, TableOfContents, UnderlineType } from 'docx';
import i18n from 'i18next';
import { Bareme } from 'src/types/bareme.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  Prejudice,
  PrejudiceType,
  PGPFReliquatAndPGPFReliquatActivationStatus,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getPrejudicesPerCategoryPerDirectEntries } from '../prejudices/sortAndOrder';
import { PrejudiceTotalValue } from '../prejudices/total';
import { getEmptyLine, getParagraph, getTextRun } from './docxFunctions';
import { makePrejudicePrint } from './makePrejudicePrint';
import { makePrejudiceWrittenPrint } from './prejudicesWrittenPrints/prejudice.written.print';
import { getAvailablePrintTypesForPrejudiceType } from 'src/constants/print';

export const makePrejudicesPrint = ({
  victime,
  victimesIndirectes,
  procedure,
  prejudices,
  monetaryErosions,
  baremes,
  PGPFReliquat,
  prejudicesTotalValues,
  isVictimeIndirecte,
  isWrittenPrint,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudices: Prejudice[];
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  prejudicesTotalValues: Partial<Record<PrejudiceType, PrejudiceTotalValue[]>>;
  isVictimeIndirecte: boolean;
  isWrittenPrint: boolean;
}): (Paragraph | Table | TableOfContents)[] | undefined => {
  const prejudicesPerCategory = getPrejudicesPerCategoryPerDirectEntries({
    procedure,
    prejudices,
    victime,
    victimesIndirectes,
  })[isVictimeIndirecte ? 'INDIRECTE' : 'DIRECTE'];
  return (prejudicesPerCategory || []).reduce(
    (
      accumulator: (Paragraph | Table | TableOfContents)[],
      [category, prejudices],
      index,
    ) => {
      if (index === 0 && isVictimeIndirecte) {
        accumulator.push(
          ...[
            getParagraph({
              children: getTextRun({
                text: i18n.t('prejudice.directe.INDIRECTE'),
                bold: true,
                size: 24,
                allCaps: true,
              }),
            }),
          ],
        );
      }
      const prejudicesPrints = prejudices.reduce(
        (
          prejudicesPrint: (Paragraph | Table | TableOfContents)[],
          prejudice,
        ) => {
          const availablePrintTypesForPrejudiceType =
            getAvailablePrintTypesForPrejudiceType({
              prejudiceType: prejudice.type,
              formData: prejudice.formData,
            });
          const prejudicePrint =
            isWrittenPrint &&
            availablePrintTypesForPrejudiceType.includes('written')
              ? makePrejudiceWrittenPrint({
                  victime,
                  procedure,
                  prejudice,
                  monetaryErosions,
                  baremes,
                  PGPFReliquat,
                  victimesIndirectes,
                  prejudicesTotalValues,
                })
              : makePrejudicePrint({
                  victime,
                  procedure,
                  prejudice,
                  monetaryErosions,
                  baremes,
                  PGPFReliquat,
                  victimesIndirectes,
                  prejudicesTotalValues,
                });
          if (prejudicePrint && prejudicePrint.length > 0) {
            prejudicesPrint.push(...[getEmptyLine(2), ...prejudicePrint]);
          }
          return prejudicesPrint;
        },
        [],
      );
      if (prejudices.length > 0 && prejudicesPrints.length > 0) {
        accumulator.push(
          getEmptyLine(),
          new Paragraph({
            children: getTextRun({
              text: i18n.t(`prejudice.categories.${category}`),
              bold: true,
              size: 24,
              underline: { color: '#000000', type: UnderlineType.SINGLE },
              break:
                isVictimeIndirecte && index === 0
                  ? 2
                  : accumulator.length > 0
                    ? 3
                    : 0,
              allCaps: true,
            }),
          }),
        );
        accumulator.push(...prejudicesPrints);
      }
      return accumulator;
    },
    [],
  );
};
