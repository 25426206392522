import i18next from 'i18next';
import { getParagraph, getTextRun } from './docxFunctions';
import { ExternalHyperlink, UnderlineType } from 'docx';
import { conditionsLink } from 'src/constants/global';

export const getCGAUTitle = () =>
  getParagraph({
    children: [
      ...getTextRun({
        text: i18next.t('print.CGAU.title'),
        bold: true,
        size: 24,
        underline: { color: '#000000', type: UnderlineType.SINGLE },
      }),
    ],
  });
export const getCGAUText = () => getParagraph(i18next.t('print.CGAU.text'));

export const getCGAULinkPrint = () =>
  getParagraph({
    children: [
      new ExternalHyperlink({
        link: conditionsLink,
        children: getTextRun({
          text: i18next.t('print.CGAU.linkText') || '',
          underline: { color: '#000000', type: UnderlineType.SINGLE },
          color: '#0000FF',
        }),
      }),
    ],
  });
