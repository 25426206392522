import React, { useMemo } from 'react';
import { CalculsGlobal } from '../../../constants/calculs';
import { TotalIndemniteGlobaleARepartirEchus } from './TotalIndemniteGlobaleARepartirEchus';

interface Props {
  totalResteACharge: number;
  priseEnChargeTiersPayeurs: Record<string, number>;
  partResponsabilite: number;
}

export const TotalReparti: React.FC<Props> = ({
  totalResteACharge,
  priseEnChargeTiersPayeurs,
  partResponsabilite,
}) => {
  const indemniteGlobaleARepartir = useMemo(
    () =>
      CalculsGlobal.getIndemnitesRepartie(
        totalResteACharge,
        priseEnChargeTiersPayeurs,
        partResponsabilite,
      ),
    [totalResteACharge, priseEnChargeTiersPayeurs, partResponsabilite],
  );
  return (
    <TotalIndemniteGlobaleARepartirEchus
      indemniteGlobaleARepartir={indemniteGlobaleARepartir}
      partResponsabilite={partResponsabilite}
    />
  );
};
