import { User } from './auth.type';
import { Organization, OrganizationMemberRole } from './organization.type';

export enum OrganizationInvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface OrganizationInvitation {
  _id: string;
  email: string;
  organization: Pick<Organization, '_id' | 'name'>;
  role: OrganizationMemberRole;
  expirationDate: string;
  sender: Pick<User, 'email' | 'firstName' | 'lastName'>;
  status: OrganizationInvitationStatus;
}
export type CreateOrganizationInvitationDto = Omit<
  OrganizationInvitation,
  '_id' | 'expirationDate' | 'organization' | 'status' | 'sender'
>;

export type UpdateOrganizationInvitationDto = Partial<
  Omit<CreateOrganizationInvitationDto, 'email'>
>;

export type AnswerOrganizationInvitationDto = {
  status: OrganizationInvitationStatus;
};
