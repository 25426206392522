import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

export interface ConfirmationDialogProps {
  title: string;
  description?: string;
  open: boolean;
  confirmText?: string | string[];
  cancelText?: string;
  onCancel: () => void;
  onConfirm: (() => void) | (() => void)[];
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  description,
  open,
  onCancel,
  onConfirm,
  maxWidth = 'xs',
  ...props
}) => {
  const { t } = useTranslation();
  const confirmText = props.confirmText ?? t('common.confirm');
  const cancelText = props.cancelText ?? t('common.cancel');
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth={maxWidth}
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      {description && <DialogContent dividers>{description}</DialogContent>}
      <DialogActions>
        <Button autoFocus onClick={onCancel} variant="text">
          {cancelText}
        </Button>
        {typeof confirmText === 'string' && (
          <Button
            onClick={onConfirm instanceof Array ? onConfirm[0] : onConfirm}
            variant="contained"
          >
            {confirmText}
          </Button>
        )}
        {confirmText instanceof Array &&
          confirmText.map((text, index) => (
            <Button
              onClick={
                onConfirm instanceof Array ? onConfirm[index] : onConfirm
              }
              variant="contained"
              key={index}
            >
              {text}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  );
};
