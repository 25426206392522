import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  Prejudice,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierValeur,
  PrejudiceFormListeProjection,
  PrejudiceFormMateriel,
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  PrejudiceFormValeurVictimesIndirectes,
  PrejudiceType,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  PrejudiceFormScolaire,
} from 'src/types/prejudice.type';
import {
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormValeur,
} from 'src/types/prejudice.type';
import { Paragraph, Table, TableOfContents } from 'docx';
import { valeurPrejudicePrint } from './prejudicesPrints/prejudices/valeurPrejudicePrint';
import { calendrierAssistancePrint } from './prejudicesPrints/prejudices/calendrierAssistancePrint';
import { Procedure } from 'src/types/procedure.type';
import { calendrierDepensePrint } from './prejudicesPrints/prejudices/calendrierDepensePrint';
import { calendrierAssistanceCapitalisationPrint } from './prejudicesPrints/prejudices/calendrierAssistanceCapitalisationPrint';
import { calendrierDepenseCapitalisationPrint } from './prejudicesPrints/prejudices/calendrierDepenseCapitalisationPrint';
import { calendrierValeursPrint } from './prejudicesPrints/prejudices/calendrierValeursPrint';
import { listeProjectionPrint } from './prejudicesPrints/prejudices/listeProjection/listeProjectionPrint';
import { Victime } from 'src/types/victime.type';
import { deficitFonctionnelTemporairePartielPrint } from './prejudicesPrints/prejudices/deficitFonctionnelTemporairePartielPrint';
import { deficitFonctionnelTemporaireTotalPrint } from './prejudicesPrints/prejudices/deficitFonctionnelTemporaireTotalPrint';
import { Bareme } from 'src/types/bareme.type';
import { perteGainsProfessionnelsActuelsPrint } from './prejudicesPrints/prejudices/perteDeGain/perteGainsProfessionnelsActuelsPrint';
import { perteGainsProfessionnelsFutursPrint } from './prejudicesPrints/prejudices/perteDeGain/perteGainsProfessionnelFutursPrint';
import { newPerteGainsProfessionnelsFutursPrint } from './prejudicesPrints/prejudices/perteDeGain/newPerteGainsProfessionnelFutursPrint';
import { newPerteGainsProfessionnelsActuelsPrint } from './prejudicesPrints/prejudices/perteDeGain/newPerteGainsProfessionnelsActuelsPrint';
import { calendrierMaterielPrint } from './prejudicesPrints/prejudices/calendrierMaterielPrint';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { PrejudiceTotalValue } from '../prejudices/total';
import { prejudiceTitlePrint } from './prejudicesPrints/prejudiceTitlePrint';
import { perteRevenusProcheVictimeDecedeePrint } from './prejudicesPrints/prejudices/perteRevenusProcheVictimeDecedeePrint';
import { perteRevenusProcheVictimeNonDecedeePrint } from './prejudicesPrints/prejudices/perteRevenusProcheVictimeNonDecedeePrint';
import { valeurVictimesIndirectesPrint } from './prejudicesPrints/prejudices/valeurVictimesIndirectesPrint';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { pgpaSaisieDirectePrint } from './prejudicesPrints/prejudices/perteDeGain/pgpaSaisieDirectePrint';
import { scolairePrint } from './prejudicesPrints/prejudices/scolairePrint';
import i18next from 'i18next';
import { pgpfSaisieDirectePrint } from './prejudicesPrints/prejudices/perteDeGain/pgpfSaisieDirectePrint';

export const makePrejudiceContentPrint = ({
  baremes,
  monetaryErosions,
  PGPFReliquat,
  prejudice,
  procedure,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  prejudice: Pick<Prejudice, 'formType' | 'formData' | 'type'>;
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  victimesIndirectes: VictimeIndirecte[];
}) => {
  switch (prejudice.formType) {
    case 'VALEUR': {
      const formData = prejudice.formData as PrejudiceFormValeur;
      return valeurPrejudicePrint({ prejudice, formData, procedure });
    }
    case 'CALENDRIER_ASSISTANCE': {
      const formData = prejudice.formData as
        | PrejudiceFormCalendrierAssistance
        | PrejudiceFormCalendrierAssistanceCapitalisation;
      return calendrierAssistancePrint({
        formData,
        tiersPayeurs: procedure.tiersPayeurs,
        monetaryErosions,
        procedure,
      });
    }
    case 'CALENDRIER_DEPENSE': {
      const formData = prejudice.formData as PrejudiceFormCalendrierDepense;
      return calendrierDepensePrint({
        procedure,
        prejudice,
        monetaryErosions,
        formData,
        tiersPayeurs: procedure.tiersPayeurs,
        victimesIndirectes,
      });
    }
    case 'LISTE_PROJECTION': {
      const formData = prejudice.formData as PrejudiceFormListeProjection;
      return listeProjectionPrint({
        prejudice,
        formData,
        PGPFReliquat,
        baremes,
        procedure,
        victime,
      });
    }
    case 'CALENDRIER_ASSISTANCE_CAPITALISATION': {
      const formData =
        prejudice.formData as PrejudiceFormCalendrierAssistanceCapitalisation;
      return calendrierAssistanceCapitalisationPrint({
        victime,
        formData,
        tiersPayeurs: procedure.tiersPayeurs,
        monetaryErosions,
        procedure,
        baremes,
      });
    }
    case 'CALENDRIER_DEPENSE_CAPITALISATION': {
      const formData =
        prejudice.formData as PrejudiceFormCalendrierDepenseCapitalisation;
      return calendrierDepenseCapitalisationPrint({
        procedure,
        prejudice,
        monetaryErosions,
        formData,
        tiersPayeurs: procedure.tiersPayeurs,
        baremes,
        victimesIndirectes,
        victime,
      });
    }
    case 'CALENDRIER_VALEURS': {
      const formData = prejudice.formData as PrejudiceFormCalendrierValeur;
      return calendrierValeursPrint({
        procedure,
        monetaryErosions,
        prejudice,
        formData,
        partResponsabilite: procedure.partResponsabilite,
      });
    }
    case 'MATERIEL': {
      const formData = prejudice.formData as PrejudiceFormMateriel;
      return calendrierMaterielPrint({
        procedure,
        monetaryErosions,
        prejudice,
        formData,
      });
    }
    case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL': {
      let formData = prejudice.formData as
        | OldPrejudiceFormPerteGainProfessionnelsActuel
        | NewPrejudiceFormPerteGainProfessionnelsActuel
        | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
      switch (formData.formType) {
        case 'DECLARATION_FISCALES': {
          formData = formData as OldPrejudiceFormPerteGainProfessionnelsActuel;
          return perteGainsProfessionnelsActuelsPrint(
            procedure,
            formData,
            monetaryErosions,
          );
        }
        case 'REVENUS': {
          formData = formData as NewPrejudiceFormPerteGainProfessionnelsActuel;
          return newPerteGainsProfessionnelsActuelsPrint(
            procedure,
            formData,
            monetaryErosions,
          );
        }
        case 'SAISIE_DIRECTE': {
          formData =
            formData as PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
          return pgpaSaisieDirectePrint(procedure, formData);
        }
        default:
          return [];
      }
    }
    case 'PERTE_GAINS_PROFESSIONNELS_FUTURS': {
      let formData = prejudice.formData as
        | OldPrejudiceFormPerteGainProfessionnelsFuturs
        | NewPrejudiceFormPerteGainProfessionnelsFuturs
        | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
      switch (formData.formType) {
        case 'DECLARATION_FISCALES': {
          formData =
            prejudice.formData as OldPrejudiceFormPerteGainProfessionnelsFuturs;
          return perteGainsProfessionnelsFutursPrint(
            victime,
            procedure,
            formData,
            baremes,
            monetaryErosions,
          );
        }
        case 'REVENUS': {
          formData =
            prejudice.formData as NewPrejudiceFormPerteGainProfessionnelsFuturs;
          return newPerteGainsProfessionnelsFutursPrint(
            victime,
            procedure,
            formData,
            baremes,
            monetaryErosions,
          );
        }
        case 'SAISIE_DIRECTE': {
          formData =
            prejudice.formData as PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
          return pgpfSaisieDirectePrint({
            formData,
            procedure,
            baremes,
            victime,
          });
        }
        default:
          return [];
      }
    }
    case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL': {
      const formData =
        prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel;
      return deficitFonctionnelTemporairePartielPrint({
        procedure,
        prejudice,
        formData,
      });
    }
    case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL': {
      const formData =
        prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal;
      return deficitFonctionnelTemporaireTotalPrint(
        procedure,
        prejudice,
        formData,
      );
    }
    case 'PERTES_REVENUS_PROCHE': {
      if (victime.dateDeces) {
        return perteRevenusProcheVictimeDecedeePrint({
          formData: prejudice.formData as PrejudiceFormPerteRevenusProche,
          procedure,
          prejudice,
          monetaryErosions,
          victime,
          victimesIndirectes,
          PGPFReliquat,
          baremes,
          dateLiquidation: procedure.dateLiquidation
            ? new Date(procedure.dateLiquidation)
            : undefined,
        });
      } else {
        return perteRevenusProcheVictimeNonDecedeePrint({
          formData:
            prejudice.formData as PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
          procedure,
          monetaryErosions,
          dateLiquidation: procedure.dateLiquidation
            ? new Date(procedure.dateLiquidation)
            : undefined,
        });
      }
    }
    case 'VALEUR_VICTIMES_INDIRECTES': {
      return valeurVictimesIndirectesPrint({
        formData: prejudice.formData as PrejudiceFormValeurVictimesIndirectes,
        victimesIndirectes,
        victime,
        procedure,
      });
    }
    case 'SCOLAIRE': {
      return scolairePrint({
        formData: prejudice.formData as PrejudiceFormScolaire,
        partResponsabilite: procedure.partResponsabilite,
      });
    }
    default:
      return undefined;
  }
};

export const makePrejudicePrint = ({
  baremes,
  monetaryErosions,
  PGPFReliquat,
  prejudicesTotalValues,
  prejudice,
  procedure,
  victime,
  victimesIndirectes,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudice: Pick<Prejudice, 'formType' | 'formData' | 'type'>;
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  prejudicesTotalValues: Partial<Record<PrejudiceType, PrejudiceTotalValue[]>>;
}): (Paragraph | Table | TableOfContents)[] | undefined => {
  try {
    const total = prejudicesTotalValues[prejudice.type];
    const prejudiceContent = makePrejudiceContentPrint({
      baremes,
      monetaryErosions,
      PGPFReliquat,
      prejudice,
      procedure,
      victimesIndirectes,
      victime,
    });
    return [
      prejudiceTitlePrint(prejudice, total ?? []),
      ...(prejudiceContent ? [...prejudiceContent] : []),
    ];
  } catch (e) {
    console.error(
      i18next.t('print.prejudice.error', {
        prejudiceName: i18next.t(
          `prejudice.prejudicesTypes.${prejudice.type}.title`,
        ),
        prejudice: JSON.stringify(prejudice, null, 2),
        error: e?.toString(),
      }),
    );
    return undefined;
  }
};
