import { Paragraph, Table, TableOfContents } from 'docx';
import {
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceRow,
  PrejudiceFormType,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { fDate, fDays, fHours } from 'src/helpers/formatTime';
import { Time } from 'src/constants/time';
import {
  CalculsFormCalendrierAssistance,
  CalculsGlobal,
} from 'src/constants/calculs';
import {
  getMontantRevaloriseWrittenPrint,
  getRevalorisationIntroductionWrittenPrintIfRevalorise,
} from '../revalorisation.written.print';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { getPeriodeWrittenPrint } from '../periode.written.print';
import { indemniteGlobaleARepartirEchusWrittenPrint } from '../indemniteGlobaleARepartir/indemniteGlobaleARepartirEchus.written.print';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { displayFormula } from '../formula';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';
import { getMontantRevalorisePrint } from '../../prejudicesPrints/revalorisationPrint';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';
import { getPeriodePrint } from '../../prejudicesPrints/periodePrint';
import { isPrejudiceRevalorise } from 'src/helpers/prejudices/revalorisation';

export default class ATPTWrittenPrintFunctions {
  static getJoursCongesText(
    row: PrejudiceFormCalendrierAssistanceRow,
    index: number,
    formData: PrejudiceFormCalendrierAssistance,
  ) {
    return row.joursConges && row.joursConges !== 365
      ? i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.joursConges',
          {
            joursConges: fDays(row.joursConges),
            totalJours: fDays(row.totalJours),
            formula:
              row.joursConges !== 365
                ? displayFormula({
                    formula: i18next.t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.totalJours.formula',
                      {
                        joursRetenus: fDays(row.joursRetenus),
                        joursConges: fDays(row.joursConges),
                        daysInYear: fDays(Time.daysInYear),
                      },
                    ),
                    options: {
                      withParentheses: true,
                    },
                    editedFieldsParameters: {
                      formData,
                      fieldName: `rows.${index}.totalJours`,
                    },
                  })
                : '',
          },
        )
      : null;
  }

  static getNombreHeureParUniteChoisieText(
    row: PrejudiceFormCalendrierAssistanceRow,
    index: number,
    formData: PrejudiceFormCalendrierAssistance,
    victime: Victime,
  ) {
    const divisor =
      CalculsFormCalendrierAssistance.getNombreHeureParJourDivisor({
        uniteNombreHeure: row.uniteNombreHeure,
      });
    return row.nombreHeureParUniteChoisie
      ? i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.nombreHeureParUniteChoisie',

          {
            victimeName: displayVictimeNameWrittenPrint(victime),
            nombreHeureParUniteChoisie: i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.nombreHeureParUniteChoisie.print.value',
              {
                nombreHeureParUniteChoisie: fDecimalNumber(
                  row.nombreHeureParUniteChoisie,
                ),
                uniteNombreHeure: row.uniteNombreHeure,
              },
            ),
            quantite: fHours(row.quantite),
            quantiteFormula: displayFormula({
              formula: i18next.t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.quantite.formula',
                {
                  nombreHeureParUniteChoisie: fHours(
                    row.nombreHeureParUniteChoisie,
                  ),
                  totalJours: fDays(row.totalJours),
                  divisor,
                  context: divisor === 1 ? 'divisorIsOne' : undefined,
                },
              ),
              options: {
                withParentheses: true,
              },
              editedFieldsParameters: {
                formData,
                fieldName: `rows.${index}.quantite`,
              },
            }),
          },
        )
      : null;
  }

  static getTypeAssistanceText(row: PrejudiceFormCalendrierAssistanceRow) {
    return row.typeAssistance || row.forfaitHoraire
      ? `${i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.typeAssistance',
          {
            typeAssistance: row.typeAssistance
              ? ` (${i18next.t(
                  `prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.typeAssistance.options.${row.typeAssistance}`,
                  row.typeAssistance || '',
                )})`
              : '',
            forfaitHoraire: fCurrency(row.forfaitHoraire),
          },
        )}`
      : null;
  }

  static getTiersPayeursParagraphs(row: PrejudiceFormCalendrierAssistanceRow) {
    return row.priseEnChargeTiersPayeurs &&
      row.priseEnChargeTiersPayeurs.filter(({ montant }) => !!montant).length >
        0
      ? [
          getParagraph(
            i18next.t(
              'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.priseEnChargeTiersPayeurs.firstPart',
            ),
          ),
          ...row.priseEnChargeTiersPayeurs
            .filter(({ montant }) => !!montant)
            .map(({ tiersPayeur, montant }) =>
              getParagraph({
                numbering: {
                  reference: 'simple-list',
                  level: 0,
                },
                children: [
                  ...getTextRun(
                    i18next.t(
                      'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.priseEnChargeTiersPayeurs.row',
                      {
                        tiersPayeur,
                        montant: fCurrency(montant),
                      },
                    ),
                  ),
                ],
              }),
            ),
        ]
      : [];
  }

  static getDeductionFiscaleText(row: PrejudiceFormCalendrierAssistanceRow) {
    return row.deductionFiscale
      ? `${i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.deductionFiscale',

          {
            deductionFiscale: fCurrency(row.deductionFiscale),
          },
        )}`
      : null;
  }

  static getAutresDeductionsText(row: PrejudiceFormCalendrierAssistanceRow) {
    return row.autresDeductions
      ? `${i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.autresDeductions',

          {
            autresDeductions: fCurrency(row.autresDeductions),
          },
        )}`
      : null;
  }

  static getResteAChargeText(
    row: PrejudiceFormCalendrierAssistanceRow,
    victime: Victime,
    procedure: Procedure,
    monetaryErosions: MonetaryErosion[],
    formData: PrejudiceFormCalendrierAssistance,
  ) {
    return row.resteACharge
      ? `${i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.resteACharge',
          {
            victimeName: displayVictimeNameWrittenPrint(victime),
            resteACharge: fCurrency(row.resteACharge),
            revalorisation:
              row.montantsDejaRevalorises === false
                ? getMontantRevaloriseWrittenPrint({
                    anneeOrDateLiquidation:
                      procedure.dateLiquidation ?? undefined,
                    anneeOrDateMontant: row.dateJustificatif ?? undefined,
                    monetaryErosions,
                    coefficientsType: formData.revalorisationCoefficientsType,
                    montant: row.resteACharge,
                  })
                : '',
          },
        )}`
      : null;
  }

  static getRecapitulatif({
    formData,
    totaux,
    procedure,
    monetaryErosions,
    isCapitalisation,
    formType,
  }: {
    formData: PrejudiceFormCalendrierAssistance;
    totaux: ReturnType<typeof CalculsFormCalendrierAssistance.totaux>;
    procedure: Procedure;
    monetaryErosions: MonetaryErosion[];
    isCapitalisation?: true;
    formType: PrejudiceFormType;
  }): (Table | Paragraph | TableOfContents)[] {
    const isRevalorise = isPrejudiceRevalorise({
      formType,
      formData,
    });
    const totalDeductionFiscale = CalculsGlobal.sum(
      Object.values(formData.rows.map((row) => row.deductionFiscale || 0)),
    );

    const totalAutresDeductions = CalculsGlobal.sum(
      Object.values(formData.rows.map((row) => row.autresDeductions || 0)),
    );
    return [
      simpleHorizontalTablePrint(
        [
          '',
          i18next.t(
            'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.montantTotal.columnHeader',
          ),
          ...procedure.tiersPayeurs.map((tiersPayeur) =>
            i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.priseEnChargeTiersPayeurs.columnHeader',
              {
                tiersPayeur,
              },
            ),
          ),
          ...(totalDeductionFiscale > 0
            ? [
                i18next.t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.deductionFiscale.columnHeader',
                ),
              ]
            : []),
          ...(totalAutresDeductions > 0
            ? [
                i18next.t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.autresDeductions.columnHeader',
                ),
              ]
            : []),
          isRevalorise
            ? i18next.t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.resteACharge.print.revalorise.columnHeader',
              )
            : i18next.t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.resteACharge.columnHeader',
              ),
          ...(isCapitalisation
            ? [
                isRevalorise
                  ? i18next.t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.annualiseMontantTotal.print.revalorise.columnHeader',
                    )
                  : i18next.t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.annualiseMontantTotal.columnHeader',
                    ),
              ]
            : []),
        ],
        [
          ...formData.rows.map((row) => ({
            rowLabel: getPeriodePrint({
              dateDebut: row.dateDebut,
              dateFin: row.dateFin,
              duree: row.totalJours,
              unite: row.uniteNombreHeure,
            }),
            columns: [
              fCurrency(row.montantTotal),
              ...row.priseEnChargeTiersPayeurs.map(({ montant }) =>
                fCurrency(montant),
              ),
              ...(totalDeductionFiscale > 0
                ? [fCurrency(row.deductionFiscale)]
                : []),
              ...(totalAutresDeductions > 0
                ? [fCurrency(row.autresDeductions)]
                : []),
              getMontantRevalorisePrint({
                montant: row.resteACharge,
                montantsDejaRevalorises: row.montantsDejaRevalorises,
                anneeOrDateMontant: row.dateJustificatif || undefined,
                monetaryErosions,
                anneeOrDateLiquidation: procedure.dateLiquidation || undefined,
                displayStar: true,
                coefficientsType: formData.revalorisationCoefficientsType,
              }),
              ...(isCapitalisation
                ? [
                    getMontantRevalorisePrint({
                      montant: row.annualiseMontantTotal || 0,
                      montantsDejaRevalorises: row.montantsDejaRevalorises,
                      anneeOrDateMontant: row.dateJustificatif || undefined,
                      monetaryErosions,
                      anneeOrDateLiquidation:
                        procedure.dateLiquidation || undefined,
                      displayStar: true,
                      coefficientsType: formData.revalorisationCoefficientsType,
                    }),
                  ]
                : []),
            ],
          })),
          {
            rowLabel: i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.total.totalRow.rowHeader',
            ),
            columns: [
              fCurrency(totaux.montantTotal.total.value),
              ...Object.values(totaux.priseEnChargeTiersPayeurs).map(
                ({ total }) => fCurrency(total.value),
              ),
              ...(totalDeductionFiscale > 0
                ? [fCurrency(totalDeductionFiscale)]
                : []),
              ...(totalAutresDeductions > 0
                ? [fCurrency(totalAutresDeductions)]
                : []),
              fCurrency(totaux.resteACharge.total.value),
              ...(isCapitalisation
                ? [fCurrency(totaux.montantTotalAnnualise.value)]
                : []),
            ],
          },
        ],
      ),
      getParagraph(i18next.t('prejudice.revalorisation.print.footnote')),
    ];
  }
}

export const atptWrittenPrint = ({
  victime,
  procedure,
  formData,
  monetaryErosions,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormCalendrierAssistance;
  monetaryErosions: MonetaryErosion[];
}): (Table | TableOfContents | Paragraph)[] => {
  const getRowParagraphs = (
    row: PrejudiceFormCalendrierAssistanceRow,
    index: number,
  ): Paragraph[] => {
    const periodeText = i18next.t(
      'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.firstPart',
      {
        numerosPieces: displayNumerosPiecesWrittenPrint(
          row.numerosPieces,
          'Parentheses',
        ),
        victimeName: displayVictimeNameWrittenPrint(victime),
        commentairesWithParentheses: row.commentaires
          ? ` (${row.commentaires})`
          : '',
        periode: getPeriodeWrittenPrint({
          dateDebut: row.dateDebut,
          dateFin: row.dateFin,
          duree: row.joursPeriode,
          options: { spaceBefore: true },
        }),
      },
    );
    const joursHospitalisationADeduireText = row.joursHospitalisationADeduire
      ? `${i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.joursHospitalisationADeduire.firstPart',
          {
            joursHospitalisationADeduire: fDays(
              row.joursHospitalisationADeduire,
            ),
          },
        )} ${
          row.deduireJoursHospitalisation
            ? i18next.t(
                'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.joursHospitalisationADeduire.deduireJoursHospitalisation',
                {
                  joursRetenus: fDays(row.joursRetenus),
                  formula: displayFormula({
                    formula: i18next.t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.joursRetenus.formula',
                      {
                        joursPeriode: fDays(row.joursPeriode),
                        joursHospitalisationADeduire: fDays(
                          row.joursHospitalisationADeduire,
                        ),
                      },
                    ),
                    options: {
                      withParentheses: true,
                    },
                    editedFieldsParameters: {
                      formData,
                      fieldName: `rows.${index}.joursRetenus`,
                    },
                  }),
                },
              )
            : i18next.t(
                'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.joursHospitalisationADeduire.noDeduireJoursHospitalisation',
              )
        }`
      : null;
    const joursCongesText = ATPTWrittenPrintFunctions.getJoursCongesText(
      row,
      index,
      formData,
    );
    const nombreHeureParUniteChoisieText =
      ATPTWrittenPrintFunctions.getNombreHeureParUniteChoisieText(
        row,
        index,
        formData,
        victime,
      );
    const typeAssistance = ATPTWrittenPrintFunctions.getTypeAssistanceText(row);
    const montantTotal = row.montantTotal
      ? `${i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.montantTotal',

          {
            montantTotal: fCurrency(row.montantTotal),
            formula: displayFormula({
              formula: i18next.t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.montantTotal.formula',
                {
                  quantite: fHours(row.quantite),
                  forfaitHoraire: fCurrency(row.forfaitHoraire),
                },
              ),
              options: {
                withParentheses: true,
              },
              editedFieldsParameters: {
                formData,
                fieldName: `rows.${index}.montantTotal`,
              },
            }),
          },
        )}`
      : null;

    const tiersPayeursTextParagraphs =
      ATPTWrittenPrintFunctions.getTiersPayeursParagraphs(row);

    const deductionFiscaleText =
      ATPTWrittenPrintFunctions.getDeductionFiscaleText(row);

    const autresDeductionsText =
      ATPTWrittenPrintFunctions.getAutresDeductionsText(row);

    const resteAChargeText = ATPTWrittenPrintFunctions.getResteAChargeText(
      row,
      victime,
      procedure,
      monetaryErosions,
      formData,
    );
    return [
      getParagraph(periodeText),
      ...(joursHospitalisationADeduireText
        ? [getEmptyLine(), getParagraph(joursHospitalisationADeduireText)]
        : []),
      ...(joursCongesText ? [getParagraph(joursCongesText)] : []),
      ...(nombreHeureParUniteChoisieText
        ? [getParagraph(nombreHeureParUniteChoisieText)]
        : []),
      ...(typeAssistance ? [getEmptyLine(), getParagraph(typeAssistance)] : []),
      ...(montantTotal ? [getEmptyLine(), getParagraph(montantTotal)] : []),
      ...(tiersPayeursTextParagraphs.length > 0
        ? [getEmptyLine(), ...tiersPayeursTextParagraphs]
        : []),
      ...(deductionFiscaleText ? [getParagraph(deductionFiscaleText)] : []),
      ...(autresDeductionsText ? [getParagraph(autresDeductionsText)] : []),
      ...(resteAChargeText ? [getParagraph(resteAChargeText)] : []),
    ];
  };
  const totaux = CalculsFormCalendrierAssistance.totaux({
    rows: formData.rows,
    partResponsabilite: procedure.partResponsabilite,
    tiersPayeurs: procedure.tiersPayeurs,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    monetaryErosions,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });
  const indemniteRepartie = CalculsGlobal.getIndemnitesRepartie(
    totaux.resteACharge.total.value,
    Object.entries(totaux.priseEnChargeTiersPayeurs).reduce(
      (
        accumulator,
        [
          tiersPayeur,
          {
            total: { value },
          },
        ],
      ) => ({
        ...accumulator,
        [tiersPayeur]: value,
      }),
      {},
    ),
    procedure.partResponsabilite,
  );

  const recapitulatif = ATPTWrittenPrintFunctions.getRecapitulatif({
    formData,
    totaux,
    procedure,
    monetaryErosions,
    formType: 'CALENDRIER_ASSISTANCE',
  });
  const totalPriseEnChargeTiersPayeurs = CalculsGlobal.sum(
    Object.values(totaux.priseEnChargeTiersPayeurs).map(
      ({ total }) => total.value,
    ),
  );
  const nonZeroRows = formData.rows.filter(
    (row) => !!row.montantTotal || !!row.resteACharge,
  );
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      formType: 'CALENDRIER_ASSISTANCE',
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      formData,
    }),
    ...nonZeroRows
      .map((row, index) => [
        getParagraph({
          numbering: {
            reference: 'numbers-dot-simple',
            level: 0,
          },
          children: getTextRun({
            text: i18next.t(
              'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.rowTitle',
              {
                dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
                dateFin: row.dateFin ? fDate(row.dateFin) : '',
              },
            ),
          }),
        }),
        ...getRowParagraphs(row, index),
        ...(index !== formData.rows.length - 1 ? [getEmptyLine()] : []),
      ])
      .flat(),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.total.print.title',
        ),
      }),
    }),
    getEmptyLine(),
    ...recapitulatif,
    getEmptyLine(),
    ...indemniteGlobaleARepartirEchusWrittenPrint({
      indemniteRepartie,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      victime,
      indemniteGlobaleFormula:
        totaux.resteACharge.total.value && totalPriseEnChargeTiersPayeurs
          ? i18next.t(
              'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.indemniteGlobaleARepartir.indemniteGlobale.formula',
              {
                totalResteACharge: fCurrency(totaux.resteACharge.total.value),
                totalPriseEnChargeTiersPayeurs: fCurrency(
                  totalPriseEnChargeTiersPayeurs,
                ),
                partResponsabilite: fPartResponsabilite(
                  procedure.partResponsabilite * 100,
                ),
                count: procedure.partResponsabilite === 1 ? 1 : 2,
                context:
                  totaux.resteACharge.total.value === 0
                    ? 'noResteACharge'
                    : totalPriseEnChargeTiersPayeurs === 0
                      ? 'noPriseEnChargeTiersPayeurs'
                      : undefined,
              },
            )
          : '',
      firstPart: i18next.t(
        'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE.writtenPrint.indemniteGlobaleARepartir.firstPart',
      ),
    }),
  ];
};
