import React from 'react';
import {
  UpdateVictimeRolesDto,
  Victime,
  VictimeUserRole,
} from 'src/types/victime.type';
import { LayoutTable } from '../styled';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  OrganizationMember,
  OrganizationMemberRole,
} from 'src/types/organization.type';
import { RadioFormField } from '../forms/RadioFieldForm';
import { useFieldArray, useForm } from 'react-hook-form';
import { Delete } from '@mui/icons-material';
import { sortVictimeMembers } from 'src/helpers/victime';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { useAuthenticatedUser } from 'src/hooks/store/auth.hooks';

interface Props {
  searchQuery: string;
  victime: Victime;
  onUpdateVictimeRoles: (data: UpdateVictimeRolesDto['roles']) => void;
}

type FormData = {
  roles: {
    member: OrganizationMember;
    role: VictimeUserRole | null;
  }[];
};

export const UpdateVictimeRolesTable: React.FC<Props> = ({
  searchQuery,
  victime,
  onUpdateVictimeRoles,
}) => {
  const { t } = useTranslation();
  const authenticatedUser = useAuthenticatedUser();
  const members = useDisplayedOrganization().organization?.members ?? [];
  const { control, formState, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      roles: members.map((member) => {
        const userRole = Object.entries(victime.roles ?? {}).find(
          ([userId]) => userId === member._id,
        )?.[1];
        return {
          member,
          role: userRole ? userRole : null,
        };
      }),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'roles',
  });
  const searchMembersByQuery = (query: string) => {
    const regex = new RegExp(query, 'i');
    return sortVictimeMembers(victime, members).reduce(
      (accumulator, member) => ({
        ...accumulator,
        [member._id]: Boolean(
          (member.firstName && regex.test(member.firstName)) ||
            (member.lastName && regex.test(member.lastName)) ||
            regex.test(member.email),
        ),
      }),
      {} as Record<string, boolean>,
    );
  };
  const filteredMembersIds: Record<string, boolean> =
    searchMembersByQuery(searchQuery);
  const onSubmit = (data: FormData) => {
    const roles = data.roles
      .filter((role) => !!role.role)
      .map((role) => ({
        userId: role.member._id,
        role: role.role as VictimeUserRole,
      }));
    if (formState.isDirty) {
      onUpdateVictimeRoles(roles);
    }
  };
  return (
    <Stack
      component="form"
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ overflowY: 'hidden' }}
      flex={1}
    >
      <Box height="100%" sx={{ overflowY: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '100%' }}>
          <LayoutTable>
            <TableBody>
              {fields.map(
                (
                  {
                    member: { role: organizationRole, ...user },
                    role: victimeRole,
                  },
                  index,
                ) => (
                  <TableRow
                    key={index}
                    sx={{
                      display: filteredMembersIds[user._id]
                        ? undefined
                        : 'none',
                    }}
                  >
                    <TableCell sx={{ maxWidth: '150px' }}>
                      <Typography
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >{`${user.firstName} ${user.lastName}`}</Typography>
                      <Typography
                        variant="caption"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {user.email}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {organizationRole === OrganizationMemberRole.admin ||
                      organizationRole === OrganizationMemberRole.owner ? (
                        <Typography
                          color="primary"
                          marginLeft={2}
                          textAlign="center"
                        >
                          {t(
                            `organization.fields.role.options.${organizationRole}.label`,
                          )}
                        </Typography>
                      ) : (
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="end"
                          flexWrap="nowrap"
                        >
                          <RadioFormField
                            control={control}
                            name={`roles.${index}.role`}
                            value={victimeRole}
                            options={Object.values(VictimeUserRole).map(
                              (role) => ({
                                label: t(
                                  `victime.fields.roles.options.${role}`,
                                ),
                                value: role,
                                labelPlacement: 'bottom',
                                disabled: user._id === authenticatedUser?._id,
                              }),
                            )}
                            row
                            sx={{ flexWrap: 'nowrap' }}
                          />
                        </Stack>
                      )}
                    </TableCell>
                    <TableCell>
                      {user._id !== authenticatedUser?._id &&
                      organizationRole === OrganizationMemberRole.member ? (
                        <IconButton
                          onClick={() =>
                            setValue(`roles.${index}.role`, null, {
                              shouldDirty: true,
                            })
                          }
                        >
                          <Delete />
                        </IconButton>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </LayoutTable>
        </TableContainer>
      </Box>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ alignSelf: 'flex-end' }}
        disabled={!formState.isDirty}
      >
        {t('forms.save')}
      </Button>
    </Stack>
  );
};
