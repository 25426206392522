import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import {
  DataGrid,
  frFR,
  enUS,
  GridRowParams,
  GridActionsCellItem,
  GridColDef,
} from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'src/components/basic/Flex';
import { CustomLoadingOverlay } from 'src/components/dataGrid/CustomLoadingOverlay';
import { CustomNoRowsOverlay } from 'src/components/dataGrid/CustomNoRowsOverlay';
import { useAppDispatch } from 'src/hooks/store';
import { handleNotification } from 'src/helpers/notifications';
import { useSnackbar } from 'notistack';
import { CreateOrUpdateInvitationDialog } from 'src/components/admin/CreateOrUpdateInvitationDialog';
import {
  createInvitation,
  deleteInvitation,
  resendInvitation,
  updateInvitation,
} from 'src/slices/invitation';
import {
  CreateInvitationDto,
  Invitation,
  UpdateInvitationDto,
} from 'src/types/invitation.type';
import { Delete, Send } from '@mui/icons-material';
import { omit } from 'lodash';

type GridRowType = Omit<Invitation, 'expirationDate'> & {
  id: string;
  expirationDate: Date;
};

interface Props {
  invitations: Invitation[];
}
export const AdminInvitations: React.FC<Props> = ({ invitations }) => {
  const dispatch = useAppDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t, i18n } = useTranslation();

  const [clickedInvitationId, setClickedInvitationId] = useState<string | null>(
    null,
  );

  const [deleteInvitationDialog, setDeleteInvitationDialog] =
    useState<string>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [invitationDialogOpen, setInvitationDialogOpen] =
    useState<boolean>(false);

  const clickedInvitation = useMemo(
    () =>
      invitations.find((invitation) => invitation._id === clickedInvitationId),
    [invitations, clickedInvitationId],
  );

  const columns: GridColDef<GridRowType>[] = [
    { field: 'email', headerName: t('common.email'), flex: 1 },
    { field: 'company', headerName: t('common.company'), flex: 0.5 },
    {
      field: 'role',
      headerName: t('common.role'),
    },

    {
      field: 'expirationDate',
      headerName: t('pages.Admin.invitations.expirationDate'),
      type: 'date',
      flex: 0.5,
      renderCell: ({ value }) => (
        <Tooltip
          title={
            value < new Date()
              ? t('pages.Admin.invitations.expirationDateTooltip')
              : ''
          }
        >
          <Box color={value < new Date() ? 'red' : undefined}>
            {value.toLocaleDateString(i18n.language, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={0}
          icon={<Send />}
          label={t('pages.Admin.invitations.resend')}
          onClick={() => onResendInvitation(params.id as string)}
        />,
        <GridActionsCellItem
          key={1}
          icon={<Delete />}
          label={t('common.delete')}
          onClick={() => adminDeleteInvitation(params.id as string)}
        />,
      ],
    },
  ];

  const rows: GridRowType[] = invitations.map((invitation) => ({
    ...invitation,
    id: invitation._id,
    expirationDate: new Date(invitation.expirationDate),
  }));

  const onResendInvitation = async (invitationId: string) => {
    await dispatch(resendInvitation({ invitationId }));
    enqueueSnackbar(t('pages.Admin.successResendInvitation'), {
      variant: 'success',
    });
  };

  const adminDeleteInvitation = (invitationId: string) => {
    setDeleteInvitationDialog(invitationId);
    setOpenDeleteDialog(true);
  };

  const dialogAdminDeleteInvitation = async () => {
    if (deleteInvitationDialog) {
      await dispatch(
        deleteInvitation({ invitationId: deleteInvitationDialog }),
      );
      setOpenDeleteDialog(false);
      setDeleteInvitationDialog('');
    }
  };

  const update = async (
    invitationId: string,
    updateInvitationDto: UpdateInvitationDto,
  ) => {
    await handleNotification(
      dispatch(
        updateInvitation({
          invitationId,
          data: updateInvitationDto,
        }),
      ),
      t('pages.Admin.successUpdateInvitation'),
      t('pages.Admin.errorUpdateInvitation'),
      enqueueSnackbar,
      closeSnackbar,
    );
  };

  const create = async (createInvitationDto: CreateInvitationDto) => {
    dispatch(createInvitation({ data: createInvitationDto }));
  };
  const onSubmitCreateOrUpdateInvitation = async (
    createInvitationDto: CreateInvitationDto,
  ) => {
    setInvitationDialogOpen(false);
    if (clickedInvitationId) {
      await update(clickedInvitationId, omit(createInvitationDto, ['email']));
    } else {
      await create(createInvitationDto);
    }
  };
  const onClickRow = (params: GridRowParams<GridRowType>) => {
    setClickedInvitationId(params.row._id);
    setInvitationDialogOpen(true);
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        sx={{
          cursor: 'default',
          boxShadow:
            'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, \
           &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
        }}
        localeText={
          i18n.language === 'en'
            ? enUS.components.MuiDataGrid.defaultProps.localeText
            : frFR.components.MuiDataGrid.defaultProps.localeText
        }
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: () => (
            <CustomNoRowsOverlay text={t('pages.Admin.noInvitation')} />
          ),
          NoResultsOverlay: CustomNoRowsOverlay,
        }}
        loading={false}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        onRowClick={onClickRow}
        pageSizeOptions={[10, 20, 50]}
      />
      <Flex
        sx={{
          marginTop: 3,
          marginBottom: 1,
          justifyContent: 'flex-end',
          marginX: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setInvitationDialogOpen(true);
          }}
        >
          {t('pages.Admin.sendInvitation')}
        </Button>
      </Flex>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDeleteInvitationDialog('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('pages.Admin.confirmDeleteInvitationTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('pages.Admin.confirmDeleteInvitation')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setDeleteInvitationDialog('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogAdminDeleteInvitation()} autoFocus>
            {t('common.delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <CreateOrUpdateInvitationDialog
        invitation={clickedInvitation}
        open={invitationDialogOpen}
        onClose={() => {
          setInvitationDialogOpen(false);
          setClickedInvitationId('');
        }}
        onSubmit={onSubmitCreateOrUpdateInvitation}
      />
    </Box>
  );
};
