import {
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  PerteGainProfessionnelsActuelSituation,
} from 'src/types/prejudice.type';
import { CalculsFormPerteGainsProfessionnelsActuel } from './calculsFormPerteGainsProfessionnelsActuel';
import { CalculsGlobal } from './calculsGlobal';
import {
  IndemniteRepartieAEchoir,
  PerteGainProfessionnelsTotauxSituations,
} from './type';
import { PGPFReliquat } from './type';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';

export abstract class CalculsFormPerteGainsProfessionnelsFuturs {
  static getTotalCapitalise(
    sommeACapitaliser: number | string,
    coefficientCapitalisation: number | string,
  ): number {
    return Number(sommeACapitaliser) * Number(coefficientCapitalisation);
  }

  static getIndemnitesRepartie({
    situations,
    victimeTotalCapitalise,
    tiersPayeursTotalCapitalise,
    partResponsabilite,
    tiersPayeurs,
    capitalisationTiersPayeurs,
    dateConsolidation,
    dateLiquidation,
    dateDeces,
  }: Pick<
    OldPrejudiceFormPerteGainProfessionnelsFuturs,
    | 'situations'
    | 'victimeTotalCapitalise'
    | 'tiersPayeursTotalCapitalise'
    | 'capitalisationTiersPayeurs'
  > & {
    partResponsabilite: number;
    tiersPayeurs: string[];
    dateConsolidation: Date | undefined;
    dateLiquidation: Date | undefined;
    dateDeces: Date | undefined;
  }): IndemniteRepartieAEchoir & {
    totalIndemniteNetPartResponsable: number;
    PGPFReliquat: PGPFReliquat;
  } {
    if (!situations) {
      return {
        indemniteGlobaleARepartir: {
          solde: 0,
          beforePartResponsabilite: 0,
        },
        indemniteVictime: {
          arreragesEchus: {
            debit: 0,
            solde: 0,
          },
          arreragesAEchoir: {
            debit: 0,
            solde: 0,
          },
          total: 0,
        },
        indemniteTiersPayeurs: {
          arreragesEchus: {
            debit: 0,
            solde: 0,
            parTiersPayeur: [],
            totalNonReparti: 0,
          },
          arreragesAEchoir: {
            debit: 0,
            solde: 0,
            parTiersPayeur: [],
            totalNonReparti: 0,
          },
          total: 0,
        },
        totalIndemniteNetPartResponsable: 0,
        PGPFReliquat: {
          echus: null,
          aEchoir: null,
          total: null,
          deduitsDuTropPercu: {
            echus: null,
            aEchoir: null,
          },
        },
      };
    }
    const totaux =
      CalculsFormPerteGainsProfessionnelsActuel.getTotauxSituations(
        situations,
        partResponsabilite,
      );
    return this.getIndemnitesRepartieSecondStep({
      totaux,
      victimeTotalCapitalise,
      tiersPayeursTotalCapitalise,
      partResponsabilite,
      totalIndemnitesParTiersPayeurs:
        CalculsFormPerteGainsProfessionnelsActuel.getTotalIndemnitesParTiersPayeurs(
          situations,
          tiersPayeurs,
        ),
      capitalisationTiersPayeurs,
      tiersPayeurs,
      dateConsolidation,
      dateLiquidation,
      dateDeces,
    });
  }

  static getIndemnitesRepartieSecondStep({
    totaux,
    partResponsabilite,
    totalIndemnitesParTiersPayeurs,
    capitalisationTiersPayeurs,
    tiersPayeurs,
    dateConsolidation,
    dateLiquidation,
    dateDeces,
    ...props
  }: Pick<
    OldPrejudiceFormPerteGainProfessionnelsFuturs,
    | 'tiersPayeursTotalCapitalise'
    | 'victimeTotalCapitalise'
    | 'capitalisationTiersPayeurs'
  > & {
    totaux: PerteGainProfessionnelsTotauxSituations;
    partResponsabilite: number;
    totalIndemnitesParTiersPayeurs: ReturnType<
      typeof CalculsFormPerteGainsProfessionnelsActuel.getTotalIndemnitesParTiersPayeurs
    >;
    tiersPayeurs: string[];
    dateConsolidation: Date | undefined;
    dateLiquidation: Date | undefined;
    dateDeces: Date | undefined;
  }): IndemniteRepartieAEchoir & {
    totalIndemniteNetPartResponsable: number;
    PGPFReliquat: PGPFReliquat;
  } {
    const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
      dateConsolidation,
      dateLiquidation,
      dateDeces,
    });
    const victimeTotalCapitalise = shouldNotDisplayCapitalisation
      ? 0
      : props.victimeTotalCapitalise;
    const tiersPayeursTotalCapitalise = shouldNotDisplayCapitalisation
      ? 0
      : props.tiersPayeursTotalCapitalise;
    const indemnitesGlobaleARepartirBeforePartResponsabilite =
      totaux.perteDeGain.value + victimeTotalCapitalise;
    const indemnitesGlobaleARepartir =
      totaux.perteDeGain.partResponsabilite +
      victimeTotalCapitalise * partResponsabilite;
    /* Preference victime */
    const victimeEchus = CalculsGlobal.min([
      indemnitesGlobaleARepartir,
      totaux.arreragesVictime.value,
    ]);
    const nonNegativeVictimeEchus = CalculsGlobal.max([victimeEchus, 0]);
    const victimeAEchoir = CalculsGlobal.min([
      victimeTotalCapitalise - Number(tiersPayeursTotalCapitalise),
      indemnitesGlobaleARepartir - nonNegativeVictimeEchus,
    ]);
    const nonNegativeVictimeAEchoir =
      victimeAEchoir < 0
        ? 0
        : victimeEchus < 0
          ? victimeEchus + victimeAEchoir
          : victimeAEchoir;

    const victimeTotal = victimeAEchoir + victimeEchus;
    const nonNegativeVictimeTotal = CalculsGlobal.max([victimeTotal, 0]);
    const victimeAEchoirAfterDeduitsTropPercus =
      victimeTotal < 0 ? 0 : nonNegativeVictimeAEchoir;
    const victimeEchusAfterDeduitsTropPercus =
      victimeTotal < 0 && nonNegativeVictimeEchus > 0
        ? 0
        : nonNegativeVictimeEchus;
    const tiersPayeursTotal =
      victimeTotal < 0
        ? indemnitesGlobaleARepartir
        : indemnitesGlobaleARepartir - victimeTotal;
    const tiersPayeursEchus = CalculsGlobal.min([
      tiersPayeursTotal,
      totaux.indemniteNet.value,
    ]);
    const tiersPayeursAEchoir = tiersPayeursTotal - tiersPayeursEchus;
    const PGPFReliquat: PGPFReliquat = {
      echus: victimeEchus < 0 ? victimeEchus : null,
      aEchoir: victimeAEchoir < 0 ? victimeAEchoir : null,
      total: victimeTotal < 0 ? victimeTotal : null,
      deduitsDuTropPercu: {
        echus:
          victimeEchusAfterDeduitsTropPercus !== nonNegativeVictimeEchus
            ? victimeEchus
            : null,
        aEchoir:
          victimeAEchoirAfterDeduitsTropPercus !== nonNegativeVictimeAEchoir
            ? victimeAEchoir
            : null,
      },
    };
    const tiersPayeursCapitaliseSum = shouldNotDisplayCapitalisation
      ? 0
      : CalculsGlobal.sum(
          tiersPayeurs.map(
            (tiersPayeur) =>
              capitalisationTiersPayeurs.parTiersPayeur.find(
                ({ tiersPayeur: tiersPayeurCapitalise }) =>
                  tiersPayeurCapitalise === tiersPayeur,
              )?.montantCapitalise || 0,
          ),
        );
    const arreragesAEchoirParTiersPayeur = tiersPayeurs.map((tiersPayeur) => {
      const montantCapitalise = shouldNotDisplayCapitalisation
        ? 0
        : capitalisationTiersPayeurs.parTiersPayeur.find(
            ({ tiersPayeur: tiersPayeurCapitalise }) =>
              tiersPayeurCapitalise === tiersPayeur,
          )?.montantCapitalise || 0;
      return {
        tiersPayeur,
        montant:
          (tiersPayeursAEchoir * montantCapitalise) /
          (tiersPayeursCapitaliseSum || 1),
        montantNonReparti: montantCapitalise,
      };
    });
    return {
      indemniteGlobaleARepartir: {
        solde: indemnitesGlobaleARepartir,
        beforePartResponsabilite:
          indemnitesGlobaleARepartirBeforePartResponsabilite,
      },
      indemniteVictime: {
        arreragesEchus: {
          debit: victimeEchusAfterDeduitsTropPercus,
          solde: 0,
        },
        arreragesAEchoir: {
          debit: victimeAEchoirAfterDeduitsTropPercus,
          solde: 0,
        },
        total: nonNegativeVictimeTotal,
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          debit: tiersPayeursEchus,
          solde: 0,
          parTiersPayeur: Object.entries(totalIndemnitesParTiersPayeurs).map(
            ([key, value]) => ({
              tiersPayeur: key,
              montant:
                (tiersPayeursEchus * value.netPaye) /
                (totaux.indemniteNet.value || 1),
              montantNonReparti: value.netPaye,
            }),
            {},
          ),
          totalNonReparti: CalculsGlobal.sum(
            Object.values(totalIndemnitesParTiersPayeurs).map(
              (value) => value.netPaye || 0,
            ),
          ),
        },
        arreragesAEchoir: {
          debit: tiersPayeursAEchoir,
          solde: 0,
          parTiersPayeur: arreragesAEchoirParTiersPayeur,
          totalNonReparti: tiersPayeursCapitaliseSum,
        },
        total: tiersPayeursTotal,
      },
      totalIndemniteNetPartResponsable: totaux.indemniteNet.partResponsabilite,
      PGPFReliquat,
    };
  }

  static getVictimeSommeACapitaliser(
    situations: PerteGainProfessionnelsActuelSituation[],
    partResponsabilite: number,
  ): number {
    const {
      revenuDeReference: { value },
    } = CalculsFormPerteGainsProfessionnelsActuel.getTotauxSituations(
      situations,
      partResponsabilite,
    );
    return value;
  }
}
