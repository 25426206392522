import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { IndemniteRepartieEchus } from 'src/constants/calculs/type';
import { fCurrency } from 'src/helpers/formatNumber';
import { getEmptyLine, getParagraph, getTextRun } from '../docxFunctions';
import { simpleVerticalTablePrint } from '../simpleVerticalTablePrint';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const debitSoldeEchusPrint = ({
  title,
  indemniteGlobaleARepartir,
  partResponsabilite,
  displayTitle = true,
}: {
  title?: string;
  indemniteGlobaleARepartir: Omit<
    IndemniteRepartieEchus,
    'indemniteTiersPayeurs'
  > &
    Partial<Pick<IndemniteRepartieEchus, 'indemniteTiersPayeurs'>>;
  partResponsabilite: number;
  displayTitle?: boolean;
}): (Table | TableOfContents | Paragraph)[] => [
  ...(displayTitle
    ? [
        getParagraph({
          children: getTextRun({
            text:
              title ??
              i18next.t(
                'prejudice.indemniteGlobaleARepartir.echus.print.title',
              ),
            bold: true,
          }),
        }),
        getEmptyLine(),
      ]
    : []),
  simpleVerticalTablePrint([
    {
      label: i18next.t(
        'prejudice.indemniteGlobaleARepartir.echus.table.indemniteGlobale.print.tableRowHeader',
        {
          partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
        },
      ),
      value: fCurrency(
        indemniteGlobaleARepartir.indemniteGlobaleARepartir.solde,
      ),
    },
    {
      label: i18next.t(
        'prejudice.indemniteGlobaleARepartir.echus.table.indemniteVictime.tableRowHeader',
      ),
      value: fCurrency(
        indemniteGlobaleARepartir.indemniteVictime.arreragesEchus.debit,
      ),
      bold: true,
    },
    ...(indemniteGlobaleARepartir.indemniteTiersPayeurs
      ? [
          {
            label: i18next.t(
              'prejudice.indemniteGlobaleARepartir.echus.table.indemniteTiersPayeurs.tableRowHeader',
            ),
            value: fCurrency(
              indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesEchus
                .debit,
            ),
            bold: true,
          },
          ...(
            indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesEchus
              .parTiersPayeur || []
          ).map(
            ({ tiersPayeur, montant }) =>
              ({
                label: tiersPayeur,
                value: fCurrency(montant),
              }) as const,
          ),
        ]
      : []),
  ]),
];
