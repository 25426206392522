import { Paragraph, Table, TableOfContents } from 'docx';
import {
  Prejudice,
  PrejudiceFormListeProjection,
  PGPFReliquatAndPGPFReliquatActivationStatus,
} from 'src/types/prejudice.type';
import { deficitFonctionnelPermanentPrint } from '../deficitFonctionnelPermanentPrint';
import { prejudiceAgrementPrint } from './prejudiceAgrementPrint';
import { prejudiceMaladiePrint } from './prejudiceMaladiePrint';
import { prejudiceNotesPrint } from '../../prejudiceNotesPrint';
import { fCurrency } from 'src/helpers/formatNumber';
import { prejudiceFormTable } from '../../prejudiceFormTablePrint';
import { prejudicesData } from 'src/constants/prejudices';
import { getEmptyLine, getParagraph, getTextRun } from '../../../docxFunctions';
import { prorataTemporisPrint } from '../../prorataTemporisPrint';
import { incidenceProfessionnellePrint } from './incidenceProfessionnellePrint';
import { isListeProjectionTotalDisplayed } from 'src/helpers/prejudices/prejudice';
import i18next from 'i18next';
import {
  CalculsFormListeProjection,
  CalculsGlobal,
} from 'src/constants/calculs';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { Bareme } from 'src/types/bareme.type';
import { fPartResponsabilite } from 'src/helpers/formatValues';

const listeProjectionTablePrint = ({
  prejudice,
  formData,
  partResponsabilite,
  PGPFReliquat,
  victime,
  procedure,
  baremes,
}: {
  prejudice: Pick<Prejudice, 'type'>;
  formData: PrejudiceFormListeProjection;
  partResponsabilite: number;
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  victime: Victime;
  procedure: Procedure;
  baremes: Bareme[];
}): (Paragraph | Table | TableOfContents)[] | undefined => {
  switch (prejudice.type) {
    case 'PREJUDICE_SEXUEL':
      return prejudiceFormTable(
        [formData],
        [
          {
            name: 'montantTotal',
            headerLabel: i18next.t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_SEXUEL.fields.listeProjection.montantTotal.print.columnHeader',
            ),
            render: (values) => fCurrency(values.montantTotal),
          },
        ],
      );
    case 'PREJUDICE_ESTHETIQUE_PERMANENT':
    case 'PREJUDICE_ESTHETIQUE_TEMPORAIRE':
    case 'SOUFFRANCES_ENDUREES':
      return prejudiceMaladiePrint({
        formData,
        prejudiceType: prejudice.type,
      });
    case 'PREJUDICE_AGREMENT':
    case 'AUTRES_POSTES_DE_PREJUDICES':
      return prejudiceAgrementPrint({
        formData,
      });
    case 'INCIDENCE_PROFESSIONNELLE':
      return incidenceProfessionnellePrint({
        formData,
        partResponsabilite,
        PGPFReliquat,
        victime,
        procedure,
        baremes,
      });
    default:
      return deficitFonctionnelPermanentPrint({
        formData,
      });
  }
};
export const listeProjectionPrint = ({
  prejudice,
  procedure,
  baremes,
  victime,
  formData,
  PGPFReliquat,
}: {
  prejudice: Pick<Prejudice, 'type'>;
  formData: PrejudiceFormListeProjection;
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  procedure: Procedure;
  victime: Victime;
  baremes: Bareme[];
}): (Paragraph | Table | TableOfContents)[] => {
  const partResponsabilite = procedure.partResponsabilite;
  const table = listeProjectionTablePrint({
    prejudice,
    formData,
    partResponsabilite,
    PGPFReliquat,
    victime,
    procedure,
    baremes,
  });
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const displayTotalPartResponsabilite =
    prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
      .displayTotalPartResponsabilite;

  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...(table ? table : []),
    getEmptyLine(),
    ...(isListeProjectionTotalDisplayed({
      prejudiceType: prejudice.type,
    })
      ? [
          getParagraph({
            children: getTextRun({
              text: displayTotalPartResponsabilite
                ? i18next.t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.fields.montantTotal.print.rowPartResponsabilite',
                    {
                      value: fCurrency(
                        formData.montantTotal * procedure.partResponsabilite,
                      ),
                      partResponsabilite: fPartResponsabilite(
                        procedure.partResponsabilite * 100,
                      ),
                    },
                  )
                : i18next.t(
                    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.fields.montantTotal.print.row',
                    {
                      total: fCurrency(formData.montantTotal),
                    },
                  ),
            }),
          }),
        ]
      : []),
    ...(!prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
      .shouldNotDisplayProrataTemporis && formData.enableProrataTemporisDeces
      ? [
          getEmptyLine(),
          ...prorataTemporisPrint({
            coefficient:
              formData.coefficientCapitalisationAgeConsolidation || 0,
            amount: formData.montantProratise || 0,
            age:
              victime.dateNaissance && procedure.dateConsolidation
                ? CalculsGlobal.getAgeOrZero(
                    victime.dateNaissance,
                    procedure.dateConsolidation,
                  )
                : undefined,
            bareme: baremes.find(
              (bareme) => bareme._id === formData.baremeCapitalisation,
            ),
            anneeCapitalisation: formData.anneeCapitalisation,
            anneesEntreConsolidationEtDeces:
              procedure.dateConsolidation && victime.dateDeces
                ? CalculsFormListeProjection.getAnneesConsolideDeces(
                    procedure.dateConsolidation,
                    victime.dateDeces,
                  )
                : undefined,
          }),
        ]
      : []),
  ];
};
