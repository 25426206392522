import { Warning } from '@mui/icons-material';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCanUpdateVictime } from 'src/hooks/store/victime.hook';
import { Victime } from 'src/types/victime.type';

interface Props {
  sx?: SxProps;
  victime: Victime;
}

export const SavePrejudiceButton: React.FC<Props> = ({ sx, victime }) => {
  const { t } = useTranslation();
  const canUpdateVictime = useCanUpdateVictime(victime);
  if (canUpdateVictime) {
    return (
      <Button type="submit" variant="contained" sx={sx}>
        {t('forms.save')}
      </Button>
    );
  }
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Warning color="disabled" />
      <Typography variant="body2">
        {t('prejudice.noOrganizationPermissions.cantSave')}
      </Typography>
    </Stack>
  );
};
