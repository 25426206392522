import React, { useMemo, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  ModalProps,
  Stack,
  Typography,
  Box,
  IconButton,
  Dialog,
  Tooltip,
  Grid,
} from '@mui/material';
import { prejudicesData } from '../../constants/prejudices';
import { Procedure } from '../../types/procedure.type';
import {
  PrejudiceFormType,
  PrejudiceType,
  Prejudice,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormValues,
} from '../../types/prejudice.type';
import { useAppDispatch } from '../../hooks/store';
import {
  updatePrejudice,
  createPrejudice,
  deletePrejudice,
} from '../../slices/prejudice';
import { Victime } from '../../types/victime.type';
import { EssentialInformations } from './EssentialInformations';
import {
  CloseOutlined,
  Delete,
  Help,
  Terminal,
  Upload,
} from '@mui/icons-material';
import { updateProcedure } from '../../slices/procedure';
import {
  Control,
  FormProvider,
  UseFormReturn,
  useFormState,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrejudiceForm } from '../styled';
import { ClosePrejudiceDialog } from './ClosePrejudiceDialog';
import { ConfirmationDialog } from '../basic/ConfirmationDialog';
import { printPrejudiceFormSubmitError } from 'src/helpers/prejudices/form';
import i18next from 'i18next';
import { PrintPrejudiceDialog } from './PrintPrejudiceDialog';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Bareme } from 'src/types/bareme.type';
import { useCanUpdateVictime } from 'src/hooks/store/victime.hook';
import { HelpDialog } from '../help/HelpDialog';
interface Props<T extends PrejudiceFormValues> extends UseFormReturn<T> {
  prejudice?: Prejudice;
  prejudiceType: PrejudiceType;
  victime: Victime;
  procedure: Procedure;
  control: Control<T>;
  open: boolean;
  victimesIndirectes: VictimeIndirecte[];
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
  prejudices: Prejudice[];
  onClose: ModalProps['onClose'];
  renderPrejudice: ({
    setAdditionalTitle,
  }: {
    setAdditionalTitle: (title: string | undefined) => void;
  }) => React.ReactNode;
}

export const PrejudiceContainer = <T extends PrejudiceFormValues>({
  prejudice,
  prejudiceType,
  victime,
  procedure,
  open,
  control,
  victimesIndirectes,
  monetaryErosions,
  baremes,
  prejudices,
  renderPrejudice,
  handleSubmit,
  onClose,
  ...props
}: Props<T>) => {
  const { t } = useTranslation();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [printPrejudiceDialogValues, setPrintPrejudiceDialogValues] =
    useState<T>();
  const [additionalTitle, setAdditionalTitle] = useState<string | undefined>(
    undefined,
  );
  const [deletePrejudiceConfirmDialog, setDeletePrejudiceConfirmDialog] =
    useState(false);
  const dispatch = useAppDispatch();

  const closeDialog = () => {
    setAdditionalTitle(undefined);
    onClose && onClose({}, 'escapeKeyDown');
  };

  const onConfirmClose = () => {
    setConfirmationDialogOpen(false);
    closeDialog();
  };

  const { dirtyFields } = useFormState({ control });

  const onClickClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setConfirmationDialogOpen(true);
    } else {
      closeDialog();
    }
  };

  const onDeletePrejudice = () => {
    if (prejudice) {
      dispatch(
        deletePrejudice({
          procedureId: procedure._id,
          prejudiceId: prejudice._id,
        }),
      );
    }
    setDeletePrejudiceConfirmDialog(false);
    closeDialog();
  };

  const onClickDeletePrejudice = () => {
    setDeletePrejudiceConfirmDialog(true);
  };

  const onClickPrint = async () => {
    await handleSubmit(
      (values) => setPrintPrejudiceDialogValues(values),
      onError,
    )();
  };

  const onClickClosePrint = () => {
    setPrintPrejudiceDialogValues(undefined);
  };

  const onError = printPrejudiceFormSubmitError;

  const onSubmit = async (values: PrejudiceFormValues, close = false) => {
    if (prejudice) {
      dispatch(
        updatePrejudice({
          procedureId: procedure._id,
          prejudiceId: prejudice._id,
          data: { formData: values },
        }),
      );
    } else {
      dispatch(
        createPrejudice({
          procedureId: procedure._id,
          data: {
            formData: values,
            type: prejudiceType,
            formType: prejudicesData[prejudiceType]
              .inputType as PrejudiceFormType,
          },
        }),
      );
    }

    if (prejudiceType === 'DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL') {
      await dispatch(
        updateProcedure({
          procedureId: procedure._id,
          victimeId: victime._id,
          data: {
            hospitalisations: (
              values as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal
            ).rows.map((row) => {
              return {
                dateDebut: row.dateDebut,
                dateFin: row.dateFin,
                jours: row.totalJours,
                precisions: '',
                motifHospitalisation: row.motifHospitalisation || '',
              };
            }),
          },
        }),
      );
    }

    close && closeDialog();
  };

  const onSave = async () => {
    await handleSubmit((values) => onSubmit(values, true), onError)();
    setConfirmationDialogOpen(false);
  };

  const onLogPrejudiceContent = async () => {
    await handleSubmit((values) => {
      console.log(JSON.stringify(values, null, 2));
    }, onError)();
  };

  const titleTooltip: string = useMemo(() => {
    const translationString = `prejudice.prejudicesTypes.${prejudiceType}.titleTooltip`;
    if (i18next.exists(translationString)) {
      return i18next.t(translationString);
    } else {
      return '';
    }
  }, [prejudiceType, t]);
  const canUpdateVictime = useCanUpdateVictime(victime);

  const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
    e.preventDefault();

    setConfirmationDialogOpen(true);
  };

  const handleUnload = (e: Event): void => {
    e.preventDefault();

    setConfirmationDialogOpen(true);
  };

  const handlePopstate = (e: PopStateEvent): void => {
    e.preventDefault();

    window.history.pushState(null, '', window.location.href);

    setConfirmationDialogOpen(true);
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullWidth={
        prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES'].fullWidth
      }
      fullScreen={
        prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES'].fullScreen
      }
      onClose={onClickClose}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Stack direction="column" spacing={2}>
          <Grid container>
            <Grid item xs={2} display="flex" justifyContent="start">
              {canUpdateVictime ? (
                <IconButton onClick={onClickDeletePrejudice}>
                  <Delete />
                </IconButton>
              ) : null}
            </Grid>
            <Grid item xs={8}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ flex: 1 }}
                justifyContent="center"
              >
                <Typography variant="subtitle1">
                  {prejudiceType &&
                    t(`prejudice.prejudicesTypes.${prejudiceType}.title`)}
                  {additionalTitle && ` : ${additionalTitle}`}
                </Typography>
                {!!titleTooltip && (
                  <Tooltip
                    title={
                      <Box sx={{ maxWidth: '400px' }}>
                        <Typography
                          whiteSpace="pre-line"
                          maxHeight="400px"
                          overflow="auto"
                        >
                          {titleTooltip}
                        </Typography>
                      </Box>
                    }
                    placement="bottom-end"
                  >
                    <IconButton>
                      <Help />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="end">
              <HelpDialog prejudiceType={prejudiceType} icon />
              {process.env.NODE_ENV === 'development' ? (
                <IconButton onClick={onLogPrejudiceContent}>
                  <Terminal />
                </IconButton>
              ) : null}
              {canUpdateVictime ? (
                <IconButton onClick={onClickPrint}>
                  <Upload />
                </IconButton>
              ) : null}
              <IconButton onClick={onClickClose}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
          <EssentialInformations victime={victime} procedure={procedure} />
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: '500px',
          display: 'flex',
          padding: 0,
        }}
      >
        <FormProvider {...{ ...props, control, handleSubmit }}>
          <PrejudiceForm
            onSubmit={handleSubmit((values) => onSubmit(values, true), onError)}
          >
            {renderPrejudice({ setAdditionalTitle })}
          </PrejudiceForm>
        </FormProvider>
      </DialogContent>
      <ClosePrejudiceDialog
        open={confirmationDialogOpen}
        victime={victime}
        onCloseWithoutSaving={onConfirmClose}
        onSave={onSave}
        onCancel={() => setConfirmationDialogOpen(false)}
      />
      <ConfirmationDialog
        open={deletePrejudiceConfirmDialog}
        onCancel={() => setDeletePrejudiceConfirmDialog(false)}
        onConfirm={onDeletePrejudice}
        title={`Supprimer ${t(
          `prejudice.prejudicesTypes.${prejudiceType}.title`,
        )}`}
        description="Voulez-vous vraiment supprimer ce préjudice ?"
        confirmText="Supprimer"
        cancelText="Annuler"
      />
      <PrintPrejudiceDialog
        open={!!printPrejudiceDialogValues}
        onClose={onClickClosePrint}
        procedure={procedure}
        victime={victime}
        victimesIndirectes={victimesIndirectes}
        prejudices={prejudices}
        monetaryErosions={monetaryErosions}
        baremes={baremes}
        prejudiceId={prejudice?._id ?? ''}
        prejudiceType={prejudiceType}
        prejudiceFormType={
          prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES'].inputType
        }
        values={printPrejudiceDialogValues}
      />
    </Dialog>
  );
};
