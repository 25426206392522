import { Paragraph, Table, TableOfContents } from 'docx';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { getEmptyLine, getParagraph } from '../../../docxFunctions';
import i18next from 'i18next';
import { prpRecapitulatifTotalTablePrint } from '../../../prejudicesPrints/prejudices/perteRevenusProcheVictimeDecedeePrint';
import { Procedure } from 'src/types/procedure.type';
import { debitSoldeEchusIndirectePrint } from '../../../prejudicesPrints/debitSoldeEchusIndirectePrint';
import { CalculPerteRevenusProche } from 'src/constants/calculs/calculPerteRevenusProche';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { indemniteGlobaleARepartirIndirecteWrittenPrint } from '../../indemniteGlobaleARepartir/indemniteGlobaleARepartirIndirecte.written.print';

export const prpRecapitulatifWrittenPrint = ({
  formData,
  procedure,
  victimesIndirectes,
}: {
  formData: PrejudiceFormPerteRevenusProche;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] => {
  const partResponsabilite =
    formData.partResponsabilite ?? procedure.partResponsabilite;

  return [
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 0,
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.recapitulatif.title',
      ),
    }),
    getEmptyLine(),
    prpRecapitulatifTotalTablePrint({ formData, partResponsabilite }),
    getEmptyLine(),
    ...debitSoldeEchusIndirectePrint({
      indemniteGlobaleARepartir: CalculPerteRevenusProche.getIndemniteRepartie({
        tiersPayeurs: procedure.tiersPayeurs,
        victimesIndirectes,
        perteRevenusProche: formData,
        partResponsabilite,
      }),
      partResponsabilite,
      victimesIndirectes,
    }),
    getEmptyLine(),
    ...indemniteGlobaleARepartirIndirecteWrittenPrint({
      indemniteRepartie: CalculPerteRevenusProche.getIndemniteRepartie({
        tiersPayeurs: procedure.tiersPayeurs,
        victimesIndirectes,
        perteRevenusProche: formData,
        partResponsabilite,
      }),
      partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      victimesIndirectes,
    }),
  ];
};
