import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormNumeroPieceDialog } from 'src/components/client/prejudiceFormComponents/FormNumeroPieceDialog';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { TooltipTextFieldForm } from 'src/components/forms/TooltipTextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { LayoutTable, NormalTable, SpanNoWrap } from 'src/components/styled';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  getMontantRevalorise,
  getQuotientRevalorisationTooltip,
  getRevalorisationTooltipContent,
} from 'src/helpers/prejudices/revalorisation';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  NumeroPieceValuesRow,
  PrejudiceFormPerteRevenusProche,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';

interface RevenuProcheRowProps {
  control: Control<PrejudiceFormPerteRevenusProche>;
  fieldName:
    | 'victime.revenu'
    | 'conjoint.revenuAvantDeces'
    | 'conjoint.revenuApresDeces';
  label: string;
  signedMontant?: boolean;
  allNumerosPieces: NumeroPieceValuesRow[];
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
}

const RevenuProcheRow: React.FC<RevenuProcheRowProps> = ({
  control,
  fieldName,
  label,
  signedMontant = false,
  allNumerosPieces,
  procedure,
  monetaryErosions,
}) => (
  <>
    <TableRow>
      <TableCell sx={{ width: '100px' }}>
        <FormNumeroPieceDialog
          control={control}
          name={`${fieldName}.numerosPieces`}
          allOtherNumeroPieces={allNumerosPieces}
        />
      </TableCell>
      <TableCell sx={{ width: '400px' }}>{label}</TableCell>
      <TableCell align="center" sx={{ width: '100px' }}>
        <TooltipTextFieldForm
          control={control}
          name={`${fieldName}.montant`}
          watchFields={[
            `${fieldName}.revaloriseMontant`,
            `${fieldName}.dejaRevalorise`,
          ]}
          computeTooltip={([revaloriseMontant, dejaRevalorise]) =>
            getRevalorisationTooltipContent({
              montantRevalorise: revaloriseMontant,
              montantsDejaRevalorises: dejaRevalorise,
            })
          }
          InputProps={{
            inputComponent: MaskNumber as any,
            inputProps: {
              suffix: '€',
              numberMask: { scale: 2, signed: signedMontant },
            } as IMaskNumberProps,
          }}
        />
      </TableCell>
      <TableCell align="center" sx={{ width: '100px' }}>
        <TextFieldForm
          control={control}
          name={`${fieldName}.annee`}
          sx={{ input: { textAlign: 'right' } }}
          InputProps={{
            inputComponent: MaskNumber as any,
            inputProps: {
              numberMask: { scale: 0, thousandsSeparator: '' },
              nullable: true,
            } as IMaskNumberProps,
          }}
        />
      </TableCell>
      <TableCell align="center" sx={{ width: '50px' }}>
        <CheckboxFormField
          control={control}
          name={`${fieldName}.dejaRevalorise`}
        />
      </TableCell>
      <TableCell align="center">
        <ComputedReadFieldForm
          control={control}
          watchFields={[
            `${fieldName}.montant`,
            `${fieldName}.annee`,
            `${fieldName}.dejaRevalorise`,
            'revalorisationCoefficientsType',
          ]}
          name={`${fieldName}.revaloriseMontant`}
          compute={([
            montant,
            annee,
            dejaRevalorise,
            revalorisationCoefficientsType,
          ]) => {
            if (dejaRevalorise || !annee || !procedure.dateLiquidation) {
              return montant;
            } else {
              return (
                getMontantRevalorise({
                  montant,
                  anneeOrDateMontant: annee,
                  anneeOrDateLiquidation: procedure.dateLiquidation,
                  monetaryErosions,
                  coefficientsType: revalorisationCoefficientsType,
                }) || 0
              );
            }
          }}
          computeTooltip={([
            montant,
            annee,
            dejaRevalorise,
            revalorisationCoefficientsType,
          ]) =>
            annee && procedure.dateLiquidation && !dejaRevalorise && !montant
              ? getQuotientRevalorisationTooltip({
                  anneeOrDateMontant: annee,
                  anneeOrDateLiquidation: procedure.dateLiquidation,
                  coefficientsType: revalorisationCoefficientsType,
                  monetaryErosions,
                })
              : ''
          }
          render={(value) => <>{fCurrency(value)}</>}
        />
      </TableCell>
    </TableRow>
  </>
);

interface Props {
  control: Control<PrejudiceFormPerteRevenusProche>;
  allNumerosPieces: NumeroPieceValuesRow[];
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
}

export const PerteAnnuelleFoyer: React.FC<Props> = ({
  control,
  allNumerosPieces,
  procedure,
  monetaryErosions,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.avantDeces.title',
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell />
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.montant.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.annee.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.revalorisation.columnHeaders.montantDejaRevalorise',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.revaloriseMontant.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RevenuProcheRow
              control={control}
              fieldName="victime.revenu"
              label={t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.victime.revenu.label',
              )}
              allNumerosPieces={allNumerosPieces}
              procedure={procedure}
              monetaryErosions={monetaryErosions}
            />
            <RevenuProcheRow
              control={control}
              fieldName="conjoint.revenuAvantDeces"
              label={t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.revenuAvantDeces.label',
              )}
              allNumerosPieces={allNumerosPieces}
              procedure={procedure}
              monetaryErosions={monetaryErosions}
            />
          </TableBody>
        </NormalTable>
      </TableContainer>
      <TableContainer component={Paper}>
        <LayoutTable>
          <TableBody>
            <TableRow>
              <TableCell align="left" colSpan={2}>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.revenu.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedReadFieldForm
                  control={control}
                  name="foyer.revenuAnnuel"
                  watchFields={[
                    'victime.revenu.revaloriseMontant',
                    'conjoint.revenuAvantDeces.revaloriseMontant',
                  ]}
                  compute={([revenuVictime, revenuConjointAvantDeces]) => {
                    return (
                      (revenuVictime || 0) + (revenuConjointAvantDeces || 0)
                    );
                  }}
                  render={(value) => (
                    <SpanNoWrap>{fCurrency(value)}</SpanNoWrap>
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <TextFieldForm
                  control={control}
                  name="foyer.pourcentageConsomVictime"
                  label={t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.pourcentageConsomVictime.label',
                  )}
                  InputProps={{
                    inputComponent: MaskNumber as any,
                    inputProps: {
                      suffix: '%',
                      numberMask: { scale: 0 },
                      nullable: true,
                    } as IMaskNumberProps,
                    sx: {
                      width: '300px',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" colSpan={2}>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.deductionPartConsomVictime.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedReadFieldForm
                  control={control}
                  name="foyer.deductionPartConsomVictime"
                  watchFields={[
                    'foyer.revenuAnnuel',
                    'foyer.pourcentageConsomVictime',
                  ]}
                  compute={([revenuAnnuel, pourcentageConsomVictime]) =>
                    (revenuAnnuel || 0) -
                    ((revenuAnnuel || 0) * (pourcentageConsomVictime || 0)) /
                      100
                  }
                  render={(value) => (
                    <SpanNoWrap>{fCurrency(value)}</SpanNoWrap>
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </LayoutTable>
      </TableContainer>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ paddingTop: 4 }}>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.apresDeces.title',
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                {t('numeroPiece.form.columnHeader')}
              </TableCell>
              <TableCell />
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.montant.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.annee.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.revalorisation.columnHeaders.montantDejaRevalorise',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.revaloriseMontant.columnHeader',
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RevenuProcheRow
              control={control}
              fieldName="conjoint.revenuApresDeces"
              label={t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.revenuApresDeces.label',
              )}
              allNumerosPieces={allNumerosPieces}
              procedure={procedure}
              monetaryErosions={monetaryErosions}
            />
          </TableBody>
        </NormalTable>
      </TableContainer>
      <TableContainer>
        <LayoutTable>
          <TableBody>
            <TableRow>
              <TableCell align="left" colSpan={2}>
                {t(
                  'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.perteAnnuelle.tableRowHeader',
                )}
              </TableCell>
              <TableCell align="right">
                <ComputedReadFieldForm
                  control={control}
                  name="foyer.perteAnnuelle"
                  watchFields={[
                    'conjoint.revenuApresDeces.revaloriseMontant',
                    'foyer.deductionPartConsomVictime',
                  ]}
                  compute={([
                    revenuConjointApresDeces,
                    deductionPartConsomVictime,
                  ]) => {
                    return (
                      (deductionPartConsomVictime || 0) -
                      (revenuConjointApresDeces || 0)
                    );
                  }}
                  render={(value) => (
                    <SpanNoWrap>{fCurrency(value)}</SpanNoWrap>
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </LayoutTable>
      </TableContainer>
    </>
  );
};
