import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useCanUpdateVictime } from 'src/hooks/store/victime.hook';
import { Victime } from 'src/types/victime.type';

interface Props {
  open: boolean;
  victime: Victime;
  onCancel: () => void;
  onCloseWithoutSaving: () => void;
  onSave: () => void;
}

export const ClosePrejudiceDialog: React.FC<Props> = ({
  open,
  victime,
  onCancel,
  onCloseWithoutSaving,
  onSave,
}) => {
  const canUpdateVictime = useCanUpdateVictime(victime);
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435,
          minWidth: { xs: 0, md: 700 },
        },
      }}
      maxWidth="xs"
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>Fermer le formulaire</DialogTitle>
      <DialogContent dividers>
        Vos changements ne sont pas enregistrés.
        <br />
        Êtes-vous sûr de vouloir fermer ce formulaire ?
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">
          Annuler
        </Button>
        <Button onClick={onCloseWithoutSaving} variant="outlined">
          Quitter sans enregistrer
        </Button>
        {canUpdateVictime ? (
          <Button autoFocus onClick={onSave} variant="contained">
            Enregistrer
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
