import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

interface Props {
  onClick: () => void;
}

export const TrashCanCTA: React.FC<Props> = ({ onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOptionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        onClick={handleOptionClick}
        sx={{
          color: '#19A3FF',
          fontWeight: 'heavy',
        }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleOptionClose}>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onClick();
            setAnchorEl(null);
          }}
        >
          Accéder à la Corbeille
        </MenuItem>
      </Menu>
    </div>
  );
};
