import { Upload } from '@mui/icons-material';
import { Box, Fab, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridToolbar,
} from '@mui/x-data-grid';
import { capitalize } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { theme } from 'src/constants/theme';
import { fDate } from 'src/helpers/formatTime';
import { useAppSelector } from 'src/hooks/store';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import { useCanUpdateVictime } from 'src/hooks/store/victime.hook';
import { getAllMonetaryErosions } from 'src/slices/monetaryErosion';
import { RootState } from 'src/store';
import { Procedure } from 'src/types/procedure.type';
import { ConfirmationDialog } from '../../../components/basic/ConfirmationDialog';
import { ProcedureMenu } from '../../../components/client/ProcedureMenu';
import { TrashCanCTA } from '../../../components/TrashCanCTA';
import { duplicateProcedure, updateProcedure } from '../../../slices/procedure';
import { fetchAllVictimes } from '../../../slices/victime';
import { Victime } from '../../../types/victime.type';
import ProcedureTrashCanDialog from '../../ProcedureTrashCanDialog';
import { CreateProcedureDialog } from './CreateProcedureDialog';
import { TOpenEditProcedure } from './index';
import { PrintProceduresDialog } from './PrintProceduresDialog';
import { monetaryErosionSelectors } from 'src/store/selectors';

interface Props {
  victime: Victime;
  setOpenEditProcedure: React.Dispatch<
    React.SetStateAction<TOpenEditProcedure>
  >;
}

export type GridRowType = Pick<
  Procedure,
  | 'procedureType'
  | 'intitule'
  | 'dateRedaction'
  | 'dateExpertise'
  | 'dateLiquidation'
> & {
  id: string;
  procedure: Procedure;
};

export const Procedures: React.FC<Props> = ({
  victime,
  setOpenEditProcedure,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organization } = useDisplayedOrganization();
  const fetchVictimes = () => dispatch(fetchAllVictimes(organization?._id));
  const [openCreateProcedureDialog, setOpenCreateProcedureDialog] =
    useState(false);

  useEffect(() => {
    dispatch(getAllMonetaryErosions());
  }, []);

  const monetaryErosions = useAppSelector((state: RootState) =>
    monetaryErosionSelectors.selectAll(state),
  );

  const [isExportOpened, setIsExportOpened] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowType[]>([]);

  const [openProcedureTrashCan, setOpenProcedureTrashCan] = useState(false);

  const [procedureToDelete, setProcedureToDelete] = useState<string | null>(
    null,
  );
  const [deleteProcedureConfirmDialog, setDeleteProcedureConfirmDialog] =
    useState(false);

  const putProcedureInTrash = async () => {
    if (procedureToDelete) {
      dispatch(
        updateProcedure({
          victimeId: victime._id,
          procedureId: procedureToDelete,
          data: { inTrash: true },
        }),
      );
      setDeleteProcedureConfirmDialog(false);
      fetchVictimes();
    }
  };

  const canUpdateVictime = useCanUpdateVictime(victime);

  const onDuplicateProcedure = (procedureId: string) => {
    dispatch(
      duplicateProcedure({
        procedureId,
        victimeId: victime._id,
      }),
    );
  };

  const renderHeaderCell = (headerText: string) => (
    <Typography
      color="primary"
      fontWeight={600}
      variant="body2"
      sx={{
        wordBreak: 'break-word',
        whiteSpace: 'normal',
      }}
    >
      {headerText}
    </Typography>
  );

  const column: GridColDef<GridRowType>[] = [
    {
      field: 'procedureType',
      renderHeader: () =>
        renderHeaderCell(t('procedure.fields.procedureType.table.label')),
      width: 200,
      hideable: false,
      renderCell: (params) =>
        t(`procedure.fields.procedureType.options.${params.row.procedureType}`),
    },
    {
      field: 'intitule',
      renderHeader: () =>
        renderHeaderCell(t('procedure.fields.intitule.table.label')),
      flex: 1.5,
      hideable: false,
    },
    {
      field: 'dateRedaction',
      renderHeader: () =>
        renderHeaderCell(t('procedure.fields.dateRedaction.table.label')),
      width: 150,
      flex: 1,
      hideable: false,
      renderCell: (params) => {
        return params.row.dateRedaction ? fDate(params.row.dateRedaction) : '';
      },
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dateExpertise',
      renderHeader: () =>
        renderHeaderCell(t('procedure.fields.dateExpertise.table.label')),
      width: 150,
      flex: 1,
      hideable: false,
      renderCell: (params) => {
        return params.row.dateExpertise ? fDate(params.row.dateExpertise) : '';
      },
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dateLiquidation',
      renderHeader: () =>
        renderHeaderCell(
          capitalize(
            t('procedure.fields.dateLiquidation.table.label', {
              procedureType: 'LIQUIDATION',
            }),
          ),
        ),
      renderCell: (params) => {
        return params.row.dateLiquidation
          ? fDate(params.row.dateLiquidation)
          : '';
      },
      width: 150,
      flex: 1,
      hideable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
    },
    {
      field: 'action',
      renderHeader: () => (
        <TrashCanCTA onClick={() => setOpenProcedureTrashCan(true)} />
      ),
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => (
        <ProcedureMenu
          procedureId={params.row.id}
          setProcedureToDelete={setProcedureToDelete}
          setDeleteProcedureConfirmDialog={setDeleteProcedureConfirmDialog}
          onDuplicateProcedure={
            canUpdateVictime ? onDuplicateProcedure : undefined
          }
        />
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  const rows = useMemo(
    () =>
      victime.procedures
        ?.filter((procedure) => !procedure.inTrash)
        .map((procedure) => ({
          ...procedure,
          id: procedure._id,
          procedure,
        })) as GridRowType[],
    [victime.procedures],
  );

  return (
    <Box
      sx={{
        overflowY: 'auto',
        margin: theme.spacing(0, 2),
        flexGrow: 1,
        height: '0px',
      }}
    >
      <Stack
        flexDirection={'row-reverse'}
        marginBottom={-7}
        marginTop={2}
        marginRight={2}
      >
        {canUpdateVictime ? (
          <Fab
            sx={(theme) => ({
              color: '#ffffff',
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
              '&:disabled': {
                backgroundColor: '#e4e8eb',
              },
              height: '40px',
            })}
            variant="extended"
            onClick={() => setIsExportOpened(true)}
            disabled={selectedRows.length === 0}
          >
            Exporter
            <Upload />
          </Fab>
        ) : null}
      </Stack>
      <DataGrid
        rows={rows}
        columns={column}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        checkboxSelection
        disableRowSelectionOnClick
        onRowClick={(params) => navigate(`./procedures/${params.row.id}`)}
        sx={{
          border: 'none',
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell': {
            '&:focus': {
              outline: 'none',
            },
          },
          marginTop: 2,
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            sx: {
              flexDirection: 'row-reverse',
            },
            quickFilterProps: {
              placeholder: t('procedure.table.search.placeholder'),
              sx: {
                width: '300px',
              },
            },
          },
        }}
        onRowSelectionModelChange={(newSelection) => {
          const selectedRowsData = rows.filter((row) =>
            newSelection.includes(row.id),
          );
          setSelectedRows(selectedRowsData);
        }}
      />
      <Fab
        variant="extended"
        size="medium"
        sx={(theme) => ({
          color: '#ffffff',
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
          },
          position: 'fixed',
          bottom: 24,
          right: 24,
          '&:disabled': {
            backgroundColor: '#e4e8eb',
          },
        })}
        onClick={() => {
          if (canUpdateVictime) {
            setOpenEditProcedure({
              answers: [
                'INDEMNISATION_ADMINISTRATIVE',
                'ACCIDENT_DROIT_COMMUN',
              ],
            });
          } else {
            navigate('/subscription');
          }
        }}
        disabled={!canUpdateVictime && !!organization}
      >
        {canUpdateVictime
          ? t('procedure.requestButton.validSubscription')
          : !organization
            ? t('procedure.requestButton.invalidSubscription')
            : t('procedure.requestButton.noPermission')}
      </Fab>
      <ConfirmationDialog
        open={deleteProcedureConfirmDialog}
        onCancel={() => setDeleteProcedureConfirmDialog(false)}
        onConfirm={putProcedureInTrash}
        title={t('procedure.trash.dialog.title')}
        description={t('procedure.trash.dialog.description') || ''}
        confirmText={t('procedure.trash.dialog.confirm') || ''}
        cancelText={t('procedure.trash.dialog.cancel') || ''}
      />
      <PrintProceduresDialog
        victime={victime}
        open={isExportOpened}
        onClose={() => setIsExportOpened(false)}
        selectedProcedures={selectedRows}
        monetaryErosions={monetaryErosions || []}
      />
      <CreateProcedureDialog
        open={openCreateProcedureDialog}
        onClose={() => setOpenCreateProcedureDialog(false)}
        onFinish={(answers) => {
          setOpenCreateProcedureDialog(false);
          setOpenEditProcedure({ answers });
        }}
      />
      <ProcedureTrashCanDialog
        isSidebar={true}
        isOpen={openProcedureTrashCan}
        closeSideBar={() => setOpenProcedureTrashCan(false)}
      />
    </Box>
  );
};
