import {
  AlignmentType,
  HeightRule,
  Paragraph,
  Table,
  TableCell,
  TableOfContents,
  TableRow,
  VerticalAlign,
} from 'docx';
import {
  Prejudice,
  PrejudiceCategorie,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import {
  getPrejudicesTotal,
  getPrejudiceTotalSubAmounts,
  getTotalValuesSum,
  PrejudiceTotalName,
  PrejudiceTotalValue,
} from 'src/helpers/prejudices/total';
import { fCurrency } from '../formatNumber';
import {
  getEmptyLine,
  getParagraph,
  getTableCell,
  getTableRow,
  getTextRun,
} from './docxFunctions';
import i18n from 'i18next';
import { getSelectedPrejudicesTypesPerCategory } from 'src/helpers/prejudices/sortAndOrder';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import i18next from 'i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { flatMap } from 'lodash';
import { isPrejudiceVictimeIndirecte } from '../prejudices/prejudice';

export const makePrejudicesTotalPrint = ({
  procedure,
  prejudicesTotalValues,
  victime,
  victimesIndirectes,
  prejudices,
  dateLiquidation,
  monetaryErosions,
  isVictimeIndirecteTable,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudices: Prejudice[];
  dateLiquidation?: Date;
  monetaryErosions: MonetaryErosion[];
  isVictimeIndirecteTable?: boolean;
  prejudicesTotalValues: Partial<Record<PrejudiceType, PrejudiceTotalValue[]>>;
}): (Paragraph | Table | TableOfContents)[] | undefined => {
  const prejudicesTypesPerCategory = getSelectedPrejudicesTypesPerCategory({
    victime,
    procedure,
    victimesIndirectes,
    prejudices,
  });
  if (
    (!prejudicesTypesPerCategory.VICTIMES_INDIRECTES_PATRIMONIAUX &&
      !prejudicesTypesPerCategory.VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX &&
      isVictimeIndirecteTable) ||
    (!isVictimeIndirecteTable &&
      !Object.entries(prejudicesTypesPerCategory)
        .filter(([categorie, _]) => categorie !== 'VICTIMES_INDIRECTES')
        .some(([types]) => types.length > 0))
  ) {
    return [];
  }
  const totalNames: PrejudiceTotalName[] = isVictimeIndirecteTable
    ? ['indemniteProche', 'indemniteVictimeEchue', 'indemniteVictimeAEchoir']
    : [
        'indemniteVictimeEchue',
        'indemniteVictimeAEchoir',
        'indemniteTiersPayeurEchue',
        'indemniteTiersPayeurAEchoir',
      ];
  let table: Table;
  if (isVictimeIndirecteTable) {
    const totalValues = getPrejudicesTotal({
      victime,
      victimesIndirectes,
      procedure,
      prejudices: prejudices.filter((prejudice) =>
        isPrejudiceVictimeIndirecte(prejudice.type),
      ),
      dateLiquidation,
      monetaryErosions,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
    const totalSubAmountValues = getPrejudiceTotalSubAmounts(
      flatMap(Object.values(prejudicesTotalValues)),
    );
    const allSubAmountNames = Object.entries(prejudicesTotalValues).reduce(
      (accumulator, [_, values]) => {
        const subAmountNames = values.reduce((subAmounts, value) => {
          if (value.subAmounts) {
            subAmounts.push(
              ...value.subAmounts
                .map((subAmount) => subAmount.name)
                .filter(
                  (name) =>
                    !subAmounts.includes(name) && !accumulator.includes(name),
                ),
            );
          }
          return subAmounts;
        }, [] as string[]);
        return [...accumulator, ...subAmountNames];
      },
      [] as string[],
    );
    table = new Table({
      rows: [
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          tableHeader: true,
          children: [
            getTableCell({ children: [] }),
            ...allSubAmountNames.map((subAmountName) =>
              getTableCell({
                children: [
                  getParagraph({
                    children: getTextRun({
                      text: subAmountName,
                      bold: true,
                    }),
                  }),
                ],
              }),
            ),
            getTableCell({
              children: [
                getParagraph({
                  children: getTextRun({
                    text: i18next.t(
                      'print.prejudicesTotal.annexe.tiersPayeur.columnHeader',
                    ),
                    bold: true,
                  }),
                }),
              ],
            }),
            getTableCell({
              children: [
                getParagraph({
                  children: getTextRun({
                    text: i18next.t(
                      'print.prejudicesTotal.annexe.total.columnHeader',
                    ),
                    bold: true,
                  }),
                }),
              ],
            }),
          ],
        }),
        ...(Object.keys(prejudicesTypesPerCategory) as PrejudiceCategorie[])
          .filter(
            (category) =>
              category === 'VICTIMES_INDIRECTES_PATRIMONIAUX' ||
              category === 'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
          )
          .reduce(
            (accumulator: TableRow[], category) => [
              ...accumulator,
              getTableRow({
                children: [
                  getTableCell({
                    columnSpan: 7,
                    children: [
                      getParagraph({
                        children: getTextRun({
                          bold: true,
                          text: i18n.t(`prejudice.categories.${category}`, ''),
                        }),
                      }),
                    ],
                  }),
                ],
              }),
              ...(prejudicesTypesPerCategory[category] ?? []).map(
                (prejudiceType) => {
                  const totalValues = prejudicesTotalValues[prejudiceType];
                  const total = getTotalValuesSum(totalValues || []);
                  const totalTiersPayeurs = totalValues?.find(
                    (value) => value.name === 'indemniteTiersPayeurEchue',
                  )?.amount;
                  return new TableRow({
                    height: {
                      value: 400,
                      rule: HeightRule.ATLEAST,
                    },
                    children: [
                      new TableCell({
                        verticalAlign: VerticalAlign.CENTER,
                        children: [
                          new Paragraph({
                            children: getTextRun({
                              text: i18n.t(
                                `prejudice.prejudicesTypes.${prejudiceType}.title`,
                              ),
                            }),
                          }),
                        ],
                      }),
                      ...allSubAmountNames.map((name) => {
                        const amount: number | undefined = totalValues?.reduce(
                          (accumulator: number | undefined, value) => {
                            if (value.subAmounts) {
                              const subAmount = value.subAmounts.find(
                                (subAmount) => subAmount.name === name,
                              )?.amount;
                              if (subAmount) {
                                return subAmount;
                              }
                            }
                            return accumulator;
                          },
                          undefined,
                        );

                        return new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: getTextRun({
                                text:
                                  amount !== undefined ? fCurrency(amount) : '',
                              }),
                            }),
                          ],
                        });
                      }),
                      getTableCell({
                        verticalAlign: VerticalAlign.CENTER,
                        children: [
                          getParagraph({
                            alignment: AlignmentType.CENTER,
                            children: getTextRun({
                              text: totalTiersPayeurs
                                ? fCurrency(totalTiersPayeurs)
                                : '',
                            }),
                          }),
                        ],
                      }),
                      new TableCell({
                        verticalAlign: VerticalAlign.CENTER,
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: getTextRun({
                              text: total !== undefined ? fCurrency(total) : '',
                            }),
                          }),
                        ],
                      }),
                    ],
                  });
                },
              ),
            ],
            [],
          ),
        getTableRow({
          children: [
            getTableCell({
              children: [
                getParagraph({
                  children: getTextRun({
                    text: i18next.t(
                      'print.prejudicesTotal.annexe.total.rowHeader',
                    ),
                    bold: true,
                  }),
                }),
              ],
            }),
            ...allSubAmountNames.map((name) =>
              getTableCell({
                children: [
                  getParagraph({
                    alignment: AlignmentType.CENTER,
                    children: getTextRun({
                      text: fCurrency(totalSubAmountValues.proche[name] || 0),
                    }),
                  }),
                ],
              }),
            ),
            getTableCell({
              children: [
                getParagraph({
                  alignment: AlignmentType.CENTER,
                  children: getTextRun({
                    text: fCurrency(totalValues.tiersPayeurs || 0),
                  }),
                }),
              ],
            }),
            getTableCell({
              children: [
                getParagraph({
                  alignment: AlignmentType.CENTER,
                  children: getTextRun({
                    text: fCurrency(totalValues.total || 0),
                  }),
                }),
              ],
            }),
          ],
        }),
      ],
    });
  } else {
    table = new Table({
      rows: [
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          tableHeader: true,
          children: [
            getTableCell({ children: [] }),
            getTableCell({
              columnSpan: 2,
              children: [
                getParagraph({
                  children: getTextRun({
                    text: i18n.t(
                      'print.prejudicesTotal.annexe.victime.columnHeader',
                    ),
                    bold: true,
                  }),
                }),
              ],
            }),
            getTableCell({
              columnSpan: 2,
              children: [
                getParagraph({
                  children: getTextRun({
                    text: i18n.t(
                      'print.prejudicesTotal.annexe.tiersPayeur.columnHeader',
                    ),
                    bold: true,
                  }),
                }),
              ],
            }),
            getTableCell({
              children: [
                getParagraph({
                  children: getTextRun({
                    text: i18n.t(
                      'print.prejudicesTotal.annexe.total.columnHeader',
                    ),
                    bold: true,
                  }),
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          tableHeader: true,
          children: [
            getTableCell({ children: [] }),
            getTableCell({
              children: [
                getParagraph(
                  i18n.t('print.prejudicesTotal.annexe.echus.columnHeader'),
                ),
              ],
            }),
            getTableCell({
              children: [
                getParagraph(
                  i18n.t('print.prejudicesTotal.annexe.aEchoir.columnHeader'),
                ),
              ],
            }),
            getTableCell({
              children: [
                getParagraph(
                  i18n.t('print.prejudicesTotal.annexe.echus.columnHeader'),
                ),
              ],
            }),
            getTableCell({
              children: [
                getParagraph(
                  i18n.t('print.prejudicesTotal.annexe.aEchoir.columnHeader'),
                ),
              ],
            }),
            getTableCell({ children: [] }),
          ],
        }),
        ...(Object.keys(prejudicesTypesPerCategory) as PrejudiceCategorie[])
          .filter(
            (category) =>
              category !== 'VICTIMES_INDIRECTES_PATRIMONIAUX' &&
              category !== 'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
          )
          .reduce(
            (accumulator: TableRow[], category) => [
              ...accumulator,
              getTableRow({
                children: [
                  getTableCell({
                    columnSpan: 7,
                    children: [
                      getParagraph({
                        children: getTextRun({
                          bold: true,
                          text: i18n.t(`prejudice.categories.${category}`, ''),
                        }),
                      }),
                    ],
                  }),
                ],
              }),
              ...(prejudicesTypesPerCategory[category] ?? []).map(
                (prejudiceType) => {
                  const totalValues = prejudicesTotalValues[prejudiceType];
                  const total = getTotalValuesSum(totalValues || []);

                  return new TableRow({
                    height: {
                      value: 400,
                      rule: HeightRule.ATLEAST,
                    },
                    children: [
                      new TableCell({
                        verticalAlign: VerticalAlign.CENTER,
                        children: [
                          new Paragraph({
                            children: getTextRun({
                              text: i18n.t(
                                `prejudice.prejudicesTypes.${prejudiceType}.title`,
                              ),
                            }),
                          }),
                        ],
                      }),
                      ...totalNames.map((name) => {
                        const amount = prejudicesTotalValues[
                          prejudiceType
                        ]?.find((value) => value.name === name)?.amount;
                        return new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: getTextRun({
                                text:
                                  amount !== undefined ? fCurrency(amount) : '',
                              }),
                            }),
                          ],
                        });
                      }),
                      new TableCell({
                        verticalAlign: VerticalAlign.CENTER,
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: getTextRun({
                              text: total !== undefined ? fCurrency(total) : '',
                            }),
                          }),
                        ],
                      }),
                    ],
                  });
                },
              ),
            ],
            [],
          ),
      ],
    });
  }
  const title = new Paragraph({
    children: [
      ...getTextRun({
        text: isVictimeIndirecteTable
          ? i18n.t('print.prejudicesTotal.victimeIndirecteTitle')
          : i18n.t('print.prejudicesTotal.victimeDirecteTitle'),
        bold: true,
        size: 20,
        break: 2,
      }),
      getEmptyLine(2),
    ],
  });
  return [title, table];
};
