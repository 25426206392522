import React, { useState } from 'react';
import {
  Button,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  defaultValues: string[];
}

export const DefaultValuesTextFieldForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  defaultValues,
  ...props
}: Props<TFieldValues, TName> & TextFieldProps) => {
  const [showDefaultValuesTooltip, setShowDefaultValuesTooltip] =
    useState(false);
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, shouldUnregister, defaultValue });
  const onSelectDefaultValue = (value: string) => {
    field.onChange(value);
    setShowDefaultValuesTooltip(false);
  };

  return (
    <Tooltip
      onClose={() => setShowDefaultValuesTooltip(false)}
      onMouseEnter={() => setShowDefaultValuesTooltip(true)}
      open={showDefaultValuesTooltip}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: 'unset',
          },
        },
      }}
      // TODO : Change layout of default values tooltip
      title={
        <Stack direction="row">
          {defaultValues.map((value, index) => (
            <Button
              key={index}
              variant="text"
              onClick={() => onSelectDefaultValue(value)}
            >
              {value}
            </Button>
          ))}
        </Stack>
      }
      placement="top"
    >
      <Tooltip title={error?.message || ''}>
        <TextField {...field} error={!!error} {...props} data-testid={name} />
      </Tooltip>
    </Tooltip>
  );
};
