import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  unauthorizedVictime: {
    textAlign: 'center',
    marginTop: '24px',
  },
});

interface Props {
  unauthorizedVictime: boolean;
}

const Unauthorized: React.FC<Props> = ({ unauthorizedVictime }) => {
  const classes = useStyles();

  return (
    <>
      {unauthorizedVictime && (
        <h1 className={classes.unauthorizedVictime}>
          {"Vous n'avez pas accès à cette victime."}
        </h1>
      )}
    </>
  );
};

export default Unauthorized;
