// TODO improve types handling

import { intersectionWith } from 'lodash';

export const flattenErrors = (errors: object): any => {
  return Object.entries(errors).reduce((accumulator, [key, value]) => {
    const normalizedKey = !Number.isNaN(Number(key)) ? 'number' : key;
    if (!(typeof value === 'object')) {
      return { ...accumulator, [normalizedKey]: value };
    }
    if ('message' in value && 'type' in value) {
      return { ...accumulator, [normalizedKey]: value };
    }

    const nestedErrors = flattenErrors(value);
    return {
      ...accumulator,
      ...Object.entries(nestedErrors).reduce(
        (nestedAccumulator, [nestedKey, nestedValue]) => ({
          ...nestedAccumulator,
          [`${normalizedKey}.${nestedKey}`]: nestedValue,
        }),
        {},
      ),
    };
  }, {});
};

export const getFormErrorFields = (
  errors: object,
  fields: string[],
): string[] => {
  const flattenedErrors = Object.keys(flattenErrors(errors));
  return intersectionWith(flattenedErrors, fields, (a, b) => a.startsWith(b));
};
