import React, { useMemo } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Victime, VictimeUserRole } from 'src/types/victime.type';
import { useAuthenticatedUser } from 'src/hooks/store/auth.hooks';
import { OrganizationMemberRole } from 'src/types/organization.type';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';

interface VictimMenuProps {
  victime: Victime;
  setVictimToDelete: (victimId: string) => void;
  setDeleteVictimConfirmDialog: (open: boolean) => void;
  isTrash?: boolean;
  setRecycleVictimConfirmDialog?: (open: boolean) => void;
  setUpdateVictimRolesDialog?: (open: boolean) => void;
}

export const VictimMenu: React.FC<VictimMenuProps> = ({
  victime,
  setVictimToDelete,
  setDeleteVictimConfirmDialog,
  isTrash = false,
  setRecycleVictimConfirmDialog,
  setUpdateVictimRolesDialog,
}) => {
  const user = useAuthenticatedUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { organization } = useDisplayedOrganization();
  const handleVictimOptionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleVictimOptionClose = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const canEditVictimeRoles = () => {
    const userOrganizationRole = organization
      ? user?.authorization.localRoles?.[organization?._id]
      : null;
    if (
      userOrganizationRole === OrganizationMemberRole.admin ||
      userOrganizationRole === OrganizationMemberRole.owner
    ) {
      return true;
    }
    const role = Object.entries(victime.roles ?? {})?.find(
      ([userId]) => userId === user?._id,
    )?.[1];
    return role && role === VictimeUserRole.admin;
  };
  const isVictimeRolesEditable = useMemo(
    () => canEditVictimeRoles(),
    [user, victime],
  );
  return (
    <div>
      <IconButton onClick={handleVictimOptionClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        key={victime._id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleVictimOptionClose}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setVictimToDelete(victime._id);
            setDeleteVictimConfirmDialog(true);
            setAnchorEl(null);
          }}
        >
          {isTrash ? 'Supprimer' : 'Mettre dans la corbeille'}
        </MenuItem>
        {isVictimeRolesEditable && !isTrash ? (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setUpdateVictimRolesDialog?.(true);
              setAnchorEl(null);
            }}
          >
            Modifier les accès
          </MenuItem>
        ) : null}
        {isTrash && setRecycleVictimConfirmDialog && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setVictimToDelete(victime._id);
              setRecycleVictimConfirmDialog(true);
              setAnchorEl(null);
            }}
          >
            Restaurer
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
