import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isCapitalisationDifferee?: boolean;
}

export const CoefficientUnavailableInBaremeTooltipText: React.FC<Props> = ({
  isCapitalisationDifferee,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography fontWeight="bold" marginBottom={1}>
        {t(
          'prejudice.capitalisation.form.fields.coefficient.unavailableInBaremeTooltip.title',
        )}
      </Typography>
      <Typography>
        {t(
          'prejudice.capitalisation.form.fields.coefficient.unavailableInBaremeTooltip.content',
          {
            context: isCapitalisationDifferee ? 'differee' : undefined,
          },
        )}
      </Typography>
    </>
  );
};
