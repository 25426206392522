import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { getProrataTemporisBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { Bareme } from 'src/types/bareme.type';
import { CalculsGlobal } from '../../../constants/calculs';
import { getEmptyLine, getParagraph, getTextRun } from '../docxFunctions';
import { simpleVerticalTablePrint } from '../simpleVerticalTablePrint';

export const prorataTemporisPrint = ({
  coefficient,
  amount,
  age,
  bareme,
  anneeCapitalisation,
  anneesEntreConsolidationEtDeces,
}: {
  coefficient: number;
  amount: number;
  age?: ReturnType<typeof CalculsGlobal.getAgeOrZero>;
  bareme?: Bareme;
  anneeCapitalisation: number | undefined;
  anneesEntreConsolidationEtDeces: number | undefined;
}): (Table | TableOfContents | Paragraph)[] => {
  return [
    getParagraph({
      children: [
        ...getTextRun({
          text: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.enableProrataTemporisDeces.print.label.firstPart',
          ),
          bold: true,
        }),
        ...getTextRun({
          text: i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.enableProrataTemporisDeces.print.label.secondPart',
          ),
        }),
      ],
    }),
    getEmptyLine(),
    simpleVerticalTablePrint([
      ...(bareme
        ? [
            {
              label: i18next.t(
                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.baremeCapitalisation.tableRowHeader',
              ),
              value: getProrataTemporisBaremeSourceLabel(bareme),
            },
          ]
        : []),
      ...(age
        ? [
            {
              label: i18next.t(
                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.ageConsolidation.tableRowHeader',
              ),
              value: i18next.t('forms.fields.age.value', {
                count: age.age,
                context: age.isExactlyZero ? 'exactly_zero' : undefined,
              }),
            },
          ]
        : []),
      ...(anneeCapitalisation && bareme?.source !== 'CJ'
        ? [
            {
              label: i18next.t(
                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneeCapitalisation.tableRowHeader',
              ),
              value: anneeCapitalisation?.toString(),
            },
          ]
        : []),
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.coefficient.tableRowHeader',
        ),
        value: i18next.t('forms.fields.age.formattedValue.value', {
          count: coefficient,
          formattedCount: fDecimalNumber(coefficient),
        }),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneesEntreConsolidationEtDeces.tableRowHeader',
        ),
        value:
          anneesEntreConsolidationEtDeces ||
          anneesEntreConsolidationEtDeces === 0
            ? i18next.t('forms.fields.year.value', {
                count: anneesEntreConsolidationEtDeces,
              })
            : '',
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.montantProratise.tableRowHeader',
        ),
        value: fCurrency(amount),
      },
    ]),
  ];
};
