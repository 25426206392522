import React, { useState } from 'react';
import { Control } from 'react-hook-form';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte } from '../../../../types/prejudice.type';
import { NormalTable } from '../../../styled';
import { Procedure } from '../../../../types/procedure.type';
import { fCurrency } from '../../../../helpers/formatNumber';
import { RenteAccidentDuTravailDialog } from './RenteAccidentDuTravailDialog';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte } from 'src/constants/calculs/calculsFormPerteGainsProfessionnelsActuelSaisieDirecte';
import { TotalIndemniteGlobaleARepartirEchus } from '../TotalIndemniteGlobaleARepartirEchus';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { getTropPercuString } from 'src/helpers/prejudices/pgpfReliquat';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { fPartResponsabilite } from 'src/helpers/formatValues';

interface Props {
  procedure: Procedure;
  control: Control<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>;
  isPGPF: boolean;
  tauxIPP: number | null;
  onClickModify: () => void;
}

export const TableRecapitulatifSaisieDirecte: React.FC<Props> = ({
  control,
  procedure,
  isPGPF = false,
  tauxIPP,
  onClickModify,
}) => {
  const { t } = useTranslation();
  const [
    isRenteAccidentDuTravailModalOpen,
    setIsRenteAccidentDuTravailModalOpen,
  ] = useState(false);
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <ComputedPropsForm
            control={control}
            watchFields={[
              'revenuEspere.total',
              'perteDeGainsProfessionnels.total',
              'indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal',
              'indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet',
            ]}
            compute={([
              totalRevenuEspere,
              totalPerteDeGainsProfessionnels,
              csgRdsTotal,
              indemnitesJournalieresPercuesNet,
            ]) => {
              const difference =
                CalculsFormPerteGainsProfessionnelsActuelSaisieDirecte.getDifference(
                  totalPerteDeGainsProfessionnels,
                  indemnitesJournalieresPercuesNet,
                );
              const props: Record<
                | 'revenuEspere'
                | 'perteDeGainsProfessionnels'
                | 'difference'
                | 'indemnitesJournalieresPercuesNet'
                | 'csgRdsTotal',
                {
                  total: number;
                  partResponsabilite: number;
                }
              > = {
                revenuEspere: {
                  total: totalRevenuEspere,
                  partResponsabilite:
                    totalRevenuEspere * procedure.partResponsabilite,
                },
                perteDeGainsProfessionnels: {
                  total: totalPerteDeGainsProfessionnels,
                  partResponsabilite:
                    totalPerteDeGainsProfessionnels *
                    procedure.partResponsabilite,
                },
                difference: {
                  total: difference,
                  partResponsabilite: difference * procedure.partResponsabilite,
                },
                indemnitesJournalieresPercuesNet: {
                  total: indemnitesJournalieresPercuesNet || 0,
                  partResponsabilite:
                    (indemnitesJournalieresPercuesNet || 0) *
                    procedure.partResponsabilite,
                },
                csgRdsTotal: {
                  total: csgRdsTotal || 0,
                  partResponsabilite:
                    (csgRdsTotal || 0) * procedure.partResponsabilite,
                },
              };
              return {
                props,
              };
            }}
            render={({
              revenuEspere,
              perteDeGainsProfessionnels,
              difference,
              indemnitesJournalieresPercuesNet,
              csgRdsTotal,
            }: Record<
              | 'revenuEspere'
              | 'perteDeGainsProfessionnels'
              | 'difference'
              | 'indemnitesJournalieresPercuesNet'
              | 'csgRdsTotal',
              {
                total: number;
                partResponsabilite: number;
              }
            >) => (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" />
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.revenuEspere.columnHeader',
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.perteDeGainsProfessionnels.columnHeader',
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.indemnitesJournalieresPercuesPendantLaPeriodeDArret.columnHeader',
                      )}
                    </TableCell>
                    {!isPGPF ? (
                      <TableCell align="center">
                        {t(
                          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.csgCrds.columnHeader',
                        )}
                      </TableCell>
                    ) : null}
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.tableRecapitulatif.difference.columnHeader',
                      )}
                    </TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {t(
                        'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.tableRecapitulatif.totalRow.rowHeader',
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {fCurrency(revenuEspere.total)}
                    </TableCell>
                    <TableCell align="center">
                      {fCurrency(perteDeGainsProfessionnels.total)}
                    </TableCell>
                    <TableCell align="center">
                      {fCurrency(indemnitesJournalieresPercuesNet.total || 0)}
                    </TableCell>
                    {!isPGPF ? (
                      <TableCell align="center">
                        {fCurrency(csgRdsTotal.total || 0)}
                      </TableCell>
                    ) : null}
                    <TableCell align="center">
                      <ComputedReadFieldForm
                        name="total"
                        control={
                          control as Control<PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte>
                        }
                        customValues={[difference.total]}
                        watchFields={[]}
                        compute={(_, customValues) => customValues?.[0] || 0}
                        render={(value) => <>{getTropPercuString(value)}</>}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={onClickModify}
                        sx={{
                          color: '#19A3FF',
                          fontWeight: 'heavy',
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell align="center">
                      {t('partResponsabilite.tableRowHeader', {
                        partResponsabilite: fPartResponsabilite(
                          procedure.partResponsabilite * 100,
                        ),
                      })}
                    </TableCell>
                    <TableCell />
                    <TableCell align="center">
                      {fCurrency(perteDeGainsProfessionnels.partResponsabilite)}
                    </TableCell>
                    <TableCell />
                    {!isPGPF ? (
                      <TableCell align="center">
                        {fCurrency(csgRdsTotal.partResponsabilite)}
                      </TableCell>
                    ) : null}
                    <TableCell colSpan={2} />
                  </TableRow>
                </TableFooter>
              </>
            )}
          />
        </NormalTable>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent={isPGPF ? 'start' : 'end'}
        alignItems="start"
      >
        {isPGPF ? (
          <Stack
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.light,
              borderRadius: theme.shape.borderRadius / 8,
              padding: theme.spacing(1),
            })}
          >
            <Typography textAlign="center" variant="subtitle1">
              {t(
                'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.modalButtonTitle',
              )}
            </Typography>
            {isPGPF && (
              <Button
                onClick={() => {
                  setIsRenteAccidentDuTravailModalOpen(true);
                }}
                sx={{ textTransform: 'none' }}
              >
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.renteAccidentDuTravail.modalButtonText',
                )}
              </Button>
            )}
          </Stack>
        ) : (
          <ComputedPropsForm
            control={control}
            watchFields={[
              'perteDeGainsProfessionnels.total',
              'indemnitesJournalieresPercuesPendantLaPeriodeDArret',
            ]}
            compute={([
              perteGainProfessionnelsTotal,
              indemnitesJournalieresPercuesPendantLaPeriodeDArret,
            ]) => {
              return {
                props:
                  CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie(
                    {
                      perteGainProfessionnelsTotal,
                      partResponsabilite: procedure.partResponsabilite,
                      indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal:
                        indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal,
                      indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet:
                        indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet,
                      indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
                        indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
                    },
                  ),
              };
            }}
            render={(untypedIndemniteGlobaleARepartir) => {
              const indemniteGlobaleARepartir =
                untypedIndemniteGlobaleARepartir as ReturnType<
                  typeof CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie
                >;
              return (
                <TotalIndemniteGlobaleARepartirEchus
                  indemniteGlobaleARepartir={indemniteGlobaleARepartir}
                  partResponsabilite={procedure.partResponsabilite}
                />
              );
            }}
          />
        )}
      </Stack>
      {isPGPF && (
        <ComputedPropsForm
          control={control}
          watchFields={['revenuEspere.total']}
          compute={([totalRevenuEspere]) => ({
            props: {
              totalRevenuEspere,
            },
          })}
          render={({ totalRevenuEspere }) => (
            <RenteAccidentDuTravailDialog
              open={isRenteAccidentDuTravailModalOpen}
              salaireAnnuelDeReference={totalRevenuEspere}
              tauxIPP={tauxIPP}
              onClose={() => setIsRenteAccidentDuTravailModalOpen(false)}
              formType="SAISIE_DIRECTE"
            />
          )}
        />
      )}
    </>
  );
};
