import { Document } from 'docx';
import { Bareme } from 'src/types/bareme.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Prejudice, PrejudiceType } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getPGPFReliquatAndPGPFReliquatActivationStatus } from '../prejudices/pgpfReliquat';
import { getPrejudiceTotal, PrejudiceTotalValue } from '../prejudices/total';
import { makePrejudicePrint } from './makePrejudicePrint';
import { getCGAULinkPrint, getCGAUText, getCGAUTitle } from './CGAUPrint';
import { getEmptyLine } from './docxFunctions';
export const makeSinglePrejudicePrint = ({
  victime,
  procedure,
  monetaryErosions,
  prejudices,
  baremes,
  victimesIndirectes,
  prejudice,
}: {
  victime: Victime;
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
  prejudices: Prejudice[];
  baremes: Bareme[];
  victimesIndirectes: VictimeIndirecte[];
  prejudice: Pick<Prejudice, 'formData' | 'type' | 'formType'>;
}): Document => {
  const PGPFReliquat =
    getPGPFReliquatAndPGPFReliquatActivationStatus(prejudices);
  const prejudicesTotalValues: Partial<
    Record<PrejudiceType, PrejudiceTotalValue[]>
  > = prejudices
    .filter(({ type }) => type !== prejudice.type)
    .reduce(
      (totalPerPrejudice, prejudice) => ({
        ...totalPerPrejudice,
        [prejudice.type]: getPrejudiceTotal({
          victime,
          victimesIndirectes,
          procedure,
          prejudice,
          dateLiquidation: procedure.dateLiquidation
            ? new Date(procedure.dateLiquidation)
            : undefined,
          monetaryErosions,
          PGPFReliquat,
          dateConsolidation: procedure.dateConsolidation
            ? new Date(procedure.dateConsolidation)
            : undefined,
          dateDeces: victime.dateDeces
            ? new Date(victime.dateDeces)
            : undefined,
        }),
      }),
      {},
    );
  const prejudicePrint = makePrejudicePrint({
    prejudice,
    procedure,
    victime,
    victimesIndirectes,
    monetaryErosions,
    baremes,
    PGPFReliquat,
    prejudicesTotalValues,
  });
  return new Document({
    sections: [
      {
        children: prejudicePrint ? prejudicePrint : [],
      },
      {
        children: [
          getCGAUTitle(),
          getEmptyLine(),
          getCGAUText(),
          getEmptyLine(),
          getCGAULinkPrint(),
        ],
      },
    ],
  });
};
