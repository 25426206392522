import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import { Bareme } from 'src/types/bareme.type';
import { apiRequest } from '../helpers/api';

/* Thunks */
export const fetchAllBaremes = createAsyncThunk(
  'baremes/fetchAll',
  async () => {
    return await apiRequest<Bareme[]>('GET', '/bareme');
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
};

/* Adapter */
export const baremesAdapter = createEntityAdapter<Bareme>({
  selectId: (bareme) => bareme._id,
});

/* Slice */
const baremesSlice = createSlice({
  name: 'baremes',
  initialState: baremesAdapter.getInitialState({
    isLoading: false,
    error: undefined,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBaremes.pending, sharedReducers.pending)
      .addCase(fetchAllBaremes.rejected, sharedReducers.rejected)
      .addCase(fetchAllBaremes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        baremesAdapter.setAll(state, payload);
      });
  },
});

export default baremesSlice.reducer;
