import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchAllBaremes } from 'src/slices/bareme';
import { baremeSelectors, monetaryErosionSelectors } from 'src/store/selectors';
import { Bareme, BaremeType } from 'src/types/bareme.type';
import { BaremeIndemnisation } from 'src/components/resources/baremes/BaremeIndemnisation';
import { BaremeRII } from 'src/components/resources/baremes/BaremeRII';
import { useTranslation } from 'react-i18next';
import { BaremeCapitalisation } from 'src/components/resources/baremes/BaremeCapitalisation';
import { useParams } from 'react-router-dom';
import { BaremeScolaire } from 'src/components/resources/baremes/BaremeScolaire';
import { BaremeAffection } from 'src/components/resources/baremes/BaremeAffection';
import { getAllMonetaryErosions } from 'src/slices/monetaryErosion';
import { MonetaryErosionsCoefficients } from 'src/components/resources/baremes/MonetaryErosionCoefficients';
import { BaremeEsperanceDeVie } from 'src/components/resources/baremes/BaremeEsperanceDeVie';

type AllBaremesType = BaremeType | 'monetaryErosions';

export const BaremePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { baremes, monetaryErosion } = useAppSelector((state) => ({
    baremes: baremeSelectors.selectAll(state),
    monetaryErosion: monetaryErosionSelectors.selectAll(state),
  }));

  const { chosenBaremeType } = useParams<{
    chosenBaremeType: AllBaremesType;
  }>();
  const [displayedBaremeType] = useState<AllBaremesType | null>(
    chosenBaremeType ?? null,
  );
  useEffect(() => {
    dispatch(fetchAllBaremes());
    dispatch(getAllMonetaryErosions());
  }, []);
  const baremesByType = baremes.reduce(
    (accumulator: Partial<Record<BaremeType, Bareme[]>>, bareme: Bareme) => ({
      ...accumulator,
      [bareme.type]: [...(accumulator[bareme.type] || []), bareme],
    }),
    {},
  );
  const currentlyDisplayedBareme =
    displayedBaremeType &&
    displayedBaremeType !== 'monetaryErosions' &&
    baremesByType[displayedBaremeType];
  const displayBareme = (baremeType: AllBaremesType, baremes: Bareme[]) => {
    switch (baremeType) {
      case 'Indemnisation':
        return <BaremeIndemnisation baremes={baremes} />;
      case 'RII':
        return <BaremeRII baremes={baremes} />;
      case 'Capitalisation':
        return <BaremeCapitalisation baremes={baremes} />;
      case 'Scolaire':
        return <BaremeScolaire baremes={baremes} />;
      case 'Affection':
        return <BaremeAffection baremes={baremes} />;
      case 'EsperanceDeVie':
        return <BaremeEsperanceDeVie baremes={baremes} />;
    }
  };
  const displayHeader = (title: string) => (
    <Grid container alignItems="center">
      <Grid item xs={10}>
        <Typography variant="h5" textAlign="center">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
  return (
    <Stack width="100%" height="100%">
      {displayedBaremeType && currentlyDisplayedBareme ? (
        <>
          {displayHeader(t(`bareme.type.${displayedBaremeType}.label.long`))}
          {displayBareme(displayedBaremeType, currentlyDisplayedBareme)}
        </>
      ) : displayedBaremeType === 'monetaryErosions' &&
        monetaryErosion &&
        monetaryErosion[0] ? (
        <>
          {displayHeader(t('monetaryErosion.resources.label.short'))}
          <MonetaryErosionsCoefficients monetaryErosions={monetaryErosion} />
        </>
      ) : (
        <>
          <Typography variant="h5" textAlign="center">
            {t('pages.Bareme.baremesTitle')}
          </Typography>
        </>
      )}
    </Stack>
  );
};
