import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { fDate } from 'src/helpers/formatTime';
import {
  cancelSubscription,
  reactivateSubscription,
} from 'src/slices/subscription';
import { ConfirmationDialog } from 'src/components/basic/ConfirmationDialog';
import { contactEmail } from 'src/constants/global';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { isSubscriptionCancellationAvailable } from 'src/helpers/subscription';
import { Subscription } from 'src/types/subscription.type';
import { Organization } from 'src/types/organization.type';
interface Props {
  open: boolean;
  onClose: () => void;
  subscription: Subscription | undefined;
  organization: Organization | null;
}
export const SubscriptionConfiguration: React.FC<Props> = ({
  open,
  onClose,
  subscription,
  organization,
}) => {
  // const { currentBilledAmount } = useAppSelector((state) => ({
  //   currentBilledAmount: state.subscription.currentBilledAmount,
  // }));
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isConfirmCancellationDialogOpen, setConfirmCancellationDialogOpen] =
    React.useState(false);

  const [isConfirmReactivateDialogOpen, setConfirmReactivateDialogOpen] =
    React.useState(false);

  const onContactUs = () => {
    window.open(`mailto:${contactEmail}`);
  };

  const onClickCancelAutomaticSubscriptionPayment = () => {
    setConfirmCancellationDialogOpen(true);
  };

  const onClickReactivateAutomaticSubscriptionPayment = () =>
    setConfirmReactivateDialogOpen(true);

  const onConfirmCancelAutomaticSubscriptionPayment = () => {
    if (!organization?._id) {
      return;
    }
    setConfirmCancellationDialogOpen(false);
    dispatch(cancelSubscription(organization?._id));
  };

  const onConfirmReactivateAutomaticSubscriptionPayment = () => {
    if (!organization?._id) {
      return;
    }
    setConfirmReactivateDialogOpen(false);
    dispatch(reactivateSubscription(organization?._id));
  };

  if (!subscription) {
    return null;
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Stack marginBottom={2} spacing={1} alignItems="start">
            <Typography variant="h6">
              {t('settings.subscriptionSettings.currentPlan')}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              {subscription.isPaying ||
              subscription.isFreeTrial ||
              subscription.isAutomaticSubscriptionPaymentActive ? (
                <CheckCircle color="success" />
              ) : (
                <Cancel color="error" />
              )}
              <Typography>
                {subscription.isPaying
                  ? t('settings.subscriptionSettings.plan.manual')
                  : subscription.isFreeTrial
                    ? !!subscription.isAutomaticSubscriptionPaymentActive
                      ? t(
                          'settings.subscriptionSettings.plan.freeTrial.manual',
                          {
                            endDate: !!subscription.freeTrialEndDate
                              ? fDate(subscription.freeTrialEndDate)
                              : '',
                          },
                        )
                      : t(
                          'settings.subscriptionSettings.plan.freeTrial.automatic',
                          {
                            endDate: !!subscription.freeTrialEndDate
                              ? fDate(subscription.freeTrialEndDate)
                              : '',
                          },
                        )
                    : subscription.isAutomaticSubscriptionPaymentActive &&
                        !subscription.cancelAtPeriodEnd
                      ? t(
                          'settings.subscriptionSettings.plan.automatic.nextPayment',
                          {
                            nextPaymentDate:
                              !!subscription.subscriptionPeriodEndDate
                                ? fDate(subscription.subscriptionPeriodEndDate)
                                : '',
                          },
                        )
                      : subscription.isAutomaticSubscriptionPaymentActive &&
                          subscription.cancelAtPeriodEnd
                        ? t(
                            'settings.subscriptionSettings.plan.automatic.cancelAtPeriodEnd',
                            {
                              endDate: !!subscription.subscriptionPeriodEndDate
                                ? fDate(subscription.subscriptionPeriodEndDate)
                                : '',
                            },
                          )
                        : t('settings.subscriptionSettings.plan.none')}
              </Typography>
            </Stack>
          </Stack>
          {isSubscriptionCancellationAvailable(subscription) ? (
            <Stack marginBottom={2} spacing={1} alignItems="start">
              <Typography>
                {t('settings.subscriptionSettings.automatic.cancel', {
                  nextPaymentDate: !!subscription.subscriptionPeriodEndDate
                    ? fDate(subscription.subscriptionPeriodEndDate)
                    : '',
                })}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={onClickCancelAutomaticSubscriptionPayment}
              >
                {t('settings.subscriptionSettings.automatic.cancelButton')}
              </Button>
            </Stack>
          ) : null}
          {subscription.isAutomaticSubscriptionPaymentActive &&
          subscription.cancelAtPeriodEnd ? (
            <Stack marginBottom={2} spacing={1} alignItems="start">
              <Button
                variant="contained"
                color="primary"
                onClick={onClickReactivateAutomaticSubscriptionPayment}
              >
                {t('settings.subscriptionSettings.automatic.reactivateButton')}
              </Button>
            </Stack>
          ) : null}
          {subscription.isPaying ? (
            <Stack marginBottom={2} spacing={1} alignItems="start">
              <Typography>
                {t('settings.subscriptionSettings.manual.cancel')}
              </Typography>
              <Button variant="contained" color="primary" onClick={onContactUs}>
                {t('settings.subscriptionSettings.manual.cancelButton')}
              </Button>
            </Stack>
          ) : null}
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        title={t('settings.subscriptionSettings.automatic.cancelConfirm')}
        open={isConfirmCancellationDialogOpen}
        description={t(
          'settings.subscriptionSettings.automatic.cancelConfirmDescription',
        )}
        onConfirm={onConfirmCancelAutomaticSubscriptionPayment}
        onCancel={() => setConfirmCancellationDialogOpen(false)}
      />
      <ConfirmationDialog
        title={t('settings.subscriptionSettings.automatic.reactivateConfirm')}
        open={isConfirmReactivateDialogOpen}
        description={t(
          'settings.subscriptionSettings.automatic.reactivateConfirmDescription',
        )}
        onConfirm={onConfirmReactivateAutomaticSubscriptionPayment}
        onCancel={() => setConfirmReactivateDialogOpen(false)}
      />
    </>
  );
};
