import React from 'react';
import {
  FieldPath,
  FieldValues,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends Omit<UseControllerProps<TFieldValues, TName>, 'defaultValue'> {
  value: PathValue<TFieldValues, TName>;
}

export const HiddenFieldForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  value,
}: Props<TFieldValues, TName>) => {
  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue: value,
  });

  return <input {...field} type="hidden" data-testid={name} />;
};
