import { Paragraph, Table, TableOfContents } from 'docx';
import {
  Prejudice,
  PrejudiceFormListeProjection,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { getParagraph, getTextRun } from 'src/helpers/print/docxFunctions';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  fCoefficientCapitalisation,
  fPartResponsabilite,
} from 'src/helpers/formatValues';
import { CalculsFormListeProjection } from 'src/constants/calculs';
import { fYears } from 'src/helpers/formatTime';
import { displayRowsSumFormula } from '../../formula';
import { displayVictimeNameWrittenPrint } from '../../victime.written.print';

export const prorataTemporisWrittenPrint = ({
  victime,
  procedure,
  formData,
  prejudice,
  partResponsabilite,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormListeProjection;
  prejudice: Pick<Prejudice, 'formType' | 'formData' | 'type'>;
  partResponsabilite: number;
}): (Table | TableOfContents | Paragraph)[] => {
  const anneesEntreConsolidationEtDeces =
    procedure.dateConsolidation && victime.dateDeces
      ? CalculsFormListeProjection.getAnneesConsolideDeces(
          procedure.dateConsolidation,
          victime.dateDeces,
        )
      : 0;
  if (partResponsabilite === 1) {
    return [
      getParagraph({
        children: [
          ...getTextRun(
            i18next.t(
              `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.prorataTemporis.firstPart`,
            ),
          ),
          ...getTextRun(
            i18next.t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.writtenPrint.fullPartResponsabilite',
              {
                victimeName: displayVictimeNameWrittenPrint(victime),
                total: fCurrency(formData.montantTotal),
                formula: formData.prejudiceValues
                  ? displayRowsSumFormula({
                      rowValues: formData.prejudiceValues.rows.map(
                        (row) => row.montant || 0,
                      ),
                      options: {
                        withParentheses: true,
                      },
                      editedFieldsParameters: {
                        formData,
                        fieldName: 'montantTotal',
                      },
                    })
                  : '',
                montantProratise: fCurrency(formData.montantProratise || 0),
                montantProratiseFormula: i18next.t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.formula',
                  {
                    totalPartResponsabilite: fCurrency(
                      formData.montantTotal * partResponsabilite,
                    ),
                    coefficientCapitalisationAgeConsolidation:
                      formData.coefficientCapitalisationAgeConsolidation ||
                      formData.coefficientCapitalisationAgeConsolidation === 0
                        ? fCoefficientCapitalisation(
                            formData.coefficientCapitalisationAgeConsolidation,
                          )
                        : 0,
                    anneesEntreConsolidationEtDeces: fYears(
                      anneesEntreConsolidationEtDeces,
                    ),
                  },
                ),
              },
            ),
          ),
        ],
      }),
    ];
  } else {
    return [
      getParagraph({
        children: [
          ...getTextRun(
            i18next.t(
              `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.prorataTemporis.firstPart`,
            ),
          ),
          ...getTextRun(
            i18next.t(
              `prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.writtenPrint.nonFullPartResponsabilite`,
              {
                victimeName: displayVictimeNameWrittenPrint(victime),
                partResponsabilite: fPartResponsabilite(
                  partResponsabilite * 100,
                ),
                total: fCurrency(formData.montantTotal),
                formula: formData.prejudiceValues
                  ? displayRowsSumFormula({
                      rowValues: formData.prejudiceValues.rows.map(
                        (row) => row.montant || 0,
                      ),
                      options: {
                        withParentheses: true,
                      },
                      editedFieldsParameters: {
                        formData,
                        fieldName: 'montantTotal',
                      },
                    })
                  : '',
                totalPartResponsabilite: fCurrency(
                  formData.montantTotal * partResponsabilite,
                ),
                montantProratise: fCurrency(formData.montantProratise || 0),
                montantProratiseFormula: i18next.t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.formula',
                  {
                    totalPartResponsabilite: fCurrency(
                      formData.montantTotal * partResponsabilite,
                    ),
                    coefficientCapitalisationAgeConsolidation:
                      formData.coefficientCapitalisationAgeConsolidation ||
                      formData.coefficientCapitalisationAgeConsolidation === 0
                        ? fCoefficientCapitalisation(
                            formData.coefficientCapitalisationAgeConsolidation,
                          )
                        : 0,
                    anneesEntreConsolidationEtDeces: fYears(
                      anneesEntreConsolidationEtDeces,
                    ),
                  },
                ),
              },
            ),
          ),
        ],
      }),
    ];
  }
};
