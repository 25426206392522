import React, { useEffect } from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  forcedValue?: boolean;
}

export const CheckboxFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  forcedValue,
  ...props
}: Props<TFieldValues, TName> & CheckboxProps) => {
  const {
    field: { onChange, value, ...otherField },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  useEffect(() => {
    if (forcedValue !== undefined) {
      onChange(forcedValue);
    }
  }, [forcedValue]);
  return (
    <Checkbox
      {...props}
      {...otherField}
      onChange={(_, checked) => {
        onChange(checked);
      }}
      checked={value}
      disabled={props.disabled || forcedValue !== undefined}
    />
  );
};
