import { Paragraph, Table, TableOfContents } from 'docx';
import {
  NumeroPieceValues,
  PrejudiceFormValeurVictimesIndirectes,
  PrejudiceFormValeurVictimesIndirectesRow,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import i18next, { t } from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { CalculsGlobal } from 'src/constants/calculs';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { fAge, fDate } from 'src/helpers/formatTime';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';

import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';

export const valeurVictimesIndirectesWrittenPrint = ({
  victime,
  formData,
  prejudiceType,
  victimesIndirectes,
}: {
  victime: Victime;
  formData: PrejudiceFormValeurVictimesIndirectes;
  prejudiceType: PrejudiceType;
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] => {
  const firstPart = i18next.t(
    `prejudice.prejudicesTypes.${prejudiceType}.writtenPrint.indemniteGlobaleARepartir.firstPart`,
  );

  const getAgeDeces = (victimeIndirecte: VictimeIndirecte) => {
    const ageDeces = victime.dateDeces
      ? CalculsGlobal.getAgeOrZero(
          victimeIndirecte.dateNaissance,
          victime.dateDeces,
        )
      : null;
    return ageDeces ? fAge(ageDeces) : '';
  };

  const getCommentaire = (
    numerosPieces?: NumeroPieceValues,
    commentaire?: string,
  ): string =>
    numerosPieces?.rows[0] && commentaire
      ? ` (${displayNumerosPiecesWrittenPrint(numerosPieces)} - ${commentaire})`
      : numerosPieces?.rows[0] || commentaire
        ? ` (${(!!numerosPieces && displayNumerosPiecesWrittenPrint(numerosPieces)) || commentaire})`
        : '';

  const indirectVictimePrejudiceTotal = (
    row: PrejudiceFormValeurVictimesIndirectesRow,
  ) => {
    const victimeIndirecte = victimesIndirectes.find(
      (victime) => victime._id === row.victimeIndirecteId,
    );
    if (!victimeIndirecte) {
      return '';
    }
    const ageDeces = victime.dateDeces
      ? i18next.t(
          'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.writtenPrint.ageDeces',
          { ageDeces: getAgeDeces(victimeIndirecte) },
        )
      : '';
    return i18next.t(
      'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.writtenPrint.préjudicetotal',
      {
        victimeName: displayVictimeNameWrittenPrint(victimeIndirecte),
        dateNaissance: fDate(victimeIndirecte.dateNaissance),
        ageDeces,
        lienVictime: i18next.t(
          `victimeIndirecte.form.fields.lienVictime.options.${victimeIndirecte.lienVictime}`,
        ),
        montant: fCurrency(row.montant),
        fractionIndemnisable: fPartResponsabilite(
          formData.partResponsabilite * 100,
        ),
        fractionIndemnisableMontant: fCurrency(
          row.montant * formData.partResponsabilite,
        ),
        commentaire: getCommentaire(row.numerosPieces, row.notes),
      },
    );
  };
  const recapitulatif = simpleHorizontalTablePrint(
    [
      i18next.t('numeroPiece.form.numeroPiece.label'),
      i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.nom.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.lienVictime.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.lieuDeVie.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.dateNaissance.columnHeader',
      ),
      ...(victime.dateDeces
        ? [
            i18next.t(
              'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.ageDeces.columnHeader',
            ),
          ]
        : []),
      i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.montant.columnHeader',
      ),
      `${i18next.t('prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.montant.columnHeader')} (${fPartResponsabilite(formData.partResponsabilite * 100)})`,
    ],
    formData.victimesIndirectes.map((row, index) => {
      const victimeIndirecte = victimesIndirectes[index];
      if (!victimeIndirecte) {
        return {
          rowLabel: '',
          columns: [
            '',
            '',
            '',
            '',
            '',
            fCurrency(row.montant),
            fCurrency(row.montant * formData.partResponsabilite),
          ],
        };
      }
      return {
        rowLabel: row.numerosPieces.rows[0]
          ? i18next.t('numeroPiece.form.numeroPiece.renderShort', {
              numeroPiece: displayNumeroPiece(row.numerosPieces),
            })
          : '',
        columns: [
          victimeIndirecte.nom + ' ' + victimeIndirecte.prenom,
          i18next.t(
            `victimeIndirecte.form.fields.lienVictime.options.${victimeIndirecte.lienVictime}`,
          ),
          (victimeIndirecte.lieuDeVie &&
            t(
              `victimeIndirecte.form.fields.lieuDeVie.options.${victimeIndirecte.lieuDeVie}`,
            )) ||
            '',
          fDate(victimeIndirecte.dateNaissance),
          ...(getAgeDeces(victimeIndirecte)
            ? [getAgeDeces(victimeIndirecte)]
            : []),
          fCurrency(row.montant),
          fCurrency(row.montant * formData.partResponsabilite),
        ],
      };
    }),
  );

  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...(!!formData.victimesIndirectes.length ? [getParagraph(firstPart)] : []),
    ...formData.victimesIndirectes
      .map((row) => [
        getParagraph(indirectVictimePrejudiceTotal(row)),
        getEmptyLine(),
      ])
      .flat(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.writtenPrint.fractionIndemnisable',
          {
            prejudiceType,
            value: fCurrency(
              formData.montantTotal * formData.partResponsabilite,
            ),
          },
        ),
      }),
    }),
    getEmptyLine(),
    recapitulatif,
  ];
};
