import i18next from 'i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Prejudice } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { WorkBook } from 'xlsx';
import { getPGPFReliquatAndPGPFReliquatActivationStatus } from '../prejudices/pgpfReliquat';
import {
  getPrejudicesTotal,
  getPrejudiceTotal,
  getPrejudiceTotalAmount,
} from '../prejudices/total';
import {
  createWorkbook,
  createWorkSheet,
  toArrayStringCell,
} from './xlsxUtils';

export const makeXlsx = ({
  victime,
  procedure,
  monetaryErosions,
  prejudices,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
  prejudices: Prejudice[];
  victimesIndirectes: VictimeIndirecte[];
}): WorkBook => {
  const PGPFReliquat =
    getPGPFReliquatAndPGPFReliquatActivationStatus(prejudices);

  const PrejudicesTotal = getPrejudicesTotal({
    victime,
    victimesIndirectes,
    procedure,
    prejudices,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    monetaryErosions,
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
  });

  const data = prejudices.map((prejudice) => {
    const values = getPrejudiceTotal({
      victime,
      victimesIndirectes,
      procedure,
      prejudice,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      monetaryErosions,
      PGPFReliquat,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
    const amount = getPrejudiceTotalAmount({ prejudiceTotalValues: values });
    return toArrayStringCell([
      i18next.t(`prejudice.prejudicesTypes.${prejudice.type}.title`),
      amount.victime === 0 ? undefined : amount.victime,
      amount.tiersPayeurs ?? undefined,
      amount.proche ?? undefined,
      amount.rentes ?? undefined,
      amount.total,
    ]);
  });

  data.unshift(
    toArrayStringCell([
      '',
      'Victime',
      'TP',
      'Victime indirecte',
      'Rente',
      'Total',
    ]),
  );

  data.push(
    toArrayStringCell([
      'Total',
      PrejudicesTotal.victime ?? 0,
      PrejudicesTotal.tiersPayeurs ?? 0,
      PrejudicesTotal.proche ?? 0,
      PrejudicesTotal.rentes ?? 0,
      PrejudicesTotal.total ?? 0,
    ]),
  );

  const sheet = createWorkSheet(data, 'Procédure');
  return createWorkbook([sheet]);
};
