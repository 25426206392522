import i18next from 'i18next';
import {
  Prejudice,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierValeur,
  PrejudiceFormListeProjection,
  PrejudiceFormMateriel,
  NumeroPieceValues,
  ListeProjectionAgrement,
  ListeProjectionFonctionnelPermanent,
  ListeProjectionIncidenceProfessionelle,
  ListeProjectionMaladie,
  PrejudiceFormValeurVictimesIndirectes,
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  NumeroPieceValuesRow,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
} from 'src/types/prejudice.type';
import {
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormValeur,
} from 'src/types/prejudice.type';

export const getNumeroPiecesByPrejudice = (
  prejudice: Prejudice,
): NumeroPieceValuesRow[] => {
  try {
    switch (prejudice.formType) {
      case 'VALEUR': {
        const formData = prejudice.formData as PrejudiceFormValeur;
        return formData.numerosPieces?.rows ? formData.numerosPieces?.rows : [];
      }
      case 'CALENDRIER_ASSISTANCE':
      case 'CALENDRIER_ASSISTANCE_CAPITALISATION': {
        const formData = prejudice.formData as
          | PrejudiceFormCalendrierAssistance
          | PrejudiceFormCalendrierAssistanceCapitalisation;
        return formData.rows.reduce((acc: NumeroPieceValuesRow[], row) => {
          const numerosPieces = row.numerosPieces;
          if (numerosPieces?.rows) {
            acc.push(...numerosPieces?.rows);
          }
          return acc;
        }, []);
      }
      case 'CALENDRIER_DEPENSE':
      case 'CALENDRIER_DEPENSE_CAPITALISATION': {
        const formData = prejudice.formData as PrejudiceFormCalendrierDepense;
        return formData.rows.reduce((acc: NumeroPieceValuesRow[], row) => {
          const numerosPieces = row.numerosPieces;
          if (numerosPieces?.rows) {
            acc.push(...numerosPieces?.rows);
          }
          return acc;
        }, []);
      }
      case 'LISTE_PROJECTION': {
        const formData = prejudice.formData as PrejudiceFormListeProjection;
        switch (prejudice.type) {
          case 'PREJUDICE_AGREMENT':
          case 'AUTRES_POSTES_DE_PREJUDICES': {
            const prejudiceValues =
              formData.prejudiceValues as ListeProjectionAgrement;
            return prejudiceValues.rows.reduce(
              (acc: NumeroPieceValuesRow[], row) => {
                const numerosPieces = row.numerosPieces;
                if (numerosPieces?.rows) {
                  acc.push(...numerosPieces?.rows);
                }
                return acc;
              },
              [],
            );
          }
          case 'DEFICIT_FONCTIONNEL_PERMANENT': {
            const prejudiceValues =
              formData.prejudiceValues as ListeProjectionFonctionnelPermanent;
            return prejudiceValues.rows.reduce(
              (acc: NumeroPieceValuesRow[], row) => {
                const numerosPieces = row.numerosPieces;
                if (numerosPieces?.rows) {
                  acc.push(...numerosPieces?.rows);
                }
                return acc;
              },
              [],
            );
          }
          case 'PREJUDICE_ESTHETIQUE_PERMANENT':
          case 'PREJUDICE_ESTHETIQUE_TEMPORAIRE':
          case 'SOUFFRANCES_ENDUREES': {
            const prejudiceValues =
              formData.prejudiceValues as ListeProjectionMaladie;
            return prejudiceValues.rows.reduce(
              (acc: NumeroPieceValuesRow[], row) => {
                const numerosPieces = row.numerosPieces;
                if (numerosPieces?.rows) {
                  acc.push(...numerosPieces?.rows);
                }
                return acc;
              },
              [],
            );
          }
          case 'INCIDENCE_PROFESSIONNELLE': {
            const prejudiceValues =
              formData.prejudiceValues as ListeProjectionIncidenceProfessionelle;
            return prejudiceValues.rows.reduce(
              (acc: NumeroPieceValuesRow[], row) => {
                const numerosPieces = row.numerosPieces;
                if (numerosPieces?.rows) {
                  acc.push(...numerosPieces?.rows);
                }
                return acc;
              },
              [],
            );
          }
          default: {
            return [];
          }
        }
      }
      case 'CALENDRIER_VALEURS': {
        const formData = prejudice.formData as PrejudiceFormCalendrierValeur;
        return formData.rows.reduce((acc: NumeroPieceValuesRow[], row) => {
          const numerosPieces = row.numerosPieces;
          if (numerosPieces) {
            acc.push(...numerosPieces?.rows);
          }
          return acc;
        }, []);
      }
      case 'MATERIEL': {
        const formData = prejudice.formData as PrejudiceFormMateriel;
        return formData.rows.reduce((acc: NumeroPieceValuesRow[], row) => {
          const numerosPieces = row.numerosPieces;
          if (numerosPieces) {
            acc.push(...numerosPieces?.rows);
          }
          return acc;
        }, []);
      }
      case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL':
      case 'PERTE_GAINS_PROFESSIONNELS_FUTURS': {
        let formData = prejudice.formData as
          | OldPrejudiceFormPerteGainProfessionnelsActuel
          | NewPrejudiceFormPerteGainProfessionnelsActuel
          | OldPrejudiceFormPerteGainProfessionnelsFuturs
          | NewPrejudiceFormPerteGainProfessionnelsFuturs
          | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
          | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
        switch (formData.formType) {
          case 'DECLARATION_FISCALES': {
            formData = formData as
              | OldPrejudiceFormPerteGainProfessionnelsActuel
              | OldPrejudiceFormPerteGainProfessionnelsFuturs;
            return formData.situations.reduce(
              (acc: NumeroPieceValuesRow[], situation) => {
                acc.push(
                  ...situation.anneesRevenusTheoriques.reduce(
                    (
                      revenusTheoriquesNumeroPieces: NumeroPieceValuesRow[],
                      row,
                    ) => {
                      const numerosPieces = row.numerosPieces;
                      if (numerosPieces?.rows) {
                        revenusTheoriquesNumeroPieces.push(
                          ...numerosPieces?.rows,
                        );
                      }
                      return revenusTheoriquesNumeroPieces;
                    },
                    [],
                  ),
                );
                acc.push(
                  ...situation.anneesRevenusReels.reduce(
                    (revenusReelsNumeroPieces: NumeroPieceValuesRow[], row) => {
                      const numerosPieces = row.numerosPieces;
                      if (numerosPieces?.rows) {
                        revenusReelsNumeroPieces.push(...numerosPieces?.rows);
                      }
                      return revenusReelsNumeroPieces;
                    },
                    [],
                  ),
                );
                acc.push(
                  ...situation.tiersPayeursIndemnites.reduce(
                    (
                      tiersPayeursIndemnitesNumeroPieces: NumeroPieceValuesRow[],
                      row,
                    ) => {
                      const numerosPieces = row.numerosPieces;
                      if (numerosPieces?.rows) {
                        tiersPayeursIndemnitesNumeroPieces.push(
                          ...numerosPieces?.rows,
                        );
                      }
                      return tiersPayeursIndemnitesNumeroPieces;
                    },
                    [],
                  ),
                );

                return acc;
              },
              [],
            );
          }
          case 'REVENUS': {
            formData = formData as
              | NewPrejudiceFormPerteGainProfessionnelsActuel
              | NewPrejudiceFormPerteGainProfessionnelsFuturs;
            return [
              ...formData.revenuActiviteAnnuelDeReference.rows.reduce(
                (acc: NumeroPieceValuesRow[], row) => {
                  const numerosPieces = row.numerosPieces;
                  if (numerosPieces?.rows) {
                    acc.push(...numerosPieces?.rows);
                  }
                  return acc;
                },
                [],
              ),
              ...formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.reduce(
                (acc: NumeroPieceValuesRow[], row) => {
                  const numerosPieces = row.numerosPieces;
                  if (numerosPieces?.rows) {
                    acc.push(...numerosPieces?.rows);
                  }
                  return acc;
                },
                [],
              ),
              ...formData.perteDeGainsProfessionnels.rows.reduce(
                (acc: NumeroPieceValuesRow[], row) => {
                  const numerosPieces = row.numerosPieces;
                  if (numerosPieces?.rows) {
                    acc.push(...numerosPieces?.rows);
                  }
                  return acc;
                },
                [],
              ),
            ];
          }
          case 'SAISIE_DIRECTE': {
            formData = formData as
              | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
              | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
            return [
              ...formData.revenuEspere.rows.reduce(
                (acc: NumeroPieceValuesRow[], row) => {
                  const numerosPieces = row.numerosPieces;
                  if (numerosPieces?.rows) {
                    acc.push(...numerosPieces?.rows);
                  }
                  return acc;
                },
                [],
              ),
              ...formData.perteDeGainsProfessionnels.rows.reduce(
                (acc: NumeroPieceValuesRow[], row) => {
                  const numerosPieces = row.numerosPieces;
                  if (numerosPieces?.rows) {
                    acc.push(...numerosPieces?.rows);
                  }
                  return acc;
                },
                [],
              ),
            ];
          }
          default: {
            return [];
          }
        }
      }
      case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL': {
        const formData =
          prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel;
        return formData.rows.reduce((acc: NumeroPieceValuesRow[], row) => {
          const numerosPieces = row.numerosPieces;
          if (numerosPieces?.rows) {
            acc.push(...numerosPieces?.rows);
          }
          return acc;
        }, []);
      }
      case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL': {
        const formData =
          prejudice.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal;
        return formData.rows.reduce((acc: NumeroPieceValuesRow[], row) => {
          const numerosPieces = row.numerosPieces;
          if (numerosPieces?.rows) {
            acc.push(...numerosPieces?.rows);
          }
          return acc;
        }, []);
      }
      case 'VALEUR_VICTIMES_INDIRECTES': {
        const formData =
          prejudice.formData as PrejudiceFormValeurVictimesIndirectes;
        return formData.victimesIndirectes.reduce(
          (accumulator: NumeroPieceValuesRow[], { numerosPieces }) => [
            ...accumulator,
            ...(numerosPieces?.rows ? numerosPieces?.rows : []),
          ],
          [],
        );
      }
      case 'PERTES_REVENUS_PROCHE': {
        if ('surcroitActivite' in prejudice.formData) {
          const formData =
            prejudice.formData as PrejudiceFormPerteRevenusProcheVictimeNonDecedee;

          return [
            formData.perteRevenuPersonnel,
            formData.montantATPADeduire,
            formData.surcroitActivite,
            formData.perteDroitRetraite,
            formData.perteChanceAssistanceConjoint,
          ].reduce((accumulator: NumeroPieceValuesRow[], value) => {
            if (value.numerosPieces?.rows) {
              accumulator.push(...value.numerosPieces?.rows);
            }
            return accumulator;
          }, []);
        } else {
          const formData =
            prejudice.formData as PrejudiceFormPerteRevenusProche;
          return [
            formData.conjoint.revenuAvantDeces,
            formData.victime.revenu,
            formData.conjoint.revenuApresDeces,
          ].reduce((accumulator: NumeroPieceValuesRow[], value) => {
            if (value.numerosPieces) {
              accumulator.push(...value.numerosPieces?.rows);
            }
            return accumulator;
          }, []);
        }
      }
      default:
        return [];
    }
  } catch (e) {
    console.error(
      i18next.t('numeroPiece.getByPrejudice.error', {
        error: e,
        prejudiceName: i18next.t(
          `prejudice.prejudicesTypes.${prejudice.type}.title`,
        ),
        prejudice: JSON.stringify(prejudice),
      }),
    );
    return [];
  }
};

export const getAllNumeroPieces = (prejudices: Prejudice[]) => {
  return prejudices.reduce((acc: NumeroPieceValuesRow[], prejudice) => {
    const numerosPieces = getNumeroPiecesByPrejudice(prejudice);
    if (numerosPieces.length > 0) {
      acc.push(...numerosPieces);
    }
    return acc;
  }, []);
};

export const displayNumeroPiece = (
  numerosPieces: NumeroPieceValues,
  limitRows = true,
) => {
  const rows = numerosPieces?.rows || [];
  return `${(limitRows ? rows.slice(0, 3) : rows)
    .map((numerosPieces) => numerosPieces?.numeroPiece)
    .filter((numeroPiece) => !!numeroPiece)
    .join(rows.length > 1 ? ', ' : '')}${
    rows.length > 3 && limitRows ? '...' : ''
  }`;
};
