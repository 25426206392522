import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CapitalisationDiffereeTooltipText: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography fontWeight="bold" marginBottom={1}>
        {t(
          'prejudice.capitalisation.form.fields.coefficient.differeeTooltip.title',
        )}
      </Typography>
      <Typography>
        {t(
          'prejudice.capitalisation.form.fields.coefficient.differeeTooltip.content',
        )}
      </Typography>
    </>
  );
};
