import { Paragraph, Table, TableOfContents } from 'docx';
import { PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine } from '../../../docxFunctions';
import { prejudiceNotesPrint } from '../../prejudiceNotesPrint';
import { tableRecapitulatifSaisieDirectePrint } from './tableRecapitulatiifSaisieDirectePrint';
import { stepsSaisieDirectePrint } from './stepsSaisieDirectePrint';
import { debitSoldeAEchoirPrint } from '../../debitSoldeAEchoirPrint';
import i18next from 'i18next';
import { totalCapitalisationPrint } from '../../totalCapitalisationPrint';
import { Victime } from 'src/types/victime.type';
import { Bareme } from 'src/types/bareme.type';
import { rentesPrint } from '../../rentesPrint';
import { CalculsFormNewPerteGainsProfessionnelsFuturs } from 'src/constants/calculs';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';

export const pgpfSaisieDirectePrint = ({
  victime,
  procedure,
  formData,
  baremes,
}: {
  procedure: Procedure;
  formData: PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
  baremes: Bareme[];
  victime: Victime;
}): (Paragraph | Table | TableOfContents)[] => {
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const indemniteGlobaleARepartir =
    CalculsFormNewPerteGainsProfessionnelsFuturs.getIndemnitesRepartie({
      perteGainProfessionnelsTotal: formData.perteDeGainsProfessionnels.total,
      partResponsabilite: procedure.partResponsabilite,
      renteCapitalisee: formData.capitalisation.montantCapitalise,
      capitalisationTiersPayeurs: formData.capitalisationTiersPayeurs,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
      indemnitesJournalieresPercuesNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .indemnitesJournalieresPercuesNet,
      tiersPayeursTotalCapitalise:
        formData.capitalisationTiersPayeurs.montantCapitalise,
      tiersPayeurs: procedure.tiersPayeurs,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
  const tableRecapitulatif = tableRecapitulatifSaisieDirectePrint({
    ...formData,
    partResponsabilite: procedure.partResponsabilite,
    isPGPF: true,
  });
  const steps = stepsSaisieDirectePrint({
    formData,
    isPGPF: true,
  });
  const debitSoldeTable = debitSoldeAEchoirPrint({
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
  });
  const bareme = baremes.find(
    (bareme) => bareme._id === formData.capitalisation.bareme,
  );
  const tableCapitalisation = totalCapitalisationPrint({
    title: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.print.title',
    ),
    victime,
    procedure,
    titleVictime: i18next.t(
      'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.title',
    ),
    victimeSommeACapitaliser: {
      label: i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.capitalisation.fields.victime.sommeACapitaliser.title',
      ),
      value: formData.capitalisation.sommeACapitaliser,
    },
    victimeCoefficientCapitalisation: {
      value: formData.capitalisation.coefficient,
    },
    bareme: bareme && {
      value: bareme,
    },
    victimeTotalCapitalise: {
      value: formData.capitalisation.montantCapitalise,
    },
    ageDernierArrerage: {
      value: formData.capitalisation.ageDernierArrerage,
    },
    isLastArrerageViager: {
      value: formData.capitalisation.isLastArrerageViager,
    },
    allTiersPayeurs: procedure.tiersPayeurs,
  });
  const rentes =
    formData.rentes?.montant || formData.rentes?.montant === 0
      ? rentesPrint({ montant: formData.rentes.montant })
      : [];
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
  });
  return [
    getEmptyLine(),
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...steps,
    getEmptyLine(),
    ...tableRecapitulatif,
    getEmptyLine(),
    ...(!formData.isRentesOption && !shouldNotDisplayCapitalisation
      ? [...tableCapitalisation, getEmptyLine()]
      : []),
    ...debitSoldeTable,
    ...(formData.isRentesOption === true && !shouldNotDisplayCapitalisation
      ? [getEmptyLine(), ...rentes]
      : []),
  ];
};
