import React, { useEffect, useMemo } from 'react';
import {
  useWatch,
  useController,
  UseControllerProps,
  FieldValues,
  FieldPath,
  FieldPathValues,
  FieldPathValue,
} from 'react-hook-form';
import { TextFieldProps, Tooltip } from '@mui/material';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TFieldNames extends
    readonly FieldPath<TFieldValues>[] = readonly FieldPath<TFieldValues>[],
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TCustomValues extends any[] = any[],
> extends UseControllerProps<TFieldValues, TName> {
  watchFields: readonly [...TFieldNames];
  compute: (
    watchValues: FieldPathValues<TFieldValues, TFieldNames>,
    customValues?: TCustomValues,
  ) => FieldPathValue<TFieldValues, TName>;
  customValues?: TCustomValues;
  computeTooltip?: (
    watchValues: FieldPathValues<TFieldValues, TFieldNames>,
    computedValue?: FieldPathValue<TFieldValues, TName>,
    customValues?: TCustomValues,
  ) => React.ReactNode;
  render?: (
    // TODO : Find out why unit tests don't detect this type and instead see it as any value of the form
    value: any, //FieldPathValue<TFieldValues, TName>,
    watchValues: FieldPathValues<TFieldValues, TFieldNames>,
  ) => React.ReactElement;
}

export const ComputedReadFieldForm = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldNames extends
    readonly FieldPath<TFieldValues>[] = readonly FieldPath<TFieldValues>[],
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TCustomValues extends any[] = any[],
>({
  name,
  control,
  watchFields,
  compute,
  computeTooltip,
  rules,
  shouldUnregister,
  defaultValue,
  customValues,
  render,
}: Props<TFieldValues, TFieldNames, TName, TCustomValues> & TextFieldProps) => {
  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const [computedTooltipValue, setComputedTooltipValue] =
    React.useState<React.ReactNode>('');
  const memoizedCustomValues = useMemo(() => customValues, [customValues]);

  const watchValues = useWatch({
    control,
    name: watchFields,
  });

  useEffect(() => {
    const computedValue = compute(watchValues, memoizedCustomValues);
    if (computedValue !== field.value) {
      field.onChange(computedValue);
    }
    if (computeTooltip) {
      setComputedTooltipValue(
        computeTooltip(watchValues, computedValue, memoizedCustomValues),
      );
    }
  }, [watchValues, memoizedCustomValues]);

  return render ? (
    <Tooltip placement="top" title={computedTooltipValue}>
      {render(field.value, watchValues)}
    </Tooltip>
  ) : null;
};
