import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/hooks/store';
import { createOrUpdateConstant } from 'src/slices/constant';
import { Constant } from 'src/types/constant.type';
import { PREJUDICE_FORM_TYPE_LIST } from 'src/types/prejudice.type';
import * as yup from 'yup';
import { NormalTable } from '../styled';
import { useTranslation } from 'react-i18next';
import { Cancel, PlusOneOutlined } from '@mui/icons-material';
import { ReadFieldForm } from '../forms/ReadFieldForm';
import { ComputedPropsForm } from '../forms/ComputedPropsForm';
interface Props {
  appConstants: Constant | undefined;
}

export const AdminAppConstants: React.FC<Props> = ({ appConstants }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const defaultValues = useMemo(
    () => appConstants?.prejudicesFormsVersions,
    [appConstants?.prejudicesFormsVersions],
  );
  const [isEditing, setIsEditing] = React.useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    resetField,
    ...useFormReturn
  } = useForm<Constant['prejudicesFormsVersions']>({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape(
        PREJUDICE_FORM_TYPE_LIST.reduce(
          (accumulator, formType) => ({
            ...accumulator,
            [formType]: yup.object().optional().shape({
              currentVersion: yup.number().optional(),
            }),
          }),
          {},
        ),
      ),
    ),
  });
  useEffect(() => {
    if (appConstants?.prejudicesFormsVersions) {
      reset(appConstants?.prejudicesFormsVersions);
    }
  }, [appConstants?.prejudicesFormsVersions]);
  const onSubmit = (data: Constant['prejudicesFormsVersions']) => {
    dispatch(
      createOrUpdateConstant({
        data: {
          prejudicesFormsVersions: data,
        },
      }),
    );
    setIsEditing(false);
  };

  return (
    <div>
      <FormProvider
        {...{
          control,
          handleSubmit,
          reset,
          setValue,
          resetField,
          ...useFormReturn,
        }}
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          {!isEditing && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsEditing(true)}
            >
              {t('pages.Admin.appConstants.edit')}
            </Button>
          )}
          <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
            <NormalTable stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t(
                      'pages.Admin.appConstants.form.fields.formType.columnHeader',
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'pages.Admin.appConstants.form.fields.currentVersion.columnHeader',
                    )}
                  </TableCell>
                  {isEditing && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {PREJUDICE_FORM_TYPE_LIST.map((formType) => (
                  <TableRow key={formType}>
                    <TableCell>
                      {t(`prejudice.prejudicesFormTypes.${formType}.title`)}
                    </TableCell>
                    <TableCell>
                      <ReadFieldForm
                        control={control}
                        name={`${formType}.currentVersion`}
                        render={(value) =>
                          value === undefined ? (
                            <>
                              {t(
                                'pages.Admin.appConstants.form.fields.currentVersion.empty',
                              )}
                            </>
                          ) : appConstants?.prejudicesFormsVersions[formType]
                              ?.currentVersion === value ? (
                            <>{value}</>
                          ) : (
                            <Typography color="primary">{value}</Typography>
                          )
                        }
                      />
                    </TableCell>
                    {isEditing && (
                      <TableCell>
                        <ComputedPropsForm
                          control={control}
                          watchFields={[`${formType}.currentVersion`]}
                          compute={([currentVersion]) => ({
                            props: {
                              isModified:
                                currentVersion !==
                                appConstants?.prejudicesFormsVersions[formType]
                                  ?.currentVersion,
                            },
                          })}
                          render={({ isModified }) =>
                            !isModified ? (
                              <IconButton
                                onClick={() => {
                                  setValue(
                                    `${formType}.currentVersion`,
                                    (appConstants?.prejudicesFormsVersions[
                                      formType
                                    ]?.currentVersion || 0) + 1,
                                  );
                                }}
                              >
                                <PlusOneOutlined />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  resetField(`${formType}.currentVersion`);
                                }}
                              >
                                <Cancel />
                              </IconButton>
                            )
                          }
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </NormalTable>
          </TableContainer>
          {isEditing && (
            <Stack
              spacing={2}
              padding={1}
              alignItems="center"
              justifyContent="end"
              direction="row"
            >
              <Button type="submit" variant="contained">
                {t('common.save')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  reset();
                  setIsEditing(false);
                }}
              >
                {t('common.cancel')}
              </Button>
            </Stack>
          )}
        </Box>
      </FormProvider>
    </div>
  );
};
