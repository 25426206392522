import { User } from './auth.type';
import { Subscription } from './subscription.type';

export enum OrganizationType {
  CABINET_AVOCAT = 'cabinet_avocat',
  ASSURANCE = 'assurance',
  FONDS_INDEMNISATION = 'fonds_indemnisation',
  MAGISTRATURE = 'magistrature',
  AUTRE = 'autre',
}

export enum OrganizationMemberRole {
  owner = 'organization_owner',
  admin = 'organization_admin',
  member = 'organization_member',
}

export interface Organization {
  _id: string;
  name: string;
  type: OrganizationType;
  subscription: Subscription;
  members: OrganizationMember[];
  victimes: string[];
  forbidNonOrganizationEmails?: boolean;
  hideSensitiveFields?: boolean;
  createdAt: string;
}

export interface OrganizationMember
  extends Pick<User, '_id' | 'email' | 'firstName' | 'lastName'> {
  role: OrganizationMemberRole;
}

export type CreateOrganizationDto = Omit<
  Organization,
  '_id' | 'members' | 'victimes' | 'createdAt' | 'subscription'
>;
export type UpdateOrganizationDto = Partial<
  Omit<CreateOrganizationDto, 'type'>
>;
export type UpdateMemberRoleDto = {
  role: OrganizationMemberRole;
};
