import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { OrganizationMemberRole } from 'src/types/organization.type';
import * as yup from 'yup';
import { SelectFieldForm } from '../forms/SelectFieldForm';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { OrganizationInvitation } from 'src/types/organizationInvitation.type';
import { updateOrganizationInvitation } from 'src/slices/organizationInvitation';

type FormData = {
  role: OrganizationMemberRole;
};

const UpdateInvitationForm: React.FC<
  {
    onSubmit: (data: FormData) => void;
  } & Pick<OrganizationInvitation, 'role' | 'email'>
> = ({ onSubmit, role, email }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      role,
    },
    resolver: yupResolver(
      yup.object().shape({
        role: yup
          .string()
          .oneOf(Object.values(OrganizationMemberRole))
          .required(),
      }),
    ),
  });
  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      paddingTop={1}
    >
      <Typography>{email}</Typography>
      <Typography>
        {t('pages.OrganizationConfiguration.AddOrUpdateMemberForm.roleTitle')}
      </Typography>
      <SelectFieldForm
        control={control}
        name="role"
        options={[
          OrganizationMemberRole.admin,
          OrganizationMemberRole.member,
        ].map((role) => ({
          value: role,
          label: (
            <Stack>
              <Typography>
                {t(`organization.fields.role.options.${role}.label`)}
              </Typography>
              <Typography
                variant="caption"
                whiteSpace="pre-wrap"
                overflow="hidden"
                maxWidth={306}
              >
                {t(`organization.fields.role.options.${role}.description`)}
              </Typography>
            </Stack>
          ),
        }))}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isDirty}
      >
        {t('forms.save')}
      </Button>
    </Stack>
  );
};

interface Props {
  invitation: OrganizationInvitation | null;
  open: boolean;
  organizationId: string;
  onClose: () => void;
}

export const UpdateOrganizationInvitationDialog: React.FC<Props> = ({
  invitation,
  open,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onSubmit = (data: FormData) => {
    if (!invitation) {
      return;
    }
    dispatch(
      updateOrganizationInvitation({
        organizationId,
        organizationInvitationId: invitation._id,
        data: { role: data.role },
      }),
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {!open || !invitation ? null : (
        <>
          <DialogTitle>
            {t(
              'pages.OrganizationConfiguration.invitations.updateInvitationDialog.title',
            )}
          </DialogTitle>
          <DialogContent sx={{ width: 400 }}>
            <UpdateInvitationForm
              onSubmit={onSubmit}
              role={invitation.role}
              email={invitation.email}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
