import {
  Prejudice,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  PerteGainProfessionnelsFutursBase,
} from 'src/types/prejudice.type';
import { fCurrency } from '../formatNumber';

export const getPGPFReliquatAndPGPFReliquatActivationStatus = (
  prejudices: Prejudice[],
): PGPFReliquatAndPGPFReliquatActivationStatus => {
  const perteGainProfessionnelsFutursFormData = prejudices.find(
    (prejudice) => prejudice.type === 'PERTES_GAINS_PROFESSIONNELS_FUTURS',
  )?.formData as PerteGainProfessionnelsFutursBase | undefined;
  return {
    PGPFReliquat: perteGainProfessionnelsFutursFormData?.PGPFReliquat ?? null,
    activatePGPFReliquat:
      perteGainProfessionnelsFutursFormData?.activatePGPFReliquat ?? null,
  };
};

export const getTropPercuString = (
  value: number,
  tropPercu?: number | null,
  deduitTropPercu?: number | null,
) => {
  if ((tropPercu && tropPercu < 0) || (value && value < 0)) {
    const tropPercuValue = tropPercu ? -tropPercu : value < 0 ? -value : 0;
    return `${fCurrency(0)}${
      tropPercuValue ? ` (trop perçu=${fCurrency(tropPercuValue)})` : ''
    }`;
  } else if (deduitTropPercu && deduitTropPercu > 0) {
    return `${fCurrency(value)} (${fCurrency(
      deduitTropPercu,
    )} déduit du trop perçu)`;
  } else {
    return fCurrency(value);
  }
};

export const getTropPercuWrittenPrint = (
  value: number,
  tropPercu?: number | null,
  deduitTropPercu?: number | null,
) => {
  if ((tropPercu && tropPercu < 0) || (value && value < 0)) {
    const tropPercuValue = tropPercu ? -tropPercu : value < 0 ? -value : 0;
    return `${fCurrency(0)}${
      tropPercuValue
        ? ` (avec un trop perçu de ${fCurrency(tropPercuValue)})`
        : ''
    }`;
  } else if (deduitTropPercu && deduitTropPercu > 0) {
    return `${fCurrency(value)} (avec ${fCurrency(
      deduitTropPercu,
    )} déduit du trop perçu)`;
  } else {
    return fCurrency(value);
  }
};
