import { Paragraph, Table, TableOfContents } from 'docx';
import {
  ListeProjectionMaladie,
  ListeProjectionMaladieRow,
  Prejudice,
  PrejudiceFormListeProjection,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import i18next from 'i18next';
import { displayNumerosPiecesWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/numerosPieces.written.print';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { getPeriodeWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/periode.written.print';
import { displayVictimeNameWrittenPrint } from '../../victime.written.print';

export const maladieWrittenPrint = ({
  victime,
  formData,
  prejudice,
}: {
  victime: Victime;
  formData: Omit<PrejudiceFormListeProjection, 'prejudiceValues'> & {
    prejudiceValues: ListeProjectionMaladie;
  };
  prejudice: Pick<Prejudice, 'type'>;
}): (Table | TableOfContents | Paragraph)[] => {
  const displayDegre = prejudice.type !== 'PREJUDICE_SEXUEL';
  const firstPartText = i18next.t(
    `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.rows.firstPart`,
  );
  const rowText = i18next.t(
    'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.writtenPrint.rows.row.text',
    {
      victimeName: displayVictimeNameWrittenPrint(victime),
    },
  );
  const getRow = (row: ListeProjectionMaladieRow, isMultipleRows = false) => {
    const firstPartText = i18next.t(
      `prejudice.prejudicesTypes.${prejudice.type}.writtenPrint.rows.firstPart`,
    );
    const rowText = i18next.t(
      'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.writtenPrint.rows.row.text',
      {
        victimeName: displayVictimeNameWrittenPrint(victime),
        numerosPieces: displayNumerosPiecesWrittenPrint(
          row.numerosPieces,
          'Parentheses',
        ),
      },
    );
    const degreText = row.degre
      ? displayNumerosPiecesWrittenPrint(row.numerosPieces, 'WithComma') +
        i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.writtenPrint.rows.row.degre',
          {
            degre: i18next.t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.fields.rows.degre.value',
              {
                degre: fDecimalNumber(row.degre, 2, true),
              },
            ),
          },
        )
      : null;

    const maladieText = row.maladie
      ? i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.writtenPrint.rows.row.object',
          {
            object: row.maladie,
          },
        )
      : null;

    const periodeText = getPeriodeWrittenPrint({
      dateDebut: row.dateDebut,
      dateFin: row.dateFin,
      duree: row.jours,
      options: { spaceBefore: true },
    });

    const allRowText = `${
      displayDegre && (row.degre || row.degre === 0) ? degreText : ''
    }${maladieText ?? ''}${periodeText}`;

    return getParagraph({
      ...(isMultipleRows
        ? {
            numbering: {
              reference: 'simple-list',
              level: 0,
            },
          }
        : {}),
      children: [
        ...getTextRun(allRowText),
        ...getTextRun({ break: 1 }),
        ...(row.montant
          ? getTextRun({
              text: i18next.t(
                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_MALADIE.writtenPrint.rows.row.montantPeriode',
                {
                  montant: fCurrency(row.montant),
                  context:
                    !row.dateDebut && !row.dateFin && !row.jours
                      ? 'noPeriode'
                      : undefined,
                },
              ),
            })
          : []),
      ],
    });
  };
  const rows = formData.prejudiceValues.rows;
  const firstPartParagraphe = getParagraph({
    children: [
      ...getTextRun(firstPartText),
      ...getTextRun(rowText),
      getEmptyLine(),
    ],
  });
  const rowsParagraphsByNumberOfRows: Record<
    'noRow' | 'oneRow' | 'multipleRows',
    Paragraph[]
  > = {
    noRow: [],
    oneRow: rows[0] ? [firstPartParagraphe, getRow(rows[0])] : [],
    multipleRows: [
      firstPartParagraphe,
      ...rows.map((row) => getRow(row, true)),
    ],
  };
  return [
    ...rowsParagraphsByNumberOfRows[
      rows.length === 0
        ? 'noRow'
        : rows.length === 1
          ? 'oneRow'
          : 'multipleRows'
    ],
  ];
};
