import React from 'react';
import { Control } from 'react-hook-form';

import {
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
} from '../../../../types/prejudice.type';
import { Procedure } from '../../../../types/procedure.type';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { CalculsFormNewPerteGainsProfessionnelsFuturs } from 'src/constants/calculs';
import { TotalPerteGainsFutursContent } from '../perteGainsProfessionnelsFuturs/TotalPerteGainsFutursContent';

interface Props {
  control: Control<NewPrejudiceFormPerteGainProfessionnelsFuturs>;
  procedure: Procedure;
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  dateDeces: Date | undefined;
}

export const TotalNewPerteGainsFuturs: React.FC<Props> = ({
  control,
  procedure,
  dateConsolidation,
  dateLiquidation,
  dateDeces,
}) => {
  return (
    <ComputedPropsForm
      control={control}
      watchFields={[
        'perteDeGainsProfessionnels.total',
        'indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet',
        'renteCapitalisee',
        'tiersPayeursTotalCapitalise',
        'indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows',
        'capitalisationTiersPayeurs',
      ]}
      compute={([
        perteGainProfessionnelsTotal,
        indemnitesJournalieresPercuesNet,
        renteCapitalisee,
        tiersPayeursTotalCapitalise,
        indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
        capitalisationTiersPayeurs,
      ]) => {
        return {
          props: {
            ...CalculsFormNewPerteGainsProfessionnelsFuturs.getIndemnitesRepartie(
              {
                perteGainProfessionnelsTotal,
                indemnitesJournalieresPercuesNet,
                indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
                tiersPayeursTotalCapitalise,
                renteCapitalisee,
                partResponsabilite: procedure.partResponsabilite,
                capitalisationTiersPayeurs,
                tiersPayeurs: procedure.tiersPayeurs,
                dateConsolidation,
                dateLiquidation,
                dateDeces,
              },
            ),
          },
        };
      }}
      render={({
        PGPFReliquat,
        indemniteGlobaleARepartir,
        indemniteVictime,
        indemniteTiersPayeurs,
      }) => (
        <TotalPerteGainsFutursContent
          control={
            control as Control<
              | NewPrejudiceFormPerteGainProfessionnelsFuturs
              | OldPrejudiceFormPerteGainProfessionnelsFuturs
              | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
            >
          }
          indemniteRepartie={{
            indemniteGlobaleARepartir,
            indemniteVictime,
            indemniteTiersPayeurs,
          }}
          PGPFReliquat={PGPFReliquat}
          partResponsabilite={procedure.partResponsabilite}
        />
      )}
    />
  );
};
