import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Route, useParams } from 'react-router-dom';
import { theme } from 'src/constants/theme';
import { useAppSelector } from 'src/hooks/store';
import { Procedures } from './Procedures';
import { Procedure } from './Procedure';
import {
  Procedure as IProcedure,
  ProcedureQuestion,
} from '../../../types/procedure.type';
import { Prejudices } from './Prejudices';
import { victimeSelectors } from '../../../store/selectors';
import { PATH_PREJUDICES, PATH_PROCEDURE } from '../../../routes/paths';
import { EssentialInformations } from 'src/components/client/EssentialInformations';
import { ProcedureEdition } from './ProcedureEdition';
import { SentryRoutes } from 'src/routes/sentry';
interface Props {
  onClickEditVictime: () => void;
  editVictimeOpen: boolean;
}
export type TOpenEditProcedure =
  | {
      answers?: ProcedureQuestion[];
      procedure?: IProcedure;
    }
  | undefined;

export const ClientProcedures: React.FC<Props> = ({
  editVictimeOpen,
  onClickEditVictime,
}) => {
  const { victimeId } = useParams<any>();
  const { victime } = useAppSelector((state) => ({
    victime: victimeId
      ? victimeSelectors.selectById(state, victimeId)
      : undefined,
  }));
  const [openEditProcedure, setOpenEditProcedure] =
    useState<TOpenEditProcedure>(undefined);

  if (!victime) {
    return (
      <Typography
        variant="h6"
        component="h3"
        style={{ paddingTop: theme.spacing(2) }}
      >
        Procedures
      </Typography>
    );
  }

  return (
    <>
      <SentryRoutes>
        <Route
          path="/"
          element={
            <>
              <EssentialInformations
                victime={victime}
                onClickEdit={onClickEditVictime}
                editVictimeOpen={editVictimeOpen}
              />
              <Procedures
                victime={victime}
                setOpenEditProcedure={setOpenEditProcedure}
              />
            </>
          }
        />
        <Route
          path={PATH_PROCEDURE}
          element={
            <Procedure
              victime={victime}
              setOpenEditProcedure={setOpenEditProcedure}
            />
          }
        />
        <Route
          path={PATH_PREJUDICES}
          element={<Prejudices victime={victime} />}
        />
      </SentryRoutes>
      <ProcedureEdition
        victime={victime}
        isSidebar={true}
        isOpen={!!openEditProcedure}
        closeSideBar={() => {
          setOpenEditProcedure(undefined);
        }}
        answers={openEditProcedure?.answers}
        procedure={openEditProcedure?.procedure}
      />
    </>
  );
};
