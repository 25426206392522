import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TextFieldForm } from '../forms/TextFieldForm';
import { CheckboxFormField } from '../forms/CheckboxFormField';
import { CreateInvitationDto, Invitation } from 'src/types/invitation.type';
import { UserJobType } from 'src/types/auth.type';
import { SelectFieldForm } from '../forms/SelectFieldForm';
interface Props {
  invitation?: Invitation;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: CreateInvitationDto) => void;
  onDelete?: () => void;
  resendInvitation?: () => void;
}

export const CreateOrUpdateInvitationDialog: React.FC<Props> = ({
  invitation,
  open,
  ...props
}) => {
  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open]);
  const { t } = useTranslation();
  const defaultValues: CreateInvitationDto = {
    email: invitation?.email || '',
    company: invitation?.company || '',
    jobType: invitation?.jobType,
    disabled: invitation?.disabled || false,
  };
  const { control, handleSubmit, reset } = useForm<CreateInvitationDto>({
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email().required(),
        jobType: Yup.string().optional(),
        disabled: Yup.boolean().required(),
        company: Yup.string(),
      }),
    ),
  });

  const onSubmit = (values: CreateInvitationDto) => {
    reset();
    props.onSubmit(values);
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {invitation
            ? t('pages.Admin.updateInvitationDialog.title')
            : t('pages.Admin.createInvitationDialog.title')}
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextFieldForm
              control={control}
              name="email"
              label={t('common.email')}
            />
            <Stack
              marginTop={2}
              marginBottom={0.5}
              direction="row"
              alignItems="center"
            >
              <Typography variant="body1">{t('common.disabled')}:</Typography>
              <CheckboxFormField control={control} name="disabled" />
            </Stack>
            <TextFieldForm
              control={control}
              name="company"
              label={t('common.company')}
            />
            <SelectFieldForm
              control={control}
              name="jobType"
              label={t('user.jobType.label')}
              allowNoSelection
              InputLabelProps={{ shrink: true }}
              options={Object.values(UserJobType).map((jobType) => ({
                value: jobType,
                label: t(`user.jobType.options.${jobType}`),
              }))}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ paddingX: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            {t('common.submit')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
