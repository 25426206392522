export const REGIME_TYPE_LISTE = [
  'SALARIE_RG',
  'SALARIE_FP',
  'INDEPENDANT_ARTISTES_AUTEURS',
  'INDEPENDANT_HORS_MICRO',
  'MICRO_ENTREPRENEUR_BNC',
  'MICRO_ENTREPRENEUR_BIC_ARTISAN',
  'MICRO_ENTREPRENEUR_BIC_ACHAT_VENTE',
  'CHOMAGE',
] as const;
export type RegimeType = (typeof REGIME_TYPE_LISTE)[number];
