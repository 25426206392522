import { Paragraph, Table, TableOfContents } from 'docx';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { getEmptyLine, getParagraph } from '../../../docxFunctions';
import i18next from 'i18next';
import { Victime } from 'src/types/victime.type';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayFormula } from '../../formula';

export const conjointWrittenPrint = ({
  formData,
  victime,
}: {
  formData: PrejudiceFormPerteRevenusProche;
  victime: Victime;
}): (Table | TableOfContents | Paragraph)[] => {
  return [
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 0,
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.conjoint.title',
        {
          context: victime.dateDeces ? 'deces' : undefined,
        },
      ),
    }),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.conjoint.firstPart',
        {
          total: fCurrency(formData.conjoint.total),
          formula:
            formData.foyer.prejudiceViager || formData.enfants.total
              ? displayFormula({
                  formula: i18next.t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.total.formula',
                    {
                      prejudiceViagerFoyer: fCurrency(
                        formData.foyer.prejudiceViager,
                      ),
                      totalEnfants: fCurrency(formData.enfants.total),
                    },
                  ),
                  options: {
                    withParentheses: true,
                  },
                  editedFieldsParameters: {
                    formData,
                    fieldName: 'conjoint.total',
                  },
                })
              : '',
        },
      ),
    ),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.conjoint.secondPart',
        {
          totalApresCapitauxDeces: fCurrency(
            formData.conjoint.totalApresCapitauxDeces,
          ),
          formula:
            formData.conjoint.total || formData.conjoint.capitauxDeces.total
              ? displayFormula({
                  formula: i18next.t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.totalApresCapitauxDeces.formula',
                    {
                      total: fCurrency(formData.conjoint.total),
                      totalCapitauxDeces: fCurrency(
                        formData.conjoint.capitauxDeces.total,
                      ),
                    },
                  ),
                  options: {
                    withParentheses: true,
                  },
                  editedFieldsParameters: {
                    formData,
                    fieldName: 'conjoint.totalApresCapitauxDeces',
                  },
                })
              : '',
        },
      ),
    ),
  ];
};
