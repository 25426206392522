import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SendHelpRequest } from 'src/components/help/SendHelpRequest';
import { CalendarIcon } from '@mui/x-date-pickers';

const PresentationVideo = () => (
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/YmCJKNNbO78"
    title="Présentation de Norma"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  />
);

const Help: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack spacing={4} direction="column" alignItems="center" marginTop={2}>
      <Typography variant="h4">{t('pages.Help.title')}</Typography>
      <Stack alignItems="center" spacing={1}>
        <Typography variant="body1">
          {t('pages.Help.scheduleMeeting.title')}
        </Typography>
        <Stack
          component="a"
          href="https://calendly.com/clara-norma"
          target="_blank"
          rel="noreferrer"
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <CalendarIcon />
          <Typography>{t('pages.Help.scheduleMeeting.anchorText')}</Typography>
        </Stack>
      </Stack>
      <Divider sx={{ width: 500 }} />
      <Stack alignItems="center" spacing={2}>
        <Typography variant="body1">{t('pages.Help.description')}</Typography>
        <PresentationVideo />
      </Stack>
      <Divider sx={{ width: 500 }} />
      <SendHelpRequest />
    </Stack>
  );
};

export default Help;
