import {
  BaremeAdditionalPropertiesPrejudiceType,
  BaremeSource,
} from 'src/types/bareme.type';

export const baremeIndemnisationSourcesOrder: Partial<
  Record<BaremeSource, number>
> = {
  Mornet: 1,
  ONIAM: 2,
  FGTI: 3,
  Quezel: 4,
};

export const baremeIndmenisationAdditionnalPropertiesPrejudiceOrder: Partial<
  Record<BaremeAdditionalPropertiesPrejudiceType, number>
> = {
  SOUFFRANCES_ENDUREES: 1,
  PREJUDICE_ESTHETIQUE: 2,
};

export const baremeRIISourcesOrder: Partial<Record<BaremeSource, number>> = {
  Mornet: 1,
  ONIAM: 2,
  FGTI: 3,
  Quezel: 4,
};

export const baremeCapitalisationSourcesOrder: Partial<
  Record<BaremeSource, number>
> = {
  GDP: 1,
  BCRIV: 2,
  CJ: 3,
  ONIAM: 4,
};
