import { styled } from '@mui/material';

export const PrejudiceForm = styled('form')(({ theme }) => ({
  flex: 1,
  overflowX: 'hidden',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(3.5),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
}));
