import React, { useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { CompleteGoogleSignUpDto, UserJobType } from 'src/types/auth.type';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { completeGoogleSignUp } from 'src/slices/auth';
import { MaskPhoneNumber } from 'src/components/masks/MaskPhoneNumber';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AcceptConditions } from 'src/components/authentication/AcceptConditions';
import { logout } from 'src/helpers/auth';

export const CompleteGoogleSignUp: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ auth: { user } }) => ({ user }));
  const { control, handleSubmit } = useForm<CompleteGoogleSignUpDto>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      company: user?.company,
      phoneNumber: user?.phoneNumber,
      jobType: undefined,
    },
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        company: yup.string().required(),
        phoneNumber: yup.string().length(10).required(),
        jobType: yup.string().oneOf(Object.values(UserJobType)).required(),
      }),
    ),
  });
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const onSubmit = async (dto: CompleteGoogleSignUpDto) => {
    dispatch(completeGoogleSignUp(dto));
  };

  const onCancel = () => {
    logout(dispatch);
  };

  return (
    <Stack
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
      marginX="auto"
      height="100%"
      maxWidth={400}
    >
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        {t('pages.CompleteGoogleSignUp.title')}
      </Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        width="100%"
      >
        <TextField
          label={t('common.email')}
          value={user?.email}
          disabled
          fullWidth
          margin="dense"
        />
        <TextFieldForm
          control={control}
          name="firstName"
          label={t('common.firstName')}
        />
        <TextFieldForm
          control={control}
          name="lastName"
          label={t('common.lastName')}
        />
        <TextFieldForm
          control={control}
          fullWidth
          label={t('common.phoneNumber')}
          name="phoneNumber"
          type="phone"
          InputProps={{
            inputComponent: MaskPhoneNumber as any,
          }}
        />
        <TextFieldForm
          control={control}
          name="company"
          label={t('common.company')}
        />
        <SelectFieldForm
          control={control}
          name="jobType"
          label={t('user.jobType.label')}
          options={Object.values(UserJobType).map((jobType) => ({
            value: jobType,
            label: t(`user.jobType.options.${jobType}`),
          }))}
        />
        <AcceptConditions
          checked={acceptedConditions}
          onClickCheck={() => setAcceptedConditions((previous) => !previous)}
        />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button onClick={onCancel}>{t('common.cancel')}</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!acceptedConditions}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
