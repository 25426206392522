import {
  ISectionOptions,
  Paragraph,
  Table,
  TableOfContents,
  UnderlineType,
} from 'docx';
import {
  NumeroPieceValuesRow,
  Prejudice,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getPrejudicesPerCategoryPerDirectEntries } from '../prejudices/sortAndOrder';
import { getEmptyLine, getParagraph, getTextRun } from './docxFunctions';
import i18next from 'i18next';
import { getNumeroPiecesByPrejudice } from '../prejudices/numeroPiece';

const getNumeroPiece = (numerosPieces: NumeroPieceValuesRow): Paragraph =>
  getParagraph({
    indent: {
      left: 200,
    },
    text: i18next.t(`print.numeroPiece.text`, {
      numeroPiece: numerosPieces.numeroPiece,
      libelle: numerosPieces.libelle,
    }),
  });

const getPrejudiceTitle = (prejudiceType: PrejudiceType) =>
  i18next.t(`prejudice.prejudicesTypes.${prejudiceType}.title`);

export const getPrejudiceNumeroPiece = (
  prejudice: Prejudice,
): (Paragraph | Table | TableOfContents)[] | undefined => {
  const numeroPieces = getNumeroPiecesByPrejudice(prejudice);
  return numeroPieces?.reduce(
    (accumulator: (Paragraph | Table | TableOfContents)[], numeroPiece) => {
      return [...accumulator, getNumeroPiece(numeroPiece), getEmptyLine()];
    },
    [],
  );
};

export const getBordereauTitle = () =>
  getParagraph({
    children: getTextRun({
      text: i18next.t('print.numeroPiece.title'),
      bold: true,
      size: 26,
    }),
  });

export const numeroPiecesPrint = ({
  procedure,
  prejudices,
  victime,
  victimesIndirectes,
}: {
  procedure: Procedure;
  prejudices: Prejudice[];
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
}): ISectionOptions => {
  const prejudicesPerCategory = getPrejudicesPerCategoryPerDirectEntries({
    procedure,
    prejudices,
    victime,
    victimesIndirectes,
  });
  return {
    children: [
      getBordereauTitle(),
      ...Object.entries(prejudicesPerCategory).reduce(
        (
          accumulator: (Paragraph | Table | TableOfContents)[],
          [type, prejudicesPerCategory],
        ) => {
          const prejudiceTypeTitle = getParagraph({
            children: getTextRun({
              text: i18next.t(`prejudice.directe.${type}`),
              underline: {
                type: UnderlineType.SINGLE,
              },
              break: 1,
              bold: true,
              size: 24,
            }),
          });
          const prejudicesPrintPerCategory = prejudicesPerCategory.reduce(
            (
              prejudicesPrintPerCategoryAccumulator: (
                | Paragraph
                | Table
                | TableOfContents
              )[],
              [category, prejudices],
            ) => {
              const categoryTitle = getParagraph({
                children: getTextRun({
                  text: i18next.t(`prejudice.categories.${category}`),
                  underline: {
                    type: UnderlineType.SINGLE,
                  },
                  break: 1,
                  bold: true,
                }),
              });
              const categoryPrejudices = prejudices.reduce(
                (acc: (Paragraph | Table | TableOfContents)[], prejudice) => {
                  const prejudiceNumeroPiece =
                    getPrejudiceNumeroPiece(prejudice);
                  if (prejudiceNumeroPiece && prejudiceNumeroPiece.length > 0) {
                    const prejudiceTitle = getParagraph({
                      indent: {
                        left: 100,
                      },
                      children: getTextRun({
                        text: getPrejudiceTitle(prejudice.type),
                        underline: {
                          type: UnderlineType.SINGLE,
                        },
                        break: 1,
                      }),
                    });
                    acc.push(prejudiceTitle);
                    acc.push(...prejudiceNumeroPiece);
                  }
                  return acc;
                },
                [],
              );
              if (categoryPrejudices.length > 0) {
                prejudicesPrintPerCategoryAccumulator.push(categoryTitle);
                prejudicesPrintPerCategoryAccumulator.push(getEmptyLine());
                prejudicesPrintPerCategoryAccumulator.push(
                  ...categoryPrejudices,
                );
              }
              return prejudicesPrintPerCategoryAccumulator;
            },
            [],
          );

          if (prejudicesPrintPerCategory.length > 0) {
            accumulator.push(prejudiceTypeTitle);
            accumulator.push(getEmptyLine());
            accumulator.push(...prejudicesPrintPerCategory);
          }
          return accumulator;
        },
        [],
      ),
    ],
  };
};
