import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextFieldForm } from '../forms/TextFieldForm';
import { Stack, Typography } from '@mui/material';

interface FormData {
  entityText: string;
}

interface Props {
  title: string;
  description?: string;
  open: boolean;
  confirmText?: string;
  cancelText?: string;
  entityText: string;
  onCancel: () => void;
  onConfirm: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export const DeleteConfirmationDialog: React.FC<Props> = ({
  title,
  description,
  open,
  entityText,
  onCancel,
  onConfirm,
  maxWidth = 'xs',
  ...props
}) => {
  const { t } = useTranslation();
  const confirmText = props.confirmText ?? t('common.delete');
  const cancelText = props.cancelText ?? t('common.cancel');
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      entityText: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        entityText: yup.string().required().oneOf([entityText]),
      }),
    ),
  });
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth={maxWidth}
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack component="form" onSubmit={handleSubmit(onConfirm)}>
          <Typography marginBottom={1}>
            {description ?? t('common.deleteConfirmationDialog.description')}
          </Typography>
          <TextFieldForm
            control={control}
            name="entityText"
            placeholder={entityText}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            marginTop={2}
            spacing={2}
          >
            <Button autoFocus onClick={onCancel} variant="contained">
              {cancelText}
            </Button>
            <Button variant="text" type="submit" color="error">
              {confirmText}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
