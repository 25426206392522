import { replace, round } from 'lodash';
import numeral from 'numeral';

/* Set default locale */
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'e';
  },
  currency: {
    symbol: '€',
  },
});
numeral.locale('fr');
// ----------------------------------------------------------------------

export function fCurrency(number: number, precision = 2) {
  return numeral(fRound(number, precision)).format(
    `0,0[.]${'0'.repeat(precision)} $`,
  );
}

export function fPercent(number: number, precision = 1) {
  return numeral(number / 100).format(`0,0[.]${'0'.repeat(precision)}%`);
}

export function fNumber(number: number) {
  return numeral(number).format();
}

export function fDecimalNumber(
  number: number,
  precision = 2,
  removeTrailingZeros = false,
) {
  const roundedNumber = fRound(number, precision);
  if (removeTrailingZeros) {
    return numeral(roundedNumber).format(`0,0.[${'0'.repeat(precision)}]`);
  } else {
    return numeral(roundedNumber).format(`0,0[.]${'0'.repeat(precision)}`);
  }
}

export function fShortenNumber(number: number) {
  return replace(numeral(number).format('0 0.00a'), '.00', '');
}

export function fData(number: number) {
  return numeral(number).format('0.0 b');
}

export function fTrunc(number: number, decimals = 2) {
  const factor = 10 ** decimals;
  const numberToTrunc = +(number * factor).toFixed(2);
  return Math.trunc(numberToTrunc) / factor;
}

export function fRound(number: number, decimals = 2) {
  return round(number, decimals);
}
