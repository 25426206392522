import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Stack,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { theme } from 'src/constants/theme';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { logout } from 'src/helpers/auth';
import { ResourcesDrawer } from 'src/components/resources/ResourcesDrawer';
import { SettingsDrawer } from 'src/components/settings/SettingsDrawer';
import { OrganizationsSelector } from './OrganizationsSelector';
import { useIsSubscribed } from 'src/hooks/store/subscription.hooks';
import { HelpDialog } from 'src/components/help/HelpDialog';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';

interface Props {
  noButtons?: boolean;
  children: React.ReactNode;
  isOrganizationLayout?: true;
  noResources?: boolean;
}

export const LoggedLayout: React.FC<Props> = ({
  children,
  noButtons,
  isOrganizationLayout,
  noResources,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const noHeader = location.pathname.startsWith('/bareme/');

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  const [openDrawerSettings, setOpenDrawerSettings] = useState<boolean>(false);
  const [openDrawerResources, setOpenDrawerResources] =
    useState<boolean>(false);

  const onClickHelp = () => {
    navigate(`/organization/${organizationId}/help`);
  };
  const isSubscribed = useIsSubscribed();
  const { organizationId } = useDisplayedOrganization();
  return (
    <>
      {!noHeader && (
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            top: '0',
            height: '64px',
            zIndex: '2',
          }}
        >
          <AppBar
            position="sticky"
            sx={(theme) => ({ backgroundColor: theme.palette.primary.dark })}
          >
            <Toolbar>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ flexGrow: 1 }}
              >
                <Typography variant="h3" component="div">
                  <Link
                    to={
                      isOrganizationLayout
                        ? `/organization/${organizationId}`
                        : '/'
                    }
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Box
                        component="img"
                        src="/norma_logo_white.png"
                        sx={{ height: '31.5px' }}
                      />
                      <Box
                        component="img"
                        src="/norma_text.png"
                        sx={{ height: '17.5px' }}
                      />
                    </Stack>
                  </Link>
                </Typography>
                {isOrganizationLayout && <OrganizationsSelector />}
              </Stack>
              {noButtons ? null : (
                <>
                  <HelpDialog icon={false} />
                  {isSubscribed ? (
                    <Button
                      variant="contained"
                      onClick={onClickHelp}
                      sx={(theme) => ({
                        borderRadius: `${theme.shape.borderRadius}px`,
                        margin: theme.spacing(0, 2),
                        backgroundColor: '#1270FD',
                        transform: 'scale(1)',
                        transition: '0.3s linear',
                        '&:hover': {
                          backgroundColor: '#1270FD',
                          transform: 'scale(1.1)',
                          transition: '0.3s linear',
                        },
                      })}
                    >
                      {t('loggedLayout.help')}
                    </Button>
                  ) : null}
                  {noResources ? null : (
                    <Button
                      sx={(theme) => ({
                        borderRadius: `${theme.shape.borderRadius}px`,
                        margin: theme.spacing(0, 2),
                        backgroundColor: '#1270FD',
                        transform: 'scale(1)',
                        transition: '0.3s linear',
                        '&:hover': {
                          backgroundColor: '#1270FD',
                          transform: 'scale(1.1)',
                          transition: '0.3s linear',
                        },
                      })}
                      color="inherit"
                      onClick={() => setOpenDrawerResources(true)}
                    >
                      {t('loggedLayout.resources')}
                    </Button>
                  )}
                </>
              )}
              <IconButton
                size="medium"
                edge="start"
                aria-label="menu"
                sx={{
                  backgroundColor: theme.palette.common.white,
                  color: '#000000',
                  marginLeft: theme.spacing(1),
                  '&:hover': {
                    backgroundColor: theme.palette.common.white,
                  },
                }}
                onClick={() => setOpenDrawerSettings(true)}
              >
                <MoreHorizIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '100%',
          height: 'calc(100% - 64px)',
          padding: '0',
          top: '64px',
          position: 'relative',
        }}
      >
        {children}
      </Box>
      <ResourcesDrawer
        open={openDrawerResources}
        onClose={() => setOpenDrawerResources(false)}
      />
      <SettingsDrawer
        open={openDrawerSettings}
        user={user}
        noSettingsLink={noButtons}
        isOrganizationSettings={isOrganizationLayout}
        onClickLogout={() => logout(dispatch)}
        onClose={() => setOpenDrawerSettings(false)}
      />
    </>
  );
};
