import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { apiRequest } from 'src/helpers/api';

interface Props {
  organizationId: string;
}

export const UpdateOrganizationPaymentMethod: React.FC<Props> = ({
  organizationId,
}) => {
  const { t } = useTranslation();

  const onClickUpdatePaymentMethod = async () => {
    const result = await apiRequest<{ url: string }>(
      'PUT',
      `/organization/${organizationId}/subscription/paymentMethod`,
    );
    if (!result.url) {
      return;
    }
    window.location.href = result.url;
  };

  return (
    <Stack>
      <Typography>
        {t(
          'pages.OrganizationConfiguration.UpdateOrganizationPaymentMethod.description',
        )}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onClickUpdatePaymentMethod}
      >
        {t(
          'pages.OrganizationConfiguration.UpdateOrganizationPaymentMethod.button',
        )}
      </Button>
    </Stack>
  );
};
