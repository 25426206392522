import { Paragraph, Table, TableOfContents } from 'docx';
import {
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormCalendrierAssistanceRow,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import {
  NumberingType,
  getEmptyLine,
  getParagraph,
  getTextRun,
} from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { fDate, fDays, fWeeks } from 'src/helpers/formatTime';
import {
  CalculsFormCalendrierAssistance,
  CalculsGlobal,
} from 'src/constants/calculs';
import { getRevalorisationIntroductionWrittenPrintIfRevalorise } from '../revalorisation.written.print';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { displayFormula } from '../formula';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';
import ATPTWrittenPrintFunctions from './atpt.written.print';
import { totalCapitalisationWrittenPrint } from '../totalCapitalisation.written.print';
import { Bareme } from 'src/types/bareme.type';
import { indemniteGlobaleARepartirAEchoirWrittenPrint } from '../indemniteGlobaleARepartir/indemniteGlobaleARepartirAEchoir.written.print';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';
import { rentesPrint } from '../../prejudicesPrints/rentesPrint';

export const atpfWrittenPrint = ({
  victime,
  procedure,
  formData,
  monetaryErosions,
  baremes,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormCalendrierAssistanceCapitalisation;
  monetaryErosions: MonetaryErosion[];
  baremes: Bareme[];
}): (Table | TableOfContents | Paragraph)[] => {
  const getPeriodeText = (row: PrejudiceFormCalendrierAssistanceRow) =>
    i18next.t(
      'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.periode',
      {
        dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        dateFin: row.dateFin ? fDate(row.dateFin) : '',
        duree: fDays(row.joursPeriode),
        dureeEnSemaines: fWeeks(CalculsGlobal.getDaysInWeeks(row.joursPeriode)),
        commentaires: row.commentaires ? ` (${row.commentaires})` : '',
      },
    );
  const getMontantTotal = (
    row: PrejudiceFormCalendrierAssistanceRow,
    index: number,
  ) =>
    row.montantTotal
      ? `${i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.montantTotal',

          {
            annualiseMontantTotal: fCurrency(row.annualiseMontantTotal || 0),
            montantTotal: fCurrency(row.montantTotal),
            formula: displayFormula({
              formula: i18next.t(
                'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE_CAPITALISATION.fields.rows.montantTotal.formula',
                {
                  annualiseMontantTotal: fCurrency(
                    row.annualiseMontantTotal || 0,
                  ),
                  totalJours: fDays(row.totalJours),
                  joursConges: fDays(row.joursConges),
                },
              ),
              options: {
                withParentheses: true,
              },
              editedFieldsParameters: {
                formData,
                fieldName: `rows.${index}.montantTotal`,
              },
            }),
          },
        )}`
      : null;
  const getEntreConsolidationEtLiquidationRowParagraphs = (
    row: PrejudiceFormCalendrierAssistanceRow,
    index: number,
  ): Paragraph[] => {
    const periodeText = getPeriodeText(row);

    const joursCongesText = ATPTWrittenPrintFunctions.getJoursCongesText(
      row,
      index,
      formData,
    );
    const nombreHeureParUniteChoisieText =
      ATPTWrittenPrintFunctions.getNombreHeureParUniteChoisieText(
        row,
        index,
        formData,
        victime,
      );
    const typeAssistance = ATPTWrittenPrintFunctions.getTypeAssistanceText(row);
    const montantTotal = getMontantTotal(row, index);

    const tiersPayeursTextParagraphs =
      ATPTWrittenPrintFunctions.getTiersPayeursParagraphs(row);

    const deductionFiscaleText =
      ATPTWrittenPrintFunctions.getDeductionFiscaleText(row);

    const autresDeductionsText =
      ATPTWrittenPrintFunctions.getAutresDeductionsText(row);

    const resteAChargeText = ATPTWrittenPrintFunctions.getResteAChargeText(
      row,
      victime,
      procedure,
      monetaryErosions,
      formData,
    );
    return [
      getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: getTextRun(periodeText),
      }),
      ...(joursCongesText ? [getParagraph(joursCongesText)] : []),
      ...(nombreHeureParUniteChoisieText
        ? [getParagraph(nombreHeureParUniteChoisieText)]
        : []),
      ...(typeAssistance ? [getParagraph(typeAssistance)] : []),
      ...(montantTotal ? [getParagraph(montantTotal)] : []),
      ...(tiersPayeursTextParagraphs.length > 0
        ? [getEmptyLine(), ...tiersPayeursTextParagraphs]
        : []),
      ...(deductionFiscaleText ? [getParagraph(deductionFiscaleText)] : []),
      ...(autresDeductionsText ? [getParagraph(autresDeductionsText)] : []),
      ...(resteAChargeText ? [getParagraph(resteAChargeText)] : []),
    ];
  };
  const getApresLiquidationRowParagraphs =
    getEntreConsolidationEtLiquidationRowParagraphs;
  const getApresLiquidationNoDateFinRowParagraphs = (
    row: PrejudiceFormCalendrierAssistanceRow,
    index: number,
  ): Paragraph[] => {
    const periodeText = i18next.t(
      'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.apresLiquidation.noDateFin.periode',
      {
        dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        commentaires: row.commentaires ? ` (${row.commentaires})` : '',
      },
    );

    const joursCongesText = ATPTWrittenPrintFunctions.getJoursCongesText(
      row,
      index,
      formData,
    );
    const nombreHeureParUniteChoisieText = i18next.t(
      'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.apresLiquidation.noDateFin.nombreHeureParUniteChoisie',
      {
        nombreHeureParUniteChoisie: i18next.t(
          'prejudice.prejudicesFormTypes.CALENDRIER_ASSISTANCE.fields.rows.nombreHeureParUniteChoisie.print.value',
          {
            nombreHeureParUniteChoisie: fDecimalNumber(
              row.nombreHeureParUniteChoisie,
            ),
            uniteNombreHeure: row.uniteNombreHeure,
          },
        ),
        victimeName: displayVictimeNameWrittenPrint(victime),
      },
    );
    const typeAssistance = ATPTWrittenPrintFunctions.getTypeAssistanceText(row);
    const annualiseMontantTotal = i18next.t(
      'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.apresLiquidation.noDateFin.annualiseMontantTotal',
      {
        annualiseMontantTotal: fCurrency(row.annualiseMontantTotal || 0),
      },
    );
    return [
      getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: getTextRun(periodeText),
      }),
      ...(joursCongesText ? [getParagraph(joursCongesText)] : []),
      ...(nombreHeureParUniteChoisieText
        ? [getParagraph(nombreHeureParUniteChoisieText)]
        : []),
      ...(typeAssistance ? [getParagraph(typeAssistance)] : []),
      ...(annualiseMontantTotal ? [getParagraph(annualiseMontantTotal)] : []),
    ];
  };
  const totaux = CalculsFormCalendrierAssistance.totaux({
    rows: formData.rows,
    partResponsabilite: procedure.partResponsabilite,
    tiersPayeurs: procedure.tiersPayeurs,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    monetaryErosions,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });
  const indemniteRepartie =
    CalculsFormCalendrierAssistance.getAssistanceIndemnitesRepartie({
      ...formData,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      monetaryErosions,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
  const totalPriseEnChargeTiersPayeurs = CalculsGlobal.sum(
    Object.values(totaux.priseEnChargeTiersPayeurs).map(
      ({ total }) => total.value,
    ),
  );
  const entreConsolidationEtLiquidationRows = formData.rows.filter(
    (row) => row.type === 'echu' && (!!row.montantTotal || !!row.resteACharge),
  );
  const apresLiquidationRows = formData.rows.filter(
    (row) =>
      row.type === 'aEchoir' &&
      !!row.dateFin &&
      (!!row.montantTotal || !!row.resteACharge),
  );
  const apresLiquidationNoDateFinRows = formData.rows.filter(
    (row) =>
      row.type === 'aEchoir' && !row.dateFin && !!row.annualiseMontantTotal,
  );
  const entreConsolidationEtLiquidationFirstPartText = i18next.t(
    'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.entreConsolidationEtLiquidationRows.firstPart',
  );
  const apresLiquidationFirstPartText = i18next.t(
    'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.apresLiquidation.firstPart',
  );
  const apresLiquidationNoDateFinFirstPartText = i18next.t(
    'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.apresLiquidation.noDateFin.firstPart',
  );

  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation: procedure.dateConsolidation
      ? new Date(procedure.dateConsolidation)
      : undefined,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
  });
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      formType: 'CALENDRIER_ASSISTANCE',
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      formData,
    }),
    getParagraph({
      numbering: {
        reference: NumberingType.ROMAN_NUMERALS,
        level: 0,
      },
      children: getTextRun(
        i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.titles.atpf',
        ),
      ),
    }),
    ...(entreConsolidationEtLiquidationRows.length > 0
      ? [
          getParagraph(entreConsolidationEtLiquidationFirstPartText),
          ...entreConsolidationEtLiquidationRows
            .map((row, index) => [
              ...(index !== 0 ? [getEmptyLine()] : []),
              ...getEntreConsolidationEtLiquidationRowParagraphs(row, index),
            ])
            .flat(),
          getEmptyLine(),
        ]
      : []),
    ...(apresLiquidationRows.length > 0
      ? [
          getParagraph(apresLiquidationFirstPartText),
          ...apresLiquidationRows
            .map((row, index) => [
              ...(index !== 0 ? [getEmptyLine()] : []),
              ...getApresLiquidationRowParagraphs(row, index),
            ])
            .flat(),
          getEmptyLine(),
        ]
      : []),
    ...(apresLiquidationNoDateFinRows.length > 0
      ? [
          getParagraph(apresLiquidationNoDateFinFirstPartText),
          ...apresLiquidationNoDateFinRows
            .map((row, index) => [
              ...(index !== 0 ? [getEmptyLine()] : []),
              ...getApresLiquidationNoDateFinRowParagraphs(row, index),
            ])
            .flat(),
          getEmptyLine(),
        ]
      : []),
    ...(!shouldNotDisplayCapitalisation
      ? [
          getEmptyLine(),
          getParagraph({
            numbering: {
              reference: NumberingType.ROMAN_NUMERALS,
              level: 0,
            },
            children: getTextRun(
              i18next.t(
                'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.titles.capitalisation',
              ),
            ),
          }),
          ...totalCapitalisationWrittenPrint({
            capitalisation: {
              bareme: formData.baremeCapitalisation,
              coefficient: formData.victimeCoefficientCapitalisation,
              montantCapitalise: formData.victimeTotalCapitalise,
              ageDernierArrerage: formData.ageDernierArrerage,
              isLastArrerageViager: formData.isLastArrerageViager,
            },
            tiersPayeursCapitalisation: {
              ...formData.capitalisationTiersPayeurs,
              montantCapitalise: formData.tiersPayeursTotalCapitalise,
            },
            sommeACapitaliser: formData.victimeSommeACapitaliser,
            baremes,
            formData,
            montantCapitaliseName: 'victimeTotalCapitalise',
            procedure,
            victime,
            total:
              formData.victimeTotalCapitalise -
              formData.tiersPayeursTotalCapitalise,
          }),
        ]
      : []),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: NumberingType.ROMAN_NUMERALS,
        level: 0,
      },
      children: getTextRun(
        i18next.t(
          'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.titles.indemniteGlobaleARepartir',
        ),
      ),
    }),
    ...indemniteGlobaleARepartirAEchoirWrittenPrint({
      indemniteRepartie,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      victime,
      indemniteGlobaleFormula:
        totaux.resteACharge.total.value && totalPriseEnChargeTiersPayeurs
          ? i18next.t(
              'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.indemniteGlobaleARepartir.indemniteGlobale.formula',
              {
                totalResteACharge: fCurrency(totaux.resteACharge.total.value),
                totalPriseEnChargeTiersPayeurs: fCurrency(
                  totalPriseEnChargeTiersPayeurs,
                ),
                partResponsabilite: fPartResponsabilite(
                  procedure.partResponsabilite * 100,
                ),
                count: procedure.partResponsabilite === 1 ? 1 : 2,
                context:
                  totaux.resteACharge.total.value === 0
                    ? 'noResteACharge'
                    : totalPriseEnChargeTiersPayeurs === 0
                      ? 'noPriseEnChargeTiersPayeurs'
                      : undefined,
              },
            )
          : '',
      firstPart: i18next.t(
        'prejudice.prejudicesTypes.ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE.writtenPrint.indemniteGlobaleARepartir.firstPart',
      ),
    }),
    ...(formData.isRentesOption && !shouldNotDisplayCapitalisation
      ? [
          getEmptyLine(),
          ...rentesPrint({
            montant: formData.rentes?.montant || 0,
          }),
        ]
      : []),
  ];
};
