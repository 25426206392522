import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { CalculsGlobal } from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { fDate } from 'src/helpers/formatTime';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import {
  PrejudiceFormValeurVictimesIndirectes,
  PrejudiceFormValeurVictimesIndirectesRow,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getEmptyLine } from '../../docxFunctions';
import { FormTableRow, prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { partResponsabiliteTotalPrint } from '../partResponsabiliteTotalPrint';
import { Procedure } from 'src/types/procedure.type';

export const valeurVictimesIndirectesPrint = ({
  formData,
  victimesIndirectes,
  victime,
  procedure,
}: {
  formData: PrejudiceFormValeurVictimesIndirectes;
  victimesIndirectes: VictimeIndirecte[];
  victime: Victime;
  procedure: Procedure;
}): (Paragraph | Table | TableOfContents)[] => {
  const partResponsabilite =
    formData.partResponsabilite || formData.partResponsabilite === 0
      ? formData.partResponsabilite
      : procedure.partResponsabilite;
  const isVictimeIndirecteLieuDeVieColumnVisible = victimesIndirectes.some(
    (victimeIndirecte) => !!victimeIndirecte.lieuDeVie,
  );
  const isAgeAuDecesColumnVisible = Boolean(
    victime.dateDeces &&
      victimesIndirectes.some(
        (victimeIndirecte) => !!victimeIndirecte.dateNaissance,
      ),
  );
  const lieuDeVieRow: FormTableRow<
    PrejudiceFormValeurVictimesIndirectesRow,
    keyof PrejudiceFormValeurVictimesIndirectesRow,
    PrejudiceFormValeurVictimesIndirectesRow[keyof PrejudiceFormValeurVictimesIndirectesRow]
  > = {
    name: 'victimeIndirecteId',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.lieuDeVie.columnHeader',
    ),
    render: (row) => {
      const victimeIndirecte = victimesIndirectes.find(
        (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
      );
      if (!victimeIndirecte?.lieuDeVie) {
        return '';
      }
      return i18next.t(
        `victimeIndirecte.form.fields.lieuDeVie.options.${victimeIndirecte.lieuDeVie}`,
      );
    },
  };
  const ageAuDecesRow: FormTableRow<
    PrejudiceFormValeurVictimesIndirectesRow,
    keyof PrejudiceFormValeurVictimesIndirectesRow,
    PrejudiceFormValeurVictimesIndirectesRow[keyof PrejudiceFormValeurVictimesIndirectesRow]
  > = {
    name: 'victimeIndirecteId',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.ageDeces.columnHeader',
    ),
    render: (row) => {
      const victimeIndirecte = victimesIndirectes.find(
        (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
      );
      if (!victimeIndirecte?.dateNaissance || !victime.dateDeces) {
        return '';
      }
      const ageDecesVictime = CalculsGlobal.getAgeOrZero(
        victimeIndirecte.dateNaissance,
        victime.dateDeces,
      );
      return i18next.t('forms.fields.age.value', {
        count: ageDecesVictime.age,
        context: ageDecesVictime.isExactlyZero ? 'exactly_zero' : undefined,
      });
    },
  };
  const table = prejudiceFormTable(formData.victimesIndirectes, [
    {
      name: 'numerosPieces',
      headerLabel: i18next.t('numeroPiece.form.columnHeader'),
      render: (row) =>
        row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
    },
    {
      name: 'victimeIndirecteId',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.nom.columnHeader',
      ),
      render: (row) => {
        const victimeIndirecte = victimesIndirectes.find(
          (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
        );
        if (!victimeIndirecte) {
          return '';
        }
        return i18next.t('victimeIndirecte.displayName', {
          nom: victimeIndirecte.nom,
          prenom: victimeIndirecte.prenom,
        });
      },
    },
    {
      name: 'victimeIndirecteId',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.lienVictime.columnHeader',
      ),
      render: (row) => {
        const victimeIndirecte = victimesIndirectes.find(
          (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
        );
        if (!victimeIndirecte) {
          return '';
        }
        return i18next.t(
          `victimeIndirecte.form.fields.lienVictime.options.${victimeIndirecte.lienVictime}`,
        );
      },
    },
    ...(isVictimeIndirecteLieuDeVieColumnVisible ? [lieuDeVieRow] : []),
    {
      name: 'victimeIndirecteId',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.dateNaissance.columnHeader',
      ),
      render: (row) => {
        const victimeIndirecte = victimesIndirectes.find(
          (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
        );
        if (!victimeIndirecte) {
          return '';
        }
        return fDate(victimeIndirecte?.dateNaissance);
      },
    },
    ...(isAgeAuDecesColumnVisible ? [ageAuDecesRow] : []),
    {
      name: 'montant',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.montant.columnHeader',
      ),
      render: (row) => fCurrency(row.montant || 0),
    },
    {
      name: 'notes',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR_VICTIMES_INDIRECTES.fields.notes.columnHeader',
      ),
    },
  ]);
  const notesPrint = prejudiceNotesPrint(formData.notes);
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...table,
    getEmptyLine(),
    partResponsabiliteTotalPrint({
      total: formData.montantTotal,
      partResponsabilite,
    }),
  ];
};
