import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validationSchemaNumeroPieces } from 'src/constants/prejudice/validation';
import * as yup from 'yup';
import { CalculsGlobal } from '../../../../constants/calculs';
import {
  ListeProjectionAgrement,
  ListeProjectionAgrementRow,
  NumeroPieceValuesRow,
  PrejudiceFormListeProjection,
} from '../../../../types/prejudice.type';
import { ComputedReadFieldForm } from '../../../forms/ComputedReadFieldForm';
import { TextFieldForm } from '../../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../../masks/MaskNumber';
import { NormalTable } from '../../../styled';
import { FormNumeroPieceDialog } from '../FormNumeroPieceDialog';

interface Props {
  control: Control<PrejudiceFormListeProjection>;
  allNumerosPieces: NumeroPieceValuesRow[];
}

export const validationSchemaPrejudiceAgrement =
  (): yup.ObjectSchema<ListeProjectionAgrement> =>
    yup.object({
      rows: yup
        .array()
        .defined()
        .of(
          yup.object({
            numerosPieces: validationSchemaNumeroPieces,
            typePrejudice: yup.string().required(),
            montant: yup.number().required().min(0),
          }),
        ),
    });
const initialValuesRow: ListeProjectionAgrementRow = {
  typePrejudice: '',
  montant: 0,
  numerosPieces: { rows: [] },
};

export const getInitialValuesPrejudiceAgrement = (
  values: ListeProjectionAgrement | undefined,
): ListeProjectionAgrement => ({
  rows:
    values?.rows?.map((row) => ({
      ...initialValuesRow,
      ...row,
    })) ?? [],
});

export const PrejudiceAgrement: React.FC<Props> = ({
  control,
  allNumerosPieces,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'prejudiceValues.rows',
  });

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as ListeProjectionAgrementRow);
    handleMenuClose();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell> {t('numeroPiece.form.columnHeader')}</TableCell>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_AGREMENT.fields.rows.typePrejudice.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.PREJUDICE_AGREMENT.fields.rows.montant.columnHeader',
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormNumeroPieceDialog
                    control={control}
                    name={`prejudiceValues.rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.typePrejudice`}
                    sx={{ width: '100%' }}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextFieldForm
                    control={control}
                    name={`prejudiceValues.rows.${index}.montant`}
                    InputProps={{
                      inputComponent: MaskNumber as any,
                      inputProps: {
                        numberMask: { scale: 2 },
                        suffix: '€',
                      } as IMaskNumberProps,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={handleMenuOpen(index)}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuIndex === index}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleDelete(index)}>
                      {t('forms.table.deleteRow', { index: index + 1 })}
                    </MenuItem>
                    <MenuItem onClick={() => duplicateRow(index)}>
                      {t('forms.table.duplicateRow', { index: index + 1 })}
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </NormalTable>
      </TableContainer>
      <ComputedReadFieldForm
        name="montantTotal"
        control={control}
        watchFields={['prejudiceValues.rows']}
        compute={([rows]) => {
          return CalculsGlobal.sommeMontantRows(rows);
        }}
      />
      <Button
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          append(initialValuesRow);
        }}
      >
        Ajouter une ligne
      </Button>
    </>
  );
};
