import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierDepenseCapitalisationRow,
  PrejudiceFormCalendrierDepenseRow,
} from 'src/types/prejudice.type';
import { Time } from '../time';
import { CalculsFormCalendrier } from './calculsFormCalendrier';
import { CalculsGlobal } from './calculsGlobal';
import {
  IndemniteRepartieAEchoir,
  IndemniteRepartieTiersPayeurs,
} from './type';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';

export abstract class CalculsFormCapitalisation {
  static isDepenseEchue({
    depense,
    isCalculCapitalisation,
    dateLiquidation,
  }: {
    depense: Pick<PrejudiceFormCalendrierDepenseRow, 'dateDebut' | 'type'>;
    isCalculCapitalisation: boolean | undefined;
    dateLiquidation: Date | undefined;
  }): boolean {
    if (!isCalculCapitalisation) {
      return true;
    }

    const dateDebut = depense.dateDebut ? new Date(depense.dateDebut) : null;
    if (!dateDebut || !dateLiquidation) {
      return false;
    }
    if (dateDebut > dateLiquidation) {
      return false;
    }
    return true;
  }

  static getMontantUnitaireAnnualise({
    type,
    frequenceMontant,
    renouvellementMaintenance,
    montantUnitaire,
    quantite,
  }: Pick<
    PrejudiceFormCalendrierDepenseCapitalisationRow,
    | 'type'
    | 'frequenceMontant'
    | 'renouvellementMaintenance'
    | 'montantUnitaire'
    | 'quantite'
  >): number {
    if (type === 'ponctuelle') {
      return Number(montantUnitaire);
    }
    switch (frequenceMontant) {
      case 'mensuel':
        return montantUnitaire * Time.monthsInYear * quantite;
      case 'hebdomadaire':
        return montantUnitaire * Time.weeksInYear * quantite;
      case 'quotidien':
        return montantUnitaire * Time.daysInYear * quantite;
      case 'annuel':
        return montantUnitaire * quantite;
      case 'viagere':
        return montantUnitaire / (renouvellementMaintenance || 1);
      default:
        return montantUnitaire;
    }
  }

  static getIndemnitesRepartie({
    rows,
    partResponsabilite,
    tiersPayeurs,
    dateLiquidation,
    monetaryErosions,
    revalorisationCoefficientsType,
    capitalisation,
    capitalisationTiersPayeurs,
    dateConsolidation,
    dateDeces,
  }: Pick<
    PrejudiceFormCalendrierDepenseCapitalisation,
    | 'rows'
    | 'capitalisation'
    | 'capitalisationTiersPayeurs'
    | 'revalorisationCoefficientsType'
  > & {
    partResponsabilite: number;
    tiersPayeurs: string[];
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
    dateConsolidation: Date | undefined;
    dateDeces: Date | undefined;
  }): IndemniteRepartieAEchoir {
    const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
      dateConsolidation,
      dateLiquidation,
      dateDeces,
    });
    const totaux = CalculsFormCalendrier.totauxDepenses({
      rows,
      tiersPayeurs,
      dateLiquidation,
      monetaryErosions,
      isCalculCapitalisation: true,
      revalorisationCoefficientsType,
    });
    const totauxAEchoir = CalculsFormCalendrier.totauxDepensesAEchoir({
      rows,
      tiersPayeurs,
      dateLiquidation,
      monetaryErosions,
      isCalculCapitalisation: true,
      revalorisationCoefficientsType,
    });
    const victimeTotalCapitalise = shouldNotDisplayCapitalisation
      ? 0
      : capitalisation.montantCapitalise;
    const tiersPayeursTotalCapitalise = shouldNotDisplayCapitalisation
      ? 0
      : capitalisationTiersPayeurs.montantCapitalise;
    const totalCreanceTiersPayeurs = CalculsGlobal.sum(
      Object.values(totaux.priseEnChargeTiersPayeurs ?? {}).map(Number),
    );

    const totalCreanceTiersPayeursAEchoir = CalculsGlobal.sum(
      Object.values(totauxAEchoir.priseEnChargeTiersPayeurs ?? {}).map(Number),
    );
    const indemniteGlobaleBeforePartResponsabilite =
      totaux.resteACharge +
      totauxAEchoir.resteACharge +
      totalCreanceTiersPayeurs +
      totalCreanceTiersPayeursAEchoir +
      victimeTotalCapitalise;
    const indemniteGlobale =
      indemniteGlobaleBeforePartResponsabilite * partResponsabilite;

    /* Préférence victime */
    const arreragesEchusVictimeDebit = CalculsGlobal.min([
      totaux.resteACharge,
      indemniteGlobale,
    ]);
    const arreragesEchusVictimeSolde =
      indemniteGlobale - arreragesEchusVictimeDebit;
    const arreragesAEchoirVictimeDebit = CalculsGlobal.min([
      victimeTotalCapitalise -
        tiersPayeursTotalCapitalise +
        totauxAEchoir.resteACharge,
      arreragesEchusVictimeSolde,
    ]);
    const arreragesAEchoirVictimeSolde =
      arreragesEchusVictimeSolde - arreragesAEchoirVictimeDebit;

    /* Rente tiers payeurs */
    const arreragesEchusTotalTiersPayeursDebit = CalculsGlobal.min([
      totalCreanceTiersPayeurs,
      arreragesAEchoirVictimeSolde,
    ]);
    const arreragesEchusTotalTiersPayeursSolde =
      arreragesAEchoirVictimeSolde - arreragesEchusTotalTiersPayeursDebit;

    const arreragesEchusTiersPayeursDebit: IndemniteRepartieTiersPayeurs =
      tiersPayeurs.map((tiersPayeur) => {
        const montantNonReparti = Number(
          totaux.priseEnChargeTiersPayeurs[tiersPayeur] || 0,
        );
        return {
          tiersPayeur,
          montant:
            (montantNonReparti * (arreragesEchusTotalTiersPayeursDebit || 0)) /
            (totalCreanceTiersPayeurs || 1),
          montantNonReparti,
        };
      }, {});
    const arreragesAEchoirTotalTiersPayeursDebit = CalculsGlobal.min([
      tiersPayeursTotalCapitalise + totalCreanceTiersPayeursAEchoir,
      arreragesEchusTotalTiersPayeursSolde,
    ]);
    const arreragesAEchoirTiersPayeursDebit: IndemniteRepartieTiersPayeurs =
      tiersPayeurs.map((tiersPayeur) => {
        const capitalisationParTiersPayeurMontantCapitalise =
          shouldNotDisplayCapitalisation
            ? 0
            : capitalisationTiersPayeurs.parTiersPayeur.find(
                (capitalisation) => capitalisation.tiersPayeur === tiersPayeur,
              )?.montantCapitalise || 0;
        const creanceTiersPayeurAEchoir =
          totauxAEchoir.priseEnChargeTiersPayeurs[tiersPayeur] || 0;
        const montantNonReparti =
          capitalisationParTiersPayeurMontantCapitalise +
          creanceTiersPayeurAEchoir;
        return {
          tiersPayeur,
          montant:
            (montantNonReparti *
              (arreragesAEchoirTotalTiersPayeursDebit || 0)) /
            ((tiersPayeursTotalCapitalise || 0) +
              (totalCreanceTiersPayeursAEchoir || 0) || 1),
          montantNonReparti,
        };
      });
    return {
      indemniteGlobaleARepartir: {
        solde: indemniteGlobale,
        beforePartResponsabilite: indemniteGlobaleBeforePartResponsabilite,
      },
      indemniteVictime: {
        arreragesEchus: {
          solde: arreragesEchusVictimeSolde,
          debit: arreragesEchusVictimeDebit,
        },
        arreragesAEchoir: {
          solde: arreragesAEchoirVictimeSolde,
          debit: arreragesAEchoirVictimeDebit,
        },
        total: arreragesEchusVictimeDebit + arreragesAEchoirVictimeDebit,
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          solde: arreragesEchusTotalTiersPayeursSolde,
          debit: arreragesEchusTotalTiersPayeursDebit,
          parTiersPayeur: arreragesEchusTiersPayeursDebit,
          totalNonReparti: totalCreanceTiersPayeurs,
        },
        arreragesAEchoir: {
          solde: 0,
          debit: arreragesAEchoirTotalTiersPayeursDebit,
          parTiersPayeur: arreragesAEchoirTiersPayeursDebit,
          totalNonReparti: tiersPayeursTotalCapitalise,
        },
        total:
          arreragesEchusTotalTiersPayeursDebit +
          arreragesAEchoirTotalTiersPayeursDebit,
      },
    };
  }
}
