import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte } from 'src/types/prejudice.type';
import { tablePerteDeGainProfessionnelPrint } from './tablePerteDeGainProfessionnelPrint';
import { revenuEsperePrint } from './revenuEsperePrint';
import { tableIndemnitesJournalieresPercuesPendantLaPeriodeDArretPrint } from './tableIndemnitesJournalieresPercuesPendantLaPeriodeDArretPrint';

export const stepsSaisieDirectePrint = ({
  formData,
  isPGPF,
}: {
  formData: PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
  isPGPF: boolean;
}): (Table | Paragraph | TableOfContents)[] => {
  return [
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.stepper.print.REVENU_ESPERE.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...revenuEsperePrint({
      rows: formData.revenuEspere.rows,
      total: formData.revenuEspere.total,
    }),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.stepper.print.PERTE_DE_GAIN_PROFESSIONNEL.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...tablePerteDeGainProfessionnelPrint({
      rows: formData.perteDeGainsProfessionnels.rows,
      total: formData.perteDeGainsProfessionnels.total,
      formType: 'SAISIE_DIRECTE',
    }),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_SAISIE_DIRECTE.stepper.print.INDEMNITES_JOURNALIERES_PERCUES.title',
        ),
        bold: true,
      }),
    }),
    getEmptyLine(),
    ...tableIndemnitesJournalieresPercuesPendantLaPeriodeDArretPrint({
      rows: formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
      totalIndemnitesPercuesNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .indemnitesJournalieresPercuesNet,
      totalIndemniteCsgCrdsNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .csgRdsTotal,
      isPGPF,
    }),
  ];
};
