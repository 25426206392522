import { AlignmentType, Table, TableRow } from 'docx';
import {
  getHeaderTableCell,
  getTableRow,
  getValueTableCell,
} from './docxFunctions';

export const simpleVerticalTablePrint = (
  values: (
    | {
        label: string;
        value: string;
        bold?: boolean;
      }
    | {
        title: string;
        bold?: boolean;
      }
    | {
        row: TableRow;
      }
  )[],
  alignment?: (typeof AlignmentType)[keyof typeof AlignmentType],
): Table => {
  return new Table({
    alignment: alignment ?? AlignmentType.START,
    rows: values.map((value) => {
      if ('title' in value) {
        return getTableRow({
          children: [
            getHeaderTableCell(
              value.title,
              {
                columnSpan: 2,
              },
              {
                alignment: AlignmentType.CENTER,
              },
              value.bold !== undefined
                ? {
                    bold: value.bold,
                  }
                : undefined,
            ),
          ],
        });
      } else if ('label' in value) {
        return getTableRow({
          children: [
            value.bold
              ? getHeaderTableCell(value.label)
              : getHeaderTableCell(value.label, undefined, undefined, {
                  bold: false,
                }),
            getValueTableCell(value.value),
          ],
        });
      } else {
        return value.row;
      }
    }),
  });
};
