import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { IndemniteRepartieEchusIndirecte } from 'src/constants/calculs/type';
import { fCurrency } from 'src/helpers/formatNumber';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getEmptyLine, getParagraph, getTextRun } from '../docxFunctions';
import { simpleVerticalTablePrint } from '../simpleVerticalTablePrint';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const debitSoldeEchusIndirectePrint = ({
  title,
  indemniteGlobaleARepartir,
  partResponsabilite,
  victimesIndirectes,
}: {
  title?: string;
  indemniteGlobaleARepartir: IndemniteRepartieEchusIndirecte;
  partResponsabilite: number;
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] => [
  getParagraph({
    children: getTextRun({
      text: i18next.t(
        'prejudice.indemniteGlobaleARepartir.indirecteEchus.print.title',
      ),
      bold: true,
    }),
  }),
  getEmptyLine(),
  simpleVerticalTablePrint([
    ...(title
      ? [
          {
            title: title,
          },
        ]
      : []),
    {
      label: i18next.t(
        'prejudice.indemniteGlobaleARepartir.indirecteEchus.table.indemniteGlobale.print.tableRowHeader',
        {
          partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
        },
      ),
      value: fCurrency(
        indemniteGlobaleARepartir.indemniteGlobaleARepartir.solde,
      ),
    },
    {
      label: i18next.t(
        'prejudice.indemniteGlobaleARepartir.indirecteEchus.table.indemniteVictime.tableRowHeader',
      ),
      value: fCurrency(
        indemniteGlobaleARepartir.indemniteProche.arreragesEchus.debit,
      ),
      bold: true,
    },
    ...Object.entries(
      indemniteGlobaleARepartir.indemniteProche.arreragesEchus
        .parVictimeIndirecte || {},
    ).map(([victimeIndirecteId, montant]) => {
      const victimeIndirecte = victimesIndirectes.find(
        (victime) => victime._id === victimeIndirecteId,
      );
      return {
        label: i18next.t('victimeIndirecte.displayNameLong', {
          nom: victimeIndirecte?.nom,
          prenom: victimeIndirecte?.prenom,
          lienVictime: victimeIndirecte?.lienVictime,
        }),
        value: fCurrency(montant),
      };
    }),
    {
      label: i18next.t(
        'prejudice.indemniteGlobaleARepartir.indirecteEchus.table.indemniteTiersPayeurs.tableRowHeader',
      ),
      value: fCurrency(
        indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesEchus.debit,
      ),
      bold: true,
    },
    ...(
      indemniteGlobaleARepartir.indemniteTiersPayeurs.arreragesEchus
        .parTiersPayeur || []
    ).map(
      ({ tiersPayeur, montant }) =>
        ({
          label: tiersPayeur,
          value: fCurrency(montant),
        }) as const,
    ),
  ]),
];
