import React from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { Procedure } from 'src/types/procedure.type';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { Accordion } from 'src/components/basic/Accordion';
import { useTranslation } from 'react-i18next';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { CalculsGlobal } from 'src/constants/calculs';
import { LayoutTable } from 'src/components/styled';

interface Props<
  TFieldValues extends FieldValues,
  TTIersPayeursSommeACapitaliserSuffix extends string,
  TTIersPayeursBaremeSuffix extends string,
  TTIersPayeursAgeDernierArrerageSuffix extends string,
  TTIersPayeursCoefficientSuffix extends string,
  TTIersPayeursIsLastArrerageViagerSuffix extends string,
  TTiersPayeursMontantCapitaliseSuffix extends string,
  TTiersPayeursRootName extends FieldPath<TFieldValues>,
  TTiersPayeursTotalCapitaliseName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursSommeACapitaliserName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursSommeACapitaliserSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursBaremeName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursBaremeSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursAgeDernierArrerageName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursAgeDernierArrerageSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursCoefficientName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursCoefficientSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursIsLastArrerageViagerName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursIsLastArrerageViagerSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursMontantCapitaliseName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTiersPayeursMontantCapitaliseSuffix}`,
> {
  control: Control<TFieldValues>;
  procedure: Procedure;
  suffix: {
    sommeACapitaliser?: TTIersPayeursSommeACapitaliserSuffix;
    bareme?: TTIersPayeursBaremeSuffix;
    ageDernierArrerage?: TTIersPayeursAgeDernierArrerageSuffix;
    coefficient?: TTIersPayeursCoefficientSuffix;
    isLastArrerageViager?: TTIersPayeursIsLastArrerageViagerSuffix;
    montantCapitalise?: TTiersPayeursMontantCapitaliseSuffix;
  };
  name: {
    root: TTiersPayeursRootName;
    montantCapitalise?: TTiersPayeursTotalCapitaliseName;
  };
  editedFieldsName: TEditedFieldsName;
  titles?: {
    title?: string;
    sommeACapitaliser?: string;
    bareme?: string;
    ageDernierArrerage?: string;
    coefficient?: string;
    isLastArrerageViager?: string;
    montantCapitalise?: string;
  };
}

export const TiersPayeursCapitalisationRows = <
  TFieldValues extends FieldValues,
  TTIersPayeursSommeACapitaliserSuffix extends string,
  TTIersPayeursBaremeSuffix extends string,
  TTIersPayeursAgeDernierArrerageSuffix extends string,
  TTIersPayeursCoefficientSuffix extends string,
  TTIersPayeursIsLastArrerageViagerSuffix extends string,
  TTiersPayeursMontantCapitaliseSuffix extends string,
  TTiersPayeursRootName extends FieldPath<TFieldValues>,
  TTiersPayeursTotalCapitaliseName extends FieldPath<TFieldValues>,
  TEditionFieldsName extends FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursSommeACapitaliserName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursSommeACapitaliserSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursBaremeName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursBaremeSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursAgeDernierArrerageName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursAgeDernierArrerageSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursCoefficientName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursCoefficientSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursIsLastArrerageViagerName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTIersPayeursIsLastArrerageViagerSuffix}`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _TTiersPayeursMontantCapitaliseName extends FieldPath<TFieldValues> &
    `${TTiersPayeursRootName}.${number}.${TTiersPayeursMontantCapitaliseSuffix}`,
>({
  control,
  procedure,
  suffix,
  name,
  titles,
  editedFieldsName,
}: Props<
  TFieldValues,
  TTIersPayeursSommeACapitaliserSuffix,
  TTIersPayeursBaremeSuffix,
  TTIersPayeursAgeDernierArrerageSuffix,
  TTIersPayeursCoefficientSuffix,
  TTIersPayeursIsLastArrerageViagerSuffix,
  TTiersPayeursMontantCapitaliseSuffix,
  TTiersPayeursRootName,
  TTiersPayeursTotalCapitaliseName,
  TEditionFieldsName,
  _TTiersPayeursSommeACapitaliserName,
  _TTiersPayeursBaremeName,
  _TTiersPayeursAgeDernierArrerageName,
  _TTiersPayeursCoefficientName,
  _TTiersPayeursIsLastArrerageViagerName,
  _TTiersPayeursMontantCapitaliseName
>) => {
  const { t } = useTranslation();
  return (
    <>
      <TableRow>
        <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
          {titles?.title}
        </TableCell>
      </TableRow>
      {procedure.tiersPayeurs.map((tiersPayeur, index) => (
        <TableRow key={index}>
          <TableCell colSpan={2}>
            <Accordion defaultExpanded={true} title={tiersPayeur}>
              <LayoutTable>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {titles?.sommeACapitaliser ||
                        t(
                          'prejudice.capitalisation.form.fields.sommeACapitaliser.title',
                        )}
                    </TableCell>
                    <TableCell align="right">
                      <TextFieldForm
                        control={control}
                        name={
                          `${name.root}.${index}.${suffix.sommeACapitaliser}` as _TTiersPayeursSommeACapitaliserName
                        }
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {titles?.sommeACapitaliser ||
                        t(
                          'prejudice.capitalisation.form.fields.coefficient.title',
                        )}
                    </TableCell>
                    <TableCell align="right">
                      <TextFieldForm
                        control={control}
                        name={
                          `${name.root}.${index}.${suffix.coefficient}` as _TTiersPayeursSommeACapitaliserName
                        }
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 3 },
                          } as IMaskNumberProps,
                        }}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t(
                        'prejudice.capitalisation.form.fields.montantCapitalise.title',
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <ComputedTextFieldForm
                        control={control}
                        name={
                          `${name.root}.${index}.${suffix.montantCapitalise}` as _TTiersPayeursMontantCapitaliseName
                        }
                        watchFields={[
                          `${name.root}.${index}.${suffix.sommeACapitaliser}` as _TTiersPayeursSommeACapitaliserName,
                          `${name.root}.${index}.${suffix.coefficient}` as _TTiersPayeursCoefficientName,
                        ]}
                        compute={([sommeACapitaliser, coefficient]) =>
                          sommeACapitaliser * coefficient
                        }
                        InputProps={{
                          inputComponent: MaskNumber as any,
                          inputProps: {
                            numberMask: { scale: 2 },
                            suffix: '€',
                          } as IMaskNumberProps,
                        }}
                        fullWidth
                        editedFieldsName={editedFieldsName}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </LayoutTable>
            </Accordion>
          </TableCell>
        </TableRow>
      ))}
      {name?.montantCapitalise ? (
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>
            {titles?.montantCapitalise ||
              t(
                'prejudice.capitalisation.form.fields.montantCapitalise.tiersPayeurs.title',
              )}
          </TableCell>
          <TableCell align="right">
            <ComputedTextFieldForm
              control={control}
              name={name.montantCapitalise}
              watchFields={procedure.tiersPayeurs.map(
                (_, index) =>
                  `${name?.root}.${index}.${suffix?.montantCapitalise}` as _TTiersPayeursMontantCapitaliseName,
              )}
              compute={(montantsCapitalises) =>
                CalculsGlobal.sum(
                  montantsCapitalises.map(
                    (montantCapitalise) => montantCapitalise || 0,
                  ),
                ) || 0
              }
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 2 },
                  suffix: '€',
                } as IMaskNumberProps,
              }}
              editedFieldsName={editedFieldsName}
            />
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
