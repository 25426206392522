import { Divider, Drawer } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { Flex } from './Flex';

const drawerWidth = 'auto';

const useStyles = createUseStyles({
  sideBar: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
      overflowY: 'scroll',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar ': {
        display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
      },
      justifyContent: 'space-between',
    },
  },
  startSideBar: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
      overflowY: 'scroll',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar ': {
        display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
      },
      justifyContent: 'start',
    },
  },
  sideBarHeader: {},
  sideBarTitle: {
    margin: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.4em',
  },
  sideBarContainer: {
    padding: '20px',
    overflowY: 'scroll',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar ': {
      display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    },
  },
  sideBarFooter: {},
  startSideBarFooter: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  sideBarActions: {
    justifyContent: 'space-evenly',
    margin: '20px',
  },
});

interface Props {
  header: React.ReactNode;
  headerClasses?: string;
  body: React.ReactNode;
  justifyStart?: boolean;
  bodyClasses?: string;
  footer: React.ReactNode;
  footerClasses?: string;
  isOpen: boolean | undefined;
  closeSideBar: () => void;
}

const Sidebar: React.FC<Props> = ({
  header,
  headerClasses,
  body,
  bodyClasses,
  footer,
  footerClasses,
  isOpen,
  closeSideBar,
  justifyStart = false,
}) => {
  const classes = useStyles();
  return (
    <Drawer
      className={justifyStart ? classes.startSideBar : classes.sideBar}
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={() => closeSideBar()}
    >
      <div className={`${classes.sideBarHeader} ${headerClasses}`}>
        <div className={classes.sideBarTitle}>{header}</div>
        <Divider />
      </div>
      <div className={`${classes.sideBarContainer} ${bodyClasses}`}>{body}</div>
      <div
        className={`${
          justifyStart ? classes.startSideBarFooter : classes.sideBarFooter
        } ${footerClasses}`}
      >
        <Divider />
        <Flex className={classes.sideBarActions}>{footer}</Flex>
      </div>
    </Drawer>
  );
};

export default Sidebar;
