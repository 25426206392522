import React from 'react';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  render?: (value: FieldPathValue<TFieldValues, TName>) => React.ReactElement;
}

export const ReadFieldForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  render,
}: Props<TFieldValues, TName>) => {
  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return render ? render(field.value) : <>{field.value}</>;
};
