import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityId,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import { Constant, CreateOrUpdateConstantDto } from 'src/types/constant.type';
import { apiRequest } from '../helpers/api';

/* Thunks */
export const fetchConstant = createAsyncThunk('constant/fetch', async () => {
  return await apiRequest<Constant>('GET', '/constant');
});

export const createOrUpdateConstant = createAsyncThunk(
  'constant/createOrUpdate',
  async ({ data }: { data: CreateOrUpdateConstantDto }) => {
    return await apiRequest<Constant>('POST', '/constant', undefined, data);
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
};

/* Adapter */
export const constantsAdapter = createEntityAdapter<Constant>({
  selectId: (constant) => constant._id,
});

/* Slice */
const constantsSlice = createSlice({
  name: 'constants',
  initialState: constantsAdapter.getInitialState({
    isLoading: false,
    error: undefined,
  }),
  reducers: {
    setConstant: (state, action: PayloadAction<Constant>) => {
      constantsAdapter.upsertOne(state, action.payload);
    },
    removeOne: (state, action: PayloadAction<EntityId>) => {
      constantsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConstant.pending, sharedReducers.pending)
      .addCase(fetchConstant.rejected, sharedReducers.rejected)
      .addCase(fetchConstant.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        constantsAdapter.setAll(state, [payload]);
      })
      .addCase(createOrUpdateConstant.pending, sharedReducers.pending)
      .addCase(createOrUpdateConstant.rejected, sharedReducers.rejected)
      .addCase(createOrUpdateConstant.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        constantsAdapter.upsertOne(state, payload);
      });
  },
});

export const { setConstant, removeOne } = constantsSlice.actions;

export default constantsSlice.reducer;
