import { Paragraph, Table, TableOfContents } from 'docx';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { getEmptyLine, getParagraph } from '../../../docxFunctions';
import i18next from 'i18next';
import { Victime } from 'src/types/victime.type';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { Procedure } from 'src/types/procedure.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { getMontantRevaloriseWrittenPrint } from '../../revalorisation.written.print';
import { displayFormula } from '../../formula';

export const perteAnnuelleFoyerWrittenPrint = ({
  formData,
  victime,
  procedure,
  monetaryErosions,
}: {
  formData: PrejudiceFormPerteRevenusProche;
  victime: Victime;
  procedure: Procedure;
  monetaryErosions: MonetaryErosion[];
}): (Table | TableOfContents | Paragraph)[] => {
  return [
    getParagraph({
      numbering: {
        reference: 'numbers-dot-simple',
        level: 0,
      },
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.perteAnnuelleFoyer.title',
      ),
    }),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.perteAnnuelleFoyer.avantDeces.lesRevenusSont',
      ),
    ),
    getParagraph({
      numbering: {
        reference: 'simple-list',
        level: 0,
      },
      text: `${i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.perteAnnuelleFoyer.avantDeces.victimeRow',
        {
          nom: i18next.t('print.victime.titleAndName', {
            lastName: victime.nom,
            firstName: victime.prenom,
            context: victime.sexe,
          }),
          revenu: fCurrency(formData.victime.revenu.montant),
          annee: formData.victime.revenu.annee,
        },
      )}${
        !formData.victime.revenu.dejaRevalorise &&
        formData.victime.revenu.montant
          ? getMontantRevaloriseWrittenPrint({
              montant: formData.victime.revenu.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation ?? undefined,
              anneeOrDateMontant: formData.victime.revenu.annee ?? undefined,
              coefficientsType: formData.revalorisationCoefficientsType,
              monetaryErosions,
            })
          : ''
      }`,
    }),
    getParagraph({
      numbering: {
        reference: 'simple-list',
        level: 0,
      },
      text: `${i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.perteAnnuelleFoyer.avantDeces.conjointRow',
        {
          nom: i18next.t('print.victime.titleAndName', {
            lastName: victime.nom,
            firstName: victime.prenom,
            context: victime.sexe,
          }),
          revenu: fCurrency(formData.conjoint.revenuAvantDeces.montant),
          annee: formData.conjoint.revenuAvantDeces.annee,
        },
      )}${
        !formData.conjoint.revenuAvantDeces.dejaRevalorise &&
        formData.conjoint.revenuAvantDeces.montant
          ? getMontantRevaloriseWrittenPrint({
              montant: formData.conjoint.revenuAvantDeces.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation ?? undefined,
              anneeOrDateMontant:
                formData.conjoint.revenuAvantDeces.annee ?? undefined,
              coefficientsType: formData.revalorisationCoefficientsType,
              monetaryErosions,
            })
          : ''
      }`,
    }),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.perteAnnuelleFoyer.avantDeces.totalFoyer',
        {
          total: fCurrency(formData.foyer.revenuAnnuel),
        },
      ),
    ),
    getParagraph(
      `${i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.perteAnnuelleFoyer.apresDeces.conjoint',
        {
          revenu: fCurrency(formData.conjoint.revenuApresDeces.montant),
          annee: formData.conjoint.revenuApresDeces.annee,
        },
      )}${
        !formData.conjoint.revenuApresDeces.dejaRevalorise &&
        formData.conjoint.revenuApresDeces.montant
          ? getMontantRevaloriseWrittenPrint({
              montant: formData.conjoint.revenuApresDeces.montant,
              anneeOrDateLiquidation: procedure.dateLiquidation ?? undefined,
              anneeOrDateMontant:
                formData.conjoint.revenuApresDeces.annee ?? undefined,
              coefficientsType: formData.revalorisationCoefficientsType,
              monetaryErosions,
            })
          : ''
      }`,
    ),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.writtenPrint.perteAnnuelleFoyer.perteAnnuelle.text',
        {
          pourcentageConsomVictime:
            formData.foyer.pourcentageConsomVictime !== null
              ? fPercent(formData.foyer.pourcentageConsomVictime)
              : '',
          perteAnnuelleFoyer: fCurrency(formData.foyer.perteAnnuelle),
          formula:
            formData.foyer.deductionPartConsomVictime ||
            formData.conjoint.revenuApresDeces.revaloriseMontant
              ? displayFormula({
                  formula: i18next.t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.perteAnnuelle.formula',
                    {
                      deductionPartConsomVictime: fCurrency(
                        formData.foyer.deductionPartConsomVictime,
                      ),
                      revenuConjointApresDeces: fCurrency(
                        formData.conjoint.revenuApresDeces.revaloriseMontant,
                      ),
                    },
                  ),
                  options: {
                    withParentheses: true,
                  },
                  editedFieldsParameters: {
                    formData,
                    fieldName: 'foyer.perteAnnuelle',
                  },
                })
              : '',
        },
      ),
    ),
  ];
};
