import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import {
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';
import { Procedure } from 'src/types/procedure.type';
import * as yup from 'yup';
import { fCurrency } from '../../../helpers/formatNumber';
import {
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  PrejudiceType,
  RevenuProche,
} from '../../../types/prejudice.type';
import { ComputedReadFieldForm } from '../../forms/ComputedReadFieldForm';
import { TextFieldForm } from '../../forms/TextFieldForm';
import { TooltipTextFieldForm } from '../../forms/TooltipTextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../masks/MaskNumber';
import { NormalTable, SpanNoWrap } from '../../styled';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';
import { FormNumeroPieceDialog } from '../prejudiceFormComponents/FormNumeroPieceDialog';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { SavePrejudiceButton } from '../SavePrejudiceButton';

const validationSchemaRevenuProche = (
  dateAccident?: Date,
  dateLiquidation?: Date,
): yup.ObjectSchema<RevenuProche> =>
  yup.object({
    montant: yup.number().min(0).optional().defined(),
    annee: yup
      .number()
      .nullable()
      .defined()
      .min(
        dateAccident?.getFullYear() || 1900,
        "L'année doit être postérieure ou égale à l'année de l'accident",
      )
      .max(
        dateLiquidation?.getFullYear() || 9999,
        "L'année doit être antérieure ou égale à l'année de liquidation",
      )
      .when('dejaRevalorise', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    dejaRevalorise: yup.boolean().optional().defined(),
    revaloriseMontant: yup.number().optional().defined(),
    numerosPieces: validationSchemaNumeroPieces,
  });
export const validationSchemaPertesRevenusProcheVictimeNonDecedee = (
  dateAccident?: Date,
  dateLiquidation?: Date,
): yup.ObjectSchema<PrejudiceFormPerteRevenusProcheVictimeNonDecedee> =>
  validationSchemaPrejudiceBase.shape({
    perteRevenuPersonnel: validationSchemaRevenuProche(
      dateAccident,
      dateLiquidation,
    ),
    montantATPADeduire: validationSchemaRevenuProche(
      dateAccident,
      dateLiquidation,
    ),
    perteDroitRetraite: validationSchemaRevenuProche(
      dateAccident,
      dateLiquidation,
    ),
    perteChanceAssistanceConjoint: validationSchemaRevenuProche(
      dateAccident,
      dateLiquidation,
    ),
    surcroitActivite: validationSchemaRevenuProche(
      dateAccident,
      dateLiquidation,
    ),
    total: yup.number().min(0).required(),
    partResponsableTotal: yup.number().min(0).optional().defined(),
    partResponsabilite: yup
      .number()
      .required()
      .min(0)
      .max(100)
      .transform((value) => value / 100),
    coefficientsType: yup
      .string()
      .oneOf(['smic', 'annuel'] as const)
      .required(),
  });

const defaultRevenuProcheValue: RevenuProche = {
  montant: 0,
  annee: null,
  dejaRevalorise: true,
  revaloriseMontant: 0,
  numerosPieces: { rows: [] },
};

export const perteRevenusProcheVictimeNonDecedeeDefaultValues = ({
  procedure,
  values,
  prejudiceType,
}: {
  procedure: Procedure;
  values: PrejudiceFormPerteRevenusProcheVictimeNonDecedee | undefined;
  prejudiceType: PrejudiceType;
}): PrejudiceFormPerteRevenusProcheVictimeNonDecedee => ({
  ...prejudiceBaseDefaultValues({
    prejudiceType,
    values,
  }),
  notes: values?.notes || '',
  perteRevenuPersonnel:
    values?.perteRevenuPersonnel || defaultRevenuProcheValue,
  montantATPADeduire: values?.montantATPADeduire || defaultRevenuProcheValue,
  perteDroitRetraite: values?.perteDroitRetraite || defaultRevenuProcheValue,
  surcroitActivite: values?.surcroitActivite || defaultRevenuProcheValue,
  perteChanceAssistanceConjoint:
    values?.perteChanceAssistanceConjoint || defaultRevenuProcheValue,
  total: values?.total || 0,
  partResponsableTotal: values?.partResponsableTotal || 0,
  partResponsabilite:
    (values?.partResponsabilite || procedure.partResponsabilite) * 100,
  coefficientsType: values?.coefficientsType || 'annuel',
});

type Props =
  PrejudiceFormProps<PrejudiceFormPerteRevenusProcheVictimeNonDecedee>;

export const FormPertesRevenusProcheVictimeNonDecedee: React.FC<Props> = ({
  values,
  procedure,
  monetaryErosions,
  victime,
  allNumerosPieces,
  ...props
}) => {
  const useFormReturn =
    useForm<PrejudiceFormPerteRevenusProcheVictimeNonDecedee>({
      mode: 'onTouched',
      defaultValues: perteRevenusProcheVictimeNonDecedeeDefaultValues({
        procedure,
        values,
        prejudiceType: props.prejudiceType,
      }),
      resolver: yupResolver(
        validationSchemaPertesRevenusProcheVictimeNonDecedee(
          victime.dateAccident ? new Date(victime.dateAccident) : undefined,
          procedure.dateLiquidation
            ? new Date(procedure.dateLiquidation)
            : undefined,
        ),
      ),
    });
  const { control } = useFormReturn;
  const { t } = useTranslation();
  const displayRevenuProcheRow = (
    fieldName:
      | 'perteRevenuPersonnel'
      | 'montantATPADeduire'
      | 'perteDroitRetraite'
      | 'surcroitActivite'
      | 'perteChanceAssistanceConjoint',
    label: string,
  ) => (
    <>
      <TableRow>
        <TableCell sx={{ width: '400px' }}>{label}</TableCell>
        <TableCell sx={{ minWidth: '150px' }}>
          <FormNumeroPieceDialog
            control={control}
            name={`${fieldName}.numerosPieces`}
            allOtherNumeroPieces={allNumerosPieces}
          />
        </TableCell>
        <TableCell align="center">
          <TooltipTextFieldForm
            control={control}
            name={`${fieldName}.montant`}
            watchFields={[`${fieldName}.revaloriseMontant`]}
            computeTooltip={([revenusAnnuelVictime]) => {
              return t('prejudice.revalorisation.tooltip.revaloriseValue', {
                value: fCurrency(revenusAnnuelVictime || 0),
              });
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                suffix: '€',
                numberMask: { scale: 2 },
              } as IMaskNumberProps,
            }}
          />
        </TableCell>
        <TableCell align="center">
          <TextFieldForm
            control={control}
            name={`${fieldName}.annee`}
            sx={{ input: { textAlign: 'right' } }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 0, thousandsSeparator: '' },
                nullable: true,
              } as IMaskNumberProps,
            }}
          />
        </TableCell>
        <TableCell align="center">
          <Controller
            control={control}
            name={`${fieldName}.dejaRevalorise`}
            render={({ field }) => (
              <Checkbox {...field} checked={field.value} />
            )}
          />
        </TableCell>
      </TableRow>

      <ComputedReadFieldForm
        control={control}
        watchFields={[
          `${fieldName}.montant`,
          `${fieldName}.annee`,
          `${fieldName}.dejaRevalorise`,
          'coefficientsType',
        ]}
        name={`${fieldName}.revaloriseMontant`}
        compute={([montant, annee, dejaRevalorise, coefficientsType]) => {
          if (dejaRevalorise || !annee || !procedure.dateLiquidation) {
            return montant;
          } else {
            return (
              getMontantRevalorise({
                montant,
                anneeOrDateMontant: annee,
                anneeOrDateLiquidation: procedure.dateLiquidation,
                monetaryErosions,
                coefficientsType,
              }) || 0
            );
          }
        }}
      />
    </>
  );

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormPerteRevenusProcheVictimeNonDecedee>
      {...props}
      procedure={procedure}
      victime={victime}
      monetaryErosions={monetaryErosions}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={2}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction.victimeNonDecedee`,
            )}
          />
          <ComputedTextFieldForm
            control={control}
            name="partResponsabilite"
            label={t('partResponsabilite.label')}
            watchFields={[]}
            compute={() => procedure.partResponsabilite * 100}
            InputLabelProps={{
              shrink: true,
              sx: { color: 'info.light' },
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 2, min: 0, max: 100 },
                suffix: '%',
              } as IMaskNumberProps,
              sx: {
                color: 'info.light',
                borderColor: 'info.light',
                ' .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'info.light',
                },
              },
            }}
            sx={{ minWidth: '268px' }}
            editedFieldsName="editedFields"
          />
          <Stack direction="row" spacing={2}>
            <CalculsBox padding={2}>
              <FormControl>
                <FormLabel
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {t('prejudice.revalorisation.coefficientsType.choice.label')}
                </FormLabel>
                <RadioFormField
                  control={control}
                  name="coefficientsType"
                  options={[
                    {
                      label: t(
                        'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                      ),
                      value: 'annuel',
                    },
                    {
                      label: t(
                        'prejudice.revalorisation.coefficientsType.choice.options.smic',
                      ),
                      value: 'smic',
                    },
                  ]}
                  row={true}
                />
              </FormControl>
            </CalculsBox>
          </Stack>
          <TableContainer component={Paper}>
            <NormalTable size="medium">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t('numeroPiece.form.columnHeader')}</TableCell>
                  <TableCell>
                    {t(
                      'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.victimeBlessee.montant.columnHeader',
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.victimeBlessee.annee.columnHeader',
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      `prejudice.revalorisation.columnHeaders.montantDejaRevalorise`,
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayRevenuProcheRow(
                  'perteRevenuPersonnel',
                  t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.perteRevenuPersonnel.label',
                  ),
                )}
                {displayRevenuProcheRow(
                  'montantATPADeduire',
                  t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.montantATPADeduire.label',
                  ),
                )}
                {displayRevenuProcheRow(
                  'perteDroitRetraite',
                  t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.perteDroitRetraite.label',
                  ),
                )}
                {displayRevenuProcheRow(
                  'surcroitActivite',
                  t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.surcroitActivite.label',
                  ),
                )}
                {displayRevenuProcheRow(
                  'perteChanceAssistanceConjoint',
                  t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.perteChanceAssistanceConjoint.label',
                  ),
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <ComputedReadFieldForm
                      control={control}
                      name="total"
                      watchFields={[
                        'perteRevenuPersonnel.revaloriseMontant',
                        'montantATPADeduire.revaloriseMontant',
                        'perteDroitRetraite.revaloriseMontant',
                        'surcroitActivite.revaloriseMontant',
                        'perteChanceAssistanceConjoint.revaloriseMontant',
                      ]}
                      compute={([
                        revalorisePerteRevenuPersonnel,
                        revaloriseMontantATPADeduire,
                        revalorisePerteDroitRetraite,
                        revaloriseSurcroitActivite,
                        revalorisePerteChanceAssistanceConjoint,
                      ]) => {
                        return (
                          (revalorisePerteRevenuPersonnel || 0) -
                          (revaloriseMontantATPADeduire || 0) +
                          (revalorisePerteDroitRetraite || 0) +
                          (revaloriseSurcroitActivite || 0) +
                          (revalorisePerteChanceAssistanceConjoint || 0)
                        );
                      }}
                      render={(value) => (
                        <SpanNoWrap>
                          {t(
                            'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.total.value',
                            {
                              value: fCurrency(value),
                            },
                          )}
                        </SpanNoWrap>
                      )}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <ComputedReadFieldForm
                      control={control}
                      name="partResponsableTotal"
                      watchFields={['total', 'partResponsabilite']}
                      compute={([total, partResponsabilite]) => {
                        return (
                          (Number(total) || 0) *
                          (partResponsabilite
                            ? partResponsabilite / 100
                            : procedure.partResponsabilite)
                        );
                      }}
                      render={(value) => (
                        <SpanNoWrap>
                          {t(
                            'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeNonDecedee.fields.partResponsableTotal.value',
                            {
                              value: fCurrency(value),
                            },
                          )}
                        </SpanNoWrap>
                      )}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </NormalTable>
          </TableContainer>

          <Stack direction="row" spacing={2}></Stack>

          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
