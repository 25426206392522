import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { prejudicesData } from 'src/constants/prejudices';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { Prejudice, PrejudiceFormValeur } from 'src/types/prejudice.type';
import { getEmptyLine } from '../../docxFunctions';
import { prejudiceCalculsPrint } from '../prejudiceCalculsPrint';
import { FormTableRow, prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { Procedure } from 'src/types/procedure.type';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const valeurPrejudicePrint = ({
  prejudice,
  formData,
  procedure,
}: {
  prejudice: Pick<Prejudice, 'type' | 'formData' | 'formType'>;
  formData: PrejudiceFormValeur;
  procedure: Procedure;
}): (Paragraph | Table | TableOfContents)[] => {
  const displayTotalPartResponsabilite =
    prejudicesData[prejudice.type || 'DEPENSE_SANTE_FUTURES']
      .displayTotalPartResponsabilite;
  const partResponsabilite =
    formData.partResponsabilite || formData.partResponsabilite === 0
      ? formData.partResponsabilite
      : procedure.partResponsabilite;
  const partResponsabiliteRow: FormTableRow<
    PrejudiceFormValeur,
    'montant',
    PrejudiceFormValeur[keyof PrejudiceFormValeur]
  > = {
    name: 'montant',
    headerLabel:
      i18next.t(
        'prejudice.prejudicesFormTypes.VALEUR.fields.montant.print.partResponsabiliteColumnHeader',
        {
          partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
        },
      ) || '',
    render: (row: PrejudiceFormValeur) =>
      row.montant ? fCurrency(row.montant * partResponsabilite) : '',
  };
  const table = prejudiceFormTable(
    [formData],
    [
      {
        name: 'numerosPieces',
        headerLabel: i18next.t('numeroPiece.form.columnHeader') || '',
        render: (row) =>
          row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
      },
      {
        name: 'montant',
        headerLabel:
          i18next.t(
            'prejudice.prejudicesFormTypes.VALEUR.fields.montant.print.columnHeader',
          ) || '',
        render: (row) => (row.montant ? fCurrency(row.montant) : ''),
      },
      ...(displayTotalPartResponsabilite ? [partResponsabiliteRow] : []),
    ],
  );
  const notesPrint = prejudiceNotesPrint(formData.notes);
  return [
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...table,
    ...prejudiceCalculsPrint(prejudice),
  ];
};
