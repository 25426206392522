import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from 'src/components/basic/Page';
import { APP_NAME } from 'src/constants/global';
import { theme } from 'src/constants/theme';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchVictimeById } from 'src/slices/victime';
import { NavBar } from '../containers/NavBar';
import { ClientProcedures } from 'src/containers/client/ClientProcedures';
import Unauthorized from 'src/containers/Unauthorized';
import { victimeSelectors } from '../store/selectors';
import VictimeEdition from 'src/containers/client/VictimeEdition';
import { fetchConstant } from 'src/slices/constant';
import { fetchAllBaremes } from 'src/slices/bareme';

const Client = () => {
  const dispatch = useAppDispatch();

  const { victimeId } = useParams<any>();
  const [editVictimeOpen, setEditVictimeOpen] = useState<boolean>(false);

  const { victime } = useAppSelector((state) => ({
    victime: victimeId
      ? victimeSelectors.selectById(state, victimeId)
      : undefined,
    user: state.auth.user,
  }));

  useEffect(() => {
    if (victimeId) {
      dispatch(fetchVictimeById(victimeId));
    }
    dispatch(fetchConstant());
    dispatch(fetchAllBaremes());
  }, []);

  const onClickEditVictime = () => {
    setEditVictimeOpen(true);
  };

  if (!victime) {
    return <Unauthorized unauthorizedVictime />;
  }

  return (
    <Page title={APP_NAME}>
      <NavBar />
      <Stack
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100% - 64px)',
          position: 'relative',
          top: '64px',
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(3),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ClientProcedures
            onClickEditVictime={onClickEditVictime}
            editVictimeOpen={editVictimeOpen}
          />
        </Box>
        <VictimeEdition
          victime={victime}
          isSidebar={true}
          isOpen={editVictimeOpen}
          closeSideBar={() => setEditVictimeOpen(false)}
        />
      </Stack>
    </Page>
  );
};

export default Client;
