export interface VictimeIndirecte {
  _id: string;
  nom: string;
  prenom: string;
  sexe: Sexe;
  dateNaissance: string;
  lienVictime: LienVictime;
  lieuDeVie?: LieuDeVie;
}

export const LIEN_VICTIME_LIST = [
  'conjointConcubin',
  'enfant',
  'frereSoeur',
  'parent',
  'grandParent',
  'petitEnfant',
  'autreParentOuProche',
] as const;
export type LienVictime = (typeof LIEN_VICTIME_LIST)[number];

export const LIEU_DE_VIE_LIST = ['auFoyer', 'horsFoyer'] as const;
export type LieuDeVie = (typeof LIEU_DE_VIE_LIST)[number];

export const SEXE_LIST = ['m', 'f'] as const;
export type Sexe = (typeof SEXE_LIST)[number];

/* DTO */

export type CreateVictimeIndirecteDto = Omit<VictimeIndirecte, '_id'>;

export type UpdateVictimeIndirecteDto = Partial<CreateVictimeIndirecteDto>;
