import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  PerteGainProfessionnelsActuelSituation,
} from 'src/types/prejudice.type';
import { RegimeType } from 'src/types/regime.type';
import { CalculsGlobal } from './calculsGlobal';
import {
  IndemniteRepartieEchus,
  IndemniteRepartieTiersPayeurs,
  PerteGainProfessionnelsTotauxSituations,
  TropPercuEchus,
} from './type';
import { Time } from '../time';

export abstract class CalculsFormPerteGainsProfessionnelsActuel {
  static getSalaireNetPaye(
    regime: RegimeType,
    {
      netFiscal,
      smicBrut,
      victimeRedevable,
    }: { netFiscal: number; smicBrut: number; victimeRedevable: boolean },
  ): number {
    switch (regime) {
      case 'SALARIE_RG':
        return netFiscal * 0.9642;
      case 'SALARIE_FP':
        return netFiscal * 0.9667;
      case 'INDEPENDANT_ARTISTES_AUTEURS':
        return netFiscal * 0.9672;
      case 'INDEPENDANT_HORS_MICRO':
        return netFiscal * 0.9617;
      case 'MICRO_ENTREPRENEUR_BNC':
        return netFiscal * 0.66;
      case 'MICRO_ENTREPRENEUR_BIC_ARTISAN':
        return netFiscal * 0.5;
      case 'MICRO_ENTREPRENEUR_BIC_ACHAT_VENTE':
        return netFiscal * 0.29;
      case 'CHOMAGE':
        if (netFiscal <= smicBrut) {
          return netFiscal;
        } else {
          if (victimeRedevable) {
            return netFiscal * 0.9689;
          } else {
            return netFiscal * 0.9697;
          }
        }
      default:
        return 0;
    }
  }

  static getSalaireNetFiscal(
    regime: RegimeType,
    {
      netPaye,
      smicBrut,
      victimeRedevable,
    }: { netPaye: number; smicBrut: number; victimeRedevable: boolean },
  ): number {
    switch (regime) {
      case 'SALARIE_RG':
        return netPaye / 0.9642;
      case 'SALARIE_FP':
        return netPaye / 0.9667;
      case 'INDEPENDANT_ARTISTES_AUTEURS':
        return netPaye / 0.9672;
      case 'INDEPENDANT_HORS_MICRO':
        return netPaye / 0.9617;
      case 'MICRO_ENTREPRENEUR_BNC':
        return netPaye / 0.66;
      case 'MICRO_ENTREPRENEUR_BIC_ARTISAN':
        return netPaye / 0.5;
      case 'MICRO_ENTREPRENEUR_BIC_ACHAT_VENTE':
        return netPaye / 0.29;
      case 'CHOMAGE':
        if (netPaye <= smicBrut) {
          return netPaye;
        } else {
          if (victimeRedevable) {
            return netPaye / 0.9689;
          } else {
            return netPaye / 0.9697;
          }
        }
      default:
        return 0;
    }
  }

  static getRevenuTheorique(
    dateDebut: string | Date,
    dateFin: string | Date,
    revenuDeReference: number,
  ): number {
    return (
      revenuDeReference *
      (new Date(dateFin).getFullYear() - new Date(dateDebut).getFullYear() + 1)
    );
  }

  static getSalaireBrut(regime: RegimeType, netFiscal: number): number {
    switch (regime) {
      case 'SALARIE_RG':
        return netFiscal * 1.2361;
      case 'SALARIE_FP':
        return netFiscal * 1.1507;
    }

    return netFiscal;
  }

  static getArreragesVictime(
    revenuTheorique: number,
    revenusReelsNet: number,
    totalIndemnites: number,
  ): number {
    return (
      this.getPerteDeGainProfessionnel({
        revenuTheorique,
        revenusReelsNet,
      }) - totalIndemnites
    );
  }

  static getTotalArreragesVictime(
    totalPerteDeGain: number,
    totalIndemnites: number,
  ): number {
    return totalPerteDeGain - totalIndemnites;
  }

  static getIndemnitesNet(
    brut: number | string,
    csgCrds: number | string,
  ): number {
    return Number(brut) - Number(csgCrds);
  }

  static getTotauxSituations(
    rows: Pick<
      PerteGainProfessionnelsActuelSituation,
      | 'revenuTheorique'
      | 'revenusReelsNet'
      | 'totalPerteDeGain'
      | 'totalIndemnitesNet'
      | 'totalIndemnitesCsgCrds'
      | 'revenuDeReference'
    >[],
    partResponsabilite: number,
  ): PerteGainProfessionnelsTotauxSituations {
    const totaux = rows.reduce(
      (
        acc: {
          revenuTheorique: number;
          revenusReelsNet: number;
          perteDeGain: number;
          indemnitesCsgCrds: number;
          indemnitesNet: number;
          revenuDeReference: number;
        },
        row,
      ) => {
        return {
          revenuTheorique: acc.revenuTheorique + Number(row.revenuTheorique),
          revenusReelsNet: acc.revenusReelsNet + Number(row.revenusReelsNet),
          perteDeGain: acc.perteDeGain + this.getPerteDeGainProfessionnel(row),
          indemnitesCsgCrds:
            acc.indemnitesCsgCrds + Number(row.totalIndemnitesCsgCrds),
          indemnitesNet: acc.indemnitesNet + Number(row.totalIndemnitesNet),
          revenuDeReference:
            acc.revenuDeReference + Number(row.revenuDeReference),
        };
      },
      {
        revenuTheorique: 0,
        revenusReelsNet: 0,
        perteDeGain: 0,
        indemnitesCsgCrds: 0,
        indemnitesNet: 0,
        revenuDeReference: 0,
      },
    );
    const perteDeGain = CalculsGlobal.max([totaux.perteDeGain, 0]);
    const arreragesVictime = this.getTotalArreragesVictime(
      perteDeGain,
      totaux.indemnitesNet,
    );
    return {
      revenuTheorique: {
        value: totaux.revenuTheorique,
        partResponsabilite: totaux.revenuTheorique * partResponsabilite,
      },
      revenuReel: {
        value: totaux.revenusReelsNet,
        partResponsabilite: totaux.revenusReelsNet * partResponsabilite,
      },
      revenuDeReference: {
        value: totaux.revenuDeReference,
        partResponsabilite: totaux.revenuDeReference * partResponsabilite,
      },
      perteDeGain: {
        value: perteDeGain,
        partResponsabilite: perteDeGain * partResponsabilite,
      },
      arreragesVictime: {
        value: arreragesVictime,
        partResponsabilite: arreragesVictime * partResponsabilite,
      },
      indemniteNet: {
        value: totaux.indemnitesNet,
        partResponsabilite: totaux.indemnitesNet * partResponsabilite,
      },
      indemniteCsgCrds: {
        value: totaux.indemnitesCsgCrds,
        partResponsabilite: totaux.indemnitesCsgCrds * partResponsabilite,
      },
    };
  }

  static getTotalIndemnitesParTiersPayeurs(
    situations: PerteGainProfessionnelsActuelSituation[],
    tiersPayeurs: string[],
  ): Record<string, { netPaye: number; csgCrds: number; total: number }> {
    const totalIndemnitesBrutParTiersPayeurs = tiersPayeurs.reduce<
      Record<string, { netPaye: number; csgCrds: number; total: number }>
    >(
      (acc, tiersPayeur) => ({
        ...acc,
        [tiersPayeur]: { netPaye: 0, csgCrds: 0, total: 0 },
      }),
      {},
    );

    situations.forEach((situation) => {
      situation.tiersPayeursIndemnites.forEach((tiersPayeurIndemnites) => {
        totalIndemnitesBrutParTiersPayeurs[tiersPayeurIndemnites.tiersPayeur] =
          {
            netPaye:
              (totalIndemnitesBrutParTiersPayeurs[
                tiersPayeurIndemnites.tiersPayeur
              ]?.netPaye || 0) + (Number(tiersPayeurIndemnites.netPaye) || 0),
            csgCrds:
              (totalIndemnitesBrutParTiersPayeurs[
                tiersPayeurIndemnites.tiersPayeur
              ]?.csgCrds || 0) + (Number(tiersPayeurIndemnites.csgRds) || 0),
            total:
              (totalIndemnitesBrutParTiersPayeurs[
                tiersPayeurIndemnites.tiersPayeur
              ]?.total || 0) +
              (Number(tiersPayeurIndemnites.netPaye) || 0) +
              (Number(tiersPayeurIndemnites.csgRds) || 0),
          };
      });
    });
    return totalIndemnitesBrutParTiersPayeurs;
  }

  static getIndemnitesRepartie(
    situations: PerteGainProfessionnelsActuelSituation[],
    tiersPayeurs: string[],
    partResponsabilite: number,
  ): IndemniteRepartieEchus & TropPercuEchus {
    if (!situations) {
      return {
        indemniteGlobaleARepartir: {
          solde: 0,
          beforePartResponsabilite: 0,
        },
        indemniteVictime: {
          arreragesEchus: {
            debit: 0,
            solde: 0,
          },
        },
        indemniteTiersPayeurs: {
          arreragesEchus: {
            debit: 0,
            parTiersPayeur: [],
            totalNonReparti: 0,
          },
        },
        tropPercu: null,
      };
    }
    const totaux = this.getTotauxSituations(situations, partResponsabilite);
    const indemniteGlobaleBeforePartResponsabilite =
      totaux.revenuTheorique.value -
      totaux.revenuReel.value +
      totaux.indemniteCsgCrds.value;
    const indemnitesGlobal =
      totaux.revenuTheorique.partResponsabilite -
      totaux.revenuReel.partResponsabilite +
      totaux.indemniteCsgCrds.partResponsabilite;

    /* Préférence victime */
    const preferenceVictimeDebit = CalculsGlobal.min([
      CalculsGlobal.max([0, totaux.arreragesVictime.value]),
      indemnitesGlobal,
    ]);
    const preferenceVictimeSolde = indemnitesGlobal - preferenceVictimeDebit;

    /* Tiers payeurs */
    const totalIndemnitesParTiersPayeurs =
      this.getTotalIndemnitesParTiersPayeurs(situations, tiersPayeurs);
    const arreragesEchusTiersPayeurs: IndemniteRepartieTiersPayeurs =
      tiersPayeurs.map((tiersPayeur) => ({
        tiersPayeur,
        montant:
          ((totalIndemnitesParTiersPayeurs[tiersPayeur]?.total || 0) *
            preferenceVictimeSolde) /
          ((totaux.indemniteNet.value || 0) +
            (totaux.indemniteCsgCrds.value || 0) || 1),
        montantNonReparti:
          totalIndemnitesParTiersPayeurs[tiersPayeur]?.total || 0,
      }));
    const tropPercu =
      totaux.arreragesVictime.value < 0 ? totaux.arreragesVictime.value : null;
    const totalArreragesTiersPayeurs = CalculsGlobal.sum(
      arreragesEchusTiersPayeurs.map((tiersPayeur) => tiersPayeur.montant),
    );
    return {
      indemniteGlobaleARepartir: {
        solde: indemnitesGlobal,
        beforePartResponsabilite: indemniteGlobaleBeforePartResponsabilite,
      },
      indemniteVictime: {
        arreragesEchus: {
          debit: preferenceVictimeDebit,
          solde: preferenceVictimeSolde,
        },
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          debit: totalArreragesTiersPayeurs,
          parTiersPayeur: arreragesEchusTiersPayeurs,
          totalNonReparti: CalculsGlobal.sum(
            Object.values(totalIndemnitesParTiersPayeurs).map(
              (value) => value.total || 0,
            ),
          ),
        },
      },
      tropPercu,
    };
  }
  static getCsgCrds(brut: number | string): number {
    return (Number(brut) * 6.7) / 100;
  }

  static getCsgCrdsByNetFiscal(netFiscal: number | string): number {
    return Number(netFiscal) * 0.0696;
  }

  static getBrut(csgRds: number | string): number {
    return (Number(csgRds) * 100) / 6.7;
  }

  static getBrutByNetFiscal(netFiscal: number | string): number {
    return Number(netFiscal) * 1.0395;
  }

  static getNetPayeByNetFiscal(netFiscal: number | string): number {
    return Number(netFiscal) * 0.96985;
  }

  static getPerteDeGainProfessionnel({
    revenuTheorique,
    revenusReelsNet,
  }: Pick<
    PerteGainProfessionnelsActuelSituation,
    'revenuTheorique' | 'revenusReelsNet'
  >): number {
    return revenuTheorique - revenusReelsNet;
  }

  static getPerteDeGainAnnualise({
    dateDebut,
    dateFin,
    situations,
  }: Pick<
    OldPrejudiceFormPerteGainProfessionnelsActuel,
    'dateDebut' | 'dateFin'
  > & {
    situations: Pick<
      PerteGainProfessionnelsActuelSituation,
      'totalPerteDeGain'
    >[];
  }): number {
    if (!dateDebut || !dateFin) {
      return 0;
    }
    return CalculsGlobal.average(
      situations.map((situation) => {
        return (
          (situation.totalPerteDeGain * Time.daysInYear) /
          (CalculsGlobal.getDays(dateDebut, dateFin) || 1)
        );
      }),
    );
  }
}
