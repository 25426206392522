import * as yup from 'yup';
import {
  PrejudiceFormCalendrierDepenseRow,
  depenseFrequences,
  depenseTypes,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { dateString } from 'src/helpers/yup';
import {
  validationSchemaNumeroPieces,
  getDefaultMinAndMaxDate,
} from 'src/constants/prejudice/validation';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import i18next from 'i18next';
import { CalculsFormCalendrierDepense } from 'src/constants/calculs';

export const validationSchemaCalendrierDepenseRow = ({
  tiersPayeurs,
  isVictimeIndirecte,
  procedure,
  victime,
  prejudiceType,
}: {
  tiersPayeurs: string[];
  dateConsolidation?: Date;
  isVictimeIndirecte?: boolean;
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<PrejudiceFormCalendrierDepenseRow> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  const { minDate: minDateJustificatif, maxDate: maxDateJustificatif } =
    CalculsFormCalendrierDepense.getMinAndMaxDateJustificatif({
      procedure,
      isCalculCapitalisation: true,
    });
  return yup.object({
    type: yup.string().required().oneOf(depenseTypes),
    numerosPieces: validationSchemaNumeroPieces,
    intitule: yup.string().defined(),
    dateDebut: dateString()
      .nullable()
      .defined()
      .minDate(undefined, minDate.message, minDate.date)
      .maxDate(undefined, maxDate.message, maxDate.date, { strict: true })
      .when([], (_, schema) => {
        if (prejudiceType === 'FRAIS_OBSEQUES') {
          return schema.optional();
        } else {
          return schema.required();
        }
      }),
    dateFin: dateString()
      .nullable()
      .defined()
      .minDate(
        'dateDebut',
        i18next.t('validation.prejudices.dates.minDateDebut') || '',
      )
      .when(['type'], ([type], schema) => {
        if (prejudiceType === 'FRAIS_OBSEQUES') {
          return schema.optional();
        }
        if (type === 'recurrente') {
          schema = schema.required();
        }
        if (!isVictimeIndirecte) {
          schema = schema.maxDate(undefined, maxDate.message, maxDate.date, {
            strict: true,
          });
        }
        return schema;
      }),
    dureeDansUniteChoisie: yup.number().required(),
    frequenceMontant: yup
      .string()
      .oneOf(depenseFrequences)
      .when('type', {
        is: 'recurrente',
        then: (schema) =>
          schema.oneOf(
            depenseFrequences.filter(
              (depenseFrequence) => depenseFrequence != 'non_renseigne',
            ),
          ),
      })
      .required(),
    montantUnitaire: yup.number().required(),
    montantTotal: yup.number().required(),
    dateJustificatif: dateString()
      .defined()
      .nullable()
      .minDate(undefined, minDateJustificatif.message, minDateJustificatif.date)
      .maxDate(
        undefined,
        maxDateJustificatif.message,
        maxDateJustificatif.date,
        { strict: true },
      ),
    quantite: yup.number().required().min(1),
    montantsDejaRevalorises: yup.boolean().required(),
    priseEnChargeTiersPayeurs: yup
      .array()
      .defined()
      .of(
        yup.object({
          tiersPayeur: yup.string().oneOf(tiersPayeurs).required(),
          montant: yup.number().required(),
        }),
      ),
    priseEnChargeTiersPayeursProratises: yup
      .array()
      .defined()
      .of(
        yup.object({
          tiersPayeur: yup.string().oneOf(tiersPayeurs).required(),
          montant: yup.number().required(),
        }),
      ),
    resteACharge: yup.number().required('required'),
    renouvellementMaintenance: yup
      .number()
      .nullable()
      .when('frequenceMontant', {
        is: 'viagere',
        then: (schema) => schema.required().min(1),
      }),
    victimeIndirecte: yup
      .string()
      .when((_, schema) =>
        isVictimeIndirecte ? schema.required() : schema.optional(),
      ),
  });
};
