import {
  MenuItem,
  Select,
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { fDecimalNumber } from 'src/helpers/formatNumber';
import {
  Bareme,
  BaremeEsperanceDeVieValue,
  Gender,
  genders,
} from 'src/types/bareme.type';
interface Props {
  baremes: Bareme[];
}

const BaremeEsperanceDeVieRow: React.FC<{
  entry: [number, (number | null)[]];
}> = ({ entry }) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          position: 'sticky',
          left: 0,
        }}
        variant="head"
      >
        {entry[0]}
      </TableCell>
      {entry[1].map((value, index) => (
        <TableCell key={index} sx={{ textAlign: 'center' }}>
          {value || value === 0 ? fDecimalNumber(value, 2) : null}
        </TableCell>
      ))}
    </TableRow>
  );
};

const BaremeEsperanceDeVieTable: React.FC<{
  values: BaremeEsperanceDeVieValue['values'];
}> = ({ values }) => {
  const { t } = useTranslation();
  const ages = useMemo(
    () =>
      Object.values(values)
        .reduce(
          (accumulator: number[], value) => [
            ...accumulator,
            ...Object.keys(value).map((key) => Number(key)),
          ],
          [] as number[],
        )
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a - b),
    [values],
  );
  const sortedValuesEntries = useMemo(
    () =>
      Object.entries(values)
        .sort(([keyA], [keyB]) => Number(keyA) - Number(keyB))
        .map(
          ([key, value]) =>
            [Number(key), ages.map((age) => value[age])] as [
              number,
              (number | null)[],
            ],
        ),
    [values],
  );

  return (
    <TableContainer sx={{ flex: 1 }}>
      <NormalTable stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              variant="head"
              sx={{
                minWidth: '100px',
                position: 'sticky',
                left: 0,
                zIndex: 3,
              }}
            />
            {ages.map((age, index) => (
              <TableCell
                key={index}
                sx={{
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
                variant="head"
              >
                {t('forms.fields.age.value', {
                  count: age,
                })}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedValuesEntries.map((entry, index) => (
            <BaremeEsperanceDeVieRow key={index} entry={entry} />
          ))}
        </TableBody>
      </NormalTable>
    </TableContainer>
  );
};

const BaremeTab: React.FC<{
  bareme: Bareme;
  selectedTabIndex: number;
  index: number;
  selectedGender: Gender;
  onChangeGender: (gender: Gender) => void;
  isGenderSelectionDisplayed: boolean;
}> = ({
  bareme,
  selectedTabIndex,
  index,
  selectedGender,
  onChangeGender,
  isGenderSelectionDisplayed,
}) => {
  const { t } = useTranslation();
  const selectedBaremeValues = useMemo(
    () =>
      (bareme?.values as BaremeEsperanceDeVieValue[]).find(
        (value) => value.gender === selectedGender,
      ),
    [bareme, selectedGender],
  );
  if (bareme.source === 'CJ') {
    return (
      <Typography
        component="a"
        href={bareme.sourceComment}
        target="_blank"
        margin={2}
        sx={{
          display: selectedTabIndex === index ? '' : 'none',
        }}
      >
        {bareme.sourceComment}
      </Typography>
    );
  }
  return (
    <Stack
      key={index}
      sx={{
        display: selectedTabIndex === index ? '' : 'none',
        maxWidth: '100%',
        overflowY: 'hidden',
      }}
      spacing={2}
    >
      {isGenderSelectionDisplayed && (
        <Select
          value={selectedGender}
          onChange={(event) =>
            onChangeGender(event.target.value as 'man' | 'woman')
          }
          sx={{
            marginTop: 1,
            marginLeft: 1,
            width: '150px',
          }}
        >
          {genders
            .filter((gender) => gender !== 'all' && gender !== 'undetermined')
            .map((value, index) => (
              <MenuItem key={index} value={value}>
                {t(`bareme.genders.${value}`)}
              </MenuItem>
            ))}
        </Select>
      )}
      {selectedBaremeValues?.values ? (
        <BaremeEsperanceDeVieTable values={selectedBaremeValues?.values} />
      ) : null}
    </Stack>
  );
};

export const BaremeEsperanceDeVie: React.FC<Props> = ({
  baremes: unfilteredBaremes,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const baremes = unfilteredBaremes;
  const [selectedGender, setSelectedGender] = useState<Gender>(
    (baremes[tabIndex]?.values?.[0] as BaremeEsperanceDeVieValue | undefined)
      ?.gender || 'man',
  );
  const selectedBareme = useMemo(() => baremes[tabIndex], [baremes, tabIndex]);
  useEffect(() => {
    setSelectedGender(
      (
        baremes?.[tabIndex]?.values?.[0] as
          | BaremeEsperanceDeVieValue
          | undefined
      )?.gender || 'man',
    );
  }, [baremes, tabIndex]);

  const onChangeTabIndex = (
    _: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setTabIndex(value);
  };

  const isGenderSelectionDisplayed = useMemo(
    () =>
      Boolean(
        (selectedBareme?.values as BaremeEsperanceDeVieValue[]).some(
          (value) => value.gender !== undefined && value.gender !== 'all',
        ),
      ),
    [],
  );
  return (
    <>
      {baremes.length > 1 && (
        <Tabs value={tabIndex} onChange={onChangeTabIndex} variant="scrollable">
          {baremes.map((bareme, index) => (
            <Tab
              key={index}
              label={getCapitalisationBaremeSourceLabel(bareme)}
              value={index}
              sx={{ textTransform: 'none' }}
            />
          ))}
        </Tabs>
      )}
      {baremes.map((bareme, index) => (
        <BaremeTab
          key={index}
          bareme={bareme}
          selectedTabIndex={tabIndex}
          index={index}
          selectedGender={selectedGender}
          onChangeGender={setSelectedGender}
          isGenderSelectionDisplayed={isGenderSelectionDisplayed}
        />
      ))}
    </>
  );
};
