import { groupBy, max } from 'lodash';
import {
  IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  PerteDeGainsProfessionnelsRow,
} from 'src/types/prejudice.type';
import { CalculsGlobal } from './calculsGlobal';
import { IndemniteRepartieAEchoir, PGPFReliquat } from './type';
import { Time } from '../time';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';

export abstract class CalculsFormNewPerteGainsProfessionnelsFuturs {
  static getTotal(
    perteGainProfessionnelsTotal: number,
    indemnitesJournalieresPercuesNet: number | null,
  ): number {
    return (
      CalculsGlobal.max([perteGainProfessionnelsTotal, 0]) -
      (indemnitesJournalieresPercuesNet || 0)
    );
  }

  static getTiersPayeursTotaux(
    rows: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow[],
    isPGPF = false,
  ) {
    const tiersPayeursDict = groupBy(rows, 'tiersPayeur');
    return Object.keys(tiersPayeursDict).map((key) => {
      return isPGPF
        ? {
            tiersPayeur: key,
            indemnitesPercuesNetParPeriode: tiersPayeursDict[key]?.reduce(
              (acc, row) => acc + Number(row.indemnitesPercuesNetParPeriode),
              0,
            ),
          }
        : {
            tiersPayeur: key,
            indemnitesPercuesNetParPeriode: tiersPayeursDict[key]?.reduce(
              (acc, row) => acc + Number(row.indemnitesPercuesNetParPeriode),
              0,
            ),
            csgRDSParPeriode: tiersPayeursDict[key]?.reduce(
              (acc, row) => acc + Number(row.csgRDSParPeriode),
              0,
            ),
          };
    });
  }

  static getIndemnitesRepartie({
    perteGainProfessionnelsTotal,
    indemnitesJournalieresPercuesNet,
    renteCapitalisee,
    tiersPayeursTotalCapitalise,
    indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
    partResponsabilite,
    capitalisationTiersPayeurs,
    tiersPayeurs,
    dateConsolidation,
    dateLiquidation,
    dateDeces,
  }: Pick<
    NewPrejudiceFormPerteGainProfessionnelsFuturs,
    'capitalisationTiersPayeurs'
  > & {
    perteGainProfessionnelsTotal: number;
    indemnitesJournalieresPercuesNet: number | null;
    renteCapitalisee: number;
    tiersPayeursTotalCapitalise: number;
    indemnitesJournalieresPercuesPendantLaPeriodeDArretRows: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow[];
    partResponsabilite: number;
    tiersPayeurs: string[];
    dateConsolidation: Date | undefined;
    dateLiquidation: Date | undefined;
    dateDeces: Date | undefined;
  }): IndemniteRepartieAEchoir & {
    PGPFReliquat: PGPFReliquat;
  } {
    const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
      dateConsolidation,
      dateLiquidation,
      dateDeces,
    });
    const total = CalculsFormNewPerteGainsProfessionnelsFuturs.getTotal(
      perteGainProfessionnelsTotal,
      indemnitesJournalieresPercuesNet,
    );
    const { A, B, C, D, E } = {
      A: Number(perteGainProfessionnelsTotal),
      B: Number(indemnitesJournalieresPercuesNet),
      C: Number(total),
      D: shouldNotDisplayCapitalisation ? 0 : Number(renteCapitalisee),
      E: shouldNotDisplayCapitalisation
        ? 0
        : Number(tiersPayeursTotalCapitalise),
    };
    const FBeforePartResponsabilite = A + D;
    const F = FBeforePartResponsabilite * partResponsabilite;
    const H = CalculsGlobal.min([F, C]);
    const H2 = CalculsGlobal.max([H, 0]);
    const I = CalculsGlobal.min([F - H2, D - E]);
    const I2 = I < 0 ? 0 : H < 0 ? H + I : I;
    const G = H + I;
    const G2 = CalculsGlobal.max([G, 0]);
    const H3 = G < 0 && H2 > 0 ? 0 : H2;
    const I3 = G < 0 ? 0 : I2;
    const J = G < 0 ? F : F - G;
    const K = CalculsGlobal.min([J, B]);
    const L = J - K;

    const tiersPayeursTotaux = this.getTiersPayeursTotaux(
      indemnitesJournalieresPercuesPendantLaPeriodeDArretRows,
      true,
    );
    const PGPFReliquat: PGPFReliquat = {
      echus: H < 0 ? H : null,
      aEchoir: I < 0 ? I : null,
      total: G < 0 ? G : null,
      deduitsDuTropPercu: {
        echus: H2 !== H3 ? H2 : null,
        aEchoir: I3 !== I2 ? I : null,
      },
    };
    const tiersPayeursCapitaliseSum = shouldNotDisplayCapitalisation
      ? 0
      : CalculsGlobal.sum(
          tiersPayeurs.map(
            (tiersPayeur) =>
              capitalisationTiersPayeurs.parTiersPayeur.find(
                ({ tiersPayeur: tiersPayeurCapitalise }) =>
                  tiersPayeurCapitalise === tiersPayeur,
              )?.montantCapitalise || 0,
          ),
        );
    const arreragesAEchoirParTiersPayeur = tiersPayeurs.map((tiersPayeur) => {
      const montantCapitalise = shouldNotDisplayCapitalisation
        ? 0
        : capitalisationTiersPayeurs.parTiersPayeur.find(
            ({ tiersPayeur: tiersPayeurCapitalise }) =>
              tiersPayeurCapitalise === tiersPayeur,
          )?.montantCapitalise || 0;
      return {
        tiersPayeur,
        montant:
          (Number(L) * montantCapitalise) / (tiersPayeursCapitaliseSum || 1),
        montantNonReparti: montantCapitalise,
      };
    });
    return {
      indemniteGlobaleARepartir: {
        solde: F,
        beforePartResponsabilite: FBeforePartResponsabilite,
      },
      indemniteVictime: {
        arreragesEchus: {
          debit: H3,
          solde: 0,
        },
        arreragesAEchoir: {
          debit: I3,
          solde: 0,
        },
        total: G2,
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          debit: K,
          solde: 0,
          parTiersPayeur: tiersPayeursTotaux.map((row) => ({
            tiersPayeur: row.tiersPayeur,
            montant:
              (Number(K) * Number(row.indemnitesPercuesNetParPeriode)) /
              (Number(B) || 1),
            montantNonReparti: Number(row.indemnitesPercuesNetParPeriode),
          })),
          totalNonReparti: Number(B),
        },
        arreragesAEchoir: {
          debit: L,
          solde: 0,
          parTiersPayeur: arreragesAEchoirParTiersPayeur,
          totalNonReparti: tiersPayeursCapitaliseSum,
        },
        total: J,
      },
      PGPFReliquat,
    };
  }
  static getPerteDeChance(
    salaireAnnuelDeReference: number,
    pourcentagePerteDeChance: number,
  ): number {
    return (salaireAnnuelDeReference * pourcentagePerteDeChance) / 100;
  }

  static getPerteDeGainAnnualisee({
    rows,
    editedFields,
  }: {
    rows: Pick<
      PerteDeGainsProfessionnelsRow,
      'perteDeGainsProfessionnels' | 'duree' | 'dateDebut' | 'dateFin'
    >[];
    editedFields: string[];
  }) {
    if (editedFields.includes('perteDeGainsProfessionnels')) {
      return undefined;
    }
    const intervals =
      rows
        ?.filter(
          (
            row,
          ): row is {
            dateDebut: string;
            dateFin: string;
            duree: number | null;
            perteDeGainsProfessionnels: number;
          } => !!row.dateDebut && !!row.dateFin,
        )
        ?.map((row) => ({
          start: new Date(row.dateDebut),
          end: new Date(row.dateFin),
        })) || [];
    let areIntervalsOverlapping = false;
    try {
      areIntervalsOverlapping =
        CalculsGlobal.areIntervalsOverlapping(intervals);
    } catch (error) {
      console.error(error);
    }
    if (areIntervalsOverlapping) {
      return 0;
    }
    return max([
      CalculsGlobal.average(
        rows
          .filter((row) => row.duree !== null)
          .map((row) => {
            if (row.duree) {
              return (
                (row.perteDeGainsProfessionnels * Time.daysInYear) / row.duree
              );
            }
            return row.perteDeGainsProfessionnels;
          }),
      ),
      0,
    ]);
  }
}
