import { User } from 'src/types/auth.type';
import {
  Organization,
  OrganizationMember,
  OrganizationMemberRole,
} from 'src/types/organization.type';
import { Victime, VictimeUserRole } from 'src/types/victime.type';
import { isSubscriptionValid } from './subscription';

export const sortVictimeMembers = (
  victime: Pick<Victime, 'roles'>,
  members: OrganizationMember[],
) => {
  return [...members].sort((a, b) => {
    if (
      a.role === OrganizationMemberRole.owner &&
      b.role !== OrganizationMemberRole.owner
    ) {
      return -1;
    } else if (
      b.role === OrganizationMemberRole.owner &&
      a.role !== OrganizationMemberRole.owner
    ) {
      return 1;
    }
    if (
      a.role === OrganizationMemberRole.admin &&
      b.role !== OrganizationMemberRole.admin
    ) {
      return -1;
    } else if (
      b.role === OrganizationMemberRole.admin &&
      a.role !== OrganizationMemberRole.admin
    ) {
      return 1;
    }
    const aVictimeRole = Object.entries(victime.roles ?? {}).find(
      ([userId]) => userId === a._id,
    )?.[1];
    const bVictimeRole = Object.entries(victime.roles ?? {}).find(
      ([userId]) => userId === b._id,
    )?.[1];
    if (aVictimeRole && !bVictimeRole) {
      return -1;
    } else if (bVictimeRole && !aVictimeRole) {
      return 1;
    }
    if (
      aVictimeRole === VictimeUserRole.admin &&
      bVictimeRole !== VictimeUserRole.admin
    ) {
      return -1;
    } else if (
      bVictimeRole === VictimeUserRole.admin &&
      aVictimeRole !== VictimeUserRole.admin
    ) {
      return 1;
    }
    if (
      aVictimeRole === VictimeUserRole.editor &&
      bVictimeRole !== VictimeUserRole.editor
    ) {
      return -1;
    } else if (
      bVictimeRole === VictimeUserRole.editor &&
      aVictimeRole !== VictimeUserRole.editor
    ) {
      return 1;
    }
    if (a.firstName && b.firstName) {
      return a.firstName.localeCompare(b.firstName);
    }
    return -1;
  });
};

export const canUpdateVictime = (
  user: Pick<User, '_id' | 'subscription'>,
  victime: Victime,
  organization?: Organization,
): boolean => {
  if (!organization) {
    return isSubscriptionValid(user.subscription);
  }
  const userOrganizationRole = organization ? organization.members.find((member) => member._id === user._id)?.role : null;
  const victimeRole = Object.entries(victime.roles ?? {}).find(([userId]) => userId === user._id)?.[1];
  return (
    userOrganizationRole === OrganizationMemberRole.admin ||
    userOrganizationRole === OrganizationMemberRole.owner ||
    victimeRole === VictimeUserRole.admin ||
    victimeRole === VictimeUserRole.editor
  );
};

export const canCreateVictime = (
  user: Pick<User, 'authorization' | 'subscription'>,
  organization?: Organization,
): boolean => {
  if (!organization) {
    return isSubscriptionValid(user.subscription);
  }
  return true;
};
