import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, InputAdornment, Link, Stack } from '@mui/material';
import i18next from 'i18next';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'src/components/basic/Accordion';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import { validationSchemaPrejudiceBase } from 'src/constants/prejudice/validation';
import { isPrejudiceVictimeIndirecte } from 'src/helpers/prejudices/prejudice';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import * as yup from 'yup';
import {
  PrejudiceFormCalendrierDepense,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import {
  defaultTableDepenseRow,
  TableDepenses,
} from '../prejudiceFormComponents/DepensesSante/TableDepenses';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';
import { TotalDepensesEchues } from '../prejudiceFormComponents/TotalDepensesEchues';
import { TotalDepensesEchuesIndirecte } from '../prejudiceFormComponents/TotalDepensesEchuesIndirecte';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { validationSchemaCalendrierDepenseRow } from './validationSchemaCalendrierDepenseRow';
interface Props extends PrejudiceFormProps<PrejudiceFormCalendrierDepense> {
  hideDepenseRecurrente?: boolean;
  hideFrequenceViagere?: boolean;
  victimesIndirectes: VictimeIndirecte[];
}

export const validationSchemaCalendrierDepense = ({
  tiersPayeurs,
  dateConsolidation,
  isVictimeIndirecte,
  prejudiceType,
  procedure,
  victime,
}: {
  tiersPayeurs: string[];
  dateConsolidation?: Date;
  isVictimeIndirecte?: boolean;
  prejudiceType: PrejudiceType;
  procedure: Procedure;
  victime: Victime;
}): yup.ObjectSchema<PrejudiceFormCalendrierDepense> =>
  validationSchemaPrejudiceBase.shape({
    rows: yup.array().defined().of(
      validationSchemaCalendrierDepenseRow({
        tiersPayeurs,
        dateConsolidation,
        isVictimeIndirecte,
        prejudiceType,
        procedure,
        victime,
      }),
    ),
    revalorisationCoefficientsType: yup
      .string()
      .oneOf(['mensuel', 'annuel'])
      .required(),
    partResponsabilite: yup.number().when([], ([], schema) => {
      if (!isVictimeIndirecte) {
        return schema.optional();
      } else {
        return schema
          .optional()
          .min(0)
          .max(100)
          .transform((value) => value / 100);
      }
    }),
  });

export const calendrierDepenseDefaultValues = ({
  prejudiceType,
  procedure,
  victime,
  values,
  hidePeriode,
  multiplyPartResponsabilite,
  isVictimeIndirecte,
}: {
  prejudiceType: PrejudiceType;
  procedure: Procedure;
  victime: Victime;
  values?: PrejudiceFormCalendrierDepense;
  hidePeriode?: boolean;
  multiplyPartResponsabilite: boolean;
  isVictimeIndirecte: boolean;
}): PrejudiceFormCalendrierDepense => ({
  ...prejudiceBaseDefaultValues({
    prejudiceType,
    values,
  }),
  notes: values?.notes || '',
  rows: sortBy(values?.rows || [], (row) => row.dateDebut).map((row) => ({
    ...defaultTableDepenseRow({
      tiersPayeurs: procedure.tiersPayeurs,
      type: row.type,
      isCalculCapitalisation: false,
      hidePeriode,
      procedure,
      victime,
    }),
    ...row,
    priseEnChargeTiersPayeurs: procedure.tiersPayeurs.map((tiersPayeur) => ({
      tiersPayeur,
      montant:
        (Array.isArray(row.priseEnChargeTiersPayeurs)
          ? row.priseEnChargeTiersPayeurs
          : []
        ).find(
          (priseEnChargeTiersPayeur) =>
            priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
        )?.montant || 0,
    })),
    priseEnChargeTiersPayeursProratises: procedure.tiersPayeurs.map(
      (tiersPayeur) => ({
        tiersPayeur,
        montant:
          (Array.isArray(row.priseEnChargeTiersPayeursProratises)
            ? row.priseEnChargeTiersPayeursProratises
            : []
          ).find(
            (priseEnChargeTiersPayeur) =>
              priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
          )?.montant || 0,
      }),
    ),
  })),
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'annuel',
  partResponsabilite:
    (!isVictimeIndirecte
      ? procedure.partResponsabilite
      : values?.partResponsabilite || values?.partResponsabilite === 0
        ? values?.partResponsabilite
        : procedure.partResponsabilite) *
    (multiplyPartResponsabilite ? 100 : 1),
});

export const FormCalendrierDepense: React.FC<Props> = ({
  procedure,
  victime,
  values,
  monetaryErosions,
  hideDepenseRecurrente,
  hideFrequenceViagere,
  dateLiquidation,
  dateConsolidation,
  prejudiceType,
  victimesIndirectes,
  allNumerosPieces,
  dateDeces,
  ...props
}) => {
  const hidePeriode = prejudiceType === 'FRAIS_OBSEQUES';
  const isVictimeIndirecte = isPrejudiceVictimeIndirecte(prejudiceType);
  const useFormReturn = useForm<PrejudiceFormCalendrierDepense>({
    defaultValues: calendrierDepenseDefaultValues({
      procedure,
      victime,
      values,
      hidePeriode,
      multiplyPartResponsabilite: true,
      isVictimeIndirecte,
      prejudiceType,
    }),
    resolver: yupResolver(
      validationSchemaCalendrierDepense({
        tiersPayeurs: procedure.tiersPayeurs,
        dateConsolidation,
        isVictimeIndirecte: isPrejudiceVictimeIndirecte(prejudiceType),
        prejudiceType,
        procedure,
        victime,
      }),
    ),
  });
  const { t } = useTranslation();
  const { control } = useMemo(() => useFormReturn, [useFormReturn]);

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  const showLink = prejudiceType === 'FRAIS_DIVERS_ACTUELS';
  const simulateurLink =
    'https://www.impots.gouv.fr/simulateur-bareme-kilometrique';
  return (
    <PrejudiceContainer<PrejudiceFormCalendrierDepense>
      {...props}
      prejudiceType={prejudiceType}
      procedure={procedure}
      victime={victime}
      monetaryErosions={monetaryErosions}
      victimesIndirectes={victimesIndirectes}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={4}>
          {showLink && (
            <Link
              href={simulateurLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {simulateurLink.replace('https://www.', '')}
            </Link>
          )}
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${prejudiceType}.introduction`,
              {
                defaultValue: '',
                context: victime.dateDeces ? 'deces' : 'blessure',
              },
            )}
          />
          {isVictimeIndirecte && (
            <ComputedTextFieldForm
              control={control}
              name="partResponsabilite"
              label={t('partResponsabilite.label')}
              watchFields={[]}
              compute={() => procedure.partResponsabilite * 100}
              InputLabelProps={{
                shrink: true,
                sx: { color: 'info.light' },
              }}
              InputProps={{
                inputComponent: MaskNumber as any,
                inputProps: {
                  numberMask: { scale: 2, min: 0, max: 100 },
                  suffix: '%',
                } as IMaskNumberProps,
                sx: {
                  color: 'info.light',
                  borderColor: 'info.light',
                  ' .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'info.light',
                  },
                },
              }}
              sx={{ minWidth: '268px' }}
            />
          )}
          <Accordion title="Enregistrement des DS" defaultExpanded>
            <TableDepenses
              control={control}
              tiersPayeurs={procedure.tiersPayeurs}
              monetaryErosions={monetaryErosions}
              dateLiquidation={dateLiquidation}
              dateConsolidation={dateConsolidation}
              hideDepenseRecurrente={hideDepenseRecurrente}
              hideFrequenceViagere={hideFrequenceViagere}
              hidePeriode={hidePeriode}
              isVictimeIndirecte={isVictimeIndirecte}
              victimesIndirectes={victimesIndirectes}
              procedure={procedure}
              victime={victime}
              prejudiceType={prejudiceType}
              allNumerosPieces={allNumerosPieces}
              dateDeces={dateDeces}
            />
          </Accordion>
          <Stack direction="row" justifyContent="start">
            <Accordion
              title="Récapitulatif"
              summaryProps={{
                sx: (theme) => ({
                  backgroundColor: theme.palette.action.hover,
                }),
              }}
            >
              {isVictimeIndirecte ? (
                <TotalDepensesEchuesIndirecte
                  control={control}
                  tiersPayeurs={procedure.tiersPayeurs}
                  dateLiquidation={dateLiquidation}
                  monetaryErosions={monetaryErosions}
                  victimesIndirectes={victimesIndirectes}
                  procedure={procedure}
                />
              ) : (
                <TotalDepensesEchues
                  control={control}
                  tiersPayeurs={procedure.tiersPayeurs}
                  partResponsabilite={procedure.partResponsabilite}
                  dateLiquidation={dateLiquidation}
                  monetaryErosions={monetaryErosions}
                />
              )}
            </Accordion>
          </Stack>
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
