import React from 'react';
import { Box, Stack, Typography, styled, IconButton } from '@mui/material';
import { isAfter } from 'date-fns';
import { Victime } from '../../types/victime.type';
import { theme } from '../../constants/theme';
import { fDateLong } from '../../helpers/formatTime';
import {
  FULL_PATH_PROCEDURE,
  PATH_ORGANIZATION,
  PATH_VICTIME,
} from '../../routes/paths';
import { Edit } from '@mui/icons-material';
import { Procedure } from '../../types/procedure.type';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { CalculsGlobal } from 'src/constants/calculs';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { useMatch } from 'react-router-dom';
import { useCanUpdateVictime } from 'src/hooks/store/victime.hook';

/* Styled components */
const GreyRoundedBox = styled(Box)({
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 0 3px #ccc',
  padding: theme.spacing(2),
  width: '100%',
});

/* Component */

interface Props {
  victime: Victime;
  procedure?: Procedure;
  editVictimeOpen?: boolean;
  onClickEdit?: () => void;
}

export const EssentialInformations: React.FC<Props> = ({
  victime,
  procedure,
  editVictimeOpen,
  onClickEdit,
}) => {
  const { t } = useTranslation();
  const showDommages = !!useMatch(
    `${PATH_ORGANIZATION}${FULL_PATH_PROCEDURE}/*`,
  );
  const matchVictime = useMatch({
    path: `${PATH_ORGANIZATION}${PATH_VICTIME}`,
    end: true,
  });
  const ageDeces =
    victime.dateNaissance && victime.dateDeces
      ? CalculsGlobal.getAgeOrZero(victime.dateNaissance, victime.dateDeces)
      : undefined;
  const ageAccident =
    victime.dateNaissance && victime.dateAccident
      ? CalculsGlobal.getAgeOrZero(victime.dateNaissance, victime.dateAccident)
      : undefined;
  const ageConsolidation =
    procedure?.dateConsolidation && victime.dateNaissance
      ? CalculsGlobal.getAgeOrZero(
          victime.dateNaissance,
          procedure.dateConsolidation,
        )
      : undefined;
  const ageLiquidation =
    procedure?.dateLiquidation && victime.dateNaissance
      ? CalculsGlobal.getAgeOrZero(
          victime.dateNaissance,
          procedure.dateLiquidation,
        )
      : undefined;
  const canUpdateVictime = useCanUpdateVictime(victime);
  return (
    <GreyRoundedBox>
      <Typography variant="caption">
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack
            direction="column"
            spacing={1}
            alignItems="left"
            justifyContent="center"
          >
            <strong>Référence dossier</strong>
            {victime.referenceDossierVictime}
          </Stack>
          {victime.dateNaissance && (
            <Stack
              direction="column"
              spacing={1}
              alignItems="left"
              justifyContent="center"
            >
              <strong>Date de naissance</strong>
              {fDateLong(victime.dateNaissance)}
            </Stack>
          )}
          {victime.dateAccident && (
            <Stack
              direction="column"
              spacing={1}
              alignItems="left"
              justifyContent="center"
            >
              <strong>Date Accident / Première constatation</strong>
              {fDateLong(victime.dateAccident)}
              {ageAccident
                ? ` (${t('forms.fields.age.value', {
                    count: ageAccident.age,
                    context: ageAccident.isExactlyZero
                      ? 'exactly_zero'
                      : undefined,
                  })})`
                : ''}
            </Stack>
          )}
          {showDommages &&
            !!procedure &&
            (procedure.dateConsolidation ? (
              <Stack direction="column" spacing={1} alignItems="center">
                <strong>Date de consolidation</strong>
                {fDateLong(procedure.dateConsolidation)}
                {victime.dateNaissance &&
                (!victime.dateDeces ||
                  isAfter(
                    new Date(victime.dateDeces),
                    new Date(procedure.dateConsolidation),
                  ))
                  ? ` (${t('forms.fields.age.value', {
                      count: ageConsolidation?.age,
                      context: ageConsolidation?.isExactlyZero
                        ? 'exactly_zero'
                        : undefined,
                    })})`
                  : ''}
              </Stack>
            ) : !victime.dateDeces ? (
              <Stack direction="column" spacing={1} alignItems="center">
                <strong>Dommage non consolidé</strong>
              </Stack>
            ) : null)}
          {victime.dateDeces && (
            <Stack
              direction="column"
              spacing={1}
              alignItems="left"
              justifyContent="center"
            >
              <strong>Date de décès</strong>
              {fDateLong(victime.dateDeces)}
              {ageDeces
                ? ` (${t('forms.fields.age.value', {
                    count: ageDeces.age,
                    context: ageDeces.isExactlyZero
                      ? 'exactly_zero'
                      : undefined,
                  })})`
                : ''}
            </Stack>
          )}
          {!!procedure && procedure.dateLiquidation && (
            <Stack direction="column" spacing={1} alignItems="center">
              <strong>
                {capitalize(
                  t(
                    `procedure.fields.dateLiquidation.label.byProcedureType.${procedure.procedureType}`,
                  ) || '',
                )}
              </strong>
              {fDateLong(procedure.dateLiquidation)}
              {victime.dateNaissance &&
              (!victime.dateDeces ||
                isAfter(
                  new Date(victime.dateDeces),
                  new Date(procedure.dateLiquidation),
                ))
                ? ` (${t('forms.fields.age.value', {
                    count: ageLiquidation?.age,
                    context: ageLiquidation?.isExactlyZero
                      ? 'exactly_zero'
                      : undefined,
                  })})`
                : ''}
            </Stack>
          )}
          {!!procedure && procedure.partResponsabilite && (
            <Stack direction="column" spacing={1} alignItems="center">
              <strong>{t('partResponsabilite.label')}</strong>
              {fPartResponsabilite(procedure.partResponsabilite * 100)}
            </Stack>
          )}
          {editVictimeOpen !== undefined && matchVictime && canUpdateVictime ? (
            <IconButton onClick={onClickEdit}>
              <Edit color="primary" />
            </IconButton>
          ) : null}
        </Stack>
      </Typography>
    </GreyRoundedBox>
  );
};
