import React from 'react';
import { TextField, TextFieldProps, Tooltip } from '@mui/material';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName>;

export const TextFieldForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  ...props
}: Props<TFieldValues, TName> & TextFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, shouldUnregister, defaultValue });

  return (
    <Tooltip title={error?.message || ''}>
      <TextField {...field} error={!!error} {...props} data-testid={name} />
    </Tooltip>
  );
};
