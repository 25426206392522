import React from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
  onClickPreviousStep?: () => void;
  onClickNextStep?: () => void;
}

export const StepContainer: React.FC<Props> = ({
  children,
  onClickPreviousStep,
  onClickNextStep,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {children}
      {!!onClickPreviousStep || !!onClickNextStep ? (
        <Stack
          direction={'row'}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {!!onClickPreviousStep ? (
            <Button
              variant="outlined"
              color="primary"
              sx={{ alignSelf: 'center' }}
              onClick={onClickPreviousStep}
            >
              {t('common.previous')}
            </Button>
          ) : null}
          {!!onClickNextStep ? (
            <Button
              variant="contained"
              sx={{ alignSelf: 'center' }}
              onClick={onClickNextStep}
            >
              {t('common.next')}
            </Button>
          ) : null}
        </Stack>
      ) : null}
    </>
  );
};
