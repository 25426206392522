import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import {
  IndemnitesJournalieresPercuesPendantLaPeriodeDArret,
  IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { groupBy } from 'lodash';
import { displayNumerosPiecesWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/numerosPieces.written.print';
import { displayMontantParPeriodeFormula } from 'src/helpers/print/prejudicesWrittenPrints/formula';
import { displayVictimeNameWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/victime.written.print';
import { getPeriodeWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/periode.written.print';

export const indemnitesJournalieresPercuesPendantLaPeriodeDArretWrittenPrint =
  ({
    formData,
    indemnitesJournalieresPercuesPendantLaPeriodeDArret,
    victime,
    isPGPF,
  }: {
    formData:
      | NewPrejudiceFormPerteGainProfessionnelsActuel
      | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte;
    indemnitesJournalieresPercuesPendantLaPeriodeDArret: IndemnitesJournalieresPercuesPendantLaPeriodeDArret;
    victime: Victime;
    isPGPF: boolean;
  }): (Table | TableOfContents | Paragraph)[] => {
    const getTiersPayeurRowFirstPartText = (
      row: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
    ) =>
      i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.indemnitesJournalieresPercuesPendantLaPeriodeDArret.row.firstPart',
        {
          periode: getPeriodeWrittenPrint({
            dateDebut: row.dateDebut,
            dateFin: row.dateFin,
            duree: row.duree,
            options: {
              endCharacter: ', ',
            },
          }),
          numerosPieces: displayNumerosPiecesWrittenPrint(
            row.numerosPieces,
            'Parentheses',
          ),
        },
      );

    const getTiersPayeurIndemnitesPercuesNetParPeriodeRowText = (
      row: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
      index: number,
    ): string | null =>
      row.indemnitesPercuesNetParPeriode
        ? i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.indemnitesJournalieresPercuesPendantLaPeriodeDArret.row.indemnitesPercuesNetParPeriode',
            {
              indemnitesPercuesNetParPeriode: fCurrency(
                row.indemnitesPercuesNetParPeriode || 0,
              ),
              formula:
                (row.indemniteJournalieresPercuesNet ||
                  row.indemniteJournalieresPercuesNet === 0) &&
                row.uniteIndemniteJournalieresPercuesNet &&
                (row.duree || row.duree === 0)
                  ? displayMontantParPeriodeFormula({
                      montant: row.indemniteJournalieresPercuesNet,
                      duree: row.duree,
                      unite: row.uniteIndemniteJournalieresPercuesNet,
                      options: {
                        withParentheses: true,
                      },
                      editedFieldsParameters: {
                        formData,
                        fieldName: `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.indemnitesPercuesNetParPeriode`,
                      },
                    })
                  : '',
            },
          )
        : null;
    const getCsgCrdsRowText = (
      row: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
      index: number,
    ) =>
      row.csgRDSParPeriode || row.csgRds
        ? i18next.t(
            'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.indemnitesJournalieresPercuesPendantLaPeriodeDArret.row.csgRDSParPeriode',
            {
              csgRDSParPeriode: fCurrency(row.csgRDSParPeriode || 0),
              formula:
                (row.csgRds || row.csgRds === 0) &&
                row.uniteCsgRds &&
                (row.duree || row.duree === 0)
                  ? displayMontantParPeriodeFormula({
                      montant: row.csgRds,
                      duree: row.duree,
                      unite: row.uniteCsgRds,
                      options: {
                        withParentheses: true,
                      },
                      editedFieldsParameters: {
                        formData,
                        fieldName: `indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.${index}.csgRDSParPeriode`,
                      },
                    })
                  : '',
            },
          )
        : null;

    const nonZeroRows =
      indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows?.filter(
        (row) => !!row.indemnitesPercuesNetParPeriode || !!row.csgRDSParPeriode,
      ) || [];

    return [
      getParagraph({
        numbering: {
          reference: 'numbers-dot-simple',
          level: isPGPF ? 1 : 0,
        },
        text: i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.indemnitesJournalieresPercuesPendantLaPeriodeDArret.title',
        ),
      }),
      getParagraph(
        i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.indemnitesJournalieresPercuesPendantLaPeriodeDArret.firstPart',
          {
            victimeName: displayVictimeNameWrittenPrint(victime),
          },
        ),
      ),
      getEmptyLine(),
      ...Object.entries(groupBy(nonZeroRows, 'tiersPayeur') ?? {})
        .map(([tiersPayeur, rows]) => [
          getParagraph({
            children: getTextRun({ text: tiersPayeur }),
          }),
          getEmptyLine(),
          ...rows.map((row, index) => {
            const tiersPayeurRowFirstPartText =
              getTiersPayeurRowFirstPartText(row);
            const indemnitesPercuesNetParPeriodeText =
              getTiersPayeurIndemnitesPercuesNetParPeriodeRowText(row, index);
            const csgCrdsText = getCsgCrdsRowText(row, index);
            const bothIndemnitesJournalieresPercuesPendantLaPeriodeDArretAndCsgCrdsText =
              i18next.t(
                'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.indemnitesJournalieresPercuesPendantLaPeriodeDArret.row.bothIndemnitesJournalieresPercuesPendantLaPeriodeDArretAndCsgCrds',
              );
            return getParagraph({
              numbering: {
                reference: 'simple-list',
                level: 0,
              },
              children: getTextRun(
                `${tiersPayeurRowFirstPartText ? `${tiersPayeurRowFirstPartText}` : ''}${
                  indemnitesPercuesNetParPeriodeText
                    ? ` ${indemnitesPercuesNetParPeriodeText}`
                    : ''
                }${
                  indemnitesPercuesNetParPeriodeText && csgCrdsText
                    ? bothIndemnitesJournalieresPercuesPendantLaPeriodeDArretAndCsgCrdsText
                    : ''
                }${csgCrdsText && !indemnitesPercuesNetParPeriodeText ? ' ' : ''}${csgCrdsText ?? ''}`,
              ),
            });
          }),
        ])
        .flat(),
      getEmptyLine(),
      getParagraph(
        i18next.t(
          'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.indemnitesJournalieresPercuesPendantLaPeriodeDArret.total',
          {
            total: fCurrency(
              indemnitesJournalieresPercuesPendantLaPeriodeDArret.indemnitesJournalieresPercuesNet ||
                0,
            ),
          },
        ),
      ),
      getEmptyLine(),
      ...(indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal
        ? [
            getParagraph(
              i18next.t(
                'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.revenus.steps.indemnitesJournalieresPercuesPendantLaPeriodeDArret.totalCsgCrds',
                {
                  total: fCurrency(
                    indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal,
                  ),
                },
              ),
            ),
          ]
        : []),
    ];
  };
