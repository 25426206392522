import { Paragraph } from 'docx';
import { getTextRun } from '../docxFunctions';

export const prejudiceNotesPrint = (notes: string): Paragraph | undefined => {
  return notes.length > 0
    ? new Paragraph({
        children: getTextRun({
          break: 1,
          text: notes,
        }),
      })
    : undefined;
};
