import React, { useState, useEffect } from 'react';
import {
  Control,
  useController,
  useFieldArray,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form';
import {
  NumeroPieceValuesRow,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
} from '../../../../types/prejudice.type';
import { PeriodeStep } from './PeriodeStep';
import { RecapitulatifStep } from './RecapitulatifStep';
import { RegimeStep } from './RegimeStep';
import { RegimeType } from '../../../../types/regime.type';
import { RevenusTheoriqueStep } from './RevenusTheoriqueStep';
import { RevenusReelsStep } from './RevenusReelsStep';
import { Procedure } from '../../../../types/procedure.type';
import { RevenusIndemnitesStep } from './RevenusIndemnitesStep';
import { DatesBanner } from './DatesBanner';
import { Dommage } from 'src/types/dommage.type';
import { Victime } from 'src/types/victime.type';
import { printPrejudiceFormFieldError } from 'src/helpers/prejudices/form';
import { defaultPrejudiceFormPerteGainProfessionnelsActuelValues } from '../../prejudiceFormTypes/OldFormPerteGainsProfessionnelsActuel';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { User } from 'src/types/auth.type';
import { Stack } from '@mui/material';

interface Props {
  setAdditionalTitle: (title: string | undefined) => void;
  procedure: Procedure;
  dommage: Dommage | undefined;
  victime: Victime;
  monetaryErosions: MonetaryErosion[];
  dateConsolidation?: Date | undefined;
  dateLiquidation?: Date | undefined;
  tauxIPP: number | null;
  allNumerosPieces: NumeroPieceValuesRow[];
  user: User | null;
}

interface PropsActuel extends Props {
  control: Control<OldPrejudiceFormPerteGainProfessionnelsActuel>;
  trigger: UseFormTrigger<OldPrejudiceFormPerteGainProfessionnelsActuel>;
  values?: OldPrejudiceFormPerteGainProfessionnelsActuel;
  getValues: UseFormGetValues<OldPrejudiceFormPerteGainProfessionnelsActuel>;
  onPrevious?: () => void;
  isPGPF: false;
}

interface PropsFutur extends Props {
  control: Control<OldPrejudiceFormPerteGainProfessionnelsFuturs>;
  trigger: UseFormTrigger<OldPrejudiceFormPerteGainProfessionnelsFuturs>;
  values?: OldPrejudiceFormPerteGainProfessionnelsFuturs;
  getValues: UseFormGetValues<OldPrejudiceFormPerteGainProfessionnelsFuturs>;
  onPrevious?: () => void;
  isPGPF: true;
}

type StepType =
  | 'SELECTION_PERIODE'
  | 'RECAPITULATIF'
  | 'SELECTION_REGIME'
  | 'REVENUS_THEORIQUES'
  | 'REVENUS_REELS'
  | 'REVENUS_INDEMNITES';

export const StepManager: React.FC<PropsActuel | PropsFutur> = ({
  setAdditionalTitle,
  getValues,
  onPrevious,
  trigger,
  procedure,
  victime,
  tauxIPP,
  control,
  values,
  isPGPF,
  monetaryErosions,
  dateConsolidation,
  dateLiquidation,
  allNumerosPieces,
}) => {
  const [currentStep, setCurrentStep] = useState<StepType>(
    ((values as OldPrejudiceFormPerteGainProfessionnelsActuel | undefined)
      ?.dateDebut &&
      (values as OldPrejudiceFormPerteGainProfessionnelsActuel | undefined)
        ?.dateFin) ||
      (isPGPF && dateConsolidation && dateLiquidation)
      ? 'RECAPITULATIF'
      : 'SELECTION_PERIODE',
  );
  const [situationIndex, setSituationIndex] = useState<number | undefined>(
    undefined,
  );

  const { fields, append, remove } = useFieldArray({
    control: control as any,
    name: 'situations',
  });

  const {
    fieldState: { error },
  } = useController({
    control: control as any,
    name: 'situations',
  });

  useEffect(() => {
    switch (currentStep) {
      case 'SELECTION_PERIODE':
        setAdditionalTitle(
          'Sélection de la période concernée par les arrêts de travail',
        );
        break;
      case 'RECAPITULATIF':
        setAdditionalTitle(undefined);
        break;
      case 'SELECTION_REGIME':
        setAdditionalTitle('Sélection du type de situation professionnelle');
        break;
      case 'REVENUS_THEORIQUES':
        setAdditionalTitle(
          isPGPF
            ? '1. Revenu théorique entre la consolidation et la décision'
            : "1. Revenu d'activité théorique",
        );
        break;
      case 'REVENUS_REELS':
        setAdditionalTitle(
          isPGPF
            ? "2. Revenus d'activité perçus entre la consolidation et la décision"
            : "2. Revenus d'activité réels",
        );
        break;
      case 'REVENUS_INDEMNITES':
        setAdditionalTitle(
          isPGPF
            ? '3. Indemnités et rentes  perçus entre la consolidation et la décision'
            : '3. Indemnités',
        );
        break;
    }
  }, [currentStep]);

  const appendSituation = (regime: RegimeType) => {
    const dateDebut =
      (
        getValues as UseFormGetValues<OldPrejudiceFormPerteGainProfessionnelsActuel>
      )('dateDebut') || dateConsolidation;
    const dateFin =
      (
        getValues as UseFormGetValues<OldPrejudiceFormPerteGainProfessionnelsActuel>
      )('dateFin') || dateLiquidation;
    if (!dateDebut || !dateFin) {
      return;
    }
    const anneeDateDebut = new Date(dateDebut).getFullYear();
    const anneeDateFin = new Date(dateFin).getFullYear();
    append(
      defaultPrejudiceFormPerteGainProfessionnelsActuelValues(
        anneeDateDebut,
        anneeDateFin,
        procedure,
        regime,
      ),
    );
  };

  const cancel = () => {
    setSituationIndex(undefined);
    setCurrentStep('RECAPITULATIF');
  };

  const backToPeriodeStep = () => {
    setCurrentStep('SELECTION_PERIODE');
  };

  const getPeriodePrintBanner = () => (
    <DatesBanner
      control={control as any}
      onClickGoToPeriodeStep={backToPeriodeStep}
      canGoToPeriodeStep={fields.length === 0}
      isPGPF={isPGPF}
      procedure={procedure}
    />
  );

  return (
    <>
      {currentStep !== 'SELECTION_PERIODE' && situationIndex !== undefined
        ? getPeriodePrintBanner()
        : null}
      {currentStep === 'SELECTION_PERIODE' ? (
        <PeriodeStep
          control={control as any}
          validate={async (fieldsToValidate) => {
            if (await trigger(fieldsToValidate as any)) {
              setCurrentStep('RECAPITULATIF');
            }
          }}
          dateConsolidation={dateConsolidation}
          dateAccident={
            victime.dateAccident ? new Date(victime.dateAccident) : undefined
          }
          dateLiquidation={dateLiquidation}
          isPGPF={isPGPF}
          onPrevious={onPrevious}
        />
      ) : null}
      {
        <Stack
          direction="column"
          sx={{ display: currentStep === 'RECAPITULATIF' ? undefined : 'none' }}
        >
          <RecapitulatifStep
            procedure={procedure}
            control={control as any}
            fields={fields as any}
            remove={remove}
            newSituation={() => {
              setCurrentStep('SELECTION_REGIME');
            }}
            editSituation={(index) => {
              setSituationIndex(index);
              setCurrentStep('REVENUS_THEORIQUES');
            }}
            isPGPF={isPGPF}
            tauxIPP={tauxIPP}
            victime={victime}
          />
        </Stack>
      }
      {currentStep === 'SELECTION_REGIME' ? (
        <RegimeStep
          control={control as any}
          selectRegime={(regime) => {
            appendSituation(regime);
            setSituationIndex(fields.length);
            setCurrentStep('REVENUS_THEORIQUES');
          }}
          cancel={cancel}
        />
      ) : null}
      {currentStep === 'REVENUS_THEORIQUES' && situationIndex !== undefined ? (
        <RevenusTheoriqueStep
          control={control as any}
          situationIndex={situationIndex}
          validate={async (fieldsToValidate) => {
            if (await trigger(fieldsToValidate as any)) {
              setCurrentStep('REVENUS_REELS');
            } else {
              printPrejudiceFormFieldError(error);
            }
          }}
          cancel={async (fieldsToValidate) => {
            if (await trigger(fieldsToValidate as any)) {
              cancel();
            } else {
              printPrejudiceFormFieldError(error);
            }
          }}
          monetaryErosions={monetaryErosions}
          dateLiquidation={dateLiquidation}
          isPGPF={isPGPF}
          dateConsolidation={dateConsolidation}
          allNumerosPieces={allNumerosPieces}
        />
      ) : null}
      {currentStep === 'REVENUS_REELS' && situationIndex !== undefined ? (
        <RevenusReelsStep
          control={control as any}
          situationIndex={situationIndex}
          validate={async (fieldsToValidate) => {
            if (await trigger(fieldsToValidate as any)) {
              setCurrentStep('REVENUS_INDEMNITES');
            } else {
              printPrejudiceFormFieldError(error);
            }
          }}
          previous={async (fieldsToValidate) => {
            if (await trigger(fieldsToValidate as any, {})) {
              setCurrentStep('REVENUS_THEORIQUES');
            } else {
              printPrejudiceFormFieldError(error);
            }
          }}
          monetaryErosions={monetaryErosions}
          dateLiquidation={dateLiquidation}
          isPGPF={isPGPF}
          dateConsolidation={dateConsolidation}
          allNumerosPieces={allNumerosPieces}
        />
      ) : null}
      {currentStep === 'REVENUS_INDEMNITES' && situationIndex !== undefined ? (
        <RevenusIndemnitesStep
          procedure={procedure}
          control={control as any}
          situationIndex={situationIndex}
          validate={async (fieldsToValidate) => {
            if (await trigger(fieldsToValidate as any)) {
              setCurrentStep('RECAPITULATIF');
              setSituationIndex(undefined);
            } else {
              printPrejudiceFormFieldError(error);
            }
          }}
          previous={async (fieldsToValidate) => {
            if (await trigger(fieldsToValidate as any)) {
              setCurrentStep('REVENUS_REELS');
            } else {
              printPrejudiceFormFieldError(error);
            }
          }}
          isPGPF={isPGPF}
          tauxIPP={tauxIPP}
          allNumerosPieces={allNumerosPieces}
        />
      ) : null}
    </>
  );
};
