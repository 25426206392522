import React from 'react';
import { TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LayoutTable } from 'src/components/styled';
import { fCurrency } from 'src/helpers/formatNumber';
import { CalculsBox } from 'src/components/basic/CalculsBox';

interface Props {
  title: string;
  echus: number;
  aEchoir: number;
  total: number;
}

export const PartialRecapitulatif: React.FC<Props> = ({
  title,
  echus,
  aEchoir,
  total,
}) => {
  const { t } = useTranslation();
  return (
    <CalculsBox>
      <LayoutTable>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={2}>
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">
              {t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.total.tableRowHeader',
              )}
            </TableCell>
            <TableCell align="right">{fCurrency(total)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">
              {t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.echus.tableRowHeader',
              )}
            </TableCell>
            <TableCell align="right">{fCurrency(echus)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">
              {t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.aEchoir.tableRowHeader',
              )}
            </TableCell>
            <TableCell align="right">{fCurrency(aEchoir)}</TableCell>
          </TableRow>
        </TableBody>
      </LayoutTable>
    </CalculsBox>
  );
};
