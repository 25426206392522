import { Paragraph, Table, TableOfContents } from 'docx';
import { IndemniteRepartieEchusIndirecte } from 'src/constants/calculs/type';
import { getEmptyLine, getParagraph } from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { debitSoldeEchusIndirectePrint } from '../../prejudicesPrints/debitSoldeEchusIndirectePrint';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';

export const indemniteGlobaleARepartirIndirecteWrittenPrint = ({
  indemniteRepartie,
  partResponsabilite,
  tiersPayeurs,
  victimesIndirectes,
}: {
  indemniteRepartie: IndemniteRepartieEchusIndirecte;
  partResponsabilite: number;
  tiersPayeurs: string[];
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] => {
  if (partResponsabilite === 1) {
    return [
      getParagraph(
        i18next.t(
          'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.fullPartResponabilite.indemniteGlobale',
          {
            montant: fCurrency(
              indemniteRepartie.indemniteGlobaleARepartir.solde,
            ),
          },
        ),
      ),
      getEmptyLine(),
      ...(indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit === 0
        ? []
        : tiersPayeurs.length > 1
          ? [
              getParagraph(
                i18next.t(
                  'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.fullPartResponabilite.multipleTiersPayeursFirstPart',
                  {
                    indemniteTiersPayeurs: fCurrency(
                      indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                        .debit,
                    ),
                  },
                ),
              ),
              ...(
                indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                  .parTiersPayeur ?? []
              ).map((tiersPayeur) =>
                getParagraph(
                  i18next.t(
                    'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.fullPartResponabilite.tiersPayeurRow',
                    {
                      montant: fCurrency(tiersPayeur.montant),
                      indemniteTiersPayeur: fDecimalNumber(
                        (tiersPayeur.montant /
                          indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                            .debit) *
                          indemniteRepartie.indemniteGlobaleARepartir.solde,
                        2,
                      ),
                      indemniteGlobale: fDecimalNumber(
                        indemniteRepartie.indemniteGlobaleARepartir.solde,
                        2,
                      ),
                      totalIndemniteTiersPayeurs: fDecimalNumber(
                        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                          .debit,
                        2,
                      ),
                      tiersPayeur: tiersPayeur.tiersPayeur,
                    },
                  ),
                ),
              ),
            ]
          : tiersPayeurs.length === 1
            ? [
                getParagraph(
                  i18next.t(
                    'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.fullPartResponabilite.oneTiersPayeur',
                    {
                      montant: fCurrency(
                        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                          .debit,
                      ),
                      tiersPayeur: tiersPayeurs[0],
                    },
                  ),
                ),
              ]
            : []),
    ];
  } else {
    return [
      getParagraph(
        i18next.t(
          'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.nonFullPartResponsabilite.indemniteGlobale',
          {
            montantPartResponsabilite: fCurrency(
              indemniteRepartie.indemniteGlobaleARepartir.solde,
            ),
            montant: fCurrency(
              indemniteRepartie.indemniteGlobaleARepartir.solde /
                partResponsabilite,
            ),
            partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
          },
        ),
      ),
      getEmptyLine(),
      getParagraph(
        i18next.t(
          'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.nonFullPartResponsabilite.proches',
          {
            montant: fCurrency(
              indemniteRepartie.indemniteProche.arreragesEchus.debit,
            ),
          },
        ),
      ),
      getEmptyLine(),
      ...(indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit === 0
        ? []
        : tiersPayeurs.length > 1
          ? [
              getParagraph(
                i18next.t(
                  'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.nonFullPartResponsabilite.multipleTiersPayeursFirstPart',
                  {
                    indemniteTiersPayeurs: fCurrency(
                      indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                        .debit,
                    ),
                  },
                ),
              ),
              getEmptyLine(),
              ...(
                indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                  .parTiersPayeur ?? []
              ).map((tiersPayeur) =>
                getParagraph(
                  i18next.t(
                    'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.nonFullPartResponsabilite.tiersPayeurRow',
                    {
                      montant: fCurrency(tiersPayeur.montant),
                      indemniteTiersPayeur: fDecimalNumber(
                        (tiersPayeur.montant /
                          indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                            .debit) *
                          indemniteRepartie.indemniteGlobaleARepartir.solde,
                        2,
                      ),
                      indemniteGlobale: fDecimalNumber(
                        indemniteRepartie.indemniteGlobaleARepartir.solde,
                        2,
                      ),
                      totalIndemniteTiersPayeurs: fDecimalNumber(
                        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                          .debit,
                        2,
                      ),
                      tiersPayeur: tiersPayeur.tiersPayeur,
                    },
                  ),
                ),
              ),
            ]
          : tiersPayeurs.length === 1
            ? [
                getParagraph(
                  i18next.t(
                    'prejudice.indemniteGlobaleARepartir.indirecteEchus.writtenPrint.nonFullPartResponsabilite.oneTiersPayeur',
                    {
                      montant: fCurrency(
                        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                          .debit,
                      ),
                      tiersPayeur: tiersPayeurs[0],
                    },
                  ),
                ),
              ]
            : []),
      getEmptyLine(),
      ...debitSoldeEchusIndirectePrint({
        indemniteGlobaleARepartir: indemniteRepartie,
        partResponsabilite,
        victimesIndirectes,
      }),
    ];
  }
};
