import { Paragraph, Table, TableOfContents, TextRun } from 'docx';
import {
  ListeProjectionFonctionnelPermanent,
  ListeProjectionFonctionnelPermanentRow,
  PrejudiceFormListeProjection,
} from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { getParagraph, getTextRun } from 'src/helpers/print/docxFunctions';
import i18next from 'i18next';
import { displayNumerosPiecesWrittenPrint } from 'src/helpers/print/prejudicesWrittenPrints/numerosPieces.written.print';
import { fCurrency, fDecimalNumber, fPercent } from 'src/helpers/formatNumber';
import { fAge } from 'src/helpers/formatTime';
import { CalculsGlobal } from 'src/constants/calculs';
import { Procedure } from 'src/types/procedure.type';
import { displayFormula } from '../../formula';
import { displayVictimeNameWrittenPrint } from '../../victime.written.print';

export const dfpWrittenPrint = ({
  victime,
  formData,
  procedure,
}: {
  victime: Victime;
  formData: Omit<PrejudiceFormListeProjection, 'prejudiceValues'> & {
    prejudiceValues: ListeProjectionFonctionnelPermanent;
  };
  procedure: Procedure;
}): (Table | TableOfContents | Paragraph)[] => {
  const getRowTextRuns = (
    row: ListeProjectionFonctionnelPermanentRow,
    index: number,
  ): TextRun[] => [
    ...getTextRun(
      i18next.t(
        'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.writtenPrint.rows.firstPart',
        {
          commentaires: row.commentaires,
          numerosPieces: row.commentaires
            ? displayNumerosPiecesWrittenPrint(row.numerosPieces, 'Parentheses')
            : displayNumerosPiecesWrittenPrint(row.numerosPieces, 'WithComma'),
          context: !row.commentaires ? 'noCommentaires' : undefined,
        },
      ),
    ),
    ...(row.pourcentage
      ? [
          ...getTextRun(
            i18next.t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.writtenPrint.rows.row.pourcentage.firstPart',
              {
                pourcentage: fPercent(row.pourcentage),
              },
            ),
          ),
          ...(row.valeurPoint
            ? getTextRun(
                i18next.t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.writtenPrint.rows.row.pourcentage.valeurPoint',
                  {
                    valeurPoint: fCurrency(row.valeurPoint),
                    montant: fCurrency(row.montant),
                    formula:
                      row.montant && row.pourcentage
                        ? displayFormula({
                            formula: i18next.t(
                              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.fields.montant.formula',
                              {
                                valeurPoint: fCurrency(row.valeurPoint),
                                montant: fCurrency(row.montant),
                                pourcentage: fDecimalNumber(
                                  row.pourcentage,
                                  2,
                                  true,
                                ),
                              },
                            ),
                            options: {
                              withParentheses: true,
                            },
                            editedFieldsParameters: {
                              formData,
                              fieldName: `prejudiceValues.rows.${index}.montant`,
                            },
                          })
                        : '',
                  },
                ),
              )
            : getTextRun(
                i18next.t(
                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.writtenPrint.rows.row.pourcentage.noValeurPoint',
                  {
                    montant: fCurrency(row.montant),
                  },
                ),
              )),
        ]
      : getTextRun(
          i18next.t(
            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.writtenPrint.rows.row.noPourcentage',
            {
              montant: fCurrency(row.montant),
            },
          ),
        )),
  ];

  const rows = formData.prejudiceValues.rows;
  const firstPartTextRun = getTextRun(
    i18next.t(
      'prejudice.prejudicesFormTypes.LISTE_PROJECTION.DEFICIT_FONCTIONNEL_PERMANENT.writtenPrint.firstPart',
      {
        victimeName: displayVictimeNameWrittenPrint(victime),
        sexe: i18next.t(
          `victime.fields.sexe.options.${victime.sexe}.adjectiveLabel`,
        ),
        ageConsolidation:
          victime.dateNaissance && procedure.dateConsolidation
            ? fAge(
                CalculsGlobal.getAgeOrZero(
                  victime.dateNaissance,
                  procedure.dateConsolidation,
                ),
              )
            : '',
        context:
          victime.dateNaissance && procedure.dateConsolidation
            ? undefined
            : 'noAgeConsolidation',
      },
    ),
  );
  const rowsParagraphsByNumberOfRows: Record<
    'noRow' | 'oneRow' | 'multipleRows',
    Paragraph[]
  > = {
    noRow: [],
    oneRow: rows[0]
      ? [
          getParagraph({
            children: [...firstPartTextRun, ...getRowTextRuns(rows[0], 0)],
          }),
        ]
      : [],
    multipleRows: [
      getParagraph({ children: firstPartTextRun }),
      ...rows.map((row, index) =>
        getParagraph({
          numbering: {
            reference: 'simple-list',
            level: 0,
          },
          children: getRowTextRuns(row, index),
        }),
      ),
    ],
  };
  return [
    ...rowsParagraphsByNumberOfRows[
      rows.length === 0
        ? 'noRow'
        : rows.length === 1
          ? 'oneRow'
          : 'multipleRows'
    ],
  ];
};
