import i18next from 'i18next';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { NumeroPieceValues } from 'src/types/prejudice.type';

export type Ponctuation = 'Parentheses' | 'WithComma';

export const displayNumerosPiecesWrittenPrint = (
  numerosPieces: NumeroPieceValues,
  ponctuation?: Ponctuation,
): string => {
  const numerosPiecesString = displayNumeroPiece(numerosPieces);
  if (numerosPiecesString) {
    return i18next.t(
      `numeroPiece.writtenPrint.value${ponctuation ? ponctuation : ''}`,
      {
        numerosPieces: numerosPiecesString,
        count: numerosPieces.rows.filter((row) => !!row.numeroPiece).length,
      },
    );
  }
  return '';
};
