import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { simpleVerticalTablePrint } from 'src/helpers/print/simpleVerticalTablePrint';
import { PerteDeRetraiteValues } from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { totalCapitalisationPrint } from '../../totalCapitalisationPrint';
import { Procedure } from 'src/types/procedure.type';
import { Bareme } from 'src/types/bareme.type';
import { CalculsGlobal } from 'src/constants/calculs';
import { fDate } from 'src/helpers/formatTime';
export const perteDeRetraitePrint = ({
  perteDeRetraite: { retraite, echus, aEchoir },
  procedure,
  victime,
  capitalisationBaremes,
}: {
  perteDeRetraite: PerteDeRetraiteValues;
  procedure: Procedure;
  victime: Victime;
  capitalisationBaremes: Bareme[];
}): (TableOfContents | Paragraph | Table)[] => {
  const dateNaissance = victime.dateNaissance
    ? new Date(victime.dateNaissance)
    : null;
  const ageRetraite =
    retraite.anneeRetraite && dateNaissance
      ? retraite.anneeRetraite - dateNaissance.getFullYear()
      : null;
  const capitalisationBareme = capitalisationBaremes.find(
    (bareme) => bareme._id === aEchoir.bareme,
  );
  return [
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.fields.perteDeRetraite.print.title',
        ),
        size: 20,
        bold: true,
      }),
    }),
    getEmptyLine(),
    simpleVerticalTablePrint([
      {
        title: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.fields.perteDeRetraite.retraite.print.title',
        ),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.anneeRetraite.rowHeader',
        ),
        value: retraite.anneeRetraite?.toString() ?? '',
      },
      ...(ageRetraite !== null
        ? [
            {
              label: i18next.t(
                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.ageRetraite.rowHeader',
              ),
              value:
                i18next.t('forms.fields.age.value', {
                  count: ageRetraite,
                  context: ageRetraite === 0 ? 'exactly_zero' : undefined,
                }) ?? '',
            },
          ]
        : []),
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.retraiteTheoriqueTotal.rowHeader',
        ),
        value: fCurrency(retraite.retraiteTheoriqueTotal),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.retraitePercue.rowHeader',
        ),
        value: fCurrency(retraite.retraitePercue),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.retraite.perteAnnuelle.rowHeader',
        ),
        value: fCurrency(retraite.perteAnnuelle),
      },
    ]),
    getEmptyLine(),
    simpleVerticalTablePrint([
      {
        title: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.fields.perteDeRetraite.echus.print.title',
        ),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.echus.nombreAnneesEntreAnneeRetraiteEtAnneeCalcul.rowHeader',
        ),
        value: i18next.t('forms.fields.age.value', {
          count: echus.nombreAnneesEntreAnneeRetraiteEtAnneeCalcul,
        }),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.echus.montantAPayerADateCalcul.rowHeader',
        ),
        value: fCurrency(echus.montantAPayerADateCalcul),
      },
    ]),
    getEmptyLine(),
    simpleVerticalTablePrint([
      {
        title: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.fields.perteDeRetraite.aEchoir.print.title',
        ),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.aEchoir.dateCapitalisation.rowHeader',
        ),
        value: aEchoir.dateCapitalisation
          ? fDate(aEchoir.dateCapitalisation)
          : '',
      },
    ]),
    ...totalCapitalisationPrint({
      victime,
      procedure,
      victimeSommeACapitaliser: {
        value: aEchoir.sommeACapitaliser,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.INCIDENCE_PROFESSIONNELLE.form.fields.perteDeRetraite.aEchoir.sommeACapitaliser.rowHeader',
        ),
      },
      ageDateAttribution: {
        value:
          aEchoir.dateCapitalisation && dateNaissance
            ? CalculsGlobal.getAgeOrZero(
                dateNaissance,
                aEchoir.dateCapitalisation,
              )
            : null,
      },
      ...(capitalisationBareme
        ? {
            bareme: {
              value: capitalisationBareme,
            },
          }
        : {}),
      ageDernierArrerage: {
        value: aEchoir.ageDernierArrerage,
      },
      isLastArrerageViager: {
        value: aEchoir.isLastArrerageViager,
      },
      victimeCoefficientCapitalisation: {
        value: aEchoir.coefficient,
      },
      victimeTotalCapitalise: {
        value: aEchoir.montantCapitalise,
      },
      allTiersPayeurs: ['CPAM'],
    }),
  ];
};
