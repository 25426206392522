import React, { useEffect } from 'react';
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LayoutTable } from 'src/components/styled';
import { useInvitationsByReceiver } from 'src/hooks/store/organizationInvitation.hooks';
import {
  OrganizationInvitation,
  OrganizationInvitationStatus,
} from 'src/types/organizationInvitation.type';
import { useAppDispatch } from 'src/hooks/store';
import {
  answerOrganizationInvitationToOrganization,
  fetchAllInvitationsbyReceiver,
} from 'src/slices/organizationInvitation';
import { fetchOrganization } from 'src/slices/organization';
import {
  useDisplayedOrganization,
  useOrganizations,
} from 'src/hooks/store/organization.hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
  isWelcomePage?: boolean;
}

export const Invitations: React.FC<Props> = ({ isWelcomePage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const invitations = useInvitationsByReceiver();
  const { organizations } = useOrganizations();
  const { setDisplayedOrganization } = useDisplayedOrganization();
  useEffect(() => {
    if (!isWelcomePage) {
      dispatch(fetchAllInvitationsbyReceiver());
    }
  }, []);
  const onAnswer = (
    invitation: OrganizationInvitation,
    status: Exclude<OrganizationInvitationStatus, 'PENDING'>,
  ) => {
    dispatch(
      answerOrganizationInvitationToOrganization({
        organizationInvitationId: invitation._id,
        dto: {
          status,
        },
      }),
    );
    if (status === OrganizationInvitationStatus.ACCEPTED) {
      dispatch(fetchOrganization(invitation.organization._id));
    }
  };

  const onGoToNorma = () => {
    const firstDefinedOrganizationId = organizations[0]?._id;
    if (firstDefinedOrganizationId) {
      setDisplayedOrganization(firstDefinedOrganizationId);
    }
    navigate('/');
  };
  return (
    <Stack padding={3} height="100%">
      <Typography variant="h4">{t('pages.Invitations.title')}</Typography>
      {isWelcomePage ? (
        <Typography variant="body1" marginY={2}>
          {t('pages.Invitations.welcomeMessage', {
            count: invitations.length,
          })}
        </Typography>
      ) : null}
      {invitations.length === 0 ? (
        <Typography marginY={2} variant="body1" color="textSecondary">
          {t('pages.Invitations.noInvitations')}
        </Typography>
      ) : (
        <TableContainer sx={{ flex: 1 }}>
          <LayoutTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('pages.Invitations.table.sender.columnHeader')}
                </TableCell>
                <TableCell align="center">
                  {t('pages.Invitations.table.organizationName.columnHeader')}
                </TableCell>
                <TableCell align="center">
                  {t('pages.Invitations.table.answer.columnHeader')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invitations.map((invitation) => (
                <TableRow key={invitation._id}>
                  <TableCell>{`${invitation.sender?.firstName} ${invitation.sender?.lastName} (${invitation.sender?.email})`}</TableCell>
                  <TableCell align="center">
                    {invitation.organization.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'center',
                    }}
                  >
                    {invitation.status ===
                    OrganizationInvitationStatus.PENDING ? (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            onAnswer(
                              invitation,
                              OrganizationInvitationStatus.REJECTED,
                            )
                          }
                        >
                          {t('pages.Invitations.table.reject.button')}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            onAnswer(
                              invitation,
                              OrganizationInvitationStatus.ACCEPTED,
                            )
                          }
                        >
                          {t('pages.Invitations.table.accept.button')}
                        </Button>
                      </>
                    ) : (
                      <Typography
                        variant="body1"
                        color={(theme) =>
                          invitation.status ===
                          OrganizationInvitationStatus.ACCEPTED
                            ? theme.palette.success.main
                            : theme.palette.error.main
                        }
                      >
                        {t(
                          `organizationInvitation.fields.status.options.${invitation.status}`,
                        )}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </LayoutTable>
        </TableContainer>
      )}
      {isWelcomePage ? (
        <Stack direction="row" spacing={2} alignSelf="end">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/create-organization')}
            sx={{ alignSelf: 'end' }}
          >
            {t('pages.Invitations.createOrganization')}
          </Button>
          {organizations.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={onGoToNorma}
              sx={{ alignSelf: 'end' }}
            >
              {t('pages.Invitations.goToNorma')}
            </Button>
          ) : null}
        </Stack>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate('/')}
          sx={{ alignSelf: 'start' }}
        >
          {t('common.back')}
        </Button>
      )}
    </Stack>
  );
};
