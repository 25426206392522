import { Paragraph, Table, TableOfContents } from 'docx';
import {
  CapitalisationValues,
  PrejudiceFormBase,
  TiersPayeursCapitalisationValues,
} from '../../../types/prejudice.type';
import { Bareme } from '../../../types/bareme.type';
import { capitalisationSelectedBaremeIntroductionWrittenPrint } from './capitalisation.written.print';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { fAge, fDate } from 'src/helpers/formatTime';
import { displayFormula } from './formula';
import { fCoefficientCapitalisation } from 'src/helpers/formatValues';
import { FieldPath } from 'react-hook-form';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { CalculsGlobal } from 'src/constants/calculs';
import { displayVictimeNameWrittenPrint } from './victime.written.print';
import { getEmptyLine, getParagraph, getTextRun } from '../docxFunctions';

export const totalCapitalisationWrittenPrint = <
  TFieldValues extends PrejudiceFormBase,
  TMontantCapitaliseName extends FieldPath<TFieldValues>,
>({
  capitalisation,
  tiersPayeursCapitalisation,
  total,
  sommeACapitaliser,
  baremes,
  formData,
  montantCapitaliseName,
  procedure,
  victime,
}: {
  sommeACapitaliser: number;
  capitalisation: CapitalisationValues;
  tiersPayeursCapitalisation: TiersPayeursCapitalisationValues;
  total?: number;
  baremes: Bareme[];
  formData: TFieldValues;
  montantCapitaliseName: TMontantCapitaliseName;
  procedure: Procedure;
  victime: Victime;
}): (Table | TableOfContents | Paragraph)[] => {
  const dateLiquidation = procedure.dateLiquidation
    ? new Date(procedure.dateLiquidation)
    : null;
  const dateNaissance = victime.dateNaissance
    ? new Date(victime.dateNaissance)
    : null;
  const selectedBareme = baremes.find(
    (bareme) => bareme._id === capitalisation.bareme,
  );
  const baremeIntroductionParagraphs =
    capitalisationSelectedBaremeIntroductionWrittenPrint(selectedBareme);
  const montantCapitaliseText = i18next.t(
    'prejudice.capitalisation.writtenPrint.montantCapitalise',
    {
      sommeACapitaliser: fCurrency(sommeACapitaliser),
      dateLiquidation: dateLiquidation ? fDate(dateLiquidation) : '',
      montantCapitalise: fCurrency(capitalisation.montantCapitalise),
      formula:
        capitalisation.coefficient || capitalisation.coefficient === 0
          ? displayFormula({
              formula: i18next.t(
                'prejudice.capitalisation.form.fields.montantCapitalise.formula',
                {
                  sommeACapitaliser: fCurrency(sommeACapitaliser),
                  coefficientCapitalisation: fCoefficientCapitalisation(
                    capitalisation.coefficient,
                  ),
                },
              ),
              options: {
                withParentheses: true,
              },
              editedFieldsParameters: {
                formData,
                fieldName: montantCapitaliseName,
              },
            })
          : '',
    },
  );
  const ageDernierArrerage =
    capitalisation.ageDernierArrerage || capitalisation.ageDernierArrerage === 0
      ? fAge(capitalisation.ageDernierArrerage)
      : null;
  const ageLiquidation =
    dateNaissance && dateLiquidation
      ? fAge(CalculsGlobal.getAgeOrZero(dateNaissance, dateLiquidation))
      : null;
  const sexe = i18next.t(
    `victime.fields.sexe.options.${victime.sexe}.determinerLabel`,
  );
  const ageDernierArrerageText = capitalisation.isLastArrerageViager
    ? i18next.t('prejudice.capitalisation.writtenPrint.isLastArrerageViager', {
        sexe,
        ageLiquidation,
      })
    : i18next.t('prejudice.capitalisation.writtenPrint.ageDernierArrerage', {
        sexe,
        ageLiquidation,
        ageDernierArrerage,
      });
  const tiersPayeursFirstPart = i18next.t(
    'prejudice.capitalisation.writtenPrint.tiersPayeurs.firstPart',
    {
      victimeName: displayVictimeNameWrittenPrint(victime),
    },
  );
  const parTiersPayeur = tiersPayeursCapitalisation.parTiersPayeur.filter(
    (row) => !!row.montantCapitalise,
  );
  const tiersPayeursPargraphsByRows = ((): Paragraph[] => {
    switch (parTiersPayeur.length) {
      case 0:
        if (tiersPayeursCapitalisation.montantCapitalise) {
          return [
            getParagraph(
              i18next.t(
                'prejudice.capitalisation.writtenPrint.tiersPayeurs.noRow',
                {
                  montantCapitalise: fCurrency(
                    tiersPayeursCapitalisation.montantCapitalise,
                  ),
                },
              ),
            ),
          ];
        } else {
          return [];
        }
      case 1:
        if (parTiersPayeur[0]) {
          return [
            getParagraph({
              children: [
                ...getTextRun(tiersPayeursFirstPart),
                ...getTextRun(
                  i18next.t(
                    'prejudice.capitalisation.writtenPrint.tiersPayeurs.oneRow',
                    {
                      tiersPayeur: parTiersPayeur[0].tiersPayeur,
                      montantCapitalise: fCurrency(
                        parTiersPayeur[0].montantCapitalise,
                      ),
                    },
                  ),
                ),
              ],
            }),
          ];
        }
        return [];
      default:
        return [
          getParagraph(
            i18next.t(
              'prejudice.capitalisation.writtenPrint.tiersPayeurs.multipleRows.firstPart',
              {
                tiersPayeurs: parTiersPayeur
                  .map((row) => row.tiersPayeur)
                  .join(', '),
                montantCapitalise: fCurrency(
                  tiersPayeursCapitalisation.montantCapitalise,
                ),
              },
            ),
          ),
          ...parTiersPayeur.map((row) =>
            getParagraph({
              numbering: {
                reference: 'simple-list',
                level: 0,
              },
              children: [
                ...getTextRun(
                  i18next.t(
                    'prejudice.capitalisation.writtenPrint.tiersPayeurs.multipleRows.row',
                    {
                      tiersPayeur: row.tiersPayeur,
                      montantCapitalise: fCurrency(row.montantCapitalise),
                    },
                  ),
                ),
              ],
            }),
          ),
        ];
    }
  })();
  const totalText = total
    ? i18next.t('prejudice.capitalisation.writtenPrint.total', {
        total: fCurrency(total),
      })
    : '';
  return [
    ...baremeIntroductionParagraphs,
    getEmptyLine(),
    getParagraph(montantCapitaliseText),
    getParagraph(ageDernierArrerageText),
    ...(tiersPayeursPargraphsByRows.length > 0
      ? [getEmptyLine(), ...tiersPayeursPargraphsByRows]
      : []),
    ...(totalText.length > 0 ? [getEmptyLine(), getParagraph(totalText)] : []),
  ];
};
