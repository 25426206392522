import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { simpleVerticalTablePrint } from 'src/helpers/print/simpleVerticalTablePrint';
import {
  MonetaryErosion,
  MonetaryErosionsCoefficientsType,
} from 'src/types/monetaryErosion.type';
import {
  PerteDeChanceDeGainProfessionnel,
  RevenuActiviteAnnuelDeReferenceRow,
} from 'src/types/prejudice.type';
import {
  getMontantRevalorisePrint,
  getQuotientRevalorisationPrint,
} from '../../revalorisationPrint';
import { perteDeChanceDeGainProfessionnelPrint } from './perteDeChanceDeGainProfessionnelPrint';

export const revenuAnnuelDeReferencePrint = ({
  rows,
  revenuAnnuelDeReference,
  perteDeChanceDeGainProfessionnel,
  dateLiquidation,
  monetaryErosions,
  revalorisationCoefficientsType,
}: {
  rows: RevenuActiviteAnnuelDeReferenceRow[];
  revenuAnnuelDeReference: number;
  perteDeChanceDeGainProfessionnel: PerteDeChanceDeGainProfessionnel;
  dateLiquidation: Date | null;
  monetaryErosions: MonetaryErosion[];
  revalorisationCoefficientsType: Exclude<
    MonetaryErosionsCoefficientsType,
    'mensuel'
  >;
}): (Table | TableOfContents | Paragraph)[] => {
  const total =
    CalculsFormNewPerteGainsProfessionnelsActuels.getTotalRevenuDeReference({
      totalRevenuActiviteAnnuelDeReference: revenuAnnuelDeReference,
    });
  return [
    ...(rows.length > 0
      ? [
          simpleHorizontalTablePrint(
            [
              i18next.t('numeroPiece.form.columnHeader'),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.rows.annee.columnHeader',
              ),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.rows.revenuNet.columnHeader',
              ),
              i18next.t('prejudice.revalorisation.quotient.columnHeader'),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.rows.revenuNet.print.revaloriseColumnHeader',
              ),
            ],
            rows.map((row) => ({
              rowLabel: row.numerosPieces
                ? displayNumeroPiece(row.numerosPieces)
                : '',
              columns: [
                row.annee?.toString() || '',
                row.unite
                  ? i18next.t('prejudice.fields.unite.print.value', {
                      unite: row.unite,
                      value: fCurrency(row.revenuNet),
                    })
                  : '',
                row.montantsDejaRevalorises
                  ? ''
                  : getQuotientRevalorisationPrint({
                      anneeOrDateLiquidation: dateLiquidation || undefined,
                      anneeOrDateMontant: row.annee || undefined,
                      monetaryErosions,
                      coefficientsType: revalorisationCoefficientsType,
                    }),
                getMontantRevalorisePrint({
                  anneeOrDateLiquidation: dateLiquidation || undefined,
                  anneeOrDateMontant: row.annee || undefined,
                  montant: row.revenuNet,
                  monetaryErosions,
                  montantsDejaRevalorises: row.montantsDejaRevalorises,
                  displayStar: false,
                  coefficientsType: revalorisationCoefficientsType,
                }),
              ],
            })),
          ),
          getEmptyLine(),
        ]
      : []),
    ...(perteDeChanceDeGainProfessionnel.rows.length > 0 ||
    perteDeChanceDeGainProfessionnel.totalIndemnite > 0 ||
    perteDeChanceDeGainProfessionnel.perteDeChance > 0
      ? [
          getParagraph({
            children: getTextRun({
              text: i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.perteDeChanceDeGainProfessionnel.title',
              ),
            }),
          }),
          getEmptyLine(),
          ...perteDeChanceDeGainProfessionnelPrint({
            rows: perteDeChanceDeGainProfessionnel.rows,
            totalIndemnite: perteDeChanceDeGainProfessionnel.totalIndemnite,
            perteDeChance: perteDeChanceDeGainProfessionnel.perteDeChance,
            coefficientPerteDeChance:
              perteDeChanceDeGainProfessionnel.coefficientPerteDeChance,
          }),
          getEmptyLine(),
        ]
      : []),
    simpleVerticalTablePrint([
      {
        title: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.table.title',
        ),
        bold: false,
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.table.mois.rowHeader',
        ),
        value: fCurrency(total.mois),
      },
      {
        label: i18next.t(
          'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.total.table.annee.rowHeader',
        ),
        value: fCurrency(revenuAnnuelDeReference),
      },
    ]),
  ];
};
