import { defaultTableAssistanceRow } from 'src/components/client/prejudiceFormComponents/TableAssistance/TableAssistance';
import { prejudicesVictimesIndirectes } from 'src/constants/prejudices';
import {
  ListeProjectionFonctionnelPermanent,
  Prejudice,
  PrejudiceFormCalendrierAssistanceRow,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormListeProjection,
  PrejudiceType,
} from 'src/types/prejudice.type';

export const isRegimeChiffreAffaire = (regime: string) =>
  regime.substring(0, 18) === 'MICRO_ENTREPRENEUR';
export const isRegimeIndependant = (regime: string) =>
  regime === 'INDEPENDANT_ARTISTES_AUTEURS' ||
  regime === 'INDEPENDANT_HORS_MICRO';
export const isListeProjectionTotalDisplayed = ({
  prejudiceType,
}: {
  prejudiceType: PrejudiceType;
}) => !Boolean(prejudiceType === 'INCIDENCE_PROFESSIONNELLE');
export const isPrejudiceVictimeIndirecte = (prejudiceType: PrejudiceType) =>
  [
    ...prejudicesVictimesIndirectes.blesseeOuDecedee,
    ...prejudicesVictimesIndirectes.decedee,
  ].includes(prejudiceType);

export const getTauxIPP = (allPrejudices: Prejudice[]): number | null => {
  const prejudiceDFP = allPrejudices.find(
    (prejudice) => prejudice.type === 'DEFICIT_FONCTIONNEL_PERMANENT',
  );
  if (!prejudiceDFP) {
    return null;
  }
  const prejudiceValues = (
    prejudiceDFP.formData as PrejudiceFormListeProjection
  ).prejudiceValues as ListeProjectionFonctionnelPermanent;
  const tauxIPP = prejudiceValues.rows[0]?.pourcentage;
  return tauxIPP || null;
};

export const getDFTPATPTRows = (
  allPrejudices: Prejudice[],
  tiersPayeurs: string[],
  dateLiquidation: Date | undefined,
): PrejudiceFormCalendrierAssistanceRow[] | null => {
  const prejudiceDFTP = allPrejudices.find(
    (prejudice) => prejudice.type === 'DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL',
  );
  if (!prejudiceDFTP) {
    return null;
  }
  const formData =
    prejudiceDFTP.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel;
  return formData.rows.map(
    (row): PrejudiceFormCalendrierAssistanceRow => ({
      ...defaultTableAssistanceRow({
        tiersPayeurs,
        dateLiquidation,
        isCalculCapitalisation: false,
      }),
      dateDebut: row.dateDebut,
      dateFin: row.dateFin,
      joursPeriode: row.joursPeriode,
      joursHospitalisationADeduire: row.joursHospitalisationADeduire || 0,
      deduireJoursHospitalisation: row.deduireJoursHospitalisation || false,
      totalJours: row.totalJours,
      type: 'echu',
    }),
  );
};
