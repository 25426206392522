import { Paragraph, Table, TableOfContents } from 'docx';
import { NewPrejudiceFormPerteGainProfessionnelsActuel } from 'src/types/prejudice.type';
import { Victime } from 'src/types/victime.type';
import { revenuActiviteDeReferenceWrittenPrint } from './revenuActiviteDeReference.written.print';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { perteDeGainsProfessionnelsWrittenPrint } from './perteDeGainsProfessionnels.written.print';
import { indemnitesJournalieresPercuesPendantLaPeriodeDArretWrittenPrint } from './indemnitesJournalieresPercuesPendantLaPeriodeDArret.written.print';
import { getEmptyLine } from 'src/helpers/print/docxFunctions';

export const revenuStepsWrittenPrint = ({
  formData,
  victime,
  dateLiquidation,
  monetaryErosions,
  isPGPF,
}: {
  formData: NewPrejudiceFormPerteGainProfessionnelsActuel;
  victime: Victime;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  isPGPF: boolean;
}): (Table | TableOfContents | Paragraph)[] => {
  return [
    ...(formData.revenuActiviteAnnuelDeReference.total
      ? [
          ...revenuActiviteDeReferenceWrittenPrint({
            formData,
            rows: formData.revenuActiviteAnnuelDeReference.rows,
            revenuAnnuelDeReference:
              formData.revenuActiviteAnnuelDeReference.total,
            perteDeChanceDeGainProfessionnel:
              formData.perteDeChanceDeGainProfessionnel,
            dateLiquidation: dateLiquidation,
            monetaryErosions: monetaryErosions,
            revalorisationCoefficientsType:
              formData.revalorisationCoefficientsType,
            victime,
            isPGPF,
          }),
          getEmptyLine(),
        ]
      : []),
    ...(formData.perteDeGainsProfessionnels.total
      ? [
          ...perteDeGainsProfessionnelsWrittenPrint({
            formData,
            perteDeGainsProfessionnels: formData.perteDeGainsProfessionnels,
            dateLiquidation: dateLiquidation,
            monetaryErosions: monetaryErosions,
            revalorisationCoefficientsType:
              formData.revalorisationCoefficientsType,
            victime,
            isPGPF,
          }),
          getEmptyLine(),
        ]
      : []),
    ...(formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
      .indemnitesJournalieresPercuesNet ||
    formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.csgRdsTotal
      ? indemnitesJournalieresPercuesPendantLaPeriodeDArretWrittenPrint({
          formData,
          indemnitesJournalieresPercuesPendantLaPeriodeDArret:
            formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret,
          victime,
          isPGPF,
        })
      : []),
  ];
};
