import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import {
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import { IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow } from 'src/types/prejudice.type';
import { getPeriodePrint } from '../../periodePrint';
import { sortBy } from 'lodash';

export const tableIndemnitesJournalieresPercuesPendantLaPeriodeDArretPrint = ({
  rows,
  totalIndemniteCsgCrdsNet,
  totalIndemnitesPercuesNet,
  isPGPF,
}: {
  rows: IndemnitesJournalieresPercuesPendantLaPeriodeDArretRow[];
  totalIndemnitesPercuesNet: number | null;
  totalIndemniteCsgCrdsNet: number | null;
  isPGPF: boolean;
}): (Table | TableOfContents | Paragraph)[] => {
  const displayIndemnitesJournalieresPercuesNet = rows.some(
    ({ indemniteJournalieresPercuesNet }) => !!indemniteJournalieresPercuesNet,
  );
  const displayCsgRds = !isPGPF && rows.some(({ csgRds }) => !!csgRds);
  return [
    ...(rows.length > 0
      ? [
          simpleHorizontalTablePrint(
            [
              i18next.t('numeroPiece.form.columnHeader'),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.commentaires.columnHeader',
              ),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.tiersPayeur.columnHeader',
              ),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.dateDebut.print.periodeColumnHeader',
              ),
              ...(displayIndemnitesJournalieresPercuesNet
                ? [
                    i18next.t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.indemniteJournalieresPercuesNet.columnHeader',
                    ),
                  ]
                : []),
              ...(displayCsgRds
                ? [
                    i18next.t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.csgRds.columnHeader',
                    ),
                  ]
                : []),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.indemnitesPercuesNetParPeriode.columnHeader',
              ),
              ...(!isPGPF
                ? [
                    i18next.t(
                      'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows.csgRDSParPeriode.columnHeader',
                    ),
                  ]
                : []),
            ],
            sortBy(rows, (row) => row.dateDebut).map(
              ({
                numerosPieces,
                commentaires,
                dateDebut,
                dateFin,
                tiersPayeur,
                indemniteJournalieresPercuesNet,
                uniteIndemniteJournalieresPercuesNet,
                csgRds,
                uniteCsgRds,
                csgRDSParPeriode,
                indemnitesPercuesNetParPeriode,
                duree,
              }) => ({
                rowLabel: numerosPieces
                  ? displayNumeroPiece(numerosPieces)
                  : '',
                columns: [
                  commentaires || '',
                  tiersPayeur,
                  getPeriodePrint({
                    dateDebut,
                    dateFin,
                    duree,
                  }),
                  ...(displayIndemnitesJournalieresPercuesNet
                    ? [
                        indemniteJournalieresPercuesNet &&
                        uniteIndemniteJournalieresPercuesNet
                          ? i18next.t('prejudice.fields.unite.print.value', {
                              unite: uniteIndemniteJournalieresPercuesNet,
                              value: fCurrency(indemniteJournalieresPercuesNet),
                            })
                          : '',
                      ]
                    : []),
                  ...(displayCsgRds
                    ? [
                        csgRds && uniteCsgRds
                          ? i18next.t('prejudice.fields.unite.print.value', {
                              unite: uniteCsgRds,
                              value: fCurrency(csgRds),
                            })
                          : '',
                      ]
                    : []),
                  fCurrency(indemnitesPercuesNetParPeriode || 0),
                  ...(!isPGPF ? [fCurrency(csgRDSParPeriode || 0)] : []),
                ],
              }),
            ),
          ),
          getEmptyLine(),
        ]
      : []),
    getParagraph({
      children: [
        ...getTextRun(
          i18next.t(
            'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.total.print.title',
          ) || '',
        ),
        ...getTextRun({
          text: i18next.t(
            'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.total.print.indemnites',
            {
              value: fCurrency(totalIndemnitesPercuesNet || 0),
            },
          ),
          break: 1,
        }),
        ...(!isPGPF
          ? getTextRun({
              text: i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.indemnitesJournalieresPercuesPendantLaPeriodeDArret.total.print.csgCrds',
                {
                  value: fCurrency(totalIndemniteCsgCrdsNet || 0),
                },
              ),
              break: 1,
            })
          : []),
      ],
    }),
  ];
};
