import { Paragraph, Table, TableOfContents } from 'docx';
import {
  ListeProjectionAgrement,
  ListeProjectionFonctionnelPermanent,
  ListeProjectionIncidenceProfessionelle,
  ListeProjectionMaladie,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  Prejudice,
  PrejudiceFormListeProjection,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { getEmptyLine, getParagraph } from '../../../docxFunctions';
import { maladieWrittenPrint } from './maladie.written.print';
import { prorataTemporisWrittenPrint } from './prorataTemporis.written.print';
import { totalListeProjectionWrittenPrint } from './totalListeProjection.written.print';
import { incidenceProfessionnelleWrittenPrint } from './incidenceProfessionnelle/ip.written.print';
import { agrementWrittenPrint } from './agrement.written.print';
import { dfpWrittenPrint } from './dfp.written.print';

const listeProjectionTableWrittenPrint = ({
  prejudice,
  formData,
  victime,
  PGPFReliquat,
  procedure,
}: {
  prejudice: Pick<Prejudice, 'type'>;
  formData: PrejudiceFormListeProjection;
  victime: Victime;
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  procedure: Procedure;
}): (Paragraph | Table | TableOfContents)[] => {
  switch (prejudice.type) {
    case 'PREJUDICE_SEXUEL':
    case 'PREJUDICE_ESTHETIQUE_PERMANENT':
    case 'PREJUDICE_ESTHETIQUE_TEMPORAIRE':
    case 'SOUFFRANCES_ENDUREES':
      return maladieWrittenPrint({
        victime,
        formData: formData as Omit<
          PrejudiceFormListeProjection,
          'prejudiceValues'
        > & {
          prejudiceValues: ListeProjectionMaladie;
        },
        prejudice,
      });
    case 'PREJUDICE_AGREMENT':
    case 'AUTRES_POSTES_DE_PREJUDICES':
      return agrementWrittenPrint({
        victime,
        formData: formData as Omit<
          PrejudiceFormListeProjection,
          'prejudiceValues'
        > & {
          prejudiceValues: ListeProjectionAgrement;
        },
        prejudice,
      });
    case 'INCIDENCE_PROFESSIONNELLE':
      return incidenceProfessionnelleWrittenPrint({
        formData: formData as Omit<
          PrejudiceFormListeProjection,
          'prejudiceValues'
        > & {
          prejudiceValues: ListeProjectionIncidenceProfessionelle;
        },
        victime,
        procedure,
        PGPFReliquat,
      });
    case 'DEFICIT_FONCTIONNEL_PERMANENT':
      return dfpWrittenPrint({
        formData: formData as Omit<
          PrejudiceFormListeProjection,
          'prejudiceValues'
        > & {
          prejudiceValues: ListeProjectionFonctionnelPermanent;
        },
        victime,
        procedure,
      });
    default:
      return [];
  }
};
export const listeProjectionWrittenPrint = ({
  victime,
  formData,
  prejudice,
  procedure,
  PGPFReliquat,
}: {
  prejudice: Pick<Prejudice, 'type' | 'formData' | 'formType'>;
  formData: PrejudiceFormListeProjection;
  victime: Victime;
  procedure: Procedure;
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
}): (Table | TableOfContents | Paragraph)[] => {
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...listeProjectionTableWrittenPrint({
      prejudice,
      formData,
      victime,
      PGPFReliquat,
      procedure,
    }),
    getEmptyLine(),
    ...(prejudice.type !== 'INCIDENCE_PROFESSIONNELLE'
      ? [
          ...(formData.enableProrataTemporisDeces
            ? prorataTemporisWrittenPrint({
                victime,
                procedure,
                formData,
                prejudice,
                partResponsabilite: procedure.partResponsabilite,
              })
            : totalListeProjectionWrittenPrint({
                formData,
                prejudice,
                partResponsabilite: procedure.partResponsabilite,
                victime,
                procedure,
              })),
        ]
      : []),
  ];
};
