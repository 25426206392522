import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid, InputAdornment, Stack, Tabs } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { TextFieldForm } from '../../forms/TextFieldForm';
import {
  defaultTableAssistanceRow,
  TableAssistance,
} from '../prejudiceFormComponents/TableAssistance/TableAssistance';
import { TotalAssistanceDebitSolde } from '../prejudiceFormComponents/TotalAssistanceDebitSolde';
import {
  getCapitalisationBaremes,
  getShouldNotDisplayCapitalisation,
} from 'src/helpers/prejudices/capitalisation';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { useTranslation } from 'react-i18next';
import { Procedure } from 'src/types/procedure.type';
import { validationSchemaCalendrierAssistanceRow } from './FormCalendrierAssistance';
import { Victime } from 'src/types/victime.type';
import i18next from 'i18next';
import { PrejudiceTab } from '../PrejudiceTab';
import { intersection, omit, sortBy } from 'lodash';
import {
  validationSchemaPrejudiceBase,
  validationSchemaPrejudiceFormCapitalisationOrRenteBase,
  validationSchemaTiersPayeursCapitalisationObject,
} from 'src/constants/prejudice/validation';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import { TotalCapitalisation } from '../prejudiceFormComponents/Capitalisation/TotalCapitalisation';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { isAfter } from 'date-fns';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import { dateString } from 'src/helpers/yup';
import { MAX_TIMESTAMP, MIN_TIMESTAMP } from 'src/helpers/formatTime';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';

export const validationSchemaCalendrierAssistanceCapitalisation = ({
  procedure,
  victime,
  prejudiceType,
  dateAccident,
  dateConsolidation,
  dateLiquidation,
  dateDeces,
}: {
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
  dateAccident: Date | undefined;
  dateConsolidation: Date | undefined;
  dateLiquidation: Date | undefined;
  dateDeces: Date | undefined;
}): yup.ObjectSchema<PrejudiceFormCalendrierAssistanceCapitalisation> => {
  const normaMinDate = new Date(MIN_TIMESTAMP);
  const normaMaxDate = new Date(MAX_TIMESTAMP);
  return validationSchemaPrejudiceBase
    .concat(validationSchemaPrejudiceFormCapitalisationOrRenteBase)
    .shape({
      victimeSommeACapitaliser: yup.number().min(0).required(),
      ageDernierArrerage: yup.lazy((value) =>
        value === ''
          ? (yup
              .string()
              .optional()
              .transform(() => null) as unknown as yup.NumberSchema<
              number,
              yup.AnyObject,
              undefined,
              ''
            >)
          : yup.number().optional().nullable().min(0),
      ),
      isLastArrerageViager: yup.boolean().optional(),
      baremeCapitalisation: yup.string().optional(),
      victimeCoefficientCapitalisation: yup
        .number()
        .nullable()
        .defined()
        .min(0),
      capitalisationTiersPayeurs: yup.object(
        omit(validationSchemaTiersPayeursCapitalisationObject(), [
          'montantCapitalise',
        ]),
      ),
      tiersPayeursTotalCapitalise: yup.number().required().min(0),
      totalMontantTotalAnnualise: yup.number().defined(),
      commentaires: yup.string().optional().defined(),
      rows: yup
        .array()
        .defined()
        .of(
          validationSchemaCalendrierAssistanceRow({
            procedure,
            victime,
            prejudiceType,
          }).shape({
            dateDebut: yup
              .string()
              .isDateString()
              .nullable()
              .required()
              .when(['type'], ([type], schema) => {
                if (!dateLiquidation) {
                  return schema;
                }
                if (type === 'aEchoir') {
                  return schema.minDate(
                    undefined,
                    i18next.t(
                      'validation.prejudices.dates.minDateLiquidation',
                    ) || '',
                    dateLiquidation,
                  );
                } else if (type === 'echu') {
                  return schema.maxDate(
                    undefined,
                    i18next.t(
                      'validation.prejudices.dates.maxDateLiquidation',
                    ) || '',
                    dateLiquidation,
                  );
                }
                return schema;
              }),
            dateFin: yup
              .string()
              .isDateString()
              .nullable()
              .optional()
              .defined()
              .minDate(
                'dateDebut',
                i18next.t('validation.prejudices.dates.minDateDebut') || '',
              )
              .when(['type'], ([type], schema) => {
                const schemaWithRequired =
                  type === 'echu' ? schema.required() : schema;
                if (!dateLiquidation) {
                  return schemaWithRequired;
                }
                if (type === 'aEchoir') {
                  return schemaWithRequired.minDate(
                    undefined,
                    i18next.t(
                      'validation.prejudices.dates.minDateLiquidation',
                    ) || '',
                    dateLiquidation,
                  );
                } else if (type === 'echu') {
                  return schemaWithRequired.maxDate(
                    undefined,
                    i18next.t(
                      'validation.prejudices.dates.maxDateLiquidation',
                    ) || '',
                    dateLiquidation,
                  );
                }
                return schema;
              }),
            dateJustificatif: dateString()
              .nullable()
              .optional()
              .defined()
              .minDate(
                undefined,
                dateAccident
                  ? i18next.t('validation.prejudices.dates.minDateAccident')
                  : i18next.t('validation.dates.dateTooLow'),
                dateAccident || normaMinDate,
              )
              .maxDate(
                undefined,
                dateConsolidation || dateDeces || dateLiquidation
                  ? i18next.t(
                      `validation.prejudices.dates.${
                        dateDeces
                          ? 'maxDateDeces'
                          : dateLiquidation
                            ? 'maxDateLiquidation'
                            : dateConsolidation
                              ? 'maxDateConsolidation'
                              : ''
                      }`,
                    )
                  : i18next.t('validation.dates.dateTooHigh'),
                dateDeces ||
                  dateLiquidation ||
                  dateConsolidation ||
                  normaMaxDate,
              ),
            resteACharge: yup.number().required().min(0),
          }),
        ),
      victimeTotalCapitalise: yup.number().required(),
      ageDateAttribution: yup.number().optional(),
      revalorisationCoefficientsType: yup
        .string()
        .oneOf(['annuel', 'smic'])
        .required(),
    });
};
export const calendrierAssistanceCapitalisationDefaultValues = ({
  prejudiceType,
  procedure,
  dateLiquidation,
  values,
}: {
  prejudiceType: PrejudiceType;
  procedure: Procedure;
  dateLiquidation: Date | undefined;
  values?: PrejudiceFormCalendrierAssistanceCapitalisation;
}): PrejudiceFormCalendrierAssistanceCapitalisation => ({
  ...prejudiceBaseDefaultValues({
    values,
    prejudiceType,
  }),
  notes: values?.notes || '',
  rows:
    sortBy(values?.rows, (row) => row.dateDebut)?.map((row) => ({
      ...defaultTableAssistanceRow({
        tiersPayeurs: procedure.tiersPayeurs,
        dateLiquidation,
        isCalculCapitalisation: true,
      }),
      ...row,
      priseEnChargeTiersPayeurs: procedure.tiersPayeurs.map((tiersPayeur) => ({
        tiersPayeur,
        montant:
          (Array.isArray(row.priseEnChargeTiersPayeurs)
            ? row.priseEnChargeTiersPayeurs
            : []
          )?.find(
            (priseEnChargeTiersPayeur) =>
              priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
          )?.montant || 0,
      })),
      type:
        row.type ||
        (row.dateDebut && dateLiquidation
          ? isAfter(new Date(row.dateDebut), dateLiquidation)
            ? 'aEchoir'
            : 'echu'
          : 'echu'),
    })) || [],
  victimeSommeACapitaliser: values?.victimeSommeACapitaliser || 0,
  ageDernierArrerage: values?.ageDernierArrerage || null,
  isLastArrerageViager: values?.isLastArrerageViager || false,
  baremeCapitalisation:
    values?.baremeCapitalisation ??
    procedure.baremes?.baremeCapitalisationId ??
    '',
  victimeCoefficientCapitalisation:
    values?.victimeCoefficientCapitalisation || null,
  capitalisationTiersPayeurs: {
    parTiersPayeur: procedure.tiersPayeurs.map((tiersPayeur) => ({
      tiersPayeur,
      sommeACapitaliser: 0,
      coefficient: 0,
      montantCapitalise: 0,
      ...values?.capitalisationTiersPayeurs?.parTiersPayeur?.find(
        (row) => row.tiersPayeur === tiersPayeur,
      ),
    })),
  },
  tiersPayeursTotalCapitalise: values?.tiersPayeursTotalCapitalise || 0,
  commentaires: values?.commentaires || '',
  totalMontantTotalAnnualise: values?.totalMontantTotalAnnualise || 0,
  isRentesOption: values?.isRentesOption || false,
  rentes: {
    montant: values?.rentes?.montant || 0,
  },
  victimeTotalCapitalise: values?.victimeTotalCapitalise || 0,
  ageDateAttribution: values?.ageDateAttribution,
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'smic',
});
type Props =
  PrejudiceFormProps<PrejudiceFormCalendrierAssistanceCapitalisation>;

export const FormCalendrierAssistanceCapitalisation: React.FC<Props> = ({
  victime,
  procedure,
  values,
  monetaryErosions,
  baremes,
  dateLiquidation,
  allNumerosPieces,
  dateDeces,
  dateConsolidation,
  dateAccident,
  ...props
}) => {
  const [activeTab, setActiveTabs] = useState(0);

  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, victime.sexe),
    [baremes, victime.sexe],
  );

  const useFormReturn =
    useForm<PrejudiceFormCalendrierAssistanceCapitalisation>({
      defaultValues: calendrierAssistanceCapitalisationDefaultValues({
        procedure,
        dateLiquidation,
        values,
        prejudiceType: props.prejudiceType,
      }),
      resolver: yupResolver(
        validationSchemaCalendrierAssistanceCapitalisation({
          procedure,
          victime,
          prejudiceType: props.prejudiceType,
          dateAccident,
          dateConsolidation,
          dateLiquidation,
          dateDeces,
        }),
      ),
    });
  const {
    control,
    formState: { errors },
  } = useMemo(() => useFormReturn, [useFormReturn]);
  const { t } = useTranslation();
  const isFirstTabError = useMemo(() => {
    const firstTabFields: (keyof PrejudiceFormCalendrierAssistanceCapitalisation)[] =
      ['rows', 'notes'];
    return (
      intersection(Object.keys(errors), firstTabFields).length > 0 &&
      activeTab !== 0
    );
  }, [errors, activeTab]);

  const isSecondTabError = useMemo(() => {
    const secondTabFields: (keyof PrejudiceFormCalendrierAssistanceCapitalisation)[] =
      [
        'victimeSommeACapitaliser',
        'ageDernierArrerage',
        'isLastArrerageViager',
        'baremeCapitalisation',
        'victimeCoefficientCapitalisation',
        'capitalisationTiersPayeurs',
        'commentaires',
        'victimeTotalCapitalise',
      ];
    return (
      intersection(Object.keys(errors), secondTabFields).length > 0 &&
      activeTab !== 1
    );
  }, [errors, activeTab]);
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation,
    dateLiquidation,
    dateDeces,
  });

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<PrejudiceFormCalendrierAssistanceCapitalisation>
      {...props}
      procedure={procedure}
      victime={victime}
      monetaryErosions={monetaryErosions}
      baremes={baremes}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={4} flex={1}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <Tabs value={activeTab} onChange={(_, value) => setActiveTabs(value)}>
            <PrejudiceTab label="ATPF" value={0} isError={isFirstTabError} />
            {!shouldNotDisplayCapitalisation ? (
              <PrejudiceTab
                label="Capitalisation"
                value={1}
                isError={isSecondTabError}
              />
            ) : null}
            <PrejudiceTab label="ATPF Récapitulatif" value={2} />
          </Tabs>
          <Stack sx={{ display: activeTab === 0 ? '' : 'none' }}>
            {activeTab === 0 && (
              <TableAssistance
                control={control}
                tiersPayeurs={procedure.tiersPayeurs}
                hospitalisations={procedure.hospitalisations}
                dateConsolidation={
                  procedure?.dateConsolidation
                    ? new Date(procedure.dateConsolidation)
                    : undefined
                }
                dateLiquidation={dateLiquidation}
                monetaryErosions={monetaryErosions}
                isCalculCapitalisation
                prejudiceType={props.prejudiceType}
                procedure={procedure}
                victime={victime}
                allNumerosPieces={allNumerosPieces}
                shouldNotDisplayCapitalisation={shouldNotDisplayCapitalisation}
                dateDeces={dateDeces}
              />
            )}
          </Stack>
          {!shouldNotDisplayCapitalisation ? (
            <Grid
              container
              sx={{ display: activeTab === 1 ? '' : 'none' }}
              spacing={1}
            >
              <Grid item md={8}>
                <TotalCapitalisation
                  control={control}
                  victimeName={{
                    sommeACapitaliser: 'victimeSommeACapitaliser',
                    bareme: 'baremeCapitalisation',
                    coefficient: 'victimeCoefficientCapitalisation',
                    montantCapitalise: 'victimeTotalCapitalise',
                    isLastArrerageViager: 'isLastArrerageViager',
                    ageDernierArrerage: 'ageDernierArrerage',
                    ageDateAttribution: 'ageDateAttribution',
                  }}
                  procedure={procedure}
                  dateLiquidation={
                    dateLiquidation ? new Date(dateLiquidation) : null
                  }
                  baremes={capitalisationBaremes}
                  dateNaissance={
                    victime.dateNaissance
                      ? new Date(victime.dateNaissance)
                      : null
                  }
                  dateDeces={
                    victime.dateDeces ? new Date(victime.dateDeces) : null
                  }
                  sexe={victime.sexe}
                  tiersPayeursName={{
                    root: 'capitalisationTiersPayeurs.parTiersPayeur',
                    montantCapitalise: 'tiersPayeursTotalCapitalise',
                  }}
                  tiersPayeurSuffix={{
                    sommeACapitaliser: 'sommeACapitaliser',
                    montantCapitalise: 'montantCapitalise',
                    coefficient: 'coefficient',
                  }}
                  defaultValuesNames={{
                    victimeSommeACapitaliser: 'totalMontantTotalAnnualise',
                  }}
                  displayTotal
                  editedFieldsName="editedFields"
                />
              </Grid>
              <Grid item md={4}>
                <CalculsBox>
                  <Stack width={'98%'}>
                    <TextFieldForm
                      control={control}
                      name="commentaires"
                      label="commentaires"
                      multiline
                      inputProps={{
                        sx: { minHeight: '200px' },
                      }}
                      maxRows={9}
                      fullWidth
                    />
                  </Stack>
                </CalculsBox>
              </Grid>
            </Grid>
          ) : null}
          <Box sx={{ display: activeTab === 2 ? '' : 'none' }}>
            <TotalAssistanceDebitSolde
              control={control}
              tiersPayeurs={procedure.tiersPayeurs}
              partResponsabilite={procedure.partResponsabilite}
              dateLiquidation={dateLiquidation}
              monetaryErosions={monetaryErosions}
              dateConsolidation={
                procedure.dateConsolidation
                  ? new Date(procedure.dateConsolidation)
                  : undefined
              }
              dateDeces={
                victime.dateDeces ? new Date(victime.dateDeces) : undefined
              }
            />
          </Box>
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
