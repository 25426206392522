import { PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow } from 'src/types/prejudice.type';

export abstract class CalculsFormCalendrierDeficitFonctionnelTemporaireTotal {
  static montantTotal({
    totalJours,
    forfaitJour,
  }: {
    totalJours: number;
    forfaitJour: number;
  }) {
    return forfaitJour * totalJours;
  }
  static total(
    rows: PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotalRow[],
  ) {
    return rows
      .map((row) => row.totalJours * row.forfaitJour)
      .reduce((acc, curr) => acc + curr, 0);
  }
}
