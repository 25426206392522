import { PrejudiceFormScolaire } from 'src/types/prejudice.type';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { prejudiceFormTable } from '../prejudiceFormTablePrint';
import i18next from 'i18next';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { simpleVerticalTablePrint } from '../../simpleVerticalTablePrint';
import { fDate } from 'src/helpers/formatTime';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { fPartResponsabilite } from 'src/helpers/formatValues';

export const scolairePrint = ({
  formData,
  partResponsabilite,
}: {
  formData: PrejudiceFormScolaire;
  partResponsabilite: number;
}) => {
  const table = prejudiceFormTable(
    [formData],
    [
      {
        name: 'numerosPieces',
        headerLabel: i18next.t('numeroPiece.form.columnHeader') || '',
        render: (row) =>
          row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
      },
      {
        name: 'montant',
        headerLabel:
          i18next.t(
            'prejudice.prejudicesFormTypes.SCOLAIRE.fields.montant.label',
          ) || '',
        render: (row) => (row.montant ? fCurrency(row.montant) : ''),
      },
    ],
  );

  const perteDeChance = prejudiceFormTable(formData.perteDeChance.rows, [
    {
      name: 'numerosPieces',
      headerLabel: i18next.t('numeroPiece.form.columnHeader') || '',
      render: (row) =>
        row.numerosPieces ? displayNumeroPiece(row.numerosPieces) : '',
    },
    {
      name: 'montant',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.montant.columnHeader',
        ) || '',
      render: (row) => (row.montant ? fCurrency(row.montant) : ''),
    },
    {
      name: 'libelle',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.libelle.columnHeader',
        ) || '',
      render: (row) => row.libelle || '',
    },
    {
      name: 'date',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.date.columnHeader',
        ) || '',
      render: (row) => (row.date ? fDate(row.date) : ''),
    },
    {
      name: 'coefficientPerteDeChance',
      headerLabel:
        i18next.t(
          'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.rows.coefficientPerteDeChance.columnHeader',
        ) || '',
      render: (row) =>
        row.coefficientPerteDeChance
          ? fPercent(row.coefficientPerteDeChance * 100)
          : '',
    },
  ]);

  const totalTable = simpleVerticalTablePrint([
    {
      label: i18next.t(
        'prejudice.prejudicesFormTypes.SCOLAIRE.fields.montant.print.title',
      ),
      value: fCurrency(formData.total),
      bold: true,
    },
    {
      label:
        i18next.t('partResponsabilite.tableRowHeader', {
          partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
        }) || '',
      value: fCurrency(formData.total * partResponsabilite),
      bold: true,
    },
  ]);

  const notesPrint = prejudiceNotesPrint(formData.notes);
  return [
    getEmptyLine(),
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...table,
    ...(formData.perteDeChance.rows.length > 0
      ? [
          getEmptyLine(),
          getParagraph({
            children: getTextRun({
              text: i18next.t(
                'prejudice.prejudicesFormTypes.SCOLAIRE.fields.perteDeChance.title',
              ),
              bold: true,
            }),
          }),
          getEmptyLine(),
          ...perteDeChance,
        ]
      : []),
    getEmptyLine(),
    totalTable,
  ];
};
