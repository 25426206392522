import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { deleteProcedureById, updateProcedure } from 'src/slices/procedure';

import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import Sidebar from 'src/components/basic/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { victimeSelectors } from '../store/selectors';
import { ConfirmationDialog } from '../components/basic/ConfirmationDialog';
import { Procedure } from '../types/procedure.type';
import { fetchAllVictimes } from '../slices/victime';
import { ProcedureMenu } from '../components/client/ProcedureMenu';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';

const useStyles = createUseStyles({
  dossierEditionHeader: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  dossierEditionStackInputs: {
    margin: theme.spacing(2, 0),
  },
  dossierEditionActions: {},
  dossierEditionActionsCancel: {},
  dossierEditionActionsSave: {},
  tableContainer: {
    padding: '24px',
    position: 'relative',
    top: '64px',
  },
  firstColumnTable: {
    paddingLeft: '0px',
  },
  buttonAddFolder: {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    position: 'fixed',
    bottom: 24,
    right: 24,
  },
  tableHead: {
    '& .MuiTableCell-root': {
      color: '#19A3FF',
      fontWeight: 'heavy',
    },
  },
  rowTable: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  nameRowTable: {
    fontWeight: 600,
  },
});

interface Props {
  isSidebar?: boolean;
  isOpen: boolean | undefined;
  closeSideBar: () => void;
}

const ProcedureTrashCanDialog: React.FC<Props> = ({
  isSidebar,
  isOpen,
  closeSideBar,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organization } = useDisplayedOrganization();

  const [deleteProcedureConfirmDialog, setDeleteProcedureConfirmDialog] =
    useState(false);

  const [cleanTrashConfirmDialog, setCleanTrashConfirmDialog] = useState(false);

  const [recycleProcedureConfirmDialog, setRecycleProcedureConfirmDialog] =
    useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState<string | null>(
    null,
  );

  const { victimeId } = useParams<any>();

  const victime = useAppSelector((state) =>
    victimeId ? victimeSelectors.selectById(state, victimeId) : undefined,
  );

  const [procedureRows, setDossierRows] = useState<Procedure[]>([]);
  const fetchVictimes = () => dispatch(fetchAllVictimes(organization?._id));
  useEffect(() => {
    fetchVictimes();
  }, [organization?._id]);

  useEffect(() => {
    setDossierRows(
      victime?.procedures.filter((procedure) => procedure.inTrash) || [],
    );
  }, [victime]);

  const deleteProcedure = async () => {
    if (selectedProcedure) {
      await dispatch(deleteProcedureById(selectedProcedure));
      setDeleteProcedureConfirmDialog(false);
      fetchVictimes();
    }
  };

  const recycleProcedureInTrash = async () => {
    if (selectedProcedure && victimeId) {
      await dispatch(
        updateProcedure({
          victimeId,
          procedureId: selectedProcedure,
          data: { inTrash: false },
        }),
      );
      setRecycleProcedureConfirmDialog(false);
      fetchVictimes();
    }
  };

  const cleanTheTrash = () => {
    procedureRows.forEach((procedure) => {
      dispatch(deleteProcedureById(procedure._id));
    });
    setCleanTrashConfirmDialog(false);
    fetchVictimes();
  };

  const header = (
    <Typography variant="h4" className={classes.dossierEditionHeader}>
      {t('procedure.trash.title')}
    </Typography>
  );

  const body = (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell align="left" className={classes.firstColumnTable}>
                #
              </TableCell>
              <TableCell>
                {t('procedure.fields.intitule.table.label')}
              </TableCell>
              <TableCell align="left">
                {t('procedure.fields.dateRedaction.table.label')}
              </TableCell>
              <TableCell align="left">
                {t('procedure.fields.dateExpertise.table.label')}
              </TableCell>
              <TableCell align="left">
                {t('procedure.fields.dateLiquidation.table.label', {
                  procedureType: 'LIQUIDATION',
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procedureRows?.map((procedureRow, index) => (
              <TableRow
                key={procedureRow._id}
                className={classes.rowTable}
                onClick={() => {
                  navigate(`/procedures/${procedureRow._id}`);
                }}
              >
                <TableCell align="left" className={classes.firstColumnTable}>
                  {index + 1}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.nameRowTable}
                >
                  {procedureRow.intitule}
                </TableCell>
                <TableCell align="left">
                  {procedureRow.dateRedaction &&
                    new Date(procedureRow.dateRedaction).toLocaleDateString()}
                </TableCell>
                <TableCell align="left">
                  {procedureRow.dateExpertise &&
                    new Date(procedureRow.dateExpertise).toLocaleDateString()}
                </TableCell>
                <TableCell align="left">
                  {procedureRow.dateLiquidation &&
                    new Date(procedureRow.dateLiquidation).toLocaleDateString()}
                </TableCell>
                <TableCell align="left">
                  <ProcedureMenu
                    procedureId={procedureRow._id}
                    setProcedureToDelete={setSelectedProcedure}
                    setDeleteProcedureConfirmDialog={
                      setDeleteProcedureConfirmDialog
                    }
                    setRecycleProcedureConfirmDialog={
                      setRecycleProcedureConfirmDialog
                    }
                    isTrash
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={deleteProcedureConfirmDialog}
        onCancel={() => setDeleteProcedureConfirmDialog(false)}
        onConfirm={deleteProcedure}
        title={t('procedure.trash.deleteDialog.title')}
        description={t('procedure.trash.deleteDialog.description') || ''}
        confirmText={t('procedure.trash.deleteDialog.confirm') || ''}
        cancelText={t('procedure.trash.deleteDialog.cancel') || ''}
      />
      <ConfirmationDialog
        open={cleanTrashConfirmDialog}
        onCancel={() => setCleanTrashConfirmDialog(false)}
        onConfirm={cleanTheTrash}
        title={t('procedure.trash.cleanDialog.title')}
        description={t('procedure.trash.cleanDialog.description')}
        confirmText={t('procedure.trash.cleanDialog.confirm')}
        cancelText={t('procedure.trash.cleanDialog.cancel')}
      />
      <ConfirmationDialog
        open={recycleProcedureConfirmDialog}
        onCancel={() => setRecycleProcedureConfirmDialog(false)}
        onConfirm={recycleProcedureInTrash}
        title={t('procedure.trash.restoreDialog.title')}
        description={t('procedure.trash.restoreDialog.description')}
        confirmText={t('procedure.trash.restoreDialog.confirm')}
        cancelText={t('procedure.trash.restoreDialog.cancel')}
      />
    </>
  );

  const footer = (
    <Stack
      className={classes.dossierEditionActions}
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      style={{ width: '100%', marginTop: theme.spacing(isSidebar ? 0 : 5) }}
    >
      <Button
        className={classes.dossierEditionActionsCancel}
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={() => {
          if (isSidebar) {
            closeSideBar();
          } else {
            navigate(-1);
          }
        }}
      >
        {t('common.back')}
      </Button>

      <Button
        className={classes.dossierEditionActionsSave}
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setCleanTrashConfirmDialog(true)}
      >
        {t('procedure.trash.clean')}
      </Button>
    </Stack>
  );

  return !isSidebar ? (
    <Container maxWidth="md" style={{ padding: theme.spacing(2) }}>
      {header}
      {body}
      {footer}
    </Container>
  ) : (
    <Sidebar
      justifyStart
      header={header}
      body={body}
      footer={footer}
      isOpen={isOpen}
      closeSideBar={() => {
        closeSideBar();
      }}
    />
  );
};

export default ProcedureTrashCanDialog;
