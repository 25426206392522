import React from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
} from 'react-hook-form';
import {
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { DeleteRounded, Edit } from '@mui/icons-material';

import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
} from '../../../../types/prejudice.type';
import { NormalTable } from '../../../styled';
import { prettyString } from '../../../../constants/regimes';
import { ReadFieldForm } from '../../../forms/ReadFieldForm';
import { fCurrency } from '../../../../helpers/formatNumber';
import { ComputedPropsForm } from '../../../forms/ComputedPropsForm';
import { CalculsFormPerteGainsProfessionnelsActuel } from '../../../../constants/calculs';
import { Procedure } from '../../../../types/procedure.type';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { getTropPercuString } from 'src/helpers/prejudices/pgpfReliquat';
import { PerteGainProfessionnelsTotauxSituations } from 'src/constants/calculs/type';
import { useTranslation } from 'react-i18next';
import { fPartResponsabilite } from 'src/helpers/formatValues';

interface Props {
  procedure: Procedure;
  control: Control<
    | OldPrejudiceFormPerteGainProfessionnelsActuel
    | OldPrejudiceFormPerteGainProfessionnelsFuturs
  >;
  fields: FieldArrayWithId<
    | OldPrejudiceFormPerteGainProfessionnelsActuel
    | OldPrejudiceFormPerteGainProfessionnelsFuturs,
    'situations'
  >[];
  isPGPF: boolean;
  remove: UseFieldArrayRemove;
  onEdit: (index: number) => void;
}

export const TableSituations: React.FC<Props> = ({
  procedure,
  control,
  fields,
  isPGPF,
  remove,
  onEdit,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            <TableRow>
              <TableCell>
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.regime.columnHeader',
                )}
              </TableCell>
              <TableCell align="right">
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.totalPerteDeGain.columnHeader',
                )}
              </TableCell>
              <TableCell align="right">
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.totalIndemnitesNet.columnHeader',
                )}
              </TableCell>
              {!isPGPF && (
                <TableCell align="right">
                  {t(
                    'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tableSituations.totalIndemnitesCsgCrds.columnHeader',
                  )}
                </TableCell>
              )}
              <TableCell align="right">
                {t(
                  'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.totalArreragesVictime.columnHeader',
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>{prettyString[field.regime]}</TableCell>
                <TableCell align="right">
                  <ComputedReadFieldForm
                    control={control}
                    watchFields={[
                      `situations.${index}.revenuTheorique`,
                      `situations.${index}.revenusReelsNet`,
                    ]}
                    compute={([revenuTheorique, revenusReelsNet]) =>
                      CalculsFormPerteGainsProfessionnelsActuel.getPerteDeGainProfessionnel(
                        {
                          revenuTheorique,
                          revenusReelsNet,
                        },
                      )
                    }
                    name={`situations.${index}.totalPerteDeGain`}
                    render={(value) => <>{fCurrency(value || 0)}</>}
                  />
                </TableCell>
                <TableCell align="right">
                  <ReadFieldForm
                    control={control}
                    name={`situations.${index}.totalIndemnitesNet`}
                    render={(value) => <>{fCurrency(value)}</>}
                  />
                </TableCell>
                {!isPGPF && (
                  <TableCell align="right">
                    <ReadFieldForm
                      control={control}
                      name={`situations.${index}.totalIndemnitesCsgCrds`}
                      render={(value) => <>{fCurrency(value)}</>}
                    />
                  </TableCell>
                )}
                <TableCell align="right">
                  <ComputedReadFieldForm
                    name={`situations.${index}.arrerageVictime`}
                    control={control}
                    watchFields={[
                      `situations.${index}.revenuTheorique`,
                      `situations.${index}.revenusReelsNet`,
                      `situations.${index}.totalIndemnitesNet`,
                    ]}
                    compute={([
                      revenuTheorique,
                      revenusReelsNet,
                      totalIndemnitesNet,
                    ]) => {
                      return CalculsFormPerteGainsProfessionnelsActuel.getArreragesVictime(
                        revenuTheorique,
                        revenusReelsNet,
                        totalIndemnitesNet,
                      );
                    }}
                    render={(value) => <>{fCurrency(value)}</>}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label={`modifier ligne ${index + 1}`}
                    onClick={() => onEdit(index)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label={`supprimer ligne ${index + 1}`}
                    onClick={() => remove(index)}
                  >
                    <DeleteRounded />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <ComputedPropsForm
              control={control}
              watchFields={['situations']}
              compute={([situations]): {
                props: {
                  totaux: PerteGainProfessionnelsTotauxSituations;
                };
              } => {
                if (!situations) {
                  return {
                    props: {
                      totaux: {
                        revenuTheorique: { value: 0, partResponsabilite: 0 },
                        revenuReel: { value: 0, partResponsabilite: 0 },
                        revenuDeReference: { value: 0, partResponsabilite: 0 },
                        perteDeGain: { value: 0, partResponsabilite: 0 },
                        arreragesVictime: { value: 0, partResponsabilite: 0 },
                        indemniteNet: { value: 0, partResponsabilite: 0 },
                        indemniteCsgCrds: { value: 0, partResponsabilite: 0 },
                      },
                    },
                  };
                }
                const totaux =
                  CalculsFormPerteGainsProfessionnelsActuel.getTotauxSituations(
                    situations,
                    procedure.partResponsabilite,
                  );
                return {
                  props: {
                    totaux,
                  },
                };
              }}
              render={({ totaux: untypedTotaux }) => {
                const totaux =
                  untypedTotaux as PerteGainProfessionnelsTotauxSituations;

                return (
                  <>
                    <TableRow>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        Total:
                      </TableCell>
                      <TableCell align="right">
                        {fCurrency(totaux.perteDeGain.value)}
                      </TableCell>
                      <TableCell align="right">
                        {fCurrency(totaux.indemniteNet.value)}
                      </TableCell>
                      {!isPGPF && (
                        <TableCell align="right">
                          {fCurrency(totaux.indemniteCsgCrds.value)}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        {getTropPercuString(totaux.arreragesVictime.value)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {t('partResponsabilite.tableRowHeader', {
                          partResponsabilite: fPartResponsabilite(
                            procedure.partResponsabilite * 100,
                          ),
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {fCurrency(totaux.perteDeGain.partResponsabilite)}
                      </TableCell>
                      <TableCell />
                      {!isPGPF && (
                        <TableCell align="right">
                          {fCurrency(
                            totaux.indemniteCsgCrds.partResponsabilite,
                          )}
                        </TableCell>
                      )}
                      <TableCell />
                    </TableRow>
                    <ComputedReadFieldForm
                      control={control}
                      name="totalRevenuDeReference"
                      watchFields={[]}
                      customValues={[totaux.revenuDeReference.value]}
                      compute={(_, customValues) => customValues?.[0] || 0}
                    />
                  </>
                );
              }}
            />
            <ComputedReadFieldForm
              control={control}
              name="totalPerteDeGainAnnualise"
              watchFields={['dateDebut', 'dateFin', 'situations']}
              compute={([dateDebut, dateFin, situations]) => {
                if (!dateDebut || !dateFin) {
                  return 0;
                }
                return (
                  CalculsFormPerteGainsProfessionnelsActuel.getPerteDeGainAnnualise(
                    {
                      dateDebut,
                      dateFin,
                      situations,
                    },
                  ) || 0
                );
              }}
            />
          </TableFooter>
        </NormalTable>
      </TableContainer>
    </>
  );
};
