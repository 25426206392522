import { Bareme, BaremeIndemnisationValues } from 'src/types/bareme.type';
import { PrejudiceFormBase, PrejudiceType } from 'src/types/prejudice.type';

export const prejudiceBaseDefaultValues = ({
  values,
}: {
  prejudiceType: PrejudiceType;
  values: PrejudiceFormBase | undefined;
}): PrejudiceFormBase => ({
  notes: values?.notes || '',
  chiffrage: values?.chiffrage || 'en_cours',
  formVersion: values?.formVersion || 0,
  editedFields: values?.editedFields || [],
});
export type BaremeDefaultMontant = Bareme & {
  values: BaremeIndemnisationValues[];
};
export const getDefaultMontant = (
  bareme?: BaremeDefaultMontant,
  degree?: number | null,
  baremeValueChoice?: string,
) => {
  if (!bareme || !degree || !baremeValueChoice || !bareme.values[degree]) {
    return 0;
  }

  const getValueKey = () => {
    switch (baremeValueChoice) {
      case 'minimum':
        return 'fromValue';
      case 'average':
        return 'average';
      case 'maximum':
        return 'toValue';
      default:
        return null;
    }
  };

  const valueKey = getValueKey();
  if (valueKey === null) {
    return null;
  }
  switch (bareme.source) {
    case 'Mornet': {
      if (valueKey === 'average') {
        const fromValue = bareme.values[degree]?.fromValue || 0;
        const toValue = bareme.values[degree]?.toValue || 0;
        return (fromValue + toValue) / 2;
      }
      return bareme.values[degree]?.[valueKey] || null;
    }
    case 'FGTI':
      return bareme.values[degree]?.value || null;
    case 'ONIAM':
      return bareme.values[degree]?.[valueKey] || null;
    default:
      return null;
  }
};
