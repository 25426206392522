import { PerteGainProfessionnelsActuelSituation } from 'src/types/prejudice.type';
import i18n from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { CalculsFormPerteGainsProfessionnelsActuel } from 'src/constants/calculs';
import { simpleHorizontalTablePrint } from 'src/helpers/print/simpleHorizontalTablePrint';
import i18next from 'i18next';
import { getTropPercuString } from 'src/helpers/prejudices/pgpfReliquat';
import { fPartResponsabilite } from 'src/helpers/formatValues';
const situationRow = (
  isPGPF: boolean,
  situation: PerteGainProfessionnelsActuelSituation,
) => ({
  rowLabel: i18n.t(
    `prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.REGIME.title.${situation.regime}`,
  ),
  columns: [
    fCurrency(situation.totalPerteDeGain),
    fCurrency(situation.totalIndemnitesNet),
    ...(!isPGPF ? [fCurrency(situation.totalIndemnitesCsgCrds)] : []),
    fCurrency(situation.arrerageVictime),
  ],
});

export const tableSituationPrint = (
  situations: PerteGainProfessionnelsActuelSituation[],
  totaux: ReturnType<
    typeof CalculsFormPerteGainsProfessionnelsActuel.getTotauxSituations
  >,
  partResponsabilite: number,
  isPGPF: boolean,
) =>
  simpleHorizontalTablePrint(
    [
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.print.regime.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.print.totalPerteDeGain.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.print.totalIndemnitesNet.columnHeader',
      ),
      ...(!isPGPF
        ? [
            i18next.t(
              'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_FUTURS.tableSituations.print.totalIndemnitesCsgCrds.columnHeader',
            ),
          ]
        : []),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.print.totalArreragesVictime.columnHeader',
      ),
    ],
    [
      ...situations.map((situation) => situationRow(isPGPF, situation)),
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.print.totalRow.tableRowHeader',
        ),
        columns: [
          ...[
            totaux.perteDeGain.value,
            totaux.indemniteNet.value,
            ...(!isPGPF ? [totaux.indemniteCsgCrds.value] : []),
          ].map((value) => (value || value === 0 ? fCurrency(value) : '')),
          getTropPercuString(totaux.arreragesVictime.value),
        ],
      },
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.tableSituations.print.partResponsabiliteRow.tableRowHeader',
          {
            partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
          },
        ),
        columns: [
          totaux.perteDeGain.partResponsabilite,
          null,
          ...(!isPGPF ? [totaux.indemniteCsgCrds.partResponsabilite] : []),
          null,
        ].map((value) => (value || value === 0 ? fCurrency(value) : '')),
      },
    ],
  );
