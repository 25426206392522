import React from 'react';
import { Control } from 'react-hook-form';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import { CalculsFormCalendrierDepense } from '../../../constants/calculs';
import {
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseCapitalisation,
} from '../../../types/prejudice.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { IndemniteRepartieEchusIndirecte } from 'src/constants/calculs/type';
import { TotalIndemniteGlobaleARepartirEchusIndirecte } from './TotalIndemniteGlobaleARepartirEchusIndirecte';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { Procedure } from 'src/types/procedure.type';

interface Props {
  control:
    | Control<PrejudiceFormCalendrierDepense>
    | Control<PrejudiceFormCalendrierDepenseCapitalisation>;
  tiersPayeurs: string[];
  monetaryErosions: MonetaryErosion[];
  dateLiquidation: Date | undefined;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
}

export const TotalDepensesEchuesIndirecte: React.FC<Props> = ({
  control,
  monetaryErosions,
  dateLiquidation,
  tiersPayeurs,
  victimesIndirectes,
  procedure,
}) => {
  return (
    <ComputedPropsForm
      control={control as Control<PrejudiceFormCalendrierDepense>}
      watchFields={[
        'rows',
        'revalorisationCoefficientsType',
        'partResponsabilite',
      ]}
      compute={([rows, revalorisationCoefficientsType, partResponsabilite]): {
        props: { indemniteRepartie: IndemniteRepartieEchusIndirecte };
      } => {
        const indemniteRepartie =
          CalculsFormCalendrierDepense.getIndemniteRepartieEchusIndirecte({
            rows,
            partResponsabilite:
              partResponsabilite || partResponsabilite === 0
                ? partResponsabilite / 100
                : procedure.partResponsabilite,
            tiersPayeurs,
            dateLiquidation,
            monetaryErosions,
            revalorisationCoefficientsType,
          });
        return {
          props: {
            indemniteRepartie,
          },
        };
      }}
      render={(props) => {
        const { indemniteRepartie } = props as {
          indemniteRepartie: IndemniteRepartieEchusIndirecte;
        };
        return (
          <TotalIndemniteGlobaleARepartirEchusIndirecte
            indemniteGlobaleARepartir={indemniteRepartie}
            victimesIndirectes={victimesIndirectes}
            partResponsabilite={procedure.partResponsabilite}
          />
        );
      }}
    />
  );
};
