import { floor, max } from 'lodash';
import { getMontantRevalorise } from 'src/helpers/prejudices/revalorisation';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  AssistanceUniteNombreHeure,
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormCalendrierAssistanceRow,
} from 'src/types/prejudice.type';
import { Time } from '../time';
import { CalculsGlobal } from './calculsGlobal';
import {
  IndemniteRepartieAEchoir,
  IndemniteRepartieTiersPayeurs,
  ValueAndPartResponsabilite,
  ValueEchuAndAEchoir,
} from './type';
import { getShouldNotDisplayCapitalisation } from 'src/helpers/prejudices/capitalisation';

export abstract class CalculsFormCalendrierAssistance {
  static totalJours({
    joursRetenus,
    joursConges,
  }: {
    joursRetenus: number;
    joursConges: number;
  }): number {
    return floor(
      joursRetenus * (joursConges !== 365 ? joursConges / Time.daysInYear : 1),
      2,
    );
  }

  static quantite({
    totalJours,
    nombreHeureParUniteChoisie,
    uniteNombreHeure,
  }: {
    totalJours: number;
    nombreHeureParUniteChoisie: number;
    uniteNombreHeure: AssistanceUniteNombreHeure;
  }) {
    const divisor = this.getNombreHeureParJourDivisor({
      uniteNombreHeure,
    });
    return nombreHeureParUniteChoisie * (totalJours / divisor);
  }

  static totaux({
    rows,
    partResponsabilite,
    tiersPayeurs,
    dateLiquidation,
    monetaryErosions,
    withDeductionFiscale,
    revalorisationCoefficientsType,
  }: {
    rows: Pick<
      PrejudiceFormCalendrierAssistanceRow,
      | 'montantTotal'
      | 'deductionFiscale'
      | 'dateJustificatif'
      | 'resteACharge'
      | 'montantsDejaRevalorises'
      | 'priseEnChargeTiersPayeurs'
      | 'uniteNombreHeure'
      | 'quantite'
      | 'type'
      | 'dateFin'
      | 'annualiseMontantTotal'
    >[];
    partResponsabilite: number;
    tiersPayeurs: string[];
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
    withDeductionFiscale?: boolean;
  } & Pick<
    PrejudiceFormCalendrierAssistance,
    'revalorisationCoefficientsType'
  >): {
    montantTotal: ValueEchuAndAEchoir<ValueAndPartResponsabilite>;
    resteACharge: ValueEchuAndAEchoir<ValueAndPartResponsabilite>;
    montantTotalAnnualise: ValueAndPartResponsabilite;
    priseEnChargeTiersPayeurs: Record<
      string,
      ValueEchuAndAEchoir<ValueAndPartResponsabilite>
    >;
    totalQuantite: number;
  } {
    const totaux: {
      montantTotal: ValueEchuAndAEchoir<number>;
      resteACharge: ValueEchuAndAEchoir<number>;
      montantTotalAnnualise: number;
      priseEnChargeTiersPayeurs: Record<string, ValueEchuAndAEchoir<number>>;
      totalQuantite: number;
    } = rows.reduce(
      (
        totaux: {
          montantTotal: ValueEchuAndAEchoir<number>;
          resteACharge: ValueEchuAndAEchoir<number>;
          montantTotalAnnualise: number;
          priseEnChargeTiersPayeurs: Record<
            string,
            ValueEchuAndAEchoir<number>
          >;
          totalQuantite: number;
        },
        row,
      ) => {
        const resteACharge =
          row.montantsDejaRevalorises === false &&
          monetaryErosions &&
          row.dateJustificatif &&
          dateLiquidation
            ? getMontantRevalorise({
                montant: row.resteACharge,
                monetaryErosions,
                anneeOrDateMontant: row.dateJustificatif,
                anneeOrDateLiquidation: dateLiquidation,
                coefficientsType: revalorisationCoefficientsType,
              })
            : row.resteACharge;
        const montantTotalAnnualise =
          row.annualiseMontantTotal !== undefined &&
          row.montantsDejaRevalorises === false &&
          monetaryErosions &&
          row.dateJustificatif &&
          dateLiquidation
            ? getMontantRevalorise({
                montant: row.annualiseMontantTotal,
                monetaryErosions,
                anneeOrDateMontant: row.dateJustificatif,
                anneeOrDateLiquidation: dateLiquidation,
                coefficientsType: revalorisationCoefficientsType,
              })
            : row.annualiseMontantTotal;
        const montantTotal =
          totaux.montantTotal.aEchoir +
          Number(row.montantTotal) -
          (withDeductionFiscale ? Number(row.deductionFiscale) : 0);
        const getUpdatedTotauxTiersPayeurs = (
          priseEnChargeTiersPayeurs: Record<
            string,
            ValueEchuAndAEchoir<number>
          >,
          row: Pick<
            PrejudiceFormCalendrierAssistanceRow,
            'priseEnChargeTiersPayeurs'
          >,
          isEchus: boolean,
        ) =>
          tiersPayeurs.reduce(
            (acc, tierPayeur) => {
              const updatedValue =
                Number(
                  row.priseEnChargeTiersPayeurs?.find(
                    (priseEnChargeTiersPayeur) =>
                      priseEnChargeTiersPayeur.tiersPayeur === tierPayeur,
                  )?.montant,
                ) || 0;
              const echus =
                (priseEnChargeTiersPayeurs[tierPayeur]?.echus || 0) +
                (isEchus ? updatedValue : 0);
              const aEchoir =
                (priseEnChargeTiersPayeurs[tierPayeur]?.aEchoir || 0) +
                (!isEchus ? updatedValue : 0);
              const total =
                (priseEnChargeTiersPayeurs[tierPayeur]?.total || 0) +
                updatedValue;
              return {
                ...acc,
                [tierPayeur]: {
                  echus,
                  aEchoir,
                  total,
                },
              };
            },
            {} as Record<string, ValueEchuAndAEchoir<number>>,
          );
        const totalQuantite =
          totaux.totalQuantite + (Number(row.quantite) || 0);

        if (row.type === 'aEchoir' && !row.dateFin) {
          return {
            ...totaux,
            totalQuantite,
            montantTotalAnnualise:
              totaux.montantTotalAnnualise + (montantTotalAnnualise || 0),
          };
        } else if (row.type === 'aEchoir') {
          return {
            ...totaux,
            montantTotal: {
              ...totaux.montantTotal,
              aEchoir: totaux.montantTotal.aEchoir + montantTotal,
              total: totaux.montantTotal.total + montantTotal,
            },
            resteACharge: {
              ...totaux.resteACharge,
              aEchoir: totaux.resteACharge.aEchoir + Number(resteACharge),
              total: totaux.resteACharge.total + Number(resteACharge),
            },
            priseEnChargeTiersPayeurs: getUpdatedTotauxTiersPayeurs(
              totaux.priseEnChargeTiersPayeurs,
              row,
              false,
            ),
            totalQuantite,
          };
        } else {
          return {
            ...totaux,
            montantTotal: {
              ...totaux.montantTotal,
              echus: totaux.montantTotal.echus + montantTotal,
              total: totaux.montantTotal.total + montantTotal,
            },
            resteACharge: {
              ...totaux.resteACharge,
              echus: totaux.resteACharge.echus + (resteACharge || 0),
              total: totaux.resteACharge.total + (resteACharge || 0),
            },
            priseEnChargeTiersPayeurs: getUpdatedTotauxTiersPayeurs(
              totaux.priseEnChargeTiersPayeurs,
              row,
              true,
            ),
            totalQuantite,
          };
        }
      },
      {
        priseEnChargeTiersPayeurs: tiersPayeurs.reduce(
          (acc, tierPayeur) => ({
            ...acc,
            [tierPayeur]: { echus: 0, aEchoir: 0, total: 0 },
          }),
          {},
        ),
        montantTotal: { echus: 0, aEchoir: 0, total: 0 },
        resteACharge: { echus: 0, aEchoir: 0, total: 0 },
        montantTotalAnnualise: 0,
        totalQuantite: 0,
      },
    );
    const convertToValueAndPartResponsabilite = (
      value: ValueEchuAndAEchoir<number>,
    ): ValueEchuAndAEchoir<ValueAndPartResponsabilite> => ({
      echus: {
        value: value.echus,
        partResponsabilite: value.echus * partResponsabilite,
      },
      aEchoir: {
        value: value.aEchoir,
        partResponsabilite: value.aEchoir * partResponsabilite,
      },
      total: {
        value: value.total,
        partResponsabilite: value.total * partResponsabilite,
      },
    });
    return {
      montantTotal: convertToValueAndPartResponsabilite(totaux.montantTotal),
      resteACharge: convertToValueAndPartResponsabilite(totaux.resteACharge),
      montantTotalAnnualise: {
        value: totaux.montantTotalAnnualise,
        partResponsabilite: totaux.montantTotalAnnualise * partResponsabilite,
      },
      priseEnChargeTiersPayeurs: Object.entries(
        totaux.priseEnChargeTiersPayeurs,
      ).reduce(
        (accumulator, [tiersPayeur, value]) => ({
          ...accumulator,
          [tiersPayeur]: convertToValueAndPartResponsabilite(value),
        }),
        {},
      ),
      totalQuantite: totaux.totalQuantite,
    };
  }

  static getNombreHeureParJourDivisor({
    uniteNombreHeure,
  }: Pick<PrejudiceFormCalendrierAssistanceRow, 'uniteNombreHeure'>) {
    return uniteNombreHeure === 'jour'
      ? 1
      : uniteNombreHeure === 'semaine'
        ? Time.daysInWeek
        : Time.nonPreciseDaysInMonth;
  }

  static annualiseMontantTotal({
    forfaitHoraire,
    joursConges,
    nombreHeureParUniteChoisie,
    uniteNombreHeure,
  }: Pick<
    PrejudiceFormCalendrierAssistanceRow,
    | 'forfaitHoraire'
    | 'joursConges'
    | 'nombreHeureParUniteChoisie'
    | 'uniteNombreHeure'
  >) {
    const nombreHeureParJourDivisor = this.getNombreHeureParJourDivisor({
      uniteNombreHeure,
    });

    return (
      (forfaitHoraire * nombreHeureParUniteChoisie * joursConges) /
      nombreHeureParJourDivisor
    );
  }

  static resteACharge({
    montantTotal,
    deductionFiscale,
    priseEnChargeTiersPayeurs,
    autresDeductions,
  }: Pick<
    PrejudiceFormCalendrierAssistanceRow,
    | 'montantTotal'
    | 'deductionFiscale'
    | 'priseEnChargeTiersPayeurs'
    | 'autresDeductions'
  >) {
    const result =
      (montantTotal || 0) -
      ((deductionFiscale || 0) +
        (CalculsGlobal.sum(
          priseEnChargeTiersPayeurs.map(({ montant }) => montant || 0),
        ) || 0) +
        (autresDeductions || 0));
    return max([result, 0]) || 0;
  }

  static montantParPeriode({
    annualiseMontant,
    totalJours,
    joursConges,
  }: Pick<
    PrejudiceFormCalendrierAssistanceRow,
    'totalJours' | 'joursConges'
  > & {
    annualiseMontant: number | undefined;
  }) {
    return ((annualiseMontant || 0) * totalJours) / (joursConges || 1);
  }

  static montantTotal({
    forfaitHoraire,
    joursConges,
    nombreHeureParUniteChoisie,
    uniteNombreHeure,
    totalJours,
  }: Pick<
    PrejudiceFormCalendrierAssistanceRow,
    | 'forfaitHoraire'
    | 'joursConges'
    | 'nombreHeureParUniteChoisie'
    | 'uniteNombreHeure'
    | 'totalJours'
  >) {
    const nombreHeureParJourDivisor = this.getNombreHeureParJourDivisor({
      uniteNombreHeure,
    });

    const montantAnnualise =
      (forfaitHoraire * nombreHeureParUniteChoisie * joursConges) /
      nombreHeureParJourDivisor;
    return this.montantParPeriode({
      annualiseMontant: montantAnnualise,
      totalJours,
      joursConges,
    });
  }

  static getAssistanceIndemnitesRepartie({
    rows,
    victimeSommeACapitaliser,
    victimeCoefficientCapitalisation,
    capitalisationTiersPayeurs,
    tiersPayeursTotalCapitalise,
    partResponsabilite,
    tiersPayeurs,
    dateLiquidation,
    monetaryErosions,
    revalorisationCoefficientsType,
    dateConsolidation,
    dateDeces,
  }: Pick<
    PrejudiceFormCalendrierAssistanceCapitalisation,
    | 'victimeCoefficientCapitalisation'
    | 'victimeSommeACapitaliser'
    | 'tiersPayeursTotalCapitalise'
    | 'capitalisationTiersPayeurs'
    | 'revalorisationCoefficientsType'
  > & {
    rows: Parameters<typeof CalculsFormCalendrierAssistance.totaux>[0]['rows'];
    partResponsabilite: number;
    tiersPayeurs: string[];
    dateLiquidation: Date | undefined;
    monetaryErosions: MonetaryErosion[];
    dateConsolidation: Date | undefined;
    dateDeces: Date | undefined;
  }): IndemniteRepartieAEchoir {
    const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
      dateConsolidation,
      dateLiquidation,
      dateDeces,
    });
    const totaux = CalculsFormCalendrierAssistance.totaux({
      rows,
      partResponsabilite,
      tiersPayeurs,
      dateLiquidation,
      monetaryErosions,
      withDeductionFiscale: true,
      revalorisationCoefficientsType,
    });

    const victimeTotalCapitalise = shouldNotDisplayCapitalisation
      ? 0
      : victimeSommeACapitaliser * (victimeCoefficientCapitalisation || 0);

    const totalTiersPayeursEchus = CalculsGlobal.sum(
      Object.values(totaux.priseEnChargeTiersPayeurs ?? {}).map(
        ({ echus: { value } }) => value,
      ),
    );

    const totalTiersPayeursRowsAEchoir = CalculsGlobal.sum(
      Object.values(totaux.priseEnChargeTiersPayeurs ?? {}).map(
        ({ aEchoir: { value } }) => value,
      ),
    );
    const totalTiersPayeursAEchoir =
      totalTiersPayeursRowsAEchoir +
      (shouldNotDisplayCapitalisation ? 0 : tiersPayeursTotalCapitalise || 0);
    const indemniteGlobaleBeforePartResponsabilite =
      totaux.resteACharge.total.value +
      totalTiersPayeursEchus +
      totalTiersPayeursRowsAEchoir +
      victimeTotalCapitalise;
    const indemniteGlobale =
      totaux.resteACharge.total.partResponsabilite +
      totalTiersPayeursEchus * partResponsabilite +
      totalTiersPayeursRowsAEchoir * partResponsabilite +
      victimeTotalCapitalise * partResponsabilite;

    /* Préférence victime */
    const arreragesEchusVictimeDebit = CalculsGlobal.min([
      totaux.resteACharge.echus.value,
      indemniteGlobale,
    ]);
    const arreragesEchusVictimeSolde =
      indemniteGlobale - arreragesEchusVictimeDebit;

    const arreragesAEchoirVictimeDebit = CalculsGlobal.min([
      victimeTotalCapitalise -
        (shouldNotDisplayCapitalisation ? 0 : tiersPayeursTotalCapitalise) +
        totaux.resteACharge.aEchoir.value,
      arreragesEchusVictimeSolde,
    ]);

    const arreragesAEchoirVictimeSolde =
      arreragesEchusVictimeSolde - arreragesAEchoirVictimeDebit;

    /* Rente tiers payeurs */
    const arreragesEchusTotalTiersPayeursDebit = CalculsGlobal.min([
      totalTiersPayeursEchus,
      arreragesAEchoirVictimeSolde,
    ]);
    const arreragesEchusTotalTiersPayeursSolde =
      arreragesAEchoirVictimeSolde - arreragesEchusTotalTiersPayeursDebit;

    const arreragesEchusTiersPayeursDebit: IndemniteRepartieTiersPayeurs =
      tiersPayeurs.map((tiersPayeur) => {
        const montantNonReparti =
          totaux.priseEnChargeTiersPayeurs[tiersPayeur]?.echus.value || 0;
        return {
          tiersPayeur,
          montant:
            (montantNonReparti * arreragesEchusTotalTiersPayeursDebit) /
            (totalTiersPayeursEchus || 1),
          montantNonReparti,
        };
      }, {});

    const arreragesAEchoirTotalTiersPayeursDebit = CalculsGlobal.min([
      totalTiersPayeursAEchoir,
      arreragesEchusTotalTiersPayeursSolde,
    ]);

    const arreragesAEchoirTiersPayeursDebit: IndemniteRepartieTiersPayeurs =
      tiersPayeurs.map((tiersPayeur) => {
        const montantCapitalise = shouldNotDisplayCapitalisation
          ? 0
          : capitalisationTiersPayeurs.parTiersPayeur.find(
              (capitalisation) => capitalisation.tiersPayeur === tiersPayeur,
            )?.montantCapitalise || 0;
        const montantNonReparti =
          (montantCapitalise || 0) +
          (totaux.priseEnChargeTiersPayeurs[tiersPayeur]?.aEchoir.value || 0);
        return {
          tiersPayeur,
          montant:
            (montantNonReparti * arreragesAEchoirTotalTiersPayeursDebit) /
            (totalTiersPayeursAEchoir || 1),
          montantNonReparti,
        };
      });

    return {
      indemniteGlobaleARepartir: {
        solde: indemniteGlobale,
        beforePartResponsabilite: indemniteGlobaleBeforePartResponsabilite,
      },
      indemniteVictime: {
        arreragesEchus: {
          solde: arreragesEchusVictimeSolde,
          debit: arreragesEchusVictimeDebit,
        },
        arreragesAEchoir: {
          solde: arreragesAEchoirVictimeSolde,
          debit: arreragesAEchoirVictimeDebit,
        },
        total: arreragesEchusVictimeDebit + arreragesAEchoirVictimeDebit,
      },
      indemniteTiersPayeurs: {
        arreragesEchus: {
          solde: arreragesEchusTotalTiersPayeursSolde,
          debit: arreragesEchusTotalTiersPayeursDebit,
          parTiersPayeur: arreragesEchusTiersPayeursDebit,
          totalNonReparti: totalTiersPayeursEchus,
        },
        arreragesAEchoir: {
          solde: 0,
          debit: arreragesAEchoirTotalTiersPayeursDebit,
          parTiersPayeur: arreragesAEchoirTiersPayeursDebit,
          totalNonReparti: totalTiersPayeursAEchoir,
        },
        total:
          arreragesEchusTotalTiersPayeursDebit +
          arreragesAEchoirTotalTiersPayeursDebit,
      },
    };
  }
}
