import { User, UserJobType } from 'src/types/auth.type';
import { apiRequest } from './api';
import { Subscription } from 'src/types/subscription.type';
import { differenceInMonths } from 'date-fns';
import { Organization, OrganizationType } from 'src/types/organization.type';

const automaticSubscriptionJobTypes: UserJobType[] = [
  UserJobType.avocat,
  UserJobType.juristeCabinetAvocat,
];
const automaticSubscriptionOrganizationTypes: Organization['type'][] = [
  OrganizationType.CABINET_AVOCAT,
];
export const isAutomaticSubscriptionAvailable = (user: User) => {
  return Boolean(
    !!user.jobType && automaticSubscriptionJobTypes.includes(user.jobType),
  );
};

export const isAutomaticSubscriptionAvailableForOrganization = (
  organization: Pick<Organization, 'subscription' | 'type'>,
) => {
  return Boolean(
    !!organization.type &&
      automaticSubscriptionOrganizationTypes.includes(organization.type),
  );
};

export const isSubscriptionQuoteAvailableForOrganization = (
  organization: Pick<Organization, 'subscription' | 'type'>,
) => {
  return Boolean(
    !!organization.type &&
      (!automaticSubscriptionOrganizationTypes.includes(organization.type) ||
        (automaticSubscriptionOrganizationTypes.includes(organization.type) &&
          !organization.subscription.isAutomaticSubscriptionPaymentActive)),
  );
};

export const createSubscriptionMandate = async (
  organizationId: string,
): Promise<string> => {
  const result = await apiRequest<{ url: string }>(
    'POST',
    `/organization/${organizationId}/subscription/create-checkout-session`,
  );

  return result.url;
};

export const isSubscriptionValid = (subscription?: Subscription) =>
  Boolean(
    !!subscription &&
      (subscription.isPaying ||
        subscription.isFreeSubscription ||
        subscription.isFreeTrial ||
        subscription.isAutomaticSubscriptionPaymentActive),
  );
export const canCancelSubscriptionAsAdmin = (subscription?: Subscription) =>
  Boolean(
    !!subscription &&
      subscription.isAutomaticSubscriptionPaymentActive &&
      !subscription.cancelAtPeriodEnd &&
      subscription.subscriptionPeriodEndDate &&
      differenceInMonths(
        new Date(subscription.subscriptionPeriodEndDate),
        new Date(),
      ) >= 2,
  );

export const isSubscriptionCancellationAvailable = (
  subscription?: Subscription,
) => {
  if (!subscription || !subscription.isAutomaticSubscriptionPaymentActive) {
    return false;
  }

  return Boolean(
    !!subscription &&
      !subscription.cancelAtPeriodEnd &&
      (subscription.isFreeTrial ||
        (subscription.subscriptionPeriodEndDate &&
          differenceInMonths(
            new Date(subscription.subscriptionPeriodEndDate),
            new Date(),
          ) >= 2)),
  );
};

export const isOrganizationSubscriptionValid = (
  subscription: Organization['subscription'],
) =>
  Boolean(
    subscription.isPaying ||
      subscription.isFreeSubscription ||
      subscription.isAutomaticSubscriptionPaymentActive,
  );
