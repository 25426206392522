import i18next from 'i18next';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { PrejudiceType } from 'src/types/prejudice.type';
import { PopulatedProcedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { CellObject, WorkBook } from 'xlsx';
import { getPGPFReliquatAndPGPFReliquatActivationStatus } from '../prejudices/pgpfReliquat';
import {
  getPrejudicesTotal,
  getPrejudiceTotal,
  PrejudiceTotalValue,
} from '../prejudices/total';
import {
  createWorkbook,
  createWorkSheet,
  toCellObject,
  WorkSheetWithName,
} from './xlsxUtils';

type DataType = 'tiersPayeurs' | 'victime' | 'proche' | 'total' | 'rentes';

export const makeProceduresExport = ({
  allPopulatedProcedure,
  victime,
  monetaryErosions,
}: {
  allPopulatedProcedure: PopulatedProcedure[];
  victime: Victime;
  monetaryErosions: MonetaryErosion[];
}): WorkBook => {
  const allPrejudicesSet: Set<string> = new Set();
  allPopulatedProcedure.forEach((procedure) => {
    procedure.prejudices.forEach((prejudice) => {
      allPrejudicesSet.add(prejudice.type);
    });
  });

  const allPrejudices = Array.from(allPrejudicesSet);

  let totalAllProcedures: number[][][] = [];

  const createData = (dataType: DataType) => {
    const data: CellObject[][] = [
      [toCellObject(victime.referenceDossierVictime)],
    ];
    let totalProcedure: number[][] = [];
    const headerRow: CellObject[] = [toCellObject('')];
    allPopulatedProcedure.forEach((procedure) => {
      headerRow.push(toCellObject(procedure.intitule));
    });
    data.push(headerRow);

    allPrejudices.forEach((prejudiceType, numRow) => {
      const row: CellObject[] = [];
      row.push(
        toCellObject(
          i18next.t(`prejudice.prejudicesTypes.${prejudiceType}.title`),
        ),
      );
      let totalPrejudice: number[] = [];
      allPopulatedProcedure.forEach((procedure, numCol) => {
        const prejudice = procedure.prejudices.find(
          (p) => p.type === prejudiceType,
        );
        const prejudices = procedure.prejudices;

        const PGPFReliquat =
          getPGPFReliquatAndPGPFReliquatActivationStatus(prejudices);
        const prejudicesTotalValues: Partial<
          Record<PrejudiceType, PrejudiceTotalValue[]>
        > = prejudices.reduce(
          (totalPerPrejudice, prejudice) => ({
            ...totalPerPrejudice,
            [prejudice.type]: getPrejudiceTotal({
              victime,
              victimesIndirectes: procedure.victimesIndirectes,
              procedure,
              prejudice,
              dateLiquidation: procedure.dateLiquidation
                ? new Date(procedure.dateLiquidation)
                : undefined,
              monetaryErosions,
              PGPFReliquat,
              dateConsolidation: procedure.dateConsolidation
                ? new Date(procedure.dateConsolidation)
                : undefined,
              dateDeces: victime.dateDeces
                ? new Date(victime.dateDeces)
                : undefined,
            }),
          }),
          {},
        );

        const prejudiceTotalValues =
          prejudicesTotalValues[
            prejudiceType as keyof typeof prejudicesTotalValues
          ];

        const getDataTypeName = () => {
          switch (dataType) {
            case 'victime': {
              return ['indemniteVictimeEchue', 'indemniteVictimeAEchoir'];
            }
            case 'tiersPayeurs': {
              return [
                'indemniteTiersPayeurAEchoir',
                'indemniteTiersPayeurEchue',
              ];
            }
            case 'proche': {
              return ['indemniteProche'];
            }
            case 'rentes': {
              return ['rentes'];
            }
            default: {
              return [];
            }
          }
        };

        let total = 0;
        prejudiceTotalValues?.forEach((values) => {
          if (getDataTypeName().includes(values.name)) {
            total += values.amount;
          }
        });

        totalPrejudice.push(total);

        const value: number | undefined = prejudice
          ? dataType === 'total'
            ? totalAmountsByProcedure()[numRow]?.[numCol] ?? 0
            : total
          : undefined;
        row.push(toCellObject(value, 'n'));
      });
      totalProcedure.push(totalPrejudice);
      data.push(row);
    });
    totalAllProcedures.push(totalProcedure);

    const totalRow: CellObject[] = [toCellObject('Total')];
    allPopulatedProcedure.forEach((procedure) => {
      const procedureTotal = getPrejudicesTotal({
        victime,
        victimesIndirectes: procedure.victimesIndirectes,
        procedure,
        prejudices: procedure.prejudices,
        dateLiquidation: procedure.dateLiquidation
          ? new Date(procedure.dateLiquidation)
          : undefined,
        monetaryErosions,
        dateConsolidation: procedure.dateConsolidation
          ? new Date(procedure.dateConsolidation)
          : undefined,
        dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
      });

      const value = procedureTotal[dataType] ?? 0;

      totalRow.push(toCellObject(value, 'n'));
    });
    data.push(totalRow);
    return data;
  };

  const mergesheet = (workSheet: WorkSheetWithName) => {
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: allPopulatedProcedure.length } },
    ];
    if (!workSheet.sheet['!merges']) {
      workSheet.sheet['!merges'] = [];
    }
    workSheet.sheet['!merges'] = workSheet.sheet['!merges'].concat(merge);
    return workSheet;
  };

  const sheetVictime = mergesheet(
    createWorkSheet(createData(`victime`), `Victime`),
  );
  const sheetTP = mergesheet(
    createWorkSheet(createData(`tiersPayeurs`), `Tiers Payeurs`),
  );
  const sheetVictimesIndirecte = mergesheet(
    createWorkSheet(createData(`proche`), `Victimes Indirecte`),
  );
  const sheetRentes = mergesheet(
    createWorkSheet(createData(`rentes`), `Rentes`),
  );

  const totalAmountsByProcedure = (): number[][] => {
    const numDepths = 4;
    const numRows = allPrejudices.length;
    const numCols = allPopulatedProcedure.length;

    const result: number[][] = Array.from({ length: numRows }, () =>
      Array(numCols).fill(0),
    );

    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        for (let depth = 0; depth < numDepths; depth++) {
          const resultRow = result[row] ?? [];
          const cellValue =
            (resultRow[col] ?? 0) +
            (totalAllProcedures[depth]?.[row]?.[col] ?? 0);
          resultRow[col] = cellValue;
          result[row] = resultRow;
        }
      }
    }

    return result;
  };

  const sheetTotal = mergesheet(createWorkSheet(createData(`total`), `Total`));

  return createWorkbook([
    sheetVictime,
    sheetTP,
    sheetVictimesIndirecte,
    sheetRentes,
    sheetTotal,
  ]);
};
