import { Paragraph, Table, TableOfContents } from 'docx';
import {
  IndemniteRepartieAEchoir,
  IndemniteRepartieTiersPayeurs,
  PGPFReliquat,
} from 'src/constants/calculs/type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { Victime } from 'src/types/victime.type';
import { displayFormula } from '../formula';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';
import { groupBy } from 'lodash';
import { debitSoldeAEchoirPrint } from '../../prejudicesPrints/debitSoldeAEchoirPrint';
import { getTropPercuWrittenPrint } from 'src/helpers/prejudices/pgpfReliquat';

export const indemniteGlobaleARepartirAEchoirWrittenPrint = ({
  indemniteRepartie,
  partResponsabilite,
  tiersPayeurs,
  victime,
  indemniteGlobaleFormula,
  firstPart,
  PGPFReliquat,
}: {
  indemniteRepartie: IndemniteRepartieAEchoir;
  partResponsabilite: number;
  tiersPayeurs: string[];
  victime: Victime;
  indemniteGlobaleFormula: string;
  firstPart: string;
  PGPFReliquat?: PGPFReliquat;
}): (Table | TableOfContents | Paragraph)[] => {
  const getTiersPayeursEchuFormulaText = (
    indemniteTiersPayeur: IndemniteRepartieTiersPayeurs[number],
  ) =>
    indemniteTiersPayeur.montantNonReparti !== indemniteTiersPayeur.montant
      ? i18next.t(
          'prejudice.indemniteGlobaleARepartir.aEchoir.fields.indemniteTiersPayeurs.parTiersPayeur.arreragesEchus.formula',
          {
            total: fCurrency(
              indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
            ),
            montantNonReparti: fCurrency(
              indemniteTiersPayeur.montantNonReparti,
            ),
            totalNonReparti: fCurrency(
              indemniteRepartie.indemniteTiersPayeurs.arreragesEchus
                .totalNonReparti || 0,
            ),
          },
        )
      : '';
  const getTiersPayeursAEchoirFormulaText = (
    indemniteTiersPayeur: IndemniteRepartieTiersPayeurs[number],
  ) =>
    indemniteTiersPayeur.montantNonReparti !== indemniteTiersPayeur.montant
      ? i18next.t(
          'prejudice.indemniteGlobaleARepartir.aEchoir.fields.indemniteTiersPayeurs.parTiersPayeur.arreragesAEchoir.formula',
          {
            total: fCurrency(
              indemniteRepartie.indemniteTiersPayeurs.arreragesAEchoir?.debit ||
                0,
            ),
            montantNonReparti: fCurrency(
              indemniteTiersPayeur.montantNonReparti,
            ),
            totalNonReparti: fCurrency(
              indemniteRepartie.indemniteTiersPayeurs.arreragesAEchoir
                ?.totalNonReparti || 0,
            ),
          },
        )
      : '';

  const indemniteGlobaleFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.fullPartResponabilite.indemniteGlobale',
    {
      indemniteGlobale: fCurrency(
        indemniteRepartie.indemniteGlobaleARepartir.solde,
      ),
      formula: displayFormula({
        formula: indemniteGlobaleFormula,
        options: {
          withParentheses: true,
        },
      }),
    },
  );

  const victimeFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.fullPartResponabilite.indemniteVictime',
    {
      indemniteVictime: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteVictime.total,
      ),
      victimeName: displayVictimeNameWrittenPrint(victime),
      arreragesEchus: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteVictime.arreragesEchus.debit,
      ),
      arreragesAEchoir: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteVictime.arreragesAEchoir?.debit || 0,
      ),
    },
  );

  const indemniteGlobaleNonFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.nonFullPartResponsabilite.indemniteGlobale',
    {
      montantPartResponsabilite: fCurrency(
        indemniteRepartie.indemniteGlobaleARepartir.solde,
      ),
      montant: fCurrency(
        indemniteRepartie.indemniteGlobaleARepartir.solde / partResponsabilite,
      ),
      partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
      formula: displayFormula({
        formula: indemniteGlobaleFormula,
        options: {
          withParentheses: true,
        },
      }),
    },
  );

  const getTiersPayeurRowText = (
    tiersPayeur: string,
    tiersPayeurEchu: IndemniteRepartieTiersPayeurs[number] | undefined,
    tiersPayeurAEchoir: IndemniteRepartieTiersPayeurs[number] | undefined,
  ) =>
    `${i18next.t(
      'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.tiersPayeurRowFirstPart',
      {
        tiersPayeur,
      },
    )}${
      !!tiersPayeurEchu?.montant
        ? i18next.t(
            'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.tiersPayeurRowEchu',
            {
              arreragesEchus: getTropPercuWrittenPrint(tiersPayeurEchu.montant),
              echusFormula: displayFormula({
                formula: getTiersPayeursEchuFormulaText(tiersPayeurEchu),
                options: {
                  withParentheses: true,
                },
              }),
            },
          )
        : ''
    }${
      !!tiersPayeurAEchoir?.montant
        ? i18next.t(
            'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.tiersPayeurRowAEchoir',
            {
              arreragesAEchoir: getTropPercuWrittenPrint(
                tiersPayeurAEchoir.montant,
              ),
              aEchoirFormula: displayFormula({
                formula: getTiersPayeursAEchoirFormulaText(tiersPayeurAEchoir),
                options: {
                  withParentheses: true,
                },
              }),
              context: !!tiersPayeurEchu?.montant ? 'both' : undefined,
            },
          )
        : ''
    }`;

  const oneTiersPayeurText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.oneTiersPayeur',
    {
      indemniteTiersPayeurs: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteTiersPayeurs.total,
      ),
      arreragesEchus: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
      ),
      arreragesAEchoir: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteTiersPayeurs.arreragesAEchoir?.debit || 0,
      ),
      tiersPayeur: tiersPayeurs[0],
    },
  );

  const noTiersPayeurNonFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.noTiersPayeur',
    {
      indemniteVictime: fCurrency(indemniteRepartie.indemniteVictime.total),
      victimeName: displayVictimeNameWrittenPrint(victime),
    },
  );

  const victimeNonFullPartResponsabiliteText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.nonFullPartResponsabilite.indemniteVictime',
    {
      victimeName: displayVictimeNameWrittenPrint(victime),
      indemniteVictime: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteVictime.total,
      ),
      arreragesEchus: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteVictime.arreragesEchus.debit,
      ),
      arreragesAEchoir: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteVictime.arreragesAEchoir?.debit || 0,
      ),
    },
  );

  const multipleTiersPayeursFirstPartText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.multipleTiersPayeursFirstPart',
    {
      arreragesEchus: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
      ),
      arreragesAEchoir: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteTiersPayeurs.arreragesAEchoir?.debit || 0,
      ),
    },
  );

  const tiersPayeursArreragesEchusParTiersPayeur = groupBy(
    indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.parTiersPayeur,
    'tiersPayeur',
  );
  const tiersPayeursArreragesAEchoirParTiersPayeur = groupBy(
    indemniteRepartie.indemniteTiersPayeurs.arreragesAEchoir?.parTiersPayeur ||
      [],
    'tiersPayeur',
  );
  const tiersPayeursParTiersPayeur = tiersPayeurs
    .map((tiersPayeur) => ({
      tiersPayeur,
      arreragesEchus:
        tiersPayeursArreragesEchusParTiersPayeur[tiersPayeur]?.[0],
      arreragesAEchoir:
        tiersPayeursArreragesAEchoirParTiersPayeur[tiersPayeur]?.[0],
    }))
    .filter(
      ({ arreragesEchus, arreragesAEchoir }) =>
        !!arreragesEchus?.montant || !!arreragesAEchoir?.montant,
    );

  const noVictimeText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.noVictime',
    {
      indemniteTiersPayeurs: fCurrency(
        indemniteRepartie.indemniteTiersPayeurs.total,
      ),
    },
  );

  const onlyGlobalTiersPayeursText = i18next.t(
    'prejudice.indemniteGlobaleARepartir.aEchoir.writtenPrint.onlyGlobalTiersPayeurs',
    {
      indemniteTiersPayeurs: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteTiersPayeurs.total,
      ),
      arreragesEchus: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteTiersPayeurs.arreragesEchus.debit,
      ),
      arreragesAEchoir: getTropPercuWrittenPrint(
        indemniteRepartie.indemniteTiersPayeurs.arreragesAEchoir?.debit || 0,
      ),
    },
  );

  if (partResponsabilite === 1) {
    return [
      getParagraph({
        children: [
          ...getTextRun(firstPart),
          ...getTextRun(indemniteGlobaleFullPartResponsabiliteText),
        ],
      }),
      ...(indemniteRepartie.indemniteVictime.total === 0
        ? [getParagraph(noVictimeText)]
        : [
            getParagraph(victimeFullPartResponsabiliteText),
            ...(indemniteRepartie.indemniteTiersPayeurs.total === 0
              ? []
              : (tiersPayeursParTiersPayeur ?? []).length > 1
                ? [
                    getParagraph(multipleTiersPayeursFirstPartText),
                    ...tiersPayeursParTiersPayeur.map((tiersPayeur) =>
                      getParagraph(
                        getTiersPayeurRowText(
                          tiersPayeur.tiersPayeur,
                          tiersPayeur.arreragesEchus,
                          tiersPayeur.arreragesAEchoir,
                        ),
                      ),
                    ),
                  ]
                : (tiersPayeursParTiersPayeur ?? []).length === 1
                  ? [getParagraph(oneTiersPayeurText)]
                  : [getParagraph(onlyGlobalTiersPayeursText)]),
          ]),
      getEmptyLine(),
      ...debitSoldeAEchoirPrint({
        indemniteGlobaleARepartir: indemniteRepartie,
        partResponsabilite,
        displayTitle: false,
        PGPFReliquat,
        displayTropPercu: true,
      }),
    ];
  } else {
    return [
      getParagraph({
        children: [
          ...getTextRun(firstPart),
          ...getTextRun(indemniteGlobaleNonFullPartResponsabiliteText),
        ],
      }),
      ...(indemniteRepartie.indemniteVictime.total === 0
        ? [getParagraph(noVictimeText)]
        : [
            getParagraph(victimeNonFullPartResponsabiliteText),
            ...(indemniteRepartie.indemniteTiersPayeurs.total === 0
              ? [getParagraph(noTiersPayeurNonFullPartResponsabiliteText)]
              : (tiersPayeursParTiersPayeur ?? []).length > 1
                ? [
                    getParagraph(multipleTiersPayeursFirstPartText),
                    getEmptyLine(),
                    ...tiersPayeursParTiersPayeur.map((tiersPayeur) =>
                      getParagraph(
                        getTiersPayeurRowText(
                          tiersPayeur.tiersPayeur,
                          tiersPayeur.arreragesEchus,
                          tiersPayeur.arreragesAEchoir,
                        ),
                      ),
                    ),
                  ]
                : (tiersPayeursParTiersPayeur ?? []).length === 1
                  ? [getParagraph(oneTiersPayeurText)]
                  : [getParagraph(onlyGlobalTiersPayeursText)]),
          ]),
      getEmptyLine(),
      ...debitSoldeAEchoirPrint({
        indemniteGlobaleARepartir: indemniteRepartie,
        partResponsabilite,
        displayTitle: false,
        PGPFReliquat,
        displayTropPercu: true,
      }),
    ];
  }
};
