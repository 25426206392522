import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  NumberingType,
  getEmptyLine,
  getParagraph,
  getTextRun,
} from 'src/helpers/print/docxFunctions';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { OldPrejudiceFormPerteGainProfessionnelsActuel } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { indemniteGlobaleARepartirEchusWrittenPrint } from '../../../indemniteGlobaleARepartir/indemniteGlobaleARepartirEchus.written.print';
import {
  CalculsFormPerteGainsProfessionnelsActuel,
  CalculsGlobal,
} from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { getRevalorisationIntroductionWrittenPrintIfRevalorise } from '../../../revalorisation.written.print';
import { fDate, fYears } from 'src/helpers/formatTime';
import { situationWrittenPrint } from './situation.written.print';
import { displayVictimeNameWrittenPrint } from '../../../victime.written.print';
import { tableSituationPrint } from 'src/helpers/print/prejudicesPrints/prejudices/perteDeGain/tableSituationPrint';

export const pgpaDeclarationFiscaleWrittenPrint = ({
  victime,
  procedure,
  formData,
  monetaryErosions,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: OldPrejudiceFormPerteGainProfessionnelsActuel;
  monetaryErosions: MonetaryErosion[];
}): (Table | TableOfContents | Paragraph)[] => {
  const totaux = CalculsFormPerteGainsProfessionnelsActuel.getTotauxSituations(
    formData.situations,
    procedure.partResponsabilite,
  );

  const indemniteRepartie =
    CalculsFormPerteGainsProfessionnelsActuel.getIndemnitesRepartie(
      formData.situations,
      procedure.tiersPayeurs,
      procedure.partResponsabilite,
    );
  const tableSituation = tableSituationPrint(
    formData.situations,
    totaux,
    procedure.partResponsabilite,
    false,
  );
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    getParagraph(
      i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.firstPart',
        {
          dateDebut: formData.dateDebut ? fDate(formData.dateDebut) : '',
          dateFin: formData.dateFin ? fDate(formData.dateFin) : '',
          years:
            formData.dateDebut && formData.dateFin
              ? fYears(
                  CalculsGlobal.getYears(formData.dateDebut, formData.dateFin),
                )
              : '',
          victimeName: displayVictimeNameWrittenPrint(victime),
        },
      ),
    ),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      formData,
      formType: 'PERTE_GAINS_PROFESSIONNELS_ACTUEL',
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    }),
    ...(formData.situations.length === 1 && formData.situations[0]
      ? [
          getParagraph(
            i18next.t(
              'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.situations.oneSituationFirstPart',
              {
                situation: i18next.t(
                  `prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.REGIME.title.${formData.situations[0].regime}`,
                ),
              },
            ),
          ),
        ]
      : formData.situations.length > 1
        ? [
            getParagraph(
              i18next.t(
                'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.situations.multipleSituationsFirstPart',
              ),
            ),
            ...formData.situations.map((situation) =>
              getParagraph({
                numbering: {
                  reference: NumberingType.SIMPLE_LIST,
                  level: 1,
                },
                text: i18next.t(
                  'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.situations.situation',
                  {
                    situation: i18next.t(
                      `prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.REGIME.title.${situation.regime}`,
                    ),
                  },
                ),
              }),
            ),
          ]
        : []),
    ...(formData.situations.length > 1
      ? formData.situations
          .map((situation, index) => [
            getEmptyLine(),
            getParagraph({
              numbering: {
                reference: NumberingType.ROMAN_NUMERALS,
                level: 0,
              },
              children: getTextRun(
                i18next.t(
                  'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.situations.situationTitle',
                  {
                    situation: i18next.t(
                      `prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.REGIME.title.${situation.regime}`,
                    ),
                  },
                ),
              ),
            }),
            ...situationWrittenPrint({
              situation,
              monetaryErosions,
              dateLiquidation: procedure.dateLiquidation
                ? new Date(procedure.dateLiquidation)
                : undefined,
              isPGPF: false,
              victime,
              ...formData,
              index,
            }),
          ])
          .flat()
      : formData.situations.length === 1 && formData.situations[0]
        ? [
            getEmptyLine(),
            ...situationWrittenPrint({
              situation: formData.situations[0],
              monetaryErosions,
              dateLiquidation: procedure.dateLiquidation
                ? new Date(procedure.dateLiquidation)
                : undefined,
              isPGPF: false,
              victime,
              ...formData,
              index: 0,
            }),
          ]
        : []),
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: NumberingType.ROMAN_NUMERALS,
        level: formData.situations.length > 1 ? 0 : 1,
      },
      text: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.tableSituations.title',
        {
          victimeName: displayVictimeNameWrittenPrint(victime),
        },
      ),
    }),
    tableSituation,
    getEmptyLine(),
    getParagraph({
      numbering: {
        reference: NumberingType.ROMAN_NUMERALS,
        level: formData.situations.length > 1 ? 0 : 1,
      },
      text: i18next.t(
        'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.title',
      ),
    }),
    ...indemniteGlobaleARepartirEchusWrittenPrint({
      indemniteRepartie,
      firstPart: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.indemniteGlobaleARepartir.firstPart',
      ),
      partResponsabilite: procedure.partResponsabilite,
      victime,
      tiersPayeurs: procedure.tiersPayeurs,
      indemniteGlobaleFormula: i18next.t(
        'prejudice.prejudicesTypes.PERTES_GAINS_PROFESSIONNELS_ACTUELS.writtenPrint.declarationFiscale.steps.indemniteGlobaleARepartir.indemniteGlobale.formula',
        {
          perteGainProfessionnelsTotal: fCurrency(
            totaux.perteDeGain.value || 0,
          ),
          csgCrdsTotal: fCurrency(totaux.indemniteCsgCrds.value || 0),
          partResponsabilite: fPartResponsabilite(
            procedure.partResponsabilite * 100,
          ),
          context: totaux.indemniteCsgCrds.value ? undefined : 'noCsgCrds',
        },
      ),
    }),
  ];
};
