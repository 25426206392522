import {
  PrejudiceCategorie,
  PrejudiceType,
  PrejudiceFormType,
} from '../types/prejudice.type';
import { ProcedureQuestion } from '../types/procedure.type';

export const mapAnswerToPrejudices: Partial<
  Record<
    ProcedureQuestion,
    {
      consolide: PrejudiceType[];
      consolideOuNonConsolide: PrejudiceType[];
      exceptionnal: PrejudiceType[];
    }
  >
> = {
  ACCIDENT_DROIT_COMMUN: {
    consolide: [
      'DEPENSE_SANTE_FUTURES',
      'FRAIS_DIVERS_FUTURS',
      'FRAIS_LOGEMENT_ADAPTE',
      'FRAIS_VEHICULE_ADAPTE',
      'PERTES_GAINS_PROFESSIONNELS_FUTURS',
      'INCIDENCE_PROFESSIONNELLE',
      'ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE',
      'DEFICIT_FONCTIONNEL_PERMANENT',
      'PREJUDICE_AGREMENT',
      'PREJUDICE_ESTHETIQUE_PERMANENT',
      'PREJUDICE_SEXUEL',
      'PREJUDICE_ETABLISSEMENT',
      'PREJUDICE_PERMANENT_EXCEPTIONNEL',
    ],
    consolideOuNonConsolide: [
      'DEPENSE_SANTE_ACTUELLES',
      'FRAIS_DIVERS_ACTUELS',
      'PERTES_GAINS_PROFESSIONNELS_ACTUELS',
      'PREJUDICE_SCOLAIRE_UNIVERSITAIRE_FORMATION',
      'ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE',
      'DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL',
      'DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL',
      'SOUFFRANCES_ENDUREES',
      'PREJUDICE_ESTHETIQUE_TEMPORAIRE',
    ],
    exceptionnal: [
      'PREJUDICE_LIES_PATHOLOGIES_EVOLUTIVES',
      'PREJUDICE_IMPREPARATION_MEDICALE',
      'PREJUDICE_ANXIETE',
      'PREJUDICE_MATERIEL',
      'INDEMNITE_PROVISIONNELLE',
      'CALCUL_DES_INTERETS_ET_CAPITALISATION_DES_INTERETS',
      'PREJUDICE_DANGOISSE_DE_MORT_IMMINENTE',
      'AUTRES_POSTES_DE_PREJUDICES',
    ],
  },
  ACCIDENT_DU_TRAVAIL: {
    consolide: [
      'FRAIS_DIVERS_FUTURS',
      'FRAIS_LOGEMENT_ADAPTE',
      'FRAIS_VEHICULE_ADAPTE',
      'INCIDENCE_PROFESSIONNELLE',
      'PREJUDICE_AGREMENT',
      'PREJUDICE_ESTHETIQUE_PERMANENT',
      'PREJUDICE_SEXUEL',
      'PREJUDICE_ETABLISSEMENT',
    ],
    consolideOuNonConsolide: [
      'FRAIS_DIVERS_ACTUELS',
      'ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE',
      'DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL',
      'DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL',
      'SOUFFRANCES_ENDUREES',
      'PREJUDICE_ESTHETIQUE_TEMPORAIRE',
    ],
    exceptionnal: [
      'PREJUDICE_MATERIEL',
      'INDEMNITE_PROVISIONNELLE',
      'CALCUL_DES_INTERETS_ET_CAPITALISATION_DES_INTERETS',
      'AUTRES_POSTES_DE_PREJUDICES',
    ],
  },
};

export const prejudicesVictimesIndirectes: {
  decedee: PrejudiceType[];
  blesseeOuDecedee: PrejudiceType[];
} = {
  decedee: ['FRAIS_OBSEQUES', 'PREJUDICE_ACCOMPAGNEMENT_FIN_DE_VIE'],
  blesseeOuDecedee: [
    'PERTES_REVENUS_DES_PROCHES',
    'FRAIS_DIVERS_DES_PROCHES',
    'PREJUDICE_AFFECTION_OU_MORAL',
    'PREJUDICES_EXTRA_PATRIMONIAUX_EXCEPTIONNELS',
    'PREJUDICE_ANGOISSE_ET_INQUIETUDE',
  ],
};

export const prejudicesData: Record<
  PrejudiceType,
  {
    categorie: PrejudiceCategorie;
    inputType: PrejudiceFormType;
    requirements?: ('dateAccident' | 'dateDeces')[];
    fullWidth?: boolean;
    fullScreen?: boolean;
    isValueSubstractedFromTotal?: boolean;
    shouldNotDisplayProrataTemporis?: boolean;
    displayTotalPartResponsabilite?: boolean;
    noRevalorisation?: boolean;
    isCustomPartResponsabilite?: boolean;
  }
> = {
  DEPENSE_SANTE_FUTURES: {
    categorie: 'PREJUDICES_PATRIMONIAUX_PERMANENTS',
    inputType: 'CALENDRIER_DEPENSE_CAPITALISATION',
    fullScreen: true,
  },
  FRAIS_DIVERS_FUTURS: {
    categorie: 'PREJUDICES_PATRIMONIAUX_PERMANENTS',
    inputType: 'CALENDRIER_DEPENSE_CAPITALISATION',
  },
  FRAIS_LOGEMENT_ADAPTE: {
    categorie: 'PREJUDICES_PATRIMONIAUX_PERMANENTS',
    inputType: 'CALENDRIER_DEPENSE_CAPITALISATION',
  },
  FRAIS_VEHICULE_ADAPTE: {
    categorie: 'PREJUDICES_PATRIMONIAUX_PERMANENTS',
    inputType: 'CALENDRIER_DEPENSE_CAPITALISATION',
  },
  PERTES_GAINS_PROFESSIONNELS_FUTURS: {
    categorie: 'PREJUDICES_PATRIMONIAUX_PERMANENTS',
    inputType: 'PERTE_GAINS_PROFESSIONNELS_FUTURS',
    requirements: ['dateAccident'],
    fullScreen: true,
  },
  INCIDENCE_PROFESSIONNELLE: {
    categorie: 'PREJUDICES_PATRIMONIAUX_PERMANENTS',
    inputType: 'LISTE_PROJECTION',
    displayTotalPartResponsabilite: true,
    shouldNotDisplayProrataTemporis: true,
  },
  ASSISTANCE_PAR_TIERCE_PERSONNE_FUTURE: {
    categorie: 'PREJUDICES_PATRIMONIAUX_PERMANENTS',
    inputType: 'CALENDRIER_ASSISTANCE_CAPITALISATION',
    fullScreen: true,
  },
  DEFICIT_FONCTIONNEL_PERMANENT: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS',
    inputType: 'LISTE_PROJECTION',
    displayTotalPartResponsabilite: true,
  },
  PREJUDICE_AGREMENT: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS',
    inputType: 'LISTE_PROJECTION',
    displayTotalPartResponsabilite: true,
  },
  PREJUDICE_ESTHETIQUE_PERMANENT: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS',
    inputType: 'LISTE_PROJECTION',
    displayTotalPartResponsabilite: true,
  },
  PREJUDICE_SEXUEL: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS',
    inputType: 'LISTE_PROJECTION',
    displayTotalPartResponsabilite: true,
  },
  PREJUDICE_ETABLISSEMENT: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS',
    inputType: 'VALEUR',
    displayTotalPartResponsabilite: true,
    isCustomPartResponsabilite: true,
  },
  PREJUDICE_PERMANENT_EXCEPTIONNEL: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS',
    inputType: 'VALEUR',
    displayTotalPartResponsabilite: true,
    isCustomPartResponsabilite: true,
  },
  DEPENSE_SANTE_ACTUELLES: {
    categorie: 'PREJUDICES_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'CALENDRIER_DEPENSE',
  },
  FRAIS_DIVERS_ACTUELS: {
    categorie: 'PREJUDICES_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'CALENDRIER_DEPENSE',
  },
  PERTES_GAINS_PROFESSIONNELS_ACTUELS: {
    categorie: 'PREJUDICES_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'PERTE_GAINS_PROFESSIONNELS_ACTUEL',
    requirements: ['dateAccident'],
    fullScreen: true,
  },
  PREJUDICE_SCOLAIRE_UNIVERSITAIRE_FORMATION: {
    categorie: 'PREJUDICES_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'SCOLAIRE',
    displayTotalPartResponsabilite: true,
  },
  ASSISTANCE_PAR_TIERCE_PERSONNE_ACTUELLE: {
    categorie: 'PREJUDICES_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'CALENDRIER_ASSISTANCE',
    displayTotalPartResponsabilite: true,
  },
  DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL',
    displayTotalPartResponsabilite: true,
  },
  DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL',
    displayTotalPartResponsabilite: true,
  },
  SOUFFRANCES_ENDUREES: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'LISTE_PROJECTION',
    shouldNotDisplayProrataTemporis: true,
    displayTotalPartResponsabilite: true,
  },
  PREJUDICE_ESTHETIQUE_TEMPORAIRE: {
    categorie: 'PREJUDICES_EXTRA_PATRIMONIAUX_TEMPORAIRES',
    inputType: 'LISTE_PROJECTION',
    displayTotalPartResponsabilite: true,
    shouldNotDisplayProrataTemporis: true,
  },
  PREJUDICE_LIES_PATHOLOGIES_EVOLUTIVES: {
    categorie: 'AUTRES_PREJUDICES_HORS_CONSOLIDATION',
    inputType: 'VALEUR',
    displayTotalPartResponsabilite: true,
    isCustomPartResponsabilite: true,
  },
  PREJUDICE_IMPREPARATION_MEDICALE: {
    categorie: 'AUTRES_PREJUDICES_HORS_CONSOLIDATION',
    inputType: 'VALEUR',
    displayTotalPartResponsabilite: true,
  },
  PREJUDICE_ANXIETE: {
    categorie: 'AUTRES_PREJUDICES_HORS_CONSOLIDATION',
    inputType: 'VALEUR',
    displayTotalPartResponsabilite: true,
    isCustomPartResponsabilite: true,
  },
  PREJUDICE_MATERIEL: {
    categorie: 'POSTES_ANNEXES',
    inputType: 'MATERIEL',
    displayTotalPartResponsabilite: true,
    isCustomPartResponsabilite: true,
  },
  CALCUL_DES_INTERETS_ET_CAPITALISATION_DES_INTERETS: {
    categorie: 'POSTES_ANNEXES',
    inputType: 'VALEUR',
    isCustomPartResponsabilite: true,
  },
  PREJUDICE_DANGOISSE_DE_MORT_IMMINENTE: {
    categorie: 'AUTRES_PREJUDICES_HORS_CONSOLIDATION',
    inputType: 'VALEUR',
    displayTotalPartResponsabilite: true,
    isCustomPartResponsabilite: true,
  },
  INDEMNITE_PROVISIONNELLE: {
    categorie: 'POSTES_ANNEXES',
    inputType: 'CALENDRIER_VALEURS',
    isValueSubstractedFromTotal: true,
    noRevalorisation: true,
  },
  AUTRES_POSTES_DE_PREJUDICES: {
    categorie: 'POSTES_ANNEXES',
    inputType: 'LISTE_PROJECTION',
    shouldNotDisplayProrataTemporis: true,
    displayTotalPartResponsabilite: true,
  },
  PERTES_REVENUS_DES_PROCHES: {
    categorie: 'VICTIMES_INDIRECTES_PATRIMONIAUX',
    inputType: 'PERTES_REVENUS_PROCHE',
    isCustomPartResponsabilite: true,
    fullScreen: true,
  },
  FRAIS_OBSEQUES: {
    categorie: 'VICTIMES_INDIRECTES_PATRIMONIAUX',
    inputType: 'CALENDRIER_DEPENSE',
  },
  PREJUDICE_ACCOMPAGNEMENT_FIN_DE_VIE: {
    categorie: 'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
    inputType: 'VALEUR_VICTIMES_INDIRECTES',
    isCustomPartResponsabilite: true,
  },
  FRAIS_DIVERS_DES_PROCHES: {
    categorie: 'VICTIMES_INDIRECTES_PATRIMONIAUX',
    inputType: 'CALENDRIER_DEPENSE',
    isCustomPartResponsabilite: true,
  },
  PREJUDICE_AFFECTION_OU_MORAL: {
    categorie: 'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
    inputType: 'VALEUR_VICTIMES_INDIRECTES',
    isCustomPartResponsabilite: true,
  },
  PREJUDICES_EXTRA_PATRIMONIAUX_EXCEPTIONNELS: {
    categorie: 'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
    inputType: 'VALEUR_VICTIMES_INDIRECTES',
    isCustomPartResponsabilite: true,
  },
  PREJUDICE_ANGOISSE_ET_INQUIETUDE: {
    categorie: 'VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX',
    inputType: 'VALEUR_VICTIMES_INDIRECTES',
    isCustomPartResponsabilite: true,
  },
};

export const prejudiceCategoriesData: Record<
  PrejudiceCategorie,
  {
    directe: boolean;
    rank: number;
  }
> = {
  PREJUDICES_PATRIMONIAUX_TEMPORAIRES: {
    directe: true,
    rank: 0,
  },
  PREJUDICES_EXTRA_PATRIMONIAUX_TEMPORAIRES: {
    directe: true,
    rank: 1,
  },
  PREJUDICES_PATRIMONIAUX_PERMANENTS: {
    directe: true,
    rank: 2,
  },
  PREJUDICES_EXTRA_PATRIMONIAUX_PERMANENTS: {
    directe: true,
    rank: 3,
  },
  AUTRES_PREJUDICES_HORS_CONSOLIDATION: {
    directe: true,
    rank: 4,
  },
  POSTES_ANNEXES: {
    directe: true,
    rank: 5,
  },
  VICTIMES_INDIRECTES_PATRIMONIAUX: {
    directe: false,
    rank: 6,
  },
  VICTIMES_INDIRECTES_EXTRA_PATRIMONIAUX: {
    directe: false,
    rank: 7,
  },
};
