import React from 'react';
import { prejudicesData } from '../../../constants/prejudices';
import { FormCalendrierDepense } from '../../../components/client/prejudiceFormTypes/FormCalendrierDepense';
import { FormValeur } from '../../../components/client/prejudiceFormTypes/FormValeur';
import {
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierValeur,
  PrejudiceFormValeur,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierAssistance,
  PrejudiceFormListeProjection,
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal,
  PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel,
  PrejudiceFormCalendrierAssistanceCapitalisation,
  PrejudiceFormPerteRevenusProche,
  PrejudiceFormValeurVictimesIndirectes,
  PrejudiceFormPerteRevenusProcheVictimeNonDecedee,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormMateriel,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  Prejudice,
  PrejudiceType,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  PrejudiceFormScolaire,
} from '../../../types/prejudice.type';
import { useAppSelector } from '../../../hooks/store';
import { FormCalendrierDepenseCapitalisation } from '../../../components/client/prejudiceFormTypes/FormCalendrierDepenseCapitalisation';
import { FormCalendrierAssistance } from '../../../components/client/prejudiceFormTypes/FormCalendrierAssistance';
import { FormListeProjection } from '../../../components/client/prejudiceFormTypes/FormListeProjection';
import { FormCalendrierDeficitFonctionnelTemporaireTotal } from 'src/components/client/prejudiceFormTypes/FormCalendrierDeficitFonctionnelTemporaireTotal';
import { FormCalendrierDeficitFonctionnelTemporairePartiel } from 'src/components/client/prejudiceFormTypes/FormCalendrierDeficitFonctionnelTemporairePartiel';
import { FormCalendrierAssistanceCapitalisation } from 'src/components/client/prejudiceFormTypes/FormCalendrierAssistanceCapitalisation';
import { FormCalendrierValeur } from 'src/components/client/prejudiceFormTypes/FormCalendrierValeur';
import { monetaryErosionSelectors } from 'src/store/selectors';
import { FormPertesRevenusProcheVictimeDecedee } from 'src/components/client/prejudiceFormTypes/PertesRevenusProche/FormPertesRevenusProcheVictimeDecedee';
import { FormValeurVictimesIndirectes } from 'src/components/client/prejudiceFormTypes/FormValeurVictimesIndirectes';
import { FormPertesRevenusProcheVictimeNonDecedee } from 'src/components/client/prejudiceFormTypes/FormPertesRevenusProcheVictimeNonDecedee';
import { FormPerteGainsProfessionnels } from '../../../components/client/prejudiceFormTypes/FormPerteGainsProfessionnel';
import { FormMateriel } from 'src/components/client/prejudiceFormTypes/FormMateriel';
import { PrejudiceFormProps } from 'src/components/client/PrejudiceFormProps';
import { FieldValues } from 'react-hook-form';
import { getDFTPATPTRows, getTauxIPP } from 'src/helpers/prejudices/prejudice';
import { getAllNumeroPieces } from 'src/helpers/prejudices/numeroPiece';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { Procedure } from 'src/types/procedure.type';
import { Bareme } from 'src/types/bareme.type';
import { ModalProps } from '@mui/material';
import { Dommage } from 'src/types/dommage.type';
import { FormScolaire } from 'src/components/client/prejudiceFormTypes/FormScolaire';

interface Props {
  open: boolean;
  prejudice?: Prejudice;
  prejudiceType?: PrejudiceType;
  allPrejudices: Prejudice[];
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  dommages: Dommage[];
  baremes: Bareme[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
  onClose: ModalProps['onClose'];
}
export const PrejudiceInputDialog: React.FC<Props> = ({
  open,
  prejudice,
  allPrejudices,
  prejudiceType,
  victime,
  victimesIndirectes,
  procedure,
  dommages,
  baremes,
  PGPFReliquat,
  onClose,
}) => {
  const { monetaryErosion, user } = useAppSelector((state) => ({
    monetaryErosion: monetaryErosionSelectors.selectAll(state),
    user: state.auth.user,
  }));
  const onCloseDialog = () => {
    onClose && onClose({}, 'escapeKeyDown');
  };
  if (prejudiceType) {
    const prejudiceData =
      prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES'];
    const prejudiceProps: PrejudiceFormProps<FieldValues> = {
      prejudice,
      prejudices: allPrejudices,
      prejudiceType,
      victime,
      procedure,
      dommages,
      baremes,
      monetaryErosions: monetaryErosion,
      victimesIndirectes,
      displayTotalPartResponsabilite:
        prejudiceData.displayTotalPartResponsabilite,
      partResponsabilite: procedure.partResponsabilite,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateAccident: victime.dateAccident
        ? new Date(victime.dateAccident)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
      open,
      allNumerosPieces: getAllNumeroPieces(allPrejudices),
      user,
      onClose: onCloseDialog,
    };
    const tauxIPP = getTauxIPP(allPrejudices);
    switch (prejudiceData.inputType) {
      case 'CALENDRIER_DEPENSE':
        return (
          <FormCalendrierDepense
            {...prejudiceProps}
            values={prejudice?.formData as PrejudiceFormCalendrierDepense}
            hideDepenseRecurrente={prejudiceType === 'FRAIS_OBSEQUES'}
            hideFrequenceViagere={prejudiceType === 'FRAIS_DIVERS_DES_PROCHES'}
            victimesIndirectes={victimesIndirectes}
          />
        );
      case 'VALEUR':
        return (
          <FormValeur
            {...prejudiceProps}
            values={prejudice?.formData as PrejudiceFormValeur}
          />
        );
      case 'CALENDRIER_DEPENSE_CAPITALISATION':
        return (
          <FormCalendrierDepenseCapitalisation
            {...prejudiceProps}
            values={
              prejudice?.formData as PrejudiceFormCalendrierDepenseCapitalisation
            }
          />
        );
      case 'CALENDRIER_ASSISTANCE':
        return (
          <FormCalendrierAssistance
            {...prejudiceProps}
            values={prejudice?.formData as PrejudiceFormCalendrierAssistance}
            DFTPRows={getDFTPATPTRows(
              allPrejudices,
              procedure.tiersPayeurs,
              prejudiceProps.dateLiquidation,
            )}
          />
        );
      case 'CALENDRIER_ASSISTANCE_CAPITALISATION':
        return (
          <FormCalendrierAssistanceCapitalisation
            {...prejudiceProps}
            values={
              prejudice?.formData as PrejudiceFormCalendrierAssistanceCapitalisation
            }
          />
        );
      case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_TOTAL':
        return (
          <FormCalendrierDeficitFonctionnelTemporaireTotal
            {...prejudiceProps}
            values={
              prejudice?.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporaireTotal
            }
          />
        );
      case 'CALENDRIER_DEFICIT_FONCTIONNEL_TEMPORAIRE_PARTIEL':
        return (
          <FormCalendrierDeficitFonctionnelTemporairePartiel
            {...prejudiceProps}
            values={
              prejudice?.formData as PrejudiceFormCalendrierDeficitFonctionnelTemporairePartiel
            }
          />
        );
      case 'LISTE_PROJECTION':
        return (
          <FormListeProjection
            {...prejudiceProps}
            values={prejudice?.formData as PrejudiceFormListeProjection}
            PGPFReliquat={PGPFReliquat}
          />
        );
      case 'PERTE_GAINS_PROFESSIONNELS_ACTUEL':
      case 'PERTE_GAINS_PROFESSIONNELS_FUTURS':
        const formData = prejudice?.formData as
          | OldPrejudiceFormPerteGainProfessionnelsActuel
          | NewPrejudiceFormPerteGainProfessionnelsActuel
          | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
          | OldPrejudiceFormPerteGainProfessionnelsFuturs
          | NewPrejudiceFormPerteGainProfessionnelsFuturs
          | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte;
        return (
          <FormPerteGainsProfessionnels
            {...prejudiceProps}
            values={formData}
            tauxIPP={tauxIPP}
          />
        );
      case 'CALENDRIER_VALEURS':
        return (
          <FormCalendrierValeur
            {...prejudiceProps}
            values={prejudice?.formData as PrejudiceFormCalendrierValeur}
          />
        );
      case 'MATERIEL':
        return (
          <FormMateriel
            {...prejudiceProps}
            values={prejudice?.formData as PrejudiceFormMateriel}
          />
        );
      case 'PERTES_REVENUS_PROCHE':
        return !!victime.dateDeces ? (
          <FormPertesRevenusProcheVictimeDecedee
            {...prejudiceProps}
            values={prejudice?.formData as PrejudiceFormPerteRevenusProche}
            victimesIndirectes={victimesIndirectes}
          />
        ) : (
          <FormPertesRevenusProcheVictimeNonDecedee
            {...prejudiceProps}
            values={
              prejudice?.formData as PrejudiceFormPerteRevenusProcheVictimeNonDecedee
            }
          />
        );
      case 'VALEUR_VICTIMES_INDIRECTES':
        return (
          <FormValeurVictimesIndirectes
            {...prejudiceProps}
            values={
              prejudice?.formData as PrejudiceFormValeurVictimesIndirectes
            }
            victimesIndirectes={victimesIndirectes}
          />
        );
      case 'SCOLAIRE': {
        return (
          <FormScolaire
            {...prejudiceProps}
            values={prejudice?.formData as PrejudiceFormScolaire}
          />
        );
      }
      default:
        return null;
    }
  } else {
    return null;
  }
};
