import { Paragraph, Table, TableOfContents, TextRun } from 'docx';
import {
  getMontantRevalorise,
  getRevalorisationFormula,
  isPrejudiceRevalorise,
} from 'src/helpers/prejudices/revalorisation';
import { getEmptyLine, getParagraph, getTextRun } from '../docxFunctions';
import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import { MonetaryErosionsCoefficientsType } from 'src/types/monetaryErosion.type';

export const montantRevaloriseWithIntroWrittenPrint = (
  parameters: Parameters<typeof getMontantRevalorise>[0],
): TextRun[] => {
  const montantRevalorise = getMontantRevalorise(parameters);
  if (montantRevalorise === null) {
    return [];
  }
  return getTextRun(
    i18next.t(
      `prejudice.revalorisation.coefficientsType.${parameters.coefficientsType}.writtenPrint.valueWithIntro`,
      {
        value: fCurrency(montantRevalorise),
      },
    ),
  );
};

export const getMontantRevaloriseWrittenPrint = (
  parameters: Parameters<typeof getMontantRevalorise>[0],
): string => {
  const montantRevalorise = getMontantRevalorise(parameters);
  if (montantRevalorise === null || montantRevalorise === parameters.montant) {
    return '';
  }
  return i18next.t('prejudice.revalorisation.writtenPrint.value', {
    value: fCurrency(montantRevalorise),
    formula: getRevalorisationFormula(parameters),
  });
};

export const revalorisationIntroductionWrittenPrint = (
  revalorisationCoefficientsType: MonetaryErosionsCoefficientsType,
  isVictimeIndirecte: boolean,
): (Table | TableOfContents | Paragraph)[] => [
  getParagraph({
    children: getTextRun(
      `${i18next.t(
        'prejudice.revalorisation.writtenPrint.coefficientsTypesIntroduction.firstPart',
      )}${
        !isVictimeIndirecte
          ? i18next.t(
              'prejudice.revalorisation.writtenPrint.coefficientsTypesIntroduction.secondPart',
              {
                context: revalorisationCoefficientsType,
              },
            )
          : i18next.t(
              'prejudice.revalorisation.writtenPrint.coefficientsTypesIntroduction.secondPartVictimeIndirecte',
              {
                context: revalorisationCoefficientsType,
              },
            )
      }`,
    ),
  }),
];
export const getRevalorisationIntroductionWrittenPrintIfRevalorise = ({
  revalorisationCoefficientsType,
  formType,
  formData,
  isVictimeIndirecte = false,
}: Parameters<typeof isPrejudiceRevalorise>[0] & {
  revalorisationCoefficientsType: MonetaryErosionsCoefficientsType;
  isVictimeIndirecte?: boolean;
}): (Table | TableOfContents | Paragraph)[] =>
  isPrejudiceRevalorise({ formType, formData })
    ? [
        ...revalorisationIntroductionWrittenPrint(
          revalorisationCoefficientsType,
          isVictimeIndirecte,
        ),
        getEmptyLine(),
      ]
    : [];
