import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceType,
  RevenuActiviteAnnuelDeReferenceRow,
  unites,
} from '../../../types/prejudice.type';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { NewStepManager } from '../prejudiceFormComponents/perteGainsProfessionnelsActuel/NewStepManager';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Victime } from 'src/types/victime.type';
import {
  getDefaultMinAndMaxDate,
  validationSchemaNumeroPieces,
  validationSchemaPrejudiceBase,
} from 'src/constants/prejudice/validation';
import { Procedure } from 'src/types/procedure.type';
import { dateString } from 'src/helpers/yup';
import i18next from 'i18next';
import {
  defaultRevenuActiviteAnnuelDeReferenceRow,
  getDefaultIndemnitesJournalieresPercuesPendantLaPeriodeDArretRow,
  getDefaultPerteDeGainsProfessionnelsRow,
} from './NewFormPerteGainsProfessionnelsFuturs';
import { MAX_TIMESTAMP } from 'src/helpers/formatTime';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { InputAdornment } from '@mui/material';
import { DintilhacButton } from '../prejudiceFormComponents/DintilhacButton';

export const validationSchemaNewFormPerteGainsProfessionnelsActuelRevenuActiviteAnnuelDeReferenceRow =
  ({
    victime,
    procedure,
    isPGPF,
  }: {
    victime: Victime;
    procedure: Procedure;
    isPGPF: boolean;
  }): yup.ObjectSchema<RevenuActiviteAnnuelDeReferenceRow> => {
    return yup.object<RevenuActiviteAnnuelDeReferenceRow>().shape({
      numerosPieces: validationSchemaNumeroPieces,
      revenuActiviteAnnuelNet: yup.number().optional().defined(),
      annee: isPGPF
        ? yup
            .number()
            .required()
            .min(1900)
            .max(
              procedure.dateLiquidation
                ? new Date(procedure.dateLiquidation).getFullYear()
                : new Date(MAX_TIMESTAMP).getFullYear(),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.rows.annee.validation.maxAnneeLiquidation',
              ),
            )
        : yup
            .number()
            .required()
            .min(1900)
            .max(
              victime.dateAccident
                ? new Date(victime.dateAccident).getFullYear()
                : new Date(MAX_TIMESTAMP).getFullYear(),
              i18next.t(
                'prejudice.prejudicesFormTypes.NEW_PERTE_GAINS_PROFESSIONELS.fields.revenuActiviteAnnuelDeReference.rows.annee.validation.maxAnneeAccident',
              ),
            ),
      mois: yup.lazy((value) =>
        value === ''
          ? (yup
              .string()
              .defined()
              .transform(() => null) as unknown as yup.NumberSchema<
              number,
              yup.AnyObject,
              undefined,
              ''
            >)
          : yup.number().optional().nullable().min(0).max(11),
      ),
      revenuNet: yup.number().defined(),
      unite: yup
        .string()
        .oneOf([...unites, ''])
        .when('revenuNet', ([revenuNet], schema) =>
          revenuNet !== 0 ? schema.required().oneOf(unites) : schema.optional(),
        )
        .defined(),
      montantsDejaRevalorises: yup.boolean().optional().defined(),
    });
  };
export const validationSchemaNewFormPerteGainsProfessionnelsActuelRevenuActiviteAnnuelDeReference =
  ({
    victime,
    procedure,
  }: {
    victime: Victime;
    procedure: Procedure;
  }): yup.ObjectSchema<
    NewPrejudiceFormPerteGainProfessionnelsActuel['revenuActiviteAnnuelDeReference']
  > =>
    yup.object({
      rows: yup
        .array()
        .defined()
        .of(
          validationSchemaNewFormPerteGainsProfessionnelsActuelRevenuActiviteAnnuelDeReferenceRow(
            {
              victime,
              procedure,
              isPGPF: false,
            },
          ),
        ),
      total: yup.number().optional().defined().positive(),
    });
export const validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnelsRow =
  ({
    victime,
    procedure,
    prejudiceType,
    isPGPF,
  }: {
    victime: Victime;
    procedure: Procedure;
    prejudiceType: PrejudiceType;
    isPGPF: boolean;
  }): yup.ObjectSchema<
    NewPrejudiceFormPerteGainProfessionnelsActuel['perteDeGainsProfessionnels']['rows'][number]
  > => {
    const { minDate, maxDate } = getDefaultMinAndMaxDate({
      procedure,
      victime,
      prejudiceType,
    });
    return yup.object({
      numerosPieces: validationSchemaNumeroPieces,
      revenuPercuNet: yup.number().required(),
      unite: yup
        .string()
        .oneOf([...unites, ''])
        .when('revenuPercuNet', ([revenuPercuNet], schema) =>
          revenuPercuNet !== 0
            ? schema.oneOf(unites).required()
            : schema.oneOf([...unites, '']).optional(),
        )
        .defined(),
      montantsDejaRevalorises: yup.boolean().optional().defined(),
      duree: yup.number().required(),
      dateDebut: dateString()
        .nullable()
        .required()
        .minDate(undefined, minDate.message, minDate.date)
        .maxDate(undefined, maxDate.message, maxDate.date, { strict: !isPGPF }),
      dateFin: yup
        .string()
        .isDateString()
        .nullable()
        .required()
        .minDate(
          'dateDebut',
          i18next.t('validation.prejudices.dates.minDateDebut') || '',
        )
        .maxDate(undefined, maxDate.message, maxDate.date, { strict: !isPGPF }),
      revenuDeReferenceAnnuel: yup.number().required(),
      revenuDeReferencePeriode: yup.number().required(),
      revenuPercuParPeriode: yup.number().required(),
      perteDeGainsProfessionnels: yup.number().required(),
      commentaires: yup.string().defined(),
    });
  };

export const validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeChanceDeGainProfessionnelRow =
  (): yup.ObjectSchema<
    NewPrejudiceFormPerteGainProfessionnelsActuel['perteDeChanceDeGainProfessionnel']['rows'][number]
  > =>
    yup.object().shape({
      numerosPieces: validationSchemaNumeroPieces,
      indemnite: yup.number().required(),
      unite: yup
        .string()
        .oneOf([...unites, ''])
        .when('revenu', ([revenu], schema) =>
          revenu !== 0
            ? schema.oneOf(unites).required()
            : schema.oneOf([...unites, '']).optional(),
        )
        .defined(),
      annee: yup.number().required(),
      commentaires: yup.string().defined(),
    });

export const validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeChanceDeGainProfessionnel =
  (): yup.ObjectSchema<
    NewPrejudiceFormPerteGainProfessionnelsActuel['perteDeChanceDeGainProfessionnel']
  > =>
    yup.object().shape({
      rows: yup
        .array()
        .of(
          validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeChanceDeGainProfessionnelRow(),
        )
        .defined(),
      totalIndemnite: yup.number().required(),
      coefficientPerteDeChance: yup.number().required().min(0).max(100),
      perteDeChance: yup.number().required(),
    });

export const validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnels =
  ({
    victime,
    procedure,
    prejudiceType,
    isPGPF,
  }: {
    victime: Victime;
    procedure: Procedure;
    prejudiceType: PrejudiceType;
    isPGPF: boolean;
  }): yup.ObjectSchema<
    NewPrejudiceFormPerteGainProfessionnelsActuel['perteDeGainsProfessionnels']
  > =>
    yup.object({
      rows: yup.array().defined().of(
        validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnelsRow(
          {
            victime,
            procedure,
            prejudiceType,
            isPGPF,
          },
        ),
      ),
      total: yup
        .number()
        .defined()
        .test(
          'perteDeChanceDeGainProfessionnel',
          '',
          (value: number, context) => {
            const contextFromValue:
              | NewPrejudiceFormPerteGainProfessionnelsActuel
              | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
              | undefined = Array.isArray(context.from)
              ? context.from[1]?.value
              : undefined;

            if (!contextFromValue) {
              return false;
            }

            const total: number =
              contextFromValue.formType === 'SAISIE_DIRECTE'
                ? (
                    contextFromValue as PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
                  )['revenuEspere'].total
                : contextFromValue.formType === 'REVENUS'
                  ? (
                      contextFromValue as NewPrejudiceFormPerteGainProfessionnelsActuel
                    )['revenuActiviteAnnuelDeReference'].total
                  : 0;

            if (total <= 0) {
              return true;
            }

            return value > 0;
          },
        ),
      revenusPercusTotal: yup.number().defined(),
      revenuReferenceTotal: yup.number().defined(),
    });
export const validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArretRow =
  ({
    victime,
    procedure,
    prejudiceType,
  }: {
    victime: Victime;
    procedure: Procedure;
    prejudiceType: PrejudiceType;
  }): yup.ObjectSchema<
    NewPrejudiceFormPerteGainProfessionnelsActuel['indemnitesJournalieresPercuesPendantLaPeriodeDArret']['rows'][0]
  > => {
    const { minDate, maxDate } = getDefaultMinAndMaxDate({
      procedure,
      victime,
      prejudiceType,
    });
    return yup.object({
      numerosPieces: validationSchemaNumeroPieces,
      dateDebut: dateString()
        .optional()
        .nullable()
        .defined()
        .minDate(undefined, minDate.message, minDate.date)
        .maxDate(undefined, maxDate.message, maxDate.date),
      dateFin: dateString()
        .optional()
        .nullable()
        .defined()
        .minDate(
          'dateDebut',
          i18next.t('validation.prejudices.dates.minDateDebut') || '',
        )
        .maxDate(undefined, maxDate.message, maxDate.date),
      duree: yup.number().required().nullable(),
      tiersPayeur: yup.string().required(),
      indemniteJournalieresPercuesNet: yup.number().required(),
      uniteIndemniteJournalieresPercuesNet: yup
        .string()
        .oneOf([...unites, ''])
        .when(
          'indemniteJournalieresPercuesNet',
          ([indemniteJournalieresPercuesNet], schema) =>
            indemniteJournalieresPercuesNet !== 0
              ? schema.oneOf(unites).required()
              : schema.optional(),
        )
        .defined(),
      csgRds: yup.number().optional().nullable().defined(),
      uniteCsgRds: yup
        .string()
        .oneOf([...unites, ''])
        .when('csgRds', ([csgRds], schema) =>
          typeof csgRds === 'number' && csgRds !== 0
            ? schema.oneOf(unites).required()
            : schema.optional(),
        )
        .defined(),
      indemnitesPercuesNetParPeriode: yup
        .number()
        .optional()
        .nullable()
        .defined(),
      csgRDSParPeriode: yup.number().optional().nullable().defined(),
      commentaires: yup.string().defined(),
    });
  };
export const validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArret =
  ({
    victime,
    procedure,
    prejudiceType,
  }: {
    victime: Victime;
    procedure: Procedure;
    prejudiceType: PrejudiceType;
  }): yup.ObjectSchema<
    NewPrejudiceFormPerteGainProfessionnelsActuel['indemnitesJournalieresPercuesPendantLaPeriodeDArret']
  > =>
    yup.object({
      rows: yup.array().defined().of(
        validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArretRow(
          {
            victime,
            procedure,
            prejudiceType,
          },
        ),
      ),
      indemnitesJournalieresPercuesNet: yup
        .number()
        .optional()
        .nullable()
        .defined(),
      csgRdsTotal: yup.number().optional().nullable().defined(),
    });
export const validationSchemaNewFormPerteGainsProfessionnelsActuel = ({
  victime,
  procedure,
  prejudiceType,
}: {
  victime: Victime;
  procedure: Procedure;
  prejudiceType: PrejudiceType;
}): yup.ObjectSchema<NewPrejudiceFormPerteGainProfessionnelsActuel> =>
  validationSchemaPrejudiceBase.shape({
    revenuActiviteAnnuelDeReference:
      validationSchemaNewFormPerteGainsProfessionnelsActuelRevenuActiviteAnnuelDeReference(
        {
          victime,
          procedure,
        },
      ),
    perteDeGainsProfessionnels:
      validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeGainsProfessionnels(
        { victime, procedure, prejudiceType, isPGPF: false },
      ),
    indemnitesJournalieresPercuesPendantLaPeriodeDArret:
      validationSchemaNewFormPerteGainsProfessionnelsActuelIndmeniteJournalieresPercuesPendantLaPeriodeDArret(
        { victime, procedure, prejudiceType },
      ),
    perteDeChanceDeGainProfessionnel:
      validationSchemaNewFormPerteGainsProfessionnelsActuelPerteDeChanceDeGainProfessionnel(),

    total: yup.number().required(),
    formType: yup.string().oneOf(['REVENUS']).required(),
    revalorisationCoefficientsType: yup
      .string()
      .oneOf(['annuel', 'smic'])
      .required(),
  });

export const defaultPerteDeChanceDeGainProfessionnelRow: NewPrejudiceFormPerteGainProfessionnelsActuel['perteDeChanceDeGainProfessionnel']['rows'][number] =
  {
    numerosPieces: { rows: [] },
    indemnite: 0,
    unite: '',
    annee: null,
    commentaires: '',
  };

export const newPGPADefaultValues = ({
  values,
  prejudiceType,
}: {
  values?: NewPrejudiceFormPerteGainProfessionnelsActuel;
  prejudiceType: PrejudiceType;
}): NewPrejudiceFormPerteGainProfessionnelsActuel => ({
  ...prejudiceBaseDefaultValues({
    prejudiceType,
    values,
  }),
  notes: values?.notes || '',
  revenuActiviteAnnuelDeReference: {
    rows:
      sortBy(values?.revenuActiviteAnnuelDeReference?.rows, 'annee').map(
        (row) => ({
          ...defaultRevenuActiviteAnnuelDeReferenceRow,
          ...row,
        }),
      ) || [],
    total: values?.revenuActiviteAnnuelDeReference?.total || 0,
  },
  perteDeGainsProfessionnels: {
    rows:
      sortBy(
        values?.perteDeGainsProfessionnels?.rows,
        (row) => row.dateDebut,
      ).map((row) => ({
        ...getDefaultPerteDeGainsProfessionnelsRow(),
        ...row,
      })) || [],
    total: values?.perteDeGainsProfessionnels?.total || 0,
    revenusPercusTotal:
      values?.perteDeGainsProfessionnels?.revenusPercusTotal || 0,
    revenuReferenceTotal:
      values?.perteDeGainsProfessionnels?.revenuReferenceTotal || 0,
  },
  indemnitesJournalieresPercuesPendantLaPeriodeDArret: {
    rows:
      sortBy(
        values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret?.rows,
        (row) => row.dateDebut,
      ).map((row) => ({
        ...getDefaultIndemnitesJournalieresPercuesPendantLaPeriodeDArretRow(),
        ...row,
      })) || [],
    csgRdsTotal:
      values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret
        ?.csgRdsTotal || null,
    indemnitesJournalieresPercuesNet:
      values?.indemnitesJournalieresPercuesPendantLaPeriodeDArret
        ?.indemnitesJournalieresPercuesNet || null,
  },
  perteDeChanceDeGainProfessionnel: {
    rows:
      values?.perteDeChanceDeGainProfessionnel?.rows.map((row) => ({
        ...row,
      })) || [],
    totalIndemnite:
      values?.perteDeChanceDeGainProfessionnel?.totalIndemnite || 0,
    coefficientPerteDeChance:
      values?.perteDeChanceDeGainProfessionnel?.coefficientPerteDeChance || 100,
    perteDeChance: values?.perteDeChanceDeGainProfessionnel?.perteDeChance || 0,
  },
  total: values?.total || 0,
  formType: values?.formType || 'REVENUS',
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'smic',
});
type Props = PrejudiceFormProps<NewPrejudiceFormPerteGainProfessionnelsActuel>;

export const NewFormPerteGainsProfessionnelsActuel: React.FC<Props> = ({
  victime,
  procedure,
  dommages,
  values,
  monetaryErosions,
  onPrevious,
  dateConsolidation,
  dateLiquidation,
  allNumerosPieces,
  ...props
}) => {
  const { t } = useTranslation();
  const useFormReturn = useForm<NewPrejudiceFormPerteGainProfessionnelsActuel>({
    defaultValues: newPGPADefaultValues({
      values,
      prejudiceType: props.prejudiceType,
    }),
    resolver: yupResolver(
      validationSchemaNewFormPerteGainsProfessionnelsActuel({
        victime,
        procedure,
        prejudiceType: props.prejudiceType,
      }),
    ),
  });
  const { control, getValues, trigger, handleSubmit } = useFormReturn;

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  return (
    <PrejudiceContainer<NewPrejudiceFormPerteGainProfessionnelsActuel>
      {...props}
      victime={victime}
      procedure={procedure}
      monetaryErosions={monetaryErosions}
      {...useFormReturn}
      renderPrejudice={({ setAdditionalTitle }) => (
        <Stack direction="column" spacing={4}>
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DintilhacButton onClick={() => setShowDintilhac(true)} />
                </InputAdornment>
              ),
            }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${props.prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <NewStepManager
            setAdditionalTitle={setAdditionalTitle}
            procedure={procedure}
            dommage={dommages?.[0]}
            prejudiceType={props.prejudiceType}
            victime={victime}
            control={control}
            trigger={trigger}
            values={values}
            getValues={getValues}
            onClose={props.onClose}
            monetaryErosions={monetaryErosions}
            onPrevious={onPrevious ? onPrevious : undefined}
            handleSubmit={handleSubmit}
            dateConsolidation={dateConsolidation}
            dateLiquidation={dateLiquidation}
            tauxIPP={null}
            allNumerosPieces={allNumerosPieces}
            user={props.user}
          />
        </Stack>
      )}
    />
  );
};
