import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { Rentes } from './Rentes';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';

interface Props<
  TFieldValues extends FieldValues,
  TRenteMontantName extends FieldPath<TFieldValues> & `${string}.montant`,
  TisRentesOptionName extends FieldPath<TFieldValues> & 'isRentesOption',
  TEditedFieldsName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  name: {
    renteMontant: TRenteMontantName;
    isRentesOption: TisRentesOptionName;
    editedFields: TEditedFieldsName;
  };
  renteDefaultValue?: number;
  titles?: {
    renteOption?: string;
    renteMontant?: string;
    renteMontantTrimestriel?: string;
    renteMontantMensuel?: string;
    renteDefaultValue?: string;
  };
  isReadonly?: boolean;
}
export const RentesOption = <
  TFieldValues extends FieldValues,
  TRenteMontantName extends FieldPath<TFieldValues> & `${string}.montant`,
  TisRentesOptionName extends FieldPath<TFieldValues> & 'isRentesOption',
  TEditionFieldsName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  titles,
  isReadonly,
  renteDefaultValue,
}: Props<
  TFieldValues,
  TRenteMontantName,
  TisRentesOptionName,
  TEditionFieldsName
>) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <CalculsBox>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">
            {titles?.renteOption ||
              t('prejudice.rentes.form.fields.isRentesOption.label')}
          </Typography>
          <CheckboxFormField control={control} name={name.isRentesOption} />
        </Stack>
      </CalculsBox>
      <ComputedPropsForm
        control={control}
        watchFields={[name.isRentesOption]}
        customValues={[renteDefaultValue]}
        compute={([isRentesOption], customValues) => ({
          props: {
            renteValue: customValues?.[0] || 0,
            hidden: !isRentesOption,
          },
        })}
        render={({ renteValue, hidden }) => (
          <CalculsBox sx={{ display: hidden ? 'none' : undefined }}>
            <Rentes
              {...{
                control,
                name,
                titles,
                isReadonly,
                renteDefaultValue: renteValue,
              }}
            />
          </CalculsBox>
        )}
      />
    </Stack>
  );
};
