import {
  AlignmentType,
  HeightRule,
  INumberingOptions,
  IParagraphOptions,
  IRunOptions,
  ITableCellOptions,
  ITableRowOptions,
  LevelFormat,
  LevelSuffix,
  Paragraph,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
} from 'docx';
import { CalculsGlobal } from 'src/constants/calculs';

const defaultTextRunOptions: IRunOptions = {
  font: 'Helvetica',
};

const defaultParagraphOptions: IParagraphOptions = {};

export const getTextRun = (options?: IRunOptions | string): TextRun[] => {
  const text = typeof options === 'string' ? options : options?.text;
  if (text) {
    return text.split('\n').map(
      (text, index) =>
        new TextRun({
          ...defaultTextRunOptions,
          ...(typeof options !== 'string' ? options : {}),
          text,
          break: index === 0 ? undefined : 1,
        }),
    );
  } else if (typeof options !== 'string') {
    return [
      new TextRun({
        ...defaultTextRunOptions,
        ...options,
      }),
    ];
  } else {
    return [
      new TextRun({
        ...defaultTextRunOptions,
      }),
    ];
  }
};

export const getParagraph = (
  options: string | IParagraphOptions,
): Paragraph => {
  if (typeof options === 'string') {
    return new Paragraph({
      ...defaultParagraphOptions,
      children: getTextRun({ text: options }),
    });
  } else {
    const { children, text, ...optionsWithoutText } = options;
    return new Paragraph({
      ...defaultParagraphOptions,
      ...optionsWithoutText,
      children: [
        ...(children ?? []),
        ...(text ? getTextRun({ text: text ?? '' }) : []),
      ],
    });
  }
};

export const getTableRow = (options: ITableRowOptions): TableRow =>
  new TableRow({
    ...options,
    height: {
      value: 400,
      rule: HeightRule.ATLEAST,
    },
  });

export const getTableCell = (options: ITableCellOptions): TableCell =>
  new TableCell({
    ...options,
    verticalAlign: VerticalAlign.CENTER,
  });

export const getHeaderTableCell = (
  text: string,
  options?: Omit<ITableCellOptions, 'children'>,
  paragraphOptions?: IParagraphOptions,
  textOptions?: IRunOptions,
): TableCell =>
  new TableCell({
    ...options,
    verticalAlign: VerticalAlign.CENTER,
    children: text.split('\n').map((text) =>
      getParagraph({
        ...paragraphOptions,
        children: getTextRun({ text, bold: true, ...textOptions }),
      }),
    ),
  });

export const getValueTableCell = (
  text: string,
  options?: Omit<ITableCellOptions, 'children'>,
  paragraphOptions?: IParagraphOptions,
  textOptions?: IRunOptions,
): TableCell =>
  new TableCell({
    ...options,
    verticalAlign: VerticalAlign.CENTER,
    children: text.split('\n').map((text) =>
      getParagraph({
        ...paragraphOptions,
        alignment: AlignmentType.CENTER,
        children: getTextRun({ ...textOptions, text }),
      }),
    ),
  });

export const getEmptyLine = (numberOfLines = 1): Paragraph =>
  new Paragraph({
    children: [
      new TextRun({ break: CalculsGlobal.max([numberOfLines - 1, 0]) }),
    ],
  });

export enum NumberingType {
  LOWERCASE_LETTERS_PARENTHESIS = 'lowercase-letters-parenthesis',
  SIMPLE_LIST = 'simple-list',
  NUMBERS_DOT_SIMPLE = 'numbers-dot-simple',
  ROMAN_NUMERALS = 'roman-numerals',
}

export const defaultNumberingConfigs: INumberingOptions['config'] = [
  {
    reference: NumberingType.LOWERCASE_LETTERS_PARENTHESIS,
    levels: [
      {
        level: 0,
        format: LevelFormat.LOWER_LETTER,
        text: '%1)',
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            ...defaultParagraphOptions,
            spacing: {
              after: 130,
            },
            indent: {
              left: 720,
              hanging: 260,
            },
          },
          run: defaultTextRunOptions,
        },
      },
    ],
  },
  {
    reference: NumberingType.SIMPLE_LIST,
    levels: [
      {
        level: 0,
        format: LevelFormat.BULLET,
        text: '-',
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            ...defaultParagraphOptions,
            spacing: {
              after: 130,
            },
            indent: {
              left: 720,
              hanging: 260,
            },
          },
          run: defaultTextRunOptions,
        },
      },
      {
        level: 1,
        format: LevelFormat.BULLET,
        text: '-',
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            ...defaultParagraphOptions,
            spacing: {
              after: 60,
            },
            indent: {
              left: 1080,
              hanging: 130,
            },
          },
          run: defaultTextRunOptions,
        },
      },
    ],
  },
  {
    reference: NumberingType.NUMBERS_DOT_SIMPLE,
    levels: [
      {
        level: 0,
        format: LevelFormat.DECIMAL,
        text: '%1.',
        alignment: AlignmentType.START,
        suffix: LevelSuffix.SPACE,
        style: {
          paragraph: {
            ...defaultParagraphOptions,
            spacing: {
              after: 130,
            },
          },
          run: defaultTextRunOptions,
        },
      },
      {
        level: 1,
        format: LevelFormat.LOWER_LETTER,
        text: '%1%2.',
        alignment: AlignmentType.START,
        suffix: LevelSuffix.SPACE,
        style: {
          paragraph: {
            ...defaultParagraphOptions,
            spacing: {
              after: 130,
            },
          },
          run: defaultTextRunOptions,
        },
      },
    ],
  },
  {
    reference: NumberingType.ROMAN_NUMERALS,
    levels: [
      {
        level: 0,
        format: LevelFormat.UPPER_ROMAN,
        text: '%1.',
        alignment: AlignmentType.START,
        suffix: LevelSuffix.SPACE,
        style: {
          paragraph: {
            ...defaultParagraphOptions,
            spacing: {
              after: 130,
            },
          },
          run: defaultTextRunOptions,
        },
      },
      {
        level: 1,
        format: LevelFormat.DECIMAL,
        text: '%2.',
        alignment: AlignmentType.START,
        suffix: LevelSuffix.SPACE,
        style: {
          paragraph: {
            ...defaultParagraphOptions,
            spacing: {
              after: 130,
            },
          },
          run: defaultTextRunOptions,
        },
      },
    ],
  },
];
