import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps, Tooltip, styled } from '@mui/material';
import {
  useController,
  UseControllerProps,
  useWatch,
  FieldValues,
  FieldPath,
  FieldPathValues,
  FieldPathValue,
} from 'react-hook-form';

/* Styled components */
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-adornedStart': {
    paddingLeft: theme.spacing(1),
  },
}));

/* Component */

interface Props<
  TFieldValues extends FieldValues,
  TFieldNames extends readonly FieldPath<TFieldValues>[],
  TName extends FieldPath<TFieldValues>,
  TCustomValues extends any[] = any[],
> extends UseControllerProps<TFieldValues, TName> {
  watchFields: readonly [...TFieldNames];
  customValues?: TCustomValues;
  computeTooltip?: (
    values: FieldPathValues<TFieldValues, TFieldNames>,
    fieldValue?: FieldPathValue<TFieldValues, TName>,
    customValues?: TCustomValues,
  ) => React.ReactNode;
  forcedError?: string;
}

export const TooltipTextFieldForm = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldNames extends
    readonly FieldPath<TFieldValues>[] = readonly FieldPath<TFieldValues>[],
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TCustomValues extends any[] = any[],
>({
  name,
  control,
  watchFields,
  customValues,
  computeTooltip,
  rules,
  shouldUnregister,
  defaultValue,
  forcedError,
  ...props
}: Props<TFieldValues, TFieldNames, TName, TCustomValues> & TextFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, shouldUnregister, defaultValue });
  const [computeTooltipValue, setComputeTooltipValue] =
    useState<React.ReactNode>('');

  const watchValues = useWatch({
    control,
    name: watchFields as any,
  });

  useEffect(() => {
    if (computeTooltip) {
      setComputeTooltipValue(
        computeTooltip(watchValues, field.value, customValues),
      );
    }
  }, [watchValues, field.value, customValues]);

  return (
    <Tooltip
      title={forcedError || error?.message ? '' : computeTooltipValue}
      placement="top"
    >
      <Tooltip title={forcedError || error?.message || ''}>
        <StyledTextField
          {...field}
          {...props}
          error={!!forcedError || !!error}
          InputProps={{
            ...props.InputProps,
            sx: {
              ...props.InputProps?.sx,
            },
          }}
        />
      </Tooltip>
    </Tooltip>
  );
};
