import React from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { Bareme, BaremeCapitalisationValue } from 'src/types/bareme.type';
import { ComputedTextFieldForm } from '../../../forms/ComputedTextFieldForm';
import {
  getAgeDateAttribution,
  getCapitalisationCoefficient,
} from 'src/helpers/prejudices/capitalisation';
import { IMaskNumberProps, MaskNumber } from 'src/components/masks/MaskNumber';
import { CoefficientUnavailableInBaremeTooltipText } from './CoefficientUnavailableInBaremeTooltipText';
import { CalculsGlobal } from 'src/constants/calculs';
import { CapitalisationDiffereeTooltipText } from './CapitalisationDiffereeTooltipText';

interface Props<
  TFieldValues extends FieldValues,
  TBaremeName extends FieldPath<TFieldValues>,
  TAgeDernierArreragename extends FieldPath<TFieldValues>,
  TIsLastArrerageViagerName extends FieldPath<TFieldValues>,
  TAgeDateAttributionName extends FieldPath<TFieldValues>,
  TCoefficientName extends FieldPath<TFieldValues>,
  TEnableCapitalisationDiffereeName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues>;
  baremeName: TBaremeName;
  ageDernierArrerageName: TAgeDernierArreragename;
  isLastArrerageViagerName: TIsLastArrerageViagerName;
  ageDateAttribution: number | undefined | null;
  editedFieldsName: TEditedFieldsName;
  baremes: Bareme[];
  sexe: 'm' | 'f' | 'u';
  ageDateAttributionName?: TAgeDateAttributionName;
  coefficientName: TCoefficientName;
  enableCapitalisationDiffereeName?: TEnableCapitalisationDiffereeName;
  dateNaissance: Date | null | undefined;
  dateLiquidation: Date | null | undefined;
  dateDeces: Date | null | undefined;
  dateAttribution: Date | string | null | undefined;
}

export const Coefficient = <
  TFieldValues extends FieldValues,
  TBaremeName extends FieldPath<TFieldValues>,
  TAgeDernierArreragename extends FieldPath<TFieldValues>,
  TIsLastArrerageViagerName extends FieldPath<TFieldValues>,
  TAgeDateAttributionName extends FieldPath<TFieldValues>,
  TEnableCapitalisationDiffereeName extends FieldPath<TFieldValues>,
  TCoefficientName extends FieldPath<TFieldValues>,
  TEditedFieldsName extends FieldPath<TFieldValues>,
>({
  control,
  baremes,
  sexe,
  baremeName,
  ageDernierArrerageName,
  isLastArrerageViagerName,
  coefficientName,
  enableCapitalisationDiffereeName,
  ageDateAttribution,
  dateNaissance,
  dateLiquidation,
  editedFieldsName,
  dateDeces,
  dateAttribution,
  ageDateAttributionName,
}: Props<
  TFieldValues,
  TBaremeName,
  TAgeDernierArreragename,
  TIsLastArrerageViagerName,
  TAgeDateAttributionName,
  TEnableCapitalisationDiffereeName,
  TCoefficientName,
  TEditedFieldsName
>) => {
  return (
    <ComputedPropsForm
      control={control}
      watchFields={[baremeName]}
      compute={([baremeCapitalisation]) => ({
        props: {
          displayCoefficient: !(
            baremes.find((bareme) => bareme._id === baremeCapitalisation)
              ?.source === 'CJ'
          ),
        },
      })}
      render={({ displayCoefficient }) =>
        displayCoefficient ? (
          <ComputedTextFieldForm
            control={control}
            name={coefficientName}
            watchFields={[
              ageDernierArrerageName,
              isLastArrerageViagerName,
              baremeName,
              ...(enableCapitalisationDiffereeName
                ? [enableCapitalisationDiffereeName]
                : []),
              ...(ageDateAttributionName ? [ageDateAttributionName] : []),
            ]}
            customValues={[ageDateAttribution, dateAttribution] as const}
            compute={(
              [
                ageDernierArrerage,
                isLastArrerageViager,
                baremeCapitalisation,
                ...watchedValues
              ],
              customValues,
            ) => {
              const watchedEnableCapitalisationDifferee: boolean | undefined =
                watchedValues.length === 2 ? watchedValues[0] : undefined;
              const watchedAgeDateAttribution: number | undefined =
                watchedValues.length === 2
                  ? watchedValues[1]
                  : watchedValues[0];
              const bareme = baremes.find(
                (bareme) => bareme._id === baremeCapitalisation,
              );
              if (bareme && dateNaissance && dateLiquidation) {
                const ageDateAttribution: number =
                  watchedAgeDateAttribution || watchedAgeDateAttribution === 0
                    ? watchedAgeDateAttribution
                    : customValues?.[0] || customValues?.[0] === 0
                      ? customValues[0]
                      : getAgeDateAttribution({
                          dateNaissance,
                          dateLiquidation,
                          dateDeces,
                          dateAttribution,
                        })?.age ?? 0;
                return getCapitalisationCoefficient({
                  sexe,
                  baremeValues: bareme.values as BaremeCapitalisationValue[],
                  ageDateAttribution,
                  ageDernierArrerage: ageDernierArrerage || null,
                  isDernierArrerageViager: isLastArrerageViager,
                  dateNaissance,
                  dateLiquidation,
                  enableCapitalisationDifferee:
                    watchedEnableCapitalisationDifferee &&
                    bareme.source !== 'CJ',
                });
              }
              return null;
            }}
            computeTooltip={(
              [
                ageDernierArrerage,
                isLastArrerageViager,
                baremeCapitalisation,
                ...watchedValues
              ],
              fieldValue,
              customValues,
            ) => {
              const watchedEnableCapitalisationDifferee: boolean | undefined =
                watchedValues.length === 2 ? watchedValues[0] : undefined;
              const watchedAgeDateAttribution: number | undefined =
                watchedValues.length === 2
                  ? watchedValues[1]
                  : watchedValues[0];
              const ageDateAttribution: number =
                watchedAgeDateAttribution || watchedAgeDateAttribution === 0
                  ? watchedAgeDateAttribution
                  : customValues?.[0] || customValues?.[0] === 0
                    ? customValues[0]
                    : getAgeDateAttribution({
                        dateNaissance,
                        dateLiquidation,
                        dateDeces,
                        dateAttribution,
                      })?.age ?? 0;
              const bareme = baremes?.find(
                (bareme) => bareme._id === baremeCapitalisation,
              );
              const ageLiquidation =
                dateLiquidation && dateNaissance
                  ? CalculsGlobal.getAge(dateNaissance, dateLiquidation)
                  : null;
              const difference =
                ageLiquidation !== null
                  ? ageDateAttribution - ageLiquidation
                  : null;
              const isCapitalisationDifferee =
                bareme?.source !== 'CJ' &&
                watchedEnableCapitalisationDifferee &&
                difference !== null &&
                difference > 0;
              if (
                !!bareme &&
                bareme.source !== 'CJ' &&
                !!dateNaissance &&
                !!dateLiquidation &&
                (!!ageDernierArrerage || isLastArrerageViager) &&
                fieldValue === null
              ) {
                return (
                  <CoefficientUnavailableInBaremeTooltipText
                    isCapitalisationDifferee={isCapitalisationDifferee}
                  />
                );
              }
              if (isCapitalisationDifferee) {
                return <CapitalisationDiffereeTooltipText />;
              }
            }}
            InputProps={{
              inputComponent: MaskNumber as any,
              inputProps: {
                numberMask: { scale: 3 },
                nullable: true,
              } as IMaskNumberProps,
            }}
            sx={{ minWidth: 268 }}
            editedFieldsName={editedFieldsName}
          />
        ) : (
          <></>
        )
      }
    />
  );
};
