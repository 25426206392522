/* A hook to check wether or not a user is subscribed to Norma, wether as an individual (legacy) or as part of an organization. */

import { RootState } from 'src/store';
import { useAppSelector } from '../store';
import { useDisplayedOrganization } from './organization.hooks';
import { isSubscriptionValid } from 'src/helpers/subscription';

// TODO Remove this when migration to organizations is complete
export const useIsUserSubscribed = (): boolean => {
  const { user } = useAppSelector(({ auth: { user } }: RootState) => ({
    user,
  }));
  return isSubscriptionValid(user?.subscription);
};

export const useIsSubscribed = (): boolean => {
  const { user } = useAppSelector(({ auth: { user } }: RootState) => ({
    user,
  }));
  const { organization } = useDisplayedOrganization();
  if (isSubscriptionValid(user?.subscription)) {
    return true;
  } else if (organization && isSubscriptionValid(organization.subscription)) {
    return true;
  }
  return false;
};
