import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckboxFormField } from 'src/components/forms/CheckboxFormField';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import { RadioFormField } from 'src/components/forms/RadioFieldForm';
import { ReadFieldForm } from 'src/components/forms/ReadFieldForm';
import { TooltipTextFieldForm } from 'src/components/forms/TooltipTextFieldForm';
import { CalculsFormCalendrier } from 'src/constants/calculs';
import { getDefaultMinAndMaxDate } from 'src/constants/prejudice/validation';
import { prejudicesData } from 'src/constants/prejudices';
import { fCurrency } from 'src/helpers/formatNumber';
import { getRevalorisationTooltip } from 'src/helpers/prejudices/revalorisation';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import {
  NumeroPieceValuesRow,
  PrejudiceFormCalendrierValeur,
  PrejudiceFormCalendrierValeurRow,
  PrejudiceFormMateriel,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { DatePickerForm } from '../../forms/DatePickerForm';
import { TextFieldForm } from '../../forms/TextFieldForm';
import { IMaskNumberProps, MaskNumber } from '../../masks/MaskNumber';
import { NormalTable, SpanNoWrap } from '../../styled';
import { FormNumeroPieceDialog } from './FormNumeroPieceDialog';

export const getDefaultCalendrierValeursRow = (
  noRevalorisation?: boolean,
): Omit<PrejudiceFormCalendrierValeurRow, 'type'> => ({
  intitule: '',
  date: null,
  montant: 0,
  ...(!noRevalorisation
    ? {
        montantsDejaRevalorises: true,
      }
    : {}),
  numerosPieces: { rows: [] },
});

interface Props {
  control: Control<PrejudiceFormCalendrierValeur | PrejudiceFormMateriel>;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation?: string | undefined | null;
  noRevalorisation?: boolean;
  procedure: Procedure;
  victime: Victime;
  prejudiceType: PrejudiceType;
  allNumerosPieces: NumeroPieceValuesRow[];
}

export const TableValeurs: React.FC<Props> = ({
  control,
  monetaryErosions,
  dateLiquidation,
  noRevalorisation,
  procedure,
  victime,
  prejudiceType,
  allNumerosPieces,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen =
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
    setAnchorEl(null);
  };

  const { fields, append, insert, remove } = useFieldArray({
    name: 'rows',
    control: control as Control<PrejudiceFormCalendrierValeur>,
  });

  const {
    minDate: { date: minDate },
    maxDate: { date: maxDate },
  } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });

  const handleDelete = (index: number) => {
    remove(index);
    handleMenuClose();
  };

  const duplicateRow = (index: number) => {
    const rowToDuplicate = fields[index];
    insert(index, {
      ...rowToDuplicate,
    } as PrejudiceFormCalendrierValeurRow);
    handleMenuClose();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <NormalTable size="medium">
          <TableHead>
            {prejudiceType === 'PREJUDICE_MATERIEL' ? (
              <TableRow>
                <TableCell align="left" colSpan={2}>
                  <FormControl>
                    <FormLabel
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {t(
                        'prejudice.revalorisation.coefficientsType.choice.label',
                      )}
                    </FormLabel>
                    <RadioFormField
                      control={control as Control<PrejudiceFormMateriel>}
                      name="revalorisationCoefficientsType"
                      options={[
                        {
                          label: t(
                            'prejudice.revalorisation.coefficientsType.choice.options.mensuel',
                          ),
                          value: 'mensuel',
                        },
                        {
                          label: t(
                            'prejudice.revalorisation.coefficientsType.choice.options.annuel',
                          ),
                          value: 'annuel',
                        },
                      ]}
                      row={true}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>{t('numeroPiece.form.columnHeader')}</TableCell>
              <TableCell sx={{ minWidth: 300 }}>
                {t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.intitule.columnHeader',
                )}
              </TableCell>
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.date.columnHeader',
                )}
              </TableCell>
              {!noRevalorisation && (
                <TableCell align="center">
                  {t(
                    'prejudice.revalorisation.columnHeaders.montantsDejaRevalorises',
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                {t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.montant.columnHeader',
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <FormNumeroPieceDialog
                    control={control}
                    name={`rows.${index}.numerosPieces`}
                    allOtherNumeroPieces={allNumerosPieces}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldForm
                    control={control}
                    name={`rows.${index}.intitule`}
                    fullWidth
                  />
                </TableCell>
                <TableCell align="center">
                  <DatePickerForm
                    control={control}
                    name={`rows.${index}.date`}
                    label={t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.date.label',
                    )}
                    TextFieldProps={{
                      InputLabelProps: { shrink: true },
                      sx: {
                        minWidth: 155,
                        width: 155,
                      },
                    }}
                    minDate={
                      prejudiceType !== 'PREJUDICE_MATERIEL'
                        ? minDate
                        : undefined
                    }
                    maxDate={
                      prejudiceType !== 'PREJUDICE_MATERIEL'
                        ? maxDate
                        : undefined
                    }
                  />
                </TableCell>
                {!noRevalorisation && (
                  <TableCell align="center">
                    <CheckboxFormField
                      control={control}
                      name={`rows.${index}.montantsDejaRevalorises`}
                    />
                  </TableCell>
                )}
                <TableCell align="right">
                  {prejudiceType === 'PREJUDICE_MATERIEL' ? (
                    <TooltipTextFieldForm
                      control={control}
                      name={`rows.${index}.montant`}
                      watchFields={[
                        `rows.${index}.montantsDejaRevalorises`,
                        `rows.${index}.date`,
                        'revalorisationCoefficientsType',
                      ]}
                      computeTooltip={(
                        [
                          montantsDejaRevalorises,
                          date,
                          revalorisationCoefficientsType,
                        ],
                        fieldValue,
                      ) => {
                        if (!noRevalorisation) {
                          return getRevalorisationTooltip({
                            anneeOrDateMontant: date || undefined,
                            montant: Number(fieldValue),
                            monetaryErosions,
                            anneeOrDateLiquidation:
                              dateLiquidation || undefined,
                            montantsDejaRevalorises:
                              montantsDejaRevalorises || false,
                            coefficientsType: revalorisationCoefficientsType,
                          });
                        }
                        return '';
                      }}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          suffix: '€',
                          numberMask: { scale: 2 },
                        } as IMaskNumberProps,
                      }}
                    />
                  ) : (
                    <TooltipTextFieldForm
                      control={control}
                      name={`rows.${index}.montant`}
                      watchFields={[
                        `rows.${index}.montantsDejaRevalorises`,
                        `rows.${index}.date`,
                      ]}
                      computeTooltip={(
                        [montantsDejaRevalorises, date],
                        fieldValue,
                      ) => {
                        if (!noRevalorisation) {
                          return getRevalorisationTooltip({
                            anneeOrDateMontant: date || undefined,
                            montant: Number(fieldValue),
                            monetaryErosions,
                            anneeOrDateLiquidation:
                              dateLiquidation || undefined,
                            montantsDejaRevalorises:
                              montantsDejaRevalorises || false,
                          });
                        }
                        return '';
                      }}
                      InputProps={{
                        inputComponent: MaskNumber as any,
                        inputProps: {
                          suffix: '€',
                          numberMask: {
                            scale: 2,
                          },
                        } as IMaskNumberProps,
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={handleMenuOpen(index)}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuIndex === index}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleDelete(index)}>
                      {t('forms.table.deleteRow', { index: index + 1 })}
                    </MenuItem>
                    <MenuItem onClick={() => duplicateRow(index)}>
                      {t('forms.table.duplicateRow', { index: index + 1 })}
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
            {prejudiceType === 'PREJUDICE_MATERIEL' ? (
              <ComputedReadFieldForm
                control={control as Control<PrejudiceFormMateriel>}
                name="total"
                watchFields={['rows', 'revalorisationCoefficientsType']}
                compute={([rows, revalorisationCoefficientsType]) =>
                  CalculsFormCalendrier.getTotalCalendrierValeur({
                    rows,
                    revalorisationCoefficientsType,
                    monetaryErosions,
                    dateLiquidation,
                    noRevalorisation,
                  })
                }
              />
            ) : (
              <ComputedReadFieldForm
                control={control}
                name="total"
                watchFields={['rows']}
                compute={([rows]) =>
                  CalculsFormCalendrier.getTotalCalendrierValeur({
                    rows,
                    monetaryErosions,
                    dateLiquidation,
                    noRevalorisation,
                  })
                }
              />
            )}
          </TableBody>
          {prejudicesData[prejudiceType || 'DEPENSE_SANTE_FUTURES']
            .displayTotalPartResponsabilite ? null : (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3 + (!noRevalorisation ? 1 : 0)} />
                <TableCell align="right">
                  <ReadFieldForm
                    control={control}
                    name="total"
                    render={(value) => (
                      <SpanNoWrap>
                        {t(
                          'prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.fields.total.value',
                          { value: fCurrency(value) },
                        )}
                      </SpanNoWrap>
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </NormalTable>
      </TableContainer>
      <Stack direction="row" spacing={4} alignSelf="center">
        <Button
          onClick={() => {
            append(getDefaultCalendrierValeursRow(noRevalorisation));
          }}
        >
          {t('prejudice.prejudicesFormTypes.CALENDRIER_VALEURS.table.addRow')}
        </Button>
      </Stack>
    </>
  );
};
