import {
  baremeCapitalisationSourcesOrder,
  baremeIndemnisationSourcesOrder,
  baremeIndmenisationAdditionnalPropertiesPrejudiceOrder,
  baremeRIISourcesOrder,
} from 'src/constants/bareme';
import { Bareme } from 'src/types/bareme.type';

const getBaremeIndemnisationRank = (bareme: Bareme) =>
  baremeIndemnisationSourcesOrder[bareme.source] ||
  0 * 1000 +
    (bareme.additionalProperties?.prejudice
      ? baremeIndmenisationAdditionnalPropertiesPrejudiceOrder[
          bareme.additionalProperties.prejudice
        ] || 0
      : 0);

export const sortBaremesIndemnisation = (baremes: Bareme[]) =>
  baremes.sort(
    (baremeA, baremeB) =>
      getBaremeIndemnisationRank(baremeA) - getBaremeIndemnisationRank(baremeB),
  );

export const getBaremeRIIRank = (bareme: Bareme) =>
  baremeRIISourcesOrder[bareme.source] || 0;

export const sortBaremesRII = (baremes: Bareme[]) =>
  baremes.sort(
    (baremeA, baremeB) => getBaremeRIIRank(baremeA) - getBaremeRIIRank(baremeB),
  );

export const getBaremeCapitalisationRank = (bareme: Bareme) =>
  baremeCapitalisationSourcesOrder[bareme.source] || 0;

export const sortBaremesCapitalisation = (baremes: Bareme[]) =>
  baremes.sort((baremeA, baremeB) => {
    const baremeARank = getBaremeCapitalisationRank(baremeA);
    const baremeBRank = getBaremeCapitalisationRank(baremeB);
    if (baremeARank !== baremeBRank) {
      return baremeARank - baremeBRank;
    }
    if (baremeA.year !== baremeB.year) {
      return baremeA.year - baremeB.year;
    }
    return (
      (baremeA.additionalProperties?.taux || 0) -
      (baremeB.additionalProperties?.taux || 0)
    );
  });
