import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AcceptConditions } from 'src/components/authentication/AcceptConditions';
import { daysFreeTrial, subscriptionPrice } from 'src/constants/subscription';
import { fCurrency } from 'src/helpers/formatNumber';
import { createSubscriptionMandate } from 'src/helpers/subscription';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';

const SubscriptionOptions: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organization, organizationId } = useDisplayedOrganization();
  const { enqueueSnackbar } = useSnackbar();
  const [isConditionsAccepted, setIsConditionsAccepted] = useState(false);
  const onClickSubscribeAutomaticSubscriptionPayment = async () => {
    if (!organization?._id) {
      return;
    }
    try {
      const authorisationUrl = await createSubscriptionMandate(
        organization?._id,
      );
      window.location.href = authorisationUrl;
    } catch (e) {
      enqueueSnackbar(t('pages.SubscriptionOptions.automatic.error'), {
        variant: 'error',
      });
    }
  };
  const onClickGoToQuote = () => {
    if (!organizationId) {
      return;
    }
    navigate(`/organization/${organizationId}/quote`);
  };
  const isFreeTrialAvailable =
    !organization?.subscription?.isFreeTrialAlreadyUsed;
  if (!organization) {
    return null;
  }
  return (
    <Stack paddingTop={2} maxWidth={600} marginX="auto">
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography variant="h5" gutterBottom>
          {isFreeTrialAvailable
            ? t('pages.SubscriptionOptions.automatic.title.freeTrial', {
                daysFreeTrial,
              })
            : t('pages.SubscriptionOptions.automatic.title.noFreeTrial')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {isFreeTrialAvailable
            ? t('pages.SubscriptionOptions.automatic.price.freeTrial', {
                yearlyPrice: fCurrency(subscriptionPrice * 12),
                monthlyPrice: fCurrency(subscriptionPrice),
              })
            : t('pages.SubscriptionOptions.automatic.price.noFreeTrial', {
                yearlyPrice: fCurrency(subscriptionPrice * 12),
                monthlyPrice: fCurrency(subscriptionPrice),
              })}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {isFreeTrialAvailable
            ? t('pages.SubscriptionOptions.automatic.description.freeTrial')
            : t('pages.SubscriptionOptions.automatic.description.noFreeTrial')}
        </Typography>
        <AcceptConditions
          checked={isConditionsAccepted}
          onClickCheck={() => setIsConditionsAccepted(!isConditionsAccepted)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onClickSubscribeAutomaticSubscriptionPayment}
          disabled={!isConditionsAccepted}
        >
          {isFreeTrialAvailable
            ? t('pages.SubscriptionOptions.automatic.button.freeTrial')
            : t('pages.SubscriptionOptions.automatic.button.noFreeTrial')}
        </Button>
      </Stack>
      <Button
        variant="outlined"
        color="primary"
        sx={{ alignSelf: 'end', marginTop: 4 }}
        onClick={onClickGoToQuote}
      >
        {t('pages.SubscriptionOptions.manual.button')}
      </Button>
    </Stack>
  );
};

export default SubscriptionOptions;
