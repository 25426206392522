import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Control,
  FieldArrayPath,
  FieldPath,
  FieldValues,
  useFieldArray,
  useFormState,
} from 'react-hook-form';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { Add, Cancel, Delete } from '@mui/icons-material';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { alpha } from '@mui/material';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import {
  NumeroPieceValues,
  NumeroPieceValuesRow,
} from 'src/types/prejudice.type';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { AutocompleteForm } from 'src/components/forms/AutocompleteForm';

interface Props<
  TFieldValues extends FieldValues,
  TNumeroPieceName extends FieldPath<TFieldValues>,
  TNumeroPieceRowsName extends FieldArrayPath<TFieldValues> & `${string}.rows`,
> {
  control: Control<TFieldValues>;
  name: TNumeroPieceName;
  numeroPieceRowsFieldName?: TNumeroPieceRowsName;
  displayNumeroPieceText?: boolean;
  displayShortPieceText?: boolean;
  allOtherNumeroPieces: NumeroPieceValuesRow[];
  renderNumeroPiece?: (value: string) => string;
}

export const FormNumeroPieceDialog = <
  TFieldValues extends FieldValues,
  TNumeroPieceName extends FieldPath<TFieldValues>,
  TNumeroPieceRowsName extends FieldArrayPath<TFieldValues> & `${string}.rows`,
  TNumeroPieceNumeroName extends FieldPath<TFieldValues> &
    `${string}.rows.${number}.numeroPiece`,
  TNumeroPieceLibelleName extends FieldPath<TFieldValues> &
    `${string}.rows.${number}.libelle`,
>({
  control,
  name,
  numeroPieceRowsFieldName = `${name}.rows` as TNumeroPieceRowsName,
  displayNumeroPieceText,
  displayShortPieceText,
  allOtherNumeroPieces,
  renderNumeroPiece,
}: Props<TFieldValues, TNumeroPieceName, TNumeroPieceRowsName>) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { errors } = useFormState({
    control,
    name,
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: numeroPieceRowsFieldName,
  });
  const onClose = () => {
    setOpen(false);
  };
  const onAdd = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    append({ numerosPieces: '', libelle: '' });
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  const reset = () => {
    replace([]);
  };
  const error = get(errors, name);
  return (
    <>
      <ComputedPropsForm
        control={control as any}
        watchFields={[name]}
        compute={([numerosPieces]) => ({
          props: {
            numerosPieces,
          },
        })}
        render={({ numerosPieces: untypedValue }) => {
          const value = untypedValue as NumeroPieceValues;
          const isValueDefined = value?.rows?.length && value.rows.length > 0;
          const displayError = error && isValueDefined;
          return (
            <Tooltip
              title={displayNumeroPiece(value, false)}
              placement={isValueDefined && !displayError ? 'top' : 'bottom'}
            >
              <Stack
                sx={(theme) => ({
                  padding: 1,
                  minHeight: '56px',
                  minWidth: '115px',
                  ':hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    borderRadius: '8px',
                  },
                  cursor: 'pointer',
                })}
                onClick={() => {
                  if (!value || !value.rows || value.rows.length === 0) {
                    onAdd();
                  }
                  setOpen(true);
                }}
                direction="row"
                alignItems="center"
                justifyContent="start"
              >
                {!isValueDefined ? (
                  <Add color="primary" sx={{ marginRight: 1 }} />
                ) : null}
                <Typography
                  color={displayError ? 'error' : 'primary'}
                  textOverflow="hidden"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {isValueDefined
                    ? renderNumeroPiece
                      ? renderNumeroPiece(displayNumeroPiece(value))
                      : displayNumeroPieceText
                        ? t(`numeroPiece.form.numeroPiece.renderLong`, {
                            numeroPiece: displayNumeroPiece(value),
                          })
                        : displayShortPieceText
                          ? t(`numeroPiece.form.numeroPiece.renderShort`, {
                              numeroPiece: displayNumeroPiece(value),
                            })
                          : displayNumeroPiece(value)
                    : t(`numeroPiece.form.add`)}
                </Typography>
                {isValueDefined ? (
                  <IconButton
                    color={error ? 'error' : 'primary'}
                    sx={{ padding: 0, paddingLeft: 0.5 }}
                    onClick={(e) => {
                      reset();
                      e.stopPropagation();
                    }}
                  >
                    <Cancel />
                  </IconButton>
                ) : null}
              </Stack>
            </Tooltip>
          );
        }}
      />
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t(`numeroPiece.form.title`)}</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <NormalTable>
            <TableHead>
              <TableRow>
                <TableCell>{t(`numeroPiece.form.numeroPiece.label`)}</TableCell>
                <TableCell>{t(`numeroPiece.form.libelle.label`)}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map(({ id }, index) => (
                <TableRow key={id}>
                  <TableCell>
                    <TextFieldForm
                      name={
                        `${numeroPieceRowsFieldName}.${index}.numeroPiece` as TNumeroPieceNumeroName
                      }
                      control={control}
                      label={t(`numeroPiece.form.numeroPiece.label`)}
                      required
                      autoFocus={fields.length === 1}
                    />
                  </TableCell>
                  <TableCell>
                    <ComputedPropsForm
                      control={control as any}
                      watchFields={[
                        `${numeroPieceRowsFieldName}.${index}.numeroPiece`,
                      ]}
                      compute={([numeroPiece]) => ({
                        props: {
                          libellesOptions: allOtherNumeroPieces
                            .filter(
                              (numeroPieceOption) =>
                                numeroPieceOption.numeroPiece === numeroPiece,
                            )
                            .map(({ libelle }) => libelle),
                        },
                      })}
                      render={({ libellesOptions }) =>
                        libellesOptions.length > 0 ? (
                          <AutocompleteForm
                            name={
                              `${numeroPieceRowsFieldName}.${index}.libelle` as TNumeroPieceLibelleName
                            }
                            control={control}
                            TextFieldProps={{
                              label: t(`numeroPiece.form.libelle.label`),
                              required: true,
                            }}
                            sx={{ minWidth: 200 }}
                            options={libellesOptions}
                            freeSolo
                          />
                        ) : (
                          <TextFieldForm
                            name={
                              `${numeroPieceRowsFieldName}.${index}.libelle` as TNumeroPieceLibelleName
                            }
                            control={control}
                            label={t(`numeroPiece.form.libelle.label`)}
                            required
                            sx={{ minWidth: 200 }}
                          />
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        onRemove(index);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </NormalTable>
          <Button
            onClick={onAdd}
            sx={{ marginTop: 1, alignSelf: 'center' }}
            startIcon={<Add />}
            variant="outlined"
          >
            {t(`numeroPiece.form.add`)}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t(`common.save`)}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
