import { Paragraph, Table, TableOfContents } from 'docx';
import {
  PrejudiceFormCalendrierDepense,
  PrejudiceFormCalendrierDepenseRow,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import {
  NumberingType,
  getEmptyLine,
  getParagraph,
  getTextRun,
} from '../../docxFunctions';
import i18next from 'i18next';
import { fCurrency, fDecimalNumber } from 'src/helpers/formatNumber';
import {
  CalculsFormCalendrier,
  CalculsFormCalendrierDepense,
  CalculsGlobal,
} from 'src/constants/calculs';
import {
  getMontantRevaloriseWrittenPrint,
  getRevalorisationIntroductionWrittenPrintIfRevalorise,
} from '../revalorisation.written.print';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import { indemniteGlobaleARepartirEchusWrittenPrint } from '../indemniteGlobaleARepartir/indemniteGlobaleARepartirEchus.written.print';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import { fDate } from 'src/helpers/formatTime';
import { displayVictimeNameWrittenPrint } from '../victime.written.print';
import { displayFormula } from '../formula';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';
import { isPrejudiceRevalorise } from 'src/helpers/prejudices/revalorisation';
import { getPeriodePrintByFrequence } from '../../prejudicesPrints/periodePrint';
import { getMontantRevalorisePrint } from '../../prejudicesPrints/revalorisationPrint';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { indemniteGlobaleARepartirIndirecteWrittenPrint } from '../indemniteGlobaleARepartir/indemniteGlobaleARepartirIndirecte.written.print';
import { groupBy } from 'lodash';

export const dsaWrittenPrint = ({
  victime,
  procedure,
  formData,
  monetaryErosions,
  prejudiceType,
  isVictimeIndirecte,
  victimesIndirectes,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormCalendrierDepense;
  monetaryErosions: MonetaryErosion[];
  isVictimeIndirecte: boolean;
  prejudiceType: PrejudiceType;
  victimesIndirectes: VictimeIndirecte[];
}): (Table | TableOfContents | Paragraph)[] => {
  const getPrisesEnChargeTiersPayeursText = ({
    priseEnChargeTiersPayeurs,
  }: Pick<PrejudiceFormCalendrierDepenseRow, 'priseEnChargeTiersPayeurs'>) => {
    const nonZeroPriseEnChargeTiersPayeurs = priseEnChargeTiersPayeurs.filter(
      (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs.montant !== 0,
    );
    return nonZeroPriseEnChargeTiersPayeurs.length
      ? nonZeroPriseEnChargeTiersPayeurs
          .map((priseEnChargeTiersPayeurs) =>
            i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.row.priseEnChargeTiersPayeur',
              {
                tiersPayeur: priseEnChargeTiersPayeurs.tiersPayeur,
                priseEnChargeTiersPayeur: fCurrency(
                  priseEnChargeTiersPayeurs.montant,
                ),
                count: priseEnChargeTiersPayeurs.montant,
              },
            ),
          )
          .join(', ')
      : '';
  };
  const getResteAChargeText = (
    {
      resteACharge,
      montantTotal,
      priseEnChargeTiersPayeursProratises: priseEnChargeTiersPayeurs,
      dateJustificatif,
      montantsDejaRevalorises,
      victimeIndirecte: victimeIndirecteId,
    }: Pick<
      PrejudiceFormCalendrierDepenseRow,
      | 'resteACharge'
      | 'montantTotal'
      | 'priseEnChargeTiersPayeursProratises'
      | 'dateJustificatif'
      | 'montantsDejaRevalorises'
      | 'victimeIndirecte'
    >,
    index: number,
  ) => {
    if (resteACharge) {
      const totalPriseEnChargeTiersPayeurs = CalculsGlobal.sum(
        priseEnChargeTiersPayeurs.map(
          (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs.montant,
        ),
      );
      const victimeIndirecte = victimesIndirectes.find(
        (victime) => victime._id === victimeIndirecteId,
      );
      return i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.row.resteACharge',
        {
          resteACharge: fCurrency(resteACharge),
          victimeName:
            isVictimeIndirecte && victimeIndirecte
              ? displayVictimeNameWrittenPrint(victimeIndirecte)
              : displayVictimeNameWrittenPrint(victime),
          formula: displayFormula({
            formula: i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.resteACharge.formula',
              {
                montantTotal: fCurrency(montantTotal),
                prisesEnChargeTiersPayeurs: fCurrency(
                  totalPriseEnChargeTiersPayeurs,
                ),
                context: totalPriseEnChargeTiersPayeurs
                  ? undefined
                  : 'noPriseEnChargeTiersPayeurs',
              },
            ),
            options: {
              withParentheses: true,
            },
            editedFieldsParameters: {
              fieldName: `rows.${index}.resteACharge`,
              formData,
            },
          }),
          revalorisation:
            procedure.dateLiquidation &&
            dateJustificatif &&
            !montantsDejaRevalorises
              ? getMontantRevaloriseWrittenPrint({
                  montant: resteACharge,
                  anneeOrDateLiquidation: procedure.dateLiquidation,
                  anneeOrDateMontant: dateJustificatif,
                  coefficientsType: formData.revalorisationCoefficientsType,
                  monetaryErosions,
                })
              : '',
        },
      );
    } else {
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.row.noResteACharge',
      );
    }
  };

  const getDepensePonctuelleParagraphs = (
    row: PrejudiceFormCalendrierDepenseRow,
    index: number,
  ) => {
    const nonZeroPriseEnChargeTiersPayeurs =
      row.priseEnChargeTiersPayeurs.filter(
        (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs.montant !== 0,
      );
    const numerosPieces = displayNumerosPiecesWrittenPrint(
      row.numerosPieces,
      'Parentheses',
    );
    const tiersPayeurText = i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.row.ponctuelle.tiersPayeur',
      {
        intitule:
          !numerosPieces?.length && !!row.intitule?.length
            ? `${row.intitule}, `
            : '',
        numerosPieces,
        montantUnitaire: fCurrency(row.montantUnitaire),
        dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        prisesEnChargeTiersPayeurs: getPrisesEnChargeTiersPayeursText(row),
      },
    );
    const noTiersPayeurText = i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.row.ponctuelle.noTiersPayeur',
      {
        intitule:
          !numerosPieces.length && !!row.intitule?.length
            ? `${row.intitule}, `
            : '',
        numerosPieces,
        dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        montantUnitaire: fCurrency(row.montantUnitaire),
        revalorisation:
          procedure.dateLiquidation &&
          row.dateJustificatif &&
          !row.montantsDejaRevalorises
            ? getMontantRevaloriseWrittenPrint({
                montant: row.montantUnitaire,
                anneeOrDateLiquidation: procedure.dateLiquidation,
                anneeOrDateMontant: row.dateJustificatif,
                monetaryErosions,
                coefficientsType: formData.revalorisationCoefficientsType,
              })
            : '',
      },
    );
    if (nonZeroPriseEnChargeTiersPayeurs.length === 0) {
      return getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: getTextRun(noTiersPayeurText),
      });
    } else {
      const resteAChargeText = getResteAChargeText(row, index);
      return getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: [
          ...getTextRun(tiersPayeurText),
          ...getTextRun({ break: 1 }),
          ...getTextRun(resteAChargeText),
        ],
      });
    }
  };
  const getDepenseRecurrenteParagraphs = (
    row: PrejudiceFormCalendrierDepenseRow,
    index: number,
  ) => {
    const nonZeroPriseEnChargeTiersPayeurs =
      row.priseEnChargeTiersPayeurs.filter(
        (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs.montant !== 0,
      );
    const numerosPieces = displayNumerosPiecesWrittenPrint(
      row.numerosPieces,
      'Parentheses',
    );
    const tiersPayeurText = i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.row.recurrente.tiersPayeur',
      {
        intitule:
          !numerosPieces?.length && !!row.intitule?.length
            ? `${row.intitule}, `
            : '',
        numerosPieces,
        dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        dateFin: row.dateFin ? fDate(row.dateFin) : '',
        montantUnitaire: fCurrency(row.montantUnitaire),
        prisesEnChargeTiersPayeurs: getPrisesEnChargeTiersPayeursText(row),
      },
    );
    const noTiersPayeur = i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.row.recurrente.noTiersPayeur',
      {
        intitule:
          !numerosPieces.length && !!row.intitule?.length
            ? `${row.intitule}, `
            : '',
        numerosPieces,
        dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
        dateFin: row.dateFin ? fDate(row.dateFin) : '',
        montantUnitaire: fCurrency(row.montantUnitaire),
        prisesEnChargeTiersPayeurs: getPrisesEnChargeTiersPayeursText(row),
      },
    );
    const frequenceMontantText = i18next.t(
      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.row.recurrente.frequenceMontant',
      {
        quantite: row.quantite,
        frequenceMontant: i18next.t(
          `prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.options.${row.frequenceMontant}`,
        ),
        montantTotal: fCurrency(row.montantTotal),
        formula: displayFormula({
          formula:
            row.frequenceMontant !== 'non_renseigne'
              ? i18next.t(
                  'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.montantTotal.formula',
                  {
                    montantUnitaire: fCurrency(row.montantUnitaire),
                    quantite: row.quantite,
                    dureeDansUniteChoisie: `${fDecimalNumber(row.dureeDansUniteChoisie)} ${i18next.t(
                      `prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.shortOptions.${row.frequenceMontant}`,
                      {
                        count: row.dureeDansUniteChoisie,
                      },
                    )}`,
                    renouvellementMaintenance: row.renouvellementMaintenance
                      ? `${fDecimalNumber(row.renouvellementMaintenance)} ${i18next.t(
                          `prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.shortOptions.${row.frequenceMontant}`,
                          {
                            count: row.renouvellementMaintenance,
                          },
                        )}`
                      : '',
                    context:
                      row.frequenceMontant === 'viagere'
                        ? 'viagere'
                        : undefined,
                  },
                )
              : '',
          options: {
            withParentheses: true,
          },
          editedFieldsParameters: {
            fieldName: `rows.${index}.montantTotal`,
            formData,
          },
        }),
        renouvellementMaintenance: row.renouvellementMaintenance
          ? `${fDecimalNumber(row.renouvellementMaintenance)} ${i18next.t(
              `prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.fields.rows.frequenceMontant.shortOptions.${row.frequenceMontant}`,
              {
                count: row.renouvellementMaintenance,
              },
            )}`
          : '',
        context: row.frequenceMontant === 'viagere' ? 'viagere' : undefined,
        count:
          row.frequenceMontant === 'viagere' &&
          row.renouvellementMaintenance !== undefined &&
          row.renouvellementMaintenance !== null
            ? row.renouvellementMaintenance
            : undefined,
      },
    );
    const resteAChargeText = getResteAChargeText(row, index);
    if (nonZeroPriseEnChargeTiersPayeurs.length === 0) {
      return getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: [
          ...getTextRun(noTiersPayeur),
          ...getTextRun({ break: 1 }),
          ...getTextRun(frequenceMontantText),
          ...getTextRun({ break: 1 }),
          ...getTextRun(resteAChargeText),
        ],
      });
    } else {
      return getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: [
          ...getTextRun(tiersPayeurText),
          ...getTextRun({ break: 1 }),
          ...getTextRun(frequenceMontantText),
          ...getTextRun({ break: 1 }),
          ...getTextRun(resteAChargeText),
        ],
      });
    }
  };

  const firstPart = isVictimeIndirecte
    ? i18next.t(
        `prejudice.prejudicesTypes.${prejudiceType}.writtenPrint.indemniteGlobaleARepartir.firstPart`,
      )
    : i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.firstPart',
        {
          victimeName: displayVictimeNameWrittenPrint(victime),
        },
      );

  const indirectVictimeName = (victimeIndirecteId: string) => {
    const victime = victimesIndirectes.find(
      (victime) => victime._id === victimeIndirecteId,
    );
    if (victime) {
      return i18next.t(
        'prejudice.indemniteGlobaleARepartir.echus.writtenPrint.VictimeIndirecte.title',
        {
          victimeName: displayVictimeNameWrittenPrint(victime),
          dateNaissance: fDate(victime.dateNaissance),
          lienVictime: victime.lienVictime,
        },
      );
    }
    return '';
  };

  const totaux = CalculsFormCalendrier.totauxDepenses({
    rows: formData.rows,
    tiersPayeurs: procedure.tiersPayeurs,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : undefined,
    monetaryErosions,
    isCalculCapitalisation: false,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });
  const indemniteRepartie =
    CalculsFormCalendrierDepense.getIndemniteRepartieEchus({
      rows: formData.rows,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      monetaryErosions,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    });
  const indemniteRepartieVictimeIndirecte =
    CalculsFormCalendrierDepense.getIndemniteRepartieEchusIndirecte({
      rows: formData.rows,
      partResponsabilite: formData.partResponsabilite ?? 0,
      tiersPayeurs: procedure.tiersPayeurs,
      dateLiquidation: procedure.dateLiquidation
        ? new Date(procedure.dateLiquidation)
        : undefined,
      monetaryErosions,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
    });

  const totalPriseEnChargeTiersPayeurs = CalculsGlobal.sum(
    Object.values(totaux.priseEnChargeTiersPayeurs).map(
      (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs,
    ),
  );
  const recapitulatif = simpleHorizontalTablePrint(
    [
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.tableRecapitulatif.fields.intitule.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.tableRecapitulatif.fields.dateDebut.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.tableRecapitulatif.fields.montantTotal.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.tableRecapitulatif.fields.priseEnChargeTiersPayeursProratises.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.tableRecapitulatif.fields.resteACharge.columnHeader',
      ),
      ...(isPrejudiceRevalorise({
        formData,
        formType: 'CALENDRIER_DEPENSE',
      })
        ? [
            i18next.t(
              'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.writtenPrint.tableRecapitulatif.fields.resteAChargeRevalorise.columnHeader',
            ),
          ]
        : []),
    ],
    formData.rows.map((row) => ({
      rowLabel: row.intitule,
      columns: [
        row.type === 'recurrente'
          ? getPeriodePrintByFrequence({
              dateDebut: row.dateDebut,
              dateFin: row.dateFin,
              frequenceMontant: row.frequenceMontant,
              dureeDansUniteChoisie: row.dureeDansUniteChoisie,
            })
          : row.dateDebut
            ? fDate(row.dateDebut)
            : '',
        fCurrency(row.montantTotal),
        fCurrency(
          CalculsGlobal.sum(
            row.priseEnChargeTiersPayeursProratises.map(
              (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs.montant,
            ),
          ) || 0,
        ),
        fCurrency(row.resteACharge),
        row.montantsDejaRevalorises || !row.resteACharge
          ? ''
          : getMontantRevalorisePrint({
              coefficientsType: formData.revalorisationCoefficientsType,
              montant: row.resteACharge,
              anneeOrDateLiquidation: procedure.dateLiquidation || undefined,
              monetaryErosions,
              anneeOrDateMontant: row.dateJustificatif || undefined,
              montantsDejaRevalorises: row.montantsDejaRevalorises,
              displayStar: false,
            }),
      ],
    })),
  );
  const nonZeroRows = formData.rows.filter(
    (row) => !!row.montantTotal || !!row.resteACharge,
  );
  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
      formType: 'CALENDRIER_DEPENSE',
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      formData,
      isVictimeIndirecte,
    }),
    ...(!!nonZeroRows.length ? [getParagraph(firstPart)] : []),
    ...Object.entries(groupBy(nonZeroRows, 'victimeIndirecte')).flatMap(
      ([victimeIndirecte, rows]) => {
        const title = getParagraph(
          isVictimeIndirecte ? indirectVictimeName(victimeIndirecte) : '',
        );
        const depenseParagraphs = rows.flatMap((row, index) => [
          row.type === 'ponctuelle'
            ? getDepensePonctuelleParagraphs(row, index)
            : getDepenseRecurrenteParagraphs(row, index),
        ]);
        return [title, ...depenseParagraphs, getEmptyLine()];
      },
    ),
    ...(isVictimeIndirecte
      ? [
          ...indemniteGlobaleARepartirIndirecteWrittenPrint({
            indemniteRepartie: indemniteRepartieVictimeIndirecte,
            partResponsabilite: formData.partResponsabilite ?? 0,
            tiersPayeurs: procedure.tiersPayeurs,
            victimesIndirectes,
          }),
        ]
      : [
          ...(nonZeroRows.length > 0
            ? [
                getParagraph({
                  children: getTextRun({
                    text: i18next.t(
                      'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE.total.print.title',
                    ),
                  }),
                }),
                getEmptyLine(),
                recapitulatif,
                getEmptyLine(),
              ]
            : []),
          ...indemniteGlobaleARepartirEchusWrittenPrint({
            indemniteRepartie,
            partResponsabilite: procedure.partResponsabilite,
            tiersPayeurs: procedure.tiersPayeurs,
            victime,
            indemniteGlobaleFormula:
              totaux.resteACharge && totalPriseEnChargeTiersPayeurs
                ? i18next.t(
                    'prejudice.prejudicesTypes.DEPENSE_SANTE_ACTUELLES.writtenPrint.indemniteGlobaleARepartir.indemniteGlobale.formula',
                    {
                      totalResteACharge: fCurrency(totaux.resteACharge),
                      totalPriseEnChargeTiersPayeurs: fCurrency(
                        totalPriseEnChargeTiersPayeurs,
                      ),
                      partResponsabilite: fPartResponsabilite(
                        procedure.partResponsabilite * 100,
                      ),
                      count: procedure.partResponsabilite === 1 ? 1 : 2,
                      context:
                        totaux.resteACharge === 0
                          ? 'noResteACharge'
                          : totalPriseEnChargeTiersPayeurs === 0
                            ? 'noPriseEnChargeTiersPayeurs'
                            : undefined,
                    },
                  )
                : '',
            firstPart: i18next.t(
              `prejudice.prejudicesTypes.${prejudiceType}.writtenPrint.indemniteGlobaleARepartir.firstPart`,
            ),
          }),
        ]),
  ];
};
