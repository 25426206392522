import { Paragraph, Table, TableOfContents } from 'docx';
import { NewPrejudiceFormPerteGainProfessionnelsActuel } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { getEmptyLine } from '../../../docxFunctions';
import { prejudiceNotesPrint } from '../../prejudiceNotesPrint';
import { tableRecapitulatifPrint } from './tableRecapitulatifPrint';
import { stepsPrint } from './stepsPrint';
import { CalculsFormNewPerteGainsProfessionnelsActuels } from 'src/constants/calculs';
import { debitSoldeEchusPrint } from '../../debitSoldeEchusPrint';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';

export const newPerteGainsProfessionnelsActuelsPrint = (
  procedure: Procedure,
  formData: NewPrejudiceFormPerteGainProfessionnelsActuel,
  monetaryErosions: MonetaryErosion[],
): (Paragraph | Table | TableOfContents)[] => {
  const notesPrint = prejudiceNotesPrint(formData.notes);
  const indemniteGlobaleARepartir =
    CalculsFormNewPerteGainsProfessionnelsActuels.getIndemnitesRepartie({
      ...formData,
      perteGainProfessionnelsTotal: formData.perteDeGainsProfessionnels.total,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretCsgRdsTotal:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .csgRdsTotal,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretIndemnitesJournalieresPercuesNet:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret
          .indemnitesJournalieresPercuesNet,
      indemnitesJournalieresPercuesPendantLaPeriodeDArretRows:
        formData.indemnitesJournalieresPercuesPendantLaPeriodeDArret.rows,
      partResponsabilite: procedure.partResponsabilite,
    });
  const tableRecapitulatif = tableRecapitulatifPrint(
    formData,
    procedure.partResponsabilite,
  );
  const steps = stepsPrint({
    formData,
    monetaryErosions,
    dateLiquidation: procedure.dateLiquidation
      ? new Date(procedure.dateLiquidation)
      : null,
    isPGPF: false,
  });
  const debitSoldeTable = debitSoldeEchusPrint({
    indemniteGlobaleARepartir,
    partResponsabilite: procedure.partResponsabilite,
  });

  return [
    getEmptyLine(),
    ...(notesPrint ? [notesPrint] : []),
    getEmptyLine(),
    ...steps,
    getEmptyLine(),
    ...tableRecapitulatif,
    getEmptyLine(),
    ...debitSoldeTable,
  ];
};
