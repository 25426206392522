import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { PrejudiceFormListeProjection } from '../../../types/prejudice.type';
import {
  FormControlLabel,
  Stack,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import { ComputedPropsForm } from '../../forms/ComputedPropsForm';
import { IMaskNumberProps, MaskNumber } from '../../masks/MaskNumber';
import {
  CalculsFormListeProjection,
  CalculsGlobal,
} from '../../../constants/calculs';
import { ComputedReadFieldForm } from 'src/components/forms/ComputedReadFieldForm';
import {
  getBaremeEsperanceDeVieAnnees,
  getProrataTemporisBaremeSourceLabel,
} from 'src/helpers/bareme/bareme';
import { Bareme, BaremeEsperanceDeVieValue } from 'src/types/bareme.type';
import { getProrataTemporisCapitalisationCoefficient } from 'src/helpers/prejudices/capitalisation';
import { Victime } from 'src/types/victime.type';
import { ComputedTextFieldForm } from 'src/components/forms/ComputedTextFieldForm';
import { CalculsBox } from 'src/components/basic/CalculsBox';
import { LayoutTable } from 'src/components/styled';
import { fDecimalNumber } from 'src/helpers/formatNumber';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { useTranslation } from 'react-i18next';
import { TypeBareme } from './Capitalisation/TypeBareme';

interface Props {
  control: Control<PrejudiceFormListeProjection>;
  dateConsolidation: Date;
  dateLiquidation: Date;
  dateDeces: Date;
  victime: Victime;
  baremes: Bareme[];
  partResponsabilite: number;
}

export const ProrataTemporisDeces: React.FC<Props> = ({
  control,
  dateConsolidation,
  dateLiquidation,
  dateDeces,
  victime,
  baremes,
  partResponsabilite,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={2}>
      <Controller
        control={control}
        name="enableProrataTemporisDeces"
        render={({ field }) => (
          <FormControlLabel
            control={<Switch />}
            label={t(
              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.enableProrataTemporisDeces.label',
            )}
            {...field}
            checked={field.value}
          />
        )}
      />
      <ComputedPropsForm
        control={control}
        watchFields={['enableProrataTemporisDeces']}
        compute={(values) => {
          const enableProrataTemporisDeces = values[0];

          return {
            hidden: !enableProrataTemporisDeces,
            props: {},
          };
        }}
        render={() => {
          const ageConsolidation = victime.dateNaissance
            ? CalculsGlobal.getAgeOrZero(
                victime.dateNaissance,
                dateConsolidation,
              )
            : undefined;
          return (
            <CalculsBox maxWidth="600px">
              <TableContainer sx={{ maxWidth: '600px' }}>
                <LayoutTable size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                        {t(
                          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.victimeTitle',
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t(
                          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.ageConsolidation.tableRowHeader',
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {ageConsolidation
                          ? t('forms.fields.age.value', {
                              count: ageConsolidation.age,
                              context: ageConsolidation.isExactlyZero
                                ? 'exactly_zero'
                                : undefined,
                            })
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t(
                          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.baremeCapitalisation.tableRowHeader',
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <SelectFieldForm
                          control={control}
                          name="baremeCapitalisation"
                          sx={{ minWidth: 268 }}
                          allowNoSelection
                          noSelectionText={t(
                            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.baremeCapitalisation.noSelectionText',
                          )}
                          options={baremes.map((bareme) => ({
                            label: getProrataTemporisBaremeSourceLabel(bareme),
                            value: bareme._id,
                          }))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t(
                          'prejudice.capitalisation.form.fields.bareme.baremeType.title',
                        )}
                      </TableCell>
                      <TableCell align="right" sx={{ maxWidth: '200px' }}>
                        <TypeBareme
                          control={control}
                          baremes={baremes}
                          baremeName="baremeCapitalisation"
                        />
                      </TableCell>
                    </TableRow>
                    <ComputedPropsForm
                      control={control}
                      watchFields={['baremeCapitalisation']}
                      compute={([baremeCapitalisation]) => {
                        const bareme = baremes.find(
                          (bareme) => bareme._id === baremeCapitalisation,
                        );
                        const annees =
                          bareme &&
                          bareme.type === 'EsperanceDeVie' &&
                          bareme.source !== 'CJ'
                            ? getBaremeEsperanceDeVieAnnees(
                                bareme?.values as BaremeEsperanceDeVieValue[],
                                victime.sexe,
                                dateConsolidation,
                                dateLiquidation,
                              )
                            : null;
                        return {
                          props: {
                            hidden: !(
                              bareme &&
                              bareme.type === 'EsperanceDeVie' &&
                              bareme.source !== 'CJ'
                            ),
                            annees,
                          },
                        };
                      }}
                      render={(props) => {
                        const annees = props.annees as ReturnType<
                          typeof getBaremeEsperanceDeVieAnnees
                        >;
                        if (
                          !annees ||
                          (!annees.anneeConsolidation &&
                            !annees.anneeLiquidation &&
                            !annees.anneeProcheConsolidation &&
                            !annees.anneeProcheLiquidation)
                        ) {
                          return <></>;
                        }
                        return (
                          <TableRow>
                            <TableCell>
                              {t(
                                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneeCapitalisation.tableRowHeader',
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <SelectFieldForm
                                control={control}
                                name="anneeCapitalisation"
                                sx={{ minWidth: 400 }}
                                options={[
                                  ...(annees.anneeConsolidation
                                    ? [
                                        {
                                          label: t(
                                            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneeCapitalisation.options.anneeConsolidation',
                                            {
                                              anneeConsolidation:
                                                annees.anneeConsolidation,
                                            },
                                          ),
                                          value: annees.anneeConsolidation,
                                        },
                                      ]
                                    : annees.anneeProcheConsolidation
                                      ? [
                                          {
                                            label: t(
                                              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneeCapitalisation.options.anneeProcheConsolidation',
                                              {
                                                anneeProcheConsolidation:
                                                  annees.anneeProcheConsolidation,
                                              },
                                            ),
                                            value:
                                              annees.anneeProcheConsolidation,
                                          },
                                        ]
                                      : []),
                                  ...(annees.anneeLiquidation
                                    ? [
                                        {
                                          label: t(
                                            'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneeCapitalisation.options.anneeLiquidation',
                                            {
                                              anneeLiquidation:
                                                annees.anneeLiquidation,
                                            },
                                          ),
                                          value: annees.anneeLiquidation,
                                        },
                                      ]
                                    : annees.anneeProcheLiquidation
                                      ? [
                                          {
                                            label: t(
                                              'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneeCapitalisation.options.anneeProcheLiquidation',
                                              {
                                                anneeProcheLiquidation:
                                                  annees.anneeProcheLiquidation,
                                              },
                                            ),
                                            value:
                                              annees.anneeProcheLiquidation,
                                          },
                                        ]
                                      : []),
                                ]}
                                allowNoSelection
                                noSelectionText={t(
                                  'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneeCapitalisation.noSelectionText',
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    />

                    <TableRow>
                      <TableCell align="left">
                        {t(
                          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.coefficient.tableRowHeader',
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <ComputedTextFieldForm
                          control={control}
                          name="coefficientCapitalisationAgeConsolidation"
                          watchFields={[
                            'baremeCapitalisation',
                            'anneeCapitalisation',
                          ]}
                          compute={([
                            baremeCapitalisation,
                            anneeCapitalisation,
                          ]) => {
                            const bareme = baremes.find(
                              (bareme) => bareme._id === baremeCapitalisation,
                            );

                            if (
                              bareme &&
                              ageConsolidation &&
                              anneeCapitalisation
                            ) {
                              return (
                                getProrataTemporisCapitalisationCoefficient({
                                  victimeSexe: victime.sexe,
                                  ageDateAttribution: ageConsolidation.age,
                                  selectedBareme: bareme,
                                  anneeCapitalisation,
                                }) || undefined
                              );
                            } else {
                              return 0;
                            }
                          }}
                          InputProps={{
                            inputComponent: MaskNumber as any,
                            inputProps: {
                              numberMask: { scale: 3 },
                              nullable: true,
                            } as IMaskNumberProps,
                          }}
                          fullWidth
                          editedFieldsName="editedFields"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t(
                          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.anneesEntreConsolidationEtDeces.tableRowHeader',
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {fDecimalNumber(
                          CalculsFormListeProjection.getAnneesConsolideDeces(
                            dateConsolidation,
                            dateDeces,
                          ),
                          2,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t(
                          'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.montantProratise.tableRowHeader',
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <ComputedReadFieldForm
                          control={control}
                          name="montantProratise"
                          watchFields={[
                            'coefficientCapitalisationAgeConsolidation',
                            'montantTotal',
                          ]}
                          compute={(values) => {
                            const [
                              coefficientCapitalisationAgeConsolidation,
                              montantTotal,
                            ] = values.map(Number);
                            return (
                              CalculsFormListeProjection.getMontantProratise(
                                (montantTotal || 0) * partResponsabilite,
                                coefficientCapitalisationAgeConsolidation || 0,
                                dateConsolidation,
                                dateDeces,
                              ) || 0
                            );
                          }}
                          render={(montantProratise) => (
                            <TextField
                              value={montantProratise}
                              label={t(
                                'prejudice.prejudicesFormTypes.LISTE_PROJECTION.prorataTemporis.fields.montantProratise.label',
                              )}
                              InputProps={{
                                inputComponent: MaskNumber as any,
                                inputProps: {
                                  suffix: '€',
                                  numberMask: { scale: 2, signed: true },
                                } as IMaskNumberProps,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled
                              fullWidth
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </LayoutTable>
              </TableContainer>
            </CalculsBox>
          );
        }}
      />
    </Stack>
  );
};
