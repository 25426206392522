import * as React from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from '@mui/material';

interface Props {
  title: string | React.ReactNode;
  summaryProps?: AccordionSummaryProps;
  errorTooltip?: string;
  isError?: boolean;
}

export const Accordion: React.FC<
  Props &
    Omit<AccordionProps, 'sx'> & {
      sx?: React.CSSProperties;
    }
> = ({
  title,
  children,
  summaryProps,
  sx,
  isError,
  errorTooltip,
  ...props
}) => {
  return (
    <Tooltip title={isError ? errorTooltip : ''}>
      <MuiAccordion
        {...props}
        disableGutters
        elevation={0}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          '&:before': {
            display: 'none',
          },
          borderColor: isError ? theme.palette.error.main : undefined,
          ...(sx ? sx : {}),
        })}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} {...summaryProps}>
          {typeof title === 'string' ? <Typography>{title}</Typography> : title}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
      </MuiAccordion>
    </Tooltip>
  );
};
