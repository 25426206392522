import React, { useMemo } from 'react';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import {
  OldPrejudiceFormPerteGainProfessionnelsActuel,
  NewPrejudiceFormPerteGainProfessionnelsActuel,
  PrejudiceFormPerteGainProfessionnelsBase,
  PrejudiceFormPerteGainProfessionelsFormType,
  OldPrejudiceFormPerteGainProfessionnelsFuturs,
  NewPrejudiceFormPerteGainProfessionnelsFuturs,
  PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte,
  PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte,
  PrejudiceFormPerteGainProfessionelsFormTypes,
} from '../../../types/prejudice.type';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { OldFormPerteGainsProfessionnelsActuel } from './OldFormPerteGainsProfessionnelsActuel';
import { NewFormPerteGainsProfessionnelsActuel } from './NewFormPerteGainsProfessionnelsActuel';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { useForm } from 'react-hook-form';
import { OldFormPerteGainsProfessionnelsFuturs } from './OldFormPerteGainsProfessionnelsFuturs';
import { NewFormPerteGainsProfessionnelsFuturs } from './NewFormPerteGainsProfessionnelsFuturs';
import { FormPerteGainsProfessionnelsActuelSaisieDirecte } from './FormPerteGainsProfessionnelsActuelSaisieDirecte';
import { FormPerteGainsProfessionnelsFutursSaisieDirecte } from './FormPerteGainsProfessionnelsFutursSaisieDirecte';
import { useTranslation } from 'react-i18next';
import { Help } from '@mui/icons-material';

type StepType = PrejudiceFormPerteGainProfessionelsFormType | 'PGP_CHOICE';

interface Props
  extends PrejudiceFormProps<
    | OldPrejudiceFormPerteGainProfessionnelsActuel
    | NewPrejudiceFormPerteGainProfessionnelsActuel
    | PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
    | OldPrejudiceFormPerteGainProfessionnelsFuturs
    | NewPrejudiceFormPerteGainProfessionnelsFuturs
    | PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
  > {
  tauxIPP: number | null;
}

export const FormPerteGainsProfessionnels: React.FC<Props> = ({
  victime,
  procedure,
  dommages,
  values,
  monetaryErosions,
  tauxIPP,
  ...props
}) => {
  const { t } = useTranslation();
  const isPGPF = props.prejudiceType === 'PERTES_GAINS_PROFESSIONNELS_FUTURS';
  const [step, setStep] = React.useState<StepType>(
    !!values?.formType ? values?.formType : 'PGP_CHOICE',
  );
  const useFormReturn = useForm<PrejudiceFormPerteGainProfessionnelsBase>({
    defaultValues: {},
  });
  const { setValue } = useMemo(() => useFormReturn, [useFormReturn]);

  const onChangeStep = (updatedStep: StepType) => {
    if (updatedStep === 'PGP_CHOICE') {
      setValue('formType', undefined);
    } else {
      setValue('formType', updatedStep);
    }
    setStep(updatedStep);
  };

  const getStep = () => {
    switch (step) {
      case 'PGP_CHOICE':
        return (
          <PrejudiceContainer<PrejudiceFormPerteGainProfessionnelsBase>
            {...props}
            procedure={procedure}
            victime={victime}
            monetaryErosions={monetaryErosions}
            {...useFormReturn}
            renderPrejudice={() => (
              <Stack direction="column" spacing={4}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  sx={{
                    marginTop: '7%',
                  }}
                >
                  {PrejudiceFormPerteGainProfessionelsFormTypes.filter(
                    // TODO : Hidden until we fix PGP1
                    (formType) => formType !== 'DECLARATION_FISCALES',
                  ).map((formType) => {
                    const tooltipContent = t(
                      `prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.formType.${formType}.tooltipContent`,
                      {
                        defaultValue: '',
                      },
                    );
                    return (
                      <Stack direction="row" key={formType}>
                        <Button
                          variant="contained"
                          onClick={() => onChangeStep(formType)}
                        >
                          {t(
                            `prejudice.prejudicesFormTypes.PERTE_GAINS_PROFESSIONNELS_ACTUEL.fields.formType.${formType}.title`,
                          )}
                        </Button>
                        {tooltipContent ? (
                          <Tooltip
                            title={
                              <Typography
                                whiteSpace="pre-line"
                                maxHeight="400px"
                                overflow="auto"
                              >
                                {tooltipContent}
                              </Typography>
                            }
                            placement="bottom-end"
                          >
                            <IconButton>
                              <Help />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            )}
          />
        );
      case 'DECLARATION_FISCALES':
        if (isPGPF) {
          return (
            <OldFormPerteGainsProfessionnelsFuturs
              victime={victime}
              values={values as OldPrejudiceFormPerteGainProfessionnelsFuturs}
              procedure={procedure}
              dommages={dommages}
              monetaryErosions={monetaryErosions}
              {...props}
              onPrevious={() => onChangeStep('PGP_CHOICE')}
              tauxIPP={tauxIPP}
            />
          );
        } else {
          return (
            <OldFormPerteGainsProfessionnelsActuel
              victime={victime}
              values={values as OldPrejudiceFormPerteGainProfessionnelsActuel}
              procedure={procedure}
              dommages={dommages}
              monetaryErosions={monetaryErosions}
              {...props}
              onPrevious={() => onChangeStep('PGP_CHOICE')}
            />
          );
        }
      case 'REVENUS':
        if (isPGPF) {
          return (
            <NewFormPerteGainsProfessionnelsFuturs
              victime={victime}
              values={values as NewPrejudiceFormPerteGainProfessionnelsFuturs}
              procedure={procedure}
              dommages={dommages}
              monetaryErosions={monetaryErosions}
              {...props}
              onPrevious={() => onChangeStep('PGP_CHOICE')}
              tauxIPP={tauxIPP}
            />
          );
        } else {
          return (
            <NewFormPerteGainsProfessionnelsActuel
              victime={victime}
              values={values as NewPrejudiceFormPerteGainProfessionnelsActuel}
              procedure={procedure}
              dommages={dommages}
              monetaryErosions={monetaryErosions}
              {...props}
              onPrevious={() => onChangeStep('PGP_CHOICE')}
            />
          );
        }
      case 'SAISIE_DIRECTE':
        if (isPGPF) {
          return (
            <FormPerteGainsProfessionnelsFutursSaisieDirecte
              victime={victime}
              values={
                values as PrejudiceFormPerteGainsProfessionnelsFutursSaisieDirecte
              }
              procedure={procedure}
              dommages={dommages}
              monetaryErosions={monetaryErosions}
              {...props}
              onPrevious={() => onChangeStep('PGP_CHOICE')}
              tauxIPP={tauxIPP}
            />
          );
        } else {
          return (
            <FormPerteGainsProfessionnelsActuelSaisieDirecte
              victime={victime}
              values={
                values as PrejudiceFormPerteGainsProfessionnelsActuelSaisieDirecte
              }
              procedure={procedure}
              dommages={dommages}
              monetaryErosions={monetaryErosions}
              {...props}
              onPrevious={() => onChangeStep('PGP_CHOICE')}
            />
          );
        }

      default:
        return null;
    }
  };
  return <>{getStep()}</>;
};
