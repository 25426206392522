import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Stack,
} from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NormalTable } from 'src/components/styled';
import { fCurrency } from 'src/helpers/formatNumber';
import { PrejudiceFormPerteRevenusProche } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { ComputedPropsForm } from 'src/components/forms/ComputedPropsForm';
import { CalculPerteRevenusProche } from 'src/constants/calculs/calculPerteRevenusProche';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { TotalIndemniteGlobaleARepartirEchusIndirecte } from 'src/components/client/prejudiceFormComponents/TotalIndemniteGlobaleARepartirEchusIndirecte';
import { fPartResponsabilite } from 'src/helpers/formatValues';
import { CalculsRentes } from 'src/constants/calculs/calculsRentes';
import { RentesOption } from 'src/components/client/prejudiceFormComponents/Rentes/RenteOption';

type RecapitulatifCapitauxDecesLineValuesType = {
  montant: number;
  capitauxDeces: number;
  apresCapitauxDeces: number;
};
type RecapitulatifCapitauxDecesReturnType = Record<
  'enfants' | 'conjoint',
  RecapitulatifCapitauxDecesLineValuesType
> &
  Record<
    'total',
    RecapitulatifCapitauxDecesLineValuesType & { partResponsabilite: number }
  > & { partResponsabilite: number };

interface Props {
  control: Control<PrejudiceFormPerteRevenusProche>;
  procedure: Procedure;
  victimesIndirectes: VictimeIndirecte[];
}
export const Recapitulatif: React.FC<Props> = ({
  control,
  procedure,
  victimesIndirectes,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <NormalTable>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.total.columnHeader',
              )}
            </TableCell>
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.capitauxDeces.columnHeader',
              )}
            </TableCell>
            <TableCell align="center">
              {t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.totalApresCapitauxDeces.columnHeader',
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <ComputedPropsForm
          control={control}
          watchFields={[
            'conjoint.total',
            'enfants.total',
            'partResponsabilite',
            'conjoint.capitauxDeces.total',
            'enfants.totalCapitauxDeces',
            'conjoint.totalApresCapitauxDeces',
            'enfants.totalApresCapitauxDeces',
          ]}
          compute={([
            conjointTotal,
            enfantsTotal,
            partResponsabilite,
            conjointCapitauxDecesTotal,
            enfantsCapitauxDecesTotal,
            conjointTotalApresCapitauxDeces,
            enfantsTotalApresCapitauxDeces,
          ]) => {
            const total = CalculPerteRevenusProche.getTotal(
              conjointTotal,
              enfantsTotal,
            );
            const partResponsabiliteValue =
              partResponsabilite / 100 || procedure.partResponsabilite;
            return {
              props: {
                enfants: {
                  montant: enfantsTotal,
                  capitauxDeces: enfantsCapitauxDecesTotal,
                  apresCapitauxDeces: enfantsTotalApresCapitauxDeces,
                },
                conjoint: {
                  montant: conjointTotal,
                  capitauxDeces: conjointCapitauxDecesTotal,
                  apresCapitauxDeces: conjointTotalApresCapitauxDeces,
                },
                total: {
                  montant: total,
                  capitauxDeces: CalculPerteRevenusProche.getTotalCapitauxDeces(
                    conjointCapitauxDecesTotal,
                    enfantsCapitauxDecesTotal,
                  ),

                  apresCapitauxDeces:
                    CalculPerteRevenusProche.getTotalApresCapitauxDeces(
                      conjointTotalApresCapitauxDeces,
                      enfantsTotalApresCapitauxDeces,
                    ),
                  partResponsabilite: total * partResponsabiliteValue,
                },
                partResponsabilite: partResponsabiliteValue,
              } as RecapitulatifCapitauxDecesReturnType,
            };
          }}
          render={(props) => {
            const { enfants, conjoint, total, partResponsabilite } =
              props as RecapitulatifCapitauxDecesReturnType;
            return (
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t(
                      'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.enfants.tableRowHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(enfants.montant)}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(enfants.capitauxDeces)}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(enfants.apresCapitauxDeces)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(
                      'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.conjoint.tableRowHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(conjoint.montant)}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(conjoint.capitauxDeces)}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(conjoint.apresCapitauxDeces)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(
                      'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.total.tableRowHeader',
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(total.montant)}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(total.capitauxDeces)}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(total.apresCapitauxDeces)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('partResponsabilite.tableRowHeader', {
                      partResponsabilite: fPartResponsabilite(
                        partResponsabilite * 100,
                      ),
                    })}
                  </TableCell>
                  <TableCell align="center">
                    {fCurrency(total.partResponsabilite)}
                  </TableCell>
                  <TableCell colSpan={2} />
                </TableRow>
              </TableBody>
            );
          }}
        />
      </NormalTable>
      <Box sx={{ alignSelf: 'end' }} marginTop={2}>
        <ComputedPropsForm
          control={control}
          watchFields={['conjoint', 'enfants', 'partResponsabilite']}
          compute={([conjoint, enfants, partResponsabilite]) => {
            const indemniteRepartie =
              CalculPerteRevenusProche.getIndemniteRepartie({
                tiersPayeurs: procedure.tiersPayeurs,
                victimesIndirectes,
                perteRevenusProche: {
                  conjoint,
                  enfants,
                },
                partResponsabilite:
                  partResponsabilite / 100 || procedure.partResponsabilite,
              });
            const conjointVictimeIndirecte = victimesIndirectes.find(
              (victime) => victime.lienVictime === 'conjointConcubin',
            );
            const indemniteConjoint = conjointVictimeIndirecte
              ? indemniteRepartie.indemniteProche.arreragesEchus
                  .parVictimeIndirecte[conjointVictimeIndirecte._id]
              : null;
            return {
              props: {
                indemniteRepartie,
                partResponsabilite:
                  partResponsabilite / 100 || procedure.partResponsabilite,
                renteMontant:
                  indemniteConjoint &&
                  (conjoint.coefficientCapitalisation ||
                    conjoint.coefficientCapitalisation === 0)
                    ? CalculsRentes.getAnnuel(
                        indemniteConjoint,
                        conjoint.coefficientCapitalisation,
                      )
                    : 0,
              },
            };
          }}
          render={({ indemniteRepartie, partResponsabilite, renteMontant }) => (
            <Stack spacing={2} width="50%">
              <TotalIndemniteGlobaleARepartirEchusIndirecte
                indemniteGlobaleARepartir={indemniteRepartie}
                victimesIndirectes={victimesIndirectes}
                partResponsabilite={partResponsabilite}
              />
              <RentesOption
                control={control}
                name={{
                  renteMontant: 'rentes.montant',
                  isRentesOption: 'isRentesOption',
                  editedFields: 'editedFields',
                }}
                titles={{
                  renteOption: t(
                    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.renteOption.title',
                  ),
                }}
                renteDefaultValue={renteMontant}
              />
            </Stack>
          )}
        />
      </Box>
    </>
  );
};
