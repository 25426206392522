import { AppDispatch, resetStore } from 'src/store';
import {
  deleteOwnAccount as deleteOwnAccountAction,
  logout as logoutAction,
} from 'src/slices/auth';

export const logout = async (dispatch: AppDispatch) => {
  dispatch(resetStore());
  dispatch(logoutAction());
};

export const deleteOwnAccount = async (dispatch: AppDispatch) => {
  dispatch(deleteOwnAccountAction());
  logout(dispatch);
};
