import React, { useMemo } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Breadcrumbs,
  Link,
  styled,
  Typography,
} from '@mui/material';
import { useAppSelector } from '../hooks/store';
import { victimeSelectors } from '../store/selectors';
import { RootState } from '../store';
import {
  PATH_ORGANIZATION,
  PATH_PREJUDICES,
  PATH_PROCEDURE,
  PATH_VICTIME,
} from '../routes/paths';

/* Styled components */
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.light,
  justifyContent: 'center',
  top: '64px',
  height: '64px',
}));

/* Component */
interface Props {
  children?: React.ReactNode;
}

export const PATHS: {
  path: string;
  text?: string;
  selector?: (state: RootState, params: any) => string;
}[] = [
  { path: PATH_ORGANIZATION, text: 'Victimes' },
  {
    path: `${PATH_ORGANIZATION}${PATH_VICTIME}/`,
    selector: (state, params) => {
      const victime = victimeSelectors.selectById(state, params['victimeId']);
      return victime ? victime.referenceDossierVictime : '?';
    },
  },
  {
    path: `${PATH_ORGANIZATION}${PATH_VICTIME}${PATH_PROCEDURE}/`,
    selector: (state, params) =>
      [
        victimeSelectors
          .selectById(state, params['victimeId'])
          ?.procedures.find(
            (procedure) => procedure._id === params['procedureId'],
          )?.procedureType || '?',
      ]
        .map(
          (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase(),
        )
        .join(' '),
  },
  {
    path: `${PATH_ORGANIZATION}${PATH_VICTIME}${PATH_PREJUDICES}/`,
    text: "Calcul de l'indemnisation",
  },
];

export const NavBar: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  const matchPaths = useMemo(
    () =>
      PATHS.map((path) => ({
        ...path,
        match: matchPath(
          {
            path: `${path.path}*`,
          },
          pathname,
        ),
        exactMatch: matchPath(
          {
            path: path.path,
          },
          pathname,
        ),
      })).filter((path) => !!path.match),
    [pathname],
  );

  const stateValues = useAppSelector<Record<string, string>>((state) =>
    matchPaths
      .filter((matchPath) => !!matchPath.selector)
      .reduce(
        (acc, value) => ({
          ...acc,
          [value.path]: (value.selector as any)(state, value.match?.params),
        }),
        {},
      ),
  );

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <Breadcrumbs sx={{ flexGrow: 1 }}>
          {matchPaths.map((path) =>
            !!path.exactMatch ? (
              <Typography key={path.path} color="text.primary">
                {path.selector ? stateValues[path.path] : path.text}
              </Typography>
            ) : (
              <Link
                key={path.path}
                underline="hover"
                color="inherit"
                to={path.match?.pathnameBase || ''}
                component={RouterLink}
              >
                {path.selector ? stateValues[path.path] : path.text}
              </Link>
            ),
          )}
        </Breadcrumbs>
        {children}
      </Toolbar>
    </StyledAppBar>
  );
};
