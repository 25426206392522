import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { pendingReducer, rejectedReducer } from '../helpers/slice';
import {
  CreatePrejudiceDto,
  Prejudice,
  PrejudiceType,
  UpdateMultiplePrejudicesDto,
  UpdatePrejudiceDto,
} from '../types/prejudice.type';

/* Thunk */

export const fetchAllPrejudices = createAsyncThunk(
  'prejudices/fetchAll',
  async (procedureId: string) => {
    return await apiRequest<Prejudice[]>(
      'GET',
      `/procedures/${procedureId}/prejudices`,
    );
  },
);

export const createPrejudice = createAsyncThunk(
  'prejudices/create',
  async (payload: { procedureId: string; data: CreatePrejudiceDto }) => {
    const { procedureId, data } = payload;

    return await apiRequest<Prejudice>(
      'POST',
      `/procedures/${procedureId}/prejudices`,
      undefined,
      data,
    );
  },
);

export const updatePrejudice = createAsyncThunk(
  'prejudices/update',
  async (payload: {
    procedureId: string;
    prejudiceId: string;
    data: UpdatePrejudiceDto;
  }) => {
    const { procedureId, prejudiceId, data } = payload;

    return await apiRequest<Prejudice>(
      'PATCH',
      `/procedures/${procedureId}/prejudices/${prejudiceId}`,
      undefined,
      data,
    );
  },
);

export const deletePrejudice = createAsyncThunk(
  'prejudices/delete',
  async (payload: { procedureId: string; prejudiceId: string }) => {
    const { procedureId, prejudiceId } = payload;

    return await apiRequest<{ id: string }>(
      'DELETE',
      `/procedures/${procedureId}/prejudices/${prejudiceId}`,
    );
  },
);

export const deletePrejudiceByTypes = createAsyncThunk(
  'prejudices/deleteByTypes',
  async (payload: { procedureId: string; types: PrejudiceType[] }) => {
    const { procedureId, types } = payload;

    return await apiRequest<{ ids: string[] }>(
      'DELETE',
      `/procedures/${procedureId}/prejudices/byTypes`,
      undefined,
      { types },
    );
  },
);
export const updateMultiplePrejudices = createAsyncThunk(
  'prejudices/updateMultiple',
  async (payload: {
    procedureId: string;
    dto: UpdateMultiplePrejudicesDto;
  }) => {
    return await apiRequest<Prejudice[]>(
      'PATCH',
      `/procedures/${payload.procedureId}/prejudices/multiple`,
      undefined,
      payload.dto,
    );
  },
);
/* Slice */

export const prejudiceAdapter = createEntityAdapter<Prejudice>({
  selectId: (prejudice) => prejudice._id,
});

const prejudiceSlice = createSlice({
  name: 'prejudice',
  initialState: prejudiceAdapter.getInitialState({
    isLoading: false,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPrejudices.fulfilled, (state, action) => {
        prejudiceAdapter.setAll(state, action.payload);
        state.isLoading = false;
      })
      .addCase(fetchAllPrejudices.pending, pendingReducer)
      .addCase(fetchAllPrejudices.rejected, rejectedReducer);

    builder
      .addCase(createPrejudice.fulfilled, (state, action) => {
        prejudiceAdapter.addOne(state, action.payload);
        state.isLoading = false;
      })
      .addCase(createPrejudice.pending, pendingReducer)
      .addCase(createPrejudice.rejected, rejectedReducer);

    builder
      .addCase(updatePrejudice.fulfilled, (state, action) => {
        prejudiceAdapter.setOne(state, action.payload);
        state.isLoading = false;
      })
      .addCase(updatePrejudice.pending, pendingReducer)
      .addCase(updatePrejudice.rejected, rejectedReducer);

    builder
      .addCase(deletePrejudice.fulfilled, (state, action) => {
        prejudiceAdapter.removeOne(state, action.payload.id);
        state.isLoading = false;
      })
      .addCase(deletePrejudice.pending, pendingReducer)
      .addCase(deletePrejudice.rejected, rejectedReducer);

    builder
      .addCase(deletePrejudiceByTypes.fulfilled, (state, action) => {
        prejudiceAdapter.removeMany(state, action.payload.ids);
        state.isLoading = false;
      })
      .addCase(deletePrejudiceByTypes.pending, pendingReducer)
      .addCase(deletePrejudiceByTypes.rejected, rejectedReducer);
    builder
      .addCase(updateMultiplePrejudices.pending, pendingReducer)
      .addCase(updateMultiplePrejudices.rejected, rejectedReducer)
      .addCase(updateMultiplePrejudices.fulfilled, (state, action) => {
        action.payload.forEach((prejudice) => {
          prejudiceAdapter.setOne(state, prejudice);
        });
        state.isLoading = false;
      });
  },
});

export default prejudiceSlice.reducer;
