import { upperFirst } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';
import {
  fetchOrganization,
  removeOrganizationMember,
} from 'src/slices/organization';
import {
  OrganizationMember,
  OrganizationMemberRole,
} from 'src/types/organization.type';
import { ConfirmationDialog } from 'src/components/basic/ConfirmationDialog';
import { OrganizationMemberMenu } from 'src/components/organization/OrganizationMemberMenu';
import { DataGrid, GridColDef, enUS, frFR } from '@mui/x-data-grid';
import { useAuthenticatedUser } from 'src/hooks/store/auth.hooks';

type GridRowType = Pick<
  OrganizationMember,
  '_id' | 'firstName' | 'lastName' | 'email' | 'role'
>;
interface Props {
  onClickUpdateMember: (member: OrganizationMember) => void;
}
export const OrganizationMembers: React.FC<Props> = ({
  onClickUpdateMember,
}) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const authenticatedUser = useAuthenticatedUser();
  const [deleteMemberConfirmationDialog, setDeleteMemberConfirmationDialog] =
    useState<{
      open: boolean;
      memberToDelete: OrganizationMember | null;
    }>({ open: false, memberToDelete: null });

  const { organization } = useDisplayedOrganization();

  useEffect(() => {
    if (organization?._id) {
      dispatch(fetchOrganization(organization?._id));
    }
  }, []);

  const onClickDeleteMember = (member: OrganizationMember) => {
    setDeleteMemberConfirmationDialog({
      open: true,
      memberToDelete: member,
    });
  };

  const onCloseDeleteMemberDialog = () => {
    setDeleteMemberConfirmationDialog({
      open: false,
      memberToDelete: null,
    });
  };

  const onConfirmDeleteMember = () => {
    if (
      deleteMemberConfirmationDialog.memberToDelete?._id &&
      organization?._id
    ) {
      dispatch(
        removeOrganizationMember({
          organizationId: organization?._id,
          memberId: deleteMemberConfirmationDialog.memberToDelete._id,
        }),
      );
      setDeleteMemberConfirmationDialog({
        open: false,
        memberToDelete: null,
      });
    }
  };

  const columns: GridColDef<GridRowType>[] = [
    {
      field: 'firstName',
      headerName: t(
        'pages.OrganizationConfiguration.table.members.columnHeader',
      ),
      flex: 1,
      renderCell: (params) => (
        <>{`${upperFirst(params.row.firstName)} ${upperFirst(params.row.lastName)}`}</>
      ),
    },
    {
      field: 'email',
      headerName: t('pages.OrganizationConfiguration.table.email.columnHeader'),
      flex: 1,
    },
    {
      field: 'role',
      headerName: t('pages.OrganizationConfiguration.table.role.columnHeader'),
      flex: 1,
      renderCell: (params) =>
        t(`organization.fields.role.options.${params.row.role}.label`),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      renderCell: (params) =>
        params.row._id === authenticatedUser?._id ||
        params.row.role === OrganizationMemberRole.owner ? null : (
          <OrganizationMemberMenu
            member={params.row as OrganizationMember}
            onClickUpdateMember={onClickUpdateMember}
            onClickDeleteMember={onClickDeleteMember}
          />
        ),
    },
  ];
  const rows: GridRowType[] =
    organization?.members.map((member) => ({
      id: member._id,
      ...member,
    })) || [];
  return (
    <>
      <DataGrid
        sx={{
          cursor: 'default',
          boxShadow:
            'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, \
             &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
          height: '100%',
        }}
        localeText={
          i18n.language === 'en'
            ? enUS.components.MuiDataGrid.defaultProps.localeText
            : frFR.components.MuiDataGrid.defaultProps.localeText
        }
        loading={false}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        pageSizeOptions={[10, 20, 50]}
      />
      <ConfirmationDialog
        open={deleteMemberConfirmationDialog.open}
        onCancel={onCloseDeleteMemberDialog}
        onConfirm={onConfirmDeleteMember}
        title={t(
          'pages.OrganizationConfiguration.deleteMemberConfirmation.title',
          {
            memberName: `${deleteMemberConfirmationDialog.memberToDelete?.firstName} ${deleteMemberConfirmationDialog.memberToDelete?.lastName}`,
            organizationName: organization?.name,
          },
        )}
      />
    </>
  );
};
