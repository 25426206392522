import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import { CalculsGlobal } from 'src/constants/calculs';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { fCurrency, fPercent } from 'src/helpers/formatNumber';
import { displayNumeroPiece } from 'src/helpers/prejudices/numeroPiece';
import { Bareme } from 'src/types/bareme.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  Prejudice,
  PrejudiceFormPerteRevenusProche,
  RevenuProche,
  PGPFReliquatAndPGPFReliquatActivationStatus,
  PrejudiceFormPerteRevenusProcheEnfantRow,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { getEmptyLine, getParagraph, getTextRun } from '../../docxFunctions';
import { simpleHorizontalTablePrint } from '../../simpleHorizontalTablePrint';
import { FormTableRow, prejudiceFormTable } from '../prejudiceFormTablePrint';
import { prejudiceNotesPrint } from '../prejudiceNotesPrint';
import { getQuotientRevalorisationPrint } from '../revalorisationPrint';
import { debitSoldeEchusIndirectePrint } from '../debitSoldeEchusIndirectePrint';
import { CalculPerteRevenusProche } from 'src/constants/calculs/calculPerteRevenusProche';
import {
  fCoefficientCapitalisation,
  fPartResponsabilite,
} from 'src/helpers/formatValues';

const getEnfantNameRow = (
  victimesIndirectes: VictimeIndirecte[],
): FormTableRow<
  PrejudiceFormPerteRevenusProcheEnfantRow,
  keyof PrejudiceFormPerteRevenusProcheEnfantRow,
  PrejudiceFormPerteRevenusProcheEnfantRow[keyof PrejudiceFormPerteRevenusProcheEnfantRow]
> => ({
  name: 'victimeIndirecteId',
  headerLabel: i18next.t(
    'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
  ),
  render: (row) => {
    const victimeIndirecte = victimesIndirectes.find(
      (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
    );
    return `${victimeIndirecte?.prenom || ''} ${victimeIndirecte?.nom || ''}`;
  },
});

const enfantsEntreDecesEtLiquidationTable = ({
  formData,
  victimesIndirectes,
}: {
  formData: PrejudiceFormPerteRevenusProche;
  victimesIndirectes: VictimeIndirecte[];
}): (Table | Paragraph | TableOfContents)[] => [
  getParagraph({
    children: getTextRun({
      text: `${i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.entreDecesEtLiquidation.title',
      )}`,
      bold: true,
      break: 1,
    }),
  }),
  ...prejudiceFormTable(formData.enfants.rows, [
    getEnfantNameRow(victimesIndirectes),
    {
      name: 'partPerteAnnuelle',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.partPerteAnnuelle.columnHeader',
      ),
      render: (row) => fCurrency(row.partPerteAnnuelle),
    },
    {
      name: 'dureeIndemnisation',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.dureeIndemnisation.columnHeader',
      ),
      render: (row) =>
        i18next.t('forms.fields.year.value', {
          count: row.dureeIndemnisation,
        }),
    },
    {
      name: 'prejudiceEntreDecesEtLiquidation',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.prejudiceEntreDecesEtLiquidation.columnHeader',
      ),
      render: (row) => fCurrency(row.prejudiceEntreDecesEtLiquidation),
    },
  ]),
];

export const partialRecapitulatifPrint = ({
  title,
  echus,
  aEchoir,
  total,
}: {
  title: string;
  echus: number;
  aEchoir: number;
  total: number;
}) =>
  simpleHorizontalTablePrint(
    [
      '',
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.echus.print.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.aEchoir.print.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.partial.total.print.columnHeader',
      ),
    ],
    [
      {
        rowLabel: title,
        columns: [fCurrency(echus), fCurrency(aEchoir), fCurrency(total)],
      },
    ],
  );

export const prpRecapitulatifTotalTablePrint = ({
  formData,
  partResponsabilite,
}: {
  formData: PrejudiceFormPerteRevenusProche;
  partResponsabilite: number;
}) => {
  const total = CalculPerteRevenusProche.getTotal(
    formData.conjoint.total,
    formData.enfants.total,
  );
  const totalCapitauxDeces = CalculPerteRevenusProche.getTotalCapitauxDeces(
    formData.conjoint.capitauxDeces.total,
    formData.enfants.totalCapitauxDeces,
  );
  const totalApresCapitauxDeces =
    CalculPerteRevenusProche.getTotalApresCapitauxDeces(
      formData.conjoint.totalApresCapitauxDeces,
      formData.enfants.totalApresCapitauxDeces,
    );
  return simpleHorizontalTablePrint(
    [
      '',
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.total.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.capitauxDeces.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.totalApresCapitauxDeces.columnHeader',
      ),
    ],
    [
      ...(formData.enfants.rows.length > 0
        ? [
            {
              rowLabel: i18next.t(
                'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.enfants.tableRowHeader',
              ),
              columns: [
                fCurrency(formData.enfants.total),
                fCurrency(formData.enfants.totalCapitauxDeces),
                fCurrency(formData.enfants.totalApresCapitauxDeces),
              ],
            },
          ]
        : []),
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.conjoint.tableRowHeader',
        ),
        columns: [
          fCurrency(formData.conjoint.total),
          fCurrency(formData.conjoint.capitauxDeces.total),
          fCurrency(formData.conjoint.totalApresCapitauxDeces),
        ],
      },
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.recapitulatif.table.total.tableRowHeader',
        ),
        columns: [
          fCurrency(total),
          fCurrency(totalCapitauxDeces),
          fCurrency(totalApresCapitauxDeces),
        ],
      },
      {
        rowLabel: i18next.t('partResponsabilite.tableRowHeader', {
          partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
        }),
        columns: [fCurrency(total * partResponsabilite), '', ''],
      },
    ],
  );
};

const getRevenuProcheTable = (
  revenusProches: (RevenuProche & {
    label: string;
  })[],
  monetaryErosions: MonetaryErosion[],
  dateLiquidation: Date | undefined,
) =>
  simpleHorizontalTablePrint(
    [
      '',
      i18next.t('numeroPiece.form.columnHeader'),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.montant.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.annee.columnHeader',
      ),
      i18next.t(
        'prejudice.revalorisation.coefficientsType.smic.print.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.fields.revenuProche.montant.print.revaloriseColumnHeader',
      ),
    ],
    revenusProches.map((revenusProche) => ({
      rowLabel: revenusProche.label,
      columns: [
        revenusProche.numerosPieces
          ? displayNumeroPiece(revenusProche.numerosPieces)
          : '',
        revenusProche.montant || revenusProche.montant === 0
          ? fCurrency(revenusProche.montant)
          : '',
        revenusProche.annee || revenusProche.annee === 0
          ? revenusProche.annee.toString()
          : '',
        revenusProche.dejaRevalorise
          ? ''
          : getQuotientRevalorisationPrint({
              monetaryErosions,
              anneeOrDateLiquidation: dateLiquidation,
              anneeOrDateMontant: revenusProche.annee ?? undefined,
              coefficientsType: 'smic',
            }),
        revenusProche.dejaRevalorise
          ? ''
          : fCurrency(revenusProche.revaloriseMontant),
      ],
    })),
  );

export const perteRevenusProcheVictimeDecedeePrint = ({
  formData,
  victimesIndirectes,
  victime,
  procedure,
  baremes,
  dateLiquidation,
  monetaryErosions,
}: {
  formData: PrejudiceFormPerteRevenusProche;
  victimesIndirectes: VictimeIndirecte[];
  victime: Victime;
  procedure: Procedure;
  baremes: Bareme[];
  prejudice: Pick<Prejudice, 'formData' | 'formType' | 'type'>;
  dateLiquidation: Date | undefined;
  monetaryErosions: MonetaryErosion[];
  PGPFReliquat: PGPFReliquatAndPGPFReliquatActivationStatus;
}): (Paragraph | Table | TableOfContents)[] => {
  const partResponsabilite =
    formData.partResponsabilite ?? procedure.partResponsabilite;
  const ageDateAttribution = victime.dateNaissance
    ? CalculsGlobal.getAge(
        victime.dateNaissance,
        procedure.dateLiquidation || new Date(),
      )
    : null;
  const conjoint = victimesIndirectes.find(
    (victimeIndirecte) => victimeIndirecte.lienVictime === 'conjointConcubin',
  );
  const ageDateAttributionConjoint = conjoint
    ? CalculsGlobal.getAge(
        conjoint?.dateNaissance,
        procedure.dateLiquidation || new Date(),
      )
    : null;
  const avantDecesTable = getRevenuProcheTable(
    [
      {
        ...formData.victime.revenu,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.victime.revenu.label',
        ),
      },
      {
        ...formData.conjoint.revenuAvantDeces,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.revenuAvantDeces.label',
        ),
      },
    ],
    monetaryErosions,
    dateLiquidation,
  );
  const apresDecesTable = getRevenuProcheTable(
    [
      {
        ...formData.conjoint.revenuApresDeces,
        label: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.revenuApresDeces.label',
        ),
      },
    ],
    monetaryErosions,
    dateLiquidation,
  );
  const baremeCapitalisation = baremes.find(
    (bareme) => bareme._id === formData.baremeCapitalisation,
  );
  const baremeCapitalisationLabel = baremeCapitalisation
    ? getCapitalisationBaremeSourceLabel(baremeCapitalisation)
    : '';
  const anneesEntreDecesEtLiquidation =
    victime.dateDeces && dateLiquidation
      ? CalculsGlobal.getYears(new Date(victime.dateDeces), dateLiquidation)
      : 0;
  const anneesEntreDecesEtLiquidationParagraph = getParagraph({
    children: getTextRun({
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.anneesEntreDecesEtLiquidation.print.value',
        {
          value: i18next.t('forms.fields.year.value', {
            count: anneesEntreDecesEtLiquidation,
          }),
        },
      ),
      break: 1,
    }),
  });

  const prejudiceViagerFoyerEntreDecesEtLiquidationParagraph = getParagraph({
    children: getTextRun({
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViagerEntreDecesEtLiquidation.print.value',
        {
          value: fCurrency(
            formData.foyer.prejudiceViagerEntreDecesEtLiquidation,
          ),
        },
      ),
      break: 1,
    }),
  });
  const prejudiceViagerFoyerTable = simpleHorizontalTablePrint(
    [
      '',
      i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.agePaiement.columnHeader',
      ),
      i18next.t('prejudice.capitalisation.form.fields.bareme.title'),
      i18next.t('prejudice.capitalisation.form.fields.coefficient.otherTitle'),
    ],
    [
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.victime.title',
        ),
        columns: [
          ageDateAttribution || ageDateAttribution === 0
            ? i18next.t('forms.fields.age.value', {
                count: ageDateAttribution,
              })
            : '',
          baremeCapitalisationLabel,
          formData.victime.coefficientCapitalisation ||
          formData.victime.coefficientCapitalisation === 0
            ? fCoefficientCapitalisation(
                formData.victime.coefficientCapitalisation,
              )
            : '',
        ],
      },
      {
        rowLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.title',
        ),
        columns: [
          ageDateAttributionConjoint || ageDateAttributionConjoint === 0
            ? i18next.t('forms.fields.age.value', {
                count: ageDateAttributionConjoint,
              })
            : '',
          baremeCapitalisationLabel,
          formData.conjoint.coefficientCapitalisation ||
          formData.conjoint.coefficientCapitalisation === 0
            ? fCoefficientCapitalisation(
                formData.conjoint.coefficientCapitalisation,
              )
            : '',
        ],
      },
    ],
  );
  const prejudiceViagerApresLiquidationParagraph = getParagraph({
    children: getTextRun({
      text: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViagerApresLiquidation.print.value',
        {
          value: fCurrency(formData.foyer.prejudiceViagerApresLiquidation),
        },
      ),
      break: 1,
    }),
  });
  const enfantNameRow: FormTableRow<
    PrejudiceFormPerteRevenusProcheEnfantRow,
    keyof PrejudiceFormPerteRevenusProcheEnfantRow,
    PrejudiceFormPerteRevenusProcheEnfantRow[keyof PrejudiceFormPerteRevenusProcheEnfantRow]
  > = {
    name: 'victimeIndirecteId',
    headerLabel: i18next.t(
      'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.nom.columnHeader',
    ),
    render: (row) => {
      const victimeIndirecte = victimesIndirectes.find(
        (victimeIndirecte) => victimeIndirecte._id === row.victimeIndirecteId,
      );
      return `${victimeIndirecte?.prenom || ''} ${victimeIndirecte?.nom || ''}`;
    },
  };

  const enfantsFirstTable = prejudiceFormTable(formData.enfants.rows, [
    enfantNameRow,
    {
      name: 'ageFinSoutienFinancier',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.ageFinSoutienFinancier.columnHeader',
      ),
      render: (row) =>
        i18next.t('forms.fields.age.value', {
          count: row.ageFinSoutienFinancier || 0,
          context:
            row.ageFinSoutienFinancier === 0 ? 'exactly_zero' : undefined,
        }),
    },
    {
      name: 'partIndividuelle',
      headerLabel: i18next.t(
        'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.partIndividuelle.columnHeader',
      ),
      render: (row) => fPercent(row.partIndividuelle || 0, 2),
    },
  ]);

  const enfantsApresLiquidationTable: (Table | Paragraph | TableOfContents)[] =
    [
      getParagraph({
        children: getTextRun({
          text: `${i18next.t(
            'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.apresLiquidation.title',
          )}`,
          bold: true,
          break: 1,
        }),
      }),
      ...prejudiceFormTable(formData.enfants.rows, [
        enfantNameRow,
        {
          name: 'ageFinSoutienFinancier',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.ageLiquidation.columnHeader',
          ),
          render: (row) => {
            const victimeIndirecte = victimesIndirectes.find(
              (victimeIndirecte) =>
                victimeIndirecte._id === row.victimeIndirecteId,
            );
            if (!victimeIndirecte) {
              return '';
            }
            const ageLiquidation = CalculsGlobal.getAgeOrZero(
              victimeIndirecte?.dateNaissance,
              dateLiquidation,
            );
            return i18next.t('forms.fields.age.value', {
              count: ageLiquidation.age,
              context: ageLiquidation.age === 0 ? 'exactly_zero' : undefined,
            });
          },
        },
        {
          name: 'partPerteAnnuelle',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.rows.partPerteAnnuelle.columnHeader',
          ),
          render: (row) => fCurrency(row.partPerteAnnuelle),
        },
        {
          name: 'coefficientCapitalisation',
          headerLabel: i18next.t(
            'prejudice.capitalisation.form.fields.bareme.title',
          ),
          render: () => baremeCapitalisationLabel,
        },
        {
          name: 'ageFinSoutienFinancier',
          headerLabel: i18next.t(
            'prejudice.capitalisation.form.fields.ageDernierArrerage.title',
          ),
          render: (row) =>
            i18next.t('forms.fields.age.value', {
              count: row.ageFinSoutienFinancier || 0,
              context:
                row.ageFinSoutienFinancier === 0 ? 'exactly_zero' : undefined,
            }),
        },
        {
          name: 'coefficientCapitalisation',
          headerLabel: i18next.t(
            'prejudice.capitalisation.form.fields.coefficient.otherTitle',
          ),
          render: (row) =>
            row.coefficientCapitalisation || row.coefficientCapitalisation === 0
              ? fCoefficientCapitalisation(row.coefficientCapitalisation)
              : '',
        },
        {
          name: 'total',
          headerLabel: i18next.t(
            'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.total.columnHeader',
          ),
          render: (row) =>
            row.montantCapitalise ? fCurrency(row.montantCapitalise) : '',
        },
      ]),
    ];

  const enfantsTotalTable: (Table | Paragraph | TableOfContents)[] = [
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.total.title',
        )}`,
        bold: true,
        break: 1,
      }),
    }),
    ...prejudiceFormTable(formData.enfants.rows, [
      enfantNameRow,
      {
        name: 'total',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.rows.total.columnHeader',
        ),
        render: (row) => fCurrency(row.total),
      },
      {
        name: 'capitauxDeces',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.rows.capitauxDeces.columnHeader',
        ),
        render: (row) => fCurrency(row.capitauxDeces.total),
      },
      {
        name: 'totalApresCapitauxDeces',
        headerLabel: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.rows.totalApresCapitauxDeces.columnHeader',
        ),
        render: (row) => fCurrency(row.totalApresCapitauxDeces),
      },
    ]),
  ];
  const totalTable = prpRecapitulatifTotalTablePrint({
    formData,
    partResponsabilite,
  });
  const notesPrint = prejudiceNotesPrint(formData.notes);
  return [
    ...(notesPrint ? [notesPrint] : []),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.perteAnnuelleFoyer.title',
        )}`,
        bold: true,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.avantDeces.print.title',
        )}`,
        break: 1,
        bold: true,
      }),
    }),
    getEmptyLine(),
    avantDecesTable,
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.revenuAnnuel.value',
          {
            value: fCurrency(formData.foyer.revenuAnnuel),
          },
        )}`,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.pourcentageConsomVictime.value',
          {
            value:
              formData.foyer.pourcentageConsomVictime !== null
                ? fPercent(formData.foyer.pourcentageConsomVictime)
                : '',
          },
        )}`,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.deductionPartConsomVictime.print.value',
          {
            value: fCurrency(formData.foyer.deductionPartConsomVictime),
          },
        )}`,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.apresDeces.print.title',
        )}`,
        break: 1,
        bold: true,
      }),
    }),
    apresDecesTable,
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.perteAnnuelle.print.value',
          {
            value: fCurrency(formData.foyer.perteAnnuelle),
          },
        )}`,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.prejudiceViagerFoyer.title',
        )}`,
        bold: true,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.prejudiceViagerFoyer.entreDecesEtLiquidation.title',
        ),
        bold: true,
        break: 1,
      }),
    }),
    anneesEntreDecesEtLiquidationParagraph,
    prejudiceViagerFoyerEntreDecesEtLiquidationParagraph,
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.prejudiceViagerFoyer.apresLiquidation.title',
        ),
        bold: true,
        break: 1,
      }),
    }),
    prejudiceViagerFoyerTable,
    prejudiceViagerApresLiquidationParagraph,
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.foyer.prejudiceViager.value',
          {
            value: fCurrency(formData.foyer.prejudiceViager),
          },
        )}`,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.enfants.title',
        )}
                `,
        bold: true,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.enfants.partTotale.print.value',
          {
            value: fPercent(formData.enfants.partTotale),
          },
        )}`,
        break: 1,
      }),
    }),
    getEmptyLine(),
    ...enfantsFirstTable,
    getEmptyLine(),
    ...enfantsEntreDecesEtLiquidationTable({
      formData,
      victimesIndirectes,
    }),
    getEmptyLine(),
    ...enfantsApresLiquidationTable,
    getEmptyLine(),
    ...enfantsTotalTable,
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.conjoint.title',
          {
            deces: !!victime.dateDeces,
          },
        )}`,
        bold: true,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.total.print.value',
          {
            value: fCurrency(formData.conjoint.total),
          },
        )}`,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.capitauxDeces.total.print.value',
          {
            value: fCurrency(formData.conjoint.capitauxDeces.total || 0),
            partResponsabilite: fPartResponsabilite(partResponsabilite * 100),
          },
        )}`,
        break: 1,
      }),
    }),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.fields.conjoint.totalApresCapitauxDeces.value',
          {
            value: fCurrency(formData.conjoint.totalApresCapitauxDeces),
          },
        )}`,
        break: 1,
      }),
    }),
    getEmptyLine(),
    getParagraph({
      children: getTextRun({
        text: `${i18next.t(
          'prejudice.prejudicesFormTypes.PERTES_REVENUS_PROCHE.victimeDecedee.total.recapitulatif.print.title',
        )}`,
        bold: true,
        break: 1,
      }),
    }),
    totalTable,
    getEmptyLine(),
    ...debitSoldeEchusIndirectePrint({
      indemniteGlobaleARepartir: CalculPerteRevenusProche.getIndemniteRepartie({
        tiersPayeurs: procedure.tiersPayeurs,
        victimesIndirectes,
        perteRevenusProche: formData,
        partResponsabilite,
      }),
      partResponsabilite,
      victimesIndirectes,
    }),
  ];
};
