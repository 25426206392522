import {
  AlignmentType,
  HeightRule,
  Paragraph,
  Table,
  TableCell,
  TableOfContents,
  TableRow,
  VerticalAlign,
} from 'docx';
import { Prejudice, PrejudiceType } from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import {
  getPrejudicesTotal,
  getPrejudiceTotalAmount,
  PrejudiceTotalValue,
} from 'src/helpers/prejudices/total';
import { fCurrency } from '../formatNumber';
import {
  getEmptyLine,
  getParagraph,
  getTableCell,
  getTableRow,
  getTextRun,
} from './docxFunctions';
import i18n from 'i18next';
import { getPrejudicesPerCategoryPerDirectEntries } from 'src/helpers/prejudices/sortAndOrder';
import { VictimeIndirecte } from 'src/types/victimeIndirecte.type';
import { simpleVerticalTablePrint } from './simpleVerticalTablePrint';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';

export const makePrejudicesMinimalTotalPrint = ({
  procedure,
  prejudicesTotalValues,
  victime,
  victimesIndirectes,
  prejudices,
  isVictimeIndirecteTable,
  monetaryErosions,
  dateLiquidation,
}: {
  victime: Victime;
  victimesIndirectes: VictimeIndirecte[];
  procedure: Procedure;
  prejudices: Prejudice[];
  isVictimeIndirecteTable?: boolean;
  prejudicesTotalValues: Partial<Record<PrejudiceType, PrejudiceTotalValue[]>>;
  monetaryErosions: MonetaryErosion[];
  dateLiquidation?: Date;
}): (Paragraph | Table | TableOfContents)[] | undefined => {
  const prejudicesPerCategory = getPrejudicesPerCategoryPerDirectEntries({
    victime,
    procedure,
    victimesIndirectes,
    prejudices,
  })[isVictimeIndirecteTable ? 'INDIRECTE' : 'DIRECTE'];
  const prejudicesForPrint = prejudicesPerCategory
    .map(([, prejudices]) => prejudices)
    .flat();
  if (prejudicesPerCategory.length === 0) {
    return [];
  }
  let table: Table;
  if (isVictimeIndirecteTable) {
    table = simpleVerticalTablePrint([
      {
        title: i18n.t(
          'print.prejudicesTotal.minimal.table.victimeIndirecteHeader',
        ),
      },
      ...prejudicesPerCategory.reduce(
        (
          accumulator: Parameters<typeof simpleVerticalTablePrint>[0],
          [category, prejudices],
        ) => {
          if (prejudices.length === 0) {
            return accumulator;
          }
          return [
            ...accumulator,
            {
              title: i18n.t(`prejudice.categories.${category}`, ''),
            },
            ...prejudices.reduce(
              (
                accumulator: Parameters<typeof simpleVerticalTablePrint>[0],
                prejudice,
              ) => {
                const totalValue = prejudicesTotalValues[prejudice.type]?.[0];
                if (totalValue) {
                  accumulator.push({
                    label:
                      i18n.t(
                        `prejudice.prejudicesTypes.${prejudice.type}.title`,
                      ) || '',
                    value: fCurrency(totalValue.amount),
                  });
                }
                return accumulator;
              },
              [],
            ),
          ];
        },
        [],
      ),
    ]);
  } else {
    const totalValues = getPrejudicesTotal({
      victime,
      victimesIndirectes,
      procedure,
      prejudices: prejudicesForPrint,
      dateLiquidation,
      monetaryErosions,
      dateConsolidation: procedure.dateConsolidation
        ? new Date(procedure.dateConsolidation)
        : undefined,
      dateDeces: victime.dateDeces ? new Date(victime.dateDeces) : undefined,
    });
    table = new Table({
      rows: [
        new TableRow({
          height: {
            value: 400,
            rule: HeightRule.ATLEAST,
          },
          tableHeader: true,
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: getTextRun({
                    break: 1,
                  }),
                }),
              ],
            }),
            ...[
              i18n.t(
                'print.prejudicesTotal.minimal.table.columnHeaders.victime',
              ),
              i18n.t(
                'print.prejudicesTotal.minimal.table.columnHeaders.tiersPayeurs',
              ),
              i18n.t('print.prejudicesTotal.minimal.table.columnHeaders.total'),
            ].map(
              (name) =>
                new TableCell({
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: getTextRun({
                        text: name,
                        bold: true,
                      }),
                    }),
                  ],
                }),
            ),
          ],
        }),
        ...prejudicesPerCategory.reduce(
          (accumulator: TableRow[], [category, prejudices]) => {
            if (prejudices.length === 0) {
              return accumulator;
            }
            return [
              ...accumulator,
              getTableRow({
                children: [
                  getTableCell({
                    columnSpan: 7,
                    children: [
                      getParagraph({
                        children: getTextRun({
                          bold: true,
                          text: i18n.t(`prejudice.categories.${category}`, ''),
                        }),
                      }),
                    ],
                  }),
                ],
              }),
              ...prejudices.map((prejudice) => {
                const totalAmount = getPrejudiceTotalAmount({
                  prejudiceTotalValues:
                    prejudicesTotalValues[prejudice.type] || [],
                });

                return new TableRow({
                  height: {
                    value: 400,
                    rule: HeightRule.ATLEAST,
                  },
                  children: [
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      children: [
                        new Paragraph({
                          children: getTextRun({
                            text: i18n.t(
                              `prejudice.prejudicesTypes.${prejudice.type}.title`,
                            ),
                          }),
                        }),
                      ],
                    }),
                    ...[
                      totalAmount.victime,
                      totalAmount.tiersPayeurs,
                      totalAmount.total,
                    ].map(
                      (amount) =>
                        new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: getTextRun({
                                text:
                                  amount || amount === 0
                                    ? fCurrency(amount)
                                    : '',
                              }),
                            }),
                          ],
                        }),
                    ),
                  ],
                });
              }),
            ];
          },
          [],
        ),
        getTableRow({
          children: [
            getTableCell({
              children: [
                getParagraph({
                  children: getTextRun({
                    text: i18n.t(
                      'print.prejudicesTotal.minimal.table.totalTableRowHeader',
                    ),
                    bold: true,
                  }),
                }),
              ],
            }),
            getTableCell({
              children: [
                getParagraph({
                  alignment: AlignmentType.CENTER,
                  children: getTextRun({
                    text: fCurrency(totalValues.victime),
                  }),
                }),
              ],
            }),
            getTableCell({
              children: [
                getParagraph({
                  alignment: AlignmentType.CENTER,
                  children: getTextRun({
                    text: fCurrency(totalValues.tiersPayeurs || 0),
                  }),
                }),
              ],
            }),
            getTableCell({
              children: [
                getParagraph({
                  alignment: AlignmentType.CENTER,
                  children: getTextRun({
                    text: fCurrency(
                      totalValues.victime + (totalValues.tiersPayeurs || 0),
                    ),
                  }),
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }
  const title = new Paragraph({
    children: [
      ...getTextRun({
        text: isVictimeIndirecteTable
          ? i18n.t('print.prejudicesTotal.minimal.victimeIndirecteTitle')
          : i18n.t('print.prejudicesTotal.minimal.victimeDirecteTitle'),
        bold: true,
        size: 20,
        break: 2,
      }),
      getEmptyLine(2),
    ],
  });
  return [title, table];
};
